import styled from 'styled-components';
import { Link } from 'react-router-dom';
export const BillingDashboardContainer = styled.div.attrs({
  id: 'billingDashboardContainer',
})``;

export const BillingHeader = styled.h1.attrs({
  id: 'billingHeader',
  'aria-label': 'Billing Table',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
  }
`;
export const BillingLabelContainer = styled.div.attrs({
  className: 'billingColumnLabels',
})`
  display: grid;
  word-break: break-word;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 14.27% 14.27% 14.27% 14.27% 14.27% 14.27% 14.27%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 14.27% 14.27% 14.27% 14.27% 14.27% 14.27% 14.27%;
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 14.27% 14.27% 14.27% 14.27% 14.27% 14.27% 14.27%;
    margin-left: 5%;
  }
`;
export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;

export const billingInformationLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const AccountLabel = styled(billingInformationLabel).attrs({
  id: 'accountNumberLabel',
})``;
export const PayOffAmountLabel = styled(billingInformationLabel).attrs({
  id: 'payOffAmountLabel',
})``;
export const BillToLabel = styled(billingInformationLabel).attrs({
  id: 'billToLabel',
})``;
export const PaymentPlanLabel = styled(billingInformationLabel).attrs({
  id: 'paymentPlanLabel',
})``;
export const InvoiceDateLabel = styled(billingInformationLabel).attrs({
  id: 'invoiceDateLabel',
})``;
export const CurrentDueLabel = styled(billingInformationLabel).attrs({
  id: 'currentDueLabel',
})``;
export const DueDateLabel = styled(billingInformationLabel).attrs({
  id: 'dueDateLabel',
})``;

export const AccountLabelMobile = styled(billingInformationLabel).attrs({
  id: 'accountNumberLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const PayOffAmountLabelMobile = styled(billingInformationLabel).attrs({
  id: 'payOffAmountLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const BillToLabelMobile = styled(billingInformationLabel).attrs({
  id: 'billToLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const PaymentPlanLabelMobile = styled(billingInformationLabel).attrs({
  id: 'paymentPlanLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const InvoiceDateLabelMobile = styled(billingInformationLabel).attrs({
  id: 'invoiceDateLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const CurrentDueLabelMobile = styled(billingInformationLabel).attrs({
  id: 'currentDueLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const DueDateLabelMobile = styled(billingInformationLabel).attrs({
  id: 'dueDateLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const billingDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;
export const AccountLink = styled(Link).attrs(props => ({
  to: {
    pathname: '/accountDetails',
    state: {
      accountNumber: props.accountnumber,
    },
  },
}))`
  :hover {
    color: ${props =>
      props.theme.primaryColorHover ? props.theme.primaryColorHover : props.theme.primaryColor};
  }
`;

export const AccountContainer = styled(billingDataDiv).attrs({
  id: 'accountNumberContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const PayOffAmountContainer = styled(billingDataDiv).attrs({
  id: 'payOffAmountContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const BillToContainer = styled(billingDataDiv).attrs({
  id: 'billToContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const PaymentPlanContainer = styled(billingDataDiv).attrs({
  id: 'paymentPlanContainer',
})`
  word-break: break-word;
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const InvoiceDateContainer = styled(billingDataDiv).attrs({
  id: 'invoiceDateContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const CurrentDueContainer = styled(billingDataDiv).attrs({
  id: 'currentDueContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const DueDateContainer = styled(billingDataDiv).attrs({
  id: 'dueDateContainer',
})`
  @media (max-width: 299px) {
    margin-bottom: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #e3e5e7;
  }
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 2px solid #e3e5e7;
  }
`;

export const BillingDataContainer = styled.div`
  display: grid;
  word-break: break-all;
  @media (max-width: 599px) {
    row-gap: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 14.27% 14.27% 14.27% 14.27% 14.27% 14.27% 14.27%;
    margin-top: 6px;
    margin-bottom: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 14.27% 14.27% 14.27% 14.27% 14.27% 14.27% 14.27%;
    margin-left: 3%;
    margin-top: 6px;
    margin-bottom: 24px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 14.27% 14.27% 14.27% 14.27% 14.27% 14.27% 14.27%;
    margin-left: 5%;
    margin-top: 6px;
    margin-bottom: 24px;
  }
`;
