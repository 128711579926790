import { API } from '../../../../utils/api/index';
import { setClaims, setLoading, setErrors } from './claimsReducer';

export const getClaims = customerId => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get('customers/' + customerId + '/claims')
      .then(response => {
        dispatch(setClaims(response.data.customerClaimListItems));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
