import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepButton, StepLabel } from '@mui/material';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import { Helmet } from 'react-helmet';
import {
  AuthorizationMessage,
  ButtonContainer,
  LinkStyleButton,
  PaymentContainer,
  PaymentHeader,
  MakePaymentBackButton,
  StyledButtonNext,
} from './styles';
import { LineDivider } from '../../common/line-divider';
import {
  resetStepOneState,
  setActiveStep,
  setIsLoading,
  setNextButtonClicked,
  setNextButtonDisabled,
} from './makePaymentReducer';
import { resetStepTwoState } from './step-two-amount/makePaymentStepTwoReducer';
import { resetStepFourState } from './step-four-recap/makePaymentStepFourReducer';
import { gaEvent } from '../../../utils/google-analytics';
import MakeAPaymentStepOne from './step-one-policy';
import MakeAPaymentStepTwo from './step-two-amount';
import MakeAPaymentStepThree from './step-three-pay';
import { CustomStepperIcons } from '../../common/stepper/utils';
import { getText } from '../../../utils/i18n';
import { ga4Event } from '../../../utils/google-analytics-4';

export const MakeAPayment = props => {
  const {
    accountNumber,
    backButtonDisabled,
    dispatch,
    i18n,
    nextButtonDisabled,
    activeStep,
    payDate,
    response,
  } = props;
  let history = useHistory();
  React.useEffect(() => {
    gaEvent({ category: 'make-a-payment', action: 'start' });
    ga4Event({ category: 'make-a-payment', action: 'start' });
    let makePaymentProcessListener = history.listen(history => {
      // when leaving payment flow, reset state and un-listen
      if (history.pathname !== '/makePayment') {
        dispatch(resetStepOneState());
        dispatch(resetStepTwoState());
        dispatch(resetStepFourState());
        makePaymentProcessListener();
      }
    });
  }, []);
  const getSteps = () => {
    return ['Choose Policy', 'Add Payment Details', 'Review/Submit'];
  };
  const steps = getSteps();
  const [completed, setCompleted] = React.useState({});
  const handleNext = () => {
    dispatch(setIsLoading(true));
    dispatch(setNextButtonClicked(true));
    dispatch(setNextButtonDisabled(true));
  };

  const handleBack = () => {
    dispatch(setIsLoading(true));
    dispatch(setActiveStep(activeStep - 1));
  };

  const handleComplete = index => {
    const newCompleted = completed;
    newCompleted[index] = true;
    setCompleted(newCompleted);
  };
  useEffect(() => {
    if (response && activeStep === 3) {
      handleComplete(activeStep);
    } else {
      handleComplete(activeStep - 1);
    }
  }, [activeStep, response]);
  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <MakeAPaymentStepOne />;
      case 1:
        return <MakeAPaymentStepTwo />;
      case 2:
        return <MakeAPaymentStepThree />;
      case 3:
        return <MakeAPaymentStepThree />;
      default:
        return <MakeAPaymentStepOne />;
    }
  };
  const handleStep = step => () => {
    dispatch(setActiveStep(step));
  };
  return (
    <PaymentContainer>
      <Helmet>
        <title>Make Payment - Select Account</title>
      </Helmet>
      {activeStep !== 3 && (
        <PaymentHeader id="paymentHeader">
          {activeStep === 2 ? `Review Details` : `Make Payment`}
          {accountNumber && `: ${accountNumber}`}
        </PaymentHeader>
      )}
      {activeStep !== 3 && (
        <>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const buttonProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    {...buttonProps}
                    completed={completed[index]}
                  >
                    <StepLabel StepIconComponent={CustomStepperIcons}>{label}</StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <LineDivider />
        </>
      )}
      {getStepContent(activeStep)}

      {activeStep !== 3 ? (
        <>
          <LinkStyleButton onClick={() => history.push('/dashboard')}>Cancel</LinkStyleButton>

          <ButtonContainer>
            {activeStep === 0 ? null : (
              <MakePaymentBackButton disabled={backButtonDisabled} onClick={handleBack}>
                Back{' '}
              </MakePaymentBackButton>
            )}
            <StyledButtonNext
              disabled={nextButtonDisabled}
              onClick={() => {
                handleNext();
              }}
            >
              {activeStep !== 2 ? `Next` : `Pay Now`}
            </StyledButtonNext>
          </ButtonContainer>
        </>
      ) : null}
      {activeStep === 2 && (
        <AuthorizationMessage>
          {getText(
            i18n,
            'payment.authorizationMessage',
            `By selecting Pay Now, you authorize us to deduct the Amount To Be Paid from the selected bank or credit card account on or after ${moment(
              payDate,
            ).format(
              DISPLAY_DATE_FORMAT,
            )} If the Amount To Be Paid is return NSF, you may be charged a fee. If this account is a joint account, your authorization constitutes authorization of all current and/or future account owners.`,
          ).replace('{{DueDateOfPayment}}', moment(payDate).format(DISPLAY_DATE_FORMAT))}{' '}
        </AuthorizationMessage>
      )}
    </PaymentContainer>
  );
};
MakeAPayment.propTypes = {
  activeStep: PropTypes.any,
  active: PropTypes.any,
  completed: PropTypes.any,
  accountNumber: PropTypes.any,
  backButtonDisabled: PropTypes.bool,
  billingAccounts: PropTypes.array,
  icon: PropTypes.any,
  nextButtonDisabled: PropTypes.bool,
  payDate: PropTypes.any,
  dispatch: PropTypes.func,
  pendingPayments: PropTypes.bool,
  i18n: PropTypes.object,
  response: PropTypes.object,
};
const mapStateToProps = state => ({
  activeStep: state.makePaymentSlice.activeStep,
  accountNumber: state.makePaymentSlice.accountToPayWith?.fullAccount?.accountNumber,
  backButtonDisabled: state.makePaymentSlice.backButtonDisabled,
  billingAccounts: state.billingSlice.billingAccounts,
  i18n: state.i18nSlice.i18n,
  nextButtonDisabled: state.makePaymentSlice.nextButtonDisabled,
  payDate: state.makePaymentStepTwoSlice.payDate,
  response: state.makePaymentAnonymousSlice.response,
});
export default connect(mapStateToProps)(MakeAPayment);
