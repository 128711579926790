import * as React from 'react';
import { cx } from '@emotion/css';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import Toolbar from '@mui/material/Toolbar';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import EditIcon from '@mui/icons-material/Edit';
import LinkIcon from '@mui/icons-material/Link';
import HelpIcon from '@mui/icons-material/Help';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import HomeIcon from '@mui/icons-material/Home';
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import QueryBuilderIcon from '@mui/icons-material/QueryBuilder';
import ReportIcon from '@mui/icons-material/Report';
import { Link } from 'react-router-dom';
import UserLogout from '../../../logout';
import {
  AnnouncementContentAreaMobile,
  classes,
  DrawerButton,
  CustomHTML1LinkVertical,
  CustomHTML2LinkVertical,
  ContentLink,
  Root,
  MobileAppBar,
  MobileMenu,
} from './styles';
import Routing from '../routing';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Footer from '../../footer';
import NewsBanners from '../../../news-banners';

export const MobileDashboardTemplate2 = (props) => {
  const [open, setOpen] = React.useState(false);
  const {
    customPage1Href,
    customPage1Html,
    customPage1Name,
    customPage2Href,
    customPage2Html,
    customPage2Name,
    CustomContentPage1,
    CustomContentPage2,
    needHelpAllowed,
    changeCoverageAllowed,
    reportClaimAllowed,
    viewChangeRequestsAllowed,
    viewIdCard,
    openQuotes,
  } = props;

  const toggleDrawer = (opened) => (event) => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpen(!opened);
  };

  return (
    <>
      <Root className={classes.appBarRoot}>
        <MobileAppBar position="static">
          <Toolbar>
            <DrawerButton
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer(open)}
              className={cx(classes.menuButton, open && classes.hide)}
            >
              <MenuIcon />
            </DrawerButton>

            <div style={{ flexGrow: 1 }} />
            <UserLogout />
          </Toolbar>
        </MobileAppBar>
      </Root>
      <SwipeableDrawer open={open} onClose={toggleDrawer(open)} onOpen={toggleDrawer(open)}>
        <MobileMenu>
          <Link to="/dashboard" onClick={toggleDrawer(true)}>
            <ListItem button>
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary={'Home'} />
            </ListItem>
          </Link>
        </MobileMenu>
        {openQuotes && (
          <MobileMenu>
            <Link to="/openQuotes" onClick={toggleDrawer(true)}>
              <ListItem button>
                <ListItemIcon>
                  <FormatQuoteIcon />
                </ListItemIcon>
                <ListItemText primary={'Open Quotes'} />
              </ListItem>
            </Link>
          </MobileMenu>
        )}
        <MobileMenu>
          <Link to="/makePayment" onClick={toggleDrawer(true)}>
            <ListItem button>
              <ListItemIcon>
                <AttachMoneyIcon />
              </ListItemIcon>
              <ListItemText primary={'Make Payment'} />
            </ListItem>
          </Link>
        </MobileMenu>
        {reportClaimAllowed ? (
          <MobileMenu>
            <Link to="/reportClaim" onClick={toggleDrawer(true)}>
              <ListItem>
                <ListItemIcon>
                  <ReportIcon />
                </ListItemIcon>
                <ListItemText primary={'Report Claim'} />
              </ListItem>
            </Link>
          </MobileMenu>
        ) : null}
        {changeCoverageAllowed ? (
          <MobileMenu>
            <Link to="/changeCoverage" onClick={toggleDrawer(true)}>
              <ListItem>
                <ListItemIcon>
                  <EditIcon />
                </ListItemIcon>
                <ListItemText primary={'Change Coverage'} />
              </ListItem>
            </Link>
          </MobileMenu>
        ) : null}
        {viewChangeRequestsAllowed && (
          <MobileMenu>
            <Link to="/pendingRequests" onClick={toggleDrawer(true)}>
              <ListItem>
                <ListItemIcon>
                  <QueryBuilderIcon />
                </ListItemIcon>
                <ListItemText primary={'Pending Requests'} />
              </ListItem>
            </Link>
          </MobileMenu>
        )}
        {viewIdCard && (
          <MobileMenu>
            <Link to="/idCards" onClick={toggleDrawer(true)}>
              <ListItem>
                <ListItemIcon>
                  <FingerprintIcon />
                </ListItemIcon>
                <ListItemText primary={'ID Cards'} />
              </ListItem>
            </Link>
          </MobileMenu>
        )}
        <MobileMenu>
          <Link to="/userProfile" onClick={toggleDrawer(true)}>
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={'Profile'} />
            </ListItem>
          </Link>
        </MobileMenu>
        <MobileMenu>
          <Link to="/customerInformation" onClick={toggleDrawer(true)}>
            <ListItem button>
              <ListItemIcon>
                <PersonIcon />
              </ListItemIcon>
              <ListItemText primary={'Customer Information'} />
            </ListItem>
          </Link>
        </MobileMenu>
        {needHelpAllowed && (
          <MobileMenu>
            <Link
              to={{
                pathname: '/needHelp',
                state: {
                  customerSlice: props.state.customerSlice,
                },
              }}
              onClick={toggleDrawer(true)}
            >
              <ListItem>
                <ListItemIcon>
                  <HelpIcon />
                </ListItemIcon>
                <ListItemText primary={'Need Help?'} />
              </ListItem>
            </Link>
          </MobileMenu>
        )}
        {CustomContentPage1 && (
          <>
            {customPage1Html ? (
              <MobileMenu>
                <ListItem>
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>

                  <CustomHTML1LinkVertical>{customPage1Name}</CustomHTML1LinkVertical>
                </ListItem>
              </MobileMenu>
            ) : (
              <MobileMenu>
                <ListItem>
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ContentLink href={customPage1Href} target="_blank">
                    {customPage1Name}
                  </ContentLink>
                </ListItem>
              </MobileMenu>
            )}
          </>
        )}
        {CustomContentPage2 && (
          <>
            {customPage2Html ? (
              <MobileMenu>
                <ListItem>
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <CustomHTML2LinkVertical>{customPage2Name}</CustomHTML2LinkVertical>
                </ListItem>
              </MobileMenu>
            ) : (
              <MobileMenu>
                <ListItem>
                  <ListItemIcon>
                    <LinkIcon />
                  </ListItemIcon>
                  <ContentLink href={customPage2Href} target="_blank">
                    {customPage2Name}
                  </ContentLink>
                </ListItem>
              </MobileMenu>
            )}
          </>
        )}
      </SwipeableDrawer>
      <Routing isMobile={true} />
      <AnnouncementContentAreaMobile>
        <NewsBanners />
      </AnnouncementContentAreaMobile>
      <Footer />
    </>
  );
};

MobileDashboardTemplate2.propTypes = {
  needHelpAllowed: PropTypes.bool,
  changeCoverageAllowed: PropTypes.bool,
  customPage1Href: PropTypes.string,
  customPage1Html: PropTypes.string,
  customPage1Name: PropTypes.string,
  customPage2Href: PropTypes.string,
  customPage2Html: PropTypes.string,
  customPage2Name: PropTypes.string,
  CustomContentPage1: PropTypes.bool,
  CustomContentPage2: PropTypes.bool,
  reportClaimAllowed: PropTypes.bool,
  state: PropTypes.shape({
    customerSlice: PropTypes.object,
  }),
  viewIdCard: PropTypes.bool,
  viewChangeRequestsAllowed: PropTypes.bool,
  openQuotes: PropTypes.bool,
};

export const mapStateToProps = (state) => ({
  needHelpAllowed: state.configurationSlice.features.features.needHelp,
  changeCoverageAllowed: state.configurationSlice.features.features.changeCoverage,
  customPage1Html: state.i18nSlice.i18n.customContent.customContentPage1.html,
  customPage1Href: state.i18nSlice.i18n.customContent.customContentPage1.url,
  customPage1Name: state.i18nSlice.i18n.customContent.customContentPage1.linkText,
  customPage2Html: state.i18nSlice.i18n.customContent.customContentPage2.html,
  customPage2Href: state.i18nSlice.i18n.customContent.customContentPage2.url,
  customPage2Name: state.i18nSlice.i18n.customContent.customContentPage2.linkText,
  CustomContentPage1: state.configurationSlice.features.features.CustomContentPage1,
  CustomContentPage2: state.configurationSlice.features.features.CustomContentPage2,
  reportClaimAllowed: state.configurationSlice.features.features.reportClaim,
  state,
  viewChangeRequestsAllowed: state.configurationSlice.features.features.viewChangeRequests,
  viewIdCard: state.customerSlice.selfServiceConfigurations.selfServiceFeature.viewIdCard,
  openQuotes: state.configurationSlice.features.features.cpPortalLinking,
});

export default connect(mapStateToProps)(MobileDashboardTemplate2);
