import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  InstructionsContainer,
  PolicyInfoFieldContainer,
  PolicyInfoFieldHeader,
  RequiredField,
  TermsAndConditionsContainer,
  TextLink,
  VerificationHeader,
} from './styles';
import { useSnackbar } from 'notistack';
import Loader from '../../../common/loader';
import { setRegistrationToken } from '../../login/loginReducer';
import {
  getRegistrationFields,
  verifyRegistrationFields,
} from '../../create-account/createAccountApi';
import { setFieldAnswers } from './stepOneReducer';
import {
  setIsLoading,
  setNextButtonDisabled,
  setNextButtonClicked,
  setActiveStep,
} from '../makePaymentAnonymousReducer';
import { getPolicyInfoInputField } from '../../../../utils/fields';
import Modal from '../../../common/modal-with-html';
import termsHtml from '../../../../config/documents/tos.html';
import privacyHtml from '../../../../config/documents/privacy.html';
import { getText } from '../../../../utils/i18n';

const Step1 = (props) => {
  const {
    registrationFields,
    activeStep,
    dispatch,
    isLoading,
    nextButtonClicked,
    fieldAnswers,
    customTOS,
    customPrivacyStatement,
    i18n,
  } = props;

  const [answers, setAnswers] = useState(fieldAnswers);
  const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [isConditionsAccepted, setConditionAccepted] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    dispatch(setRegistrationToken(''));
    dispatch(setIsLoading(true));
    dispatch(getRegistrationFields(enqueueSnackbar));
  }, []);

  useEffect(() => {
    if (registrationFields && registrationFields.length > 0 && nextButtonClicked === false) {
      props.dispatch(setIsLoading(false));
    }
  }, [registrationFields]);

  const nextButtonClickHandler = async () => {
    const registrationAnswers = [];
    for (let i = 0; i < registrationFields.length; i++) {
      registrationAnswers[i] = { name: registrationFields[i].value, value: answers[i].answer };
    }
    dispatch(setNextButtonClicked(false));
    dispatch(setFieldAnswers([answers]));
    await dispatch(
      verifyRegistrationFields(
        {
          optionKey: 'serviceportal',
          registrationInfo: registrationAnswers,
        },
        enqueueSnackbar,
        true,
      ),
    );
    dispatch(setIsLoading(true));
    dispatch(setActiveStep(1));
  };
  useEffect(() => {
    if (activeStep === 0 && nextButtonClicked === true) {
      nextButtonClickHandler();
    }
  }, [nextButtonClicked, activeStep]);

  const areRequiredFieldsCompleted = () => {
    if (
      registrationFields.length === 0 ||
      Object.entries(answers).length !== registrationFields.length
    )
      return setNextButtonDisabled(true);
    for (const [, value] of Object.entries(answers)) {
      if (!value.fieldComplete) return dispatch(setNextButtonDisabled(true));
    }
    if (isConditionsAccepted === false) {
      return dispatch(setNextButtonDisabled(true));
    }
    return dispatch(setNextButtonDisabled(false));
  };
  useEffect(() => {
    // check required fields are complete and listen on any state related changes
    areRequiredFieldsCompleted();
  }, [answers, registrationFields, isConditionsAccepted]);

  return (
    <>
      <Helmet>
        <title>Make Payment - Select Account</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {i18n?.makePaymentAnonymous?.stepOne?.instructions && (
            <InstructionsContainer
              dangerouslySetInnerHTML={{ __html: i18n.makePaymentAnonymous.stepOne.instructions }}
            />
          )}
          <VerificationHeader id="">
            Answer the following question to verify a valid account.
          </VerificationHeader>
          {registrationFields.map((registrationField, index) => (
            <PolicyInfoFieldContainer key={index} id={registrationField.name + 'FieldContainer'}>
              <PolicyInfoFieldHeader>
                {registrationField.name}
                <RequiredField> *</RequiredField>
              </PolicyInfoFieldHeader>
              {getPolicyInfoInputField(
                registrationField.value,
                index,
                answers,
                setAnswers,
                'MakePayAnon',
              )}
            </PolicyInfoFieldContainer>
          ))}
          <TermsAndConditionsContainer>
            <label htmlFor="termsAndConditions">
              <input
                type="checkbox"
                checked={isConditionsAccepted}
                id="termsAndConditions"
                onChange={() => {
                  setConditionAccepted(!isConditionsAccepted);
                }}
              />
              <span> I have read and accept the </span>
            </label>
            <TextLink
              onClick={() => {
                setTermsAndConditionsDialogOpen(true);
              }}
            >
              {getText(i18n, 'general.termsOfServiceTitle', 'Terms of Service')}
            </TextLink>{' '}
            and{' '}
            <TextLink
              onClick={() => {
                setPrivacyDialogOpen(true);
              }}
            >
              {getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
            </TextLink>
            .
            <Modal
              modalHtml={termsHtml}
              dialogOpen={termsAndConditionsDialogOpen}
              customModal={customTOS}
              modalTitle={getText(i18n, 'general.termsOfServiceTitle', 'Terms of Service')}
              setDialogClosed={() => setTermsAndConditionsDialogOpen(false)}
            />
            <Modal
              modalHtml={privacyHtml}
              dialogOpen={privacyDialogOpen}
              customModal={customPrivacyStatement}
              modalTitle={getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
              setDialogClosed={() => setPrivacyDialogOpen(false)}
            />
          </TermsAndConditionsContainer>
        </>
      )}
    </>
  );
};
Step1.displayName = 'MakePaymentAnonymousStep1';
Step1.propTypes = {
  activeStep: PropTypes.number,
  isLoading: PropTypes.bool,
  nextButtonClicked: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  dispatch: PropTypes.any,
  registrationFields: PropTypes.array,
  registrationToken: PropTypes.string,
  handleNext: PropTypes.func,
  fieldAnswers: PropTypes.array,
  customPrivacyStatement: PropTypes.bool,
  customTOS: PropTypes.bool,
  i18n: PropTypes.object,
};

export const mapStateToProps = (state) => ({
  registrationFields: state.loginSlice.registrationFields,
  registrationToken: state.loginSlice.registrationToken,
  fieldAnswers: state.makePaymentAnonymousStepOneSlice.fieldAnswers,
  isLoading: state.makePaymentAnonymousSlice.isLoading,
  i18n: state.i18nSlice.i18n,
  activeStep: state.makePaymentAnonymousSlice.activeStep,
  nextButtonClicked: state.makePaymentAnonymousSlice.nextButtonClicked,
  nextButtonDisabled: state.makePaymentAnonymousSlice.nextButtonDisabled,
  customPrivacyStatement: state.configurationSlice.features.features.customPrivacyStatement,
  customTOS: state.configurationSlice.features.features.customTOS,
  state,
});
export default connect(mapStateToProps)(Step1);
