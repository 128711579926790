import { setNewsBanners } from './newsBannersReducer';
import { API } from '../../../utils/api';
import moment from 'moment';

export const getNewsBanners = asOfDate => {
  return dispatch => {
    dispatch(setNewsBanners([]));
    return API.get(`newsBanners?status=Active&asOfDate=${asOfDate}`)
      .then(response => {
        const originalNewsBannerList = response?.data?.newsBannerListItems
          ? response.data.newsBannerListItems
          : [];

        // filter out expired news banners
        const currentTimestamp = moment(
          moment().format('YYYY-MM-DD') + ' ' + moment().format('HH:mm:ss'),
        );
        const filteredNewsBannerList = originalNewsBannerList.filter(newsBannerItem => {
          const newsBanner = newsBannerItem.newsBanner;
          const startTime = moment(newsBanner.startDt + ' ' + newsBanner.startTm);
          const endTime = moment(newsBanner.endDt + ' ' + newsBanner.endTm);
          return (
            currentTimestamp.isAfter(startTime) &&
            currentTimestamp.isBefore(endTime) &&
            newsBanner.siteLocation.toLowerCase() === 'serviceportal'
          );
        });

        // sort by sortOrder field
        const sortedList =
          filteredNewsBannerList.length > 0
            ? filteredNewsBannerList.sort((a, b) =>
                a.newsBanner.sortOrder < b.newsBanner.sortOrder ? -1 : 1,
              )
            : [];
        dispatch(setNewsBanners(sortedList));
      })
      .catch(() => dispatch(setNewsBanners([])));
  };
};
