import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import DesktopDashboardTemplate2 from './desktop';
import MobileDashboardTemplate2 from './mobile';

export const DashboardTemplate2 = () => {
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return hidden ? <MobileDashboardTemplate2 /> : <DesktopDashboardTemplate2 />;
};

export default DashboardTemplate2;
