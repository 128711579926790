import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import GetQuoteModal from '../../open-quotes/getQuoteModal';
import {
  GetQuoteContainer,
  GetAQuoteButtonContainer,
  GetQuoteHeader,
  TemplateOnePanel,
} from './styles';
import Loader from '../../../common/loader';
import { getText } from '../../../../utils/i18n';
export const GetQuote = props => {
  const { isLoading, template, i18n } = props;

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <GetQuoteContainer template={template}>
          <TemplateOnePanel template={template}>Get a Quote</TemplateOnePanel>
          <GetQuoteHeader template={template}>
            {getText(
              i18n,
              'general.newQuoteMessage',
              'Protect yourself with the right coverages for you',
            )}
          </GetQuoteHeader>
          <GetAQuoteButtonContainer template={template}>
            <GetQuoteModal />
          </GetAQuoteButtonContainer>
        </GetQuoteContainer>
      )}
    </>
  );
};
GetQuote.propTypes = {
  isLoading: PropTypes.bool,
  template: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = state => ({
  isLoading: state.agentSlice.isLoading,
  template: state.configurationSlice.features.template,
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(GetQuote);
