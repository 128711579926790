import React, { useContext, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import {
  ButtonContainer,
  CheckboxContainer,
  ConsentCheckbox,
  ConsentContainer,
  ConsentDescription,
  EditingNoticeContainer,
  EditNotificationsContainer,
  EmailLabel,
  MiniTitle,
  NotificationCheckbox,
  SMSLabel,
} from './styles';
import Loader from '../../common/loader';
import { Button, createTheme } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import InfoIcon from '@mui/icons-material/Info';
import { ThemeContext } from 'styled-components';
import { setEditingNotifications } from './profileReducer';
import { saveNotificationPreferences } from './profileAPI';
import { useSnackbar } from 'notistack';
import { getText } from '../../../utils/i18n';
import TooltipInfo from '../../common/tooltip';

export const EditingNotifications = (props) => {
  const theme = useContext(ThemeContext);

  const muiTheme = createTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          '&.Mui-completed': {
            color: theme.primaryColor,
          },
          '&.Mui-active': {
            color: theme.primaryColor,
          },
        },
        active: {},
        completed: {},
      },
      MuiButton: {
        root: {
          backgroundColor: theme.primaryColor,
          color: 'white',
          '&:hover': {
            backgroundColor: theme.primaryColorHover,
            color: 'white',
          },
          '&.Mui-disabled': {
            backgroundColor: 'gray',
          },
        },
      },
    },
  });
  const {
    termAcceptInd,
    consentDescription,
    customerId,
    customerSubscriptions,
    dispatch,
    isLoading,
    isSubscribedSMS,
    customerSubscriptionsFull,
    notificationSmsNumber,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [customerSubscription, setCustomerSubscriptions] = useState(customerSubscriptions);
  const [consent, setConsent] = useState(termAcceptInd);
  const customerSubscriptionBody = { ...customerSubscriptionsFull };

  const updateNotificationPreferences = () => {
    customerSubscriptionBody.termAcceptInd = consent;
    let statusCd = false;
    customerSubscription.forEach((categorySubscription) => {
      statusCd = statusCd || categorySubscription.subscribedInd;
    });

    // at least one category is enabled and consent is true
    customerSubscriptionBody.statusCd = statusCd && consent;

    customerSubscriptionBody.customerSubscription = customerSubscription;
    dispatch(saveNotificationPreferences(customerId, customerSubscriptionBody, enqueueSnackbar));
  };
  const setCheckbox = (subscription) => {
    setCustomerSubscriptions((prevState) => {
      return prevState.map((thisSubscription) => {
        if (
          subscription.categoryCd === thisSubscription.categoryCd &&
          subscription.typeCd === thisSubscription.typeCd
        ) {
          return { ...thisSubscription, subscribedInd: !thisSubscription.subscribedInd };
        } else {
          return thisSubscription;
        }
      });
    });
  };

  const changeConsent = (consent) => {
    setConsent(consent);

    // when user removes consent, we display a warning
    if (!consent) {
      enqueueSnackbar(
        'Subsequent notifications will no longer be sent after the withdrawal of your notifications consent.',
        {
          variant: 'warning',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        },
      );
    }
  };
  const mapSubscriptions = (typeCd, header) => {
    return customerSubscription.map((subscription) => {
      if (subscription.typeCd === typeCd) {
        return (
          <CheckboxContainer key={`${subscription.categoryCd}${subscription.typeCd}`}>
            <label htmlFor={`${subscription.categoryCd}${subscription.typeCd}`}>
              <NotificationCheckbox
                onChange={() => setCheckbox(subscription)}
                checked={subscription.subscribedInd}
                style={{ color: theme.primaryColor, padding: '0' }}
                id={`${subscription.categoryCd}${subscription.typeCd}`}
              />{' '}
              <span aria-label={header}>{subscription.categoryCd}</span>
              {subscription.description && (
                <TooltipInfo title={subscription.description} size="large" />
              )}
            </label>
          </CheckboxContainer>
        );
      }
    });
  };
  return (
    <>
      <ThemeProvider theme={muiTheme}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <EditNotificationsContainer>
              <div>
                <SMSLabel>Receive via SMS</SMSLabel>
                {mapSubscriptions('SMS', 'Receive via SMS')}
              </div>
              <div>
                <EmailLabel>Receive via Email</EmailLabel>
                {mapSubscriptions('Email', 'Receive via Email')}
              </div>
            </EditNotificationsContainer>

            {!isSubscribedSMS && (
              <EditingNoticeContainer>
                <InfoIcon style={{ position: 'relative', top: '6px', right: '4px' }} />
                <MiniTitle>Notice:</MiniTitle>{' '}
                <span id="noticeText">
                  You are not subscribed to SMS notifications. If you have opted out on your phone,
                  you can text RESUME to {notificationSmsNumber} to receive notifications. Refresh
                  this page to see the change in status.
                </span>
              </EditingNoticeContainer>
            )}
            <ConsentContainer>
              <label htmlFor="consentCheck">
                <ConsentCheckbox
                  id="consentCheck"
                  onChange={() => {
                    changeConsent(!consent);
                  }}
                  style={{ color: theme.primaryColor, padding: '0', marginTop: '0' }}
                  checked={consent}
                />
                <ConsentDescription>{consentDescription}</ConsentDescription>
              </label>
              <ButtonContainer>
                <Button onClick={() => updateNotificationPreferences()}>Apply </Button>
                <Button
                  style={{ marginLeft: '24px' }}
                  onClick={() => dispatch(setEditingNotifications(false))}
                >
                  Cancel{' '}
                </Button>
              </ButtonContainer>
            </ConsentContainer>
          </>
        )}
      </ThemeProvider>
    </>
  );
};
EditingNotifications.propTypes = {
  termAcceptInd: PropTypes.bool,
  consentDescription: PropTypes.string,
  customerId: PropTypes.any,
  customerSubscriptions: PropTypes.any,
  customerSubscriptionsFull: PropTypes.object,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  isSubscribedSMS: PropTypes.bool,
  notificationSmsNumber: PropTypes.string,
};
const mapStateToProps = (state) => ({
  consentDescription: getText(
    state.i18nSlice.i18n,
    'userProfile.consentDescription',
    `By clicking here, I consent to receiving notifications through the selected
  channels and bear all the costs related to its usage.`,
  ),
  termAcceptInd: state.notificationSlice.customerSubscriptionsFull.termAcceptInd,
  customerId: state.loginSlice.user.preferred_username,
  customerSubscriptions: state.notificationSlice.customerSubscriptions,
  customerSubscriptionsFull: state.notificationSlice.customerSubscriptionsFull,
  isLoading: state.profileSlice.isLoading,
  isSubscribedSMS: state.notificationSlice.isSubscribedSMS,
  notificationSmsNumber: state.configurationSlice.features.notificationSmsNumber,
});
export default connect(mapStateToProps)(EditingNotifications);
