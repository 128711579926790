import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  Button,
  ButtonsContainer,
  CancelButton,
  DescriptionTextInputField,
  FileName,
  FileThumbnail,
  FileThumbnailContainer,
  FileUploadButton,
  FileUploadButtonLabel,
  FileUploadContentContainer,
  FileUploadFormContainer,
  FileUploadFormFieldHeader,
  FileUploadHeader,
  FileUploadHeaderContainer,
  FileUploadSubHeader,
  GarbageIcon,
  GarbageIconContainer,
  HalfWidthFieldContainer,
  ImgThumbnail,
  ModalCloserSpaced,
  PicturesContainer,
  PitcureIcon,
  RequiredField,
  SaveButton,
  StyledLoaderContainer,
  ThumbnailContainer,
} from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Select from 'react-select';
import { checkFileSize } from '../../../utils/file-utils';
import { useSnackbar } from 'notistack';
import { attachFilesApi, uploadFilesApi } from '../../../utils/upload-api';
import {
  setAttachmentTemplatesLoading,
  setFileAttachmentComplete,
  setUploadedFiles,
} from './fileUploadReducer';
import { getAttachmentTemplates } from './fileUploadApi';
import Loader from '../../common/loader';
import { ApiError } from '../../common/api-error';
import sanitizeHtml from 'sanitize-html';

const FileUploadModal = (props) => {
  const {
    attachmentTemplatesLimit,
    dispatch,
    uploadFileSize,
    type,
    beanRef,
    errors,
    uploadedFiles,
    isFileAttachmentComplete,
    refreshAttachments,
    attachmentTemplates,
    attachmentTemplatesLoading,
    isAttachingFiles,
    policyUserAttachmentsDisplay,
  } = props;

  const [open, setOpen] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [description, setDescription] = useState('');
  const [selectedAttachmentTemplate, setSelectedAttachmentTemplate] = useState(undefined);
  const [fileUploadInProgress, setFileUploadInProgress] = useState(false);
  const comment = 'Uploaded from Service Portal';
  const { enqueueSnackbar } = useSnackbar();

  const resetFields = () => {
    setSelectedFiles([]);
    setDescription('');
    setFileUploadInProgress(false);
    dispatch(setUploadedFiles(null));
    dispatch(setFileAttachmentComplete(false));
  };

  const useFallbackThumbnail = (file) =>
    file.type.includes('document') || file.type.includes('pdf') || file.type.includes('text');

  const handleOpen = () => {
    resetFields();
    dispatch(getAttachmentTemplates(type, beanRef, attachmentTemplatesLimit));
    dispatch(setAttachmentTemplatesLoading(true));
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleFileSelection = () => {
    setSelectedFiles([]);
    const fileInput = document.getElementById('uploadNewPropertyFile');
    let { files } = fileInput;
    files = [...files];

    const goodFiles = checkFileSize(files, uploadFileSize, enqueueSnackbar);
    [...goodFiles].forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        file.url = reader.result;
        setSelectedFiles((oldList) => [...oldList, file]);
      };
      reader.readAsDataURL(file);
    });

    if (goodFiles.length > 0) {
      setFileUploadInProgress(true);
      dispatch(uploadFilesApi(goodFiles));
    }
  };

  const filterDeletedFiles = (uploadedFiles, filesToAttach) => {
    if (uploadedFiles.length === filesToAttach.length) return uploadedFiles;
    const filterFiles = [];
    const uploadedFilesSet = new Set();
    filesToAttach.forEach((file) => uploadedFilesSet.add(file.name));
    uploadedFiles.forEach((fileName) => {
      if (uploadedFilesSet.has(fileName)) {
        filterFiles.push(fileName);
      }
    });
    return filterFiles;
  };

  const onSaveButtonClick = () => {
    dispatch(
      attachFilesApi(
        filterDeletedFiles(uploadedFiles, selectedFiles),
        type,
        beanRef,
        selectedAttachmentTemplate.id,
        description,
        comment,
        enqueueSnackbar,
      ),
    );
  };

  React.useEffect(() => {
    if (isFileAttachmentComplete) {
      setOpen(false);
      if (policyUserAttachmentsDisplay) {
        refreshAttachments();
      }
      dispatch(setFileAttachmentComplete(false));
    }
  }, [isFileAttachmentComplete]);

  React.useEffect(() => {
    if (uploadedFiles && uploadedFiles.length > 0) {
      setFileUploadInProgress(false);
    }
  }, [uploadedFiles]);

  return (
    <>
      <Button onClick={() => handleOpen()}> Upload New Files</Button>
      <Dialog open={open} onClose={handleClose} fullWidth={true} maxWidth="md">
        <ModalCloserSpaced onClick={() => handleClose()} aria-label="Close Button">
          x
        </ModalCloserSpaced>
        {errors ? (
          <ApiError body="There was an error loading your document types." variant="table" />
        ) : (
          <FileUploadContentContainer>
            <FileUploadHeaderContainer>
              <FileUploadHeader>Upload New Files</FileUploadHeader>
            </FileUploadHeaderContainer>
            <FileUploadSubHeader>Document Details</FileUploadSubHeader>
            <FileUploadFormContainer>
              <FileUploadFormFieldHeader htmlFor="documentType">
                Document Type <RequiredField>*</RequiredField>
              </FileUploadFormFieldHeader>
              <HalfWidthFieldContainer>
                {attachmentTemplatesLoading ? (
                  <Loader />
                ) : (
                  <Select
                    name="documentType"
                    id="documentType"
                    defaultValue={{ label: '-- Select a Document Type --', value: ' ' }}
                    options={attachmentTemplates}
                    value={selectedAttachmentTemplate}
                    onChange={(selectedTemplate) => {
                      setSelectedAttachmentTemplate(selectedTemplate);
                      setDescription(selectedTemplate.name + ' from Policyholder');
                    }}
                  />
                )}
              </HalfWidthFieldContainer>
            </FileUploadFormContainer>
            <FileUploadFormContainer>
              <FileUploadFormFieldHeader htmlFor="description">
                Description <RequiredField>*</RequiredField>
              </FileUploadFormFieldHeader>
              <DescriptionTextInputField
                name="description"
                id="description"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => {
                  setDescription(sanitizeHtml(e.target.value));
                }}
              />
            </FileUploadFormContainer>
            <FileUploadSubHeader>
              Attach File(s) <RequiredField>*</RequiredField>
            </FileUploadSubHeader>
            <FileUploadFormContainer>
              {selectedFiles.length > 0 && (
                <PicturesContainer>
                  {selectedFiles.map((file, index) => (
                    <ThumbnailContainer key={index}>
                      {useFallbackThumbnail(file) ? (
                        <FileThumbnailContainer>
                          <FileThumbnail />
                          <FileName>{file.name}</FileName>
                        </FileThumbnailContainer>
                      ) : (
                        <ImgThumbnail src={file.url} />
                      )}
                      <GarbageIconContainer>
                        <GarbageIcon
                          onClick={() => {
                            setUploadedFiles(null);
                            selectedFiles.splice(index, 1);
                            setSelectedFiles((uploadFiles) => [...uploadFiles]);
                          }}
                        />
                      </GarbageIconContainer>
                    </ThumbnailContainer>
                  ))}
                </PicturesContainer>
              )}
            </FileUploadFormContainer>
            {fileUploadInProgress && (
              <>
                <StyledLoaderContainer>
                  <Loader loaderheight="20px" loaderwidth="20px" />
                </StyledLoaderContainer>
                <>{'  '} Uploading file(s)...</>
              </>
            )}
            <FileUploadFormContainer>
              <FileUploadButtonLabel>
                <FileUploadButton>
                  <PitcureIcon className="fa fa-picture-o" /> Select File(s)
                  <input
                    type="file"
                    id="uploadNewPropertyFile"
                    accept="image/*,video/*,.pdf,.txt,.docx"
                    multiple
                    style={{
                      display: 'none',
                      width: '115px',
                    }}
                    onChange={() => {
                      handleFileSelection();
                    }}
                  />
                </FileUploadButton>
              </FileUploadButtonLabel>
            </FileUploadFormContainer>
            <ButtonsContainer>
              <CancelButton
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </CancelButton>
              <SaveButton
                disabled={
                  fileUploadInProgress ||
                  selectedFiles.length === 0 ||
                  description.length === 0 ||
                  selectedAttachmentTemplate === undefined ||
                  isAttachingFiles === true
                }
                onClick={() => {
                  onSaveButtonClick();
                }}
              >
                Save
              </SaveButton>
              {isAttachingFiles && <Loader loaderheight="20px" loaderwidth="20px" />}
            </ButtonsContainer>
          </FileUploadContentContainer>
        )}
      </Dialog>
    </>
  );
};
FileUploadModal.propTypes = {
  attachmentTemplatesLimit: PropTypes.string,
  dispatch: PropTypes.func,
  uploadFileSize: PropTypes.string,
  type: PropTypes.string,
  beanRef: PropTypes.string,
  errors: PropTypes.string,
  uploadedFiles: PropTypes.array,
  isFileAttachmentComplete: PropTypes.bool,
  refreshAttachments: PropTypes.func,
  attachmentTemplates: PropTypes.array,
  attachmentTemplatesLoading: PropTypes.bool,
  isAttachingFiles: PropTypes.bool,
  policyUserAttachmentsDisplay: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  attachmentTemplatesLimit: state.configurationSlice.features.attachmentTemplatesLimit,
  uploadFileSize: state.configurationSlice.features.maxUploadFileSize,
  errors: state.fileUploadSlice.errors,
  uploadedFiles: state.fileUploadSlice.uploadedFiles,
  isFileAttachmentComplete: state.fileUploadSlice.isFileAttachmentComplete,
  attachmentTemplates: state.fileUploadSlice.attachmentTemplates,
  attachmentTemplatesLoading: state.fileUploadSlice.attachmentTemplatesLoading,
  isAttachingFiles: state.fileUploadSlice.isAttachingFiles,
  policyUserAttachmentsDisplay:
    state.configurationSlice.features.features.policyUserAttachmentsDisplay,
  state,
});
export default connect(mapStateToProps)(FileUploadModal);
