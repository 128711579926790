import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AddDriverButtonsContainer,
  AddDriverDateInputField,
  AddDriverField,
  AddDriverFileUploadButton,
  AddDriverHeader,
  AddDriverTextInputField,
  CancelButton,
  FileName,
  FileThumbnail,
  FileThumbnailContainer,
  GarbageIcon,
  GarbageIconContainer,
  ImgThumbnail,
  NextButton,
  PhotoIcon,
  PicturesContainer,
  RequiredField,
  ThumbnailContainer,
  TwoColumnContainer,
} from './styles';
import AdditionQuestionField from '../add-vehicle/additional-question-field';
import moment from 'moment';
import {
  getDriverRelationshipTemplate,
  getLatestPolicyInfo,
  preSubmitPolicyChange,
  uploadAddDriverFiles,
} from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import Select from 'react-select';
import { ISO_DATE_FORMAT, US_STATES } from '../../../../common/constants';
import { createField } from '../selector';
import { getAdditionalQuestions, getQuestionReplies } from './selector';
import {
  setLoading,
  setPolicyChangePreview,
  setPolicyChangePreviewData,
} from '../../policyChangeReducer';
import { checkFileSize } from '../../../../../utils/file-utils';
import PolicyChangePreview from '../policy-change-preview';
import { useSnackbar } from 'notistack';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useHistory } from 'react-router';

const todayDt = moment().format(ISO_DATE_FORMAT);
export const AddDriver = (props) => {
  const {
    selectedPolicy,
    policyRefSelected,
    customerId,
    selectedPolicyChangeTemplate,
    uploadFileSize,
    policyChangePreview,
    driverRelationshipTemplate,
    i18n,
  } = props;
  // local state
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const MARITAL_STATUS = [
    { label: 'Married', value: 'Married' },
    { label: 'Unmarried', value: 'Unmarried' },
  ];
  const GENDER = [
    { label: 'Male', value: 'Male' },
    { label: 'Female', value: 'Female' },
  ];
  const [reason, setReason] = useState('');
  const [driverFirstName, setDriverFirstName] = useState('');
  const [driverMiddleName, setDriverMiddleName] = useState('');
  const [driverLastName, setDriverLastName] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [gender, setGender] = useState({
    label: '-- Select a gender --',
    value: 'default',
  });
  const [relationshipToInsured, setRelationshipToInsured] = useState({
    label: '-- Relationship to Insured --',
    value: 'default',
  });
  const [maritalStatus, setMaritalStatus] = useState({
    label: '-- Select a Status --',
    value: 'default',
  });
  const [dateLicensed, setDateLicensed] = useState('');
  const [licenseNumber, setLicenseNumber] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState({
    label: '-- Select a State --',
    value: 'default',
  });
  const [uploadFiles, setUploadFiles] = useState([]);
  const [additionalQuestions] = useState(getAdditionalQuestions(selectedPolicyChangeTemplate));
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const setAdditionalQuestionsAnswers = (index, answer) => {
    const theAnswer = {};
    theAnswer[index] = { answer };
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const setAdditionalSubQuestionsAnswers = (parentIndex, index, answer) => {
    const subQuestionAnswers = [];
    subQuestionAnswers[index] = answer;

    const theAnswer = [];
    theAnswer[parentIndex] = {
      answer: answers[parentIndex].answer,
      subQuestionAnswers,
    };
    setAnswers((prevAnswers) => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    return (
      effectiveDate.length > 0 &&
      reason.length > 0 &&
      driverFirstName.length > 0 &&
      driverLastName.length > 0 &&
      gender.value.length > 0 &&
      maritalStatus.value.length > 0 &&
      dateLicensed.length > 0 &&
      relationshipToInsured.value.length > 0 &&
      birthDate.length > 0 &&
      licenseNumber.length > 0 &&
      stateOrProvince.value &&
      stateOrProvince.value.length > 0
    );
  };

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    const questions = additionalQuestions;
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(createField('Requested Effective Date', effectiveDate, 'text'));
    fields.push(createField('Reason for Add', reason, 'text'));
    fields.push(createField("Driver's First Name", driverFirstName, 'text'));
    fields.push(createField('Middle Name', driverMiddleName, 'text'));
    fields.push(createField('Last Name', driverLastName, 'text'));
    fields.push(createField('Relationship to Insured', relationshipToInsured.value, 'text'));
    fields.push(createField('Birth Date', birthDate, 'text'));
    fields.push(createField('Gender', gender.value, 'text'));
    fields.push(createField('Marital Status', maritalStatus.value, 'text'));
    fields.push(createField('Date Licensed', dateLicensed, 'text'));
    fields.push(createField('License State', stateOrProvince.value, 'text'));
    fields.push(createField('License Number', licenseNumber, 'text'));

    for (let i = 0; i < questions.length; i++) {
      const question = questions[i];
      const answer = answers[i].answer;
      let value;
      if (question.dataType === 'YesNo') {
        value = answer;
      } else if (question.dataType === 'List') {
        value = answer;
      } else if (question.dataType === 'Numeric') {
        value = answer.toString();
      } else if (question.dataType === 'TextArea') {
        value = answer;
      }
      fields.push(createField(question.text, value, 'text'));

      if (
        question.questions &&
        question.questions.question &&
        question.questions.question.length > 0 &&
        answers[i].subQuestionAnswers
      ) {
        for (let j = 0; j < question.questions.question.length; j++) {
          const subQuestion = question.questions.question[j];
          const subAnswer = answers[i].subQuestionAnswers[j];
          let subValue;
          if (subQuestion.dataType === 'YesNo') {
            subValue = subAnswer;
          } else if (subQuestion.dataType === 'List') {
            subValue = subAnswer;
          } else if (subQuestion.dataType === 'Numeric') {
            subValue = subAnswer.toString();
          } else if (subQuestion.dataType === 'TextArea') {
            subValue = subAnswer;
          }
          fields.push(createField(subQuestion.text, subValue, 'text'));
        }
      }
    }

    fields.push(
      createField(
        "Pictures of driver's license",
        uploadFiles.length > 0 ? 'Included' : 'Not included',
        'text',
      ),
    );
    return fields;
  };

  const handleFileSelection = () => {
    const fileInput = document.getElementById('uploadNewDriverFile');
    let { files } = fileInput;
    files = [...files];

    const goodFiles = checkFileSize(files, uploadFileSize, enqueueSnackbar);

    [...goodFiles].forEach((file) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        file.url = reader.result;
        // setUploadFiles doesn't update the state immediately unless we follow the following technique
        setUploadFiles((oldList) => [...oldList, file]);
      };
      reader.readAsDataURL(file);
    });
  };

  const useFallbackThumbnail = (file) =>
    file.type.includes('document') || file.type.includes('pdf') || file.type.includes('text');

  const setInitialAnswers = (questions) => {
    let defaultAnswers = {};
    for (let i = 0; i < questions.length; i++) {
      defaultAnswers[i] = { answer: 'No' };
    }
    setAnswers(defaultAnswers);
  };

  useEffect(() => {
    if (policyChangePreview) {
      props.dispatch(setPolicyChangePreview(null));
    }
    setInitialAnswers(additionalQuestions);
    props.dispatch(getDriverRelationshipTemplate());
  }, []);

  const onNextButtonClick = async () => {
    setLoading(true);
    let payload = {
      name: selectedPolicyChangeTemplate.name,
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: policyRefSelected,
        policyDescription: selectedPolicy.label.substring(selectedPolicy.label.indexOf(' ') + 1),
      },
      changeRequest: [
        {
          detailDescription: reason,
          partyInfo: [
            {
              nameInfo: {
                surname: driverLastName,
                givenName: driverFirstName,
                otherGivenName: driverMiddleName,
              },
              personInfo: {
                birthDt: birthDate,
                genderCd: gender.value,
                maritalStatusCd: maritalStatus.value,
              },
              driverInfo: {
                relationshipToInsuredCd: relationshipToInsured.value,
                licenseDt: dateLicensed,
                licensedStateProvCd: stateOrProvince.value,
                licenseNumber: licenseNumber,
              },
            },
          ],
        },
      ],
      sourceCd: 'ServicePortal',
      questionReplies: [{ questionReply: getQuestionReplies(additionalQuestions, answers) }],
    };
    const fields = createPolicyChangeCoverageSummary();

    if (uploadFiles.length > 0) {
      props.dispatch(setLoading(true));
      await props.dispatch(uploadAddDriverFiles(uploadFiles));
      props.dispatch(setLoading(false));
    }

    props.dispatch(
      setPolicyChangePreviewData({
        fields,
        payload,
        recapFields: createPolicyChangeCoverageSummary(),
      }),
    );

    //get current policy deductible
    props.dispatch(getLatestPolicyInfo(selectedPolicy.value));

    //submit API call
    props.dispatch(preSubmitPolicyChange(payload));
  };

  const history = useHistory();

  return (
    <>
      {policyChangePreview ? (
        <PolicyChangePreview />
      ) : (
        <>
          <TwoColumnContainer>
            <AddDriverField>
              <AddDriverHeader>
                Requested Effective Date<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.effectiveDateTooltip')}
                />
              </AddDriverHeader>
              <AddDriverDateInputField
                id="effectiveDate"
                value={effectiveDate}
                onChange={(e) => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </AddDriverField>
            <AddDriverField>
              <AddDriverHeader>
                Reason for Add<RequiredField>*</RequiredField>
                <TooltipInfo title={getText(i18n, 'changeCoverage.addDriver.reasonDateTooltip')} />
              </AddDriverHeader>
              <AddDriverTextInputField
                id="reasonForAddDriver"
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value);
                }}
              />
            </AddDriverField>
          </TwoColumnContainer>
          <TwoColumnContainer>
            <AddDriverField>
              <AddDriverHeader>
                Driver&#39;s First Name<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.driverFirstNameTooltip')}
                />
              </AddDriverHeader>
              <AddDriverTextInputField
                id="driverFirstName"
                value={driverFirstName}
                onChange={(e) => {
                  setDriverFirstName(e.target.value);
                }}
              />
            </AddDriverField>
            <AddDriverField>
              <AddDriverHeader>
                Middle Name
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.driverMiddleNameTooltip')}
                />
              </AddDriverHeader>
              <AddDriverTextInputField
                id="driverMiddleName"
                value={driverMiddleName}
                onChange={(e) => {
                  setDriverMiddleName(e.target.value);
                }}
              />
            </AddDriverField>
          </TwoColumnContainer>
          <TwoColumnContainer>
            <AddDriverField>
              <AddDriverHeader>
                Last Name<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.driverLastNameTooltip')}
                />
              </AddDriverHeader>
              <AddDriverTextInputField
                id="driverLastName"
                value={driverLastName}
                onChange={(e) => {
                  setDriverLastName(e.target.value);
                }}
              />
            </AddDriverField>
            <AddDriverField>
              <AddDriverHeader>
                Relationship to Insured<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.relationshipToInsuredTooltip')}
                />
              </AddDriverHeader>
              <Select
                name="relationShipToInsured"
                id="relationShipToInsured"
                defaultValue={{ label: '-- Relationship to Insured --', value: 'default' }}
                value={relationshipToInsured}
                options={driverRelationshipTemplate}
                onChange={(selectedRelationship) => {
                  setRelationshipToInsured(selectedRelationship);
                }}
              />
            </AddDriverField>
          </TwoColumnContainer>
          <TwoColumnContainer>
            <AddDriverField>
              <AddDriverHeader>
                Birth Date<RequiredField>*</RequiredField>
                <TooltipInfo title={getText(i18n, 'changeCoverage.addDriver.birthDateTooltip')} />
              </AddDriverHeader>
              <AddDriverDateInputField
                id="birthDate"
                value={birthDate}
                onChange={(e) => {
                  setBirthDate(e.target.value);
                }}
              />
            </AddDriverField>
            <AddDriverField>
              <AddDriverHeader>
                Gender<RequiredField>*</RequiredField>
                <TooltipInfo title={getText(i18n, 'changeCoverage.addDriver.genderTooltip')} />
              </AddDriverHeader>
              <Select
                name="gender"
                id="gender"
                defaultValue={{ label: '-- Select a gender --', value: 'default' }}
                value={gender}
                options={GENDER}
                onChange={(selectedGender) => {
                  setGender(selectedGender);
                }}
              />
            </AddDriverField>
          </TwoColumnContainer>
          <TwoColumnContainer>
            <AddDriverField>
              <AddDriverHeader>
                Marital Status<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.maritalStatusTooltip')}
                />
              </AddDriverHeader>
              <Select
                name="maritalStatus"
                id="maritalStatus"
                defaultValue={{ label: '-- Select Status --', value: 'default' }}
                value={maritalStatus}
                options={MARITAL_STATUS}
                onChange={(selectedStatus) => {
                  setMaritalStatus(selectedStatus);
                }}
              />
            </AddDriverField>
            <AddDriverField>
              <AddDriverHeader>
                Date Licensed<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.dateLicensedTooltip')}
                />
              </AddDriverHeader>
              <AddDriverDateInputField
                id="dateLicensed"
                value={dateLicensed}
                onChange={(e) => {
                  setDateLicensed(e.target.value);
                }}
              />
            </AddDriverField>
          </TwoColumnContainer>
          <TwoColumnContainer>
            <AddDriverField>
              <AddDriverHeader>
                License State<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.licenseStateTooltip')}
                />
              </AddDriverHeader>
              <Select
                name="stateList"
                id="stateList"
                defaultValue={{ label: '-- Select a State --', value: 'default' }}
                value={stateOrProvince}
                options={US_STATES}
                onChange={(selectedState) => {
                  setStateOrProvince(selectedState);
                }}
              />
            </AddDriverField>
            <AddDriverField>
              <AddDriverHeader>
                License Number<RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addDriver.licenseNumberTooltip')}
                />
              </AddDriverHeader>
              <AddDriverTextInputField
                id="licenseNumber"
                value={licenseNumber}
                onChange={(e) => {
                  setLicenseNumber(e.target.value);
                }}
              />
            </AddDriverField>
          </TwoColumnContainer>
          {additionalQuestions.map((q, index) => {
            return (
              <AdditionQuestionField
                id={q.name}
                key={index}
                q={q}
                index={index}
                setAdditionalQuestionsAnswers={setAdditionalQuestionsAnswers}
                setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
              />
            );
          })}
          <AddDriverField>
            <AddDriverHeader>Picture of Driver&#39;s License</AddDriverHeader>

            <label>
              <AddDriverFileUploadButton>
                <PhotoIcon /> Select File(s)
                <input
                  type="file"
                  id="uploadNewDriverFile"
                  multiple
                  accept="image/*,video/*,.pdf,.txt,.docx"
                  style={{
                    display: 'none',
                    width: '115px',
                  }}
                  onChange={() => {
                    handleFileSelection();
                  }}
                />
              </AddDriverFileUploadButton>
            </label>
          </AddDriverField>
          {uploadFiles.length > 0 && (
            <PicturesContainer>
              {uploadFiles.map((file, index) => (
                <ThumbnailContainer key={index}>
                  {useFallbackThumbnail(file) ? (
                    <FileThumbnailContainer>
                      <FileThumbnail />
                      <FileName>{file.name}</FileName>
                    </FileThumbnailContainer>
                  ) : (
                    <ImgThumbnail src={file.url} />
                  )}
                  <GarbageIconContainer>
                    <GarbageIcon
                      onClick={() => {
                        uploadFiles.splice(index, 1);
                        setUploadFiles((uploadFiles) => [...uploadFiles]);
                      }}
                    />
                  </GarbageIconContainer>
                </ThumbnailContainer>
              ))}
            </PicturesContainer>
          )}
          <AddDriverButtonsContainer>
            <CancelButton
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Cancel
            </CancelButton>
            <NextButton
              disabled={!checkRequiredFields() || isSubmitting}
              onClick={() => {
                onNextButtonClick();
              }}
            >
              Next
            </NextButton>
            {isSubmitting && <Loader loaderheight="20px" loaderwidth="20px" />}
          </AddDriverButtonsContainer>
        </>
      )}
    </>
  );
};

AddDriver.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  isLoading: PropTypes.bool,
  uploadFileSize: PropTypes.any,
  policyRefSelected: PropTypes.string,
  attachmentDescription: PropTypes.string,
  attachmentTemplateId: PropTypes.string,
  policyChangePreview: PropTypes.object,
  driverRelationshipTemplate: PropTypes.array,
  i18n: PropTypes.object,
};

const mapStateToProps = (state) => ({
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  isLoading: state.policyChangeSlice.isLoading,
  uploadFileSize: state.configurationSlice.features.features.UploadFileSize,
  driverRelationshipTemplate: state.policyChangeSlice.driverRelationshipTemplate,
  policyRefSelected: state.policySlice.policies.find(
    (policy) => policy.policyNumber === state.policyChangeSlice.selectedPolicy.label.split('-')[0],
  ).policyRef,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(AddDriver);
