import styled from 'styled-components';
// NOTE: if variant controls become too unreadable we should consider refactoring inside component
// and separating the container/body/title into their own styled components
export const ErrorMessageContainer = styled.div`
  margin: ${(props) =>
    props.variant === 'modal'
      ? '0 0 30px 0'
      : props.variant === 'nomargin'
      ? '0'
      : props.variant === 'table'
      ? '2% 5%'
      : '30px'};
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279) {
    margin-top: 30px;
    margin-left: ${(props) =>
      props.variant === 'dashboard' ? '30px' : props.variant === 'menu' ? '14px' : null};
  }
  @media (min-width: 1280px) {
    margin-left: ${(props) =>
      props.variant === 'dashboard' ? '5%' : props.variant === 'menu' ? '14px' : null};
  }
`;
export const ErrorMessageTitle = styled.h3.attrs({})`
  font-family: 'Helvetica Neue', Lato, proxima-nova, Arial, Helvetica, sans-serif, sans-serif;
  font-size: 30px;
  font-weight: 500;
  margin: 16px 0 16px 0;
  color: ${(props) => (props.titleColor ? props.titleColor : 'black')};
`;
export const ErrorMessageBody = styled.div.attrs({
  id: 'errorBody',
})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 14px;
  font-weight: 400;
`;
