import React, { useEffect } from 'react';
// import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
// import { ThemeContext } from 'styled-components';
import { Helmet } from 'react-helmet';
// import { ISO_DATE_FORMAT } from '../../../common/constants';
import { cloneDeep } from 'lodash';
import {
  AmountDisclaimerContainer,
  AmountDisclaimerText,
  AmountContainer,
  AmountHeader,
  AmountText,
  // DateChoice, //NOTE: Several Stubbed out lines of code that can be added
  //                     back if scheduled payment request api is fixed to allow anon.
  // DateContainer,
  FullAmountCard,
  InformationArea,
  InstructionsContainer,
  MinimumAmountCard,
  OtherAmountCard,
  PartialAmountInput,
  // PayDateHeader,
  PaymentTypeContainer,
  PaymentTypeHeader,
  RadioChoice,
  StyledInfoIcon,
  SubHeader,
} from './styles';
import {
  setActiveStep,
  setNextButtonClicked,
  setNextButtonDisabled,
  setInstrumentToPayWith,
  setAmountToPay,
  // setPayDate,
  setRadioAmountType,
} from '../makePaymentAnonymousReducer';
import { getAddress, getBillingCity, getBillingState, getBillingZip } from './selectors';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import FundingInstruments from './fundingInstruments';

export const bankAccountTypes = [
  {
    label: 'Personal Checking',
    value: 'PChecking',
    typeCd: 'Checking',
    entryCd: 'PPD',
  },
  {
    label: 'Personal Savings',
    typeCd: 'Savings',
    value: 'Savings',
    entryCd: 'PPD',
  },
  {
    label: 'Commercial Checking',
    value: 'CChecking',
    typeCd: 'Checking',
    entryCd: 'CCD',
  },
];
const Step3 = (props) => {
  const {
    activeStep,
    amountToPay,
    billSelected,
    dispatch,
    accountPaymentConfigId,
    nextButtonClicked,
    paymentTenantName,
    paymentApiUrl,
    customerAddress,
    customerCity,
    firstName,
    instrumentToPayWith,
    lastName,
    customerState,
    customerZip,
    i18n,
    otherAmountAllowed,
    // schedulePaymentsAllowed,
    radioAmountType,
  } = props;

  const isStatementAccount = billSelected.billingAccount.accountNumber.slice(0, 2) === 'SA';
  let [radioAmountChecked, setRadioAmountChecked] = React.useState(radioAmountType);
  let [paymentAmount, setPaymentAmount] = React.useState('');
  const { openAmt, totalAmt } = billSelected.billingAccount;
  let [partialAmount, setPartialAmount] = React.useState(
    amountToPay !== totalAmt ? amountToPay : null,
  );
  // let [dateChoice, setDateChoice] = React.useState(moment().format(ISO_DATE_FORMAT));

  useEffect(() => {
    dispatch(setNextButtonDisabled(true));
    if (window.GuidewirePayments.createSDK) {
      const config = {
        apiUrl: paymentApiUrl,
        tenant: paymentTenantName,
        paymentConfigId: accountPaymentConfigId,
        featurePrefillAddress: true,
        customerAddress,
        customerCountry: 'US',
        customerCity,
        customerFirstName: firstName,
        customerLastName: lastName,
        customerState,
        customerZip,
        dev_enableVerboseLogging: false,
        featureCustomAddressCollection: true,
      };
      window.GuidewirePayments = window.GuidewirePayments.createSDK(config);
    }
  }, []);

  const isValidPartialAmt = (amount) => {
    return (
      parseFloat(amount) >= parseFloat(openAmt) &&
      parseFloat(amount) <= parseFloat(totalAmt) &&
      parseFloat(amount) !== parseFloat(0)
    );
  };
  useEffect(() => {
    if (
      (instrumentToPayWith && radioAmountChecked !== 2 && radioAmountChecked !== null) ||
      (instrumentToPayWith &&
        radioAmountChecked !== null &&
        radioAmountChecked === 2 &&
        isValidPartialAmt(partialAmount))
    ) {
      dispatch(setNextButtonDisabled(false));
    } else {
      dispatch(setNextButtonDisabled(true));
    }
  }, [radioAmountChecked, instrumentToPayWith, partialAmount]);
  const nextButtonClickHandler = () => {
    if (radioAmountChecked === 2) {
      dispatch(setAmountToPay(partialAmount));
      // dispatch(setPayDate(dateChoice));
    } else {
      dispatch(setAmountToPay(paymentAmount));
      // dispatch(setPayDate(dateChoice));
    }
    let newInstrument = cloneDeep(instrumentToPayWith);

    dispatch(setInstrumentToPayWith(newInstrument));
    dispatch(setNextButtonClicked(false));
    dispatch(setRadioAmountType(radioAmountChecked));
    dispatch(setActiveStep(3));
  };
  useEffect(() => {
    if (activeStep === 2 && nextButtonClicked === true) {
      nextButtonClickHandler();
    }
  }, [nextButtonClicked, activeStep]);
  return (
    <>
      <Helmet>
        <title>Make Payment - Select Payment Method</title>
      </Helmet>
      <>
        {i18n?.makePaymentAnonymous?.stepThree?.instructions && (
          <InstructionsContainer
            dangerouslySetInnerHTML={{ __html: i18n.makePaymentAnonymous.stepThree.instructions }}
          />
        )}
        {/* <SubHeader>Select Date</SubHeader>
        <DateContainer>
          <FullAmountCard>
            <PayDateHeader>
              Payment Date
              <TooltipInfo title={getText(i18n, 'makePayment.stepTwo.paymentDateTooltip')} />
              <DateChoice
                id={'PaymentDate'}
                disabled={!schedulePaymentsAllowed}
                value={dateChoice}
                min={moment().format(ISO_DATE_FORMAT)}
                onChange={e => setDateChoice(e.target.value)}
              />
            </PayDateHeader>
          </FullAmountCard>
        </DateContainer> */}
        <SubHeader>Select Amount</SubHeader>
        {i18n?.makePaymentAnonymous?.stepThree?.selectAmountInstructions && (
          <InstructionsContainer
            dangerouslySetInnerHTML={{
              __html: i18n.makePaymentAnonymous.stepThree.selectAmountInstructions,
            }}
          />
        )}
        <AmountContainer>
          <label htmlFor="PayoffAmountRadio">
            <FullAmountCard>
              <RadioChoice
                aria-label="Select Amount Payoff Amount"
                id={'PayoffAmountRadio'}
                checked={radioAmountChecked === 0}
                onChange={() => {
                  setPaymentAmount(totalAmt);
                  setRadioAmountChecked(0);
                }}
              />
              <AmountHeader>
                Payoff Amount
                <TooltipInfo title={getText(i18n, 'makePayment.stepTwo.payOffAmountTooltip')} />
              </AmountHeader>
              <AmountText id={'PayoffAmount'}>${totalAmt}</AmountText>
            </FullAmountCard>
          </label>
          {openAmt !== '0.00' && (
            <label htmlFor="CurrentAmountDueRadio">
              <MinimumAmountCard>
                <RadioChoice
                  aria-label="Select Amount Current Due"
                  id={'CurrentAmountDueRadio'}
                  checked={radioAmountChecked === 1}
                  onChange={() => {
                    setPaymentAmount(openAmt);
                    setRadioAmountChecked(1);
                  }}
                />
                <AmountHeader>Current Due</AmountHeader>
                <AmountText id={'CurrentDueAmount'}>${openAmt}</AmountText>
              </MinimumAmountCard>
            </label>
          )}
          {otherAmountAllowed && !isStatementAccount && (
            <label htmlFor="PartialAmountRadio">
              <OtherAmountCard>
                <RadioChoice
                  aria-label="Select Amount Partial Amount"
                  id={'PartialAmountRadio'}
                  checked={radioAmountChecked === 2}
                  onChange={() => {
                    setRadioAmountChecked(2);
                  }}
                />
                <AmountHeader>
                  {' '}
                  Partial Amount
                  <TooltipInfo title={getText(i18n, 'makePayment.stepTwo.partialAmountTooltip')} />
                </AmountHeader>
                <PartialAmountInput
                  type="number"
                  aria-label="Partial Amount"
                  value={partialAmount}
                  onChange={(e) => {
                    setPartialAmount(e.target.value);
                    setRadioAmountChecked(2);
                  }}
                  onBlur={() => setPartialAmount(parseFloat(partialAmount).toFixed(2))}
                />
                <InformationArea>
                  <StyledInfoIcon />
                  Partial amount must be between payoff amount and current due.
                </InformationArea>
              </OtherAmountCard>
            </label>
          )}
        </AmountContainer>
        <PaymentTypeContainer>
          <PaymentTypeHeader>Payment Type</PaymentTypeHeader>
          {i18n?.makePaymentAnonymous?.stepThree?.genericPaymentTypeInstructions && (
            <InstructionsContainer
              dangerouslySetInnerHTML={{
                __html: i18n.makePaymentAnonymous.stepThree.genericPaymentTypeInstructions,
              }}
            />
          )}
          {instrumentToPayWith &&
            (instrumentToPayWith?.methodCd == 'Credit Card' ||
              instrumentToPayWith?.sourceType === 'CreditCard') &&
            i18n?.makePaymentAnonymous?.stepThree?.creditCardSpecificInstructions && (
              <InstructionsContainer
                dangerouslySetInnerHTML={{
                  __html: i18n.makePaymentAnonymous.stepThree.creditCardSpecificInstructions,
                }}
              />
            )}
          <FundingInstruments />
        </PaymentTypeContainer>
        <AmountDisclaimerContainer>
          <AmountDisclaimerText>
            <StyledInfoIcon />
            {getText(
              i18n,
              'general.paymentAmountDisclaimer',
              'Final payment amount may change depending on the payment type selected and applicable fees.',
            )}
          </AmountDisclaimerText>
        </AmountDisclaimerContainer>
      </>
    </>
  );
};
Step3.displayName = 'MakePaymentAnonymousStep3';
Step3.propTypes = {
  activeStep: PropTypes.any,
  amountToPay: PropTypes.any,
  accountPaymentConfigId: PropTypes.string,
  billingAccounts: PropTypes.array,
  customerAddress: PropTypes.string,
  customerCity: PropTypes.string,
  customerState: PropTypes.string,
  customerZip: PropTypes.string,
  dispatch: PropTypes.func,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  nextButtonClicked: PropTypes.bool,
  paymentTenantName: PropTypes.string,
  paymentConfigId: PropTypes.object,
  paymentApiUrl: PropTypes.string,
  i18n: PropTypes.object,
  radioAmountType: PropTypes.any,
  billSelected: PropTypes.any,
  schedulePaymentsAllowed: PropTypes.any,
  instrumentToPayWith: PropTypes.any,
  otherAmountAllowed: PropTypes.any,
  anonymousPaymentRoutingNumberError: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  accountPaymentConfigId: state.makePaymentAnonymousSlice.paymentConfigId,
  activeStep: state.makePaymentAnonymousSlice.activeStep,
  amountToPay: state.makePaymentAnonymousSlice.amountToPay,
  billSelected: state.makePaymentAnonymousSlice.billSelected,
  customerAddress: getAddress(
    state.makePaymentAnonymousSlice.billSelected.billingAccount.partyInfo[0].addresses,
  ),
  customerCity: getBillingCity(
    state.makePaymentAnonymousSlice.billSelected.billingAccount.partyInfo[0].addresses,
  ),
  firstName:
    state.makePaymentAnonymousSlice.billSelected.billingAccount.partyInfo[0].nameInfo.givenName,
  lastName:
    state.makePaymentAnonymousSlice.billSelected.billingAccount.partyInfo[0].nameInfo.surname,
  customerState: getBillingState(
    state.makePaymentAnonymousSlice.billSelected.billingAccount.partyInfo[0].addresses,
  ),
  customerZip: getBillingZip(
    state.makePaymentAnonymousSlice.billSelected.billingAccount.partyInfo[0].addresses,
  ),
  isLoading: state.makePaymentAnonymousSlice.isLoading,
  instrumentToPayWith: state.makePaymentStepTwoSlice.instrumentToPayWith,
  nextButtonClicked: state.makePaymentAnonymousSlice.nextButtonClicked,
  nextButtonDisabled: state.makePaymentAnonymousSlice.nextButtonDisabled,
  paymentTenantName: state.configurationSlice.features.paymentTenantName,
  paymentConfigId: state.configurationSlice.features.paymentConfigIds,
  paymentApiUrl: state.configurationSlice.features.paymentApiUrl,
  i18n: state.i18nSlice.i18n,
  radioAmountType: state.makePaymentAnonymousSlice.radioAmountType,
  anonymousPaymentRoutingNumberError:
    state.makePaymentAnonymousSlice.anonymousPaymentRoutingNumberError,
  schedulePaymentsAllowed: state.configurationSlice.features.features.scheduledPayments,
  otherAmountAllowed: state.configurationSlice.features.features.otherPaymentAmountAllowed,
});
export default connect(mapStateToProps)(Step3);
