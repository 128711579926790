import { createSlice } from '@reduxjs/toolkit';
// eslint-disable-next-line no-undef
// const CC = process.env.CC;
// eslint-disable-next-line no-undef
const { features } = require(`../../../config/config`);
const initialState = {
  isLoading: false,
  isServiceUnavailable: false,
  features: {
    template: '',
  },
  errors: null,
};

export const configurationSlice = createSlice({
  name: 'configuration',
  initialState,
  reducers: {
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setFeatures: state => {
      return { ...state, features };
    },
    setServiceUnavailable: (state, action) => {
      return { ...state, isServiceUnavailable: action.payload };
    },
  },
});
export const {
  setFeatures,
  setLoading,
  setErrors,
  setServiceUnavailable,
} = configurationSlice.actions;
export default configurationSlice.reducer;
