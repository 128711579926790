import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { useSnackbar } from 'notistack';
import { checkFileSize } from '../../../../utils/file-utils';
import { flattenDeep } from 'lodash';
import { Helmet } from 'react-helmet';
import { useDropzone } from 'react-dropzone';

import {
  AnonymousReportClaimEventHeader,
  AnonymousReportClaimStepFiveContainer,
  GarbageIcon,
  GarbageIconContainer,
  img,
  IndividualQuestionContainer,
  LossEventMessage,
  QuestionsContainer,
  QuestionTypeHeader,
  SelectFileImage,
  StyledDropZone,
  StyledSection,
  thumb,
  thumbInner,
  thumbsContainer,
} from './styles';
import Loader from '../../../common/loader';
import {
  ReportClaimButton,
  ReportClaimNextButton,
  ReportClaimButtonContainer,
} from '../step1/styles';
import { uploadFilesAndReview } from './anonymousReportClaimFiveApi';
import { ReportClaimCancelButton } from '../styles';
import { useHistory } from 'react-router';
import { handleQuestionsPreviouslyAnswered } from '../../../../utils/state';
import { getText } from '../../../../utils/i18n';
import {
  getQuestionInputType,
  mapSubQuestionFunctions,
} from '../../report-claim/report-claim-step-four/utils';
import { requiredQuestionsCompleteCheck } from '../../report-claim/report-claim-button-container/utils';

export const Step5 = (props) => {
  const {
    claimEvent,
    reportClaimDetailsSection,
    dispatch,
    handleNext,
    handleStep,
    i18n,
    maxUploadFileSize,
    questionReplies,
    registrationToken,
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  let mapReplies = handleQuestionsPreviouslyAnswered(questionReplies, reportClaimDetailsSection);
  let mappedSubQuestions = mapSubQuestionFunctions(mapReplies);
  const [question, setQuestion] = React.useState(mappedSubQuestions);
  const [files, setFiles] = React.useState([]);
  const history = useHistory();
  const getListOptions = (stringList) => {
    //stringList to array based on comma
    const newArray = stringList.split(',');
    return newArray.map((option) => {
      return { value: option, label: option };
    });
  };

  const setFilesForUpload = (fileNames, props) => {
    let goodFiles = checkFileSize(fileNames, maxUploadFileSize, enqueueSnackbar);
    if (goodFiles.length !== 0) {
      setFiles((prevState) =>
        flattenDeep([
          ...prevState,
          goodFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              key: file.name,
              id: props.attachment.id,
              templateId: props.attachment.templateId,
            }),
          ),
        ]),
      );
    }
  };

  let Previews = (props) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*,video/*,.pdf,.txt,.docx',
      onDrop: (acceptedFiles) => {
        setFilesForUpload(acceptedFiles, props);
      },
    });
    const thumbs = files.map((file, index) => {
      // eslint-disable-next-line react/prop-types
      if (props.attachment.id === file.id) {
        return (
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <img src={file.preview} style={img} alt="thumbnailForAnonymousReportClaimFile" />
              <GarbageIconContainer>
                <GarbageIcon
                  onClick={() => {
                    files.splice(index, 1);
                    setFiles([...files]);
                  }}
                />
              </GarbageIconContainer>
            </div>
          </div>
        );
      }
    });

    return (
      <StyledSection className="container">
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <SelectFileImage className="fa fa-image btn" /> Select File(s)
          <input {...getInputProps()} />
        </StyledDropZone>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </StyledSection>
    );
  };

  const areRequiredFieldsCompleted = (question) => {
    return !requiredQuestionsCompleteCheck(question);
  };
  const submitForReview = () => {
    dispatch(uploadFilesAndReview(files, question, props, registrationToken, enqueueSnackbar));
  };
  return (
    <AnonymousReportClaimStepFiveContainer>
      <Helmet>
        <title>Report Claim - Additional Loss Details</title>
      </Helmet>
      <AnonymousReportClaimEventHeader>{claimEvent.name}</AnonymousReportClaimEventHeader>
      <QuestionTypeHeader>Please provide details about the incident.</QuestionTypeHeader>
      {i18n?.general?.lossMessage ? (
        <LossEventMessage
          dangerouslySetInnerHTML={{
            __html: getText(i18n, 'general.lossMessage', ''),
          }}
        />
      ) : null}
      {question.length <= 0 && <Loader />}
      {question.map((event) => {
        return (
          <QuestionsContainer key={event.id}>
            <QuestionTypeHeader>{event.name}</QuestionTypeHeader>
            {event.questions.question.map((thisQuestion) => {
              return (
                <React.Fragment key={thisQuestion.id}>
                  {getQuestionInputType(
                    thisQuestion,
                    setQuestion,
                    getListOptions,
                    reportClaimDetailsSection,
                  )}
                </React.Fragment>
              );
            })}
            {event.attachments &&
              event.attachments.map((attachment) => {
                return (
                  <IndividualQuestionContainer key={attachment.id}>
                    <label>{attachment.description}</label>
                    <Previews attachment={attachment} />
                  </IndividualQuestionContainer>
                );
              })}
          </QuestionsContainer>
        );
      })}
      <ReportClaimButtonContainer>
        <ReportClaimCancelButton onClick={() => history.push('/')}>Cancel</ReportClaimCancelButton>
        <ReportClaimNextButton
          id="Next"
          disabled={areRequiredFieldsCompleted(question)}
          onClick={() => {
            submitForReview();
            handleNext();
          }}
        >
          Next
        </ReportClaimNextButton>
        <ReportClaimButton
          id="Previous"
          onClick={() => {
            handleStep();
          }}
        >
          Back
        </ReportClaimButton>
      </ReportClaimButtonContainer>
    </AnonymousReportClaimStepFiveContainer>
  );
};
Step5.propTypes = {
  claimEvent: PropTypes.shape({
    name: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  maxUploadFileSize: PropTypes.any,
  reportClaimDetailsSection: PropTypes.array,
  attachment: PropTypes.shape({
    id: PropTypes.string,
    templateId: PropTypes.string,
  }),
  handleNext: PropTypes.func,
  handleStep: PropTypes.func,
  i18n: PropTypes.any,
  questionReplies: PropTypes.any,
  state: PropTypes.object,
  registrationToken: PropTypes.string,
};
const mapStateToProps = (state) => ({
  claimEvent: state.anonymousReportClaimStepThreeSlice.claimEvent,
  maxUploadFileSize: state.configurationSlice.features.maxUploadFileSize,
  i18n: state.i18nSlice.i18n,
  questionReplies: state.anonymousReportClaimStepSixSlice.validationClaim.questionReplies,
  reportClaimDetailsSection:
    state.anonymousReportClaimStepFourSlice.updatedClaimEventTemplate.eventSections,
  registrationToken: state.loginSlice.registrationToken,
  state,
});
export default connect(mapStateToProps)(Step5);
