import IdCards from '../components/features/id-cards';
import AccountDetails from '../components/features/account-details';
import UserProfile from '../components/features/user-profile';
import PolicyDetails from '../components/features/policy-details';
import ClaimDetails from '../components/features/claim-details';
import NeedHelp from '../components/features/need-help';
import ReportClaim from '../components/features/report-claim';
import ChangeCoverage from '../components/features/change-coverage';
import MakeAPayment from '../components/features/make-payment';
import PendingRequests from '../components/features/pending-requests';
import OpenQuotes from '../components/features/open-quotes';
import CustomPage1 from '../components/features/custom-pages/custom-page-1';
import CustomPage2 from '../components/features/custom-pages/custom-page-2';
import CustomerInformation from '../components/features/customer-information';

export const routes = [
  {
    path: '/accountDetails',
    component: AccountDetails,
  },
  {
    path: '/idCards',
    component: IdCards,
  },
  {
    path: '/policyDetails',
    component: PolicyDetails,
  },
  {
    path: '/userProfile',
    component: UserProfile,
  },
  {
    path: '/claimDetails',
    component: ClaimDetails,
  },
  {
    path: '/needHelp',
    component: NeedHelp,
  },
  {
    path: '/reportClaim',
    component: ReportClaim,
  },
  {
    path: '/changeCoverage',
    component: ChangeCoverage,
  },
  {
    path: '/makePayment',
    component: MakeAPayment,
  },
  {
    path: '/pendingRequests',
    component: PendingRequests,
  },
  {
    path: '/openQuotes',
    component: OpenQuotes,
  },
  {
    path: '/customPage1',
    component: CustomPage1,
  },
  {
    path: '/customPage2',
    component: CustomPage2,
  },
  {
    path: '/customerInformation',
    component: CustomerInformation,
  },
];
export default routes;
