import { find } from 'lodash';

export const getBestWayToContact = state => {
  const contactPhone = find(
    state.reportClaimStepThreeSlice.claimEventContacts.contact.phoneInfo,
    phone => phone.preferredInd === true,
  );
  if (contactPhone) {
    return contactPhone;
  } else if (
    state.reportClaimStepThreeSlice.claimEventContacts.contact.emailInfo.preferredInd === true
  ) {
    return state.reportClaimStepThreeSlice.claimEventContacts.contact.emailInfo;
  }
  return {};
};
const getMailingAddress = addresses => {
  const mailing = addresses.find(element => element.addrTypeCd === 'InsuredMailingAddr');
  return mailing ? mailing : '';
};
export const getAddress = addresses => {
  const mailingAddress = getMailingAddress(addresses);
  return mailingAddress === '' ? '' : mailingAddress.addr1;
};
export const getMailingCity = addresses => {
  const mailingCity = getMailingAddress(addresses);
  return mailingCity === '' ? '' : mailingCity.city;
};
export const getMailingState = addresses => {
  const mailingState = getMailingAddress(addresses);
  return mailingState === '' ? '' : mailingState.stateProvCd;
};
export const getMailingZip = addresses => {
  const mailingZip = getMailingAddress(addresses);
  return mailingZip === '' ? '' : mailingZip.postalCode;
};
export const getHomePhone = (contact, temp) => {
  let homePhone = contact.find(element => element.phoneName === 'Home');
  return temp !== '' ? temp : homePhone ? homePhone.phoneNumber : '';
};
export const getMobilePhone = (contact, temp) => {
  const mobilePhone = contact.find(element => element.phoneName === 'Mobile');
  return temp !== '' ? temp : mobilePhone ? mobilePhone.phoneNumber : '';
};
export const getBusinessPhone = (contact, temp) => {
  const businessPhone = contact.find(element => element.phoneName === 'Business');
  return temp !== '' ? temp : businessPhone ? businessPhone.phoneNumber : '';
};

export const getOtherPhone = (contact, temp) => {
  const otherPhone = contact.find(element => element.phoneName === 'Other');
  return temp !== '' ? temp : otherPhone ? otherPhone.phoneNumber : '';
};
