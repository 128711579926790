import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { API } from '../../../../utils/api/index';
import { getText } from '../../../../utils/i18n';
import {
  CombinePolicesDashboardContainer,
  CombinePolicesTitle,
  CombinePolicesInteractiveContainer,
  CombinePolicesPromptLink,
  CombinePolicesText,
  PolicyNumberInput,
  CancelButtonColumn,
  CancelButton,
  SubmitButtonColumn,
  SubmitButton,
} from './styles';

const States = {
  INITIAL: 'initial',
  ENTER_POLICY: 'enterPolicy',
  SUCCESS: 'success',
  ERROR: 'error',
};

export const CombinePolicies = props => {
  const { customerId, i18n } = props;

  const [state, setState] = useState({
    currentState: States.INITIAL,
  });

  const [policyNumber, setPolicyNumber] = useState('');

  let submitTask = () => {
    // TODO: switch policyNumber as an additonal param when API change is in place.
    API.post('customers/' + customerId + '/tasks', {
      templateId: 'CustomerTask0015',
      text: 'Please move policy ' + policyNumber + ' to this customer',
    })
      .then(() => {
        setState({ currentState: States.SUCCESS });
      })
      .catch(() => {
        setState({ currentState: States.ERROR });
      })
      .finally(() => {
        setPolicyNumber('');
      });
  };

  switch (state.currentState) {
    case States.INITIAL:
      return (
        <>
          <CombinePolicesDashboardContainer>
          {i18n?.combinePolicies?.combinePoliciesTitle ? (
            <CombinePolicesTitle
              dangerouslySetInnerHTML={{
                __html: getText(i18n, 'combinePolicies.combinePoliciesTitle', ''),
              }}
            />
          ) : null}
            <CombinePolicesInteractiveContainer>
              <CombinePolicesPromptLink
                onClick={() => setState({ currentState: States.ENTER_POLICY })}
              >
                Add another policy to this account.
              </CombinePolicesPromptLink>
            </CombinePolicesInteractiveContainer>
          </CombinePolicesDashboardContainer>
        </>
      );
    case States.ENTER_POLICY:
      return (
        <>
          <CombinePolicesDashboardContainer>
          {i18n?.combinePolicies?.combinePoliciesTitle ? (
            <CombinePolicesTitle
              dangerouslySetInnerHTML={{
                __html: getText(i18n, 'combinePolicies.combinePoliciesTitle', ''),
              }}
            />
          ) : null}
          {i18n?.combinePolicies?.combinePoliciesTitle ? (
            <CombinePolicesText dangerouslySetInnerHTML={{
                __html: getText(i18n, 'combinePolicies.combinePoliciesMessage', ''),
              }} />
            ) : null}
            <CombinePolicesInteractiveContainer>
              <CombinePolicesText>Policy Number</CombinePolicesText>
              <PolicyNumberInput>
                <input value={policyNumber || ''} onChange={e => setPolicyNumber(e.target.value)} />
              </PolicyNumberInput>
              <CancelButtonColumn>
                <CancelButton
                  onClick={() => {
                    setState({ currentState: States.INITIAL });
                    setPolicyNumber('');
                  }}
                >
                  Cancel
                </CancelButton>
              </CancelButtonColumn>
              <SubmitButtonColumn>
                <SubmitButton disabled={policyNumber === ''} onClick={() => submitTask()}>
                  Add Policy
                </SubmitButton>
              </SubmitButtonColumn>
            </CombinePolicesInteractiveContainer>
          </CombinePolicesDashboardContainer>
        </>
      );
    case States.SUCCESS:
      return (
        <>
          <CombinePolicesDashboardContainer>
          {i18n?.combinePolicies?.successTitle ? (
            <CombinePolicesTitle
              dangerouslySetInnerHTML={{
                __html: getText(i18n, 'combinePolicies.successTitle', ''),
              }} />
          ) : null}
          {i18n?.combinePolicies?.successTitle ? (
            <CombinePolicesText
              dangerouslySetInnerHTML={{
                __html: getText(i18n, 'combinePolicies.successMessage', ''),
              }}
            />
          ) : null}
            <CombinePolicesInteractiveContainer>
              <CombinePolicesPromptLink
                onClick={() => {
                  setState({ currentState: States.ENTER_POLICY });
                  setPolicyNumber('');
                }}
              >
                Add another policy to this account.
              </CombinePolicesPromptLink>
            </CombinePolicesInteractiveContainer>
          </CombinePolicesDashboardContainer>
        </>
      );
    case States.ERROR:
      return (
        <>
          <CombinePolicesDashboardContainer>
            <CombinePolicesTitle>Oops!</CombinePolicesTitle>
            <CombinePolicesText>
              Something went wrong and your information was not sent.
            </CombinePolicesText>
            <CombinePolicesInteractiveContainer>
              <CancelButtonColumn>
                <CancelButton onClick={() => setState({ currentState: States.INITIAL })}>
                  Cancel
                </CancelButton>
              </CancelButtonColumn>
              <SubmitButtonColumn>
                <SubmitButton onClick={() => setState({ currentState: States.ENTER_POLICY })}>
                  Try Again
                </SubmitButton>
              </SubmitButtonColumn>
            </CombinePolicesInteractiveContainer>
          </CombinePolicesDashboardContainer>
        </>
      );
    default:
      return null;
  }
};

CombinePolicies.propTypes = {
  customerId: PropTypes.string,
  currentState: PropTypes.string,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  customerId: state.loginSlice.user.customer_id,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(CombinePolicies);
