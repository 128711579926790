import * as React from 'react';
import { connect } from 'react-redux';
import { Route, useRouteMatch } from 'react-router-dom';
import routes from '../../../../../routes/index';
import Billing from '../../billing/index';
import Policies from '../../policies/index';
import Claims from '../../claims/index';
import CombinePolicies from '../../combine-policies/index';
import GetQuote from '../../get-quote';
import MyAgent from '../../my-agent';

import PropTypes from 'prop-types';
import NewsBanners from '../../../news-banners';

export const Routes = (props) => {
  const { claimListAllowed, combinePoliciesAllowed, getQuoteAllowed } = props;
  return (
    <>
      {useRouteMatch('/dashboard') ? (
        <>
          <Billing />
          <Policies id="dashboardPolicies" />
          {claimListAllowed && <Claims />}
          {combinePoliciesAllowed && <CombinePolicies />}
          {props.isMobile ? (
            <>
              <NewsBanners />
              <MyAgent />
              {getQuoteAllowed && (
                <>
                  <GetQuote />
                </>
              )}
            </>
          ) : null}
        </>
      ) : null}
      {routes.map((route, i) => (
        <RouteWithSubRoutes key={i} {...route} />
      ))}
    </>
  );
};
const RouteWithSubRoutes = (route) => {
  return (
    <Route
      path={route.path}
      render={(props) => (
        // pass the sub-routes down to keep nesting
        <route.component {...props} routes={route.routes} />
      )}
    />
  );
};
Routes.propTypes = {
  getQuoteAllowed: PropTypes.bool,
  isMobile: PropTypes.bool,
  claimListAllowed: PropTypes.bool,
  combinePoliciesAllowed: PropTypes.bool,
};
export const mapStateToProps = (state) => ({
  claimListAllowed: state.configurationSlice.features.features.claimList,
  combinePoliciesAllowed: state.configurationSlice.features.features.combinePolicies,
  getQuoteAllowed: state.configurationSlice.features.features.cpPortalLinking,
});
export default connect(mapStateToProps)(Routes);
