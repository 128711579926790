import { combineReducers } from '@reduxjs/toolkit';

import billingSlice from '../components/features/dashboard/billing/billingReducer';
import claimsSlice from '../components/features/dashboard/claims/claimsReducer';
import configurationSlice from '../components/common/configuration/configurationReducer';
import customerSlice from '../components/features/customer/customerReducer';
import profileSlice from '../components/features/user-profile/profileReducer';
import idCardsSlice from '../components/features/id-cards/idCardsReducer';
import loginSlice from '../components/features/login/loginReducer';
import logoutSlice from '../components/features/logout/logoutReducer';
import makePaymentAnonymousSlice from '../components/features/make-payment-anonymous/makePaymentAnonymousReducer';
import makePaymentAnonymousStepOneSlice from '../components/features/make-payment-anonymous/step1/stepOneReducer';
import mfaSlice from '../components/features/enable-mfa/mfaReducer';
import payPlanSlice from '../components/features/account-details/payPlanReducer';
import policySlice from '../components/features/dashboard/policies/policyReducer';
import policyDetailsSlice from '../components/features/policy-details/policyDetailsReducer';
import agentSlice from '../components/features/dashboard/my-agent/myAgentReducer';
import claimDetailsSlice from '../components/features/claim-details/claimDetailsReducer';
import reportClaimSlice from '../components/features/report-claim/reportClaimReducer';
import reportClaimStepOneSlice from '../components/features/report-claim/report-claim-step-one/reportClaimStepOneReducer';
import reportClaimStepTwoSlice from '../components/features/report-claim/report-claim-step-two/reportClaimStepTwoReducer';
import reportClaimStepThreeSlice from '../components/features/report-claim/report-claim-step-three/reportClaimStepThreeReducer';
import reportClaimStepFourSlice from '../components/features/report-claim/report-claim-step-four/reportClaimStepFourReducer';
import reportClaimStepFiveSlice from '../components/features/report-claim/report-claim-step-five-review-submit/reportClaimStepFiveReducer';
import policyChangeSlice from '../components/features/change-coverage/policyChangeReducer';
import makePaymentSlice from '../components/features/make-payment/makePaymentReducer';
import makePaymentStepTwoSlice from '../components/features/make-payment/step-two-amount/makePaymentStepTwoReducer';
import makePaymentStepFourSlice from '../components/features/make-payment/step-four-recap/makePaymentStepFourReducer';
import notificationSlice from '../components/features/user-profile/notificationReducer';
import pendingRequestsSlice from '../components/features/pending-requests/pendingRequestsReducer';
import openQuotesSlice from '../components/features/open-quotes/openQuotesReducer';
import i18nSlice from '../components/common/i18n/i18nReducer';
import anonymousReportClaimStepOneSlice from '../components/features/report-claim-anonymous/step1/anonymousReportClaimStepOneReducer';
import anonymousReportClaimStepTwoSlice from '../components/features/report-claim-anonymous/step2/anonymousReportClaimStepTwoReducer';
import anonymousReportClaimStepThreeSlice from '../components/features/report-claim-anonymous/step3/anonymousReportClaimStepThreeReducer';
import anonymousReportClaimStepFourSlice from '../components/features/report-claim-anonymous/step4/anonymousReportClaimStepFourReducer';
import anonymousReportClaimStepFiveSlice from '../components/features/report-claim-anonymous/step5/anonymousReportClaimStepFiveReducer';
import anonymousReportClaimStepSixSlice from '../components/features/report-claim-anonymous/recap-and-submit/anonymouseReportClaimStepSixReducer';
import forgotUsernameSlice from '../components/features/forgot-username/forgotUsernameReducer';
import fileUploadSlice from '../components/features/file-upload/fileUploadReducer';
import newsBannersSlice from '../components/features/news-banners/newsBannersReducer';
import addressesSlice from '../components/common/addresses/addressesReducer';

const combinedReducers = combineReducers({
  agentSlice,
  billingSlice,
  claimsSlice,
  claimDetailsSlice,
  configurationSlice,
  customerSlice,
  idCardsSlice,
  loginSlice,
  logoutSlice,
  makePaymentAnonymousSlice,
  makePaymentAnonymousStepOneSlice,
  makePaymentSlice,
  makePaymentStepTwoSlice,
  makePaymentStepFourSlice,
  mfaSlice,
  notificationSlice,
  payPlanSlice,
  policySlice,
  profileSlice,
  policyDetailsSlice,
  reportClaimSlice,
  reportClaimStepOneSlice,
  reportClaimStepTwoSlice,
  reportClaimStepThreeSlice,
  reportClaimStepFourSlice,
  reportClaimStepFiveSlice,
  policyChangeSlice,
  pendingRequestsSlice,
  openQuotesSlice,
  i18nSlice,
  anonymousReportClaimStepOneSlice,
  anonymousReportClaimStepTwoSlice,
  anonymousReportClaimStepThreeSlice,
  anonymousReportClaimStepFourSlice,
  anonymousReportClaimStepFiveSlice,
  anonymousReportClaimStepSixSlice,
  forgotUsernameSlice,
  fileUploadSlice,
  newsBannersSlice,
  addressesSlice,
});
export const rootReducer = (state, action) => {
  if (action.type === 'logout/logUserOut') {
    // eslint-disable-next-line no-param-reassign
    state = undefined;
  }
  return combinedReducers(state, action);
};

export default rootReducer;
