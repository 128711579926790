import React from 'react';

import PropTypes from 'prop-types';
import { ClaimDetailsLabel, ClaimDetailsValue } from './styles';

const ClaimDetailsRow = (props) => {
  const { label, value, value2 } = props;
  return (
    <>
      <ClaimDetailsLabel id={label}>{label}:</ClaimDetailsLabel>
      <ClaimDetailsValue id={label}>
        {value} {value2 && <ClaimDetailsValue>{value2}</ClaimDetailsValue>}
      </ClaimDetailsValue>
    </>
  );
};
ClaimDetailsRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  value2: PropTypes.string,
};
export default ClaimDetailsRow;
