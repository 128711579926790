import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { findIndex } from 'lodash';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';

import {
  addPayment,
  getBillingAddress,
  makeSDK,
  getFirstName,
  getLastName,
} from '../../make-payment/step-two-amount/utils';
import ACHPayContainer from '../../make-payment/step-two-amount/ach';
import { getPaymentCategory } from '../../../../utils/payment/index';

import {
  ACHCard,
  ACHInfo,
  AddNewBankAccount,
  AddNewCard,
  AddNewPaymentType,
  AddPaymentInfo,
  CreditCard,
  CreditInfo,
  RadioChoice,
  StyledACHIcon,
  StyledAddIcon,
  StyledButtonNextDeleteCard,
  StyledCreditIcon,
  StyledDeleteButton,
  StyledDeleteIcon,
  StyledDialogActions,
  StyledDialogSpan,
  StyledHorizontalLine,
  StyledModal,
  StyledModalACHIcon,
  StyledModalCreditIcon,
  StyledTextSpan,
} from './styles';

import { deleteFundingInstrument } from '../../make-payment/step-two-amount/makePaymentStepTwoApi';
import { setPaymentInstrument } from '../../make-payment/step-two-amount/makePaymentStepTwoReducer';

export const FundingInstruments = (props) => {
  const {
    accountId,
    accountPaymentConfigId,
    dispatch,
    existingFundingInstruments,
    instrumentToPayWith,
    firstName,
    lastName,
    customerAddress,
    paymentApiUrl,
    paymentMethods,
    paymentTenantName,
  } = props;
  const [open, setOpen] = useState(false);
  const [achContainerOpen, setACHContainerOpen] = useState(false);
  const [addPaymentOpen, setAddPaymentOpen] = useState(false);
  const [fundingInstruments, setFundingInstruments] = useState(existingFundingInstruments);
  const [instrumentToDelete, setInstrumentToDelete] = useState();
  const { enqueueSnackbar } = useSnackbar();
  const [paymentCategory, setPaymentCategory] = useState();

  useEffect(() => {
    let payCat = getPaymentCategory(paymentMethods, setPaymentCategory);
    if (window.GuidewirePayments.createSDK) {
      if (paymentCategory !== 'ACHFormOnly') {
        // no need to load iframe if classic ach form
        window.GuidewirePayments = makeSDK(
          firstName,
          lastName,
          customerAddress,
          paymentTenantName,
          accountPaymentConfigId,
          paymentApiUrl,
          payCat,
        );
      }
    }

    if (instrumentToPayWith) {
      setFundingInstruments([instrumentToPayWith]);
    }
  }, []);

  const deleteInstrument = (instrument) => {
    if (instrument.isTemporary) {
      let indexToRemove = findIndex(fundingInstruments, (current) => {
        if (instrument.sourceType === 'CreditCard') {
          return current?.id === instrument.id;
        } else {
          return current?.achBankAccountNumber === instrument.achBankAccountNumber;
        }
      });
      if (instrument.id === instrumentToPayWith.id) {
        dispatch(setPaymentInstrument(null));
      }
      let cloned = cloneDeep(fundingInstruments);
      cloned.splice(indexToRemove, 1);
      setFundingInstruments(cloned);
    } else {
      dispatch(
        deleteFundingInstrument(accountId, instrument.id, enqueueSnackbar, setFundingInstruments),
      );
      setFundingInstruments(fundingInstruments.filter((e) => e.id !== instrument.id));
    }
    setOpen(false);
  };

  const handleAddPaymentClose = () => {
    setAddPaymentOpen(false);
    setACHContainerOpen(false);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  const openDeleteDialog = (instrument) => {
    setOpen(true);
    setInstrumentToDelete(instrument);
  };

  const setACHTopLevel = (fundingInstrument) => {
    setFundingInstruments(
      fundingInstruments ? [...fundingInstruments, fundingInstrument] : [fundingInstrument],
    );
    dispatch(setPaymentInstrument(fundingInstrument));
    setAddPaymentOpen(false);
    setACHContainerOpen(false);
  };
  const addACH = () => {
    setACHContainerOpen(true);
  };
  return (
    <>
      {fundingInstruments &&
        fundingInstruments.map((instrument, index) => {
          if (instrument) {
            if (
              (instrument.methodCd === 'Credit Card' || instrument.sourceType === 'CreditCard') &&
              paymentMethods.includes('CC')
            ) {
              return (
                <label htmlFor={index} key={instrument.id}>
                  <CreditCard key={instrument.id}>
                    <RadioChoice
                      onChange={() => {
                        dispatch(setPaymentInstrument(instrument));
                      }}
                      value={instrument}
                      id={index}
                      checked={
                        instrumentToPayWith ? instrumentToPayWith.id === instrument.id : false
                      }
                    />
                    <StyledCreditIcon />
                    <CreditInfo>
                      {instrument.methodCd} {instrument.cardType && instrument.cardType} -{' '}
                      {instrument.creditCardNumber} {instrument.lastFour && instrument.lastFour}
                    </CreditInfo>
                    {(instrument.isTemporary || instrument.action === 'None') && (
                      <>
                        <StyledDeleteIcon
                          id={index + 'deleteIcon'}
                          onClick={() => {
                            openDeleteDialog(instrument);
                          }}
                        />
                        <Dialog open={open} onClose={handleCloseDialog}>
                          <StyledModal>
                            <DialogTitle id="alert-dialog-title">
                              {'Are you sure you want to Delete?'}
                            </DialogTitle>
                            <StyledDialogSpan>This action cannot be undone.</StyledDialogSpan>
                            <StyledDialogActions>
                              <StyledDeleteButton
                                onClick={() => deleteInstrument(instrumentToDelete)}
                              >
                                Yes
                              </StyledDeleteButton>
                              <StyledButtonNextDeleteCard onClick={handleCloseDialog}>
                                No
                              </StyledButtonNextDeleteCard>
                            </StyledDialogActions>
                          </StyledModal>
                        </Dialog>
                      </>
                    )}
                  </CreditCard>
                </label>
              );
            } else if (
              (instrument.methodCd === 'ACH' || instrument.sourceType === 'ACH') &&
              paymentMethods.includes('ACH')
            ) {
              return (
                <label
                  htmlFor={`ACH${index}`}
                  key={
                    instrument.achBankAccountNumber
                      ? instrument.achBankAccountNumber
                      : instrument.id
                      ? instrument.id
                      : `key${index}`
                  }
                >
                  <ACHCard key={instrument.achBankAccountNumber}>
                    <RadioChoice
                      onChange={() => {
                        dispatch(setPaymentInstrument(instrument));
                      }}
                      value={instrument}
                      id={`ACH${index}`}
                      checked={
                        instrumentToPayWith
                          ? instrumentToPayWith.achBankAccountNumber ===
                            instrument.achBankAccountNumber
                          : false
                      }
                    />
                    <StyledACHIcon />
                    <ACHInfo
                      aria-label={`Payment Type ${instrument?.achBankAccountTypeCd} -
                      ${
                        instrument?.achBankAccountNumber
                          ? instrument?.achBankAccountNumber?.slice(-4)
                          : ''
                      }`}
                    >
                      {instrument.achBankAccountTypeCd}{' '}
                      {instrument.achBankAccountNumber ? '-' : undefined}{' '}
                      {instrument.achBankAccountNumber
                        ? instrument.achBankAccountNumber.slice(-4)
                        : ''}
                    </ACHInfo>
                    {(instrument.isTemporary || instrument.action === 'None') && (
                      <>
                        <StyledDeleteIcon
                          onClick={() => {
                            openDeleteDialog(instrument);
                          }}
                        />
                        <Dialog open={open} onClose={handleCloseDialog}>
                          <StyledModal>
                            <DialogTitle id="alert-dialog-title">
                              {'Are you sure you want to Delete?'}
                            </DialogTitle>
                            <StyledDialogSpan id="alert-dialog-description">
                              This action cannot be undone.
                            </StyledDialogSpan>
                            <StyledDialogActions>
                              <StyledDeleteButton
                                onClick={() => deleteInstrument(instrumentToDelete)}
                              >
                                Yes
                              </StyledDeleteButton>
                              <StyledButtonNextDeleteCard onClick={handleCloseDialog}>
                                No
                              </StyledButtonNextDeleteCard>
                            </StyledDialogActions>
                          </StyledModal>
                        </Dialog>
                      </>
                    )}
                  </ACHCard>
                </label>
              );
            }
          }
        })}
      <AddNewPaymentType
        id={'AddNewPaymentButton'}
        onClick={() => {
          if (paymentCategory === 'UserSelect' || paymentCategory === 'Echeck') {
            // if payCategory is user select or echeck we can go straight to loading iframe
            // otherwise we need to pop modal and check which pay methods to show.
            dispatch(addPayment(setFundingInstruments, fundingInstruments, false, enqueueSnackbar));
          } else {
            setAddPaymentOpen(true);
          }
        }}
      >
        <StyledAddIcon />
        <AddPaymentInfo>Add New Payment Type</AddPaymentInfo>
      </AddNewPaymentType>
      <Dialog open={addPaymentOpen} onClose={handleAddPaymentClose} maxWidth={false}>
        <DialogTitle id="alert-dialog-title">{'Select Payment Type'}</DialogTitle>
        <StyledHorizontalLine />
        <StyledModal>
          {paymentMethods.includes('CC') && (
            <AddNewCard
              id={'CreditCardPaymentType'}
              onClick={() => {
                setAddPaymentOpen(false);
                setACHContainerOpen(false);
                dispatch(
                  addPayment(setFundingInstruments, fundingInstruments, false, enqueueSnackbar),
                );
              }}
            >
              <StyledModalCreditIcon />
              <StyledTextSpan>Credit Card</StyledTextSpan>
            </AddNewCard>
          )}
          {paymentMethods.includes('ACH') && (
            <AddNewBankAccount
              id={'ACHPaymentType'}
              onClick={() => {
                addACH();
              }}
            >
              <StyledModalACHIcon />
              <StyledTextSpan>Bank Account</StyledTextSpan>
            </AddNewBankAccount>
          )}
          {achContainerOpen ? (
            <ACHPayContainer setACHTopLevel={setACHTopLevel} isAnonymousPay={true} />
          ) : null}
        </StyledModal>
      </Dialog>
    </>
  );
};
FundingInstruments.propTypes = {
  accountId: PropTypes.string,
  accountPaymentConfigId: PropTypes.string,
  accountToPayWith: PropTypes.object,
  customerAddress: PropTypes.object,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dispatch: PropTypes.func,
  existingFundingInstruments: PropTypes.array,
  instrumentToPayWith: PropTypes.object,
  isLoading: PropTypes.bool,
  otherAmountAllowed: PropTypes.bool,
  paymentTenantName: PropTypes.string,
  paymentCategory: PropTypes.string,
  paymentApiUrl: PropTypes.string,
  paymentVendor: PropTypes.string,
  schedulePaymentsAllowed: PropTypes.any,
  screen: PropTypes.string,
  paymentMethods: PropTypes.array,
};

const mapStateToProps = (state) => ({
  state,
  accountPaymentConfigId: state.makePaymentAnonymousSlice.paymentConfigId,
  accountToPayWith: state.makePaymentSlice.accountToPayWith,
  accountId: state.makePaymentSlice?.accountToPayWith?.billingAccount?.systemId,
  otherAmountAllowed: state.configurationSlice.features.features.otherPaymentAmountAllowed,
  customerAddress: getBillingAddress(state.customerSlice.customer),
  firstName: getFirstName(state.makePaymentAnonymousSlice.billSelected.billingAccount),
  lastName: getLastName(state.makePaymentAnonymousSlice.billSelected.billingAccount),
  existingFundingInstruments: state.makePaymentStepTwoSlice.fundingInstruments,
  instrumentToPayWith: state.makePaymentStepTwoSlice.instrumentToPayWith,
  schedulePaymentsAllowed: state.configurationSlice.features.features.scheduledPayments,
  isLoading: state.makePaymentStepTwoSlice.isLoading,
  paymentTenantName: state.configurationSlice.features.paymentTenantName,
  paymentApiUrl: state.configurationSlice.features.paymentApiUrl,
  paymentMethods: state.configurationSlice.features.anonymousPaymentMethods,
});

export default connect(mapStateToProps)(FundingInstruments);
