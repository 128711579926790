import React from 'react';
import { ContactMessage, HelpContactLabel, HelpContactLink, IconAndLinkContainer } from './styles';
import PropTypes from 'prop-types';
import PhoneIcon from '@mui/icons-material/Phone';
import MailIcon from '@mui/icons-material/Mail';

const getContactLink = contact => {
  let contactLink = contact.value;
  if (contact.id.endsWith('Phone')) {
    contactLink = `tel:${contact.value}`;
  }
  if (contact.id.endsWith('Email')) {
    contactLink = `mailto:${contact.value}`;
  }
  return contactLink;
};
export const NeedHelpContactRow = props => {
  const contact = props.contact;
  return (
    <>
      <HelpContactLabel>
        <ContactMessage>
          {contact.id.includes('Phone')
            ? 'Call Us'
            : contact.id.includes('Email')
            ? 'Send us a message'
            : contact.name}
        </ContactMessage>
      </HelpContactLabel>
      <IconAndLinkContainer>
        {contact?.id?.endsWith('Phone') ? (
          <PhoneIcon />
        ) : contact?.id?.endsWith('Email') ? (
          <MailIcon />
        ) : null}
        {'   '}
        <HelpContactLink href={getContactLink(contact)}>{contact.value}</HelpContactLink>
      </IconAndLinkContainer>
    </>
  );
};

NeedHelpContactRow.propTypes = {
  contact: PropTypes.shape({
    name: PropTypes.string,
    value: PropTypes.string,
    id: PropTypes.string,
  }),
};
