import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uploadedFiles: null,
  errors: null,
  isFileAttachmentComplete: false,
  attachmentTemplates: [],
  attachmentTemplatesLoading: false,
  isAttachingFiles: false,
};

export const fileUploadSlice = createSlice({
  name: 'fileUploadSlice',
  initialState,
  reducers: {
    setUploadedFiles: (state, action) => {
      return { ...state, uploadedFiles: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setFileAttachmentComplete: (state, action) => {
      return { ...state, isFileAttachmentComplete: action.payload };
    },
    setAttachmentTemplates: (state, action) => {
      return { ...state, attachmentTemplates: action.payload };
    },
    setAttachmentTemplatesLoading: (state, action) => {
      return { ...state, attachmentTemplatesLoading: action.payload };
    },
    setAttachingFiles: (state, action) => {
      return { ...state, isAttachingFiles: action.payload };
    },
  },
});
export const {
  setUploadedFiles,
  setErrors,
  setFileAttachmentComplete,
  setAttachmentTemplates,
  setAttachmentTemplatesLoading,
  setAttachingFiles,
} = fileUploadSlice.actions;
export default fileUploadSlice.reducer;
