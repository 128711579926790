import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimEventTemplates: [],
  validationClaim: {},
  fileNames: [],
  claimEventValidationErrors: '',
  isClaimEventValidated: false,
  isFilesBeingAttached: false,
};
export const reportClaimStepFourSlice = createSlice({
  name: 'reportClaimStepFourSlice',
  initialState,
  reducers: {
    resetStepFourState: () => {
      return initialState;
    },
    setClaimEventTemplates: (state, action) => {
      return { ...state, claimEventTemplates: action.payload };
    },
    setValidateClaim: (state, action) => {
      return { ...state, validationClaim: action.payload };
    },
    setClaimEventValidated: (state, action) => {
      return { ...state, isClaimEventValidated: action.payload };
    },
    setClaimEventValidationErrors: (state, action) => {
      return { ...state, claimEventValidationErrors: action.payload };
    },
    setFilesBeingAttached: (state, action) => {
      return { ...state, isFilesBeingAttached: action.payload };
    },
  },
});
export const {
  resetStepFourState,
  setClaimEventTemplates,
  setClaimEventValidationErrors,
  setValidateClaim,
  setClaimEventValidated,
  setFilesBeingAttached,
} = reportClaimStepFourSlice.actions;
export default reportClaimStepFourSlice.reducer;
