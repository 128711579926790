import React from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';
import {
  EffectiveDateLabelMobile,
  ExpirationDateLabelMobile,
  HorizontalLine,
  PolicyContainer,
  PolicyDataContainer,
  PolicyDescriptionLabelMobile,
  PolicyLabelMobile,
  PolicyStatusContainer,
  PolicyStatusLabelMobile,
} from './style';
import PropTypes from 'prop-types';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';

const PolicyRow = props => {
  const {
    policyNumber,
    effectiveDt,
    expirationDt,
    systemId,
    statusCd,
    productName,
    policyDocumentUploadAllowed,
    insured,
    policyAddress,
  } = props;
  return (
    <PolicyDataContainer policyDocumentUpload={policyDocumentUploadAllowed}>
      <PolicyContainer>
        <PolicyLabelMobile>Policy Number</PolicyLabelMobile>
        <Link
          aria-label={`Policy Number ${policyNumber}`}
          to={{
            pathname: '/policyDetails',
            state: {
              policySystemId: systemId,
              effectiveDt,
              policyNumber,
              insured,
            },
          }}
        >
          {policyNumber}
          <br />
        </Link>
        <PolicyDescriptionLabelMobile aria-label={`Policy Type ${productName}`}>
          {productName}
          <br />
          {policyAddress}
        </PolicyDescriptionLabelMobile>
      </PolicyContainer>
      <PolicyContainer>
        <EffectiveDateLabelMobile>Effective Date</EffectiveDateLabelMobile>
        <div aria-label={`Effective Date ${moment(effectiveDt).format(DISPLAY_DATE_FORMAT)}`}>
          {moment(effectiveDt).format(DISPLAY_DATE_FORMAT)}
        </div>
      </PolicyContainer>
      <PolicyContainer>
        <ExpirationDateLabelMobile>Expiration Date</ExpirationDateLabelMobile>
        <div aria-label={`Expiration Date ${moment(expirationDt).format(DISPLAY_DATE_FORMAT)}`}>
          {moment(expirationDt).format(DISPLAY_DATE_FORMAT)}
        </div>
      </PolicyContainer>
      <PolicyStatusContainer>
        <PolicyStatusLabelMobile>Status</PolicyStatusLabelMobile>
        <div aria-label={`Status ${statusCd}`}>{statusCd}</div>
      </PolicyStatusContainer>
      {policyDocumentUploadAllowed && (
        <PolicyContainer>
          <PolicyLabelMobile>Documents</PolicyLabelMobile>
          <Link
            aria-label="Upload Documents"
            to={{
              pathname: '/policyDetails',
              state: {
                policySystemId: systemId,
                effectiveDt,
                policyNumber,
                insured,
                uploadDocumentsClicked: true,
              },
            }}
          >
            Upload Documents
          </Link>
        </PolicyContainer>
      )}
      <HorizontalLine />
    </PolicyDataContainer>
  );
};
PolicyRow.propTypes = {
  policyRef: PropTypes.string,
  policyNumber: PropTypes.string,
  effectiveDt: PropTypes.string,
  policyDocumentUploadAllowed: PropTypes.bool,
  expirationDt: PropTypes.string,
  statusCd: PropTypes.string,
  systemId: PropTypes.string,
  description: PropTypes.string,
  productName: PropTypes.string,
  displayDescription: PropTypes.string,
  insured: PropTypes.object,
  currentUploads: PropTypes.number,
  totalUploads: PropTypes.number,
  policyAddress: PropTypes.string,
};
export default PolicyRow;
