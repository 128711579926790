import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditingEmail from './edit-email';
import { setEmailErrors } from '../user-profile/profileReducer';
import { EmailAddressLabel, EmailContainer, EmailError, ProfileEmailsAddress } from './styles';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';

export const EmailDetails = props => {
  const {
    dispatch,
    emailAddress,
    editingEmailAndPhone,
    emailErrors,
    isDashboard,
    i18n,
    email,
    setEmail,
  } = props;

  React.useEffect(() => {
    dispatch(setEmailErrors(null));
  }, []);

  return (
    <>
      {emailErrors && <EmailError>{emailErrors}</EmailError>}

      <>
        <EmailContainer>
          <EmailAddressLabel>
            Email Address
            <TooltipInfo
              title={getText(i18n, 'dashboard.customerInformation.emailAddressTooltip')}
            />
          </EmailAddressLabel>
          {!editingEmailAndPhone && (
            <>
              <ProfileEmailsAddress>{emailAddress}</ProfileEmailsAddress>
            </>
          )}
          {editingEmailAndPhone ? (
            <EditingEmail isDashboard={isDashboard} email={email} setEmail={setEmail} />
          ) : null}
        </EmailContainer>
      </>
    </>
  );
};

EmailDetails.propTypes = {
  dispatch: PropTypes.func,
  emailAddress: PropTypes.string,
  emailErrors: PropTypes.any,
  editingEmailAndPhone: PropTypes.bool,
  isDashboard: PropTypes.bool,
  isLoading: PropTypes.bool,
  notificationEmail: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = state => ({
  isLoading: state.profileSlice.isLoading,
  emailErrors: state.profileSlice.emailErrors,
  editingEmailAndPhone: state.profileSlice.editingEmailAndPhone,
  emailAddress: state.profileSlice.emailAddress,
  i18n: state.i18nSlice.i18n,
});
EmailDetails.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(EmailDetails);
