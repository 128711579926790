// Viewport Sizing
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1280px
// xl, extra-large: 1920px
import List from '@mui/material/List';
import AppBar from '@mui/material/AppBar';
import IconButton from '@mui/material/IconButton';
import { styled as muistyled } from '@mui/material/styles';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

// Material UI styles for Mobile Menu
const PREFIX = 'mobile';
export const classes = {
  root: `${PREFIX}-root`,
  appBarRoot: `${PREFIX}-appBarRoot`,
  menuButton: `${PREFIX}-menuButton`,
  hide: `${PREFIX}-hide`,
  content: `${PREFIX}-content`,
  toolbar: `${PREFIX}-toolbar`,
};
export const Root = muistyled('div')(() => ({
  [`&.${classes.appBarRoot}`]: {
    flexGrow: 1,
  },
}));
export const DrawerButton = muistyled(IconButton)(({ theme }) => ({
  [`&.${classes.menuButton}`]: {
    marginRight: theme.spacing(1),
  },
  [`&.${classes.hide}`]: {
    display: 'none',
  },
}));
export const Main = muistyled('main')(({ theme }) => ({
  [`&.${classes.content}`]: {
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
}));
export const Toolbar = muistyled('div')(({ theme }) => ({
  [`&.${classes.toolbar}`]: theme.mixins.toolbar,
}));

export const MobileAppBar = styled(AppBar)`
  background-color: ${props => props.theme.primaryColor};
`;
export const MobileMenu = styled(List)`
  background-color: ${props => props.theme.primaryColor};
  color: white;
  width: auto;
  a {
    color: white;
  }
  svg {
    color: white;
  }
`;
export const ContentLink = styled.a.attrs({
  id: 'customContentLinkMobileTemplate1',
})`
  line-height: 1.8;
  text-decoration: underline;
  color: white;
  :hover {
    text-decoration: underline;
  }
`;

export const VerticalMenuLink = styled(Link)`
  line-height: 1.8;
  text-decoration: underline;
  color: #555;
  :hover {
    text-decoration: underline;
  }
`;
export const CustomHTML1LinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customPage1',
  to: '/customPage1',
})``;
export const CustomHTML2LinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customPage2',
  to: '/customPage2',
})``;
