import { some } from 'lodash';
import { API } from '../../../utils/api/index';
import { isEmail } from '../../../utils/validators/generalValidators';
import {
  setDeleteUserErrors,
  setEditingEmail,
  setEditingNotifications,
  setEditingPhone,
  setEmailErrors,
  setNotificationErrors,
  setGoPaperlessErrors,
  setLoading,
  setPhoneErrors,
  setEditingTimezone,
} from './profileReducer';
import { setCustomer } from '../customer/customerReducer';
import { setPolicies } from '../dashboard/policies/policyReducer';
import {
  setCustomerSubscriptions,
  setCustomerSubscriptionsFull,
  setIsSubscribedEmail,
  setIsSubscribedSMS,
  setNotificationEmail,
  setNotificationPhone,
  setTimezone,
} from './notificationReducer';
import { setPolicyPreference } from '../policy-details/policyDetailsReducer';

export const patchCustomerDetails = (customerId, body, phoneOrEmail) => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.patch('customers/' + customerId, body)
      .then(response => {
        dispatch(setCustomer(response.data));
        dispatch(setEditingEmail(false));
        dispatch(setEditingPhone(false));
        dispatch(setEmailErrors(null));
        dispatch(setPhoneErrors(null));
      })
      .catch(err => {
        if (phoneOrEmail === 'Phone') {
          dispatch(setPhoneErrors(err.response.data.message));
        } else {
          dispatch(setEmailErrors(err.response.data.message));
        }
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const saveCustomerEmail = (props, newEmailAddress) => {
  const { systemId, customer, customerParty, emailAddress } = props;
  if (isEmail(newEmailAddress)) {
    let body = {
      partyInfo: [
        {
          id: customerParty.id,
          emailInfo: {
            emailAddr: newEmailAddress,
          },
        },
      ],
      _revision: customer._revision,
    };
    if (newEmailAddress !== emailAddress) {
      props.dispatch(patchCustomerDetails(systemId, body, 'Email'));
    }
  } else {
    props.dispatch(setEmailErrors('Please enter a valid e-mail address.'));
  }
};

const patchPolicy = (policyRef, body, policies, dispatch) => {
  return API.patch('policies/' + policyRef, body).then(response => {
    // update policyReducer to the latest states
    const updatedPolicy = response.data;
    const updatedPolicies = [];
    for (let i = 0; i < policies.length; i++) {
      if (updatedPolicy.systemId === policies[i].policyRef) {
        let policy = {};
        policy.policyRef = updatedPolicy.systemId;
        policy.policyNumber = updatedPolicy.basicPolicy.policyNumber;
        policy.effectiveDt = updatedPolicy.basicPolicy.effectiveDt;
        policy.expirationDt = updatedPolicy.basicPolicy.expirationDt;
        policy.statusCd = updatedPolicy.basicPolicy.statusCd;
        policy.displayDescription = updatedPolicy.basicPolicy.displayDescription;
        policy.description = updatedPolicy.basicPolicy.description;
        policy.productName = policies[i].productName;
        policy.insured = updatedPolicy.insured;
        policy._revision = updatedPolicy._revision;
        updatedPolicies[i] = policy;
      } else {
        updatedPolicies[i] = policies[i];
      }
    }
    dispatch(setPolicies(updatedPolicies));
  });
};

export const updatePolicyDeliveryPreference = (policies, policy, preference, notify) => {
  return dispatch => {
    dispatch(setLoading(true));
    const body = {
      insured: {
        id: policy.insured.id,
        preferredDeliveryMethod: preference,
      },
      _revision: policy._revision,
    };

    if (policy._revision === null) {
      // retrieve policy revision, then patch it
      return API.get(`policies/${policy.policyRef}`)
        .then(response => {
          return response.data._revision;
        })
        .then(revision => {
          body._revision = revision;
          dispatch(setPolicyPreference(preference));
          notify(`Your policy preferences have been updated`, {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          });
          patchPolicy(policy.policyRef, body, policies, dispatch);
        })
        .catch(err => {
          dispatch(setGoPaperlessErrors(err.message));
        })
        .finally(() => dispatch(setLoading(false)));
    } else {
      patchPolicy(policy.policyRef, body, policies, dispatch)
        .then(() => {
          dispatch(setPolicyPreference(preference));
          notify(`Your policy preferences have been updated`, {
            variant: 'success',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          });
        })
        .catch(err => {
          dispatch(setGoPaperlessErrors(err.message));
        })
        .finally(() => dispatch(setLoading(false)));
    }
  };
};

export const deleteUser = (userId, deactivateUserOnly) => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.delete(`customerLogins/${userId}?deactivateOnly=${deactivateUserOnly}`)
      .then(() => {})
      .catch(err => {
        dispatch(setDeleteUserErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const updatePassword = (
  userId,
  payload,
  notify,
  setPassword,
  setCurrentPassword,
  setConfirmPassword,
) => {
  return () => {
    return API.put(`/customerLogins/${userId}/password`, payload)
      .then(() => {
        notify('Success. Your password has been changed', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
        setPassword('');
        setCurrentPassword('');
        setConfirmPassword('');
      })
      .catch(() => {
        notify('Error. Your password cannot be changed right now', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      })
      .finally(() => {});
  };
};
export const getCustomerSubscriptions = userId => {
  return dispatch => {
    return API.get(`/customerLogins/${userId}/subscriptions`)
      .then(response => {
        dispatch(
          setCustomerSubscriptions(
            response.data.customerSubscriptions
              ? response.data.customerSubscriptions.customerSubscription
                ? response.data.customerSubscriptions.customerSubscription
                : null
              : response.data.customerSubscription
              ? response.data.customerSubscription
              : null,
          ),
        );
        dispatch(
          setCustomerSubscriptionsFull(
            response.data.customerSubscriptions
              ? response.data.customerSubscriptions
              : response.data
              ? response.data
              : null,
          ),
        );
        let filteredEmail = response.data.customerSubscription.filter(
          sub => sub.typeCd === 'Email',
        );
        let filteredSMS = response.data.customerSubscription.filter(sub => sub.typeCd === 'SMS');
        dispatch(setIsSubscribedEmail(some(filteredEmail, ['subscribedInd', true])));
        dispatch(setIsSubscribedSMS(some(filteredSMS, ['subscribedInd', true])));
        dispatch(
          setNotificationEmail(response.data.emailAddress ? response.data.emailAddress : ''),
        );

        dispatch(setNotificationPhone(response.data.phoneNumber ? response.data.phoneNumber : ''));
        dispatch(
          setTimezone(
            response.data.timezone
              ? response.data.timezone
              : Intl.DateTimeFormat().resolvedOptions().timeZone,
          ),
        );
      })
      .catch(error => {
        dispatch(setNotificationErrors(error));
      })
      .finally(() => {
        dispatch(setLoading(false));
      });
  };
};
const getSaveNotificationEmailBody = (customerSubscriptionFull, emailAddress) => {
  return {
    customerSubscription: customerSubscriptionFull.customerSubscription,
    emailAddress,
  };
};
export const saveNotificationEmail = (loginId, customerSubscriptionFull, emailAddress, notify) => {
  return dispatch => {
    return API.post(
      `/customerLogins/${loginId}/subscriptions`,
      getSaveNotificationEmailBody(customerSubscriptionFull, emailAddress),
    )
      .then(() => {
        dispatch(setEditingEmail(false));
        dispatch(getCustomerSubscriptions(loginId));
      })
      .catch(error => {
        notify(
          `The email you have entered cannot be saved. ${
            error?.response?.data?.message ? error.response.data.message : ''
          } Please try again later or contact your agent for assistance.`,
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      });
  };
};
const getSaveNotificationBodyPhone = (customerSubscriptionFull, phoneNumber) => {
  return {
    customerSubscription: customerSubscriptionFull.customerSubscription,
    phoneNumber,
  };
};
export const saveNotificationPhone = (loginId, customerSubscriptionFull, phone, notify) => {
  return dispatch => {
    return API.post(
      `/customerLogins/${loginId}/subscriptions`,
      getSaveNotificationBodyPhone(customerSubscriptionFull, phone),
    )
      .then(() => {
        dispatch(setEditingPhone(false));
        dispatch(getCustomerSubscriptions(loginId));
      })
      .catch(error => {
        notify(
          `The number you have entered cannot be saved. ${
            error?.response?.data?.message ? error.response.data.message : ''
          } Please try again later or contact your agent for assistance.`,
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      });
  };
};
export const saveNotificationPreferences = (loginId, customerSubscriptionsFull, notify) => {
  return dispatch => {
    return API.post(`/customerLogins/${loginId}/subscriptions`, customerSubscriptionsFull)
      .then(() => {
        dispatch(setEditingNotifications(false));
        dispatch(getCustomerSubscriptions(loginId));
      })
      .catch(error => {
        notify(
          `The notifications you have selected cannot be saved. ${
            error?.response?.data?.message ? error.response.data.message : ''
          } Please try again later or contact your agent for assistance.`,
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      });
  };
};
const getSaveTimezoneBody = timezone => {
  return {
    timezone,
  };
};
export const saveTimeZone = (userId, timezone, notify) => {
  return dispatch => {
    return API.post(`/customerLogins/${userId}/subscriptions`, getSaveTimezoneBody(timezone))
      .then(() => {
        dispatch(setEditingTimezone(false));
        dispatch(getCustomerSubscriptions(userId));
      })
      .catch(error => {
        notify(
          `The timezone you have selected cannot be saved. ${
            error?.response?.data?.message ? error.response.data.message : ''
          } Please try again later or contact your agent for assistance.`,
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      });
  };
};
