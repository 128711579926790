import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LoaderMargin } from '../../id-cards/styles';
import Loader from '../../../common/loader';
import { Helmet } from 'react-helmet';
import { findIndex } from 'lodash';
import { getClaimEventTemplateById } from '../reportClaimApi';
import {
  ClaimEventNameLabel,
  LocationEvent,
  LocationFieldsContainer,
  ReportClaimCityStateContainer,
  ReportClaimDriverNameAndLicenseContainer,
  ReportClaimsAddressTitleHeader,
  ReportClaimsCountryTitleHeader,
  ReportClaimsFieldHeader,
  ReportClaimsStepTwoContainer,
  ReportClaimsStepTwoSubHeader,
  ReportClaimStepTwoField,
  ReportClaimStepTwoVehicleAndDriverContainer,
  ReportClaimsTitleHeader,
  ReportClaimTwoFormContainerFullRow,
  ReportClaimZipCountyContainer,
  RequiredField,
  StateList,
  StateSelectComponent,
  ZipErrorMessage,
} from './styles';
import {
  ErrorMessage,
  ReportClaimsAddressTextInputField,
  ReportClaimsTextAreaField,
  ReportClaimsTextInputField,
  ReportClaimsZipInput,
} from '../styles';
import Select from 'react-select';
import { US_STATES } from '../../../common/constants';
import {
  setAddrTypeCd,
  setCity,
  setCountryNonUs,
  setDescription,
  setDriverNameManual,
  setId,
  setIsNonUSAddress,
  setLicenseNumberManual,
  setLocation1,
  setLocation2,
  setLocation3,
  setLocation4,
  setSelectedDriver,
  setSelectedDriverRelationship,
  setSelectedLocation,
  setSelectedVehicle,
  setState,
  setZip,
} from './reportClaimStepTwoReducer';

import { getDrivers, getLocations, getVehicles } from './selectors';
import ApiError from '../../../common/api-error';
import Addresses from '../../../common/addresses';
import { defaultLookupAddress, getStateByCode } from '../../../common/addresses/addressUtils';
import { setLookupAddress } from '../../../common/addresses/addressesReducer';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import ReportClaimButtons from '../report-claim-button-container';

export const ReportClaimStepTwo = props => {
  const {
    address,
    activeStep,
    customerId,
    claimEvent,
    countryNonUs,
    descriptionStored,
    dispatch,
    drivers,
    driverRelationshipOptions,
    driverRelationshipSelected,
    errors,
    locations,
    isClaimEventLoading,
    isNonUSAddress,
    manualDriverName,
    manualLicenseNumber,
    handleNext,
    handleStep,
    selectedClaimEventTemplate,
    claimEventContactsErrors,
    selectedLocation,
    selectedDriver,
    selectedVehicle,
    vehicles,
    dateValue,
    timeValue,
    i18n,
  } = props;
  let [location1Text, setLocation1Text] = React.useState(address?.addr1 ? address.addr1 : '');
  let [location2Text, setLocation2Text] = React.useState(address?.addr2 ? address.addr2 : '');
  let [location3Text, setLocation3Text] = React.useState(address?.addr3 ? address.addr3 : '');
  let [location4Text, setLocation4Text] = React.useState(address?.addr4 ? address.addr4 : '');
  let [cityText, setCityText] = React.useState(address?.city ? address.city : '');
  let [countyText, setCountyText] = React.useState('');
  let [zipText, setZipText] = React.useState(address?.postalCode ? address.postalCode : '');
  let [descriptionText, setDescriptionText] = React.useState(
    descriptionStored ? descriptionStored : '',
  );
  let [countryText, setCountryText] = React.useState(countryNonUs);
  let [stateText, setStateText] = React.useState({ label: 'Alabama', value: 'AL' });
  let [nonUSStateOrProvinceText, setNonUSStateOrProvinceText] = React.useState(
    address.stateProvCd ? address.stateProvCd : '',
  );

  const setFinalLocation = () => {
    dispatch(setLocation1(location1Text));
    dispatch(setLocation2(location2Text));
    dispatch(setLocation3(location3Text));
    dispatch(setLocation4(location4Text));
    dispatch(setCity(cityText));
    dispatch(setState(isNonUSAddress ? nonUSStateOrProvinceText : stateText.value));
    dispatch(setZip(zipText));
    dispatch(setDescription(descriptionText));
    dispatch(setCountryNonUs(countryText));
    dispatch(setSelectedDriverRelationship(driverRelationshipSelected?.value));
    if (selectedLocation.riskAddress) {
      dispatch(setAddrTypeCd(selectedLocation.riskAddress.addrTypeCd));
      dispatch(setId(selectedLocation.riskAddress.id));
    } else {
      dispatch(setAddrTypeCd(selectedLocation.typeCd));
      dispatch(setId(selectedLocation.id));
    }
  };

  const isNonUSCheckboxDisabled = () => {
    if (selectedClaimEventTemplate.view === 'Vehicle') {
      return false;
    }
    return !selectedLocation.id || selectedLocation.riskAddress;
  };

  const fillAddressFields = selectedAddress => {
    const getStateTextFromList = stateCd => {
      let index = findIndex(US_STATES, state => state.value === stateCd);
      return US_STATES[index];
    };
    if (selectedAddress.riskAddress) {
      setLocation1Text(selectedAddress.riskAddress.addr1 ? selectedAddress.riskAddress.addr1 : '');
      setLocation2Text(selectedAddress.riskAddress.addr2 ? selectedAddress.riskAddress.addr2 : '');
      setLocation3Text(selectedAddress.riskAddress.addr3 ? selectedAddress.riskAddress.addr3 : '');
      setLocation4Text(selectedAddress.riskAddress.addr4 ? selectedAddress.riskAddress.addr4 : '');
      setCityText(selectedAddress.riskAddress.city ? selectedAddress.riskAddress.city : '');
      setCountyText(selectedAddress.riskAddress.county ? selectedAddress.riskAddress.county : '');
      setCountryText(
        selectedAddress.riskAddress.regionCd ? selectedAddress.riskAddress.regionCd : '',
      );
      setZipText(
        selectedAddress.riskAddress.postalCode ? selectedAddress.riskAddress.postalCode : '',
      );
      setStateText(
        selectedAddress.riskAddress.stateProvCd
          ? getStateTextFromList(selectedAddress.riskAddress.stateProvCd)
          : '',
      );
    } else {
      setLocation1Text(address?.addr1 ? address.addr1 : '');
      setLocation2Text(address?.addr2 ? address.addr2 : '');
      setLocation3Text(address?.addr3 ? address.addr3 : '');
      setLocation4Text(address?.addr4 ? address.addr4 : '');
      setCityText(address?.city ? address.city : '');
      setCountyText(address?.county ? address.county : '');
      setZipText(address?.postalCode ? address.postalCode : '');
      setCountryText(countryNonUs ? countryNonUs : '');
    }
  };

  useEffect(() => {
    if (selectedClaimEventTemplate) {
      props.dispatch(
        getClaimEventTemplateById(
          selectedClaimEventTemplate.id,
          customerId,
          dateValue,
          timeValue,
          selectedClaimEventTemplate.view === 'Location' ? 'RiskLocation' : 'VehicleAndDrivers',
        ),
      );
    }
    if (selectedLocation) {
      fillAddressFields(selectedLocation);
    }
    props.dispatch(setLookupAddress(defaultLookupAddress));
  }, []);

  const onLocationSelectionChange = selectedLocationOption => {
    if (selectedLocationOption.id !== 'Unscheduled Location') {
      props.dispatch(setIsNonUSAddress(false));
    }
    if (selectedLocationOption) {
      props.dispatch(setSelectedLocation(selectedLocationOption));
      fillAddressFields(selectedLocationOption);
    }
    props.dispatch(setLookupAddress(defaultLookupAddress));
  };

  const zipErrorMessage = zipText.length !== 0 && zipText.length < 5 ? 'Invalid Entry' : '';

  const onVehicleSelectionChange = selectedVehicleOption => {
    if (selectedVehicleOption) {
      props.dispatch(setSelectedVehicle(selectedVehicleOption));
    }
  };

  const onDriverSelectionChange = selectedVehicleOption => {
    if (selectedVehicleOption) {
      props.dispatch(setSelectedDriver(selectedVehicleOption));
    }
  };
  const onDriverRelationshipSelectionChange = selectedRelationshipType => {
    if (selectedRelationshipType) {
      props.dispatch(setSelectedDriverRelationship(selectedRelationshipType));
    }
  };

  const checkIfVehicleOrUnscheduled = () => {
    if (selectedClaimEventTemplate.view === 'Vehicle') {
      //if vehicle all address stuff should be addable
      return false;
    }
    return !(
      (selectedLocation && selectedLocation.value === 'Unscheduled Location') ||
      (selectedLocation && selectedLocation.value === 'Other Location')
    );
  };

  const handlePlaceSelect = address => {
    const { addr1, addr2, city, stateProvCd, postalCode } = address;
    setLocation1Text(addr1);
    setLocation2Text(addr2);
    setCityText(city);
    setZipText(postalCode);
    setStateText(getStateByCode(stateProvCd));
  };

  return (
    <>
      <Helmet>
        <title>Report Claim - Loss Description</title>
      </Helmet>
      {isClaimEventLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : errors ? (
        <ApiError body="Unable to get contact information." />
      ) : (
        <>
          <ReportClaimsStepTwoContainer>
            {!!claimEventContactsErrors && <ErrorMessage>{claimEventContactsErrors}</ErrorMessage>}
            <ClaimEventNameLabel>{claimEvent && claimEvent}</ClaimEventNameLabel>
            <ReportClaimsStepTwoSubHeader>Add the location details.</ReportClaimsStepTwoSubHeader>
            {selectedClaimEventTemplate.view === 'Vehicle' ? (
              <ReportClaimStepTwoVehicleAndDriverContainer>
                <div>
                  <h3>
                    Vehicle Information <RequiredField>*</RequiredField>
                  </h3>
                  <Select
                    name="vehicleList"
                    id="vehicleList"
                    options={vehicles}
                    onChange={onVehicleSelectionChange.bind(this)}
                  />
                </div>
                <div>
                  <h3>
                    Driver Information <RequiredField>*</RequiredField>
                  </h3>
                  <Select
                    name="driverList"
                    id="driverList"
                    options={drivers}
                    onChange={onDriverSelectionChange.bind(this)}
                  />
                </div>
              </ReportClaimStepTwoVehicleAndDriverContainer>
            ) : null}
            {selectedClaimEventTemplate.view === 'Vehicle' &&
            (selectedDriver.value === 'NonPermissiveUse' ||
              selectedDriver.value === 'PermissiveUse') ? (
              <ReportClaimDriverNameAndLicenseContainer>
                <div>
                  <ReportClaimsTitleHeader htmlFor="driverName">
                    Driver Name <RequiredField>*</RequiredField>
                  </ReportClaimsTitleHeader>
                  <ReportClaimsTextInputField
                    name="driversName"
                    value={manualDriverName}
                    onChange={e => props.dispatch(setDriverNameManual(e.target.value))}
                  />
                </div>
                <div>
                  <ReportClaimsTitleHeader htmlFor="licenseNumber">
                    License Number
                  </ReportClaimsTitleHeader>
                  <ReportClaimsTextInputField
                    name="licenseNumber"
                    value={manualLicenseNumber}
                    onChange={e => props.dispatch(setLicenseNumberManual(e.target.value))}
                  />
                </div>
                <div>
                  <ReportClaimsTitleHeader htmlFor="driverRelationshipList">
                    Driver Relationship To Insured <RequiredField>*</RequiredField>
                  </ReportClaimsTitleHeader>
                  <Select
                    name="driverRelationshipList"
                    id="driverRelationshipList"
                    options={driverRelationshipOptions}
                    value={driverRelationshipSelected}
                    onChange={onDriverRelationshipSelectionChange.bind(this)}
                  />
                </div>
              </ReportClaimDriverNameAndLicenseContainer>
            ) : null}

            {selectedClaimEventTemplate.view === 'Location' ? (
              <>
                <ReportClaimsTitleHeader htmlFor="locationSelection">
                  Location Event <RequiredField>*</RequiredField>{' '}
                  <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.lossEventTooltip')} />
                </ReportClaimsTitleHeader>
                <LocationEvent
                  aria-label="Location Event"
                  name="locationList"
                  id="locationList"
                  options={locations}
                  defaultValue={
                    selectedLocation
                      ? locations.find(location => location.value === selectedLocation.value)
                      : null
                  }
                  onChange={onLocationSelectionChange.bind(this)}
                />
              </>
            ) : null}
            <ReportClaimStepTwoField>
              <ReportClaimsTitleHeader htmlFor="nonUSAddressCheckBox">
                Non-U.S. Address{' '}
                <input
                  type="checkbox"
                  aria-label="Non US Address"
                  disabled={isNonUSCheckboxDisabled()}
                  name="nonUSAddressCheckBox"
                  checked={isNonUSAddress}
                  defaultValue={isNonUSAddress}
                  onChange={() => props.dispatch(setIsNonUSAddress(!isNonUSAddress))}
                />
                <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.nonUSAddressTooltip')} />
              </ReportClaimsTitleHeader>
            </ReportClaimStepTwoField>
            <LocationFieldsContainer>
              {isNonUSAddress && (
                <ReportClaimTwoFormContainerFullRow>
                  <ReportClaimsCountryTitleHeader htmlFor="country">
                    Country <RequiredField>*</RequiredField>
                    <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.countryTooltip')} />
                  </ReportClaimsCountryTitleHeader>
                  <ReportClaimsTextInputField
                    name="country"
                    value={countryText}
                    onChange={e => {
                      setCountryText(e.target.value);
                    }}
                    disabled={checkIfVehicleOrUnscheduled()}
                  />
                </ReportClaimTwoFormContainerFullRow>
              )}
              {!isNonUSAddress && !checkIfVehicleOrUnscheduled() && (
                <ReportClaimTwoFormContainerFullRow>
                  <Addresses
                    id="reportClaimContactLocationAddress"
                    size="large"
                    handlePlaceSelect={handlePlaceSelect}
                    isRequiredField={true}
                    label={'Location Address'}
                  >
                    <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.addressTooltip')} />
                  </Addresses>
                </ReportClaimTwoFormContainerFullRow>
              )}
              {(checkIfVehicleOrUnscheduled() || isNonUSAddress) && (
                <>
                  <ReportClaimTwoFormContainerFullRow>
                    <ReportClaimsAddressTitleHeader htmlFor="locationAddress1">
                      Location Address <RequiredField>*</RequiredField>
                      <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.addressTooltip')} />
                    </ReportClaimsAddressTitleHeader>
                    <ReportClaimsAddressTextInputField
                      id="locationAddress1"
                      name="locationAddress1"
                      value={location1Text}
                      onChange={e => setLocation1Text(e.target.value)}
                      disabled={checkIfVehicleOrUnscheduled()}
                    />
                    <ReportClaimsAddressTextInputField
                      id="locationAddress2"
                      name="locationAddress2"
                      value={location2Text}
                      onChange={e => {
                        setLocation2Text(e.target.value);
                      }}
                      disabled={checkIfVehicleOrUnscheduled()}
                    />
                    {isNonUSAddress && (
                      <ReportClaimTwoFormContainerFullRow>
                        <ReportClaimsAddressTextInputField
                          name="locationAddress3"
                          value={location3Text}
                          onChange={e => {
                            setLocation3Text(e.target.value);
                          }}
                          disabled={checkIfVehicleOrUnscheduled()}
                        />
                        <ReportClaimsAddressTextInputField
                          name="locationAddress4"
                          value={location4Text}
                          onChange={e => {
                            setLocation4Text(e.target.value);
                          }}
                          disabled={checkIfVehicleOrUnscheduled()}
                        />
                      </ReportClaimTwoFormContainerFullRow>
                    )}
                  </ReportClaimTwoFormContainerFullRow>
                  <ReportClaimCityStateContainer>
                    <ReportClaimStepTwoField>
                      <ReportClaimsFieldHeader htmlFor="city">
                        City <RequiredField>*</RequiredField>
                        <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.cityTooltip')} />
                      </ReportClaimsFieldHeader>
                      <ReportClaimsTextInputField
                        id="city"
                        name="city"
                        value={cityText}
                        onChange={e => {
                          setCityText(e.target.value);
                        }}
                        disabled={checkIfVehicleOrUnscheduled()}
                      />
                    </ReportClaimStepTwoField>
                    <StateSelectComponent>
                      <ReportClaimStepTwoField>
                        <ReportClaimsFieldHeader htmlFor="state">
                          {isNonUSAddress ? 'State/Province' : 'State'}{' '}
                          <RequiredField>*</RequiredField>
                          <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.stateTooltip')} />
                        </ReportClaimsFieldHeader>
                        {!isNonUSAddress ? (
                          <StateList
                            name="stateList"
                            id="stateList"
                            options={US_STATES}
                            value={stateText}
                            onChange={option => {
                              setStateText(option);
                            }}
                            isDisabled={checkIfVehicleOrUnscheduled()}
                          />
                        ) : (
                          <ReportClaimStepTwoField>
                            <ReportClaimsTextInputField
                              name="stateOrProvince"
                              value={nonUSStateOrProvinceText}
                              onChange={e => {
                                setNonUSStateOrProvinceText(e.target.value);
                              }}
                            />
                          </ReportClaimStepTwoField>
                        )}
                      </ReportClaimStepTwoField>
                    </StateSelectComponent>
                  </ReportClaimCityStateContainer>
                  <ReportClaimZipCountyContainer>
                    <ReportClaimStepTwoField>
                      <ReportClaimsFieldHeader htmlFor="zip">
                        {isNonUSAddress ? 'Postal Code' : 'ZIP'} <RequiredField>*</RequiredField>
                        <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.zipTooltip')} />
                      </ReportClaimsFieldHeader>
                      <ReportClaimsZipInput
                        id="zip"
                        name="zip"
                        value={zipText}
                        onChange={e => {
                          setZipText(e.target.value);
                        }}
                        disabled={checkIfVehicleOrUnscheduled()}
                      />
                      <ZipErrorMessage>{zipErrorMessage && zipErrorMessage}</ZipErrorMessage>
                    </ReportClaimStepTwoField>
                    {!isNonUSAddress && (
                      <div>
                        <ReportClaimsFieldHeader htmlFor="county">
                          County{' '}
                          <TooltipInfo title={getText(i18n, 'reportClaim.stepTwo.countyTooltip')} />
                        </ReportClaimsFieldHeader>
                        <ReportClaimsTextInputField
                          name="county"
                          value={countyText}
                          onChange={e => {
                            setCountyText(e.target.value);
                          }}
                          disabled={checkIfVehicleOrUnscheduled()}
                        />
                      </div>
                    )}
                  </ReportClaimZipCountyContainer>
                </>
              )}
            </LocationFieldsContainer>

            <ReportClaimTwoFormContainerFullRow>
              <ReportClaimsFieldHeader htmlFor="description">
                Describe what happened <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'reportClaim.stepTwo.descriptionTooltip')}
                  aria-label="Describe what happened"
                />
              </ReportClaimsFieldHeader>
              <ReportClaimsTextAreaField
                name="description"
                value={descriptionText}
                aria-label="Describe what happened"
                onChange={e => {
                  setDescriptionText(e.target.value);
                }}
              />
            </ReportClaimTwoFormContainerFullRow>
          </ReportClaimsStepTwoContainer>

          <ReportClaimButtons
            activeStep={activeStep}
            handleNext={handleNext}
            handleStep={handleStep}
            location1Text={location1Text}
            descriptionText={descriptionText}
            zipText={zipText}
            driverRelationshipSelected={driverRelationshipSelected}
            manualDriverName={manualDriverName}
            selectedDriver={selectedDriver}
            selectedVehicle={selectedVehicle}
            isNonUSAddress={isNonUSAddress}
            countryText={countryText}
            nonUSStateOrProvinceText={nonUSStateOrProvinceText}
            stateText={stateText}
            cityText={cityText}
            setFinalLocation={setFinalLocation}
          />
        </>
      )}
    </>
  );
};

ReportClaimStepTwo.propTypes = {
  address: PropTypes.any,
  activeStep: PropTypes.any,
  cityText: PropTypes.string,
  claimEvent: PropTypes.string,
  claimEventContacts: PropTypes.object,
  claimEventContactsErrors: PropTypes.string,
  countyText: PropTypes.string,
  countryText: PropTypes.string,
  countryNonUs: PropTypes.string,
  customerId: PropTypes.string,
  dateValue: PropTypes.string,
  descriptionStored: PropTypes.string,
  descriptionText: PropTypes.string,
  dispatch: PropTypes.func,
  drivers: PropTypes.array,
  driverRelationshipOptions: PropTypes.array,
  driverRelationshipSelected: PropTypes.any,
  errors: PropTypes.any,
  handleNext: PropTypes.any,
  handleStep: PropTypes.any,
  isClaimEventLoading: PropTypes.bool,
  isNonUSAddress: PropTypes.bool,
  isReadyForNextStep: PropTypes.bool,
  location1Text: PropTypes.string,
  location2Text: PropTypes.string,
  location3Text: PropTypes.string,
  location4Text: PropTypes.string,
  locations: PropTypes.array,
  manualDriverName: PropTypes.string,
  manualLicenseNumber: PropTypes.string,
  selectedClaimEventTemplate: PropTypes.object,
  selectedDriver: PropTypes.object,
  selectedLocation: PropTypes.object,
  selectedState: PropTypes.object,
  selectedVehicle: PropTypes.object,
  stateText: PropTypes.string,
  timeValue: PropTypes.string,
  vehicles: PropTypes.array,
  zipText: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = state => ({
  address: state.reportClaimStepTwoSlice.addr[0],
  claimEvent: state.reportClaimSlice.selectedClaimEventTemplate.description,
  claimEventContacts: state.reportClaimSlice.claimEventContacts,
  claimEventContactsErrors: state.reportClaimSlice.claimEventContactsErrors,
  countryText: state.reportClaimStepTwoSlice.countryText,
  countryNonUs: state.reportClaimStepTwoSlice.countryNonUs,
  customerId: state.customerSlice.customer.systemId,
  descriptionStored: state.reportClaimStepTwoSlice.descriptionText,
  dateValue: state.reportClaimStepTwoSlice.dateValue,
  drivers: getDrivers(state.reportClaimSlice.claimEvent),
  driverRelationshipOptions: state.reportClaimSlice.driverRelationshipOptions,
  driverRelationshipSelected: state.reportClaimStepTwoSlice.driverRelationshipSelected,
  errors: state.reportClaimSlice.errors,
  isClaimEventLoading: state.reportClaimSlice.isClaimEventLoading,
  isNonUSAddress: state.reportClaimStepTwoSlice.isNonUSAddress,
  isReadyForNextStep: state.reportClaimStepTwoSlice.isReadyForNextStep,
  locations: getLocations(state.reportClaimSlice.claimEvent),
  manualDriverName: state.reportClaimStepTwoSlice.manualDriverName,
  manualLicenseNumber: state.reportClaimStepTwoSlice.manualLicenseNumber,
  selectedClaimEventTemplate: state.reportClaimSlice.selectedClaimEventTemplate,
  selectedDriver: state.reportClaimStepTwoSlice.selectedDriver,
  selectedLocation: state.reportClaimStepTwoSlice.selectedLocation,
  selectedVehicle: state.reportClaimStepTwoSlice.selectedVehicle,
  timeValue: state.reportClaimStepTwoSlice.timeValue,
  vehicles: getVehicles(state.reportClaimSlice.claimEvent),
  i18n: state.i18nSlice.i18n,
});
export default connect(mapStateToProps)(ReportClaimStepTwo);
