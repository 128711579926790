import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimSubmission: '',
  referenceNumber: '',
  isSubmitted: false,
};
export const reportClaimStepFiveSlice = createSlice({
  name: 'reportClaimStepFiveSlice',
  initialState,
  reducers: {
    resetStepFiveState: () => {
      return initialState;
    },
    setClaimReferenceNumber: (state, action) => {
      return { ...state, referenceNumber: action.payload };
    },
    setClaimSubmit: (state, action) => {
      return { ...state, claimSubmission: action.payload };
    },
    setIsSubmitted: (state, action) => {
      return { ...state, isSubmitted: action.payload };
    },
  },
});
export const {
  resetStepFiveState,
  setClaimSubmit,
  setClaimReferenceNumber,
  setIsSubmitted,
} = reportClaimStepFiveSlice.actions;
export default reportClaimStepFiveSlice.reducer;
