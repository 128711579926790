import * as React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  AccountLabel,
  BillingDashboardContainer,
  BillingLabelContainer,
  BillingHeader,
  BillToLabel,
  CurrentDueLabel,
  DueDateLabel,
  InvoiceDateLabel,
  LoaderMargin,
  PayOffAmountLabel,
  PaymentPlanLabel,
} from './styles';
import { getCustomerBillingInformation } from './billingAPI';
import Loader from '../../../common/loader';
import BillingRow from './billingRow';
import ApiError from '../../../common/api-error';

export const Billing = props => {
  const { isLoading, billingAccounts, errors, customerId, hasOpenBalance, billingAccountsCountLimit } = props;
  React.useEffect(() => {
    props.dispatch(getCustomerBillingInformation(customerId, hasOpenBalance, billingAccountsCountLimit));
  }, []);

  return (
    <>
      <BillingDashboardContainer>
        <BillingHeader>Billing</BillingHeader>
        <BillingLabelContainer>
          <AccountLabel aria-label="Column Header Account Number"> Account Number </AccountLabel>
          <PayOffAmountLabel>Payoff Amount </PayOffAmountLabel>
          <BillToLabel aria-label="Column Header Bill To">Bill To </BillToLabel>
          <PaymentPlanLabel aria-label="Column Header Payment Plan">Payment Plan </PaymentPlanLabel>
          <InvoiceDateLabel aria-label="Column Header Invoice Date">Invoice Date </InvoiceDateLabel>
          <CurrentDueLabel aria-label="Column Header Current Due">Current Due </CurrentDueLabel>
          <DueDateLabel aria-label="Column Header Due Date">Due Date </DueDateLabel>
        </BillingLabelContainer>
        {isLoading ? (
          <LoaderMargin>
            <Loader />
          </LoaderMargin>
        ) : (
          <>
            {errors ? (
              <ApiError
                body="There was an error loading your Billing Accounts."
                variant="dashboard"
              />
            ) : (
              <BillingRow billingAccounts={billingAccounts} />
            )}
          </>
        )}
      </BillingDashboardContainer>
    </>
  );
};
Billing.propTypes = {
  dispatch: PropTypes.func,
  billingAccounts: PropTypes.array,
  errors: PropTypes.any,
  isLoading: PropTypes.bool,
  customerId: PropTypes.string,
  policies: PropTypes.array,
  hasOpenBalance: PropTypes.bool,
  billingAccountsCountLimit: PropTypes.string,
};
export const mapStateToProps = state => ({
  state,
  isLoading: state.billingSlice.isLoading,
  billingAccounts: state.billingSlice.billingAccounts,
  errors: state.billingSlice.errors,
  customerId: state.loginSlice.user.customer_id,
  hasOpenBalance: state.configurationSlice.features.features.hasOpenBalance,
  billingAccountsCountLimit: state.configurationSlice.features.billingAccountsCountLimit,
});
export default connect(mapStateToProps)(Billing);
