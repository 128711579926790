import styled from 'styled-components';
export const ReportClaimsContainer = styled.div.attrs({
  id: 'ReportClaimsContainer',
})`
  margin: 30px;
  @media (max-width: 599px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsHeader = styled.h1.attrs({
  id: 'ReportClaimsHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 96.6%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    width: 30%;
  }
`;
export const ReportClaimsZipInput = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 96.6%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    width: 17%;
  }
`;

export const ReportClaimsAddressTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  margin-bottom: 10px;
  width: 48%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsTextAreaField = styled.textarea.attrs({
  id: 'ReportClaimsTextInputField',
})`
  height: 80px;
  margin-bottom: 10px;
  width: 99.6%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsButtonsContainer = styled.div.attrs({
  id: 'ReportClaimsButtonsContainer',
})`
  text-align: right;
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ReportClaimCancelButton = styled.button.attrs({
  id: 'ReportClaimsCancelButton',
})`
  margin-left: 10px;
  padding: 8px 39px;
  border: 0;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const ReportClaimNextButton = styled.button.attrs({
  id: 'ReportClaimsNextButton',
})`
  margin-left: 10px;
  padding: 8px 47px;
  border: 0;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
  @media (max-width: 599px) {
    margin-top: 10px;
  }
  :disabled {
    background-color: gray;
  }
`;

export const ErrorMessage = styled.div`
  color: red;
  margin: 16px;
  font-size: 18px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    margin-top: 16px;
  }
`;
