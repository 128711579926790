import { setBillingAccounts, setErrors } from '../../dashboard/billing/billingReducer';
import { setIsLoading } from '../makePaymentAnonymousReducer';
import { TOKENIZED_API } from '../../../../utils/api';

export const getCustomerBillingInformation = (
  customerId,
  token,
  hasOpenBalance,
  billingAccountsCountLimit,
) => {
  return async (dispatch) => {
    dispatch(setIsLoading(true));
    if (TOKENIZED_API) {
      return TOKENIZED_API.get(
        `billingAccounts?customerId=${customerId}&customerToken=${token}&hasOpenBalance=${hasOpenBalance}&limit=${billingAccountsCountLimit}`,
      )
        .then(async (response) => {
          if (response?.data?.billingAccounts) {
            const billingAccounts = response.data.billingAccounts;
            let hasStatementAccounts =
              billingAccounts.filter((account) => !!account.billingAccount.statementAccountRef)
                .length > 0;
            if (hasStatementAccounts) {
              const carrierCdMap = new Map();
              await TOKENIZED_API.get(
                `statementAccounts?customerId=${customerId}&customerToken=${token}`,
              )
                .then((statementAccountsResponse) => {
                  const statementAccounts =
                    statementAccountsResponse.data.statementAccountListItems;
                  statementAccounts.forEach((statementAccountItem) => {
                    const statementAccount = statementAccountItem.statementAccount;
                    carrierCdMap.set(statementAccountItem.ref, statementAccount.carrierCd);
                  });
                  return carrierCdMap;
                })
                .catch((e) => {
                  console.error(e);
                });
              billingAccounts.forEach((billingAccount) => {
                const statementAccountRef = billingAccount.billingAccount.statementAccountRef;
                if (
                  carrierCdMap.has(statementAccountRef) &&
                  billingAccount.billingAccount.arPolicy === undefined
                ) {
                  // update arPolicy.carrierCd only for statement accounts
                  const carrierCd = carrierCdMap.get(statementAccountRef);
                  billingAccount.billingAccount.arPolicy = { carrierCd };
                }
              });
              dispatch(setBillingAccounts(billingAccounts));
            } else {
              response.data.billingAccounts.map((item) => {
                if (item.billingAccount.arPolicy) {
                  item.billingAccount.carrierCd = item.billingAccount.arPolicy.carrierCd;
                }
              });
              dispatch(setBillingAccounts(response.data.billingAccounts));
            }
          }
        })
        .catch(() => dispatch(setErrors('unable to make payment at this time')));
    }
  };
};
