import {
  setEmailAddress,
  setLoading,
  setFactorId,
  setMFAOptions,
  setMFAEnabled,
  setModalOpen,
  setCurrentMFA,
  setPhoneNumber,
} from './mfaReducer';
import { API } from '../../../utils/api';
import { some } from 'lodash';
import { formatPhoneNumber } from '../../../utils/validators/generalValidators';

export const getEnrollmentOptionsAndStatus = (customerId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return API.get(`/customerLogins/${customerId}/factors`)
      .then((response) => {
        const findPhone = response.data.filter((option) => option.factorType === 'SMS');
        if (findPhone[0] && findPhone[0].profile) {
          dispatch(setPhoneNumber(formatPhoneNumber(findPhone[0].profile)));
        } else {
          dispatch(setPhoneNumber(''));
        }
        const findEmail = response.data.filter((option) => option.factorType === 'EMAIL');
        if (findEmail[0] && findEmail[0].profile) {
          dispatch(setEmailAddress(findEmail[0].profile));
        } else {
          dispatch(setEmailAddress(''));
        }
        const activeSubs = some(response.data, ['enrollmentStatus', 'ACTIVE']);
        dispatch(setMFAEnabled(activeSubs));
        dispatch(setMFAOptions(response.data));
      })
      .catch(() => {})
      .finally(() => dispatch(setLoading(false)));
  };
};
export const enrollUserMFA = (customerId, factorType, primaryPhoneNumber, emailAddress, notify) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    return await API.post(`/customerLogins/${customerId}/factors`, {
      factorType,
      profile: factorType === 'SMS' ? primaryPhoneNumber : emailAddress,
    })
      .then((response) => {
        dispatch(setFactorId(response.data.factorId));
        if (response.data.enrollmentStatus === 'PENDING_ACTIVATION') {
          dispatch(setModalOpen(true));
          return dispatch(setCurrentMFA(response.data));
        }
        if (response.data.enrollmentStatus === 'ACTIVE') {
          dispatch(setModalOpen(false));
        }
        return dispatch(getEnrollmentOptionsAndStatus(customerId));
      })
      .catch(() => {
        notify('We were unable to enroll MFA at this time. Please contact your Agent.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
export const disableMFAForPhone = (customerId, selectedMFA) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    return await API.delete(`/customerLogins/${customerId}/factors/${selectedMFA.factorId}`)
      .then(() => {
        return dispatch(getEnrollmentOptionsAndStatus(customerId));
      })
      .catch((err) => err)
      .finally(() => dispatch(setLoading(false)));
  };
};
export const verifyMFACode = (customerId, factorId, mfaCode, notify) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return API.post(`/customerLogins/${customerId}/factors/${factorId}/activateRequest`, {
      passCode: mfaCode,
    })
      .then(() => {
        dispatch(setModalOpen(false));
        return dispatch(getEnrollmentOptionsAndStatus(customerId));
      })
      .catch(() => {
        notify(
          'We were unable to verify your code. Please check if the code is correct or request to resend code.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
export const sendMFACode = (loginId, factorId, notify) => {
  return () => {
    API.post(`customerLogins/${loginId}/factors/${factorId}/sendRequest`)
      .then(() => {
        notify('You should receive a code shortly.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      })
      .catch(() => {
        // console.log(error);
        notify('We were unable to send a code, please try again later.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      });
  };
};
