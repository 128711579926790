import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  i18n: {},
};

export const i18nSlice = createSlice({
  name: 'i18nSlice',
  initialState,
  reducers: {
    setI18n: (state, action) => {
      return { ...state, i18n: action.payload };
    },
  },
});

export const { setI18n } = i18nSlice.actions;
export default i18nSlice.reducer;
