import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Helmet } from 'react-helmet';
// import { getClaimEventTemplates } from './anonymousReportClaimStepTwoApi';
import { getClaimEventTemplates } from '../reportClaimApi';
import { FormControl } from '@mui/material';
// import { setErrors, setSelectedClaimEventTemplate } from './anonymousReportClaimStepTwoReducer';
import { setClaimEvent, setErrors, setSelectedClaimEventTemplate } from '../reportClaimReducer';
import { setDateValue, setTimeValue } from '../report-claim-step-two/reportClaimStepTwoReducer';

import {
  EmergencyMessageComponent,
  LossMessageComponent,
  ReportClaimField,
  ReportClaimFormContainer,
  ReportClaimsSelectionHeader,
  ReportClaimStepTwoField,
  ReportClaimsTitleHeader,
  ReportDateTimeContainer,
  RequiredField,
  SelectLossEvent,
} from './styles';
import { ISO_DATE_FORMAT, TIME_FORMAT_24_HRS } from '../../../common/constants';
import ApiError from '../../../common/api-error';
import { getText } from '../../../../utils/i18n';
import TooltipInfo from '../../../common/tooltip';
import ReportClaimButtons from '../report-claim-button-container';
import Loader from '../../../common/loader';
import { setLoading } from './reportClaimStepOneReducer';

export const Step1 = props => {
  const {
    activeStep,
    claimEventTemplates,
    selectedClaimEventTemplate,
    dispatch,
    handleNext,
    handleStep,
    customerId,
    errors,
    loading,
    dateValue,
    timeValue,
    features,
    i18n,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  useEffect(() => {
    dispatch(setClaimEvent(null));
    dispatch(setErrors(null));
    dispatch(setLoading(true));
    async function getClaimTemplates() {
      if (props.claimEventTemplates.length === 0)
        await dispatch(getClaimEventTemplates(customerId));
    }
    getClaimTemplates();
    dispatch(setLoading(false));
    if (dateValue === '') {
      dispatch(setDateValue(features.defaultLossDateTime ? moment().format(ISO_DATE_FORMAT) : ''));
    }
    if (timeValue === '') {
      dispatch(
        setTimeValue(features.defaultLossDateTime ? moment().format(TIME_FORMAT_24_HRS) : ''),
      );
    }
  }, []);

  const onClaimEventTemplateChange = selectedOption => {
    if (selectedOption) {
      dispatch(setSelectedClaimEventTemplate(selectedOption));
    }
  };

  return (
    <>
      <Helmet>
        <title>Report Claim - Select Loss Event</title>
      </Helmet>
      {loading ? (
        <Loader />
      ) : (
        <>
          {errors ? (
            <ApiError body="Your request cannot be processed at this time. Please try again later." />
          ) : (
            <ReportClaimFormContainer>
              <FormControl>
                <ReportClaimsSelectionHeader htmlFor="lossEventList">
                  {i18n?.general?.emergencyMessage ? (
                    <EmergencyMessageComponent
                      dangerouslySetInnerHTML={{
                        __html: getText(i18n, 'general.emergencyMessage', ''),
                      }}
                    />
                  ) : null}
                  {i18n?.general?.lossMessage ? (
                    <LossMessageComponent
                      dangerouslySetInnerHTML={{
                        __html: getText(i18n, 'general.lossMessage', ''),
                      }}
                    />
                  ) : null}
                  Loss Event <RequiredField>*</RequiredField>
                  <TooltipInfo title={getText(i18n, 'reportClaim.lossEventTooltip')} />
                </ReportClaimsSelectionHeader>
                <SelectLossEvent
                  name="lossEventList"
                  id="claimEventTemplate"
                  aria-label="Loss Event"
                  options={claimEventTemplates}
                  defaultValue={
                    selectedClaimEventTemplate
                      ? {
                          label: selectedClaimEventTemplate.label,
                          value: selectedClaimEventTemplate,
                        }
                      : null
                  }
                  onChange={onClaimEventTemplateChange.bind(this)}
                />
              </FormControl>
              <ReportDateTimeContainer>
                <ReportClaimField>
                  <ReportClaimsTitleHeader htmlFor="lossDate">
                    Date of Incident <RequiredField>*</RequiredField>
                    <TooltipInfo title={getText(i18n, 'reportClaim.lossDateTooltip')} />
                  </ReportClaimsTitleHeader>
                  <input
                    type="date"
                    aria-label="Date of Incident"
                    value={dateValue}
                    max={todayDt}
                    onChange={e => {
                      dispatch(setDateValue(e.target.value));
                    }}
                  />
                </ReportClaimField>
                <ReportClaimStepTwoField>
                  <ReportClaimsTitleHeader htmlFor="timeOfIncidentTextBox">
                    Time of Incident<RequiredField>*</RequiredField>
                    <TooltipInfo title={getText(i18n, 'reportClaim.timeOfIncidentTooltip')} />
                  </ReportClaimsTitleHeader>
                  <input
                    aria-label="Time of Incident"
                    type="time"
                    name="timeOfIncidentTextBox"
                    value={timeValue}
                    onChange={e => {
                      dispatch(setTimeValue(e.target.value));
                    }}
                  />
                </ReportClaimStepTwoField>
              </ReportDateTimeContainer>
              <ReportClaimButtons
                activeStep={activeStep}
                handleNext={handleNext}
                handleStep={handleStep}
              />
            </ReportClaimFormContainer>
          )}
        </>
      )}
    </>
  );
};

Step1.propTypes = {
  activeStep: PropTypes.any,
  dispatch: PropTypes.any,
  claimEventTemplates: PropTypes.array,
  selectedClaimEventTemplate: PropTypes.object,
  handleNext: PropTypes.func,
  handleStep: PropTypes.any,
  loading: PropTypes.bool,
  registrationToken: PropTypes.string,
  customerId: PropTypes.string,
  errors: PropTypes.string,
  dateValue: PropTypes.string,
  timeValue: PropTypes.string,
  features: PropTypes.object,
  i18n: PropTypes.object,
};

export const mapStateToProps = state => ({
  claimEventTemplates: state.reportClaimSlice.claimEventTemplates,
  errors: state.reportClaimSlice.errors,
  loading: state.reportClaimStepOneSlice.loading,
  selectedClaimEventTemplate: state.reportClaimSlice.selectedClaimEventTemplate,
  customerId: state.customerSlice.customer.systemId,
  dateValue: state.reportClaimStepTwoSlice.dateValue,
  timeValue: state.reportClaimStepTwoSlice.timeValue,
  features: state.configurationSlice.features.features,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(Step1);
