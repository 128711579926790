import React, { useState } from 'react';
import {
  AddVehicleField,
  AddVehicleFieldHeader,
  AddVehicleNumericInputField,
  AddVehicleTextAreaInputField,
  RequiredField,
} from './styles';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { YES_NO_DEFAULT } from './selector';

const yesNoOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const AdditionSubQuestionField = props => {
  const { q, parentIndex, index, setAdditionalSubQuestionsAnswers } = props;
  const yesNoInitialState = q.defaultValue || YES_NO_DEFAULT;
  const [selectedYesNoValue, setSelectedYesNoValue] = useState({
    label: yesNoInitialState,
    value: yesNoInitialState,
  });
  const [numericInputValue, setNumericInputValue] = useState('');

  const getInputField = type => {
    let input;
    switch (type) {
      case 'YesNo':
        input = (
          <Select
            options={yesNoOptions}
            value={selectedYesNoValue}
            onChange={selectedValue => {
              setSelectedYesNoValue(selectedValue);
              setAdditionalSubQuestionsAnswers(parentIndex, index, selectedValue);
            }}
          />
        );
        break;
      case 'Numeric':
        input = (
          <AddVehicleNumericInputField
            value={numericInputValue}
            onChange={e => {
              setNumericInputValue(e.target.value);
              setAdditionalSubQuestionsAnswers(parentIndex, index, e.target.value);
            }}
          />
        );
        break;
      case 'TextArea':
        input = (
          <AddVehicleTextAreaInputField
            value={numericInputValue}
            onChange={e => {
              setNumericInputValue(e.target.value);
              setAdditionalSubQuestionsAnswers(parentIndex, index, e.target.value);
            }}
          />
        );
        break;
      default:
        input = null;
    }
    return input;
  };

  return (
    <AddVehicleField key={q.name}>
      <AddVehicleFieldHeader>
        {q.text}
        {q.requiredInd === 'Yes' && <RequiredField>*</RequiredField>}
      </AddVehicleFieldHeader>
      {getInputField(q.dataType)}
    </AddVehicleField>
  );
};
AdditionSubQuestionField.propTypes = {
  q: PropTypes.object,
  index: PropTypes.number,
  parentIndex: PropTypes.number,
  setAdditionalSubQuestionsAnswers: PropTypes.func,
};

const mapStateToProps = state => ({
  state,
});

export default connect(mapStateToProps)(AdditionSubQuestionField);
