import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  confirmationNumber: null,
  errors: null,
  isLoading: false,
  response: null,
};

export const makePaymentStepFourSlice = createSlice({
  name: 'makePaymentStepFourSlice',
  initialState,
  reducers: {
    resetStepFourState: () => {
      return initialState;
    },
    setConfirmationNumber: (state, action) => {
      return { ...state, confirmationNumber: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setResponse: (state, action) => {
      return { ...state, response: action.payload };
    },
  },
});
export const {
  resetStepFourState,
  setConfirmationNumber,
  setErrors,
  setLoading,
  setResponse,
} = makePaymentStepFourSlice.actions;
export default makePaymentStepFourSlice.reducer;
