import { API, MULTI_PART_API } from '../../../../utils/api';
import {
  setValidateClaim,
  setClaimEventTemplates,
  setClaimEventValidated,
  setClaimEventValidationErrors,
  setFilesBeingAttached,
} from './reportClaimStepFourReducer';
import { cloneDeep } from 'lodash';
import { setNewPhones } from '../report-claim-step-three/reportClaimStepThreeReducer';
import { recurseQuestionReplies } from './utils';

const getReportClaimBody = (props, question, filenames) => {
  let getReplies = event => {
    let answers = [];
    let questions = cloneDeep(event);
    return recurseQuestionReplies(questions, answers);
  };

  // deep copy phone info to new object since it is read only
  let phoneInfoList = JSON.parse(
    JSON.stringify(props.state.reportClaimStepThreeSlice.claimEventContacts.contact.phoneInfo),
  );

  // set business/other phone as preferred contact if selected
  let phoneInfoPrimary = phoneInfoList[0];
  let phoneInfoSecondary = phoneInfoList[1];
  let bestWayToContact = props.state.reportClaimStepThreeSlice.bestWayToContact;
  phoneInfoPrimary.phoneName = bestWayToContact ? bestWayToContact : '';
  if (bestWayToContact === 'Business') {
    phoneInfoPrimary.phoneNumber = props.state.reportClaimStepThreeSlice.businessPhoneTemp;
    phoneInfoPrimary.preferredInd = true;
    phoneInfoSecondary.preferredInd = false;
  } else if (bestWayToContact === 'Other') {
    phoneInfoPrimary.phoneNumber = props.state.reportClaimStepThreeSlice.otherPhoneTemp;
    phoneInfoPrimary.preferredInd = true;
    phoneInfoSecondary.preferredInd = false;
  } else if (bestWayToContact === 'Home') {
    phoneInfoPrimary.phoneNumber = props.state.reportClaimStepThreeSlice.homePhoneTemp;
    phoneInfoPrimary.preferredInd = true;
    phoneInfoSecondary.preferredInd = false;
  } else if (bestWayToContact === 'Mobile') {
    phoneInfoPrimary.phoneNumber = props.state.reportClaimStepThreeSlice.mobilePhoneTemp;
    phoneInfoPrimary.preferredInd = true;
    phoneInfoSecondary.preferredInd = false;
  }
  props.dispatch(setNewPhones(phoneInfoList));

  const requestBody = {
    id: props.state.reportClaimSlice.claimEvent.id,
    sourceCd: 'ServicePortal_v4.0.0',
    customerNumber: props.state.reportClaimSlice.claimEvent.customerNumber,
    eventCd: props.state.reportClaimSlice.claimEvent.eventCd,
    accidentInd: props.state.reportClaimSlice.claimEvent.accidentInd,
    lossDt: props.state.reportClaimStepTwoSlice.dateValue,
    lossTm: props.state.reportClaimStepTwoSlice.timeValue,
    addr: props.state.reportClaimStepTwoSlice.addr,
    detailDescription: props.state.reportClaimStepTwoSlice.descriptionText,
    policyNumber: props.state.reportClaimStepThreeSlice.claimEventContacts.policyNumber,
    templateIdRef: props.state.reportClaimStepThreeSlice.claimEventContacts.templateIdRef,
    contact: {
      id: props.state.reportClaimStepThreeSlice.claimEventContacts.contact.id,
      partyTypeCd: props.state.reportClaimStepThreeSlice.claimEventContacts.contact.partyTypeCd,
      personInfo: props.state.reportClaimStepThreeSlice.claimEventContacts.contact.personInfo
        ? props.state.reportClaimStepThreeSlice.claimEventContacts.contact.personInfo
        : {},
      emailInfo: props.state.reportClaimStepThreeSlice.claimEventContacts.contact.emailInfo,
      phoneInfo: phoneInfoList,
      nameInfo: props.state.reportClaimStepThreeSlice.claimEventContacts.contact.nameInfo
        ? props.state.reportClaimStepThreeSlice.claimEventContacts.contact.nameInfo
        : {},
      addresses: props.state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses,
    },
    questionReplies: {
      id: 'QuestionReplies-1',
      questionReply: getReplies(question),
    },
    eventSections: [
      {
        attachments: filenames,
      },
    ],
  };

  if (props.state.reportClaimSlice.selectedClaimEventTemplate.view === 'Location') {
    // if HO policy, we use location
    requestBody.riskIdRef = props.state.reportClaimStepTwoSlice.selectedLocation.id;
  } else if (props.state.reportClaimSlice.selectedClaimEventTemplate.view === 'Vehicle') {
    // if PA policy, we user driver and vehicle info
    const selectedDriver = props.state.reportClaimStepTwoSlice.selectedDriver;
    const nameInfo = selectedDriver.partyInfo.nameInfo;
    requestBody.driverIdRef = selectedDriver.value;
    requestBody.driverName =
      props.state.reportClaimStepTwoSlice.manualDriverName !== ''
        ? props.state.reportClaimStepTwoSlice.manualDriverName
        : `${nameInfo.givenName} ${nameInfo.surname}`;
    requestBody.driverLicense =
      props.state.reportClaimStepTwoSlice.manualLicenseNumber !== ''
        ? props.state.reportClaimStepTwoSlice.manualLicenseNumber
        : selectedDriver.partyInfo.driverInfo.licenseNumber;
    requestBody.vehicleIdRef = props.state.reportClaimStepTwoSlice.selectedVehicle.id;
    requestBody.permissiveDriverRelationshipToInsuredCd =
      props.state.reportClaimStepTwoSlice.driverRelationshipSelected;
  }

  return requestBody;
};

export const validateReportClaim = (body, dispatch) => {
  return API.post('/claimEvents?validateOnly=true', body)
    .then(() => dispatch(setClaimEventValidated(true)))
    .catch(err => {
      console.log(err);
      dispatch(
        setClaimEventValidationErrors(
          `We are unable to validate your claim right now, please reach out to your provider for more information. ${
            err ? err?.message : ''
          }`,
        ),
      );
    });
};

const createRequestBodyAndValidateClaim = async (props, question, response, dispatch) => {
  let finalBody = getReportClaimBody(props, question, response);
  dispatch(setClaimEventTemplates(question));
  dispatch(setValidateClaim(finalBody));
  await validateReportClaim(finalBody, dispatch);
};

export const uploadFilesAndReview = (files, question, props) => {
  return async dispatch => {
    dispatch(setClaimEventValidated(false));
    if (files.length > 0) {
      let fd = new FormData();
      let promises = files.map(file => {
        fd.append(file.name, file);
        return MULTI_PART_API.post('multifileupload', fd)
          .then(() => {
            return {
              description: 'Pictures of the Damage',
              templateId: file.templateId,
              requiredInd: false,
              fileName: file.name,
              id: file.id,
            };
          })
          .catch(err => {
            console.log(err);
          });
      });
      await Promise.all(promises)
        .then(async response => {
          await createRequestBodyAndValidateClaim(props, question, response, dispatch);
        })
        .catch(err => {
          console.log(err);
        })
        .finally(() => {
          // TODO:
          // if user hits previous button after that this may cause a break, consider moving
          // into final submit and/or pathcheck in root index for this
          // may also require saving the files array into redux.
          files.forEach(file => URL.revokeObjectURL(file.preview));
          dispatch(setFilesBeingAttached(false));
        });
    } else {
      await createRequestBodyAndValidateClaim(props, question, [], dispatch);
    }
  };
};
