import styled from 'styled-components';

export const AnonymousReportClaimsStepSixContainer = styled.div.attrs({
  id: 'AnonymousReportClaimsStepSixContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 3%;
    margin-right: 3%;
  }
`;

export const RecapDesktopLabel = styled.h3.attrs({})`
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: 500;
  /* @media (max-width: 900px) {
    display: none;
  } */
`;
export const RecapText = styled.div.attrs({
  id: 'recapText',
})`
  margin-top: 8px;
  display: inline-block;
  max-width: 800px;
  margin-bottom: 80px;
`;

export const ReferenceNumber = styled(RecapText).attrs({
  id: 'referenceNumber',
})`
  display: inline-block;
  margin-bottom: 36px;
`;
export const HomeScreenButton = styled.button.attrs({})`
  padding: 10px 45px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  float: right;
  margin: 25px 0;
  position: relative;
  top: 35px;
  @media (max-width: 380px) {
    margin-top: 16px;
  }
`;
export const StyledReportClaimHeader = styled.h1.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 36px;
  font-weight: 500;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 40px;
  }
`;
export const StyledSubSubHeader = styled.h3.attrs({
  id: 'confirmationSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 15px;
  font-weight: 600;
  margin-top: 20px;
`;
export const InfoContainer = styled.div.attrs({
  id: 'confirmationInfoContainer',
})`
  max-width: 725px;
`;
