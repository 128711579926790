import trim from 'lodash/trim';
import { US_STATES } from '../constants';

export const getStateByCode = stateCode => {
  return US_STATES.filter(state => stateCode === state.value)[0];
};

export const defaultLookupAddress = {
  addr1: '',
  addr2: '',
  city: '',
  stateProvCd: '',
  postalCode: '',
};

export const getFormattedAddress = address => {
  let formattedAddress = null;
  if (address.addr1 && address.city && address.postalCode && address.stateProvCd) {
    formattedAddress = [
      trim(address.addr1),
      address.addr2 ? ' ' + trim(address.addr2) : '',
      ', ' + trim(address.city),
      ', ' + trim(address.stateProvCd),
      ', ' + trim(address.postalCode),
    ].join('');
  }
  return formattedAddress;
};

export const setAddressFields = (address, setAddress, setCity, setZip, setStateOrProvince) => {
  const { addr1, addr2, city, stateProvCd, postalCode } = address;
  setAddress(addr1 + (addr2 ? ', ' + addr2 : ''));
  setCity(city);
  setZip(postalCode);
  setStateOrProvince(getStateByCode(stateProvCd));
};
