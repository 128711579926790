import React, { useContext, useEffect, useState } from 'react';
import {
  ButtonContainer,
  ChooseNotificationMethod,
  FieldContainer,
  FieldHeader,
  FooterContainer,
  ForgotUsernameDisclaimerLabel,
  ForgotUsernameHeader,
  ForgotUsernameMessageLabel,
  ForgotUsernameStepSubContainer,
  LogoLink,
  RequiredField,
  RetrieveUsernameButton,
  ReturnToLoginPageButton,
  ReturnToLoginPageButtonContainer,
  TextInputField,
  ViaEmailButton,
  ViaTextButton,
} from './styles';
import { LoggedOutTemplateLogo } from '../login/styles';
import Footer from '../dashboard/footer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ThemeContext } from 'styled-components';
import Loader from '../../common/loader';
import { resetState } from './forgotUsernameReducer';
import { retrieveUsername } from './forgotUsernameApi';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router';
import { isEmail, isPhone } from '../../../utils/validators/generalValidators';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';

export const ForgotUsername = props => {
  const { dispatch, response, isLoading, i18n, companyName } = props;
  const [isViaSms, setViaSms] = useState(false);
  const [policyNumber, setPolicyNumber] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const theme = useContext(ThemeContext);
  const { enqueueSnackbar } = useSnackbar();

  const areRequiredFieldsComplete = () => {
    return (
      policyNumber.length > 0 &&
      ((isViaSms && mobile && isPhone(mobile)) || (!isViaSms && email && isEmail(email)))
    );
  };

  const getUsername = () => {
    dispatch(
      retrieveUsername(
        {
          policyNumber,
          contactMethod: isViaSms ? 'sms' : 'email',
          contactValue: isViaSms ? mobile : email,
        },
        enqueueSnackbar,
      ),
    );
  };

  const history = useHistory();

  useEffect(() => {
    dispatch(resetState());
  }, []);

  useEffect(() => {
    if (response !== null) {
      history.push('/');
    }
  }, [response]);

  return (
    <>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      <>
        <ForgotUsernameStepSubContainer>
          <ForgotUsernameHeader>Retrieve User ID</ForgotUsernameHeader>
          <ForgotUsernameMessageLabel>
            If you forgot your user ID, we can send it to you via email. The information you provide
            must match our records.
          </ForgotUsernameMessageLabel>
          {isViaSms && (
            <ChooseNotificationMethod>
              <ViaTextButton
                isActive={isViaSms === true}
                activeColor={theme.primaryTextColor ? theme.primaryTextColor : 'white'}
                activeBackgroundColor={theme.primaryColor}
                onClick={async () => {
                  setViaSms(true);
                }}
              >
                Send via Text
              </ViaTextButton>
              <ViaEmailButton
                onClick={() => setViaSms(false)}
                isActive={isViaSms === false}
                activeColor={theme.primaryTextColor ? theme.primaryTextColor : 'white'}
                activeBackgroundColor={theme.primaryColor}
              >
                Send via Email
              </ViaEmailButton>
            </ChooseNotificationMethod>
          )}
          <form>
            <FieldContainer>
              <FieldHeader>
                Policy Number<RequiredField> *</RequiredField>
              </FieldHeader>
              <TextInputField
                id="policyNumber"
                value={policyNumber}
                onChange={e => {
                  setPolicyNumber(e.target.value);
                }}
                autoComplete="policyNumber"
              />
            </FieldContainer>
            {isViaSms ? (
              <FieldContainer>
                <FieldHeader>
                  Mobile Phone Number<RequiredField> *</RequiredField>
                </FieldHeader>
                <TextInputField
                  id="mobilePhoneNumber"
                  value={mobile}
                  onChange={e => {
                    setMobile(e.target.value);
                  }}
                  autoComplete="mobilePhoneNumber"
                />
              </FieldContainer>
            ) : (
              <FieldContainer>
                <FieldHeader>
                  Email Address<RequiredField> *</RequiredField>
                  <TooltipInfo title={getText(i18n, 'forgotUsername.emailAddressTooltip')} />
                </FieldHeader>
                <TextInputField
                  id="email"
                  value={email}
                  onChange={e => {
                    setEmail(e.target.value);
                  }}
                  autoComplete="email"
                />
              </FieldContainer>
            )}
          </form>
          {isViaSms && (
            <ForgotUsernameDisclaimerLabel>
              By providing your mobile phone number and submitting, you consent to receive your User
              Name from us. Message and data rates may apply.
            </ForgotUsernameDisclaimerLabel>
          )}
          <ButtonContainer>
            {isLoading ? (
              <Loader />
            ) : (
              <RetrieveUsernameButton
                disabled={!areRequiredFieldsComplete()}
                onClick={() => {
                  getUsername();
                }}
              >
                Retrieve Username
              </RetrieveUsernameButton>
            )}
          </ButtonContainer>
        </ForgotUsernameStepSubContainer>
        <ReturnToLoginPageButtonContainer>
          <ReturnToLoginPageButton to="/">Return to the Sign-In screen</ReturnToLoginPageButton>
        </ReturnToLoginPageButtonContainer>
      </>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};
ForgotUsername.propTypes = {
  dispatch: PropTypes.func,
  response: PropTypes.object,
  isLoading: PropTypes.bool,
  state: PropTypes.object,
  i18n: PropTypes.object,
  companyName: PropTypes.string,
};

export const mapStateToProps = state => ({
  isLoading: state.forgotUsernameSlice.isLoading,
  response: state.forgotUsernameSlice.response,
  i18n: state.i18nSlice.i18n,
  companyName: state.configurationSlice.features.companyName,
  state,
});

export default connect(mapStateToProps)(ForgotUsername);
