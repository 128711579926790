import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';

import { AnonymousReportClaimContainer, StyledHeader } from './styles';
import { styled } from '@mui/material/styles';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Typography from '@mui/material/Typography';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import Step5 from './step5';
import Step6 from './recap-and-submit';
import { StepButton, StepLabel } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LoggedOutTemplateLogo, LogoLink } from '../login/styles';
import { gaEvent } from '../../../utils/google-analytics';
import Submitted from './submitted';
import {
  resetStepSixState,
  setClaimSubmit,
} from './recap-and-submit/anonymouseReportClaimStepSixReducer';
import { resetStepOneState } from './step1/anonymousReportClaimStepOneReducer';
import { resetStepTwoState } from './step2/anonymousReportClaimStepTwoReducer';
import { resetStepThreeState } from './step3/anonymousReportClaimStepThreeReducer';
import { resetStepFourState } from './step4/anonymousReportClaimStepFourReducer';
import { resetStepFiveState } from './step5/anonymousReportClaimStepFiveReducer';
import { CustomStepperIcons } from '../../common/stepper/utils';
import { useHistory } from 'react-router';
import { ga4Event } from '../../../utils/google-analytics-4';

const PREFIX = 'Card';
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  instructions: `${PREFIX}-instructions`,
};
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
  [`&.${classes.button}`]: {
    marginRight: theme.spacing(1),
  },
  [`&.${classes.backButton}`]: {
    marginRight: theme.spacing(1),
  },
  [`&.${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const TypographyRoot = styled(Typography)(({ theme }) => ({
  [`&.${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const getSteps = () => {
  return ['Verify Account', 'Loss Event', 'Location', 'Contact', 'Questions', 'Submit'];
};

const ReportClaimAnonymous = props => {
  const { claimSubmission, dispatch, companyName } = props;

  const [activeStep, setActiveStep] = React.useState(0);
  const [completed, setCompleted] = React.useState({});
  const steps = getSteps();

  const history = useHistory();
  useEffect(() => {
    let reportClaimAnonymousListener = history.listen(history => {
      if (history.pathname === '/reportClaimNotLoggedIn') {
        //path is fine, allow state to be tracked
      } else {
        // reset state for make payment anonymous.
        // and unlisten
        dispatch(resetStepOneState());
        dispatch(resetStepTwoState());
        dispatch(resetStepThreeState());
        dispatch(resetStepFourState());
        dispatch(resetStepFiveState());
        dispatch(resetStepSixState());
        dispatch(setClaimSubmit(''));
        reportClaimAnonymousListener();
      }
    });
  }, []);

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    handleComplete();
    setActiveStep(newActiveStep);
  };

  const handleStep = step => () => {
    setActiveStep(prevState => {
      if (step < prevState) {
        let updateCompleted = completed;
        for (let i = step + 1; i < prevState; i++) {
          delete updateCompleted[i];
        }
        setCompleted(updateCompleted);
      }
      return step;
    });
  };

  const getStepContent = stepIndex => {
    switch (stepIndex) {
      case 0:
        return <Step1 handleNext={handleNext} />;
      case 1:
        return <Step2 handleNext={handleNext} handleStep={handleStep(stepIndex - 1)} />;
      case 2:
        return <Step3 handleNext={handleNext} handleStep={handleStep(stepIndex - 1)} />;
      case 3:
        return <Step4 handleNext={handleNext} handleStep={handleStep(stepIndex - 1)} />;
      case 4:
        return <Step5 handleNext={handleNext} handleStep={handleStep(stepIndex - 1)} />;
      case 5:
        return <Step6 handleNext={handleNext} handleStep={handleStep(stepIndex - 1)} />;
      default:
        return <Step1 handleNext={handleNext} handleStep={handleStep(stepIndex - 1)} />;
    }
  };

  useEffect(() => {
    gaEvent({ category: 'report-a-claim', action: 'start' });
    ga4Event({ category: 'report-a-claim', action: 'start' });
  }, []);

  return (
    <>
      <Helmet>
        <title>Report Claim Anonymous</title>
      </Helmet>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      {claimSubmission === '' ? (
        <AnonymousReportClaimContainer>
          <Root className={classes.root}>
            <StyledHeader>Report Claim</StyledHeader>
            <Stepper
              alternativeLabel
              activeStep={activeStep}
              style={{ backgroundColor: 'transparent' }}
            >
              {steps.map((label, index) => (
                <Step key={label}>
                  <StepButton onClick={handleStep(index)} completed={completed[index]}>
                    <StepLabel StepIconComponent={CustomStepperIcons}>{label}</StepLabel>
                  </StepButton>
                </Step>
              ))}
            </Stepper>
            <div>
              {
                <div>
                  <TypographyRoot component={'span'} className={classes.instructions}>
                    {getStepContent(activeStep)}
                  </TypographyRoot>
                </div>
              }
            </div>
          </Root>
        </AnonymousReportClaimContainer>
      ) : (
        <>
          <AnonymousReportClaimContainer>
            <Submitted />
          </AnonymousReportClaimContainer>
        </>
      )}
    </>
  );
};
ReportClaimAnonymous.propTypes = {
  claimSubmission: PropTypes.string,
  dispatch: PropTypes.func,
  companyName: PropTypes.string,
};

export const mapStateToProps = state => ({
  claimSubmission: state.anonymousReportClaimStepSixSlice.claimSubmission,
  companyName: state.configurationSlice.features.companyName,
});
export default connect(mapStateToProps)(ReportClaimAnonymous);
