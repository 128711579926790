import { API, setDispatcher, setServiceTestUrl } from '../../../utils/api/index';
import {
  setCustomer,
  setErrors,
  setLoading,
  setSelfServiceConfigurations,
} from './customerReducer';

export const getCustomerById = (customerId, notify) => {
  setServiceTestUrl(`customers/${customerId}/selfServiceConfigurations`);
  return async dispatch => {
    setDispatcher(dispatch);
    dispatch(setLoading(true));
    await Promise.allSettled([
      API.get(`customers/${customerId}`),
      API.get(`customers/${customerId}/selfServiceConfigurations`),
    ])
      .then((responses) => {
        // INow sends 404 when no customer is found, handling this with error message.
        if (responses?.[0]?.reason?.response?.status === 404) {
          notify('This user does not exist in this environment. Please log out.', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          });
          return;
        }
        dispatch(setCustomer(responses[0].value.data));

        const response = responses[1].value.data;
        const features = response.selfServiceFeature;
        const viewIdCards = features
          ? features.filter(
              feature => feature.id === 'ViewVehicleIdCard' && feature.value === 'Yes',
            )
          : null;
        response.selfServiceFeature = { viewIdCard: viewIdCards && viewIdCards.length > 0 };
        dispatch(setSelfServiceConfigurations(response));
      })
      .catch(error => dispatch(setErrors(error?.message ? error.message : error)))
      .finally(() => dispatch(setLoading(false)));
  };
};
