import moment from 'moment';
import { checkExpiration } from '../../../utils/validators/generalValidators';
export const isDownTimeModeActive = downTime => {
  let isDownTimeActive = false;
  if (downTime && downTime.isServiceUnavailable) return true;
  if (downTime && downTime.beginTime && downTime.endTime) {
    const currentTime = moment();
    const downTimeBeginTime = moment(downTime.beginTime);
    const downTimeEndTime = moment(downTime.endTime);
    isDownTimeActive =
      currentTime.isAfter(downTimeBeginTime) && currentTime.isBefore(downTimeEndTime);
  }
  return isDownTimeActive;
};
export const showHelperPanel = state => {
  const {
    configurationSlice: { features },
  } = state;
  return checkExpiration(((features || {}).features || {}).loginPanelExpirationDate);
};
