import React, { useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router, Route, Switch, useHistory } from 'react-router-dom';
import { LoginCallback, SecureRoute, Security } from '@okta/okta-react';
import { OktaAuth } from '@okta/okta-auth-js';
import { Normalize } from 'styled-normalize';
import { configureStore } from '@reduxjs/toolkit';
import rootReducer from './reducers/index';
import { Provider } from 'react-redux';
import PropTypes from 'prop-types';
import Home from './components/index';
import Dashboard from './components/features/dashboard/index';
import { setFeatures } from './components/common/configuration/configurationReducer';
import { ThemeProvider } from 'styled-components';
import { ThemeProvider as MuiThemeProvider } from '@mui/material';
import { createTheme } from '@mui/material/styles';
import { SnackbarProvider } from 'notistack';
import CreateAccount from './components/features/create-account/index';
import ErrorPage from './components/common/error';
import { loadI18n } from './components/common/i18n/i18nApi';

// eslint-disable-next-line no-undef
import { GlobalStyle, theme } from './config/style/style';
import MakePaymentAnonymous from './components/features/make-payment-anonymous';
import ReportClaimAnonymous from './components/features/report-claim-anonymous';
import ForgotUsername from './components/features/forgot-username';
import ForgotPassword from './components/features/forgot-password';

const { features } = require(`./config/config`);
const store = configureStore({ reducer: rootReducer });

const App = () => {
  if (window.location.href.includes('registrationToken=')) {
    // if it is a signup token url, clear okta session
    localStorage.removeItem('okta-token-storage');
  }
  const history = useHistory();
  const authHandler = () => {
    history.push('/');
  };

  const restoreOriginalUri = () => {};

  useEffect(() => {
    store.dispatch(loadI18n());
    store.dispatch(setFeatures());
  }, []);

  const oktaAuth = new OktaAuth({
    issuer: features.tokenIssuer,
    clientId: features.clientId,
  });

  const thisTheme = createTheme();

  return (
    <Provider store={store}>
      <Normalize />
      <MuiThemeProvider theme={thisTheme}>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <SnackbarProvider>
            <Router>
              <Security
                oktaAuth={oktaAuth}
                onAuthRequired={authHandler}
                restoreOriginalUri={restoreOriginalUri}
              >
                <Switch>
                  <Route path="/error" component={ErrorPage} />
                  <Route path="/" component={Home} />
                  <Route path="/login/callback" component={LoginCallback} />
                  <Route path="/createAccount" component={CreateAccount} />
                  <Route path="/makePaymentAnonymous" component={MakePaymentAnonymous} />
                  <Route path="/reportClaimNotLoggedIn" component={ReportClaimAnonymous} />
                  <Route path="/forgotUsername" component={ForgotUsername} />
                <Route path="/forgotPassword" component={ForgotPassword} />
                  <SecureRoute path="/dashboard" component={Dashboard} />
                </Switch>
              </Security>
            </Router>
          </SnackbarProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
};

App.propTypes = {
  store: PropTypes.object.isRequired,
};
const container = document.getElementById('root');
const root = createRoot(container);
root.render(<App store={store} />);
