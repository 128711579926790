import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Spinner = props => (
  <StyledSpinner
    viewBox="0 0 50 50"
    loaderwidth={props.loaderwidth}
    loaderheight={props.loaderheight}
  >
    <circle className="path" cx="25" cy="25" r="20" fill="none" strokeWidth="2" />
  </StyledSpinner>
);

const StyledSpinner = styled.svg.attrs({
  id: 'styledSpinner',
  alt: 'LoadingIcon',
})`
  animation: rotate 1s linear infinite;
  width: ${props => (props.loaderwidth ? props.loaderwidth : '50px')};
  height: ${props => (props.loaderheight ? props.loaderheight : '50px')};

  & .path {
    stroke: ${props => props.theme.primaryColor};
    stroke-linecap: round;
    animation: dash 1.5s ease-in-out infinite;
  }

  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 150;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 90, 150;
      stroke-dashoffset: -124;
    }
  }
`;
Spinner.propTypes = {
  loaderheight: PropTypes.string,
  loaderwidth: PropTypes.string,
};

export const Loader = props => {
  return <Spinner loaderheight={props.loaderheight} loaderwidth={props.loaderwidth} />;
};
Loader.propTypes = {
  loaderheight: PropTypes.string,
  loaderwidth: PropTypes.string,
};
export default Loader;
