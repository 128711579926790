import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ButtonContainer, PendingPaymentAcceptanceButton, PendingPaymentContainer } from './styles';
import { setAcceptedPendingPaymentMessage } from '../../features/make-payment/makePaymentReducer';

const PendingPaymentBanner = props => {
  const {
    acceptedPendingPaymentMessage,
    accountToPay,
    dispatch,
    isReview,
    pendingPayments,
  } = props;
  const acceptPendingPayment = () => {
    dispatch(setAcceptedPendingPaymentMessage(true));
  };
  return (
    <PendingPaymentContainer>
      <div>
        A payment of $
        {accountToPay
          ? accountToPay
              .reduce(
                (previousValue, currentValue) =>
                  previousValue + parseFloat(currentValue.receiptAmt),
                0,
              )
              .toFixed(2)
          : pendingPayments
              .reduce(
                (previousValue, currentValue) =>
                  previousValue + parseFloat(currentValue.receiptAmt),
                0,
              )
              .toFixed(2)}{' '}
        is currently pending for this account. Payment beyond the Current Amount Due will be applied
        to the remaining account balance.{' '}
      </div>
      {isReview && (
        <>
          <ButtonContainer>
            <PendingPaymentAcceptanceButton
              onClick={() => acceptPendingPayment()}
              disabled={acceptedPendingPaymentMessage}
            >
              I understand
            </PendingPaymentAcceptanceButton>
          </ButtonContainer>
        </>
      )}
    </PendingPaymentContainer>
  );
};
PendingPaymentBanner.propTypes = {
  acceptedPendingPaymentMessage: PropTypes.bool,
  accountToPay: PropTypes.array,
  dispatch: PropTypes.func,
  isReview: PropTypes.any,
  pendingPayments: PropTypes.array,
};
export const mapStateToProps = state => ({
  state,
  acceptedPendingPaymentMessage: state.makePaymentSlice.acceptedPendingPaymentMessage,
  pendingPayments: state.makePaymentSlice.accountToPayWith?.fullAccount?.pendingReceipts || null,
});
export default connect(mapStateToProps)(PendingPaymentBanner);
