import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  lookupAddress: {
    addr1: '',
    addr2: '',
    city: '',
    stateProvCd: '',
    postalCode: '',
  },
  componentMeta: null,
  isLoading: false,
  lookupAddressVerified: false,
  lookupAddressVerificationError: false,
};

export const addressesSlice = createSlice({
  name: 'addressesSlice',
  initialState,
  reducers: {
    setLookupAddress: (state, action) => {
      return { ...state, lookupAddress: action.payload };
    },
    setComponentMeta: (state, action) => {
      return { ...state, componentMeta: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setLookupAddressVerificationError: (state, action) => {
      return { ...state, lookupAddressVerificationError: action.payload };
    },
    setLookupAddressVerified: (state, action) => {
      return { ...state, lookupAddressVerified: action.payload };
    },
  },
});
export const {
  setLookupAddress,
  setComponentMeta,
  setLoading,
  setLookupAddressVerified,
  setLookupAddressVerificationError,
} = addressesSlice.actions;
export default addressesSlice.reducer;
