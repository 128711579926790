import { some } from 'lodash';
export const getPendingPayments = account => {
  return some(account?.fullAccount?.pendingReceipts, ['unpostedReceiptTypeCd', 'Pending']);
};

export const getCarrierCd = accountToPayWith => {
  if (
    accountToPayWith &&
    accountToPayWith.billingAccount &&
    accountToPayWith?.billingAccount?.accountDisplayName === 'Statement'
  ) {
    return accountToPayWith?.fullAccount?.underlyingBillingAccounts[1]?.arPolicy.carrierCd;
  } else {
    return accountToPayWith.billingAccount.arPolicy.carrierCd;
  }
};

export const determinePaymentConfigId = (paymentConfigIds, accountToPayWith) => {
  const carrierCd = getCarrierCd(accountToPayWith);
  return paymentConfigIds[carrierCd];
};
export const determineAnonymousPaymentConfigId = (paymentConfigIds, accountToPayWith) => {
  const carrierCd = accountToPayWith?.billingAccount?.arPolicy?.carrierCd;
  return paymentConfigIds[carrierCd];
};
