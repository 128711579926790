import { API } from '../../../../utils/api';
import { setLoading, setAgents, setErrors } from './myAgentReducer';
import find from 'lodash/find';

export const getMyAgents = systemId => {
  return dispatch => {
    dispatch(setLoading(true));
    API.get(`/customers/${systemId}/summary`)
      .then(async response => {
        let promises = response.data.providerInfo.map(provider => {
          return API.get(
            `/providers?providerTypeCd=Producer&providerNumber=${provider.providerNumber}`,
          )
            .then(response => {
              let agentInfo = find(response.data.providerListItems, item => {
                return item.providerMini.indexName === provider.indexName;
              });
              return { ...agentInfo.providerMini.partyInfo, ref: agentInfo.ref };
            })
            .catch(err => {
              return err.message ? err.message : 'errors';
            });
        });
        await Promise.all(promises)
          .then(response => {
            dispatch(setAgents(response));
          })
          .catch(err => dispatch(setErrors(err.message ? err.message : 'errors')));
        // we await promise all so we can finally set loading false from top level call
      })
      .catch(err => dispatch(setErrors(err.message ? err.message : 'errors')))
      .finally(() => dispatch(setLoading(false)));
  };
};
