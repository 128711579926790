import { every } from 'lodash';
import moment from 'moment';
import { _functions } from '../../report-claim-anonymous/step5/utils';

export const stepOneRequiredFieldCheck = props => {
  const { dateValue, timeValue, selectedClaimEventTemplate } = props;
  const selectedDateTime = moment(new Date(dateValue + 'T' + timeValue));
  return (
    dateValue.length > 0 &&
    timeValue.length > 0 &&
    !selectedDateTime.isAfter(moment()) &&
    !!selectedClaimEventTemplate
  );
};
const checkDriverAndVehicle = props => {
  const { driverRelationshipSelected, manualDriverName, selectedDriver, selectedVehicle } = props;
  return (
    selectedVehicle.value &&
    (selectedDriver.value === 'PermissiveUse' || selectedDriver.value === 'NonPermissiveUse'
      ? manualDriverName.length > 0 && driverRelationshipSelected !== ''
      : selectedDriver.value)
  );
};
const genericFieldRequirements = props => {
  // fields that both vehicle type and location based claims require.
  const {
    descriptionText,
    selectedLocation,
    location1Text,
    cityText,
    zipText,
    isNonUSAddress,
    countryText,
    nonUSStateOrProvinceText,
    stateText,
  } = props;
  return (
    descriptionText.trim().length > 0 &&
    (selectedLocation.riskAddress ||
      (location1Text.length > 0 && cityText.length > 0 && zipText.length > 4)) &&
    (isNonUSAddress === true
      ? countryText.length > 0 && nonUSStateOrProvinceText.length > 0
      : (stateText && stateText.value.length) > 0 || (stateText && stateText.length > 0))
  );
};
export const stepTwoRequiredFieldCheck = props => {
  const { selectedClaimEventTemplate } = props;
  if (selectedClaimEventTemplate.view === 'Vehicle') {
    return !(genericFieldRequirements(props) && checkDriverAndVehicle(props));
  }
  return !genericFieldRequirements(props);
};
export const stepThreeRequiredFieldCheck = props => {
  const {
    bestWayToContact,
    email,
    homePhoneField,
    businessPhoneField,
    otherPhoneField,
    isEmail,
    isPhone,
    mobilePhoneField,
    isBestWayToContactSelected,
  } = props;
  if (!isBestWayToContactSelected) {
    return false;
  }

  let completedFlag = false;
  // mobile validation
  completedFlag =
    completedFlag ||
    (bestWayToContact === 'Mobile' && mobilePhoneField && isPhone(mobilePhoneField));

  // home phone validation
  completedFlag =
    completedFlag || (bestWayToContact === 'Home' && homePhoneField && isPhone(homePhoneField));

  // business phone validation
  completedFlag =
    completedFlag ||
    (bestWayToContact === 'Business' && businessPhoneField && isPhone(businessPhoneField));

  // other phone validation
  completedFlag =
    completedFlag || (bestWayToContact === 'Other' && otherPhoneField && isPhone(otherPhoneField));

  //email validation
  completedFlag = completedFlag || (bestWayToContact === 'Email' && email && isEmail(email));
  return completedFlag;
};

export const requiredQuestionsCompleteCheck = (question, previousLevel) => {
  let shown = true;
  for (var i = 0; i < question.length; i++) {
    //loop through current lvl of the array
    if (!question[i]?.questions) {
      //no more questions deep? check ids
      let check = every(question, thisQuestion => {
        if (previousLevel && thisQuestion.function) {
          const parentValue = previousLevel.Value;
          const functionName = thisQuestion.function.name;
          const func = _functions[functionName];
          var args = thisQuestion.function.args;
          shown = func(parentValue, args);
        }
        if (thisQuestion.requiredInd === 'No') {
          return true;
        }
        if (thisQuestion.requiredInd === 'Yes' && !shown) {
          return true; //aka is it defined/truthy
        } else {
          return thisQuestion.requiredInd === 'Yes' && thisQuestion.Value;
        }
      });
      if (check) {
        continue; //good for current questions index, but could be deeper questions on a different question[i]
      } else {
        return false;
      }
    } else {
      if (previousLevel && question[i].function) {
        const parentValue = previousLevel.Value;
        const functionName = question[i].function.name;
        const func = _functions[functionName];
        var args = question[i].function.args;
        shown = func(parentValue, args);
      }
      if (question[i].requiredInd === 'Yes' && shown && !question[i].Value) {
        return false; //bail early, they don't have all values
      } else {
        if (requiredQuestionsCompleteCheck(question[i].questions.question, question[i])) {
          //go deeper
          continue;
        } else {
          return false;
        }
      }
    }
  }
  return true;
};
