import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import EditingTimeZone from './editTimezone';
import { setEditingTimezone } from './profileReducer';
import {
  EditTimezoneButtonContainer,
  EditTimezoneLink,
  EmailError,
  HelpIcon,
  TimezoneValue,
  TimezoneContainer,
  TimezoneLabel,
} from './styles';

import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const TimezoneDetails = (props) => {
  const { dispatch, editingTimezone, timezonePreference, timezoneErrors } = props;

  return (
    <>
      <TimezoneContainer>
        <TimezoneLabel>
          Timezone
          <Tooltip title="SMS will only be sent between the hours of 8am-9pm in the selected timezone.">
            <IconButton size="small" component="span">
              <HelpIcon />
            </IconButton>
          </Tooltip>
          <TimezoneValue>{timezonePreference}</TimezoneValue>
        </TimezoneLabel>
        {!editingTimezone && (
          <>
            <EditTimezoneButtonContainer>
              <EditTimezoneLink onClick={() => dispatch(setEditingTimezone(true))}>
                Edit
              </EditTimezoneLink>
            </EditTimezoneButtonContainer>
          </>
        )}
      </TimezoneContainer>
      {timezoneErrors && <EmailError>{timezoneErrors}</EmailError>}
      {editingTimezone ? <EditingTimeZone /> : null}
    </>
  );
};

TimezoneDetails.propTypes = {
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  isDashboard: PropTypes.bool,
  timezonePreference: PropTypes.string,
  timezoneErrors: PropTypes.any,
  editingTimezone: PropTypes.bool,
  timeZonePreference: PropTypes.string,
};
const mapStateToProps = (state) => ({
  isLoading: state.profileSlice.isLoading,
  timezoneErrors: state.profileSlice.timezoneErrors,
  editingTimezone: state.profileSlice.editingTimezone,
  timezonePreference: state.notificationSlice.userTimezonePreference,
});

export default connect(mapStateToProps)(TimezoneDetails);
