import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  claims: [],
  isLoading: false,
  totalUploads: null,
  currentUploads: null,
  errors: null,
};

export const claimsSlice = createSlice({
  name: 'claimsSlice',
  initialState,
  reducers: {
    setClaims: (state, action) => {
      return { ...state, claims: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setClaimTotalUploads: (state, action) => {
      return { ...state, totalUploads: action.payload };
    },
    setClaimCurrentUploads: (state, action) => {
      return { ...state, currentUploads: action.payload };
    },
  },
});
export const {
  setClaims,
  setLoading,
  setErrors,
  setClaimCurrentUploads,
  setClaimTotalUploads,
} = claimsSlice.actions;
export default claimsSlice.reducer;
