import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  RadioLabel,
  RemoveVehicleFinanceCompanyButtonsContainer,
  RemoveVehicleFinanceCompanyCancelButton,
  RemoveVehicleFinanceCompanyComponent,
  RemoveVehicleFinanceCompanyContainer,
  RemoveVehicleFinanceCompanyField,
  RemoveVehicleFinanceCompanyFieldHeader,
  RemoveVehicleFinanceCompanyRadioButton,
  RemoveVehicleFinanceCompanySubmitButton,
  RemoveVehicleFinanceCompanyTextAreaField,
  RequiredField,
} from './styles';
import moment from 'moment';

import { createField, formatRecapFieldDate, getVehicleFinanceCompanyById } from '../selector';
import Loader from '../../../../common/loader';
import { getAdditionalInterestsForVehicles, submitPolicyChange } from '../../policyChangeAPI';
import { setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useHistory } from 'react-router';

export const RemoveVehicleFinanceCompany = props => {
  const {
    vehicleFinanceCompanyList,
    customerId,
    selectedPolicy,
    selectedPolicyChangeTemplate,
    i18n,
    isLossPayee,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [selectedCompanyId, setSelectedCompanyId] = useState('');
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      effectiveDate.length > 0 && reason.length > 0 && selectedCompanyId.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  checkRequiredFields();

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason for Removal', reason, 'text'));
    fields.push(
      createField(
        isLossPayee ? 'Loss Payee to be Removed' : 'Vehicle Finance Company to be Removed',
        selectedCompany.interestName,
        'text',
      ),
    );
    return fields;
  };

  const onSubmitButtonClick = () => {
    setIsSubmitting(true);
    setIsSubmitButtonEnabled(false);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          ai: [selectedCompany],
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  useEffect(() => {
    if (selectedPolicy?.value !== 'default') {
      props.dispatch(getAdditionalInterestsForVehicles(selectedPolicy.value));
    }
  }, [selectedPolicy]);

  const history = useHistory();

  return (
    <>
      <RemoveVehicleFinanceCompanyContainer>
        <RemoveVehicleFinanceCompanyField>
          <RemoveVehicleFinanceCompanyFieldHeader>
            Requested Effective Date <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(
                i18n,
                'changeCoverage.removeVehicleFinanceCompany.effectiveDateTooltip',
              )}
            />
          </RemoveVehicleFinanceCompanyFieldHeader>
          <input
            type="date"
            aria-label="Requested Effective Date"
            value={effectiveDate}
            min={todayDt}
            onChange={e => {
              setEffectiveDate(e.target.value);
            }}
          />
        </RemoveVehicleFinanceCompanyField>
        <RemoveVehicleFinanceCompanyField>
          <RemoveVehicleFinanceCompanyFieldHeader>
            Reason for Removal <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.removeVehicleFinanceCompany.reasonTooltip')}
            />
          </RemoveVehicleFinanceCompanyFieldHeader>
          <RemoveVehicleFinanceCompanyTextAreaField
            value={reason}
            onChange={e => {
              setReason(e.target.value);
            }}
          />
        </RemoveVehicleFinanceCompanyField>
        <RemoveVehicleFinanceCompanyField>
          <RemoveVehicleFinanceCompanyFieldHeader>
            {isLossPayee ? 'Loss Payee to be Removed ' : 'Vehicle Finance Company to be Removed '}
            <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(
                i18n,
                'changeCoverage.removeVehicleFinanceCompany.companyToBeRemovedTooltip',
              )}
            />
          </RemoveVehicleFinanceCompanyFieldHeader>
          {vehicleFinanceCompanyList.map(company => {
            return (
              <RemoveVehicleFinanceCompanyComponent key={company.id}>
                <label htmlFor={company.id}>
                  <RemoveVehicleFinanceCompanyRadioButton
                    name="financeCompany"
                    value={company.id}
                    id={company.id}
                    onChange={e => {
                      setSelectedCompanyId(e.target.value);
                      setSelectedCompany(
                        getVehicleFinanceCompanyById(e.target.value, vehicleFinanceCompanyList),
                      );
                    }}
                  />{' '}
                  <RadioLabel>{`${company.interestName} - ${company.interestTypeCd} ${company.accountNumber}`}</RadioLabel>
                </label>
              </RemoveVehicleFinanceCompanyComponent>
            );
          })}
        </RemoveVehicleFinanceCompanyField>

        <RemoveVehicleFinanceCompanyButtonsContainer>
          <RemoveVehicleFinanceCompanyCancelButton
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Cancel
          </RemoveVehicleFinanceCompanyCancelButton>
          <RemoveVehicleFinanceCompanySubmitButton
            disabled={!isSubmitButtonEnabled || isSubmitting}
            onClick={() => {
              onSubmitButtonClick();
            }}
          >
            Submit
          </RemoveVehicleFinanceCompanySubmitButton>
          {isSubmitting ? (
            <StyledLoaderContainer>
              <Loader loaderheight="20px" loaderwidth="20px" />
            </StyledLoaderContainer>
          ) : (
            <Spacer />
          )}
        </RemoveVehicleFinanceCompanyButtonsContainer>
      </RemoveVehicleFinanceCompanyContainer>
    </>
  );
};
RemoveVehicleFinanceCompany.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  vehicleFinanceCompanyList: PropTypes.array,
  i18n: PropTypes.object,
  isLossPayee: PropTypes.bool,
};
const mapStateToProps = state => ({
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  vehicleFinanceCompanyList: state.policyChangeSlice.vehicleFinanceCompanyList,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(RemoveVehicleFinanceCompany);
