import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';

export const HelpIconLarge = styled(InfoIcon).attrs({
  className: 'fa fa-info-circle',
})`
  margin-left: 6px;
  color: #777;
  font-size: 16px;
`;

export const HelpIconMedium = styled(InfoIcon).attrs({
  className: 'fa fa-info-circle',
})`
  margin-left: 6px;
  color: #777;
  font-size: 12px;
`;
