import { cloneDeep } from 'lodash';

const recursionMap = (newArray, questionReplies) => {
  if (!questionReplies) {
    return newArray;
  }

  for (var i = 0; i < newArray.length; i++) {
    if (!newArray[i]?.questions) {
      let replyValue = questionReplies?.questionReply.find(
        question => question.name === newArray[i]?.name,
      );
      if (replyValue?.value) {
        newArray[i] = { ...newArray[i], Value: replyValue.value };
        continue;
      } else {
        continue;
      }
    } else {
      let outerReplyValue = questionReplies?.questionReply.find(
        question => question.name === newArray[i]?.name,
      );
      if (outerReplyValue?.value) {
        newArray[i] = { ...newArray[i], Value: outerReplyValue.value };
      }
      recursionMap(newArray[i].questions.question, questionReplies);
      continue;
    }
  }
  return newArray;
};
export const handleQuestionsPreviouslyAnswered = (questionReplies, reportClaimDetailsSection) => {
  let cloned = cloneDeep(reportClaimDetailsSection); // so we can do in place assignments;
  return recursionMap(cloned, questionReplies);
};
