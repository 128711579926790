import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Tooltip } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { setCurrentMFA, setModalOpen } from './mfaReducer';
import {
  ButtonContainer,
  CancelMFA,
  ConfirmCodeInput,
  ConfirmMFACode,
  ConfirmPhone,
  EmailContainer,
  EmailSelect,
  EmailStyledLabel,
  HelpIcon,
  MFAContainer,
  MFAEditContainer,
  MFALabel,
  MFATitle,
  MfaUnEnrollButton,
  MfaUnEnrollInformation,
  ModalContainer,
  ModalHeader,
  ModalNote,
  PhoneContainer,
  PhoneInput,
  PhoneSelect,
  ResendMFACode,
  StyledLabel,
  StyledMfaUnEnrollHeader,
  StyledMfaUnEnrollModal,
} from './styles';
import {
  disableMFAForPhone,
  enrollUserMFA,
  getEnrollmentOptionsAndStatus,
  sendMFACode,
  verifyMFACode,
} from './mfaAPI';
import Loader from '../../common/loader';
import { useSnackbar } from 'notistack';
import { getText } from '../../../utils/i18n';
import IconButton from '@mui/material/IconButton';

export const EnableMFAContainer = (props) => {
  const {
    currentMFA,
    customerId,
    dispatch,
    emailAddress,
    i18n,
    isLoading,
    mfaEnabled,
    mfaOptions,
    modalOpen,
    phoneNumber,
  } = props;
  const { enqueueSnackbar } = useSnackbar();

  const [MFACode, setMFACode] = useState('');
  const [phoneToUse, setPhoneToUse] = useState('');
  const [mfaCodeSent, setMFACodeSent] = useState(false);
  const [phoneConfirmed, setPhoneConfirmed] = useState(false);
  const [unEnrollModalOpen, setUnEnrollModalOpen] = useState(false);
  const [mfaOption, setMfaOption] = useState({});
  useEffect(() => {
    dispatch(getEnrollmentOptionsAndStatus(customerId));
    setPhoneToUse('');
    setPhoneConfirmed(false);
  }, []);

  const handleModalClose = (option) => {
    dispatch(disableMFAForPhone(customerId, option)); //api goes from pending_activation to not_setup
    dispatch(setModalOpen(false));
    dispatch(setCurrentMFA({ factorId: null, factorType: null })); //reset currentMFA being used
    setPhoneToUse('');
    setPhoneConfirmed(false);
  };

  const resendMFACode = async (factorId) => {
    setMFACodeSent(true);
    setTimeout(() => {
      setMFACodeSent(false);
    }, 10000);
    await dispatch(sendMFACode(customerId, factorId, enqueueSnackbar));
  };
  const confirmMFACodeEnabled = async () => {
    if (currentMFA && currentMFA.factorId) {
      await dispatch(verifyMFACode(customerId, currentMFA.factorId, MFACode, enqueueSnackbar));
    } else {
      await dispatch(
        enrollUserMFA(customerId, currentMFA.factorType, phoneToUse, emailAddress, enqueueSnackbar),
      );
    }
  };
  const findAndSetMFAOption = (option) => {
    dispatch(setCurrentMFA(option)); //first we set the mfa option selected, i.e. email or sms
    if (option.enrollmentStatus === 'NOT_SETUP') {
      if (option.factorType === 'SMS' && phoneConfirmed === false) {
        dispatch(setModalOpen(true));
        return; //need them to give us the phone to use
      }
      dispatch(
        enrollUserMFA(customerId, option.factorType, phoneToUse, emailAddress, enqueueSnackbar),
      );
    }
    if (option.enrollmentStatus === 'ACTIVE' && option.factorType !== 'EMAIL') {
      setUnEnrollModalOpen(true);
      setMfaOption(option);
    }
    if (option.enrollmentStatus === 'PENDING_ACTIVATION') {
      dispatch(setModalOpen(true));
    }
  };
  const enrollPhoneInMFA = async () => {
    setPhoneConfirmed(true);
    setMFACodeSent(true);
    setTimeout(() => {
      setMFACodeSent(false);
    }, 10000);
    enqueueSnackbar('Sending request for code now', {
      variant: 'info',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    });
    await dispatch(
      enrollUserMFA(customerId, currentMFA.factorType, phoneToUse, null, enqueueSnackbar),
    );
  };
  return (
    <>
      {isLoading ? (
        <div style={{ marginTop: '16px' }}>
          <Loader />
        </div>
      ) : (
        <MFAContainer>
          <MFALabel>
            Multi-Factor Authentication
            <Tooltip
              title={getText(
                i18n,
                'mfa.explanation',
                'MFA adds a second layer of security by requiring a user to enter an additional code from their email or phone to login to their account.',
              )}
            >
              <IconButton size="small" component="span">
                <HelpIcon />
              </IconButton>
            </Tooltip>
          </MFALabel>
          {mfaEnabled ? (
            <MFAEditContainer>
              <MFATitle>
                Would you like to add/remove your phone as an authentication method?
              </MFATitle>
              {mfaOptions.map((option) => {
                if (option.factorType === 'SMS') {
                  return (
                    <PhoneContainer key={option.factorType}>
                      <StyledLabel htmlFor="phoneSelect">
                        <PhoneSelect
                          checked={option.enrollmentStatus === 'ACTIVE'}
                          onChange={() => findAndSetMFAOption(option)}
                        />
                        SMS authentication{' '}
                        {phoneNumber ? `and send verification code to ${phoneNumber}` : null}
                      </StyledLabel>
                    </PhoneContainer>
                  );
                } else if (option.factorType === 'EMAIL') {
                  return (
                    <EmailContainer key={option.factorType}>
                      <EmailStyledLabel htmlFor="emailSelect">
                        <EmailSelect checked={true} readOnly />
                        Email authentication{' '}
                        {emailAddress ? `and send verification code to ${emailAddress}` : null}
                      </EmailStyledLabel>
                    </EmailContainer>
                  );
                }
              })}

              <Dialog open={modalOpen} onClose={handleModalClose}>
                <ModalContainer>
                  {/*If sms is pending activation we don't need to reaccept their phone so we re-open modal where they can resend*/}
                  {currentMFA.factorType === 'SMS' &&
                  phoneConfirmed === false &&
                  currentMFA.enrollmentStatus !== 'PENDING_ACTIVATION' ? (
                    <>
                      <ModalHeader>SMS authentication</ModalHeader>
                      <div>Please enter a phone number to receive the verification code.</div>
                      <PhoneInput
                        type="text"
                        mask="(999)999-9999"
                        onChange={(e) => setPhoneToUse(e.target.value)}
                      />
                      <ConfirmPhone onClick={() => enrollPhoneInMFA()}>
                        Confirm Phone Number
                      </ConfirmPhone>
                    </>
                  ) : (
                    <>
                      <ModalHeader>Please Confirm Code</ModalHeader> In order to proceed, please
                      verify the Multi-Factor Authentication code.{' '}
                      <ModalNote>
                        We sent a code to{' '}
                        {currentMFA && currentMFA.factorType === 'SMS'
                          ? phoneToUse
                            ? phoneToUse
                            : currentMFA.profile
                          : emailAddress}
                      </ModalNote>
                      <ConfirmCodeInput type="text" onChange={(e) => setMFACode(e.target.value)} />
                      <ConfirmMFACode
                        disabled={MFACode.length < 1}
                        onClick={() => confirmMFACodeEnabled()}
                      >
                        Confirm Code
                      </ConfirmMFACode>
                      <ResendMFACode
                        disabled={mfaCodeSent}
                        onClick={() => resendMFACode(currentMFA.factorId)}
                      >
                        Resend Code
                      </ResendMFACode>
                      <CancelMFA onClick={() => handleModalClose(currentMFA)}>Cancel</CancelMFA>
                    </>
                  )}
                </ModalContainer>
              </Dialog>
              <Dialog
                open={unEnrollModalOpen}
                onClose={() => {
                  setUnEnrollModalOpen(false);
                }}
              >
                <StyledMfaUnEnrollModal>
                  <StyledMfaUnEnrollHeader>Disable Authentication Method</StyledMfaUnEnrollHeader>
                  <MfaUnEnrollInformation>
                    Are you sure you want to disable your phone as an authentication method?
                  </MfaUnEnrollInformation>
                  <ButtonContainer>
                    <MfaUnEnrollButton
                      onClick={() => setUnEnrollModalOpen(false)}
                      className="cancel"
                    >
                      Cancel
                    </MfaUnEnrollButton>
                    <MfaUnEnrollButton
                      onClick={() => {
                        setUnEnrollModalOpen(false);
                        dispatch(disableMFAForPhone(customerId, mfaOption));
                        setPhoneConfirmed(false);
                        setPhoneToUse('');
                      }}
                      disabled={isLoading}
                    >
                      Disable
                    </MfaUnEnrollButton>
                  </ButtonContainer>
                </StyledMfaUnEnrollModal>
              </Dialog>
            </MFAEditContainer>
          ) : null}
        </MFAContainer>
      )}
    </>
  );
};

EnableMFAContainer.propTypes = {
  currentMFA: PropTypes.any,
  customerId: PropTypes.string,
  dispatch: PropTypes.func,
  emailAddress: PropTypes.string,
  factorId: PropTypes.string,
  isLoading: PropTypes.bool,
  i18n: PropTypes.any,
  mfaEnabled: PropTypes.bool,
  mfaOptions: PropTypes.any,
  modalOpen: PropTypes.bool,
  phoneNumber: PropTypes.string,
  userSelectedMFAOption: PropTypes.any,
};

const mapStateToProps = (state) => ({
  currentMFA: state.mfaSlice.currentMFA,
  customerId: state.loginSlice.user.preferred_username,
  emailAddress: state.mfaSlice.emailAddress,
  i18n: state.i18nSlice.i18n,
  isLoading: state.mfaSlice.isLoading,
  mfaEnabled: state.mfaSlice.mfaEnabled,
  mfaOptions: state.mfaSlice.mfaOptions,
  modalOpen: state.mfaSlice.modalOpen,
  phoneNumber: state.mfaSlice.phoneNumber,
});

export default connect(mapStateToProps)(EnableMFAContainer);
