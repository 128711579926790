import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  policies: [],
  isLoading: false,
  errors: null,
  iSubmitting: false,
  totalUploads: null,
  currentUploads: null,
  submittedPolicy: null,
};

export const policySlice = createSlice({
  name: 'policySlice',
  initialState,
  reducers: {
    setPolicies: (state, action) => {
      return { ...state, policies: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setSubmitting: (state, action) => {
      return { ...state, isSubmitting: action.payload };
    },
    setPolicyTotalUploads: (state, action) => {
      return { ...state, totalUploads: action.payload };
    },
    setPolicyCurrentUploads: (state, action) => {
      return { ...state, currentUploads: action.payload };
    },
    setSubmittingRow: (state, action) => {
      return { ...state, submittedPolicy: action.payload };
    },
  },
});
export const {
  setPolicies,
  setLoading,
  setErrors,
  setPolicyTotalUploads,
  setPolicyCurrentUploads,
  setSubmittingRow,
} = policySlice.actions;
export default policySlice.reducer;
