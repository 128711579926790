import { API } from '../../../utils/api';
import { getCustomerBillingInformation } from '../dashboard/billing/billingAPI';
import { setLoading, setPayPlanOptions } from './payPlanReducer';

export const getPayPlanOptions = policyRef => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get(`policies/${policyRef}/paymentPlans`).then(response => {
      let filteredPayPlanOptions = response.data.applicationPayPlanListItems.filter(plan => {
        return !(
          plan.name.includes('Mortgagee') ||
          plan.name.includes('Agent') ||
          plan.name.includes('Statement')
        );
      });
      dispatch(setPayPlanOptions(filteredPayPlanOptions));
      dispatch(setLoading(false));
    });
  };
};
export const updatePayPlan = (customerId, body, notify, billingId, hasOpenBalance, billingAccountsCountLimit) => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.post(`/policies/${customerId}/updatePaymentPlanRequest`, body)
      .then(async () => {
        notify('Your payment plan has been updated', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });

        return await dispatch(getCustomerBillingInformation(billingId, hasOpenBalance, billingAccountsCountLimit));
      })
      .catch(() => {
        notify(
          'There was an issue updating your payment plan. Please contact your insurance agent.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
