import { sortBy } from 'lodash';
import moment from 'moment';
import { ISO_DATE_FORMAT, SERVICE_PORTAL } from '../../common/constants';
import { setDateToPay } from './payPlanReducer';

export const updatePayPlanBody = (
  instrumentToPayWith,
  newPlan,
  payPlanDateToPay,
  customerId,
  paymentServiceAccountId,
) => {
  let requestPayload;
  let PaymentSource;
  if (
    (instrumentToPayWith?.methodCd === 'ACH' || instrumentToPayWith?.sourceType === 'ACH') &&
    !newPlan.payPlanCd.includes('Direct')
  ) {
    PaymentSource = {
      electronicPaymentSource: {
        achBankAccountNumber: instrumentToPayWith.achBankAccountNumber,
        achBankAccountTypeCd: instrumentToPayWith.achBankAccountTypeCd,
        achName: instrumentToPayWith.achName,
        achRoutingNumber: instrumentToPayWith.achRoutingNumber,
        achBankName: instrumentToPayWith.achBankName,
        achStandardEntryClassCd: instrumentToPayWith.achStandardEntryClassCd,
        sourceTypeCd: 'InstallmentSource',
        methodCd: instrumentToPayWith
          ? instrumentToPayWith.sourceType
            ? instrumentToPayWith.sourceType
            : instrumentToPayWith.methodCd
            ? instrumentToPayWith.methodCd
            : ''
          : '',
        sourceName: SERVICE_PORTAL,
        paymentServiceAccountId,
      },
    };
  } else if (!newPlan.payPlanCd.includes('Direct')) {
    PaymentSource = {
      electronicPaymentSource: {
        methodCd: instrumentToPayWith
          ? instrumentToPayWith.sourceType
            ? 'Credit Card'
            : instrumentToPayWith.methodCd
            ? instrumentToPayWith.methodCd
            : ''
          : '',
        customerPaymentProfileId: instrumentToPayWith
          ? instrumentToPayWith.customerPaymentProfileId
            ? instrumentToPayWith.customerPaymentProfileId
            : instrumentToPayWith?.id?.toString()
            ? instrumentToPayWith?.id?.toString()
            : ''
          : '',
        customerProfileId: `CUST-${customerId}`,
        creditCardNumber: instrumentToPayWith?.lastFour
          ? instrumentToPayWith.lastFour
          : instrumentToPayWith.creditCardNumber
          ? instrumentToPayWith.creditCardNumber
          : '',
        creditCardTypeCd: instrumentToPayWith?.cardType
          ? instrumentToPayWith.cardType
          : instrumentToPayWith.creditCardTypeCd
          ? instrumentToPayWith.creditCardTypeCd
          : '',
        sourceTypeCd: 'InstallmentSource',
        sourceName: SERVICE_PORTAL,
        paymentServiceAccountId,
      },
    };
  } else {
    PaymentSource = {};
  }
  requestPayload = {
    payPlanCd: newPlan.payPlanCd,
    ...(payPlanDateToPay && { paymentDay: payPlanDateToPay ? payPlanDateToPay.toString() : '' }),
    ...PaymentSource,
  };
  return requestPayload;
};
export const updatePayMethodBody = (instrumentToPayWith, account, customerId) => {
  let requestPayload;
  let PaymentSource;
  if (
    (instrumentToPayWith?.methodCd === 'ACH' || instrumentToPayWith?.sourceType === 'ACH') &&
    !account.payPlan.payPlanCd.includes('Direct')
  ) {
    PaymentSource = {
      electronicPaymentSource: {
        achBankAccountNumber: instrumentToPayWith.achBankAccountNumber,
        achBankAccountTypeCd: instrumentToPayWith.achBankAccountTypeCd,
        achName: instrumentToPayWith.achName,
        achRoutingNumber: instrumentToPayWith.achRoutingNumber,
        achBankName: instrumentToPayWith.achBankName,
        achStandardEntryClassCd: instrumentToPayWith.achStandardEntryClassCd,
        sourceTypeCd: 'InstallmentSource',
        methodCd: instrumentToPayWith
          ? instrumentToPayWith.sourceType
            ? instrumentToPayWith.sourceType
            : instrumentToPayWith.methodCd
            ? instrumentToPayWith.methodCd
            : ''
          : '',
        sourceName: SERVICE_PORTAL,
      },
    };
  } else if (!account.payPlan.payPlanCd.includes('Direct')) {
    PaymentSource = {
      electronicPaymentSource: {
        methodCd: instrumentToPayWith
          ? instrumentToPayWith.sourceType
            ? 'Credit Card'
            : instrumentToPayWith.methodCd
            ? instrumentToPayWith.methodCd
            : ''
          : '',
        customerPaymentProfileId: instrumentToPayWith
          ? instrumentToPayWith.customerPaymentProfileId
            ? instrumentToPayWith.customerPaymentProfileId
            : instrumentToPayWith?.id?.toString()
            ? instrumentToPayWith?.id?.toString()
            : ''
          : '',
        customerProfileId: `CUST-${customerId}`,
        creditCardNumber: instrumentToPayWith?.lastFour
          ? instrumentToPayWith.lastFour
          : instrumentToPayWith.creditCardNumber
          ? instrumentToPayWith.creditCardNumber
          : '',
        creditCardTypeCd: instrumentToPayWith?.cardType
          ? instrumentToPayWith.cardType
          : instrumentToPayWith.creditCardTypeCd
          ? instrumentToPayWith.creditCardTypeCd
          : '',
        sourceTypeCd: 'InstallmentSource',
        sourceName: SERVICE_PORTAL,
      },
    };
  } else {
    PaymentSource = {};
  }
  requestPayload = {
    payPlanCd: account.payPlan.payPlanCd,
    ...(account.arPolicy?.paymentDay && {
      paymentDay: account.arPolicy?.paymentDay ? account.arPolicy?.paymentDay.toString() : '',
    }),
    ...PaymentSource,
  };
  return requestPayload;
};
export const handlePayDateChange = (dayToPay, dispatch) => {
  let checkDate = dayToPay;
  if (checkDate > 31) {
    checkDate = 1;
  }
  dispatch(setDateToPay(Math.abs(checkDate)));
};
export const collapseEventBody = {
  category: 'show-payment-schedule-button',
  action: 'click',
  label: 'collapse',
};

export const expandEventBody = {
  category: 'show-payment-schedule-button',
  action: 'click',
  label: 'expand',
};
export const setStatementAccountsAndMerge = (
  fullAccount,
  paymentTypesMap,
  isScheduledOrPending,
) => {
  let billingHistory = [];
  let underlyingBillingAccounts = [];
  if (fullAccount.billingHistory) {
    billingHistory = fullAccount.billingHistory.filter(bill => paymentTypesMap.has(bill.typeCd));
  }
  if (fullAccount.underlyingBillingAccounts) {
    underlyingBillingAccounts = fullAccount.underlyingBillingAccounts.flatMap(receipt => {
      //flatmap takes just the pending receipts that meet our filter criteria
      if (receipt.pendingReceipts) {
        return receipt.pendingReceipts.filter(receipt => receipt.receiptAmt !== '0.00');
      }
    });
    underlyingBillingAccounts = underlyingBillingAccounts.filter(array => array !== undefined);
  }

  let merged = [].concat(billingHistory, underlyingBillingAccounts);

  merged = sortBy(merged, receipt => moment(receipt.receiptDt, ISO_DATE_FORMAT).valueOf());
  merged = merged.reverse();
  if (isScheduledOrPending) {
    merged = merged.filter(item => !paymentTypesMap.has(item.typeCd));
  } else {
    merged = merged.filter(item => paymentTypesMap.has(item.typeCd));
  }
  return merged;
};
export const setPolicyAccountsAndMerge = (fullAccount, paymentTypesMap, isScheduledOrPending) => {
  let billingHistory = [];
  let pendingReceipts = [];
  if (fullAccount.billingHistory) {
    billingHistory = fullAccount.billingHistory.filter(bill => paymentTypesMap.has(bill.typeCd));
  }
  if (fullAccount.pendingReceipts) {
    pendingReceipts = fullAccount.pendingReceipts.filter(receipt => receipt.receiptAmt !== '0.00');
  }

  let merged = [].concat(billingHistory, pendingReceipts);
  merged = sortBy(merged, receipt => moment(receipt.receiptDt, ISO_DATE_FORMAT).valueOf());
  merged = merged.reverse();
  if (isScheduledOrPending) {
    merged = merged.filter(item => !paymentTypesMap.has(item.typeCd));
  } else {
    merged = merged.filter(item => paymentTypesMap.has(item.typeCd));
  }

  return merged;
};
