import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import moment from 'moment';

import Loader from '../../../common/loader';
import { PaymentContainer } from '../styles';
import {
  AmountCard,
  AmountHeader,
  AmountText,
  ButtonContainer,
  CardContainer,
  InstructionsContainer,
  NotificationContainer,
  PaidWithCard,
  PaidWithHeader,
  PaidWithType,
  PayDate,
  PayDateCard,
  PayDateHeader,
  PayFailedButtonHome,
  PaymentHeader,
  StyledACHIcon,
  StyledButtonHome,
  StyledCreditIcon,
  StyledInfoIcon,
  StyledInformationMessage,
  StyledPrintButton,
  StyledPrintIcon,
  StyledSuccessHeader,
  StyledSuccessMessage,
  SuccessHeaderTop,
} from './styles';
import { LineDivider } from '../../../common/line-divider';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';
import { getText } from '../../../../utils/i18n';

export const MakeAPaymentStepFour = (props) => {
  const {
    accountNumber,
    confirmationNumber,
    isLoading,
    printButtonEnabled,
    response,
    errors,
    i18n,
  } = props;

  return (
    <PaymentContainer>
      <Helmet>
        <title>Make Payment - Confirmation</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {response ? (
            <PaymentContainer>
              <SuccessHeaderTop>Payment Success: {accountNumber}</SuccessHeaderTop>
              <LineDivider />
              {i18n?.makePayment?.stepFour?.paymentSuccess && (
                <InstructionsContainer
                  dangerouslySetInnerHTML={{ __html: i18n.makePayment.stepFour.paymentSuccess }}
                />
              )}
              <NotificationContainer>
                <StyledInformationMessage>
                  <StyledInfoIcon />
                  <StyledSuccessHeader>
                    Payment Success:{' '}
                    <StyledSuccessMessage>
                      {getText(
                        i18n,
                        'payment.paymentSuccess',
                        `Your payment request has been received and will be processed. When completed, your payment will be posted to your policy on the date shown. We recommend that you keep a copy of this confirmation for your records. Click the button below to print a copy for your records.`,
                      )}{' '}
                      {confirmationNumber && (
                        <>
                          Your confirmation number is <b>{confirmationNumber}</b>
                        </>
                      )}
                    </StyledSuccessMessage>
                  </StyledSuccessHeader>
                </StyledInformationMessage>
              </NotificationContainer>
              <CardContainer>
                <AmountCard>
                  <AmountHeader>Amount Paid</AmountHeader>
                  <AmountText>${parseFloat(response.payments[0].receiptAmt).toFixed(2)}</AmountText>
                </AmountCard>
                <PaidWithCard>
                  <PaidWithHeader>Paid with</PaidWithHeader>
                  <PaidWithType>
                    {response.paymentMethod === 'ACH' ? <StyledACHIcon /> : <StyledCreditIcon />}
                    {response.paymentMethod === 'ACH' ? 'Bank Account' : response.paymentMethod}
                  </PaidWithType>
                </PaidWithCard>
                <PayDateCard>
                  <PayDateHeader>Payment Date</PayDateHeader>
                  <PayDate>{moment(response.scheduledDate).format(DISPLAY_DATE_FORMAT)}</PayDate>
                </PayDateCard>
              </CardContainer>
              <ButtonContainer>
                {printButtonEnabled && (
                  <StyledPrintButton onClick={() => window.print()}>
                    <StyledPrintIcon />
                    Print a Receipt
                  </StyledPrintButton>
                )}
                <Link to="/dashboard">
                  <StyledButtonHome>Return Home</StyledButtonHome>
                </Link>
              </ButtonContainer>
            </PaymentContainer>
          ) : (
            <PaymentContainer>
              <PaymentHeader>Payment Failed</PaymentHeader>
              <LineDivider />
              {i18n?.makePayment?.stepFour?.paymentFailure && (
                <InstructionsContainer
                  dangerouslySetInnerHTML={{
                    __html: i18n.makePayment.stepFour.paymentFailure,
                  }}
                />
              )}
              <NotificationContainer>
                <StyledInfoIcon failed={true} />
                <StyledInformationMessage failed={true}>
                  <b>Payment Failed:</b> {errors}
                </StyledInformationMessage>
              </NotificationContainer>
              <ButtonContainer>
                <Link to="/dashboard">
                  <PayFailedButtonHome>Return Home</PayFailedButtonHome>
                </Link>
              </ButtonContainer>
            </PaymentContainer>
          )}
        </>
      )}
    </PaymentContainer>
  );
};
MakeAPaymentStepFour.propTypes = {
  errors: PropTypes.string,
  i18n: PropTypes.object,
  isLoading: PropTypes.bool,
  response: PropTypes.object,
  accountNumber: PropTypes.string,
  printButtonEnabled: PropTypes.bool,
  confirmationNumber: PropTypes.any,
};
const mapStateToProps = (state) => ({
  errors: state.makePaymentStepFourSlice.errors,
  i18n: state.i18nSlice.i18n,
  isLoading: state.makePaymentStepFourSlice.isLoading,
  response: state.makePaymentStepFourSlice.response,
  accountNumber: state.makePaymentSlice.accountToPayWith.billingAccount.accountNumber,
  printButtonEnabled: state.configurationSlice.features.features.printButtonEnabled,
  confirmationNumber: state.makePaymentStepFourSlice.confirmationNumber,
});
export default connect(mapStateToProps)(MakeAPaymentStepFour);
