import styled from 'styled-components';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

export const TwoColumnContainer = styled.div.attrs({})`
  display: grid;
  @media (max-width: 459px) {
  }
  @media (min-width: 460px) and (max-width: 699px) {
    grid-template-columns: 48% 48%;
    grid-template-rows: 1fr;
    column-gap: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 48% 48%;
    column-gap: 32px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 48% 48%;
    grid-template-rows: 1fr;
    column-gap: 32px;
  }
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const AddDriverButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 10%;
`;
export const CancelButton = styled.button.attrs({
  id: 'Cancel',
})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;

export const NextButton = styled.button.attrs({
  id: 'Next',
})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;

export const AddDriverFileUploadButton = styled.div.attrs({
  id: 'AddDriverFileUploadButton',
})`
  padding: 3px 15px;
  display: inline-block;
  -webkit-box-shadow: 1px 1px 2px 1px black; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow: 1px 1px 2px 1px black; /* Firefox 3.5 - 3.6 */
  box-shadow: 1px 1px 2px 1px black; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  :active {
    -webkit-box-shadow: none; /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
    -moz-box-shadow: none; /* Firefox 3.5 - 3.6 */
    box-shadow: none; /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */
  }
  @media (max-width: 384px) {
    margin-bottom: 5px;
  }
`;
export const AddDriverField = styled.form.attrs({})`
  min-width: 200px;
`;
export const PicturesContainer = styled.div.attrs({})`
  border: 2px solid ${(props) => props.theme.secondaryColor};
  padding: 10px 0 5px 10px;
  margin-top: 8px;
`;
export const ThumbnailContainer = styled.div.attrs({})`
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 10px;
`;
export const ImgThumbnail = styled.img.attrs({})`
  width: 120px;
  height: 120px;
`;
export const FileThumbnailContainer = styled.div.attrs({})`
  display: inline-block;
  margin-right: 20px;
`;

export const AddDriverHeader = styled.h4.attrs({
  id: 'addDriverHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.001rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const PhotoIcon = styled(InsertPhotoIcon).attrs({
  className: 'fa fa-picture-o',
  'aria-hidden': true,
})`
  position: relative;
  top: 6px;
`;
PhotoIcon.displayName = 'PhotoIcon';

export const AddDriverDateInputField = styled.input.attrs({
  type: 'date',
})`
  height: 30px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const AddDriverTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const GarbageIconContainer = styled.div.attrs({})`
  margin-left: 15px;
`;
export const GarbageIcon = styled(DeleteOutlineIcon).attrs({
  className: 'fa fa-trash-o',
})`
  cursor: pointer;
  margin-left: -15px;
`;
export const FileName = styled.span`
  word-break: break-word;
  overflow: hidden;
  height: 40px;
  display: inline-block;
  max-width: 120px;
`;
export const FileThumbnail = styled(InsertDriveFileIcon).attrs({
  className: 'fa fa-file',
})`
  font-size: 80px;
  display: block;
`;
