import { API } from '../../../utils/api';
import { setErrors, setLoading, setPendingRequests } from './pendingRequestsReducer';

export const getPendingRequests = customerId => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get(`/customerPolicyChanges?customerRef=${customerId}`)
      .then(response => {
        dispatch(setPendingRequests(response.data.changeRequestItems));
      })
      .catch(e => {
        dispatch(setErrors(e));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
