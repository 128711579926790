import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrimaryPhoneInfo, getSecondaryPhoneInfo } from './selectors';
import EditingPhones from './editPhone';
import { setEditingPhone, setEmailErrors, setPhoneErrors } from './profileReducer';
import {
  EditPhoneButtonContainer,
  EditPhonesLink,
  PhoneDescription,
  PhoneNumberContainer,
  PhoneNumbersLabel,
  ProfileError,
  ProfilePhoneNumberLabel,
  StyledSecondaryPhoneContainer,
} from './styles';
import { getText } from '../../../utils/i18n';
import TooltipInfo from '../../common/tooltip';

export const PhoneDetails = (props) => {
  const {
    ariaLabel,
    editingPhone,
    isDashboard,
    notificationPhone,
    phoneErrors,
    phoneNumberPrimary,
    phoneNumberSecondary,
    primaryPhoneNameType,
    secondaryPhoneNameType,
    i18n,
  } = props;

  React.useEffect(() => {
    props.dispatch(setEmailErrors(null));
    props.dispatch(setPhoneErrors(null));
  }, []);

  const cancelPhone = () => {
    props.dispatch(setEditingPhone(false));
    props.dispatch(setPhoneErrors(null));
  };

  return (
    <>
      {phoneErrors && <ProfileError>{phoneErrors}</ProfileError>}
      <PhoneNumberContainer $isDashboard={isDashboard}>
        {phoneNumberPrimary && (
          <>
            <>
              <PhoneNumbersLabel>
                Phone{' '}
                <PhoneDescription>
                  (Used for SMS notifications)
                  <TooltipInfo
                    title={getText(i18n, 'dashboard.customerInformation.primaryPhoneTooltip')}
                  />
                </PhoneDescription>
                {editingPhone ? (
                  <ProfilePhoneNumberLabel>
                    Mobile phone number
                    <TooltipInfo
                      title={getText(
                        i18n,
                        'dashboard.customerInformation.mobilePhoneNumberTooltip',
                      )}
                    />
                  </ProfilePhoneNumberLabel>
                ) : (
                  <ProfilePhoneNumberLabel>
                    {notificationPhone && notificationPhone !== '' ? notificationPhone : 'None'}
                  </ProfilePhoneNumberLabel>
                )}
              </PhoneNumbersLabel>
              {!editingPhone && (
                <>
                  <EditPhoneButtonContainer>
                    <EditPhonesLink onClick={() => props.dispatch(setEditingPhone(true))}>
                      Edit
                    </EditPhonesLink>
                  </EditPhoneButtonContainer>
                </>
              )}
            </>
          </>
        )}
      </PhoneNumberContainer>
      {editingPhone ? (
        <EditingPhones isDashboard={isDashboard} cancelPhone={cancelPhone} ariaLabel={ariaLabel} />
      ) : null}
    </>
  );
};

PhoneDetails.propTypes = {
  ariaLabel: PropTypes.string,
  dispatch: PropTypes.func,
  editingPhone: PropTypes.bool,
  isDashboard: PropTypes.bool,
  notificationPhone: PropTypes.string,
  phoneErrors: PropTypes.any,
  phoneNumberPrimary: PropTypes.string,
  phoneNumberSecondary: PropTypes.string,
  primaryPhoneNameType: PropTypes.string,
  secondaryPhoneNameType: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  phoneErrors: state.profileSlice.phoneErrors,
  editingPhone: state.profileSlice.editingPhone,
  notificationPhone: state.notificationSlice.notificationPhone,
  phoneNumberPrimary: getPrimaryPhoneInfo(state).phoneNumber,
  phoneNumberSecondary: getSecondaryPhoneInfo(state).phoneNumber,
  primaryPhoneNameType: getPrimaryPhoneInfo(state).phoneName,
  secondaryPhoneNameType: getSecondaryPhoneInfo(state).phoneName,
  i18n: state.i18nSlice.i18n,
});
PhoneDetails.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(PhoneDetails);
