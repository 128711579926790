import { API, refreshToken, TOKENIZED_API } from '../../../../utils/api/index';
import {
  setBankInformation,
  setFundingInstrumentError,
  setFundingInstruments,
  setIsLoading,
  setRoutingNumberError,
} from './makePaymentStepTwoReducer';
import { SERVICE_PORTAL } from '../../../common/constants';

export const getFundingInstruments = (systemId, notify, instrumentToPayWith) => {
  return dispatch => {
    API.get(`/billingAccounts/${systemId}/paymentSources?sourceName=${SERVICE_PORTAL}`)
      .then(response => {
        let instruments = [];
        instruments.push(response.data.electronicPaymentSource);
        if (instrumentToPayWith && instrumentToPayWith?.isTemporary) {
          instruments.push(instrumentToPayWith);
        }
        instruments = instruments.flatMap(instrument => instrument);
        instruments = instruments.filter(instrument => instrument !== undefined);
        dispatch(setFundingInstruments(instruments));
      })
      .catch(err => {
        let instruments = [];
        if (instrumentToPayWith) {
          instruments.push(instrumentToPayWith);
          dispatch(setFundingInstruments(instruments));
        } else {
          dispatch(
            setFundingInstrumentError(
              err.data
                ? `Unable to retrieve funding instruments right now, please add manually for now ${err.data}`
                : 'Unable to retrieve funding instruments right now, please add manually for now ',
            ),
          );
          notify('Unable to retrieve funding instruments right now, please add manually for now ', {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          });
        }
      })
      .finally(() => {
        dispatch(setIsLoading(false));
      });
  };
};

export const deleteFundingInstrument = (systemId, paymentOnFileId, notify) => {
  return dispatch => {
    API.delete(`/billingAccounts/${systemId}/paymentSources/${paymentOnFileId}`)
      .then(() => {
        dispatch(getFundingInstruments(systemId, notify));
      })
      .catch(() => {
        notify('Unable to delete the payment source', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
      });
  };
};

export const verifyRoutingNumber = (routingNumber, isAnonymousPay = false) => {
  const _API = isAnonymousPay ? TOKENIZED_API : API;
  if (isAnonymousPay) {
    refreshToken();
  }
  return dispatch => {
    _API
      .get(`/bankInformation/${routingNumber}`)
      .then(response => {
        dispatch(setBankInformation(response.data));
        dispatch(setRoutingNumberError(''));
      })
      .catch(() => {
        dispatch(setRoutingNumberError('Routing number is not valid.'));
      });
  };
};
