import { useOktaAuth } from '@okta/okta-react';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { IdleTimerConsumer, IdleTimerProvider } from 'react-idle-timer';

import { getCustomerById } from './features/customer/customerAPI';
import { setCrossLinkRegistrationToken, setUser } from './features/login/loginReducer';
import Dashboard from './features/dashboard/index';
import UserLogin from './features/login/index';
import Loader from './common/loader';
import CreateAccount from './features/create-account';
import { getQueryParams, setBaseUrl } from '../utils/api';
import MakePaymentAnonymous from './features/make-payment-anonymous';
import ReportClaimAnonymous from './features/report-claim-anonymous';
import ForgotUsername from './features/forgot-username';
import { gaPageView } from '../utils/google-analytics';
import { setupPendo } from '../utils/pendo';
import { Button, Dialog, DialogActions, DialogContent } from '@mui/material';
import { StayLoggedInButton } from './common/buttons/styles';
import { millisecondsToMinutes } from '../utils/validators/generalValidators';
import { ga4PageView } from '../utils/google-analytics-4';
import { ErrorPage } from './common/error';
import { isDownTimeModeActive } from './features/login/selector';
import ForgotPassword from './features/forgot-password';
import { useSnackbar } from 'notistack';

const Home = props => {
  let history = useHistory();
  const { authState, oktaAuth } = useOktaAuth();
  const { dispatch, user, template, baseUrl, features, isServiceUnavailable } = props;
  const href = window.location.href;
  const containSignupToken = href.includes('registrationToken=');
  const { enqueueSnackbar } = useSnackbar();
  const setCustomer = async id => {
    await dispatch(getCustomerById(id, enqueueSnackbar));
  };
  const [idleTimeOutModal, setIdleTimeOutModal] = useState(false);
  const location = useLocation();
  const timeoutTime = 1000 * 60 * 15;

  useEffect(() => {
    if (
      !containSignupToken &&
      history.location.pathname !== '/createAccount' &&
      history.location.hash !== '#/forgotUsername'
    ) {
      if (!authState || !authState.isAuthenticated) {
        dispatch(setUser(null));
        history.push('/');
      } else if (authState && authState.isAuthenticated) {
        oktaAuth.getUser().then(userInfo => {
          dispatch(setUser(userInfo));
          setCustomer(userInfo.customer_id);
          if (history.location.pathname === '/') {
            // prevents navigation to dashboard from other views on okta token refresh
            history.push('/dashboard');
          }
        });
      }
    }
  }, [authState, oktaAuth]);

  useEffect(() => {
    gaPageView(location.pathname);
    ga4PageView(location.pathname);
  }, [location]);

  useEffect(() => {
    setBaseUrl(baseUrl);
    // base url should be available before calling registration api
    if (containSignupToken && baseUrl) {
      const signupToken = getQueryParams('registrationToken', href);
      props.dispatch(setCrossLinkRegistrationToken(signupToken));
      history.push('/createAccount');
    }
  }, [baseUrl]);

  useEffect(() => {
    // setup pendo
    if (features.companyName) {
      setupPendo(features.companyName, user?.preferred_username || 'anonymous');
    }
  }, [user, features]);

  const setPaymentSdkUrl = url => {
    if (!url) {
      return;
    }
    const paymentSdkScriptTag = document.getElementById('paymentSdkScript');
    paymentSdkScriptTag.setAttribute('src', url);
  };
  useEffect(() => {
    const isDownTimeFlag = isDownTimeModeActive(features.downTime);
    if (isDownTimeFlag || isServiceUnavailable) {
      history.replace('/error');
    }
    // set payment sdk url
    setPaymentSdkUrl(features.paymentSdkUrl);
  }, [features]);

  const handleSignout = () => {
    setIdleTimeOutModal(false);
    oktaAuth.signOut();
    dispatch(setUser(null));
  };
  const handleOK = (idleTimer, setIdleTimeOutModal) => {
    idleTimer.reset();
    setIdleTimeOutModal(false);
    oktaAuth.session.refresh();
  };
  const onPrompt = () => {
    setIdleTimeOutModal(true);
  };

  const onIdle = () => {
    setIdleTimeOutModal(true);
  };

  return (
    <>
      {!authState ||
        authState.isPending ||
        (authState && authState.isAuthenticated && !user) ||
        (authState && authState.isAuthenticated && !template && <Loader />)}
      {authState && authState.isAuthenticated && user && template && (
        <IdleTimerProvider
          timeout={timeoutTime}
          promptBeforeIdle={1000 * 60 * 2}
          startOnMount
          onPrompt={onPrompt}
          onIdle={onIdle}
          crossTab={true}
          debounce={250}
        >
          <IdleTimerConsumer>
            {idleTimer => (
              <>
                {idleTimer.getRemainingTime() === 0 ? (
                  handleSignout()
                ) : (
                  <Dialog
                    open={idleTimeOutModal && (idleTimer.isIdle() || idleTimer.isPrompted())}
                    onClose={() => setIdleTimeOutModal(false)}
                    aria-labelledby="confirm-dialog"
                  >
                    <DialogContent>
                      <h3>Stay signed in?</h3>
                      <span>
                        To protect your privacy, user sessions expire after{' '}
                        {millisecondsToMinutes(timeoutTime)} minutes of inactivity.
                        <br />
                        To prevent losing any unsaved changes, select Stay Logged In.
                        <br />
                      </span>
                      <br />
                      <DialogActions>
                        <Button
                          color="secondary"
                          onClick={() => {
                            handleSignout();
                          }}
                          variant="contained"
                        >
                          Logout
                        </Button>
                        <StayLoggedInButton
                          color="primary"
                          onClick={() => handleOK(idleTimer, setIdleTimeOutModal)}
                          disabled={idleTimer.getRemainingTime() === 0}
                          variant="contained"
                        >
                          Stay Logged In
                        </StayLoggedInButton>
                      </DialogActions>
                    </DialogContent>
                  </Dialog>
                )}
                <Dashboard />
              </>
            )}
          </IdleTimerConsumer>
        </IdleTimerProvider>
      )}
      {!authState ||
        (!authState.isAuthenticated && (
          <>
            {history.location.pathname === '/error' ? (
              <ErrorPage />
            ) : history.location.pathname === '/createAccount' ? (
              <CreateAccount />
            ) : history.location.pathname === '/makePaymentAnonymous' ? (
              <MakePaymentAnonymous />
            ) : history.location.pathname === '/reportClaimNotLoggedIn' ? (
              <ReportClaimAnonymous />
            ) : history.location.hash === '#/forgotUsername' ? (
              <ForgotUsername />
            ) : history.location.hash === '#/forgotPassword' ? (
              <ForgotPassword />
            ) : (
              <UserLogin />
            )}
          </>
        ))}
    </>
  );
};
Home.propTypes = {
  dispatch: PropTypes.any,
  user: PropTypes.any,
  idleTimeOutModal: PropTypes.any,
  template: PropTypes.string,
  baseUrl: PropTypes.string,
  features: PropTypes.any,
  isServiceUnavailable: PropTypes.bool,
};

export const mapStateToProps = state => ({
  state,
  user: state.loginSlice.user,
  template: state.configurationSlice.features.template,
  baseUrl: state.configurationSlice.features.inowEndpoint,
  features: state.configurationSlice.features,
  isServiceUnavailable: state.configurationSlice.isServiceUnavailable,
});

export default connect(mapStateToProps)(Home);
