import React, { createRef, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  getPolicyAttachments,
  getPolicyDetails,
  getPolicyDocuments,
  getPolicyForms,
} from './policiesAPI';
import Loader from '../../common/loader';
import AuthenticatedLink from '../../common/authenticated-link';
import sanitizeHtml from 'sanitize-html';

import {
  CustomContent,
  DocumentsHeader,
  DocumentTypeContainer,
  DocumentTypeLabel,
  DocumentTypeLabelMobile,
  DownloadLinkContainer,
  DownloadLinkLabel,
  DownloadLinkLabelMobile,
  FileIcon,
  LoaderMargin,
  MapMarker,
  PdfIcon,
  PolicyAttachmentsDataContainer,
  PolicyAttachmentsLabelContainer,
  PolicyAttachmentsUploadButtonContainer,
  PolicyDetailsContainer,
  PolicyDetailsHeader,
  PolicyDetailsHelperText,
  PolicyDocumentsContainer,
  PolicyDocumentsDataContainer,
  PolicyDocumentsLabelContainer,
  PolicyFormsDataContainer,
  PolicyFormsLabelContainer,
  PolicyInfoContainer,
} from './styles';
import AddressBox from './address-box';
import GoPaperless from './goPaperless';
import ApiError from '../../common/api-error';
import { getText } from '../../../utils/i18n';
import FileUploadModal from '../file-upload/index';
import { getPolicyEmail } from '../user-profile/selectors';

export const PolicyDetails = (props) => {
  const {
    coverageDetailsAllowed,
    policyDocuments,
    policyDetails,
    isLoading,
    isDetailsLoading,
    location: {
      state: { policyNumber, insured, uploadDocumentsClicked },
    },
    errors,
    paperlessChoiceUpdateAllowed,
    forms,
    showPolicyForms,
    showPolicyFormsDownloadLink,
    i18n,
    policies,
    policyAttachments,
    policyDocumentUpload,
    policyUserAttachmentsDisplay,
    useInsuredAttachments,
  } = props;

  const policyRef = props.location.state.policySystemId;
  const effectiveDt = props.location.state.effectiveDt;

  const getInsuredAddresses = (insured) => {
    let insuredParty;
    // get InsuredParty partyInfo
    if (insured && insured.partyInfo) {
      insuredParty = insured.partyInfo.filter(
        (partyInfo) => partyInfo.partyTypeCd === 'InsuredParty',
      );
    }
    // return non-empty addresses
    return insuredParty[0].addresses.filter((address) => !!address.addr1);
  };

  const addresses = getInsuredAddresses(insured);

  const createFormDownloadLink = (productFormIdRef) => {
    return `policies/${policyRef}/forms/${productFormIdRef}/content`;
  };

  const uploadButtonRef = createRef();

  const refreshAttachments = () => {
    props.dispatch(getPolicyAttachments(policyRef, useInsuredAttachments));
  };

  useEffect(() => {
    props.dispatch(getPolicyDocuments(policyRef));
    if (policyUserAttachmentsDisplay) {
      refreshAttachments();
    }
    props.dispatch(getPolicyDetails(policyRef, effectiveDt));
    props.dispatch(getPolicyForms(policyRef));
  }, []);

  useEffect(() => {
    if (!isLoading) {
      if (uploadDocumentsClicked && uploadButtonRef.current) {
        // scroll to upload button, if directed from dashboard after click on Upload Documents link
        uploadButtonRef.current.scrollIntoView();
      }
    }
  }, [isLoading]);

  const policyHelperText = getText(i18n, 'policyDetails.helper.policy', '');
  const driversHelperText = getText(i18n, 'policyDetails.helper.drivers', '');
  const propertyHelperText = getText(i18n, 'policyDetails.helper.property', '');
  const addressesHelperText = getText(i18n, 'policyDetails.helper.addresses', '');
  const documentsHelperText = getText(i18n, 'policyDetails.helper.documents', '');
  const coverageHelperText = getText(i18n, 'policyDetails.helper.coverage', '');
  const formsHelperText = getText(i18n, 'policyDetails.helper.forms', '');

  return (
    <PolicyDetailsContainer>
      <Helmet>
        <title>Policy Details</title>
      </Helmet>
      {policyHelperText !== '' && (
        <PolicyDetailsHelperText>{policyHelperText}</PolicyDetailsHelperText>
      )}
      <PolicyDetailsHeader id="Number">Policy Number {policyNumber}</PolicyDetailsHeader>
      {isDetailsLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : errors ? (
        <ApiError body="Unable to get coverage information." variant="nomargin" />
      ) : (
        <>
          {policyDetails.policyDetailsHtml && (
            <>
              <PolicyInfoContainer>
                <CustomContent
                  id="view_policy_0"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(policyDetails.policyDetailsHtml),
                  }}
                />
              </PolicyInfoContainer>
            </>
          )}

          {policyDetails.propertyDetailHtml && (
            <>
              <PolicyDetailsHeader id="Property">
                Property
                {propertyHelperText !== '' && (
                  <PolicyDetailsHelperText>{propertyHelperText}</PolicyDetailsHelperText>
                )}
              </PolicyDetailsHeader>
              <PolicyInfoContainer>
                <CustomContent
                  id="view_policy_1"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(policyDetails.propertyDetailHtml),
                  }}
                />
              </PolicyInfoContainer>
            </>
          )}

          {policyDetails.vehiclesDetailHtml && coverageDetailsAllowed && (
            <>
              <PolicyDetailsHeader id="Coverage">
                Coverage
                {coverageHelperText !== '' && (
                  <PolicyDetailsHelperText>{coverageHelperText}</PolicyDetailsHelperText>
                )}
              </PolicyDetailsHeader>
              <PolicyInfoContainer>
                <CustomContent
                  id="view_policy_2"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(policyDetails.vehiclesDetailHtml),
                  }}
                />
              </PolicyInfoContainer>
            </>
          )}

          {policyDetails.driversDetailHtml && (
            <>
              <PolicyDetailsHeader id="Drivers">
                Drivers
                {driversHelperText !== '' && (
                  <PolicyDetailsHelperText>{driversHelperText}</PolicyDetailsHelperText>
                )}
              </PolicyDetailsHeader>
              <PolicyInfoContainer>
                <CustomContent
                  id="view_policy_3"
                  dangerouslySetInnerHTML={{
                    __html: sanitizeHtml(policyDetails.driversDetailHtml),
                  }}
                />
              </PolicyInfoContainer>
            </>
          )}
        </>
      )}
      <PolicyDetailsHeader id="Addresses">
        <MapMarker />
        Addresses
        {addressesHelperText !== '' && (
          <PolicyDetailsHelperText>{addressesHelperText}</PolicyDetailsHelperText>
        )}
      </PolicyDetailsHeader>
      <AddressBox id="AddressBox" addresses={addresses} />
      {showPolicyForms && forms && forms.length > 0 && (
        <>
          <DocumentsHeader>
            <FileIcon />
            Forms
            {formsHelperText !== '' && (
              <PolicyDetailsHelperText>{formsHelperText}</PolicyDetailsHelperText>
            )}
          </DocumentsHeader>
          <PolicyDocumentsContainer>
            {isLoading ? (
              <LoaderMargin>
                <Loader />
              </LoaderMargin>
            ) : (
              <>
                <PolicyFormsLabelContainer>
                  <DocumentTypeLabel id="FormDescription">Description</DocumentTypeLabel>
                </PolicyFormsLabelContainer>
                {errors ? (
                  <ApiError body="There was an error loading your forms." variant="table" />
                ) : (
                  <>
                    {forms.map((form) => {
                      return (
                        <PolicyFormsDataContainer key={form.productFormIdRef}>
                          <DocumentTypeContainer>
                            <DocumentTypeLabelMobile>Description</DocumentTypeLabelMobile>
                            <div>{form.displayDesc}</div>
                          </DocumentTypeContainer>
                          {showPolicyFormsDownloadLink && (
                            <DownloadLinkContainer
                              aria-label={`View Sample Form for ${form.displayDesc}`}
                            >
                              <AuthenticatedLink
                                url={createFormDownloadLink(form.productFormIdRef)}
                                filename={form.displayDesc + '.pdf'}
                              >
                                <PdfIcon />
                                View Sample
                              </AuthenticatedLink>
                            </DownloadLinkContainer>
                          )}
                        </PolicyFormsDataContainer>
                      );
                    })}
                  </>
                )}
              </>
            )}
          </PolicyDocumentsContainer>
        </>
      )}
      <DocumentsHeader>
        <FileIcon />
        Documents
        {documentsHelperText !== '' && (
          <PolicyDetailsHelperText>{documentsHelperText}</PolicyDetailsHelperText>
        )}
      </DocumentsHeader>
      <PolicyDocumentsContainer>
        {isLoading ? (
          <LoaderMargin>
            <Loader />
          </LoaderMargin>
        ) : (
          <>
            <PolicyDocumentsLabelContainer>
              <DocumentTypeLabel id="DocumentType">Document Type</DocumentTypeLabel>
              <DownloadLinkLabel>Download Link</DownloadLinkLabel>
              <DocumentTypeLabel id="Date">Date</DocumentTypeLabel>
            </PolicyDocumentsLabelContainer>
            {errors ? (
              <ApiError body="There was an error loading your documents." variant="table" />
            ) : (
              <>
                {policyDocuments &&
                  policyDocuments.map((document) => {
                    return (
                      <PolicyDocumentsDataContainer key={document.ref}>
                        <DocumentTypeContainer>
                          <DocumentTypeLabelMobile>Document Type</DocumentTypeLabelMobile>
                          <div>{document.name}</div>
                        </DocumentTypeContainer>
                        <DownloadLinkContainer aria-label={`Download ${document.name}`}>
                          <DownloadLinkLabelMobile>Download Link</DownloadLinkLabelMobile>
                          <AuthenticatedLink
                            url={document._links[0].href}
                            filename={document.name + '.pdf'}
                          >
                            <PdfIcon />
                            Download
                          </AuthenticatedLink>
                        </DownloadLinkContainer>
                        <DocumentTypeContainer>
                          <DocumentTypeLabelMobile>Date</DocumentTypeLabelMobile>
                          <div>{document.addDt}</div>
                        </DocumentTypeContainer>
                      </PolicyDocumentsDataContainer>
                    );
                  })}
              </>
            )}
            {policyUserAttachmentsDisplay && (
              <>
                <PolicyAttachmentsLabelContainer>
                  <DocumentTypeLabel id="AttachmentType">Document Type</DocumentTypeLabel>
                  <DocumentTypeLabel id="AttachmentDescription">Description</DocumentTypeLabel>
                  <DownloadLinkLabel>Download Link</DownloadLinkLabel>
                  <DocumentTypeLabel id="UploadDate">Upload Date</DocumentTypeLabel>
                </PolicyAttachmentsLabelContainer>
                {errors ? (
                  <ApiError body="There was an error loading your attachments." variant="table" />
                ) : (
                  <>
                    {policyAttachments &&
                      policyAttachments.map((document) => {
                        return (
                          <PolicyAttachmentsDataContainer key={document.ref}>
                            <DocumentTypeContainer>
                              <DocumentTypeLabelMobile>Document Type</DocumentTypeLabelMobile>
                              <div>{document.name}</div>
                            </DocumentTypeContainer>
                            <DocumentTypeContainer>
                              <DocumentTypeLabelMobile>Description</DocumentTypeLabelMobile>
                              <div>{document.description}</div>
                            </DocumentTypeContainer>
                            <DownloadLinkContainer>
                              <DownloadLinkLabelMobile>Download Link</DownloadLinkLabelMobile>
                              <AuthenticatedLink
                                url={document._links[0].href}
                                filename={document.name + '.pdf'}
                              >
                                <PdfIcon />
                                Download
                              </AuthenticatedLink>
                            </DownloadLinkContainer>
                            <DocumentTypeContainer>
                              <DocumentTypeLabelMobile>Upload Date</DocumentTypeLabelMobile>
                              <div>{document.addDt}</div>
                            </DocumentTypeContainer>
                          </PolicyAttachmentsDataContainer>
                        );
                      })}
                  </>
                )}
              </>
            )}
            {policyDocumentUpload && (
              <PolicyAttachmentsUploadButtonContainer>
                <FileUploadModal
                  id="fileUploadModal"
                  type="Policy"
                  beanRef={policyRef}
                  refreshAttachments={refreshAttachments}
                />
              </PolicyAttachmentsUploadButtonContainer>
            )}
            <div ref={uploadButtonRef} />
            {paperlessChoiceUpdateAllowed &&
              getPolicyEmail(policies.find((policy) => policy.policyNumber === policyNumber)) && (
                <GoPaperless policyNumber={policyNumber} />
              )}
          </>
        )}
      </PolicyDocumentsContainer>
    </PolicyDetailsContainer>
  );
};

PolicyDetails.propTypes = {
  dispatch: PropTypes.func,
  coverageDetailsAllowed: PropTypes.bool,
  policyDocuments: PropTypes.array,
  policyDetails: PropTypes.object,
  isLoading: PropTypes.bool,
  isDetailsLoading: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      policySystemId: PropTypes.string,
      policyNumber: PropTypes.string,
      insured: PropTypes.object,
      uploadDocumentsClicked: PropTypes.bool,
      effectiveDt: PropTypes.string,
    }),
  }),
  paperlessChoiceUpdateAllowed: PropTypes.bool,
  errors: PropTypes.any,
  forms: PropTypes.array,
  showPolicyForms: PropTypes.bool,
  showPolicyFormsDownloadLink: PropTypes.bool,
  i18n: PropTypes.object,
  policies: PropTypes.array,
  policyAttachments: PropTypes.array,
  policyDocumentUpload: PropTypes.bool,
  policyUserAttachmentsDisplay: PropTypes.bool,
  useInsuredAttachments: PropTypes.bool,
};
const mapStateToProps = (state) => ({
  coverageDetailsAllowed: state.configurationSlice.features.features.coverageDetails,
  errors: state.policyDetailsSlice.errors,
  isLoading: state.policyDetailsSlice.isLoading,
  isDetailsLoading: state.policyDetailsSlice.isDetailsLoading,
  policyDocuments: state.policyDetailsSlice.policyDocuments,
  policies: state.policySlice.policies,
  policyDocumentUpload: state.configurationSlice.features.features.policyDocumentUpload,
  policyDetails: state.policyDetailsSlice.policyDetails,
  paperlessChoiceUpdateAllowed: state.configurationSlice.features.features.paperlessChoiceUpdate,
  forms: state.policyDetailsSlice.forms,
  showPolicyForms: state.configurationSlice.features.features.policyForms,
  showPolicyFormsDownloadLink: state.configurationSlice.features.features.policyFormsDownload,
  i18n: state.i18nSlice.i18n,
  policyAttachments: state.policyDetailsSlice.policyAttachments,
  policyUserAttachmentsDisplay:
    state.configurationSlice.features.features.policyUserAttachmentsDisplay,
  useInsuredAttachments: state.configurationSlice.features.features.useInsuredAttachments,
  state,
});
PolicyDetails.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(PolicyDetails);
