import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimDetails: {},
  isLoading: false,
  errors: null,
  claimAttachments: [],
};

export const claimDetailsSlice = createSlice({
  name: 'claimDetailsSlice',
  initialState,
  reducers: {
    setClaimDetails: (state, action) => {
      return { ...state, claimDetails: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setClaimAttachments: (state, action) => {
      return { ...state, claimAttachments: action.payload };
    },
  },
});

export const {
  setClaimDetails,
  setLoading,
  setErrors,
  setClaimAttachments,
} = claimDetailsSlice.actions;
export default claimDetailsSlice.reducer;
