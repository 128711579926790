// accounts.js example duck
//  https://redux-toolkit.js.org/api/createReducer
//  https://redux-toolkit.js.org/api/createAction
// https://redux-toolkit.js.org/api/createAction#using-prepare-callbacks-to-customize-action-contents
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  billingAccounts: [],
  errors: null,
  paymentTypes: {},
};

export const billingSlice = createSlice({
  name: 'billingSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setBillingAccounts: (state, action) => {
      return { ...state, billingAccounts: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setPaymentTypes: (state, action) => {
      return { ...state, paymentTypes: action.payload };
    },
  },
});
export const { setLoading, setBillingAccounts, setErrors, setPaymentTypes } = billingSlice.actions;
export default billingSlice.reducer;
