import styled from 'styled-components';
import { Input } from 'antd';

export const LookupAddressInput = styled(Input)`
  width: 100%;
  height: 30px;
`;
export const AddressLookupHeader = styled.h4.attrs({
  id: 'AddressLookupHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  margin-top: 16px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

const verificationMessage = styled.div`
  margin-top: 5px;
  font-size: 12px;
`;
export const ErrorMessage = styled(verificationMessage)`
  color: red;
`;

export const VerificationMessage = styled(verificationMessage)`
  color: green;
`;
export const VerifyingMessage = styled(verificationMessage)`
  color: ${props => props.theme.primaryColor};
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const ManualAddressContainer = styled.div.attrs({})`
  display: grid;
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }
`;

export const ManualAddressFieldsContainer = styled.div.attrs({})`
  display: grid;
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    width: 50%;
    margin-top: 8px;
    margin-bottom: 8px;
  }
`;

export const ManualAddressTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  margin-bottom: 10px;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: 100%;
  }
`;

export const ManualAddressGenericField = styled.div.attrs({})`
  min-width: 200px;
`;
export const ManualAddressFieldHeader = styled.h4.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 5px;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const StateSelectComponent = styled.div.attrs({})`
  margin-bottom: 10px;
`;

export const ManualAddressSmallFieldsContainer = styled.div.attrs({
  id: 'zipCountyContainer',
})`
  display: grid;
  width: 100%;
  @media (max-width: 599px) {
    row-gap: 16px;
    margin-top: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
    margin-bottom: 5px;
  }
`;
export const ManualAddressZipInput = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 96.6%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ManualAddressZipErrorMessage = styled.span.attrs({})`
  color: red;
  margin: 0;
`;
