import { API, TOKENIZED_API } from '../../../utils/api';
import get from 'lodash/get';
import {
  setLoading,
  setLookupAddress,
  setLookupAddressVerificationError,
  setLookupAddressVerified,
} from './addressesReducer';
import { defaultLookupAddress } from './addressUtils';

const placeMetadataCache = {};
const placeVerificationCache = {};

export const getPlaceMetadata = (place, isAnonymousUser = false) => {
  return dispatch => {
    dispatch(setLookupAddress(defaultLookupAddress));
    dispatch(setLookupAddressVerificationError(false));
    const cachedMetadata = placeMetadataCache[place.place_id];
    if (cachedMetadata) {
      dispatch(setLookupAddress(cachedMetadata.data));
    } else {
      dispatch(setLoading(true));
      const _API = isAnonymousUser ? TOKENIZED_API : API;
      _API
        .get(`/addresses/googlePlacesFill?placeId=${place.place_id}`)
        .then(response => {
          const metadata = response;
          placeMetadataCache[place.place_id] = metadata;
          const address = metadata.data;

          return _API
            .post(`/addresses/verificationRequest`, address)
            .then(v => {
              let verification = (placeVerificationCache[place.place_id] = v);
              let verifiedAddress = null;
              if (verification.code !== 0) {
                verifiedAddress = get(verification, 'data.addresses[0]');
              }
              dispatch(setLookupAddressVerified(true));
              dispatch(setLookupAddress(verifiedAddress));
            })
            .catch(() => {
              dispatch(setLookupAddress(defaultLookupAddress));
              dispatch(setLookupAddressVerificationError(true));
            });
        })
        .catch(() => {
          dispatch(setLookupAddress(defaultLookupAddress));
          dispatch(setLookupAddressVerificationError(true));
        })
        .finally(() => dispatch(setLoading(false)));
    }
  };
};

export const getUpdatedAddressMetadata = (updatedAddress, isAnonymousUser) => {
  return dispatch => {
    dispatch(setLookupAddress(defaultLookupAddress));
    dispatch(setLookupAddressVerificationError(false));
    dispatch(setLookupAddressVerified(false));
    dispatch(setLoading(true));
    const _API = isAnonymousUser ? TOKENIZED_API : API;
    _API
      .get(`/addresses/googlePlacesFill?addressLine=${updatedAddress}`)
      .then(response => {
        const address = response.data;
        return _API
          .post(`/addresses/verificationRequest`, address)
          .then(verification => {
            let verifiedAddress = null;
            if (verification.code !== 0) {
              verifiedAddress = get(verification, 'data.addresses[0]');
            }
            dispatch(setLookupAddressVerified(true));
            dispatch(setLookupAddress(verifiedAddress));
          })
          .catch(() => {
            dispatch(setLookupAddress(defaultLookupAddress));
            dispatch(setLookupAddressVerificationError(true));
          });
      })
      .catch(() => {
        dispatch(setLookupAddress(defaultLookupAddress));
        dispatch(setLookupAddressVerificationError(true));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
