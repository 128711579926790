import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  isAvailableProductLoading: false,
  openQuotes: [],
  errors: null,
  availableProduct: null,
  availableProductError: null,
};

export const openQuotesSlice = createSlice({
  name: 'openQuotesSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setAvailableProductLoading: (state, action) => {
      return { ...state, isAvailableProductLoading: action.payload };
    },
    setOpenQuotes: (state, action) => {
      return { ...state, openQuotes: action.payload };
    },
    setAvailableProduct: (state, action) => {
      return { ...state, availableProduct: action.payload };
    },
    setAvailableProductError: (state, action) => {
      return { ...state, availableProductError: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});
export const {
  setLoading,
  setAvailableProductLoading,
  setOpenQuotes,
  setErrors,
  setAvailableProduct,
  setAvailableProductError,
  resetState,
} = openQuotesSlice.actions;
export default openQuotesSlice.reducer;
