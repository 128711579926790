import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  fieldAnswers: {},
  privacyAcceptance: false,
};

export const anonymousReportClaimStepOneSlice = createSlice({
  name: 'anonymousReportClaimStepOneSlice',
  initialState,
  reducers: {
    resetStepOneState: () => {
      return initialState;
    },
    setFieldAnswers: (state, action) => {
      return { ...state, fieldAnswers: action.payload };
    },
    setPrivacyAcceptance: (state, action) => {
      return { ...state, privacyAcceptance: action.payload };
    },
  },
});
export const {
  setFieldAnswers,
  setPrivacyAcceptance,
  resetStepOneState,
} = anonymousReportClaimStepOneSlice.actions;
export default anonymousReportClaimStepOneSlice.reducer;
