import * as React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { logUserOut } from './logoutReducer';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { StyledLogout } from './styles';
import { gaEvent } from '../../../utils/google-analytics';
import { ga4Event } from '../../../utils/google-analytics-4';

export const UserLogout = props => {
  const { oktaAuth } = useOktaAuth();

  const logout = async () => {
    gaEvent({ category: 'logout', action: 'click' });
    ga4Event({ category: 'logout', action: 'click' });
    props.dispatch(logUserOut());
    oktaAuth.signOut();

    // this prevents AuthApiError on logout which is browser specific
    // and caused during session invalidation
    localStorage.removeItem('okta-token-storage');
  };

  return (
    <StyledLogout
      id="logOut"
      onClick={() => logout()}
      onKeyPress={() => logout()}
      role="button"
      tabIndex={0}
    >
      Log Out
    </StyledLogout>
  );
};
UserLogout.propTypes = {
  dispatch: PropTypes.any,
};
export const mapStateToProps = state => ({
  state,
});
export default connect(mapStateToProps)(UserLogout);
