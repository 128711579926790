import styled from 'styled-components';
export const PaymentContainer = styled.div`
  margin: 30px;
`;
export const PaymentHeader = styled.h1`
  font-size: 1.525rem;
  font-weight: 400;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const SubHeader = styled.h2`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;
export const PolicyToPayContainer = styled.div`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    column-gap: 24px;
  }
`;
export const RadioChoice = styled.input.attrs({
  type: 'radio',
})`
  margin-left: 16px;
  margin-top: 16px;
  height: 24px;
  width: 24px;
`;
export const PolicyHeader = styled.div`
  font-size: 16px;
  color: #28333f;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  /* font-family: "Source Sans Pro"; */
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;
`;
export const PaymentCards = styled.div`
  margin-bottom: 24px;
  height: 56px;
  width: 290px;
  border-radius: 4px;
  background-color: #fafafa;
`;
export const CreditCard = styled(PaymentCards)`
  font-size: 16px;
`;
export const ButtonContainer = styled.div.attrs({
  id: 'buttonContainer',
})`
  display: inline-block;
  float: right;
  @media (max-width: 366px) {
    text-align: center;
  }
`;
export const StyledButtonNext = styled.button.attrs({
  id: 'nextButton',
})`
  margin-left: 10px;
  padding: 8px 47px 8px 47px;
  border: 0;
  transition: 220ms all ease-in-out;
  background-color: ${props => props.theme.primaryColor};

  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
  /* border-radius: 4px; */

  a:webkit-any-link {
    color: white;
  }
  :disabled {
    background-color: gray;
  }
`;

export const PolicySubHeaders = styled.div.attrs({})`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const CurrentDueAmount = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 32px;
  margin-top: 8px;
`;

export const CurrentDueContainer = styled.div.attrs({})`
  margin-left: 47px;
  margin-top: 8px;
`;

export const DatesAndAmountsContainer = styled.div.attrs({
  className: 'datesAndAmounts',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-left: 47px;
    margin-top: 8px;
    row-gap: 6px;
    margin-bottom: 6px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 33% 33% 33%;
    row-gap: 4px;
    align-self: end;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 25% 25% 25% 25%;
    align-self: end;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 36% 32% 32%;
  }
`;
export const PolicyAndCurrentDueContainer = styled.div.attrs({})`
  display: inline-block;
`;
export const PolicyValueHeader = styled.div.attrs({})`
  font-weight: 600;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const GridSpacingDesktopOne = styled.div.attrs({
  id: 'grid spacer',
})`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) {
  }
`;
export const GridSpacingDesktopTwo = styled.div.attrs({
  id: 'grid spacer 2',
})`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) {
  }
`;
export const NoPaymentDueText = styled.label`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 20px;
`;
export const AmountDisclaimerContainer = styled.div.attrs({})`
  margin-bottom: 20px;
`;
export const AmountDisclaimerText = styled.label`
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const LinkStyleButton = styled.button.attrs({
  id: 'makePaymentCancelButton',
})`
  background-color: initial;
  color: ${props => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-top: 8px;

  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
  }
`;
export const AuthorizationMessage = styled.div`
  margin-top: 40px;
`;
export const MakePaymentBackButton = styled.button.attrs({
  id: 'makePaymentBackButton',
})`
  margin-right: 10px;
  padding: 8px 18px;
  color: ${props => props.theme.primaryColor};
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
    box-shadow: 3px 3px 6px gray;
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
    box-shadow: initial;
  }
  @media (max-width: 599px) {
  }
`;
