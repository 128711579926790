import { createField, formatRecapFieldDate } from '../selector';

export const YES_NO_DEFAULT = 'No';

export const createPolicyChangeCoverageSummary = (
  effectiveDate,
  reason,
  changeName,
  questions,
  answers,
) => {
  const fields = [];
  fields.push(createField('Change Requested', changeName, 'text', false));
  fields.push(createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'));
  fields.push(createField('Reason', reason, 'text'));

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers[i].answer;
    let value;
    if (question.dataType === 'YesNo') {
      value = answer;
    } else if (question.dataType === 'List') {
      value = answer;
    } else if (question.dataType === 'Numeric') {
      value = answer.toString();
    } else if (question.dataType === 'TextArea') {
      value = answer;
    }
    fields.push(createField(question.text, value, 'text'));

    if (
      question.questions &&
      question.questions.question &&
      question.questions.question.length > 0 &&
      answers[i].subQuestionAnswers
    ) {
      for (let j = 0; j < question.questions.question.length; j++) {
        const subQuestion = question.questions.question[j];
        const subAnswer = answers[i].subQuestionAnswers[j];
        let subValue;
        if (subQuestion.dataType === 'YesNo') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'List') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'Numeric') {
          subValue = subAnswer.toString();
        } else if (subQuestion.dataType === 'TextArea') {
          subValue = subAnswer;
        }
        fields.push(createField(subQuestion.text, subValue, 'text'));
      }
    }
  }
  return fields;
};
