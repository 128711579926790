import { setClaimAttachments, setClaimDetails, setErrors, setLoading } from './claimDetailsReducer';
import { API } from '../../../utils/api';

export const getClaimDetails = claimSystemId => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));
    return API.get('claims/' + claimSystemId + '/consumerSummary')
      .then(response => {
        const claimDetails = response.data;
        const addresses = claimDetails.addr;
        let addr1 = '';
        let addr2 = '';

        if (addresses !== undefined && addresses.length > 0) {
          const riskAddresses = addresses.filter(address => address.addrTypeCd === 'RiskAddr');
          if (riskAddresses.length > 0) {
            const addr = riskAddresses[0];
            addr1 = addr.addr1;
            addr2 = addr.city + ', ' + addr.stateProvCd + ', ' + addr.postalCode;
            claimDetails.addr1 = addr1;
            claimDetails.addr2 = addr2;
          }
        }
        dispatch(setClaimDetails(claimDetails));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getClaimAttachments = claimId => {
  return dispatch => {
    dispatch(setLoading(true));
    dispatch(setErrors(null));
    return API.get('claims/' + claimId + '/documents')
      .then(response => {
        // filter attachments
        const attachments =
          response.data.documentListItems && response.data.documentListItems.length > 0
            ? response.data.documentListItems.filter(
                documentListItem => documentListItem.type === 'Attachment',
              )
            : [];
        dispatch(setClaimAttachments(attachments));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
