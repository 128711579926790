import React from 'react';
import PropTypes from 'prop-types';
import { AddressSection, AddressWrapper, BoldHeading } from '../dashboard/policies/style';

const AddressBox = props => {
  const { addresses } = props;
  return (
    <div className="clear">
      {addresses.map(address => (
        <AddressSection
          id={`policyDetailsAddress_${address.addrTypeCd}`}
          key={`address_${address.id}`}
        >
          <BoldHeading>
            {address.addrTypeCd.replaceAll('Insured', '').replaceAll('Addr', '')}
          </BoldHeading>
          <AddressWrapper>
            {address.addr1}
            <br />
            {address.city}, {address.stateProvCd} {address.postalCode}
          </AddressWrapper>
        </AddressSection>
      ))}
    </div>
  );
};
AddressBox.propTypes = {
  policyRef: PropTypes.string,
  policyNumber: PropTypes.string,
  effectiveDt: PropTypes.string,
  expirationDt: PropTypes.string,
  statusCd: PropTypes.string,
  systemId: PropTypes.string,
  description: PropTypes.string,
  productName: PropTypes.string,
  displayDescription: PropTypes.string,
  uploadAndAttachDocuments: PropTypes.func,
  addresses: PropTypes.array,
};
export default AddressBox;
