import {
  setClaimEvent,
  setClaimEventLoading,
  setClaimEventTemplates,
  setDriverRelationshipOptions,
  setErrors,
} from './reportClaimReducer';
import { API } from '../../../utils/api';

export const getClaimEventTemplates = (customerId, accidentIndicator = false) => {
  return dispatch => {
    return API.get(
      '/claimEventTemplates?customerRef=' +
        customerId +
        (accidentIndicator ? '&accidentIndicator=true' : ''),
    )
      .then(response => {
        // set values and labels in array
        const claimEventTemplateOptions = response.data.claimEventTemplateItems.map(template => ({
          ...template,
          value: template.id,
          label: template.description ? template.description : template.name,
        }));
        dispatch(setClaimEventTemplates(claimEventTemplateOptions));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getClaimEventTemplateById = (templateId, customerId, lossDt, lossTm, includeValue) => {
  return dispatch => {
    dispatch(setClaimEventLoading(true));
    return API.get(
      `/claimEvents/${templateId}` +
        `?customerRef=${customerId}` +
        `&lossDt=${lossDt}` +
        `&lossTm=${lossTm}` +
        (includeValue === 'RiskLocation' ? '&includeRiskLocations=true' : '') +
        (includeValue === 'VehicleAndDrivers' ? '&includeVehiclesAndDrivers=true' : '') +
        (includeValue === 'Contacts' ? '&includeContacts=true' : ''),
    )
      .then(response => {
        dispatch(setClaimEvent(response.data));
        return API.get(
          `/coderefs/UW/underwriting/driver-relationship/all
        `,
        ).then(response =>
          dispatch(
            setDriverRelationshipOptions(
              response.data.options.map(option => ({ value: option.value, label: option.name })),
            ),
          ),
        );
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setClaimEventLoading(false)));
  };
};
