// Viewport Sizing
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1280px
// xl, extra-large: 1920px
import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const TemplateOneDashboardContainer = styled.div`
  margin-top: 20px;
  @media (min-width: 900px) and (max-width: 1279px) {
    display: grid;
    row-gap: 5px;
    grid-template-rows: 160px;
    grid-template-areas:
      'navbar'
      'dashboard'
      ' . '
      'sidepanels';
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 10px 18fr 6fr 10px;
    grid-template-areas:
      '. navbar sidepanels'
      '. dashboard sidepanels'
      '. . . ';
    grid-template-rows: 170px 13fr 1fr;
  }
  @media (min-width: 1920px) {
    display: grid;
    column-gap: 20px;
    grid-template-columns: 10px 14fr 3fr 20px;
    grid-template-areas:
      '. navbar sidepanels '
      '. dashboard sidepanels '
      '. . . ';
    grid-template-rows: 160px 13fr 1fr;
  }
`;
export const TemplateOneNav = styled.nav.attrs({
  id: 'templateOneNavigation',
})`
  min-height: 75px;
  background-color: ${(props) => props.theme.primaryColor};
  display: grid;
  grid-area: navbar;
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-areas:
      ' . . . .'
      ' logo . toplinks toplinks'
      ' logo . toplinks toplinks'
      ' . bottomlinks bottomlinks bottomlinks'
      ' . . . .';
    grid-template-columns: 10px 21% 18px 76%;
    row-gap: 5px;
  }
  @media (min-width: 1280px) and (max-width: 1979px) {
    grid-template-areas:
      ' . . . .'
      ' logo . toplinks toplinks'
      ' logo . toplinks toplinks'
      ' bottomlinks bottomlinks bottomlinks bottomlinks'
      ' . . . .';
    grid-template-columns: 1fr 1fr 4fr 6fr;
  }
  @media (min-width: 1980px) {
    grid-template-areas:
      '. logo . toplinks toplinks'
      '. logo . toplinks toplinks'
      'bottomlinks bottomlinks bottomlinks bottomlinks bottomlinks';
    grid-template-columns: 10px 10% 25px 67%;
    grid-template-rows: 15% 55% 29%;
  }
`;
export const LogoLink = styled(Link)`
  grid-area: logo;
`;
export const TemplateLogo = styled.img.attrs({
  id: 'loggedInLogo',
})`
  width: auto;
  height: 75px;
  padding: 8% 0 5% 0;
  @media (min-width: 900px) and (max-width: 1279px) {
    padding: 15px 0% 0% 15px;
    height: 35px;
  }
  @media (min-width: 1280px) and (max-width: 1979px) {
    padding: 15px 0 0% 20px;
    height: 45px;
  }
  @media (min-width: 1980px) {
    height: 55px;
    padding: 10% 0% 0% 0%;
  }
`;
export const TopMenuLinks = styled.div`
  color: white;
  grid-area: toplinks;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: flex-start;
  font-size: 1rem;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  @media (min-width: 1280px) {
  }
`;
export const HorizontalIndividualMenuLink = styled(Link)`
  font-size: 1rem;
  color: white;
  text-decoration: underline;
  justify-self: center;
  :hover {
    color: ${(props) => props.theme.gray};
    text-decoration: none;
  }
`;
export const ProfileLinkHorizontal = styled(HorizontalIndividualMenuLink)`
  grid-area: profile;
  line-height: 1.8;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
export const NeedHelpLinkHorizontal = styled(HorizontalIndividualMenuLink)`
  grid-area: needhelp;
  line-height: 1.8;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;
export const LogoutLinkHorizontal = styled.div`
  grid-area: logout;
  line-height: 1.8;
  padding: 1% 5%;
  @media (min-width: 900px) and (max-width: 1279px) {
    padding: 1% 5% 1% 5%;
  }
`;

export const StyledLogoutHorizontal = styled.button.attrs({
  id: 'styledLogoutLink',
})`
  grid-area: logout;
  border-radius: 3px;
  transition: 220ms all ease-in-out;
  background-color: ${(props) => props.theme.primaryColor};
  :hover {
    background-color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${(props) => props.theme.primaryActiveColadsfor};
  }
  color: white;
  padding: 8px 12px 8px 10px;
  border: 1px solid white;
`;

export const BottomMenuLinksContainer = styled.div.attrs({
  id: 'bottomMenuLinksTemplate1',
})`
  color: white;
  grid-area: bottomlinks;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  margin-left: 15%;
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-bottom: 5px;
    text-align: center;
  }
  @media (min-width: 1280px) and (max-width: 1919px) {
    align-items: center;
    margin-left: 5%;
  }
  @media (min-width: 1920px) {
    align-items: center;
  }
  font-size: 1rem;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const BottomMenuLinks = styled(Link)`
  line-height: 1.8;
  text-decoration: none;
  color: white;
  :hover {
    text-decoration: underline;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    align-self: center;
  }
  @media (min-width: 1280px) {
    text-align: center;
  }
`;

export const HomeLink = styled(BottomMenuLinks)`
  grid-area: home;
`;
export const IdCardsLink = styled(BottomMenuLinks).attrs({
  id: 'idCardsLink',
})`
  grid-area: idCards;
`;
export const OpenQuoteLink = styled(BottomMenuLinks).attrs({
  id: 'openQuotesLink',
})`
  grid-area: openquotes;
`;
export const PendingRequestLink = styled(BottomMenuLinks).attrs({
  id: 'pendingRequestLink',
})`
  grid-area: pendingrequests;
`;
export const MakePaymentLink = styled(BottomMenuLinks).attrs({
  id: 'makePaymentLink',
})`
  grid-area: makepayment;
`;
export const ReportClaimLink = styled(BottomMenuLinks).attrs({
  id: 'reportClaimLink',
})`
  grid-area: reportclaim;
`;
export const ChangeCoverageLink = styled(BottomMenuLinks).attrs({
  id: 'changeMyCoverageLink',
})`
  grid-area: changemycoverage;
`;

export const CustomLinkOne = styled(BottomMenuLinks)`
  grid-area: link1;
`;

export const DashboardContentArea = styled.div`
  background-color: white;
  grid-area: dashboard;
`;

export const SidePanelContainer = styled.div`
  grid-area: sidepanels;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
export const SidePanelContainerWithAnnouncements = styled.div`
  grid-area: sidepanels;
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;
export const AgentContainer = styled.div.attrs({
  id: 'agentContainer',
})`
  background-color: white;
  grid-area: agentcontainer;
`;
export const GetQuoteContainer = styled.div.attrs({
  id: 'getQuoteContainer',
})`
  background-color: white;
  grid-area: getquotecontainer;
`;
export const AnnouncementsContainer = styled.div.attrs({
  id: 'announcementsContainer',
})`
  background-color: white;
  grid-area: announcementsContainer;
`;
export const TopLinkContainers = styled.div.attrs({
  id: 'linksContainer',
})`
  padding: 1.3% 5% 1% 5%;
  @media (min-width: 900px) and (max-width: 1279px) {
    padding: 1.3% 5% 1% 5%;
  }
`;
export const ContentLink = styled.a.attrs({
  id: 'customContentLinkTemplate1',
})`
  line-height: 1.8;
  text-decoration: none;
  color: white;
  :hover {
    text-decoration: underline;
  }
`;
export const VerticalMenuLink = styled(Link)`
  color: white;
  text-decoration: none;
  align-self: center;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  :hover {
    text-decoration: underline;
  }
`;
export const CustomHTML1LinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customPage1',
  to: '/customPage1',
})``;
export const CustomHTML2LinkVertical = styled(VerticalMenuLink).attrs({
  id: 'customPage2',
  to: '/customPage2',
})``;
