import styled from 'styled-components';
import { policyInformationLabel } from '../policies/style';

// Viewport Sizing
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1280px
// xl, extra-large: 1920px
export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;

export const ClaimsDashboardContainer = styled.div.attrs({
  id: 'claimsDashboardContainer',
})``;

export const ClaimsHeader = styled.h1.attrs({
  id: 'claimsHeader',
  'aria-label': 'Claims Table',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-block: 1em;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
  }
`;
export const ClaimsLabelContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: ${props =>
      props.claimDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${props =>
      props.claimDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${props =>
      props.claimDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 5%;
  }
`;
export const claimsInformationLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const ClaimNumberLabel = styled(claimsInformationLabel).attrs({
  id: 'claimNumberLabel',
})``;
export const StatusCdLabel = styled(claimsInformationLabel).attrs({
  id: 'statusCdLabel',
})``;
export const LossDtLabel = styled(claimsInformationLabel).attrs({
  id: 'lossDtLabel',
})``;
export const LossCauseCdLabel = styled(claimsInformationLabel).attrs({
  id: 'lossCauseCdLabel',
})``;
export const UploadLabel = styled(policyInformationLabel).attrs({
  id: 'uploadLabel',
})``;

export const ClaimNumberLabelMobile = styled(claimsInformationLabel).attrs({
  id: 'claimNumberLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const StatusCdLabelMobile = styled(claimsInformationLabel).attrs({
  id: 'statusCdLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const LossDtLabelMobile = styled(claimsInformationLabel).attrs({
  id: 'lossDtLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const LossCauseCdLabelMobile = styled(claimsInformationLabel).attrs({
  id: 'lossCauseCdLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const claimsDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const ClaimNumberContainer = styled(claimsDataDiv).attrs({
  id: 'claimNumberContainer',
})`
  word-break: break-word;
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const StatusCdContainer = styled(claimsDataDiv).attrs({
  id: 'statusCdContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const LossDtContainer = styled(claimsDataDiv).attrs({
  id: 'lossDtContainer',
})`
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const UploadLabelMobile = styled(claimsInformationLabel).attrs({
  id: 'uploadLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const LossCauseCdContainer = styled(claimsDataDiv).attrs({
  id: 'lossCauseCdContainer',
})`
  word-break: break-word;
  @media (max-width: 299px) {
  }
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ClaimsDataContainer = styled.div`
  display: grid;
  word-break: break-all;
  /* column-gap: 8px; */
  /* margin: 20px 0 20px 20px; */
  @media (max-width: 599px) {
    row-gap: 16px;
    margin-top: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: ${props =>
      props.claimDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-top: 6px;
    margin-bottom: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${props =>
      props.claimDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-top: 6px;
    margin-bottom: 24px;
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${props =>
      props.claimDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 5%;
    margin-top: 6px;
    margin-bottom: 24px;
  }
`;
export const HorizontalLine = styled.hr`
  display: none;
  @media (max-width: 600px) {
    display: block;
    width: 100%;
  }
`;
