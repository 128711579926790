import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newsBanners: [],
  isLoading: false,
  errors: null,
};
export const newsBannersSlice = createSlice({
  name: 'newsBannersSlice',
  initialState,
  reducers: {
    setNewsBanners: (state, action) => {
      return { ...state, newsBanners: action.payload };
    },
  },
});
export const { setNewsBanners } = newsBannersSlice.actions;
export default newsBannersSlice.reducer;
