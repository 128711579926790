import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  loading: false,
};

export const reportClaimStepOneSlice = createSlice({
  name: 'reportClaimStepOneSlice',
  initialState,
  reducers: {
    resetStepTwoState: () => {
      return initialState;
    },
    setLoading: (state, action) => {
      return { ...state, loading: action.payload };
    },
  },
});
export const { resetStepTwoState, setLoading } = reportClaimStepOneSlice.actions;
export default reportClaimStepOneSlice.reducer;
