// Selector
export function getCustomerParty(state) {
  return (
    (state?.customerSlice?.customer?.partyInfo &&
      state.customerSlice.customer.partyInfo?.find(
        customerParty => customerParty?.partyTypeCd === 'CustomerParty',
      )) ||
    {}
  );
}

export function getMailingAddress(state) {
  const customerParty = getCustomerParty(state);
  return (
    customerParty?.addresses?.find(
      mailingAddress => mailingAddress?.addrTypeCd === 'CustomerMailingAddr',
    ) || {}
  );
}

export function getPrimaryPhoneInfo(state) {
  const customerParty = getCustomerParty(state);
  return (
    customerParty?.phoneInfo?.find(
      phonePrimary => phonePrimary?.phoneTypeCd === 'CustomerPhonePrimary',
    ) || {}
  );
}

export function getSecondaryPhoneInfo(state) {
  const customerParty = getCustomerParty(state);
  return (
    customerParty?.phoneInfo?.find(
      phonePrimary => phonePrimary?.phoneTypeCd === 'CustomerPhoneSecondary',
    ) || {}
  );
}

export function getPolicyEmail(policy) {
  return policy.insured.partyInfo.find(party => party.partyTypeCd === 'InsuredParty').emailInfo
    .emailAddr;
}

export function getPolicyMailingAddress(policy) {
  const address = policy.insured.partyInfo
    .find(partyInfo => partyInfo.partyTypeCd === 'InsuredParty')
    .addresses.find(address => address.addrTypeCd === 'InsuredMailingAddr');
  return `${address.addr1}, ${address.city}, ${address.stateProvCd} ${address.postalCode}`;
}
