import styled from 'styled-components';
import DeleteIcon from '@mui/icons-material/Delete';

export const ReportClaimStepFourContainer = styled.div.attrs({
  id: 'ReportClaimStepFourContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media (min-width: 1280px) {
    margin-left: 65px;
    margin-right: 65px;
  }
`;

export const ReportClaimEventHeader = styled.h5.attrs({
  id: 'claimEventNameHeader',
})`
  font-weight: 600;
  color: #333;
  font-size: 16px;
  margin-bottom: initial;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const QuestionTypeHeader = styled.h3.attrs({
  id: 'questionTypeHeader',
})`
  font-size: 18px;
  margin-bottom: 0;
  margin-top: 0;
  font-weight: 500;
  color: #333333;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const StyledReportClaimHeader = styled.h2.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 600;
  @media (max-width: 599px) {
    margin-top: 24px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 24px;
  }
  @media (min-width: 1280px) {
    margin-top: 24px;
  }
`;

export const QuestionsContainer = styled.div.attrs({
  id: 'questionsContainer',
})`
  /* margin: 30px 16px 16px 16px; */
  margin-top: 45px;
`;

export const IndividualQuestionContainer = styled.div.attrs({
  id: 'individualQuestionContainer',
})`
  margin-top: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const QuestionReplyTextArea = styled.textarea.attrs({})`
  width: 100%;
`;

export const QuestionReplyText = styled.input.attrs({
  id: 'questionReplyText',
})`
  width: 100%;
`;

export const QuestionLabel = styled.label.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  display: block;
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const StyledSection = styled.section.attrs({})`
  margin-top: 16px;
`;
export const StyledDropZone = styled.div.attrs({ id: 'styledPictureUploadButtonLoggedIn' })`
  cursor: pointer;
  /* margin-left: 0px; */
  padding: 10px 10px;
  color: ${(props) => props.theme.primaryColor};
  background-color: white;
  border: 1px solid ${(props) => props.theme.primaryColor};
  width: 150px;
  text-align: center;
  border-radius: 4px;
  :hover {
    color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    color: ${(props) => props.theme.primaryActiveColor};
  }
  @media (max-width: 599px) {
    padding-left: 22px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    padding-left: 22px;
    padding-right: 30px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const GarbageIconContainer = styled.div.attrs({})`
  position: absolute;
  margin-top: 6px;
`;
export const GarbageIcon = styled(DeleteIcon).attrs({
  className: 'fa fa-trash-o',
})`
  cursor: pointer;
`;
export const LossEventMessage = styled.h4.attrs({
  id: 'lossMessage',
})`
  font-weight: 400;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const thumbsContainer = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

export const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  marginTop: 20,
  width: 100,
  height: 100,
  padding: 4,
  boxSizing: 'border-box',
};

export const thumbInner = {
  display: 'inline-block',
  minWidth: 0,
  overflow: 'hidden',
};

export const img = {
  display: 'block',
  width: 'auto',
  height: '100%',
};
