import React, { useEffect, useState } from 'react';
import { LoggedOutTemplateLogo } from '../login/styles';
import {
  ButtonContainer,
  FieldContainer,
  FieldHeader,
  FooterContainer,
  ForgotPasswordHeader,
  ForgotPasswordMessageLabel,
  ForgotPasswordStepSubContainer,
  LogoLink,
  ResetPasswordButton,
  ReturnToLoginPageButton,
  ReturnToLoginPageButtonContainer,
  TextInputField,
} from './styles';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';
import Loader from '../../common/loader';
import Footer from '../dashboard/footer';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

export const ForgotPassword = props => {
  const { i18n, companyName } = props;
  const [userId, setUserId] = useState('');
  const [isResetComplete, setResetComplete] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isError, setError] = useState(false);

  const { oktaAuth } = useOktaAuth();
  const handleResetPassword = () => {
    setLoading(true);
    oktaAuth
      .forgotPassword({ username: userId, factorType: 'EMAIL' })
      .then(() => {
        setLoading(false);
        setResetComplete(true);
      })
      .catch(e => {
        console.log(e);
        setError(true);
      });
  };

  useEffect(() => {
    setResetComplete(false);
    setLoading(false);
    setError(false);
  }, []);

  return (
    <>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      <>
        {isError ? (
          <ForgotPasswordStepSubContainer>
            <ForgotPasswordHeader>Error</ForgotPasswordHeader>
            <ForgotPasswordMessageLabel>
              There was a problem resetting your password. Please contact your agent for assistance
              or try again later.
            </ForgotPasswordMessageLabel>
          </ForgotPasswordStepSubContainer>
        ) : !isResetComplete ? (
          <ForgotPasswordStepSubContainer>
            <ForgotPasswordHeader>Reset Password</ForgotPasswordHeader>
            <ForgotPasswordMessageLabel>
              After confirming password reset, you will receive an userId shortly with password
              reset instructions.
            </ForgotPasswordMessageLabel>
            <form>
              <FieldContainer>
                <FieldHeader>
                  Please enter your User ID
                  <TooltipInfo title={getText(i18n, 'resetPassword.userIdTooltip')} />
                </FieldHeader>
                <TextInputField
                  id="userId"
                  value={userId}
                  onChange={e => {
                    setUserId(e.target.value);
                  }}
                  autoComplete="userId"
                />
              </FieldContainer>
            </form>
            <ButtonContainer>
              {isLoading ? (
                <Loader />
              ) : (
                <ResetPasswordButton
                  disabled={userId.length === 0}
                  onClick={() => {
                    handleResetPassword();
                  }}
                >
                  Retrieve Password
                </ResetPasswordButton>
              )}
            </ButtonContainer>
          </ForgotPasswordStepSubContainer>
        ) : (
          <ForgotPasswordStepSubContainer>
            <ForgotPasswordHeader>Email sent!</ForgotPasswordHeader>
            <ForgotPasswordMessageLabel>
              {`Email has been sent to ${userId} with instructions on resetting your password`}
            </ForgotPasswordMessageLabel>
          </ForgotPasswordStepSubContainer>
        )}

        <ReturnToLoginPageButtonContainer>
          <ReturnToLoginPageButton to="/">Return to the Sign-In screen</ReturnToLoginPageButton>
        </ReturnToLoginPageButtonContainer>
      </>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};
ForgotPassword.propTypes = {
  state: PropTypes.object,
  i18n: PropTypes.object,
  companyName: PropTypes.string,
};

export const mapStateToProps = state => ({
  i18n: state.i18nSlice.i18n,
  companyName: state.configurationSlice.features.companyName,
  state,
});

export default connect(mapStateToProps)(ForgotPassword);
