import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  policyChangeTemplates: [],
  mortgageeInterestTypes: [],
  vehicleInterestTypes: [],
  propertyMortgageeList: null,
  isPropertyMortgageeListLoading: true,
  vehicleFinanceCompanyList: [],
  buildingRisks: [],
  vehicleRisks: [],
  policyChangeSection: [],
  selectedPolicyChangeTemplate: null,
  policyOptions: [],
  selectedPolicy: null,
  isLoading: false,
  errors: null,
  showChangeCoverageResults: false,
  policyChangeCoverageRecapFields: [],
  policyChangePreviewData: [],
  isBuildingRisksLoading: false,
  isVehicleRisksLoading: false,
  policyChangePreview: null,
  latestPolicyInfo: null,
  vehicleModel: null,
  drivers: [],
  driverRelationshipTemplate: [{ label: '-- Relationship to Insured --', value: 'default' }],
  isVinVerificationFailed: false,
};

export const policyChangeSlice = createSlice({
  name: 'policyChangeSlice',
  initialState,
  reducers: {
    resetChangeCoverageState: () => {
      return initialState;
    },
    setPolicyChangeTemplates: (state, action) => {
      return { ...state, policyChangeTemplates: action.payload };
    },
    setSelectedPolicyChangeTemplate: (state, action) => {
      return { ...state, selectedPolicyChangeTemplate: action.payload };
    },
    setPropertyMortgageeList: (state, action) => {
      return { ...state, propertyMortgageeList: action.payload };
    },
    setPropertyMortgageeListLoading: (state, action) => {
      return { ...state, isPropertyMortgageeListLoading: action.payload };
    },
    setPolicyOptions: (state, action) => {
      return { ...state, policyOptions: action.payload };
    },
    setSelectedPolicy: (state, action) => {
      return { ...state, selectedPolicy: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setShowChangeCoverageResults: (state, action) => {
      return { ...state, showChangeCoverageResults: action.payload };
    },
    setPolicyChangeCoverageRecapFields: (state, action) => {
      return { ...state, policyChangeCoverageRecapFields: action.payload };
    },
    setMortgageeInterestTypes: (state, action) => {
      return { ...state, mortgageeInterestTypes: action.payload };
    },
    setBuildingRisks: (state, action) => {
      return { ...state, buildingRisks: action.payload };
    },
    setBuildingRisksLoading: (state, action) => {
      return { ...state, isBuildingRisksLoading: action.payload };
    },
    setVehicleRisksLoading: (state, action) => {
      return { ...state, isVehicleRisksLoading: action.payload };
    },
    setVehicleRisks: (state, action) => {
      return { ...state, vehicleRisks: action.payload };
    },
    setVehicleInterestTypes: (state, action) => {
      return { ...state, vehicleInterestTypes: action.payload };
    },
    setVehicleFinanceCompanyList: (state, action) => {
      return { ...state, vehicleFinanceCompanyList: action.payload };
    },
    setPolicyChangePreview: (state, action) => {
      return { ...state, policyChangePreview: action.payload };
    },
    setLatestPolicyInfo: (state, action) => {
      return { ...state, latestPolicyInfo: action.payload };
    },
    setPolicyChangePreviewData: (state, action) => {
      return { ...state, policyChangePreviewData: action.payload };
    },
    setPolicyChangeSection: (state, action) => {
      return { ...state, policyChangeSection: action.payload };
    },
    setVehicleModel: (state, action) => {
      return { ...state, vehicleModel: action.payload };
    },
    setDrivers: (state, action) => {
      return { ...state, drivers: action.payload };
    },
    setDriverRelationshipTemplate: (state, action) => {
      return { ...state, driverRelationshipTemplate: action.payload };
    },
    setVinVerificationFailed: (state, action) => {
      return { ...state, isVinVerificationFailed: action.payload };
    },
  },
});
export const {
  resetChangeCoverageState,
  setLoading,
  setMortgageeInterestTypes,
  setBuildingRisks,
  setPolicyChangeTemplates,
  setSelectedPolicyChangeTemplate,
  setPolicyOptions,
  setSelectedPolicy,
  setErrors,
  setShowChangeCoverageResults,
  setPolicyChangeCoverageRecapFields,
  setBuildingRisksLoading,
  setPropertyMortgageeList,
  setPropertyMortgageeListLoading,
  setVehicleRisks,
  setVehicleRisksLoading,
  setVehicleInterestTypes,
  setVehicleFinanceCompanyList,
  setPolicyChangePreview,
  setPolicyChangeSection,
  setLatestPolicyInfo,
  setPolicyChangePreviewData,
  setVehicleModel,
  setDrivers,
  setDriverRelationshipTemplate,
  setVinVerificationFailed,
} = policyChangeSlice.actions;
export default policyChangeSlice.reducer;
