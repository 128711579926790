export const getReplies = state => {
  return state.reportClaimStepFourSlice.claimEventTemplates.map(event => {
    if (event.questions.question) {
      return event.questions.question.map(thisQuestion => {
        if (thisQuestion.questions && thisQuestion.questions.question) {
          return {
            topDesc: thisQuestion.shortDesc,
            topVal: thisQuestion.Value,
            id: thisQuestion.id,
            subQuestions: thisQuestion.questions.question.map(subQuestion => {
              return {
                shortDesc: subQuestion.shortDesc,
                Value: subQuestion.Value,
                id: subQuestion.id,
              };
            }),
          };
        }
        return {
          shortDesc: thisQuestion.shortDesc,
          Value: thisQuestion.Value,
          id: thisQuestion.id,
        };
      });
    }
  });
};
