export const getCustomerParty = (state) => {
  return (
    (state?.customerSlice?.customer?.partyInfo &&
      state.customerSlice.customer.partyInfo?.find(
        (customerParty) => customerParty?.partyTypeCd === 'CustomerParty',
      )) ||
    {}
  );
};
export const getMailingAddress = (state) => {
  const customerParty = getCustomerParty(state);
  return (
    customerParty?.addresses?.find(
      (mailingAddress) => mailingAddress?.addrTypeCd === 'CustomerMailingAddr',
    ) || {}
  );
};
export const getPrimaryPhoneInfo = (state) => {
  /* check if we've started editing phone yet which is profileSlice state piece,
   otherwise look for customer party stuff*/

  const customerParty = getCustomerParty(state);
  return (
    customerParty?.phoneInfo?.find(
      (phonePrimary) => phonePrimary?.phoneTypeCd === 'CustomerPhonePrimary',
    ) || {}
  );
};
export const getSecondaryPhoneInfo = (state) => {
  const customerParty = getCustomerParty(state);
  return (
    customerParty?.phoneInfo?.find(
      (phonePrimary) => phonePrimary?.phoneTypeCd === 'CustomerPhoneSecondary',
    ) || {}
  );
};
