import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import TimezoneSelect from 'react-timezone-select';
import { useSnackbar } from 'notistack';
import { saveTimeZone } from './profileAPI';
import {
  EditEmailContainer,
  SaveTimezoneButton,
  TimezoneInputContainer,
  CancelButton,
} from './styles';
import { setEditingTimezone, setTimezoneErrors } from './profileReducer';

const EditingTimezone = props => {
  const { customerId, dispatch, timezone } = props;
  const { enqueueSnackbar } = useSnackbar();
  const [selectedTimezone, setSelectedTimezone] = useState(timezone);
  const cancelTimezone = () => {
    dispatch(setEditingTimezone(false));
    dispatch(setTimezoneErrors(null));
  };

  return (
    <>
      <EditEmailContainer>
        <>
          <TimezoneInputContainer>
            <TimezoneSelect value={selectedTimezone} onChange={setSelectedTimezone} />
          </TimezoneInputContainer>
        </>
      </EditEmailContainer>
      <SaveTimezoneButton
        onClick={() => dispatch(saveTimeZone(customerId, selectedTimezone.value, enqueueSnackbar))}
      >
        Apply
      </SaveTimezoneButton>
      <CancelButton
        onClick={() => {
          cancelTimezone();
        }}
      >
        Cancel
      </CancelButton>
    </>
  );
};

EditingTimezone.propTypes = {
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  customerId: PropTypes.string,
  emailAddress: PropTypes.string,
  timezone: PropTypes.any,
};
const mapStateToProps = state => ({
  isLoading: state.profileSlice.isLoading,
  customerId: state.loginSlice.user.preferred_username,
  timezone: state.notificationSlice.userTimezonePreference,
});
EditingTimezone.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(EditingTimezone);
