import styled from 'styled-components';

export const StyledLogout = styled.button.attrs({
  id: 'logoutButton',
})`
  grid-area: logout;
  border-radius: 3px;
  transition: 220ms all ease-in-out;
  background-color: ${props => props.theme.primaryColor};
  :hover {
    background-color: ${props =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${props => props.theme.primaryActiveColadsfor};
  }
  color: white;
  padding: 8px 12px 8px 10px;
  border: 1px solid white;
  cursor: pointer;
`;
