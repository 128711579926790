import React from 'react';
import PropTypes from 'prop-types';
import {
  IndividualQuestionContainer,
  QuestionLabel,
  QuestionReplyText,
  QuestionReplyTextArea,
  RequiredField,
} from './styles';
import Select from 'react-select';
import {
  _functionName,
  _functions,
  _functionParms,
  _isJavaScript,
} from '../../report-claim-anonymous/step5/utils';
import { cloneDeep } from 'lodash';

const recurseFunctions = newArray => {
  for (var i = 0; i < newArray.length; i++) {
    //loop through current lvl of the array
    if (!newArray[i]?.questions) {
      //no more layers deep final check
      if (newArray[i].showOnParentValue) {
        if (_isJavaScript(newArray[i].showOnParentValue)) {
          newArray[i].function = {
            name: _functionName(newArray[i].showOnParentValue),
            args: _functionParms(newArray[i].showOnParentValue),
          };
          // we can add show/hide in here possibly?
        } else {
          newArray[i].function = {
            name: 'evalEquals',
            args: newArray[i].showOnParentValue,
          };
        }
        continue;
      } else {
        continue;
      }
    } else {
      if (newArray[i].showOnParentValue) {
        if (_isJavaScript(newArray[i].showOnParentValue)) {
          newArray[i].function = {
            name: _functionName(newArray[i].showOnParentValue),
            args: _functionParms(newArray[i].showOnParentValue),
          };
        } else {
          newArray[i].function = {
            name: 'evalEquals',
            args: newArray[i].showOnParentValue,
          };
        }
        continue;
      }
      recurseFunctions(newArray[i].questions.question);
      continue;
    }
  }
  return newArray;
};

export const mapSubQuestionFunctions = fullQuestions => {
  let cloned = cloneDeep(fullQuestions);
  cloned = recurseFunctions(cloned);
  return cloned;
};

const recursionMap = (newArray, response, thisQuestion) => {
  for (var i = 0; i < newArray.length; i++) {
    //loop through current lvl of the array
    if (!newArray[i]?.questions) {
      //no more questions deep? check ids
      if (newArray[i].id === thisQuestion.id) {
        newArray[i] = { ...newArray[i], Value: response };
        continue;
      } else {
        continue;
      }
    } else {
      if (newArray[i].id === thisQuestion.id) {
        //check ids at current level
        newArray[i] = { ...newArray[i], Value: response };
      }
      recursionMap(newArray[i].questions.question, response, thisQuestion); //go deeper
      continue;
    }
  }
  return newArray;
};
export const setQuestionResponses = (response, thisQuestion, setQuestion) => {
  setQuestion(prevState => {
    const cloned = cloneDeep(prevState);
    const recursedState = recursionMap(cloned, response, thisQuestion);
    return recursedState;
  });
};

export const NextLevelTree = ({
  thisQuestion,
  level,
  setQuestion,
  getListOptions,
  previousLevel,
}) => {
  let show = true;
  // need a show check at each level, previousLevel
  if (previousLevel && thisQuestion.function) {
    const parentValue = previousLevel.Value;
    const functionName = thisQuestion.function.name;
    const func = _functions[functionName];
    var args = thisQuestion.function.args;
    show = func(parentValue, args);
  }
  return (
    <>
      {thisQuestion.dataType === 'YesNo' && show && (
        <>
          <IndividualQuestionContainer>
            <QuestionLabel htmlFor={thisQuestion.name}>
              {thisQuestion.text}{' '}
              {thisQuestion.requiredInd === 'Yes' ? <RequiredField>*</RequiredField> : null}
            </QuestionLabel>
            <Select
              name={thisQuestion.name}
              id={thisQuestion.name}
              options={[
                { value: 'Yes', label: 'Yes' },
                { value: 'No', label: 'No' },
              ]}
              defaultValue={
                thisQuestion.Value ? { value: thisQuestion.Value, label: thisQuestion.Value } : ''
              }
              onChange={response => setQuestionResponses(response.value, thisQuestion, setQuestion)}
            />
          </IndividualQuestionContainer>
        </>
      )}
      {thisQuestion.dataType === 'List' && show && (
        <IndividualQuestionContainer>
          <QuestionLabel htmlFor={thisQuestion.name}>
            {thisQuestion.text}{' '}
            {thisQuestion.requiredInd === 'Yes' ? <RequiredField>*</RequiredField> : null}
          </QuestionLabel>
          <Select
            name={thisQuestion.name}
            id={thisQuestion.name}
            defaultValue={
              thisQuestion.Value
                ? { value: thisQuestion.Value, label: thisQuestion.Value }
                : { value: '', label: '' }
            }
            value={
              thisQuestion.Value
                ? { value: thisQuestion.Value, label: thisQuestion.Value }
                : { value: '', label: '' }
            }
            options={getListOptions(thisQuestion.constraints)}
            onChange={response => {
              setQuestionResponses(response.value, thisQuestion, setQuestion);
            }}
          />
        </IndividualQuestionContainer>
      )}
      {thisQuestion.dataType === 'TextArea' && show && (
        <IndividualQuestionContainer>
          <QuestionLabel htmlFor={thisQuestion.name}>
            {thisQuestion.text}{' '}
            {thisQuestion.requiredInd === 'Yes' ? <RequiredField>*</RequiredField> : null}
          </QuestionLabel>
          <QuestionReplyTextArea
            id={thisQuestion.name}
            type={thisQuestion.dataType}
            name={thisQuestion.name}
            defaultValue={thisQuestion.Value}
            required={thisQuestion.requiredInd === 'Yes'}
            onChange={e => setQuestionResponses(e.target.value, thisQuestion, setQuestion)}
          />
        </IndividualQuestionContainer>
      )}
      {thisQuestion.dataType === 'Numeric' && show && (
        <IndividualQuestionContainer>
          <QuestionLabel htmlFor={thisQuestion.name}>
            {thisQuestion.text}{' '}
            {thisQuestion.requiredInd === 'Yes' ? <RequiredField>*</RequiredField> : null}
          </QuestionLabel>
          <QuestionReplyText
            id={thisQuestion.name}
            type="number"
            name={thisQuestion.name}
            defaultValue={thisQuestion.Value}
            required={thisQuestion.requiredInd === 'Yes'}
            onChange={e => setQuestionResponses(e.target.value, thisQuestion, setQuestion)}
          />
        </IndividualQuestionContainer>
      )}
      {thisQuestion.dataType !== 'YesNo' &&
        thisQuestion.dataType !== 'List' &&
        thisQuestion.dataType !== 'TextArea' &&
        thisQuestion.dataType !== 'Numeric' &&
        show && (
          <IndividualQuestionContainer>
            <QuestionLabel htmlFor={thisQuestion.name}>
              {thisQuestion.text}{' '}
              {thisQuestion.requiredInd === 'Yes' ? <RequiredField>*</RequiredField> : null}
            </QuestionLabel>
            <QuestionReplyText
              id={thisQuestion.name}
              type={thisQuestion.dataType}
              name={thisQuestion.name}
              defaultValue={thisQuestion.Value}
              required={thisQuestion.requiredInd === 'Yes'}
              onChange={e => setQuestionResponses(e.target.value, thisQuestion, setQuestion)}
            />
          </IndividualQuestionContainer>
        )}
      {thisQuestion.Value &&
      'questions' in thisQuestion &&
      thisQuestion.questions.question &&
      show === true
        ? thisQuestion.questions.question.map(question => {
            return (
              <NextLevelTree
                name={question.name}
                level={level + 1}
                key={question.name}
                previousLevel={thisQuestion}
                thisQuestion={question}
                setQuestion={setQuestion}
                getListOptions={getListOptions}
              />
            );
          })
        : undefined}
    </>
  );
};
NextLevelTree.propTypes = {
  thisQuestion: PropTypes.any,
  level: PropTypes.any,
  handleResponse: PropTypes.any,
  handleSelectResponse: PropTypes.any,
  getListOptions: PropTypes.any,
  handleSubQuestionResponse: PropTypes.any,
  reportClaimDetailsSection: PropTypes.any,
  setQuestion: PropTypes.func,
  previousLevel: PropTypes.any,
};

export const TopLevelTree = ({ thisQuestion, setQuestion, getListOptions }) => {
  let level = 0;

  return (
    <NextLevelTree
      name={thisQuestion.name}
      level={level + 1}
      thisQuestion={thisQuestion}
      setQuestion={setQuestion}
      getListOptions={getListOptions}
    />
  );
};
TopLevelTree.propTypes = {
  thisQuestion: PropTypes.any,
  level: PropTypes.any,
  handleResponse: PropTypes.any,
  handleSelectResponse: PropTypes.any,
  getListOptions: PropTypes.any,
  handleSubQuestionResponse: PropTypes.any,
  reportClaimDetailsSection: PropTypes.any,
  setQuestion: PropTypes.func,
};
export const getQuestionInputType = (
  thisQuestion,
  setQuestion,
  getListOptions,
  reportClaimDetailsSection,
) => {
  return (
    <TopLevelTree
      name={thisQuestion.name}
      thisQuestion={thisQuestion}
      setQuestion={setQuestion}
      getListOptions={getListOptions}
      reportClaimDetailsSection={reportClaimDetailsSection}
    />
  );
};
export const recurseQuestionReplies = (event, answers) => {
  for (let i = 0; i < event.length; i++) {
    if (!event[i].questions) {
      if (event[i].Value) {
        answers.push({
          id: event[i].id,
          name: event[i].name,
          value: event[i]?.Value,
        });
      }
    } else if (event[i].questions) {
      if (event[i].Value) {
        answers.push({
          id: event[i].id,
          name: event[i].name,
          value: event[i].Value,
        });
      }
      recurseQuestionReplies(event[i].questions.question, answers);
    }
    continue;
  }
  return answers;
};
