import styled from 'styled-components';

export const ReportClaimStepFiveContainer = styled.div.attrs({
  id: 'ReportClaimStepFiveContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media (min-width: 1280px) {
    margin-left: 65px;
    margin-right: 65px;
  }
`;

export const QuestionReplyContainer = styled.div.attrs({
  id: 'questionReplyContainer',
})`
  display: grid;
  margin-top: 24px;
  @media (max-width: 599px) {
    column-gap: 8px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    column-gap: 8px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 3fr;
    row-gap: 8px;
  }
`;

export const RecapDesktopLabel = styled.div.attrs({
  id: 'recapDesktopLabel',
})`
  margin-top: 8px;
  margin-bottom: 8px;
  font-size: 18px;
  font-weight: 500;
  /* @media (max-width: 900px) {
    display: none;
  } */
`;

export const QuestionsMobileLabel = styled.div.attrs({})`
  margin-top: 25px;
  margin-bottom: 8px;
  /* font-size: 18px; */
  font-weight: 500;
  @media (min-width: 700px) {
    margin-top: initial;
    margin-bottom: initial;
  }
  @media (min-width: 900px) {
    display: none;
  }
`;

export const ResponsesMobileLabel = styled.div.attrs({
  id: 'responsesMobileLabel',
})`
  margin-top: 8px;
  margin-bottom: 8px;
  /* font-size: 18px; */
  font-weight: 500;
  @media (min-width: 700px) {
    margin-top: initial;
    margin-bottom: initial;
  }
  @media (min-width: 900px) {
    display: none;
  }
`;

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;
export const PolicyNumberLabel = styled.div.attrs({
  id: 'QuestionPolicyNumberLabel',
})`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const QuestionLabel = styled.div.attrs(props => ({
  id: props.id ? props.id : 'questionLabelGeneric',
}))`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const LossDateLabel = styled.div.attrs({
  id: 'QuestionLossDateLabel',
})`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const LossTimeLabel = styled.div.attrs({
  id: 'QuestionLossTimeLabel',
})`
  font-size: 14px;
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const EventNameLabel = styled.h3.attrs({
  id: 'eventNameLabel',
})`
  font-weight: 600;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
`;
export const EventMessage = styled.h3.attrs({
  id: 'eventMessage',
})`
  font-weight: 400;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
`;
