import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLocation: {
    // riskAddress: null,
  },
  claimEvent: null,
  claimEventContacts: null,
  isClaimEventsContactsLoading: false,
  claimEventContactsErrors: '',
  driverRelationshipSelected: '',
  isClaimEventLoading: false,
  selectedState: {},
  selectedVehicle: {
    label: '',
    value: '',
    id: '',
    riskNumber: '',
    description: '',
    typeCd: '',
    riskIdRef: '',
    vehicleModelYr: '',
    vehicleManufacturer: '',
    vehicleModel: '',
    vehicleIdentificationNumber: '',
    lineCd: '',
    ncciMake: '',
    vehicleInd: '',
    policyNumber: '',
  },
  selectedDriver: {
    value: '',
    label: '',
    id: '',
    partyInfo: '',
    policyNumber: '',
  },
  manualDriverName: '',
  manualLicenseNumber: '',
  isNonUSAddress: false,
  dateValue: '',
  timeValue: '',
  zipText: '',
  descriptionText: '',
  location1Text: '',
  location2Text: '',
  location3Text: '',
  location4Text: '',
  cityText: '',
  stateText: '',
  county: '',
  countryNonUS: '',
  addr: [
    {
      id: '',
      addrTypeCd: '',
      addr1: '',
      city: '',
      stateProvCd: '',
      postalCode: '',
    },
  ],
};
export let mapLocation1 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr1: action.payload };
  });
};
export let mapLocation2 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr2: action.payload };
  });
};
export let mapLocation3 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr3: action.payload };
  });
};
export let mapLocation4 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr4: action.payload };
  });
};
export let mapAddrTypeCd = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addrTypeCd: action.payload };
  });
};
export let mapId = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], id: action.payload };
  });
};
export let mapCity = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], city: action.payload };
  });
};
export let mapState = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], stateProvCd: action.payload };
  });
};
export let mapZip = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], postalCode: action.payload };
  });
};

export const anonymousReportClaimStepThreeSlice = createSlice({
  name: 'anonymousReportClaimStepThreeSlice',
  initialState,
  reducers: {
    resetStepThreeState: () => {
      return initialState;
    },
    setAddrTypeCd: (state, action) => {
      return { ...state, addr: mapAddrTypeCd(state, action) };
    },
    setSelectedLocation: (state, action) => {
      return { ...state, selectedLocation: action.payload };
    },
    setDateValue: (state, action) => {
      return { ...state, dateValue: action.payload };
    },
    setId: (state, action) => {
      return { ...state, addr: mapId(state, action) };
    },
    setTimeValue: (state, action) => {
      return { ...state, timeValue: action.payload };
    },
    setSelectedState: (state, action) => {
      return { ...state, selectedState: action.payload };
    },
    setZip: (state, action) => {
      return { ...state, addr: mapZip(state, action) };
    },
    setDescription: (state, action) => {
      return { ...state, descriptionText: action.payload };
    },
    setSelectedVehicle: (state, action) => {
      return { ...state, selectedVehicle: action.payload };
    },
    setSelectedDriver: (state, action) => {
      return { ...state, selectedDriver: action.payload };
    },
    setIsNonUSAddress: (state, action) => {
      return { ...state, isNonUSAddress: action.payload };
    },
    setLocation1: (state, action) => {
      return { ...state, addr: mapLocation1(state, action) };
    },
    setLocation2: (state, action) => {
      return { ...state, addr: mapLocation2(state, action) };
    },
    setLocation3: (state, action) => {
      return { ...state, addr: mapLocation3(state, action) };
    },
    setLocation4: (state, action) => {
      return { ...state, addr: mapLocation4(state, action) };
    },
    setCity: (state, action) => {
      return { ...state, addr: mapCity(state, action) };
    },
    setCounty: (state, action) => {
      return { ...state, county: action.payload };
    },
    setCountryNonUS: (state, action) => {
      return { ...state, countryNonUS: action.payload };
    },
    setState: (state, action) => {
      return { ...state, addr: mapState(state, action) };
    },
    setDriverNameManual: (state, action) => {
      return { ...state, manualDriverName: action.payload };
    },
    setLicenseNumberManual: (state, action) => {
      return { ...state, manualLicenseNumber: action.payload };
    },
    setClaimEvent: (state, action) => {
      return { ...state, claimEvent: action.payload };
    },
    // setClaimEventContacts: (state, action) => {
    //   return { ...state, claimEventContacts: action.payload };
    // },
    // setClaimEventContactsErrors: (state, action) => {
    //   return { ...state, claimEventContactsErrors: action.payload };
    // },
    setClaimEventLoading: (state, action) => {
      return { ...state, isClaimEventLoading: action.payload };
    },
    setClaimEventContactsLoading: (state, action) => {
      return { ...state, isClaimEventsContactsLoading: action.payload };
    },
    setSelectedDriverRelationship: (state, action) => {
      return { ...state, driverRelationshipSelected: action.payload };
    },
  },
});
export const {
  resetStepThreeState,
  setAddrTypeCd,
  setSelectedLocation,
  setSelectedState,
  setIsNonUSAddress,
  setZip,
  setDescription,
  setId,
  setLocation1,
  setLocation2,
  setLocation3,
  setLocation4,
  setCity,
  setCounty,
  setCountryNonUS,
  setState,
  setDateValue,
  setTimeValue,
  setSelectedVehicle,
  setSelectedDriver,
  setDriverNameManual,
  setLicenseNumberManual,
  setClaimEvent,
  setClaimEventLoading,
  setClaimEventContacts,
  setClaimEventContactsErrors,
  setClaimEventContactsLoading,
  setSelectedDriverRelationship,
} = anonymousReportClaimStepThreeSlice.actions;
export default anonymousReportClaimStepThreeSlice.reducer;
