// Viewport Sizing
// xs, extra-small: 0px
// sm, small: 600px
// md, medium: 900px
// lg, large: 1280px
// xl, extra-large: 1920px
import styled from 'styled-components';
import InputMask from 'react-input-mask';
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import DeleteIcon from '@mui/icons-material/Delete';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import AddIcon from '@mui/icons-material/Add';

export const AccountDetailsHeader = styled.h3.attrs({
  id: 'accountDetailsHeader',
})`
  font-family: 'Helvetica Neue', Lato, proxima-nova, Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  margin-left: 30px;
`;

export const PayoffCurrentDueAndDueDateContainer = styled.div.attrs({
  id: 'payoffCurrentDueAndDueDateContainer',
})`
  display: grid;
  margin-left: 30px;
  margin-bottom: 26px;
  @media (max-width: 900px) {
    grid-template-areas:
      ' payoff '
      ' currentdue'
      ' duedate';
    row-gap: 16px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
    grid-template-areas: ' payoff currentdue duedate ';
  }
`;

export const PayoffContainer = styled.div.attrs({
  id: 'payoffAmountContainer',
})`
  display: grid;

  grid-template-areas:
    ' header '
    ' data';
  @media (max-width: 900px) {
  }
  @media (min-width: 900px) {
    margin-left: 30px;
    row-gap: 16px;
  }
`;
export const ChangePayScheduleContainer = styled.div.attrs({
  id: 'changePayContainer',
})`
  margin: 20px 0;
  font-weight: 600;
`;
export const ChangePayMethodContainer = styled.div.attrs({
  id: 'changePayContainer',
})`
  margin-left: 8px;
  display: inline;
  font-weight: 600;
`;
export const PayPlanPayInformation = styled.div.attrs({
  id: 'payPlanInformation',
})`
  margin: 20px 0 0 0;
  display: inline-block;
`;
export const PaymentInfo = styled.span.attrs({
  id: 'PayInfo',
})`
  margin: 20px 0;
  font-weight: 600;
`;
export const CurrentDueContainer = styled.div.attrs({
  id: 'currentDueContainer',
})`
  display: grid;

  grid-template-areas:
    ' header '
    ' data';
  @media (max-width: 900px) {
  }
  @media (min-width: 900px) {
    margin-left: 30px;
    row-gap: 16px;
  }
`;
export const DueDateContainer = styled.div.attrs({
  id: 'dueDateContainer',
})`
  display: grid;

  grid-template-areas:
    ' header '
    ' data';
  @media (max-width: 900px) {
  }
  @media (min-width: 900px) {
    margin-left: 30px;
    row-gap: 16px;
  }
`;

export const PayoffAmountHeaderMobile = styled.div.attrs({
  id: 'payoffAmountHeader',
})`
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 900px) {
    /* display: none; */
  }
`;
export const PayoffAmountData = styled.div.attrs({
  id: 'payoffAmountData',
})``;
export const CurrentDueHeaderMobile = styled.div.attrs({
  id: 'currentDueHeader',
})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    /* display: none; */
  }
`;
export const CurrentDueData = styled.div.attrs({
  id: 'currentDueData',
})`
  grid-area: data;
`;
export const DueDateHeaderMobile = styled.div.attrs({
  id: 'accountDueDateHeader',
})`
  font-size: 16px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const DueDateData = styled.div.attrs({
  id: 'accountDueDateData',
})`
  grid-area: data;
`;

export const RecentPaymentsHeader = styled.h3.attrs({
  id: 'recentPaymentsHeader',
})`
  font-family: 'Helvetica Neue', Lato, proxima-nova, Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  margin-left: 30px;
`;

export const RecentPaymentDataContainer = styled.div.attrs({
  id: 'recentPaymentsDataContainer',
})`
  display: grid;
  margin-left: 30px;
  @media (max-width: 599px) {
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    margin-bottom: 5%;
    border-bottom: 2px solid ${(props) => props.theme.gray};
    padding-bottom: 5%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }

  @media (min-width: 900px) {
    margin-left: 30px;
    margin-right: 30px;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  }
`;
export const PaymentIdContainer = styled.div.attrs({
  id: 'paymentIdContainer',
})`
  display: grid;

  grid-template-rows: 1fr 1fr;
  @media (max-width: 900px) {
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const PaymentIdHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;
export const PaymentIdData = styled.div.attrs({})``;
export const ReceiptDateContainer = styled.div.attrs({
  id: 'receiptDateContainer',
})`
  display: grid;
  grid-template-rows: 1fr 1fr;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const DescriptionContainer = styled.div.attrs({
  id: 'descriptionContainer',
})`
  display: grid;
  grid-template-rows: 1fr 1fr;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const ReceiptDateHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;
export const ReceiptDescriptionHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;
export const ReceiptDateData = styled.div.attrs({})``;
export const ReceiptDescriptionData = styled.div.attrs({})``;
export const AmountContainer = styled.div.attrs({
  id: 'amountContainer',
})`
  display: grid;

  grid-template-rows: 1fr 1fr;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const AmountHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 900px) {
    display: none;
  }
`;
export const AmountData = styled.div.attrs({})``;
export const StatusContainer = styled.div.attrs({
  id: 'statusContainer',
})`
  display: grid;

  grid-template-rows: 1fr 1fr;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const StatusHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;
export const StatusData = styled.div.attrs({})``;

export const RecentPaymentHeaderContainer = styled.div.attrs({})`
  display: grid;
  margin-left: 30px;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    display: none;
  }
  @media (min-width: 900px) {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    margin-right: 30px;
  }
`;

export const PaymentIdHeader = styled.div.attrs({
  id: 'paymentIdHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const ReceiptDateHeader = styled.div.attrs({
  id: 'receiptDateHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const ReceiptDescriptionHeader = styled.div.attrs({
  id: 'receiptDescriptionHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const AmountHeader = styled.div.attrs({
  id: 'amountHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const StatusHeader = styled.div.attrs({
  id: 'statusHeader',
})`
  font-weight: 700;
  margin-left: 30px;
  @media (max-width: 900px) {
    display: none;
  }
`;

export const BalancesHeader = styled.h3.attrs({
  id: 'balancesHeader',
})`
  margin: 30px;
`;

export const BalancesDataContainer = styled.div.attrs({
  id: 'balancesDataContainer',
})`
  display: grid;
  margin-bottom: 20px;
  margin-left: 30px;
  @media (max-width: 599px) {
    grid-template-rows: 1fr 1fr 1fr 1fr;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    grid-template-columns: ${(props) =>
      props.showPaymentSchedule ? `1fr 1fr 1fr 1fr` : `1fr 1fr 1fr 1fr`};
  }
  @media (min-width: 900px) {
    margin-left: 30px;
    grid-template-columns: ${(props) =>
      props.showPaymentSchedule ? `25% 25% 20% 30%` : `25% 25% 20% 30%`};
  }
`;
export const PolicyNumberContainer = styled.div.attrs({
  id: 'policyNumberContainer',
})`
  display: grid;

  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const PolicyNumberHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;
export const PolicyNumberData = styled.div.attrs({})``;
export const PayoffAmountBalancesContainer = styled.div.attrs({
  id: 'payoffAmountBalancesContainer',
})`
  display: grid;

  @media (max-width: 600px) {
    margin-top: 8px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const PayoffAmountBalancesHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;
export const PayoffAmountBalancesData = styled.div.attrs({})``;
export const CurrentDueBalancesContainer = styled.div.attrs({
  id: 'currentDueBalancesContainer',
})`
  display: grid;

  @media (max-width: 600px) {
    margin-top: 8px;
  }
  @media (min-width: 900px) {
    margin-left: 30px;
  }
`;
export const CurrentDueBalancesHeaderMobile = styled.div.attrs({})`
  font-size: 16px;
  font-weight: 700;

  @media (min-width: 900px) {
    display: none;
  }
`;
export const CurrentDueBalancesData = styled.div.attrs({})``;
export const DueDateBalancesContainer = styled.div.attrs({
  id: 'dueDateBalancesContainer',
})`
  display: grid;

  /* grid-template-rows: 1fr 1fr; */
  @media (max-width: 900px) {
  }
  @media (min-width: 900px) {
    margin-left: 24px;
    position: relative;
    bottom: 6px;
  }
`;
export const DueDateBalancesHeaderMobile = styled.div.attrs({
  id: 'dueDateMobileHeader',
})`
  font-size: 16px;
  font-weight: 700;
  @media (min-width: 900px) {
    display: none;
  }
`;

export const BalancesHeaderContainer = styled.div.attrs({
  id: 'balancesHeader',
})`
  display: grid;
  margin-left: 30px;
  margin-bottom: 20px;
  @media (max-width: 900px) {
    display: none;
  }
  @media (min-width: 900px) {
    grid-template-columns: 25% 25% 20% 30%;
  }
`;

export const PolicyNumberBalancesHeader = styled.div.attrs({
  id: 'policyNumberBalancesHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const PayoffAmountBalancesHeader = styled.div.attrs({
  id: 'payoffAmountBalancesHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const CurrentDueBalancesHeader = styled.div.attrs({
  id: 'currentDueBalancesHeader',
})`
  margin-left: 30px;
  font-weight: 700;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const DueDateBalancesHeader = styled.div.attrs({
  id: 'dueDateBalancesHeader',
})`
  font-weight: 700;
  margin-left: 30px;
  @media (max-width: 900px) {
    display: none;
  }
`;
export const PaymentScheduleButton = styled.div.attrs({})`
  display: inline-block;
  width: 65%;
  margin-bottom: 2%;
  position: relative;
  bottom: 6px;
  @media (max-width: 600px) {
    width: 86%;
  }
  @media (max-width: 900px) {
    margin-top: 4%;
  }
`;
export const PaymentScheduleHideButton = styled.button.attrs({
  id: 'hidePaymentScheduleButton',
})`
  display: inline-block;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  width: 48%;
  bottom: 8px;
  :hover {
    background-color: transparent;
  }
  :active {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    width: 86%;
  }
  @media (max-width: 900px) {
    margin-top: 4%;
  }
`;
export const PaymentScheduleCancelButton = styled.button.attrs({
  id: 'cancelButton',
})`
  display: inline-block;
  color: #00739d;
  background-color: transparent;
  box-shadow: none;
  cursor: pointer;
  position: relative;
  :hover {
    background-color: transparent;
  }
  :active {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    /* width: 60%; */
  }
`;

export const PayPlanGrossAmountHeader = styled.div.attrs({})`
  font-weight: bold;
`;
export const PayPlanScheduleContainer = styled.div.attrs({
  id: 'currentPayPlanSchedule',
})`
  background-color: #dfdedf;
  padding: 20px;
  grid-column: 1/5;
  margin: 30px;
  @media (max-width: 599px) {
    grid-column: initial;
    margin: initial;
    margin-top: 15px;
    overflow: auto;
  }
`;

export const NoRecentPayments = styled.div.attrs({
  id: 'noRecentPaymentsErrorMessage',
})`
  margin-left: 60px;
  font-size: 18px;
`;
export const HidePaymentArea = styled.div.attrs({
  id: 'paymentScheduleContainer',
})`
  cursor: pointer;
  @media (max-width: 600px) {
    position: relative;
    right: 7px;
  }
  @media (min-width: 900px) {
    position: initial;
  }
`;
export const NewPaymentActionContainer = styled.div`
  cursor: pointer;
`;
export const ShowPaymentContainer = styled.div.attrs({
  id: 'showPaymentScheduleContainer',
})`
  cursor: pointer;
  @media (max-width: 600px) {
    position: relative;
    right: 7px;
  }
`;
export const PaymentScheduleEditButton = styled.button.attrs({
  id: 'editPaySchedule',
})`
  color: #00a5b8;
  background-color: transparent;
  box-shadow: none;
  text-decoration: underline;
  cursor: pointer;
  :hover {
    color: #008a9a;
    background-color: transparent;
  }
  :active {
    color: #006f7c;
    background-color: transparent;
  }
`;
export const ChangePayScheduleEditScreenContainer = styled.div.attrs({
  id: 'changePayScheduleEditScreenContainer',
})`
  background-color: white;
  padding: 20px;
  margin-top: 20px;
`;
export const PayScheduleEditHeader = styled.h1.attrs({
  id: 'payScheduleEditHeader',
})`
  margin: 0;
`;
export const EditPaymentScheduleButtonContainer = styled.div.attrs({
  id: 'editPayScheduleButtonContainer',
})`
  float: right;
  margin-top: 16px;
`;
export const PayPlanButtonContainer = styled.div.attrs({
  id: 'payPlanOptionsContainers',
})`
  width: 24%;
  margin: 20px 0;
  @media (max-width: 600px) {
    width: 80%;
  }
`;
export const DateOfPaymentContainer = styled.div.attrs({
  id: 'dateOfMonthToPayContainer',
})`
  width: 40%;
  margin: 20px 0;
  @media (max-width: 600px) {
    width: 80%;
  }
`;
export const SelectPayPlanHeader = styled.div`
  margin: 20px 0;
`;
export const PaymentDateHeader = styled.div`
  margin: 20px 0;
`;
export const StyledPayDateInput = styled(InputMask).attrs({
  type: 'number',
})``;
export const SelectPayMethodHeader = styled.div.attrs({
  id: 'paymentMethodHeader',
})`
  margin: 20px 0;
`;
export const SaveNewPaymentScheduleButton = styled.button.attrs({
  id: 'SaveNewPaymentScheduleButton',
})`
  color: white;
  padding: 8px 20px;
  margin-left: 8px;
`;
export const CurrentPayPlanHeader = styled.h2.attrs({
  id: 'currentPayPlan',
})`
  margin: 0 0 20px 0;
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const BillNumber = styled.span.attrs({
  id: 'billNumber',
})`
  position: relative;
  bottom: 7px;
`;
export const BillNumberHeader = styled.span.attrs({
  id: 'billNumberHeader',
})`
  margin-left: 24px;
`;
export const ShowPaymentsButton = styled.button.attrs({
  id: 'showPaymentButtons',
})`
  grid-column-start: 3;
  color: ${(props) => props.theme.primaryColor};
  background-color: initial;
  box-shadow: initial;
  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
  }
  :hover {
    color: ${(props) => props.theme.primaryHoverColor};
    background-color: initial;
    box-shadow: initial;
    text-decoration: underline;
  }
  :active {
    color: ${(props) => props.theme.primaryHoverColor};
    background-color: initial;
    box-shadow: initial;
  }
`;
export const ShowRecentPaymentsButton = styled.button.attrs({
  id: 'showRecentPaymentButtons',
})`
  grid-column-start: 3;
  color: ${(props) => props.theme.primaryColor};
  background-color: initial;
  box-shadow: initial;
  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
  }
  :hover {
    color: ${(props) => props.theme.primaryHoverColor};
    background-color: initial;
    box-shadow: initial;
    text-decoration: underline;
  }
  :active {
    color: ${(props) => props.theme.primaryHoverColor};
    background-color: initial;
    box-shadow: initial;
  }
`;
export const TableFix = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 2fr 1fr;
  margin-left: 30px;
  justify-items: start;
  @media (max-width: 600px) {
    display: block;
    margin: 0 auto;
  }
`;

export const StyledModal = styled.div`
  padding: 16px 24px;
  @media (max-width: 599px) {
    width: calc(100% - 64px);
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 361px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 361px;
  }
  @media (min-width: 1280px) {
    width: 361px;
  }
  min-height: 260px;
`;
export const StyledACHContainer = styled.div`
  margin: 45px 0 45px;
`;
export const StyledACHInputContainer = styled.div`
  margin: 24px 0 24px;
`;
export const PaymentContainer = styled.div`
  margin: 30px;
`;
export const PaymentHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const SubHeader = styled.h2`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;
export const DateContainer = styled.div`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    grid-template-columns: 32% 32% 32%;
    column-gap: 24px;
  }
`;

export const AmountCards = styled.div`
  margin-bottom: 24px;
  height: 144px;
  width: 100%;
  border-radius: 4px;
  background-color: #fafafa;
`;
export const DateChoice = styled.input.attrs({
  type: 'date',
  'aria-label': 'Payment Date',
})`
  display: block;
  margin-top: 8px;
`;
export const FullAmountCard = styled(AmountCards)``;
export const MinimumAmountCard = styled(AmountCards)``;
export const OtherAmountCard = styled(AmountCards)``;
export const RadioChoice = styled.input.attrs({
  type: 'radio',
})`
  margin-left: 16px;
  margin-top: 16px;
  height: 24px;
  width: 24px;
`;

export const PayDateHeader = styled.div.attrs({
  id: 'PayDateHeader',
})`
  font-size: 16px;
  color: #28333f;
  margin-left: 16px;
  margin-top: 16px;
  font-weight: 400;
  display: inline-block;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;
`;
export const AmountText = styled.div`
  font-size: 32px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #28333f;
  display: block;
  margin-left: 48px;
  margin-top: 8px;
`;

export const PartialAmountInput = styled.input.attrs({
  id: 'partialAmountInput',
  placeholder: '$0.00',
})`
  display: block;
  margin-left: 48px;
  width: 121px;
  height: 40px;
`;

export const InformationArea = styled.div.attrs({
  id: 'amountInformation',
})`
  margin-left: 48px;
  margin-top: 16px;
  font-size: 14px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const StyledInfoIcon = styled(InfoIcon).attrs({
  className: 'fa fa-lg fa-info-circle',
})`
  margin-right: 6px;
`;

export const PaymentTypeContainer = styled.div.attrs({})`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;

export const PaymentTypeHeader = styled.div.attrs({})`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;
export const PaymentCards = styled.div`
  margin-bottom: 24px;
  height: 56px;
  display: block;
  width: 350px;
  border-radius: 4px;
  background-color: #fafafa;
  :hover {
    background-color: #fafafa;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const CreditCard = styled(PaymentCards)`
  font-size: 16px;
`;
export const ACHCard = styled(PaymentCards)`
  font-size: 16px;
`;
export const AddNewCard = styled.button.attrs({
  id: 'addNewCardButton',
})`
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  display: block;
  margin-top: 0;
  margin-bottom: 16px;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    background-color: white;
  }
  box-shadow: 0 1px 4px 0 rgba(40, 51, 63, 0.24);
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 290px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 290px;
  }
  @media (min-width: 1280px) {
    width: 290px;
  }
`;
export const AddNewPaymentType = styled.button.attrs({
  id: 'addNewPaymentTypeButton',
})`
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  display: block;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    background-color: white;
  }
  box-shadow: 0 1px 4px 0 rgba(40, 51, 63, 0.24);
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 290px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 290px;
  }
  @media (min-width: 1280px) {
    width: 290px;
  }
`;
export const AddNewBankAccount = styled.button.attrs({
  id: 'addNewBankAccountButton',
})`
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  display: block;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    background-color: white;
  }
  box-shadow: 0 1px 4px 0 rgba(40, 51, 63, 0.24);
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 290px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 290px;
  }
  @media (min-width: 1280px) {
    width: 290px;
  }
`;

export const StyledCreditIcon = styled(CreditCardIcon).attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  margin-left: 8px;
`;
export const StyledModalCreditIcon = styled(CreditCardIcon).attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  position: relative;
  top: 3px;
`;
export const StyledDeleteIcon = styled(DeleteIcon).attrs({
  className: 'fa fa-lg fa-trash',
})`
  cursor: pointer;
  top: 17px;
  right: 22px;
  position: relative;
  float: right;
`;
export const CreditInfo = styled.span.attrs({
  id: 'creditCardInfo',
  className: 'creditInfoSpan',
})`
  margin-left: 8px;
  bottom: 6px;
  font-weight: 400;
  position: relative;
`;

export const StyledACHIcon = styled(AccountBalanceIcon).attrs({
  className: 'fa fa-lg fa-university',
})`
  margin-left: 8px;
`;
export const StyledModalACHIcon = styled(AccountBalanceIcon).attrs({
  className: 'fa fa-lg fa-university',
})`
  margin-left: 18px;
  position: relative;
  top: 3px;
`;

export const ACHInfo = styled.span.attrs({
  className: 'creditInfoSpan',
})`
  margin-left: 8px;
  bottom: 5px;
  font-weight: 400;
  position: relative;
`;
export const StyledAddIcon = styled(AddIcon).attrs({
  className: 'fa fa-lg fa-plus',
})`
  position: relative;
  top: 2px;
  color: ${(props) => props.theme.primaryColor};
  @media (max-width: 599px) {
    margin-left: 3px;
  }
`;
export const AddPaymentInfo = styled.span.attrs({
  className: 'addPaymentInfoSpan',
})`
  font-size: 16px;
  bottom: 4px;
  position: relative;
  margin-left: 8px;
  font-weight: 400;
  color: ${(props) => props.theme.primaryColor};
  /* text-decoration: none; */
`;
export const StyledDeleteButton = styled.button.attrs({
  id: 'deleteCardYesButton',
})`
  background-color: #b82323;
  :hover {
    background-color: red;
  }
  :active {
    background-color: #ad1c1c;
  }
  color: white;
  border: none;
  padding: 6px 55px 6px 55px;
  margin-right: 14px;
`;
export const StyledButtonNextDeleteCard = styled.button.attrs({
  id: 'deleteCardNoButton',
})`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  border: none;
  padding: 6px 55px 6px 55px;
`;
export const StyledTextSpan = styled.span.attrs({})`
  margin-left: 16px;
  font-size: 18px;
  position: relative;
  bottom: 3px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-decoration: none;
`;

export const StyledRoutingNumber = styled(InputMask).attrs({
  id: 'RoutingNumber',
})`
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 28px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;
export const StyledAccountNumber = styled(InputMask).attrs({
  id: 'AccountNumber',
})`
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 28px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;

export const StyledNameOnAccount = styled.input`
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 28px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;
export const StyledAddACH = styled.button.attrs({
  id: 'addAccountButton',
})`
  margin: 8px 0 8px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 8px 24px;
`;

export const StyledHorizontalLine = styled.div`
  background-color: #7d91ac;
  height: 1px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    margin-right: 24px;
    margin-left: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-right: 24px;
    margin-left: 24px;
  }
  @media (min-width: 1280px) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;
export const StyledACHTitle = styled.h3.attrs({
  id: 'bankAccountDetailsHeader',
})`
  font-size: 20px;
  font-family: 'Roboto', proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
`;
export const StyledLabel = styled.label`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const StyledDialogSpan = styled.span`
  padding: 16px 24px;
  color: gray;
  font-size: 18px;
`;
export const StyledDialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 16px 24px;
  align-items: center;
  justify-content: flex-start;
`;
export const FieldErrorMessage = styled.div`
  color: red;
  font-size: 12px;
  margin-top: 2px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 16px 0;
  font-size: initial;
`;
