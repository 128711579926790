import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  ClaimEventNameLabel,
  HomeScreenButton,
  InfoContainer,
  ReportClaimConfirmationContainer,
  StyledReportClaimHeader,
  StyledSubSubHeader,
} from './styles';
import Loader from '../../../common/loader';
import ApiError from '../../../common/api-error';
import { gaEvent } from '../../../../utils/google-analytics';
import { useHistory } from 'react-router';
import { ga4Event } from '../../../../utils/google-analytics-4';

export const ReportClaimConfirmation = props => {
  const { claimDescription, claimSubmission, referenceNumber } = props;

  React.useEffect(() => {
    gaEvent({ category: 'report-a-claim', action: 'complete' });
    ga4Event({ category: 'report-a-claim', action: 'complete' });
  }, []);

  const history = useHistory();

  return (
    <ReportClaimConfirmationContainer>
      <Helmet>
        <title>Report Claim - Confirmation</title>
      </Helmet>
      <StyledReportClaimHeader>Report Claim</StyledReportClaimHeader>
      <ClaimEventNameLabel>{claimDescription}</ClaimEventNameLabel>
      {claimSubmission !== 'Failure' && <StyledSubSubHeader>Confirmation</StyledSubSubHeader>}
      {claimSubmission === '' && <Loader />}
      {claimSubmission !== '' && claimSubmission === 'Success' ? (
        <InfoContainer>
          Thank you for submitting your claim report. Your claims adjuster will be contacting you
          shortly. If you have any questions, please contact your Agent.
          {referenceNumber && <> Reference: {referenceNumber}</>}
        </InfoContainer>
      ) : null}
      {claimSubmission !== '' && claimSubmission === 'Failure' ? (
        <ApiError
          body="There was a problem submitting your claim. Please
          contact your agent for further assistance."
          variant="nomargin"
        />
      ) : null}
      {claimSubmission !== '' && (
        <HomeScreenButton
          id="HomeScreenButton"
          onClick={() => {
            history.push('/dashboard');
          }}
        >
          Return Home
        </HomeScreenButton>
      )}
    </ReportClaimConfirmationContainer>
  );
};
ReportClaimConfirmation.propTypes = {
  claimDescription: PropTypes.string,
  claimSubmission: PropTypes.string,
  referenceNumber: PropTypes.string,
};
const mapStateToProps = state => ({
  claimDescription: state.reportClaimSlice.selectedClaimEventTemplate.description,
  claimSubmission: state.reportClaimStepFiveSlice.claimSubmission,
  referenceNumber: state.reportClaimStepFiveSlice.referenceNumber,
});
export default connect(mapStateToProps)(ReportClaimConfirmation);
