import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { getClaims } from './claimsAPI';
import Loader from '../../../common/loader';
import {
  ClaimsDashboardContainer,
  ClaimsDataContainer,
  ClaimsHeader,
  ClaimsLabelContainer,
  ClaimNumberContainer,
  ClaimNumberLabel,
  ClaimNumberLabelMobile,
  HorizontalLine,
  LoaderMargin,
  LossCauseCdContainer,
  LossCauseCdLabel,
  LossCauseCdLabelMobile,
  LossDtLabel,
  LossDtContainer,
  LossDtLabelMobile,
  StatusCdContainer,
  StatusCdLabel,
  StatusCdLabelMobile,
  UploadLabel,
} from './styles';
import { Link } from 'react-router-dom';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';
import ApiError from '../../../common/api-error';

export const Claims = props => {
  const { claims, isLoading, errors, customerId, features } = props;

  React.useEffect(() => {
    props.dispatch(getClaims(customerId));
  }, []);

  return (
    <ClaimsDashboardContainer>
      <ClaimsHeader>Claims</ClaimsHeader>
      <ClaimsLabelContainer claimDocumentUpload={features.claimDocumentUpload}>
        <ClaimNumberLabel aria-label="Column Header Claim Number"> Claim Number </ClaimNumberLabel>
        {features.claimStatus && (
          <StatusCdLabel aria-label="Column Header Status">Status</StatusCdLabel>
        )}
        <LossDtLabel aria-label="Column Header Loss Date"> Loss Date </LossDtLabel>
        <LossCauseCdLabel aria-label="Column Header Loss Cause">Loss Cause</LossCauseCdLabel>
        {features.claimDocumentUpload && (
          <UploadLabel aria-label="Column Header Documents">Documents </UploadLabel>
        )}
      </ClaimsLabelContainer>
      {isLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : (
        <>
          {errors ? (
            <ApiError body="There was an error loading your Claims." variant={'dashboard'} />
          ) : (
            <>
              {claims.map(claim => {
                return (
                  <ClaimsDataContainer
                    claimDocumentUpload={features.claimDocumentUpload}
                    key={claim.claimNumber}
                  >
                    <ClaimNumberContainer>
                      <ClaimNumberLabelMobile>Claim Number</ClaimNumberLabelMobile>
                      <Link
                        to={{
                          pathname: '/claimDetails',
                          state: {
                            claimSystemId: claim.systemId.toString(),
                          },
                        }}
                      >
                        <div aria-label={`Claim Number ${claim.claimNumber}`}>
                          {claim.claimNumber}
                        </div>
                      </Link>
                    </ClaimNumberContainer>
                    {features.claimStatus && (
                      <StatusCdContainer>
                        <StatusCdLabelMobile>Status Code</StatusCdLabelMobile>
                        <div aria-label={`Status ${claim.statusCd}`}>{claim.statusCd}</div>
                      </StatusCdContainer>
                    )}
                    <LossDtContainer
                      aria-label={`Loss Date ${moment(claim.lossDt).format(DISPLAY_DATE_FORMAT)}`}
                    >
                      <LossDtLabelMobile>Loss Date</LossDtLabelMobile>
                      {moment(claim.lossDt).format(DISPLAY_DATE_FORMAT)}
                    </LossDtContainer>
                    <LossCauseCdContainer aria-label={`Loss Cause ${claim.lossCauseCd}`}>
                      <LossCauseCdLabelMobile>Loss Cause</LossCauseCdLabelMobile>
                      {claim.lossCauseCd}
                    </LossCauseCdContainer>
                    {features.claimDocumentUpload && (
                      <ClaimNumberContainer>
                        <ClaimNumberLabelMobile>Documents </ClaimNumberLabelMobile>
                        <Link
                          aria-label="Upload Documents"
                          to={{
                            pathname: '/claimDetails',
                            state: {
                              claimSystemId: claim.systemId.toString(),
                              uploadDocumentsClicked: true,
                            },
                          }}
                        >
                          <div>Upload Documents</div>
                        </Link>
                      </ClaimNumberContainer>
                    )}
                    <HorizontalLine />
                  </ClaimsDataContainer>
                );
              })}
            </>
          )}
        </>
      )}
    </ClaimsDashboardContainer>
  );
};

Claims.propTypes = {
  dispatch: PropTypes.func,
  claims: PropTypes.array,
  isLoading: PropTypes.bool,
  customerId: PropTypes.string,
  errors: PropTypes.any,
  features: PropTypes.object,
  totalUploads: PropTypes.number,
};

const mapStateToProps = state => ({
  claims: state.claimsSlice.claims,
  customerId: state.loginSlice.user.customer_id,
  isLoading: state.claimsSlice.isLoading,
  errors: state.claimsSlice.errors,
  features: state.configurationSlice.features.features,
  state,
});

Claims.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(Claims);
