export const checkFileSize = (files, uploadFileSize, notify) => {
  const allowedSize = parseSizeToBytes(uploadFileSize);
  const badFiles = files.filter(currentFile => currentFile.size >= allowedSize);
  const goodFiles = files.filter(currentFile => currentFile.size < allowedSize);

  if (badFiles && badFiles.length > 0 && notify) {
    notify(`error, files exceed max size allowed: ${badFiles.toString()}`, {
      variant: 'error',
      anchorOrigin: {
        horizontal: 'center',
        vertical: 'top',
      },
    });
    return [];
  }
  return goodFiles;
};

export const parseSizeToBytes = fileSizeConfig => {
  const defaultMaxSize = 500000000;
  if (!fileSizeConfig) {
    return defaultMaxSize;
  }
  const fileSize = fileSizeConfig.match(/(\d*)([mM])/);
  return fileSize[1] * 1000000;
};
