import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  claimEventTemplates: [],
  driverRelationshipOptions: [],
  errors: null,
  selectedClaimEventTemplate: null,
};

export const anonymousReportClaimStepTwoSlice = createSlice({
  name: 'anonymousReportClaimStepTwoSlice',
  initialState,
  reducers: {
    resetStepTwoState: () => {
      return initialState;
    },
    setClaimEventTemplates: (state, action) => {
      return { ...state, claimEventTemplates: action.payload };
    },
    setDriverRelationshipOptions: (state, action) => {
      return { ...state, driverRelationshipOptions: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setSelectedClaimEventTemplate: (state, action) => {
      return { ...state, selectedClaimEventTemplate: action.payload };
    },
  },
});
export const {
  resetStepTwoState,
  setClaimEventTemplates,
  setDriverRelationshipOptions,
  setErrors,
  setSelectedClaimEventTemplate,
} = anonymousReportClaimStepTwoSlice.actions;
export default anonymousReportClaimStepTwoSlice.reducer;
