import styled from 'styled-components';

export const PolicyInfoFieldContainer = styled.div.attrs({
  className: 'PolicyInfoFieldContainer',
})`
  display: grid;
  grid-template-columns: 100%;
`;

export const PolicyInfoFieldHeader = styled.label.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-weight: 500;
  margin-top: 15px;
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const TextLink = styled.button`
  cursor: pointer;
  color: blue;
  background: none;
  box-shadow: none;
  padding: 0;
  text-decoration: underline;
  :hover {
    background: none;
  }
`;

export const TermsAndConditionsContainer = styled.div.attrs({})`
  margin-top: 15px;
`;
export const VerificationHeader = styled.h1`
  font-size: 1.225rem;
  font-weight: 400;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 16px 0;
`;
