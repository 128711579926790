import React from 'react';
import { HelpContactContainer, HelpDataContainer, HelpLabel } from './styles';
import PropTypes from 'prop-types';
import { NeedHelpContactRow } from './need-help-contact-row';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import { SelfServiceHours, SelfServiceHoursHeader, SelfServiceMessage } from './styles';
const PREFIX = 'Card';
const classes = {
  root: `${PREFIX}-root`,
};
const CardRoot = styled(Card)(() => ({
  [`&.${classes.root}`]: {
    margin: '15px 15px 15px 0px',
    padding: '30px',
    boxShadow: '0px 2px 4px 0px #28333F3D',
  },
}));
const NeedHelpRow = props => {
  const { label, selfServiceContact } = props;

  return (
    <CardRoot className={classes.root}>
      <HelpDataContainer>
        <HelpLabel>{label}</HelpLabel>
        {selfServiceContact
          .filter(message => {
            return message.id.includes('Message');
          })
          .map(message => {
            return <SelfServiceMessage key={message.id}>{message.value}</SelfServiceMessage>;
          })}
        <HelpContactContainer>
          {selfServiceContact
            .filter(contact => {
              return !contact.value.endsWith(' address');
            })
            .filter(contact => !contact.id.includes('Message'))
            .filter(contact => !contact.id.includes('Hours'))
            .map(contact => {
              return <NeedHelpContactRow contact={contact} key={contact.id} />;
            })}
        </HelpContactContainer>
        {selfServiceContact
          .filter(item => {
            return item.id.includes('Hours');
          })
          .map(item => {
            return (
              <SelfServiceHours key={item.id}>
                <SelfServiceHoursHeader>
                  {!item.id.includes('AdditionalHours') && item.name}
                </SelfServiceHoursHeader>
                {item.value}
              </SelfServiceHours>
            );
          })}
      </HelpDataContainer>
    </CardRoot>
  );
};

NeedHelpRow.propTypes = {
  selfServiceContact: PropTypes.array,
  label: PropTypes.any,
};

export default NeedHelpRow;
