export const setupPendo = (companyName, userId) => {
  // setup pendo only for prod envs
  if (process.env.ENVIRONMENT && process.env.ENVIRONMENT.toLowerCase().includes('prod')) {
    (function(apiKey) {
      (function(p, e, n, d, o) {
        var v, w, x, y, z;
        o = p[d] = p[d] || {};
        o._q = o._q || [];
        v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
        for (w = 0, x = v.length; w < x; ++w)
          (function(m) {
            o[m] =
              o[m] ||
              function() {
                o._q[m === v[0] ? 'unshift' : 'push']([m].concat([].slice.call(arguments, 0)));
              };
          })(v[w]);
        y = e.createElement(n);
        y.async = !0;
        y.src = 'https://cdn.pendo.io/agent/static/' + apiKey + '/pendo.js';
        z = e.getElementsByTagName(n)[0];
        z.parentNode.insertBefore(y, z);
      })(window, document, 'script', 'pendo');
    })('1c9931c7-84ab-4ab8-4d22-dd6ae60fc65c');

    // initialize pendo to track anonymous user
    const pendo = window.pendo;
    if (pendo) {
      pendo.initialize({
        visitor: {
          id: `${companyName.toLowerCase()}_${userId}`,
        },
        account: {
          id: companyName,
          name: 'Service Portal V4',
          environment: process.env.ENVIRONMENT,
          version: process.env.BUILD_VERSION,
        },
      });
    }
  }
};

export const updatePendoConfig = (companyName, userId) => {
  // update pendo only for prod envs
  if (process.env.ENVIRONMENT && process.env.ENVIRONMENT.toLowerCase().includes('prod')) {
    const pendo = window.pendo;
    if (pendo && userId) {
      // update pendo to track logged in user
      pendo.identify({
        visitor: {
          id: `${companyName.toLowerCase()}_${userId}`,
        },
        account: {
          id: companyName,
          name: 'Service Portal V4',
          environment: process.env.ENVIRONMENT,
          version: process.env.BUILD_VERSION,
        },
      });
    }
  }
};
