import React, { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { setPhoneErrors, setEmailErrors, setLoading } from './profileReducer';
import { deleteUser, getCustomerSubscriptions } from './profileAPI';
import ChangePassword from './changePassword';
import EnableMFAContainer from '../enable-mfa';
import Loader from '../../common/loader';
import {
  AccountDeleteInformation,
  CancelDeleteAccountButton,
  DeleteMyAccountButton,
  DeleteButtonContainer,
  HelpIcon,
  NameContainer,
  NameLabel,
  OnlineProfileHeader,
  ProfileContainer,
  ProfileEditorsContainer,
  StyledAccountHeader,
  StyledDeleteAccountModal,
  UserNameLabel,
  AccountEmailLabel,
  AccountEmailContainer,
  AccountEmailText,
} from './styles';
import PhoneDetails from './phoneDetails';
import EmailDetails from './emailDetails';
import TimezoneDetails from './timezoneDetails';
import NotificationDetails from './notificationDetails';
import { Tooltip } from '@mui/material';
import IconButton from '@mui/material/IconButton';

export const UserProfile = (props) => {
  const { oktaAuth } = useOktaAuth();
  const {
    dispatch,
    notificationErrors,
    isLoading,
    userId,
    deactivateUserOnly,
    mfaEnabled,
    userName,
    userEmail,
    notificationPreferences,
  } = props;

  React.useEffect(() => {
    dispatch(setEmailErrors(null));
    dispatch(setPhoneErrors(null));
    dispatch(setLoading(true));
    if (notificationPreferences) {
      dispatch(getCustomerSubscriptions(userId));
    } else {
      dispatch(setLoading(false));
    }
  }, []);
  const [modalOpen, setModalOpen] = useState(false);
  const handleModalClose = () => setModalOpen(false);
  const deleteUserAndRedirect = async (userId, deactivateUserOnly) => {
    await props.dispatch(deleteUser(userId, deactivateUserOnly));
    oktaAuth.signOut();
  };

  return (
    <>
      <Helmet>
        <title>User Profile</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {notificationErrors ? (
            <div>errors</div>
          ) : (
            <ProfileContainer>
              <OnlineProfileHeader>Your Online Profile</OnlineProfileHeader>
              <ProfileEditorsContainer notificationPreferences={notificationPreferences}>
                <div>
                  <NameContainer>
                    <NameLabel>
                      Username
                      <UserNameLabel>{userName}</UserNameLabel>
                    </NameLabel>
                  </NameContainer>
                  <AccountEmailContainer>
                    <AccountEmailLabel>
                      Registration Email
                      <Tooltip title="This email address is used for account registration and may be used to send information for authentication purposes.">
                        <IconButton size="small" component="span">
                          <HelpIcon />
                        </IconButton>
                      </Tooltip>
                      <AccountEmailText>{userEmail}</AccountEmailText>
                    </AccountEmailLabel>
                  </AccountEmailContainer>
                  {notificationPreferences && (
                    <>
                      <PhoneDetails isDashboard={false} ariaLabel="Mobile Phone Number" />
                      <EmailDetails isDashboard={false} />
                      <TimezoneDetails />
                      <NotificationDetails />
                    </>
                  )}
                </div>
                <ChangePassword notificationPreferences={notificationPreferences} />
              </ProfileEditorsContainer>
              {mfaEnabled && <EnableMFAContainer />}
              <DeleteMyAccountButton
                onClick={() => {
                  setModalOpen(true);
                }}
                disabled={isLoading}
              >
                Delete My Account
              </DeleteMyAccountButton>
              <Dialog open={modalOpen} onClose={handleModalClose}>
                <StyledDeleteAccountModal>
                  <StyledAccountHeader>Delete My Account</StyledAccountHeader>
                  <AccountDeleteInformation>
                    This action will delete your user account but will not cancel your policy.{' '}
                    <b>This action cannot be undone</b>, but you may create a new user account
                    later.
                  </AccountDeleteInformation>
                  <DeleteButtonContainer>
                    <CancelDeleteAccountButton
                      onClick={() => setModalOpen(false)}
                      className="cancel"
                    >
                      Cancel
                    </CancelDeleteAccountButton>
                    <DeleteMyAccountButton
                      onClick={() => {
                        handleModalClose();
                        deleteUserAndRedirect(userId, deactivateUserOnly);
                      }}
                      disabled={isLoading}
                    >
                      Delete My Account
                    </DeleteMyAccountButton>
                  </DeleteButtonContainer>
                </StyledDeleteAccountModal>
              </Dialog>
            </ProfileContainer>
          )}
        </>
      )}
    </>
  );
};

UserProfile.defaultProps = {
  state: {},
};

UserProfile.propTypes = {
  i18n: PropTypes.any,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  mfaEnabled: PropTypes.bool,
  userId: PropTypes.string,
  deactivateUserOnly: PropTypes.bool,
  notificationErrors: PropTypes.any,
  userName: PropTypes.string,
  userEmail: PropTypes.string,
  paperlessChoiceUpdateAllowed: PropTypes.bool,
  notificationPreferences: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  dispatch: PropTypes.func,
  deactivateUserOnly: state.configurationSlice.features.features.deactivateUserOnly,
  isLoading: state.profileSlice.isLoading,
  notificationErrors: state.profileSlice.notificationErrors,
  userId: state.loginSlice.user.preferred_username,
  mfaEnabled: state.configurationSlice.features.features.mfaEnabled,
  paperlessChoiceUpdateAllowed: state.configurationSlice.features.features.paperlessChoiceUpdate,
  userName: state.loginSlice.user.preferred_username,
  userEmail: state.loginSlice.user.email,
  notificationPreferences: state.configurationSlice.features.features.notificationPreferences,
});

export default connect(mapStateToProps)(UserProfile);
