import React from 'react';
import { Tooltip } from '@mui/material';
import PropTypes from 'prop-types';
import { HelpIconLarge, HelpIconMedium } from './styles';
import IconButton from '@mui/material/IconButton';

export const TooltipInfo = props => {
  return props.title && props.title.length > 0 ? (
    <Tooltip title={props.title}>
      <IconButton size={props.size === 'large' ? 'large' : 'small'} component="span">
        {props.size === 'large' ? <HelpIconLarge /> : <HelpIconMedium />}
      </IconButton>
    </Tooltip>
  ) : null;
};
TooltipInfo.propTypes = {
  title: PropTypes.string,
  size: PropTypes.string,
};
export default TooltipInfo;
