import {
  setBuildingRisks,
  setBuildingRisksLoading,
  setDriverRelationshipTemplate,
  setDrivers,
  setErrors,
  setLatestPolicyInfo,
  setLoading,
  setMortgageeInterestTypes,
  setPolicyChangePreview,
  setPolicyChangeSection,
  setPolicyChangeTemplates,
  setPolicyOptions,
  setPropertyMortgageeList,
  setPropertyMortgageeListLoading,
  setSelectedPolicyChangeTemplate,
  setShowChangeCoverageResults,
  setVehicleFinanceCompanyList,
  setVehicleInterestTypes,
  setVehicleModel,
  setVehicleRisks,
  setVehicleRisksLoading,
  setVinVerificationFailed,
} from './policyChangeReducer';
import { API, MULTI_PART_API } from '../../../utils/api';

export const getPolicyChangeTemplates = customerId => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get('/customerPolicyChangeTemplates?customerRef=' + customerId)
      .then(response => {
        const options = response.data.policyChangeTemplates.map(template => ({
          ...template,
          value: template.id,
          label: template.name,
        }));
        dispatch(setPolicyChangeTemplates(options));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getPolicyChangeTemplateById = (templateId, customerId) => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get(`/customerPolicyChangeTemplates/${templateId}?customerRef=${customerId}`)
      .then(response => {
        dispatch(setSelectedPolicyChangeTemplate(response.data));
        const options = response.data.policyDetails.map(policyDetail => ({
          value: policyDetail.policyRef,
          label: policyDetail.policyDescription,
        }));
        dispatch(setPolicyOptions(options));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const submitPolicyChange = payload => {
  return dispatch => {
    return API.post('/customerPolicyChanges', payload)
      .then(() => {
        dispatch(setShowChangeCoverageResults(true));
      })
      .catch(() => {
        dispatch(
          setErrors('Your request cannot be processed at this time. Please try again later.'),
        );
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const preSubmitPolicyChange = payload => {
  return dispatch => {
    return API.post('/customerPolicyChangePreSubmit', payload)
      .then(response => {
        dispatch(setPolicyChangePreview(response.data));
      })
      .catch(err => {
        dispatch(
          setErrors(
            err?.response?.data?.message
              ? ` Your request cannot be processed at this time. Please try again later. Additional details: ${err.response.data.message}`
              : 'Your request cannot be processed at this time. Please try again later.',
          ),
        );
      });
  };
};

export const getMortgageeInterestTypes = () => {
  return dispatch => {
    return API.get('/coderefs/UW/ai/interest-type/mortgagee')
      .then(response => {
        let options = response.data.options
          .map(option => {
            return {
              label: option.name,
              ...option,
            };
          })
          .filter(option => {
            return option.value !== '';
          });
        dispatch(setMortgageeInterestTypes(options));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getBuildingRisks = (policyId, lineCd) => {
  return dispatch => {
    dispatch(setBuildingRisksLoading(true));
    return API.get(`/policies/${policyId}/lines/${lineCd}/risks`)
      .then(response => {
        const buildingRisks = [];
        if (response.data.risks && response.data.risks.length > 0) {
          for (let i = 0; i < response.data.risks.length; i++) {
            const risk = response.data.risks[i];
            const addresses = risk.building.addresses;
            for (let j = 0; j < addresses.length; j++) {
              let address = addresses[j];
              if (address.addrTypeCd === 'RiskAddr') {
                buildingRisks.push(address);
              }
            }
          }
        }
        dispatch(setBuildingRisks(buildingRisks));
        dispatch(setBuildingRisksLoading(false));
      })
      .catch(err => {
        dispatch(setBuildingRisksLoading(false));
        dispatch(setErrors(err.message));
      });
  };
};

export const getAdditionalInterests = policyId => {
  return dispatch => {
    dispatch(setPropertyMortgageeListLoading(true));
    return API.get(`/policies/${policyId}/additionalInterests`)
      .then(response => {
        const propertyMortgageeList = [];
        if (response.data.ais && response.data.ais.length > 0) {
          for (let i = 0; i < response.data.ais.length; i++) {
            const ai = response.data.ais[i];
            const originalValue = {
              id: ai.id,
              interestTypeCd: ai.interestTypeCd,
              interestName: ai.interestName,
              accountNumber: ai.accountNumber,
              partyInfo: ai.partyInfo,
              escrowInd: ai.escrowInd,
            };
            let mortgagee = {
              ...originalValue,
              originalValue,
            };
            const addresses = ai.partyInfo.addresses;
            for (let j = 0; j < addresses.length; j++) {
              let address = addresses[j];
              if (address.addrTypeCd === 'AIMailingAddr') {
                mortgagee = { ...address, ...mortgagee };
              }
            }
            propertyMortgageeList.push(mortgagee);
          }
        }
        dispatch(setPropertyMortgageeList(propertyMortgageeList));
        dispatch(setPropertyMortgageeListLoading(false));
      })
      .catch(err => {
        dispatch(setPropertyMortgageeListLoading(false));
        dispatch(setErrors(err.message));
      });
  };
};

export const getVehicleRisks = policyId => {
  return dispatch => {
    dispatch(setVehicleRisksLoading(true));
    return API.get(`/policies/${policyId}/lines/PersonalAuto/risks`)
      .then(response => {
        const vehicleRisks = [];
        if (response.data.risks && response.data.risks.length > 0) {
          for (let i = 0; i < response.data.risks.length; i++) {
            const risk = response.data.risks[i];
            vehicleRisks.push({
              id: risk.vehicle.id,
              description: risk.description,
              model: risk.vehicle.model,
              modelYear: risk.vehicle.modelYr,
              vin: risk.vehicle.vehIdentificationNumber,
              make: risk.vehicle.manufacturer,
            });
          }
        }
        dispatch(setVehicleRisks(vehicleRisks));
        dispatch(setVehicleRisksLoading(false));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
        dispatch(setVehicleRisksLoading(false));
      });
  };
};

export const getVehicleInterestTypes = () => {
  return dispatch => {
    return API.get('/coderefs/UW/ai/interest-type/vehicle-finance-company')
      .then(response => {
        let options = response.data.options
          .map(option => {
            return {
              label: option.name,
              ...option,
            };
          })
          .filter(option => {
            return option.value !== '';
          });
        dispatch(setVehicleInterestTypes(options));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getAdditionalInterestsForVehicles = policyId => {
  return dispatch => {
    return API.get(`/policies/${policyId}/additionalInterests`)
      .then(response => {
        const financeCompanyList = [];
        if (response.data.ais && response.data.ais.length > 0) {
          for (let i = 0; i < response.data.ais.length; i++) {
            const ai = response.data.ais[i];
            let company = {
              id: ai.id,
              interestTypeCd: ai.interestTypeCd,
              interestName: ai.interestName,
              accountNumber: ai.accountNumber,
              partyInfo: ai.partyInfo,
            };
            financeCompanyList.push(company);
          }
        }
        dispatch(setVehicleFinanceCompanyList(financeCompanyList));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getLatestPolicyInfo = policyRef => {
  return dispatch => {
    return API.get(`/policies/${policyRef}/full`)
      .then(response => {
        let deductible = null;
        const policy = response.data;
        if (policy) {
          const lines = response.data.lines;
          for (let line of lines) {
            const risks = line.risks;
            for (let risk of risks) {
              if (risk.status === 'Active' && risk.building && risk.building.status === 'Active') {
                deductible = risk.building.allPerilDed;
              }
              if (deductible !== null) break;
            }
            if (deductible !== null) break;
          }
          dispatch(
            setLatestPolicyInfo({
              deductible,
              finalPremiumAmt: policy.basicPolicy.finalPremiumAmt,
            }),
          );
        }
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getVehiclesByVin = vin => {
  return dispatch => {
    dispatch(setVinVerificationFailed(false));
    return API.get(`/vehicleModels/?vin=${encodeURIComponent(vin)}`)
      .then(response => {
        if (response.data.vehicleModelListItems && response.data.vehicleModelListItems.length > 0) {
          dispatch(setVehicleModel(response.data.vehicleModelListItems[0].vehicleModel));
        } else {
          dispatch(setVinVerificationFailed(true));
        }
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getDrivers = policyId => {
  return dispatch => {
    return API.get(`/policies/${policyId}/drivers`)
      .then(response => dispatch(setDrivers(response.data.drivers)))
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const getDriverRelationshipTemplate = () => {
  return dispatch => {
    return API.get('coderefs/UW/underwriting/driver-relationship/all')
      .then(response => {
        let driverRelations = response.data.options;
        driverRelations = driverRelations
          .filter(relation => {
            const value = relation['value'] ? relation['value'].toLowerCase() : '';
            return !value.includes('self');
          })
          .map(relation => {
            relation['label'] = relation['name'];
            delete relation['name'];
            return relation;
          });
        dispatch(setDriverRelationshipTemplate(driverRelations));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};

export const uploadFilesAndSubmitPolicyChange = (files, payload) => {
  let fd = new FormData();
  files.map(file => fd.append(file.name, file));

  return async dispatch => {
    return MULTI_PART_API.post('multifileupload', fd)
      .then(response => {
        const attachments = [];
        const id = 'PropertyAttachment';
        const description = 'Pictures of the items';
        const templateId = 'CustomerAttachment0002';
        const requiredInd = false;
        // TODO we may need to read the response from response.data.files later
        response.data.map(data =>
          attachments.push({ id, description, templateId, requiredInd, fileName: data.name }),
        );
        const policyChangeSection = {
          id: 'PolicyChangeSection001',
          name: 'Item Photos',
          sectionCd: 'ItemPhotos',
          policyChangeAttachment: attachments,
        };

        // update payload
        payload.policyChangeSection = [policyChangeSection];

        // call submitPolicyChange
        dispatch(submitPolicyChange(payload));
      })
      .catch(() => {
        dispatch(
          setErrors('Your request cannot be processed at this time. Please try again later.'),
        );
      });
  };
};

export const uploadAddDriverFiles = files => {
  let fd = new FormData();
  files.map(file => fd.append(file.name, file));

  return async dispatch => {
    return MULTI_PART_API.post('multifileupload', fd)
      .then(response => {
        const attachments = [];
        const id = 'PropertyAttachment';
        const description = "Pictures of the driver's license";
        const templateId = 'PolicyAttachment0001';
        const requiredInd = false;
        // TODO we may need to read the response from response.data.files later
        response.data.map(data =>
          attachments.push({ id, description, templateId, requiredInd, fileName: data.name }),
        );
        const policyChangeSection = {
          id: 'PolicyChangeSection001',
          name: 'Item Photos/Driver Questions',
          sectionCd: 'ItemPhotos',
          policyChangeAttachment: attachments,
        };
        // update payload
        dispatch(setPolicyChangeSection([policyChangeSection]));
      })
      .catch(() => {
        dispatch(
          setErrors('Your request cannot be processed at this time. Please try again later.'),
        );
      });
  };
};
