import React, { useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { StepButton, StepLabel } from '@mui/material';
import Button from '@mui/material/Button';
import { ThemeContext } from 'styled-components';
import moment from 'moment';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';

// import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import Step1 from './step1';
import Step2 from './step2';
import Step3 from './step3';
import Step4 from './step4';
import {
  ButtonContainer,
  ReturnHomeButtonContainer,
  ContentContainer,
  LinkStyleButton,
  PaymentHeader,
  StyledContainer,
  StyledPrintIcon,
  AmountCard,
  AmountHeader,
  AmountText,
  CardContainer,
  InstructionsContainer,
  MakePaymentBackButton,
  NotificationContainer,
  PaidWithCard,
  PaidWithHeader,
  PaidWithType,
  PayDate,
  PayDateCard,
  PayDateHeader,
  PaymentContainer,
  StyledACHIcon,
  StyledButtonHome,
  StyledCreditIcon,
  StyledInfoIcon,
  StyledInformationMessage,
  StyledPrintButton,
  StyledSuccessHeader,
  StyledSuccessMessage,
  SuccessHeaderTop,
} from './styles';
import { LoggedOutTemplateLogo, LogoLink } from '../login/styles';
import {
  resetMakePaymentAnonymous,
  setActiveStep,
  setIsLoading,
  setNextButtonClicked,
  setNextButtonDisabled,
} from './makePaymentAnonymousReducer';
import { gaEvent } from '../../../utils/google-analytics';

import { getText } from '../../../utils/i18n';
import { Link } from 'react-router-dom';
import { CustomStepperIcons } from '../../common/stepper/utils';
import { LineDivider } from '../../common/line-divider';
import { ga4Event } from '../../../utils/google-analytics-4';
import Loader from '../../common/loader';

const getSteps = () => {
  return ['Verify Account', 'Choose Policy', 'Add Payment Details', 'Review/Submit'];
};

export const MakePaymentAnonymous = (props) => {
  const {
    confirmationNumber,
    dispatch,
    isLoading,
    nextButtonDisabled,
    activeStep,
    error,
    makePaymentStarted,
    response,
    i18n,
    billSelected,
    printButtonEnabled,
    companyName,
  } = props;

  const [completed, setCompleted] = React.useState({});
  let history = useHistory();
  useEffect(() => {
    gaEvent({ category: 'make-a-payment', action: 'start' });
    ga4Event({ category: 'make-a-payment', action: 'start' });
    let makePayAnonymousListener = history.listen((history) => {
      if (history.pathname === '/makePaymentAnonymous') {
        //path is fine, allow state to be tracked
      } else {
        // reset state for make payment anonymous.
        // and unlisten
        props.dispatch(resetMakePaymentAnonymous());
        makePayAnonymousListener();
      }
    });
  }, []);
  const theme = useContext(ThemeContext);

  const muiTheme = createTheme({
    components: {
      MuiStepIcon: {
        styleOverrides: {
          root: {
            '&.Mui-completed': {
              color: theme.primaryColor,
            },
            '&.Mui-active': {
              color: theme.primaryColor,
            },
          },
          active: {},
          completed: {},
        },
      },
      MuiButton: {
        styleOverrides: {
          root: {
            backgroundColor: theme.primaryColor,
            color: 'white',
            '&:hover': {
              backgroundColor: theme.primaryHoverColor,
              color: 'white',
            },
            '&.Mui-disabled': {
              backgroundColor: 'gray',
            },
          },
        },
      },
    },
  });

  const steps = getSteps();

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Step1 />;
      case 1:
        return <Step2 />;
      case 2:
        return <Step3 />;
      case 3:
        return <Step4 />;
      default:
        return <Step1 />;
    }
  };

  const handleNext = () => {
    dispatch(setIsLoading(true));
    dispatch(setNextButtonClicked(true));
    dispatch(setNextButtonDisabled(true));
  };

  const handleBack = () => {
    dispatch(setIsLoading(true));
    dispatch(setActiveStep(activeStep - 1));
  };

  const handleComplete = (index) => {
    const newCompleted = completed;
    newCompleted[index] = true;
    setCompleted(newCompleted);
  };

  useEffect(() => {
    if (response && activeStep === 3) {
      handleComplete(activeStep);
    } else {
      handleComplete(activeStep - 1);
    }
  }, [activeStep, response]);

  const handleStep = (step) => () => {
    dispatch(setActiveStep(step));
  };
  return (
    <>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      {response ? (
        <StyledContainer>
          <PaymentContainer>
            <SuccessHeaderTop>
              Payment Success: {billSelected?.billingAccount?.accountDisplayNumber}
            </SuccessHeaderTop>
            <LineDivider />
            {i18n?.makePaymentAnonymous?.paymentSuccess && (
              <InstructionsContainer
                dangerouslySetInnerHTML={{
                  __html: i18n.makePaymentAnonymous.paymentSuccess,
                }}
              />
            )}
            <NotificationContainer>
              <StyledInformationMessage>
                <StyledInfoIcon />
                <StyledSuccessHeader>
                  Payment Success:{' '}
                  <StyledSuccessMessage>
                    {getText(
                      i18n,
                      'payment.paymentSuccess',
                      `Your payment request has been received and will be processed. When completed, your payment will be posted to your policy on the date shown. We recommend that you keep a copy of this confirmation for your records. Click the button below to print a copy for your records.`,
                    )}{' '}
                    {confirmationNumber && (
                      <>
                        Your confirmation number is <b>{confirmationNumber}</b>
                      </>
                    )}
                  </StyledSuccessMessage>
                </StyledSuccessHeader>
              </StyledInformationMessage>
            </NotificationContainer>
            <CardContainer>
              <AmountCard>
                <AmountHeader>Amount Paid</AmountHeader>
                <AmountText>${parseFloat(response.payments[0].receiptAmt).toFixed(2)}</AmountText>
              </AmountCard>
              <PaidWithCard>
                <PaidWithHeader>Paid with</PaidWithHeader>
                <PaidWithType>
                  {response.paymentMethod === 'ACH' ? <StyledACHIcon /> : <StyledCreditIcon />}
                  {response.paymentMethod === 'ACH' ? 'Bank Account' : response.paymentMethod}
                </PaidWithType>
              </PaidWithCard>
              <PayDateCard>
                <PayDateHeader>Payment Date</PayDateHeader>
                <PayDate>{moment(response.scheduledDate).format(DISPLAY_DATE_FORMAT)}</PayDate>
              </PayDateCard>
            </CardContainer>
            <ReturnHomeButtonContainer>
              {printButtonEnabled && (
                <StyledPrintButton onClick={() => window.print()}>
                  <StyledPrintIcon />
                  Print a Receipt
                </StyledPrintButton>
              )}
              <Link to="/dashboard">
                <StyledButtonHome>Return Home</StyledButtonHome>
              </Link>
            </ReturnHomeButtonContainer>
          </PaymentContainer>
        </StyledContainer>
      ) : (
        <StyledContainer>
          <PaymentHeader id="paymentHeader">
            Make a Payment{' '}
            {billSelected?.billingAccount?.accountDisplayNumber &&
              `: ${billSelected.billingAccount.accountDisplayNumber}`}
          </PaymentHeader>
          <Stepper alternativeLabel activeStep={activeStep}>
            {steps.map((label, index) => {
              const stepProps = {};
              const buttonProps = {};

              return (
                <Step key={label} {...stepProps}>
                  <StepButton
                    onClick={handleStep(index)}
                    {...buttonProps}
                    completed={completed[index]?.toString()}
                  >
                    <StepLabel StepIconComponent={CustomStepperIcons}>{label}</StepLabel>
                  </StepButton>
                </Step>
              );
            })}
          </Stepper>
          <div>
            <ContentContainer>
              {getStepContent(activeStep)}
              {makePaymentStarted && isLoading ? <Loader /> : null}
              {!response && !error && !makePaymentStarted ? (
                <>
                  <LinkStyleButton id="returnHome" onClick={() => history.push('/dashboard')}>
                    Cancel
                  </LinkStyleButton>
                  <ButtonContainer>
                    <ThemeProvider theme={muiTheme}>
                      <Button
                        id={activeStep === 3 ? 'Finish' : 'Next'}
                        onClick={handleNext}
                        disabled={nextButtonDisabled}
                        style={
                          activeStep === 0
                            ? { float: 'right' }
                            : { marginLeft: '8px', float: 'right' }
                        }
                      >
                        {activeStep === 3 ? 'Pay Now' : 'Next'}
                      </Button>
                    </ThemeProvider>
                    {activeStep === 0 ? null : (
                      <MakePaymentBackButton onClick={handleBack}>Back</MakePaymentBackButton>
                    )}
                  </ButtonContainer>
                </>
              ) : null}
            </ContentContainer>
          </div>
        </StyledContainer>
      )}
    </>
  );
};

MakePaymentAnonymous.propTypes = {
  activeStep: PropTypes.number,
  dispatch: PropTypes.any,
  error: PropTypes.any,
  makePaymentStarted: PropTypes.bool,
  nextButtonDisabled: PropTypes.bool,
  registrationFields: PropTypes.array,
  registrationToken: PropTypes.string,
  termsOfServiceText: PropTypes.string,
  privacyStatementText: PropTypes.string,
  response: PropTypes.object,
  handleNext: PropTypes.func,
  fieldAnswers: PropTypes.array,
  i18n: PropTypes.object,
  billSelected: PropTypes.object,
  printButtonEnabled: PropTypes.bool,
  companyName: PropTypes.string,
  confirmationNumber: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  activeStep: state.makePaymentAnonymousSlice.activeStep,
  fieldAnswers: state.makePaymentAnonymousStepOneSlice.fieldAnswers,
  makePaymentStarted: state.makePaymentAnonymousSlice.makePaymentStarted,
  isLoading: state.makePaymentAnonymousSlice.isLoading,
  nextButtonDisabled: state.makePaymentAnonymousSlice.nextButtonDisabled,
  privacyStatementText: state.configurationSlice.features.privacyStatement,
  registrationFields: state.loginSlice.registrationFields,
  registrationToken: state.loginSlice.registrationToken,
  response: state.makePaymentAnonymousSlice.response,
  state,
  termsOfServiceText: state.configurationSlice.features.termsOfService,
  i18n: state.i18nSlice.i18n,
  billSelected: state.makePaymentAnonymousSlice.billSelected,
  printButtonEnabled: state.configurationSlice.features.features.printButtonEnabled,
  companyName: state.configurationSlice.features.companyName,
  confirmationNumber: null,
  error: state.makePaymentAnonymousSlice.error,
});
export default connect(mapStateToProps)(MakePaymentAnonymous);
