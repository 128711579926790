import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  payPlanOptions: [],
  newPlan: null,
  payPlanDateToPay: '1',
};

export const payPlanSlice = createSlice({
  name: 'payPlanSlice',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setPayPlanOptions: (state, action) => {
      return { ...state, payPlanOptions: action.payload };
    },
    setNewPlanSchedule: (state, action) => {
      return { ...state, newPlan: action.payload };
    },
    setDateToPay: (state, action) => {
      return { ...state, payPlanDateToPay: action.payload };
    },
  },
});
export const {
  resetState,
  setLoading,
  setFundingInstruments,
  setNewPlanSchedule,
  setPayPlanOptions,
  setDateToPay,
} = payPlanSlice.actions;
export default payPlanSlice.reducer;
