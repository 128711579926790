import { API } from '../../../utils/api/index';
import {
  setDetailsLoading,
  setErrors,
  setLoading,
  setPolicyDetails,
  setPolicyDocuments,
  setForms,
  setPolicyAttachments,
} from './policyDetailsReducer';

export const getPolicyDocuments = (policyId) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return API.get('policies/' + policyId + '/insuredDocuments')
      .then((response) => {
        dispatch(setPolicyDocuments(response.data.documentListItems));
      })
      .catch((err) => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getPolicyAttachments = (policyId, useInsuredAttachments) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    return API.get(
      `policies/${policyId}/${useInsuredAttachments ? 'insuredAttachments' : 'documents'}`,
    )
      .then((response) => {
        if (useInsuredAttachments) {
          dispatch(setPolicyAttachments(response.data.documentListItems));
        } else {
          const attachments =
            response.data.documentListItems && response.data.documentListItems.length > 0
              ? response.data.documentListItems.filter(
                  (documentListItem) => documentListItem.type === 'Attachment',
                )
              : [];
          dispatch(setPolicyAttachments(attachments));
        }
      })
      .catch((err) => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getPolicyDetails = (policyId, effectiveDt) => {
  return (dispatch) => {
    dispatch(setDetailsLoading(true));
    return API.get(`policies/${policyId}/insuredPolicyDetails?effectiveDt=${effectiveDt}`)
      .then((response) => {
        dispatch(setPolicyDetails(response.data));
      })
      .catch((err) => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setDetailsLoading(false)));
  };
};

export const getPolicyForms = (policyId) => {
  return (dispatch) => {
    dispatch(setDetailsLoading(true));
    return API.get('policies/' + policyId + '/full')
      .then((response) => {
        dispatch(setForms((response.data && response.data.forms) || []));
      })
      .catch((err) => {
        dispatch(setErrors(err.message));
      })
      .finally(() => dispatch(setDetailsLoading(false)));
  };
};
