export const features = {
    "template": "template2",
    "companyName": "Co-operative Insurance Companies (Prod)",
    "inowEndpoint": "https://coic.iscs.com",
    "tokenIssuer": "https://coic-inow.okta.com/oauth2/default",
    "clientId": "0oa4hrpa72n5ZMDa2697",
    "features": {
        "CustomContentPage1": false,
        "CustomContentPage2": false,
        "addRegistrationEmail": false,
        "agentEmail": false,
        "agentLink": false,
        "agentPanel": true,
        "blockAchNumberOps": true,
        "changeCoverage": false,
        "claimDetailsDocuments": false,
        "claimDetailsPayments": false,
        "claimDocumentUpload": false,
        "claimList": true,
        "claimStatus": true,
        "combinePolicies": true,
        "coverageDetails": true,
        "cpPortalLinking": false,
        "customPrivacyStatement": false,
        "customTOS": false,
        "deactivateUserOnly": false,
        "defaultLossDateTime": false,
        "editPayPlan": false,
        "hasOpenBalance": true,
        "loginPanelExpirationDate": "2999-01-01",
        "mfaEnabled": true,
        "needHelp": true,
        "notificationPreferences": false,
        "otherPaymentAmountAllowed": true,
        "paperlessChoiceUpdate": false,
        "passwordResetInfo": true,
        "payPlanTypesToFilter": [
            "Mortgagee",
            "Agent",
            "Statement"
        ],
        "pendingOrScheduledPaymentsList": true,
        "policyDocumentUpload": false,
        "policyForms": false,
        "policyFormsDownload": false,
        "policyUserAttachmentsDisplay": false,
        "printButtonEnabled": true,
        "recentPaymentsList": true,
        "rememberMe": true,
        "reportClaim": false,
        "scheduledPayments": false,
        "showImageTag": true,
        "standardPhoneLength": false,
        "unauthenticatedPayments": true,
        "unauthenticatedReportClaim": false,
        "useInsuredAttachments": false,
        "verifyAddress": true,
        "viewChangeRequests": false
    },
    "keys": {
        "googleAnalytics": null,
        "googleAnalytics4": "G-38J6S4R7H1",
        "googlePlacesApi": ""
    },
    "salesPortalUrl": "",
    "cpProducts": [
        "Homeowners",
        "Personal Auto"
    ],
    "paymentApiUrl": "https://midas.api.omega2-andromeda.guidewire.net/",
    "paymentTenantName": "tenant.coic",
    "paymentConfigIds": {
        "CIUI": "3104701"
    },
    "paymentMethods": [
        "ACH",
        "CC"
    ],
    "anonymousPaymentMethods": [
        "ACH",
        "CC"
    ],
    "achEntryClassCode": "WEB",
    "processingFee": 0,
    "maxUploadFileSize": "500mb",
    "attachmentTemplatesLimit": "20",
    "policiesCountLimit": "50",
    "billingAccountsCountLimit": "50",
    "faviconFileExtension": "ico",
    "policyCreatedSinceDt": 365,
    "policyStatusCd": "Active,Non-Renew Requested,Cancel Notice",
    "passwordConstraints": [
        {
            "constraint": "minLength",
            "value": 12,
            "message": "Use at least 12 characters"
        },
        {
            "constraint": "minNumber",
            "value": 1,
            "message": "Contain at least one number"
        },
        {
            "constraint": "minLowerCase",
            "value": 1,
            "message": "Contain at least one lower case letter"
        },
        {
            "constraint": "minUpperCase",
            "value": 1,
            "message": "Contain at least one upper case letter"
        }
    ],
    "userIdConstraints": [
        {
            "constraint": "minLength",
            "value": 3,
            "message": "Use at least 3 characters"
        }
    ],
    "downTime": {
        "beginTime": "2024-05-17T17:00-04:00",
        "endTime": "2024-05-20T8:30-04:00"
    },
    "notificationSmsNumber": "123-456-7890",
    "billingStatusCd": "Normal, Cancel, Legal",
    "_meta": {
        "build": "4.0.0-23-7b87ce8",
        "commit": "7b87ce88b2996daddec82602e014252ef980b1b9",
        "company": "coic",
        "date": "2025-01-27T20:04:40+00:00",
        "source": "serviceportal-four-config",
        "version": "4.0.0",
        "imageTag": ""
    }
};