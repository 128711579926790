const getBillingAddress = addresses => {
  const billingOrMailing = addresses.find(element => element.addrTypeCd === 'InsuredBillingAddr')
    ? addresses.find(element => element.addrTypeCd === 'InsuredBillingAddr')
    : addresses.find(element => element.addrTypeCd === 'InsuredMailingAddr')
    ? addresses.find(element => element.addrTypeCd === 'InsuredMailingAddr')
    : addresses.find(element => element.addrTypeCd === 'StatementAccountBillingAddr')
    ? addresses.find(element => element.addrTypeCd === 'StatementAccountBillingAddr')
    : addresses.find(element => element.addrTypeCd === 'StatementAccountMailingAddr');
  return billingOrMailing ? billingOrMailing : '';
};
export const getAddress = addresses => {
  const billingAddress = getBillingAddress(addresses);
  return billingAddress === '' ? '' : billingAddress.addr1;
};
export const getBillingCity = addresses => {
  const billingCity = getBillingAddress(addresses);
  return billingCity === '' ? '' : billingCity.city;
};
export const getBillingState = addresses => {
  const billingState = getBillingAddress(addresses);
  return billingState === '' ? '' : billingState.stateProvCd;
};
export const getBillingZip = addresses => {
  const billingZip = getBillingAddress(addresses);
  return billingZip === '' ? '' : billingZip.postalCode;
};

export const restructureCustomerForPayment = account => {
  return {
    indexName: `${account.partyInfo[0].nameInfo.givenName},${account.partyInfo[0].nameInfo.surname}`,
    partyInfo: [
      {
        nameInfo: {
          givenName: account.partyInfo[0].nameInfo.givenName,
          surname: account.partyInfo[0].nameInfo.surname,
        },
        addresses: [
          {
            addr1: getAddress(account.partyInfo[0].addresses),
            city: getBillingCity(account.partyInfo[0].addresses),
            stateProvCd: getBillingState(account.partyInfo[0].addresses),
            postalCode: getBillingZip(account.partyInfo[0].addresses),
          },
        ],
      },
    ],
  };
};
