import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const RadioChoice = styled.input.attrs({
  type: 'radio',
})`
  margin-left: 16px;
  margin-top: 16px;
  height: 24px;
  width: 24px;
`;
export const ReturnHomeButton = styled(Link).attrs({
  to: '/',
})`
  margin-top: 10px;
  padding: 8px 170px;
  width: 104.5%;
  text-decoration: none;
  border: ${(props) =>
    props.theme.primaryColor ? `1px solid ${props.theme.primaryColor}` : 'none'};
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const AmountContainer = styled.div`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    grid-template-columns: 32% 32%;
    column-gap: 24px;
  }
`;

export const AmountCards = styled.div.attrs({
  id: 'amountCards',
})`
  margin-bottom: 24px;
  padding-left: 24px;

  border-radius: 4px;
  background-color: #fafafa;
  @media (max-width: 464px) {
  }
  @media (min-width: 465px) and (max-width: 1129px) {
  }
  @media (min-width: 1130px) and (max-width: 1269px) {
  }
  @media (min-width: 1445px) {
    height: 200px;
  }
`;
export const AmountText = styled.div`
  font-size: 32px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #28333f;
  display: inline;
  margin-left: 25px;
`;
export const PartialAmountInput = styled.input.attrs({
  id: 'partialAmountInput',
  placeholder: '$0.00',
})`
  margin-left: 25px;
  width: 121px;
  height: 40px;
`;
export const AmountsContainer = styled.div`
  margin-top: 40px;
`;
export const AmountTitle = styled.label`
  font-size: 13px;
  font-weight: 550;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const PaymentContainer = styled.div`
  margin: 30px;
`;

export const SubHeader = styled.h2`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;
export const PolicyToPayContainer = styled.div`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    column-gap: 24px;
    margin-right: 24px;
  }
`;

export const PolicyCard = styled(AmountCards).attrs({
  id: 'policyCard',
})`
  display: grid;
  @media (max-width: 599px) {
    padding-left: 0;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 35% 65%;
    padding-bottom: 10px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-rows: 70% 30%;
    grid-template-columns: 100%;
    padding-bottom: 10px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
  }
`;

export const PolicyHeader = styled.div`
  font-size: 16px;
  color: #28333f;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  /* font-family: "Source Sans Pro"; */
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;
`;
export const PaymentCards = styled.div`
  margin-bottom: 24px;
  height: 56px;
  width: 290px;
  border-radius: 4px;
  background-color: #fafafa;
`;
export const CreditCard = styled(PaymentCards)`
  font-size: 16px;
`;

export const PolicySubHeaders = styled.div.attrs({})`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const CurrentDueAmount = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 32px;
  margin-top: 8px;
`;

export const CurrentDueContainer = styled.div.attrs({})`
  margin-left: 47px;
  margin-top: 8px;
`;

export const DatesAndAmountsContainer = styled.div.attrs({
  className: 'datesAndAmounts',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-left: 47px;
    margin-top: 8px;
    row-gap: 6px;
    margin-bottom: 6px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 33% 33% 33%;
    row-gap: 4px;
    align-self: end;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 25% 25% 25% 25%;
    align-self: end;
    margin-top: 10px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 36% 32% 32%;
  }
`;
export const PolicyAndCurrentDueContainer = styled.div.attrs({})`
  display: inline-block;
`;
export const PolicyValueHeader = styled.div.attrs({
  id: 'policyValueHeader',
})`
  font-weight: 600;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const PayoffAmountHeader = styled.div.attrs({
  id: 'payoffAmountHeader',
})`
  font-weight: 600;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const GridSpacingDesktopOne = styled.div.attrs({
  id: 'grid spacer',
})`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) {
  }
`;
export const GridSpacingDesktopTwo = styled.div.attrs({
  id: 'grid spacer 2',
})`
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    display: none;
  }
  @media (min-width: 1280px) {
  }
`;
export const NoPaymentDueText = styled.label`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 20px;
  margin-right: 20px;
`;
export const AmountDisclaimerContainer = styled.div.attrs({})`
  margin-bottom: 20px;
`;
export const AmountDisclaimerText = styled.label`
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 16px 0;
`;
