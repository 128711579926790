import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  AddVehicleButton,
  AddVehicleButtonsContainer,
  AddVehicleContainer,
  AddVehicleField,
  AddVehicleFieldHeader,
  AddVehicleTextAreaField,
  AddVehicleTextInputField,
  RequiredField,
  TwoColumnContainer,
  TwoColumnContainerTop,
} from './styles';
import moment from 'moment';
import {
  getLatestPolicyInfo,
  getVehiclesByVin,
  preSubmitPolicyChange,
  submitPolicyChange,
} from '../../policyChangeAPI';
import PropTypes from 'prop-types';
import { getAdditionalQuestions, getQuestionReplies } from '../selector';
import AdditionQuestionField from './additional-question-field';
import {
  setPolicyChangeCoverageRecapFields,
  setPolicyChangePreview,
  setPolicyChangePreviewData,
  setVehicleModel,
} from '../../policyChangeReducer';
import { FieldErrorMessage, Spacer, StyledLoaderContainer } from '../../styles';
import {
  createPolicyChangeCoverageSummary,
  getFieldsForVehicleWithVIN,
  YES_NO_DEFAULT,
} from './selector';
import PolicyChangePreview from '../policy-change-preview';
import Loader from '../../../../common/loader';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useHistory } from 'react-router-dom';

export const AddVehicle = props => {
  const {
    selectedPolicyChangeTemplate,
    vehicleModel,
    selectedPolicy,
    customerId,
    changeCoverageShowNewPremium,
    policyChangePreview,
    isVinVerificationFailed,
    i18n,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [vin, setVin] = useState('');
  const [additionalQuestions] = useState(getAdditionalQuestions(selectedPolicyChangeTemplate));
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [answers, setAnswers] = useState({});

  const setAdditionalQuestionsAnswers = (index, answer) => {
    const theAnswer = {};
    theAnswer[index] = { answer };
    setAnswers(prevAnswers => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const setAdditionalSubQuestionsAnswers = (parentIndex, index, answer) => {
    const subQuestionAnswers = [];
    subQuestionAnswers[index] = answer;

    const theAnswer = {};
    theAnswer[parentIndex] = {
      answer: answers[parentIndex].answer,
      subQuestionAnswers,
    };
    setAnswers(prevAnswers => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const checkAdditionalAnswersComplete = () => {
    let requiredAnswersCompleted = true;
    for (let i = 0; i < additionalQuestions.length; i++) {
      const answer = answers[i] !== undefined ? answers[i].answer : '';
      const question = additionalQuestions[i];
      if (question.dataType === 'YesNo' && question.requiredInd === 'Yes') {
        if (answer === undefined || answer.length === 0) {
          // default or yes if not set
          setAdditionalQuestionsAnswers(
            i,
            question.defaultValue !== undefined ? question.defaultValue : YES_NO_DEFAULT,
          );
        }
      } else if (question.dataType === 'List' && question.requiredInd === 'Yes') {
        requiredAnswersCompleted = answer !== undefined && answer.length !== 0;
        if (!requiredAnswersCompleted) {
          return false;
        }
      } else if (question.dataType === 'Numeric' && question.requiredInd === 'Yes') {
        requiredAnswersCompleted =
          requiredAnswersCompleted && answer !== undefined && answer.length > 0;
        if (!requiredAnswersCompleted) {
          return false;
        }
      } else if (question.dataType === 'TextArea' && question.requiredInd === 'Yes') {
        requiredAnswersCompleted =
          requiredAnswersCompleted && answer !== undefined && answer.length > 0;
        if (!requiredAnswersCompleted) {
          return false;
        }
      }

      // sub question answers check
      if (answers[i] && answers[i].subQuestionAnswers) {
        for (let j = 0; j < answers[i].subQuestionAnswers.length; j++) {
          const subAnswer = answers[i].subQuestionAnswers[j];
          const subQuestion = additionalQuestions[i].questions.question[j];
          if (subQuestion.dataType === 'YesNo' && subQuestion.requiredInd === 'Yes') {
            if (subAnswer === undefined || subAnswer.length === 0) {
              // default or yes if not set
              setAdditionalSubQuestionsAnswers(
                i,
                j,
                question.defaultValue !== undefined ? question.defaultValue : YES_NO_DEFAULT,
              );
            }
          } else if (subQuestion.dataType === 'List' && subQuestion.requiredInd === 'Yes') {
            requiredAnswersCompleted = subAnswer !== undefined && subAnswer.length !== 0;
            if (!requiredAnswersCompleted) {
              return false;
            }
          } else if (subQuestion.dataType === 'Numeric' && subQuestion.requiredInd === 'Yes') {
            requiredAnswersCompleted =
              requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
            if (!requiredAnswersCompleted) {
              return false;
            }
          } else if (subQuestion.dataType === 'TextArea' && subQuestion.requiredInd === 'Yes') {
            requiredAnswersCompleted =
              requiredAnswersCompleted && subAnswer !== undefined && subAnswer.length > 0;
            if (!requiredAnswersCompleted) {
              return false;
            }
          }
        }
      }
    }
    return !!requiredAnswersCompleted;
  };

  const getVehicles = vinNumber => {
    if (vehicleModel !== null) {
      props.dispatch(setVehicleModel(null));
    }
    if (vinNumber.length === 17) {
      props.dispatch(getVehiclesByVin(vinNumber));
    }
  };

  const checkRequiredFieldsCompleted = () => {
    // check effective date and reason
    let requiredFieldsCompleted = effectiveDate.length > 0 && reason.trim().length > 0;

    requiredFieldsCompleted = requiredFieldsCompleted && vehicleModel !== null;
    return !!requiredFieldsCompleted;
  };

  const createPayload = vehicleInformation => {
    return {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          vehicle: getFieldsForVehicleWithVIN(vehicleInformation, vin),
        },
      ],
      policyChangeSection: selectedPolicyChangeTemplate.policyChangeSections,
      questionReplies: [{ questionReply: getQuestionReplies(additionalQuestions, answers) }],
      sourceCd: 'ServicePortal',
    };
  };

  const onSubmitButtonClick = () => {
    // create payload
    const payload = createPayload(vehicleModel);

    // set fields for recap/result
    props.dispatch(
      setPolicyChangeCoverageRecapFields(
        createPolicyChangeCoverageSummary(
          effectiveDate,
          reason,
          selectedPolicyChangeTemplate.name,
          vehicleModel,
          additionalQuestions,
          answers,
        ),
      ),
    );

    setIsSubmitting(true);
    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  const onNextButtonClick = () => {
    window.scrollTo(0, 0);
    // create payload
    const payload = createPayload(vehicleModel);

    const fields = createPolicyChangeCoverageSummary(
      effectiveDate,
      reason,
      selectedPolicyChangeTemplate.name,
      vehicleModel,
      additionalQuestions,
      answers,
    );

    props.dispatch(
      setPolicyChangePreviewData({
        fields,
        payload,
        recapFields: createPolicyChangeCoverageSummary(
          effectiveDate,
          reason,
          selectedPolicyChangeTemplate.name,
          vehicleModel,
          additionalQuestions,
          answers,
        ),
      }),
    );

    setIsSubmitting(true);

    // get current policy deductible
    props.dispatch(getLatestPolicyInfo(selectedPolicy.value));

    // submit API call
    props.dispatch(preSubmitPolicyChange(payload));
  };

  useEffect(() => {
    if (policyChangePreview) {
      props.dispatch(setPolicyChangePreview(null));
    }
    if (vehicleModel) {
      props.dispatch(setVehicleModel(null));
    }
  }, []);

  useEffect(() => {
    setIsSubmitting(false);
  }, [policyChangePreview]);

  const history = useHistory();

  return (
    <>
      {policyChangePreview ? (
        <PolicyChangePreview />
      ) : (
        <AddVehicleContainer>
          <TwoColumnContainerTop>
            <AddVehicleField>
              <AddVehicleFieldHeader>
                Requested Effective Date <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'changeCoverage.addVehicle.effectiveDateTooltip')}
                />
              </AddVehicleFieldHeader>
              <input
                type="date"
                aria-label="Requested Effective Date"
                value={effectiveDate}
                min={todayDt}
                onChange={e => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </AddVehicleField>
            <AddVehicleField>
              <AddVehicleFieldHeader>
                Reason <RequiredField>*</RequiredField>
                <TooltipInfo title={getText(i18n, 'changeCoverage.addVehicle.reasonTooltip')} />
              </AddVehicleFieldHeader>
              <AddVehicleTextAreaField
                id="reason"
                value={reason}
                onChange={e => {
                  setReason(e.target.value);
                }}
              />
            </AddVehicleField>
          </TwoColumnContainerTop>
          <TwoColumnContainer>
            <AddVehicleField>
              <AddVehicleFieldHeader>
                VIN <RequiredField>*</RequiredField>
                <TooltipInfo title={getText(i18n, 'changeCoverage.addVehicle.vinTooltip')} />
              </AddVehicleFieldHeader>
              <AddVehicleTextInputField
                id="vin"
                value={vin}
                maxLength="17"
                onChange={e => {
                  // remove all non-alphanumeric characters
                  const refinedVin = e.target.value.replace(/[^a-zA-Z\d:]/, '');

                  setVin(refinedVin);
                  getVehicles(refinedVin);
                }}
              />
              {((vin.length > 1 && vin.length < 17) || isVinVerificationFailed) && (
                <FieldErrorMessage>The VIN provided could not be verified</FieldErrorMessage>
              )}
            </AddVehicleField>

            {vehicleModel && (
              <AddVehicleField>
                <AddVehicleFieldHeader>
                  Year
                  <TooltipInfo
                    title={getText(i18n, 'changeCoverage.addVehicle.vehicleYearTooltip')}
                  />
                </AddVehicleFieldHeader>
                <AddVehicleTextInputField id="year" disabled={true} value={vehicleModel.modelYr} />
              </AddVehicleField>
            )}
            {vehicleModel && (
              <AddVehicleField>
                <AddVehicleFieldHeader>
                  Make
                  <TooltipInfo
                    title={getText(i18n, 'changeCoverage.addVehicle.vehicleMakeTooltip')}
                  />
                </AddVehicleFieldHeader>
                <AddVehicleTextInputField
                  id="make"
                  disabled={true}
                  value={vehicleModel.manufacturer}
                />
              </AddVehicleField>
            )}
            {vehicleModel && (
              <AddVehicleField>
                <AddVehicleFieldHeader>
                  Model
                  <TooltipInfo
                    title={getText(i18n, 'changeCoverage.addVehicle.vehicleModelTooltip')}
                  />
                </AddVehicleFieldHeader>
                <AddVehicleTextInputField
                  id="model"
                  disabled={true}
                  value={`${vehicleModel.model} - Engine Size: ${vehicleModel.engineSize} - Cylinders: ${vehicleModel.engineCylinders}`}
                />
              </AddVehicleField>
            )}
            {additionalQuestions.map((q, index) => {
              return (
                <AdditionQuestionField
                  id={q.text}
                  key={index}
                  q={q}
                  index={index}
                  setAdditionalQuestionsAnswers={setAdditionalQuestionsAnswers}
                  setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
                />
              );
            })}
          </TwoColumnContainer>
          <AddVehicleButtonsContainer>
            <AddVehicleButton
              id="Cancel"
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Cancel
            </AddVehicleButton>
            {changeCoverageShowNewPremium ? (
              <>
                <AddVehicleButton
                  id="Next"
                  disabled={
                    !checkAdditionalAnswersComplete() ||
                    !checkRequiredFieldsCompleted() ||
                    isSubmitting
                  }
                  onClick={() => {
                    onNextButtonClick();
                  }}
                >
                  Next
                </AddVehicleButton>
                {isSubmitting ? (
                  <StyledLoaderContainer>
                    <Loader loaderheight="20px" loaderwidth="20px" />
                  </StyledLoaderContainer>
                ) : (
                  <Spacer />
                )}
              </>
            ) : (
              <>
                <AddVehicleButton
                  disabled={
                    !checkAdditionalAnswersComplete() ||
                    !checkRequiredFieldsCompleted() ||
                    isSubmitting
                  }
                  onClick={() => onSubmitButtonClick()}
                >
                  Submit
                </AddVehicleButton>
                {isSubmitting ? (
                  <StyledLoaderContainer>
                    <Loader loaderheight="20px" loaderwidth="20px" />
                  </StyledLoaderContainer>
                ) : (
                  <Spacer />
                )}
              </>
            )}
          </AddVehicleButtonsContainer>
        </AddVehicleContainer>
      )}
    </>
  );
};

AddVehicle.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicyChangeTemplate: PropTypes.object,
  vehicleManufacturerOptions: PropTypes.array,
  vehicleModelOptions: PropTypes.array,
  vehicleModel: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicy: PropTypes.object,
  changeCoverageShowNewPremium: PropTypes.bool,
  policyChangePreview: PropTypes.object,
  isVinVerificationFailed: PropTypes.bool,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  vehicleManufacturerOptions: state.policyChangeSlice.vehicleManufacturerOptions,
  vehicleModelOptions: state.policyChangeSlice.vehicleModelOptions,
  vehicleModel: state.policyChangeSlice.vehicleModel,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  customerId: state.customerSlice.customer.systemId,
  changeCoverageShowNewPremium:
    // state.configurationSlice.features.features.changeCoverageShowNewPremium,
    !!state.policyChangeSlice.selectedPolicyChangeTemplate.showPremChgInd,
  state,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  isVinVerificationFailed: state.policyChangeSlice.isVinVerificationFailed,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(AddVehicle);
