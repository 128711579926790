import moment from 'moment';
import { DISPLAY_DATE_FORMAT, SERVICE_PORTAL } from '../../../common/constants';
import { ga4Event } from '../../../../utils/google-analytics-4';

export const configurePaymentBody = (
  instrumentToPayWith,
  amountToPay,
  customerId,
  accountId,
  paymentServiceAccountId,
) => {
  let finalBody;
  if (instrumentToPayWith.sourceType === 'CreditCard') {
    finalBody = {
      scheduledDate: moment().format(DISPLAY_DATE_FORMAT),
      paymentMethod: 'Credit Card',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          electronicPaymentSource: {
            creditCardNumber: instrumentToPayWith.lastFour,
            customerPaymentProfileId: instrumentToPayWith.id.toString(),
            customerProfileId: `CUST-${customerId}`,
            paymentServiceAccountId: paymentServiceAccountId,
            sourceName: SERVICE_PORTAL,
          },
        },
      ],
    };
  } else {
    finalBody = {
      scheduledDate: moment().format(DISPLAY_DATE_FORMAT),
      paymentMethod: 'ACH',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          electronicPaymentSource: {
            achBankAccountNumber: instrumentToPayWith.achBankAccountNumber,
            achBankAccountTypeCd: instrumentToPayWith.achBankAccountTypeCd,
            achName: instrumentToPayWith.achName,
            achRoutingNumber: instrumentToPayWith.achRoutingNumber,
            achStandardEntryClassCd: instrumentToPayWith.achStandardEntryClassCd,
            sourceName: SERVICE_PORTAL,
          },
        },
      ],
    };
  }

  if (instrumentToPayWith.isTemporary) {
    // GA4 event for ACH-Account/CreditCard added
    ga4Event({
      category: 'make-a-payment',
      action:
        instrumentToPayWith.sourceType === 'CreditCard' ? 'credit_card_added' : 'ach_account_added',
      label: 'anonymous_payment',
    });
  }
  return finalBody;
};
