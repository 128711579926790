import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { EditEmailContainer, EmailAddressInput } from './styles';

const EditingEmail = props => {
  const { email, setEmail } = props;
  return (
    <>
      <EditEmailContainer>
        <>
          <EmailAddressInput>
            <input
              value={email}
              onChange={event => setEmail(event.target.value)}
              aria-label="Email Address"
            />
          </EmailAddressInput>
        </>
      </EditEmailContainer>
    </>
  );
};

EditingEmail.propTypes = {
  dispatch: PropTypes.func,
  emailAddress: PropTypes.string,
};
const mapStateToProps = state => ({
  emailAddress: state.profileSlice.emailAddress,
});
EditingEmail.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(EditingEmail);
