import styled from 'styled-components';

export const ChangeCoverageContainer = styled.div.attrs({
  id: 'changeCoverageContainer',
})`
  @media (max-width: 599px) {
    margin: 0;
  }
  margin: 30px;
`;

export const ChangeCoverageMessageComponent = styled.div.attrs({ id: 'lossMessageComponent' })`
  margin-bottom: 24px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;

export const ChangeCoverageHeader = styled.h3.attrs({
  id: 'changeCoverageHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangeCoverageLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
    margin-bottom: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
    margin-bottom: 2%;
  }
`;

export const PolicyToChangeLabel = styled(ChangeCoverageLabel).attrs({
  id: 'policyToChangeLabel',
})``;

export const ChangeCoverageFormContainer = styled.div.attrs({
  id: 'changeCoverageFormContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const ChangeCoveragePolicySelectorContainer = styled.div.attrs({
  id: 'ChangeCoveragePolicySelectorContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    width: 50%;
  }
  @media (min-width: 1280px) {
    width: 50%;
  }
`;

export const CoverageChangesRecapContainer = styled.div``;

export const CoverageRecapHeader = styled.h4.attrs({
  id: 'coverageRecapHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const CoverageRecapSubHeader = styled.div.attrs({ id: 'coverageRecapSubHeader' })`
  margin-bottom: 25px;
`;

export const ChangeCoverageRecapLabel = styled.h4.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
`;

export const ChangeCoverageRecapRow = styled.div.attrs({})`
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 30% 70%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 30% 70%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 25% 75%;
    grid-template-rows: 1fr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;

export const ChangeCoverageRecapTextArea = styled.textarea.attrs({
  id: 'ChangeCoverageRecap',
  readOnly: true,
})`
  height: 180px;
  width: 100%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ChangeCoverageRecapButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
`;
export const ChangeCoverageButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const FieldErrorMessage = styled.div`
  color: red;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const StyledLoaderContainer = styled.div.attrs({})`
  position: relative;
  display: inline-block;
  top: 4px;
  left: 4px;
`;
export const Spacer = styled.div.attrs({})`
  position: relative;
  display: inline-block;
  height: 20px;
  width: 20px;
`;
