import React from 'react';
import {
  PolicyInfoDateField,
  PolicyInfoZipFieldText,
  PolicyInfoFieldTextInputField,
} from '../../components/features/report-claim-anonymous/step1/styles';
import { DateIconSpan, IconSpan } from './styles';
import { StyledCheckIcon } from '../../components/features/user-profile/styles';
import { formatPhoneNumber, isEmail, isPhone } from '../validators/generalValidators';

const validateAnswers = (type, index, answer, setAnswers) => {
  let fieldComplete;
  switch (type) {
    case 'PolicyNumber':
      fieldComplete = answer && answer.length > 1;
      break;
    case 'DateOfBirth':
    case 'PolicyEffectiveDt':
    case 'PolicyExpirationDt':
      fieldComplete = answer && answer.length === 10;
      break;
    case 'Zip':
      fieldComplete = answer && answer.length >= 5;
      break;
    case 'PhoneNumber':
      fieldComplete = answer && isPhone(answer);
      break;
    case 'CustomerNumber':
      fieldComplete = answer && answer.length > 0;
      break;
    case 'Email':
      fieldComplete = answer && isEmail(answer);
      break;
    default:
      fieldComplete = answer && answer.length > 0;
  }
  const updateAnswer = {};
  updateAnswer[index] = { answer, fieldComplete };
  setAnswers((prevAnswer) => {
    return { ...prevAnswer, ...updateAnswer };
  });
};

export const getPolicyInfoInputField = (type, index, answers, setAnswers, featureName) => {
  let inputField;
  switch (type) {
    case 'PolicyNumber':
      inputField = (
        <div>
          <PolicyInfoFieldTextInputField
            id={type + index}
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
            placeholder="Enter your policy number here."
            width={
              featureName === 'CreateAccount'
                ? '48%'
                : featureName === 'MakePayAnon'
                ? '16%'
                : featureName === 'ReportClaimAnon'
                ? '16%'
                : '30%'
            }
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <IconSpan>
              <StyledCheckIcon id={type} />
            </IconSpan>
          )}
        </div>
      );
      break;
    case 'DateOfBirth':
      inputField = (
        <div>
          <PolicyInfoDateField
            id={type + index}
            type="date"
            aria-label="Date of Birth"
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            max="3000-01-01"
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <DateIconSpan>
              <StyledCheckIcon id={type} />
            </DateIconSpan>
          )}
        </div>
      );
      break;
    case 'PolicyEffectiveDt':
      inputField = (
        <div>
          <PolicyInfoDateField
            id={type + index}
            type="date"
            aria-label="Policy Effective Date"
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <DateIconSpan>
              <StyledCheckIcon id={type} />
            </DateIconSpan>
          )}
        </div>
      );
      break;
    case 'PolicyExpirationDt':
      inputField = (
        <div>
          <PolicyInfoDateField
            id={type + index}
            aria-label="Policy Expiration Date"
            type="date"
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <DateIconSpan>
              <StyledCheckIcon id={type} />
            </DateIconSpan>
          )}
        </div>
      );
      break;
    case 'Zip':
      inputField = (
        <div>
          <PolicyInfoZipFieldText
            maxLength="7"
            id={type + index}
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            placeholder="12345"
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
            width={
              featureName === 'CreateAccount'
                ? '20%'
                : featureName === 'MakePayAnon'
                ? '9%'
                : featureName === 'ReportClaimAnon'
                ? '9%'
                : '20%'
            }
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <IconSpan>
              <StyledCheckIcon id={type} />
            </IconSpan>
          )}
        </div>
      );
      break;
    case 'PhoneNumber':
      inputField = (
        <div>
          <PolicyInfoFieldTextInputField
            id={type + index}
            value={answers && answers[index] ? answers[index].answer : ''}
            onChange={(e) => {
              validateAnswers(type, index, formatPhoneNumber(e.target.value), setAnswers);
            }}
            placeholder="(555) 555-5555"
            width={
              featureName === 'CreateAccount'
                ? '30%'
                : featureName === 'MakePayAnon'
                ? '16%'
                : featureName === 'ReportClaimAnon'
                ? '16%'
                : '30%'
            }
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <IconSpan>
              <StyledCheckIcon id={type} />
            </IconSpan>
          )}
        </div>
      );
      break;
    case 'Email':
      inputField = (
        <div>
          <PolicyInfoFieldTextInputField
            id={type + index}
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <IconSpan>
              <StyledCheckIcon id={type} />
            </IconSpan>
          )}
        </div>
      );
      break;
    case 'CustomerNumber':
      inputField = (
        <div>
          <PolicyInfoFieldTextInputField
            id={type + index}
            defaultValue={answers && answers[index] ? answers[index].answer : ''}
            onChange={(e) => {
              validateAnswers(type, index, e.target.value, setAnswers);
            }}
          />{' '}
          {answers[index] && answers[index].fieldComplete && (
            <IconSpan>
              <StyledCheckIcon id={type} />
            </IconSpan>
          )}
        </div>
      );
      break;

    default:
      inputField = (
        <PolicyInfoFieldTextInputField
          id={type + index}
          type="text"
          onChange={(e) => {
            validateAnswers(type, index, e.target.value, setAnswers);
          }}
        />
      );
  }
  return inputField;
};
