import styled from 'styled-components';
import Checkbox from '@mui/material/Checkbox';
import CheckIcon from '@mui/icons-material/Check';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import PrintIcon from '@mui/icons-material/Print';
import InfoIcon from '@mui/icons-material/Info';

import Select from 'react-select';

import { Link } from 'react-router-dom';

export const DeleteButtonContainer = styled.div`
  text-align: right;
  margin-top: 16px;
`;

export const CancelDeleteAccountButton = styled.button.attrs({
  id: 'cancelAccountDeleteButton',
})`
  padding: 8px 10px 10px 10px;
  margin: 0px 8px 0px 0px;
`;

export const AccountDeleteInformation = styled.div.attrs({
  id: 'accountDeletionInformation',
})`
  margin: 46px 0 0 16px;
`;

export const StyledAccountHeader = styled.h4.attrs({
  id: 'accountDeleteHeader',
})`
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  font-family: proxima-nova, sans-serif;
`;

export const StyledDeleteAccountModal = styled.div.attrs({
  id: 'accountModalContainer',
})`
  padding: 16px;
`;

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;

export const ProfileContainer = styled.div.attrs({
  id: 'ProfileContainer',
})`
  padding: 30px 30px 0 30px;
`;

export const ProfileError = styled.div.attrs({
  'data-test': 'error-field',
})`
  color: #cc3300;
  margin-top: 8px;
`;
ProfileError.displayName = 'ProfileError';

export const EmailError = styled.div.attrs({
  'data-test': 'error-field',
})`
  color: #cc3300;
  margin-top: 8px;
  max-width: 250px;
`;
EmailError.displayName = 'EmailError';

export const ProfileDetailsContainer = styled.div.attrs({
  id: 'ProfileDetailsContainer',
})`
  margin-top: 16px;
  margin-bottom: 16px;
`;

export const OuterProfileContainer = styled.div.attrs({
  id: 'OuterProfileContainer',
})`
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
  }
`;

export const GoPaperlessContainer = styled.div.attrs({
  id: 'GoPaperlessContainer',
})`
  @media (max-width: 739px) {
    border-bottom: none;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-right: 5%;
    margin-top: 2%;
  }
`;

export const ChangePasswordContainer = styled.div.attrs({
  id: 'ChangePasswordContainer',
})`
  @media (max-width: 599px) {
    margin: 30px 0 0 0;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  margin-left: ${(props) => (props.notificationPreferences ? '30px' : '0px')};
  margin-right: 30px;
`;

export const ProfileHeader = styled.div.attrs({})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.1rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;

export const OnlineProfileHeader = styled.h1.attrs({
  id: 'OnlineProfileHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-block: 1em;
`;

export const ChangePasswordHeader = styled(ProfileHeader).attrs({
  id: 'ChangePasswordHeader',
})`
  font-weight: ${(props) => (props.notificationPreferences ? 'normal' : 'bold')};
`;

export const GoPaperlessHeader = styled.div.attrs({
  id: 'GoPaperlessHeader',
})`
  font-size: 1rem;
  font-weight: 700;
`;

export const PhoneEditContainer = styled.div.attrs({
  id: 'phoneEditContainer',
})`
  display: grid;
  margin-top: 16px;
  @media (max-width: 599px) {
    row-gap: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    row-gap: 16px;
    grid-template-columns: 80%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 80%;
    row-gap: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${(props) => (props.isDashboard ? '35%' : '60%')};
    row-gap: 16px;
  }
`;

export const EditPhoneButtonContainer = styled.div.attrs({
  id: 'editPhoneButtonContainer',
})`
  @media (min-width: 450px) and (max-width: 599px) {
    align-self: end;
  }
`;

export const EditEmailButtonContainer = styled.div.attrs({
  id: 'editEmailButtonContainer',
})`
  @media (min-width: 450px) and (max-width: 599px) {
    align-self: end;
  }
`;

export const EditNotificationContainer = styled.div.attrs({
  id: 'editNotificationContainer',
})`
  @media (min-width: 450px) and (max-width: 599px) {
    align-self: end;
  }
`;

export const EmailContainer = styled.div.attrs({
  id: 'emailContainer',
})`
  padding-top: 5px;
  display: grid;
  @media (max-width: 450px) {
    margin-top: 16px;
  }
  @media (min-width: 450px) and (max-width: 599px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '30% 40% 30%' : '80% 20%')};
    display: grid;
    column-gap: ${(props) => (props.$isDashboard ? '25px' : '35px')};
    margin-top: ${(props) => (props.$isDashboard ? '32px' : '32px')};
    padding-top: ${(props) => (props.$isDashboard ? '0' : '0')};
  }
  @media (min-width: 600px) and (max-width: 959px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '1fr 1fr 2fr' : '6fr 3fr 2fr')};
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '2fr 3fr 5fr' : '6fr 3fr 2fr')};
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '15% 25% 50%' : '55.4% 44.6%')};
  }
`;

export const NotificationContainer = styled.div.attrs({
  id: 'notificationContainer',
})`
  padding-top: 24px;
  display: grid;
  @media (min-width: 450px) and (max-width: 599px) {
    grid-template-columns: 80% 20%;
    column-gap: 35px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 6fr 3fr 2fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 6fr 3fr 2fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 55.4% 44.6%;
  }
`;

export const EditEmailContainer = styled.div.attrs({
  id: 'editEmailContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    row-gap: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    row-gap: 16px;
    grid-template-columns: 2fr 2fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 2fr;
    row-gap: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 4fr 3fr;
    row-gap: 16px;
  }
`;

export const ProfileLabel = styled.h2.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  padding-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const ProfileInformationContainer = styled.div.attrs({})`
  margin-bottom: 16px;
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 0;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 1fr 3fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 4fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 3fr 6fr;
    margin-bottom: 8px;
  }
`;

export const PolicyLabelDesktop = styled(ProfileLabel).attrs({
  id: 'policyLabelDesktop',
})`
  @media (max-width: 599px) {
    display: none;
  }
`;
export const PolicyLabelMobile = styled(ProfileLabel).attrs({
  id: 'policyLabelMobile',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const PolicyNumberLabel = styled.div.attrs({
  id: 'policyNumberLabel',
})`
  font-size: 1rem;
  font-weight: 700;
`;

export const PreferenceLabelDesktop = styled(ProfileLabel).attrs({
  id: 'preferenceLabelDesktop',
})`
  @media (max-width: 599px) {
    display: none;
  }
`;
export const PreferenceLabelMobile = styled(ProfileLabel).attrs({
  id: 'preferenceLabelMobile',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const NameLabel = styled(ProfileLabel).attrs({
  id: 'nameLabel',
})``;

export const NameContainer = styled(ProfileInformationContainer).attrs({
  id: 'nameContainer',
})`
  @media (min-width: 1280px) {
    grid-template-columns: 15% 25% 50%;
  }
`;

export const AccountEmailLabel = styled(ProfileLabel).attrs({
  id: 'accountEmailLabel',
})``;

export const AccountEmailContainer = styled(ProfileInformationContainer).attrs({
  id: 'accountEmailContainer',
})``;

export const MailingAddressContainer = styled(ProfileInformationContainer).attrs({
  id: 'mailingAddressContainer',
})`
  @media (max-width: 599px) {
    margin-top: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 15% 25% 50%;
  }
`;

export const PhoneNumberContainer = styled(ProfileInformationContainer).attrs({
  id: 'phoneNumberContainer',
})`
  display: grid;
  row-gap: 6px;
  @media (max-width: 450px) {
    margin-top: 16px;
  }
  @media (min-width: 450px) and (max-width: 599px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '30% 40% 30%' : '80% 20%')};
    display: grid;
    column-gap: ${(props) => (props.$isDashboard ? '25px' : '35px')};
    margin-top: ${(props) => (props.$isDashboard ? '32px' : null)};
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '1fr 1fr 2fr' : '6fr 3fr 2fr')};
    row-gap: 20px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '2fr 3fr 5fr' : '6fr 3fr 2fr')};
    row-gap: 20px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${(props) => (props.$isDashboard ? '15% 25% 50%;' : '55.4% 44.6%')};
    margin-bottom: 12px;
  }
`;

export const MailingAddressLabel = styled(ProfileLabel).attrs({
  id: 'mailingAddressLabel',
})``;

export const PhoneNumbersLabel = styled.div.attrs({
  id: 'phoneNumbersLabel',
})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 599px) {
    margin-top: ${(props) => (props.$isDashboard ? null : '16px')};
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const EmailAddressLabel = styled(ProfileLabel).attrs({
  id: 'emailAddressLabel',
})`
  font-weight: 700;
  @media (max-width: 599px) {
    margin-top: 0;
    padding-bottom: 0;
  }
`;

export const NotificationAddressLabel = styled(ProfileLabel).attrs({
  id: 'notificationAddressLabel',
})`
  @media (min-width: 450px) and (max-width: 599px) {
    padding-bottom: 0;
  }
  @media (max-width: 599px) {
    margin-top: 16px;
  }
`;

export const CurrentPasswordLabel = styled(ProfileLabel).attrs({
  id: 'currentPasswordLabel',
})`
  margin-top: 20px;
`;

export const NewPasswordLabel = styled(ProfileLabel).attrs({
  id: 'newPasswordLabel',
})`
  margin-top: 20px;
`;

export const ConfirmPasswordLabel = styled(ProfileLabel).attrs({
  id: 'confirmPasswordLabel',
})`
  margin-top: 20px;
`;

export const ProfileDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const PolicyMailingAddress = styled(ProfileDataDiv).attrs({
  id: 'policyMailingAddress',
})`
  @media (min-width: 600px) {
  }
  margin-bottom: 18px;
`;

export const PolicyEmailAddress = styled(ProfileDataDiv).attrs({
  id: 'policyEmailAddress',
})`
  @media (min-width: 600px) {
  }
  margin-bottom: 10px;
`;

export const PolicySummary = styled(ProfileDataDiv).attrs({
  id: 'policySummary',
})`
  @media (min-width: 600px) {
  }
  padding-bottom: 10px;
`;

export const ProfileMailingAddress = styled(ProfileDataDiv).attrs({
  id: 'profileMailingAddress',
})`
  word-break: break-word;
  @media (min-width: 600px) {
  }
`;

export const ProfileEmailsAddress = styled(ProfileDataDiv).attrs({
  id: 'profileEmailsAddress',
})`
  word-break: break-word;
  font-weight: 400;
  margin-top: ${(props) => (props.$isDashboard ? null : '8px')};
  @media (min-width: 450px) and (max-width: 599px) {
    align-self: end;
  }
  @media (min-width: 600px) {
  }
`;

export const TimezoneValue = styled(ProfileDataDiv).attrs({
  id: 'timezoneValue',
})`
  word-break: break-word;
  font-weight: 400;
  margin-top: ${(props) => (props.$isDashboard ? null : '8px')};
  @media (min-width: 450px) and (max-width: 599px) {
    align-self: end;
  }
  @media (min-width: 600px) {
  }
`;
export const NotificationType = styled(ProfileDataDiv).attrs({
  id: 'notificationType',
})`
  font-weight: 400;
  margin-top: 8px;
`;

export const PdfIcon = styled(PictureAsPdfIcon).attrs({
  className: 'fa fa-file-pdf-o',
})`
  margin: 0 1% 0 0%;
`;

PdfIcon.displayName = 'PdfIcon';

export const FileIcon = styled(FileCopyIcon).attrs({
  className: 'fa fa-files-o',
})`
  margin: 0 1% 0 0%;
`;
FileIcon.displayName = 'FileIcon';

export const StyledPrintIcon = styled(PrintIcon).attrs({
  className: 'fa fa-print',
})`
  margin: 0 1% 0 0%;
`;
PrintIcon.displayName = 'PrintIcon';

export const InsetPanel = styled.div.attrs({
  className: 'inset panel-bg table-responsive p',
})``;
InsetPanel.displayName = 'InsetPanel';

export const ChangePasswordButton = styled.button.attrs({
  id: 'ChangePasswordButton',
})`
  background-color: ${(props) => props.theme.primaryColor};
  box-shadow: none;
  border-radius: 0;
  font-size: 14px;
  color: white;
  padding: 10px;
  margin: 5px 5px 5px 0;
  :hover {
    background-color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${(props) => props.theme.primaryActiveColor};
  }
  :disabled {
    background-color: gray;
  }
`;

export const SaveEmailButton = styled.button.attrs({
  id: 'SaveEmailButton',
})`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 7px 24px;
  margin-right: 20px;
  margin-top: 16px;
  :hover {
    background-color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${(props) => props.theme.primaryActiveColor};
  }
`;

export const SaveTimezoneButton = styled.button.attrs({
  id: 'SaveTimezoneButton',
})`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 7px 24px;
  margin-right: 20px;
  margin-top: 16px;
  :hover {
    background-color: ${(props) =>
      props.theme.primaryHoverColor
        ? props.theme.primaryHoverColor
        : props.theme.primaryColor
        ? props.theme.primaryColor
        : 'gray'};
  }
  :active {
    background-color: ${(props) => props.theme.primaryActiveColor};
  }
`;

export const SavePhonesButton = styled.button.attrs({
  id: 'SavePhonesButton',
})`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 7px 24px;
  margin-top: 16px;
  margin-right: 20px;
  margin-bottom: 20px;
`;

export const EditPhonesLink = styled.a.attrs({
  type: 'button',
  id: 'editPhonesLink',
  to: '#',
})`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: blue;
  padding-left: 0;
  line-height: 1.2;
  text-align: left;
  text-decoration: none;
  -webkit-appearance: initial;
  margin-top: ${(props) => (props.$isDashboard ? null : '26px')};
  :hover {
    color: #00009c;
  }
`;

export const EditEmailAddressLink = styled.a.attrs({
  type: 'button',
  id: 'editEmailAddressLink',
  to: '#',
})`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: blue;
  padding-left: 0;
  line-height: 1.2;
  text-align: left;
  text-decoration: none;
  -webkit-appearance: initial;
  margin-top: ${(props) => (props.$isDashboard ? null : '22px')};
  :hover {
    color: #00009c;
  }
`;

export const EditNotificationPreferencesLink = styled.button.attrs({
  id: 'editNotificationPreferencesLink',
})`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: blue;
  padding-left: 0;
  line-height: 1.2;
  text-align: left;
  text-decoration: none;
  box-shadow: none;
  :hover {
    color: #00009c;
    background-color: transparent;
  }
  :disabled {
    color: rgba(0, 115, 157, 0.4);
    background-color: transparent;
    border: none;
    box-shadow: none;
    pointer-events: none;
  }
`;

export const EmailAddressInput = styled.div.attrs({
  id: 'emailAddressInput',
})`
  float: right;
  display: flex;
  input {
    display: block;
    padding: 5px;
    margin-top: 8px;
    font-weight: normal;
  }
`;

export const TimezoneInputContainer = styled.div.attrs({
  id: 'timezoneInputContainer',
})`
  /* float: right;
  display: flex;
  input {
    display: block;
    padding: 5px;
    margin-top: 8px;
    font-weight: normal;
  } */
  @media (min-width: 1280px) {
    width: 65%;
  }
`;

export const CurrentPasswordInput = styled.div.attrs({
  id: 'currentPasswordInput',
})`
  input {
    display: block;
    padding: 5px;
    width: 80%;
    font-weight: normal;
  }
`;

export const NewPasswordInput = styled.div.attrs({
  id: 'newPasswordInput',
})`
  input {
    display: block;
    padding: 5px;
    width: 80%;
    font-weight: normal;
  }
`;

export const ConfirmPasswordInput = styled.div.attrs({
  id: 'confirmPasswordInput',
})`
  input {
    display: block;
    padding: 5px;
    margin-bottom: 8px;
    @supports (-moz-appearance: none) {
      margin-bottom: 25px;
    }
    width: 80%;
    font-weight: normal;
  }
`;

export const PrimaryPhoneNumberInput = styled.div.attrs({
  id: 'primaryPhoneNumberInput',
})`
  input {
    display: block;
    padding: 7px;
    margin-top: 1px;
    width: 75%;
    font-weight: normal;
    @media (max-width: 380px) {
      width: ${(props) => (props.isDashboard ? '89%' : '86%')};
    }
    @media (min-width: 381px) and (max-width: 599px) {
      width: ${(props) => (props.isDashboard ? '90%' : '88%')};
    }
    @media (min-width: 600px) and (max-width: 900px) {
      width: ${(props) => (props.isDashboard ? '93%' : '82%')};
    }
  }
`;

export const SecondaryPhoneNumberInput = styled.div.attrs({
  id: 'secondaryPhoneNumberInput',
})`
  input {
    display: block;
    padding: 7px;
    margin-top: 1px;
    width: 75%;
    font-weight: normal;
    @media (max-width: 380px) {
      width: ${(props) => (props.isDashboard ? '89%' : '86%')};
    }
    @media (min-width: 381px) and (max-width: 599px) {
      width: ${(props) => (props.isDashboard ? '81%' : '88%')};
    }
    @media (min-width: 600px) and (max-width: 900px) {
      width: ${(props) => (props.isDashboard ? '83%' : '82%')};
    }
  }
`;

export const PhoneSelect = styled(Select).attrs({
  id: 'phoneSelect',
  'aria-label': 'Primary Phone Type',
})`
  /* margin: 0 27px 0 0; */
  /* height: 35px; */
  width: ${(props) => (props.isDashboard ? '85%' : '91%')};
  @media (max-width: 380px) {
    width: ${(props) => (props.isDashboard ? '92%' : '90%')};
  }
  @media (min-width: 381px) and (max-width: 599px) {
    width: ${(props) => (props.isDashboard ? '93%' : '93%')};
  }
  @media (min-width: 600px) and (max-width: 900px) {
    width: ${(props) => (props.isDashboard ? '93%' : '95%')};
  }
`;
export const SecondaryPhoneSelect = styled(PhoneSelect).attrs({
  id: 'secondaryPhoneSelect',
  'aria-label': 'Secondary Phone Type',
})`
  @media (max-width: 380px) {
    width: ${(props) => (props.isDashboard ? '92%' : '90%')};
  }
  @media (min-width: 381px) and (max-width: 599px) {
    width: ${(props) => (props.isDashboard ? '83%' : '93%')};
  }
  @media (min-width: 600px) and (max-width: 900px) {
    width: ${(props) => (props.isDashboard ? '84%' : '95%')};
  }
  width: ${(props) => (props.isDashboard ? '85%' : '91%')};
`;

export const CancelButton = styled(Link).attrs({
  id: 'combinePoliciesCancelButton',
  to: '#',
})`
  background-color: transparent;
  text-align: left;
  font-size: 14px;
  text-decoration: none;
  padding: 7px 0;
  color: blue;
  -webkit-appearance: initial;
`;

export const ProfileEditorsContainer = styled.div.attrs({
  id: 'profileEditorsContainer',
})`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: ${(props) => (props.notificationPreferences ? '1fr 1fr' : '1fr')};
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${(props) => (props.notificationPreferences ? '1fr 1fr' : '1fr')};
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${(props) => (props.notificationPreferences ? '1fr 1fr' : '1fr')};
  }
`;

export const ProfileRadioButtonContainer = styled.div.attrs({
  id: 'profileRadioButtonContainer',
})`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 1fr 1fr;
    max-width: 250px;
    min-width: 200px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr;
    max-width: 250px;
    min-width: 200px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 1fr 1fr;
    max-width: 250px;
    min-width: 200px;
  }
`;

export const DeleteMyAccountButton = styled.button.attrs({
  id: 'DeleteMyAccountButton',
})`
  background-color: #af0000;
  color: white;
  padding: 10px;
  margin: 25px 5px 25px 0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 1px rgb(40 51 63 / 24%);
  :hover {
    background-color: #d90000;
  }
  :active {
    background-color: #ba0000;
  }
`;
export const PasswordInputField = styled.input.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
  type: 'password',
}))`
  width: -webkit-fill-available;
`;
export const PasswordConstraintMessageLabel = styled.div.attrs((props) => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-size: 12px;
  margin-top: 5px;
`;
export const StyledCheckIcon = styled(CheckIcon).attrs((props) => ({
  id: props.id + 'CheckIcon',
  className: 'fa fa-check',
}))`
  color: ${(props) => props.theme.primaryColor};
`;
export const PaperLessHeaderContainer = styled.div`
  display: grid;
  margin-top: 3%;
  border-bottom: 2px solid #444;
  @media (max-width: 739px) {
    border-bottom: none;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
    margin-left: 0;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 4fr 1fr 1fr;
    grid-template-rows: 4fr 1fr 1fr;
  }
`;

export const Separator = styled.div.attrs({ id: 'separator' })`
  height: 5px;
  width: 100%;
  background-color: #f1f1f1;
`;

export const RequiredField = styled.span.attrs({})`
  margin: 5px 10px;
  color: red;
`;
export const PhoneRowContainer = styled.div.attrs({
  id: 'phoneRowContainer',
})`
  display: grid;
  @media (max-width: 379px) {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
  @media (min-width: 380px) and (max-width: 979px) {
    row-gap: ${(props) => (props.isDashboard ? '16px' : '16px')};
    grid-template-columns: ${(props) => (props.isDashboard ? '80%' : '90%')};
  }
  @media (min-width: 980px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr 1%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${(props) => (props.isDashboard ? '1fr 1fr 1%' : null)};
  }
`;
export const PrimaryPhoneRequiredField = styled.span.attrs({})`
  color: red;
  margin-left: 12px;
  @media (max-width: 599px) {
    margin-left: 10px;
  }
  @media (min-width: 780px) and (max-width: 900px) {
    margin-left: 26px;
  }
`;
export const PhoneSelectorContainer = styled.div.attrs({})`
  display: flex;
`;
export const EditNotificationsContainer = styled.div`
  display: grid;

  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
  }
`;
export const NotificationCheckbox = styled(Checkbox).attrs({
  type: 'checkbox',
})``;

export const SMSLabel = styled.h3.attrs({
  id: 'SMSLabel',
})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  @media (max-width: 599px) {
    /* margin-top: 16px; */
  }
  @media (min-width: 600px) and (max-width: 979px) {
    /* max-width: 90%; */
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    /* max-width: 90%; */
  }
  @media (min-width: 1280px) {
    /* max-width: 95%; */
  }
`;
export const EmailLabel = styled(SMSLabel).attrs({
  id: 'EmailLabel',
})``;
export const CheckboxContainer = styled.div`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 16px;
`;
export const ConsentCheckbox = styled(Checkbox).attrs({
  type: 'checkbox',
})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 24px;
`;
export const ConsentContainer = styled.div`
  max-width: 530px;
  margin-top: 30px;
`;
export const ButtonContainer = styled.div`
  margin-top: 24px;
`;
export const ConsentDescription = styled.span`
  margin-left: 8px;
`;
export const NotificationError = styled.div``;

export const NoticeContainer = styled.div`
  background-color: #f8f9fa;
  margin-top: 16px;
  margin-bottom: 16px;
  padding: 16px;
  max-width: 431px;
  line-height: 21px;
  @media (min-width: 450px) and (max-width: 599px) {
    margin-top: 32px;
  }
`;
export const EditingNoticeContainer = styled(NoticeContainer)`
  max-width: 560px;
`;
export const MiniTitle = styled.span`
  font-weight: bolder;
`;
export const PhoneDescription = styled.span`
  color: #657580;
  line-height: 21px;
  font-size: 14px;
`;
export const EmailDescription = styled.span`
  color: #657580;
  line-height: 21px;
  font-size: 14px;
`;

export const ProfilePhoneNumberLabel = styled.div.attrs({
  id: 'profilephoneNumbersLabel',
})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 400;
  margin-top: 8px;
  @media (max-width: 599px) {
    margin-top: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
  }
`;
export const PrimaryPhoneLabel = styled(PhoneNumbersLabel)`
  font-weight: 400;
`;
export const SecondaryPhoneNumberLabel = styled(PhoneNumbersLabel)`
  font-weight: 400;
`;
export const UserNameLabel = styled.div`
  font-weight: 400;
  margin-top: 12px;
`;
export const AccountEmailText = styled.div`
  font-weight: 400;
  margin-top: 12px;
`;

export const StyledSecondaryPhoneContainer = styled.div`
  margin-top: ${(props) => (props.$isDashboard ? '0' : '4px')};
`;

export const TimezoneLabel = styled(ProfileLabel).attrs({
  id: 'timezoneLabel',
})`
  font-weight: ${(props) => (props.editingEmail ? 400 : 700)};
  @media (max-width: 599px) {
    margin-top: 16px;
    padding-bottom: 0;
  }
`;

export const TimezoneContainer = styled(ProfileInformationContainer).attrs({
  id: 'timezoneContainer',
})`
  display: grid;
  row-gap: 6px;
  @media (min-width: 450px) and (max-width: 599px) {
    grid-template-columns: 80% 20%;
    display: grid;
    column-gap: 35px;
    margin-top: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: 6fr 3fr 2fr;
    row-gap: 20px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 6fr 3fr 2fr;
    row-gap: 20px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${(props) => (props.isDashboard ? '15% 25% 50%;' : '55.4% 44.6%')};
    margin-bottom: 12px;
    margin-top: 20px;
  }
`;

export const EditTimezoneLink = styled(Link).attrs({
  type: 'button',
  id: 'editTimezoneLink',
  to: '#',
})`
  cursor: pointer;
  border: none;
  background-color: transparent;
  color: blue;
  padding-left: 0;
  line-height: 1.2;
  text-align: left;
  text-decoration: none;
  margin-top: ${(props) => (props.isDashboard ? null : '22px')};
  :hover {
    color: #00009c;
  }
`;

export const EditTimezoneButtonContainer = styled.div.attrs({
  id: 'editTimezoneButtonContainer',
})`
  @media (min-width: 450px) and (max-width: 599px) {
    align-self: end;
  }
`;

export const HelpIcon = styled(InfoIcon).attrs({
  className: 'fa fa-info-circle',
})`
  margin-left: 6px;
  color: #777;
  font-size: 16px;
  position: relative;
  bottom: 1px;
`;
