import * as React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AboutUsBlurb, AboutUsModalContainer, StyledAboutUsLink } from './styles';
import { Contacts } from './contacts';

export const AboutUs = (props) => {
  const { aboutUsModal } = props;
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      {aboutUsModal && (
        <>
          <StyledAboutUsLink onClick={handleOpen}>{aboutUsModal.aboutUsLink}</StyledAboutUsLink>
          <Dialog open={open} onClose={handleClose}>
            <AboutUsModalContainer>
              <h3>{aboutUsModal.companyName}</h3>
              <AboutUsBlurb>{aboutUsModal.aboutUs}</AboutUsBlurb>
              <AboutUsBlurb>{aboutUsModal.streetAddress}</AboutUsBlurb>
              <Contacts contacts={aboutUsModal.contacts} />
            </AboutUsModalContainer>
          </Dialog>
        </>
      )}
    </>
  );
};

AboutUs.propTypes = {
  aboutUsModal: PropTypes.any,
};

export const mapStateToProps = (state) => ({
  state,
  aboutUsModal: state.i18nSlice.i18n.aboutUsModal,
});

export default connect(mapStateToProps)(AboutUs);
