import styled from 'styled-components';

export const StyledHeader = styled.h3.attrs({
  id: 'reportClaimUnauthedHeader',
})`
  font-size: 24px;
  font-weight: 700;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin: 0 0 16px 0;
`;

export const AnonymousReportClaimContainer = styled.div.attrs({
  id: 'ReportClaimAnonymousContainer',
})`
  padding: 24px;
  border: 2px solid ${props => (props.theme.gray ? props.theme.gray : 'black')};
  width: 73%;
  margin: 34px auto;
  background: white;
  @media (max-width: 599px) {
    width: 95%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 90%;
  }
`;

export const ReportClaimCancelButton = styled.button.attrs({
  id: 'repotClaimCancelButton',
})`
  background-color: initial;
  color: ${props => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-top: 8px;
  padding: initial;

  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
  }
`;
export const AuthorizationMessage = styled.div`
  margin-top: 40px;
`;
export const ClaimEventNameLabel = styled.div.attrs({
  id: 'claimEventNameLabel',
})`
  font-weight: 400;
  margin-top: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
