import styled from 'styled-components';

export const PolicyChangePreviewContainer = styled.div.attrs({
  id: 'PolicyChangePreviewContainer',
})`
  margin-top: 10px;
`;
export const PolicyChangePreviewSubContainer = styled.div.attrs({
  id: 'PolicyChangePreviewSubContainer',
})``;
export const PolicyChangePreviewBorderedContainer = styled.div.attrs({
  id: 'PolicyChangePreviewBorderedContainer',
})`
  @media (max-width: 459px) {
    margin-top: 16px;
  }
  border: 2px solid ${props => props.theme.primaryColor};
  padding-left: 20px;
  padding-bottom: 20px;
  min-width: 200px;
`;
export const TwoColumnContainer = styled.div.attrs({})`
  display: grid;
  margin-top: 20px;
  @media (max-width: 459px) {
    grid-template-columns: 100%;
  }
  @media (min-width: 460px) and (max-width: 699px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 50% 50%;
    grid-template-rows: 1fr;
  }
`;
export const PolicyChangePreviewField = styled.div.attrs({})`
  min-width: 200px;
`;

export const PolicyChangePreviewFieldHeader = styled.h4.attrs({
  id: 'PolicyChangePreviewFieldHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const NewPremiumLabel = styled.div.attrs({})`
  font-weight: 600;
  color: ${props => props.theme.primaryColor};
`;
export const PolicyChangePreviewTextArea = styled.input.attrs({
  type: 'textArea',
  readOnly: true,
})`
  height: 30px;
  width: 90%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const InformationMessage = styled.div`
  margin-top: 20px;
  font-size: 12px;
  font-style: italic;
`;
export const PolicyChangePreviewButtonsContainer = styled.div.attrs({})`
  text-align: right;
  margin-right: 2%;
  margin-top: 15px;
  @media (max-width: 384px) {
    text-align: center;
  }
`;
export const PolicyChangePreviewButton = styled.button.attrs({})`
  margin-left: 10px;
  padding: 10px 20px;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  :disabled {
    background-color: gray;
  }
  @media (max-width: 384px) {
    margin-bottom: 10px;
  }
`;
export const HorizontalLine = styled.hr.attrs({})`
  margin-top: 30px;
`;
