import {
  setClaimEventTemplates,
  setDriverRelationshipOptions,
  setErrors,
} from './anonymousReportClaimStepTwoReducer';
import { TOKENIZED_API } from '../../../../utils/api';

export const getClaimEventTemplates = (customerId, registrationToken) => {
  return async dispatch => {
    return TOKENIZED_API.get(
      `/claimEventTemplates?customerRef=${customerId}&customerToken=${registrationToken}`,
    )
      .then(response => {
        // set values and labels in array
        const claimEventTemplateOptions = response.data.claimEventTemplateItems.map(template => ({
          ...template,
          value: template.id,
          label: template.description ? template.description : template.name,
        }));
        dispatch(setClaimEventTemplates(claimEventTemplateOptions));
        return TOKENIZED_API.get(
          `/coderefs/UW/underwriting/driver-relationship/all
        `,
        ).then(response =>
          dispatch(
            setDriverRelationshipOptions(
              response.data.options.map(option => ({ value: option.value, label: option.name })),
            ),
          ),
        );
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      });
  };
};
