import { ga4Event } from '../../../../utils/google-analytics-4';
import { SERVICE_PORTAL } from '../../../common/constants';

export const configurePaymentBody = (
  instrumentToPayWith,
  payDate,
  amountToPay,
  customerId,
  accountId,
  paymentServiceAccountId,
) => {
  let finalBody;
  if (!instrumentToPayWith.isTemporary) {
    finalBody = {
      scheduledDate: payDate,
      paymentMethod: instrumentToPayWith.methodCd === 'Credit Card' ? 'Credit Card' : 'ACH',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          paymentOnFileId: instrumentToPayWith.id,
          electronicPaymentSource: {
            creditCardNumber: instrumentToPayWith.creditCardNumber,
            achName: instrumentToPayWith.achName,
            achRoutingNumber: instrumentToPayWith.achRoutingNumber,
            achBankAccountTypeCd: instrumentToPayWith.achBankAccountTypeCd,
            achBankAccountNumber: instrumentToPayWith.achBankAccountNumber,
            achStandardEntryClassCd: instrumentToPayWith.achStandardEntryClassCd,
            sourceName: SERVICE_PORTAL,
          },
        },
      ],
    };
  } else if (
    instrumentToPayWith.isTemporary === true &&
    instrumentToPayWith.sourceType === 'CreditCard'
  ) {
    finalBody = {
      scheduledDate: payDate,
      paymentMethod: 'Credit Card',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          electronicPaymentSource: {
            creditCardNumber: instrumentToPayWith.lastFour,
            customerPaymentProfileId: instrumentToPayWith.id.toString(),
            customerProfileId: `CUST-${customerId}`,
            paymentServiceAccountId: paymentServiceAccountId, //carrier code? paymentServiceAccountId is from any billingAccounts response, under arPolicy.carrierCd
            sourceName: SERVICE_PORTAL,
          },
        },
      ],
    };
  } else {
    finalBody = {
      scheduledDate: payDate,
      paymentMethod: 'ACH',
      payments: [
        {
          sourceRef: accountId,
          sourceCd: 'Web Portal',
          checkAmt: amountToPay,
          receiptAmt: amountToPay,
          electronicPaymentSource: {
            achBankAccountNumber: instrumentToPayWith.achBankAccountNumber,
            achBankAccountTypeCd: instrumentToPayWith.achBankAccountTypeCd,
            achName: instrumentToPayWith.achName,
            achRoutingNumber: instrumentToPayWith.achRoutingNumber,
            achStandardEntryClassCd: instrumentToPayWith.achStandardEntryClassCd,
            customerPaymentProfileId: instrumentToPayWith?.id
              ? instrumentToPayWith.id.toString()
              : '',
            sourceName: SERVICE_PORTAL,
          },
        },
      ],
    };
  }

  if (instrumentToPayWith.isTemporary) {
    // GA4 event for ACH-Account/CreditCard added
    ga4Event({
      category: 'make-a-payment',
      action:
        instrumentToPayWith.sourceType === 'CreditCard' ? 'credit_card_added' : 'ach_account_added',
      label: 'loggedin_payment',
    });
  }
  return finalBody;
};
