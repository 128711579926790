import styled from 'styled-components';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import FileCopyIcon from '@mui/icons-material/FileCopy';

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;
export const ClaimDetailsContainer = styled.div.attrs({
  id: 'ClaimDetailsContainer',
})`
  margin: 30px;
`;
export const ClaimDetailsTitle = styled.h3.attrs({
  id: 'ClaimDetailsTitle',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;
export const ClaimInfoContainer = styled.div.attrs({})`
  border: 2px solid #444;
  padding: 15px;
  display: grid;
  word-break: break-word;
  margin-bottom: 4%;
  @media (max-width: 549px) {
  }
  @media (min-width: 550px) and (max-width: 769px) {
    grid-template-columns: 40% 60%;
  }
  @media (min-width: 770px) and (max-width: 979px) {
    grid-template-columns: 17% 25% 17% 41%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 17% 25% 17% 41%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 17% 25% 17% 41%;
  }
`;
export const claimDetailsInfoLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  display: inline-block;
  margin-bottom: 3%;
  word-break: break-word;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;
export const ClaimDetailsLabel = styled(claimDetailsInfoLabel).attrs((props) => ({
  id: 'claimDetailsLabel' + props.id,
}))`
  font-weight: 700;
`;

export const ClaimDetailsValue = styled(claimDetailsInfoLabel).attrs((props) => ({
  id: 'claimDetailsValue' + props.id,
}))``;
export const UploadModalContainer = styled.div.attrs({})`
  margin-top: 16px;
`;
export const SectionHeader = styled.h3.attrs({
  id: 'claimDetailsDocumentsHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
    margin-top: 2%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-top: 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-top: 2%;
  }
  @media (min-width: 1280px) {
    margin-top: 2%;
  }
`;
export const FileIcon = styled(FileCopyIcon).attrs({
  className: 'fa fa-files-o',
})`
  margin: 0 1% 0 0;
  position: relative;
  top: 3px;
`;
export const claimDetailsInformationLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;
export const DocumentTypeLabel = styled(claimDetailsInformationLabel).attrs((props) => ({
  id: `documentTypeLabel${props.id}`,
}))`
  @media (max-width: 739px) {
    display: none;
  }
`;
export const DownloadLinkLabel = styled(claimDetailsInformationLabel).attrs({
  id: 'downloadLinkLabel',
})`
  @media (max-width: 739px) {
    display: none;
  }
`;
export const ClaimAttachmentsDataContainer = styled.div`
  display: grid;
  word-break: break-word;
  @media (max-width: 739px) {
    row-gap: 16px;
    margin: 16px 0 16px 0;
    padding: 0 0 16px 0;
    border-bottom: 2px solid #ddd;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin: 1% 3% 1% 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin: 2% 3% 2% 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 5fr 7fr 3fr 3fr;
    margin: 20px 5%;
  }
`;
export const claimDetailsDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;
export const DocumentTypeContainer = styled(claimDetailsDataDiv).attrs({
  id: 'DocumentTypeContainer',
})`
  @media (min-width: 600px) {
  }
`;

export const DownloadLinkContainer = styled(claimDetailsDataDiv).attrs({
  id: 'DownloadLinkContainer',
})`
  @media (min-width: 600px) {
  }
`;
export const DocumentTypeLabelMobile = styled(claimDetailsInformationLabel).attrs({
  id: 'deliveryPreferenceLabel',
})`
  @media (min-width: 740px) {
    display: none;
  }
`;
export const DownloadLinkLabelMobile = styled(claimDetailsInformationLabel).attrs({
  id: 'deliveryPreferenceLabel',
})`
  @media (min-width: 740px) {
    display: none;
  }
`;
export const PdfIcon = styled(PictureAsPdfIcon).attrs({
  className: 'fa fa-file-pdf-o',
})`
  margin: 0 1% 0 0;
  position: relative;
  top: 6px;
`;
export const ClaimDocumentsContainer = styled.div.attrs({})`
  border: 2px solid #444;
  padding: 15px;
  margin-bottom: 3%;
`;
export const ClaimAttachmentsLabelContainer = styled.div`
  display: grid;
  border-bottom: 2px solid #444;
  @media (max-width: 739px) {
    border-bottom: none;
  }
  @media (min-width: 739px) and (max-width: 979px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 2fr 2fr 1fr 1fr;
    margin-left: 3%;
    margin-right: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 5fr 7fr 3fr 3fr;
    margin: 16px 5%;
    padding: 10px 0;
  }
`;
export const ClaimDetailsHeader = styled.h3.attrs({
  id: 'ClaimDetailsHeader',
})`
  font-family: 'Helvetica Neue', Lato, proxima-nova, Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  margin-left: 30px;
`;
