import styled from 'styled-components';

export const NeedHelpContainer = styled.div.attrs({
  id: 'needHelpContainer',
})`
  margin: 30px;
`;

export const HelpHeader = styled.h1.attrs({
  id: 'helpHeader',
})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 32px;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const HelpInfoContainer = styled.div.attrs({})`
  /* padding: 15px; */
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const HelpDataContainer = styled.div`
  word-break: break-all;
`;

export const HelpContactContainer = styled.div`
  word-break: break-all;
`;
export const HelpLabel = styled.h2`
  word-break: normal;
  margin: 0;
  font-weight: 600;
  font-size: 24px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  @media (max-width: 659px) {
    text-align: center;
  }
`;
export const HelpContactLabel = styled.div`
  word-break: break-word;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-top: 10px;
  /* margin-bottom: 30px; */
  @media (max-width: 659px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;
export const IconAndLinkContainer = styled.div`
  word-break: break-word;
  font-size: 16px;
  font-weight: 500;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  margin-top: 10px;
  /* margin-bottom: 30px; */
  @media (max-width: 659px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;
export const ContactMessage = styled.div`
  word-break: break-word;
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 600;
  margin-top: 24px;
  line-height: 20px /* margin-bottom: 30px; */ @media (max-width: 659px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;
export const HelpContactLink = styled.a`
  position: relative;
  bottom: 6px;
  left: 20px;
  @media (max-width: 659px) {
    left: 10px;
  }
`;
export const SelfServiceMessage = styled.div`
  margin-top: 12px;
  word-break: break-word;
  @media (max-width: 659px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;
export const SelfServiceHours = styled.div`
  margin-top: 24px;
  @media (max-width: 659px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;
export const SelfServiceHoursHeader = styled.h4`
  font-style: normal;
  font-weight: 600;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 20px;
  margin-bottom: 10px;
  margin-top: initial;
  @media (max-width: 659px) {
    text-align: center;
    margin-bottom: 0px;
  }
`;
