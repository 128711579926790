import * as React from 'react';
import { StyledPrivacyStatementLink } from './styles';
import { connect } from 'react-redux';
import html from '../../../../config/documents/privacy.html';
import Modal from '../../../common/modal-with-html';
import PropTypes from 'prop-types';
import { getText } from '../../../../utils/i18n';

export const PrivacyStatement = props => {
  const { customPrivacyStatement, i18n } = props;
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <>
      <StyledPrivacyStatementLink onClick={handleOpen}>
        Privacy Statement{' '}
      </StyledPrivacyStatementLink>
      <Modal
        modalHtml={html}
        dialogOpen={open}
        customModal={customPrivacyStatement}
        modalTitle={getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
        setDialogClosed={() => handleClose()}
      />
    </>
  );
};

PrivacyStatement.propTypes = {
  i18n: PropTypes.object,
  customPrivacyStatement: PropTypes.bool,
};

export const mapStateToProps = state => ({
  i18n: state.i18nSlice.i18n,
  customPrivacyStatement: state.configurationSlice.features.features.customPrivacyStatement,
  state,
});
export default connect(mapStateToProps)(PrivacyStatement);
