import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  response: null,
};

export const forgotUsernameSlice = createSlice({
  name: 'forgotUsernameSlice',
  initialState,
  reducers: {
    resetState: () => {
      return initialState;
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setResponse: (state, action) => {
      return { ...state, response: action.payload };
    },
  },
});
export const { setIsLoading, setResponse, resetState } = forgotUsernameSlice.actions;
export default forgotUsernameSlice.reducer;
