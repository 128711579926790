import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { getMyAgents } from './myAgentApi.js';
import PropTypes from 'prop-types';
import {
  AddressLineOne,
  AddressLineTwo,
  AgentContainer,
  AgentEmailIcon,
  AgentPhoneIcon,
  AgentImage,
  AgentsEmailHeader,
  AgentsPhoneHeader,
  MyAgentAddress,
  MyAgentContainer,
  MyAgentHeader,
  TemplateOnePanel,
  PoliciesSubHeader,
} from './styles';
import { LineDivider } from '../../../common/line-divider/index.js';
import Loader from '../../../common/loader';
import ApiError from '../../../common/api-error/index.js';
export const MyAgent = props => {
  const {
    agentLinkAllowed,
    dispatch,
    customerId,
    isLoading,
    agents,
    template,
    policies,
    showAgentEmail,
  } = props;

  const [agentRefPolicyMap, setAgentRefPolicyMap] = React.useState({});

  useEffect(() => {
    if (customerId) {
      dispatch(getMyAgents(customerId));
    }
  }, [customerId]);

  useEffect(() => {
    // update agent info with policies
    if (agents && agents.length > 1 && policies && policies.length > 0) {
      const _agentRefPolicyMap = {};
      policies.forEach((policy) => {
        const providerRef = policy.providerRef;
        let policyNumList;
        if (_agentRefPolicyMap[providerRef]) {
          policyNumList = _agentRefPolicyMap[providerRef];
        } else {
          policyNumList = [];
        }
        policyNumList.push(policy.policyNumber);
        _agentRefPolicyMap[providerRef] = policyNumList;
      });
      setAgentRefPolicyMap(_agentRefPolicyMap);
    }
  }, [agents, policies]);
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <AgentContainer template={template}>
          {template === 'template1' ? <TemplateOnePanel>My Agent</TemplateOnePanel> : null}
          {agents && agents.length > 0 ? (
            agents.map((agent) => {
              const policies = agentRefPolicyMap[agent.ref] ? agentRefPolicyMap[agent.ref] : [];
              return (
                <React.Fragment key={agent.id}>
                  <MyAgentContainer id={agent.id} template={template}>
                    <MyAgentHeader>
                      {agent && agent.nameInfo && agent.nameInfo.commercialName}
                    </MyAgentHeader>
                    {agent.addresses.map((address) =>
                      address.addrTypeCd === 'ProviderStreetAddr' ? (
                        <MyAgentAddress key={address.id}>
                          <AddressLineOne>{address.addr1} </AddressLineOne>
                          <AddressLineTwo>
                            {address.city}, {address.stateProvCd} {address.postalCode}
                          </AddressLineTwo>
                        </MyAgentAddress>
                      ) : null,
                    )}
                    {agents && agents.length > 1 && (
                      <>
                        <PoliciesSubHeader>
                          {policies.length === 1 ? 'Policy' : 'Policies'}
                        </PoliciesSubHeader>
                        {policies && policies.map((policy) => <div key={policy}>{policy}</div>)}
                      </>
                    )}
                    {showAgentEmail && (
                      <>
                        <AgentsEmailHeader>Email</AgentsEmailHeader>
                        <div>
                          <AgentEmailIcon id="agentEmailEnvelope" className="fas fa-envelope" />{' '}
                          {agentLinkAllowed ? (
                            <a
                              id="agentEmailAddressLink"
                              href={`mailto:${agent.emailInfo.emailAddr}`}
                            >
                              {agent.emailInfo.emailAddr}
                            </a>
                          ) : (
                            <> {agent.emailInfo.emailAddr} </>
                          )}
                        </div>
                      </>
                    )}
                    <AgentsPhoneHeader>Phone</AgentsPhoneHeader>
                    <div>
                      <AgentPhoneIcon id="agentPhoneIcon" className="fas fa-phone" />{' '}
                      {agentLinkAllowed ? (
                        agent.phoneInfo[0].phoneNumber ? (
                          <a id="agentPhoneNumber0" href={`tel:${agent.phoneInfo[0].phoneNumber}`}>
                            {agent.phoneInfo[0].phoneNumber}
                          </a>
                        ) : agent.phoneInfo[1].phoneNumber ? (
                          <a id="agentPhoneNumber1" href={`tel:${agent.phoneInfo[1].phoneNumber}`}>
                            {agent.phoneInfo[1].phoneNumber}
                          </a>
                        ) : (
                          <span>Not Available</span>
                        )
                      ) : agent.phoneInfo[0].phoneNumber ? (
                        <span>{agent.phoneInfo[0].phoneNumber}</span>
                      ) : agent.phoneInfo[1].phoneNumber ? (
                        <span>{agent.phoneInfo[1].phoneNumber}</span>
                      ) : (
                        <span>Not Available</span>
                      )}
                    </div>
                  </MyAgentContainer>
                  <LineDivider />
                </React.Fragment>
              );
            })
          ) : (
            <ApiError body="Unable to retrieve Agents right now." variant="menu" />
          )}
        </AgentContainer>
      )}
    </>
  );
};
MyAgent.propTypes = {
  agents: PropTypes.any,
  policies: PropTypes.array,
  agentLinkAllowed: PropTypes.bool,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  providerRefs: PropTypes.array,
  customerId: PropTypes.string,
  showAgentEmail: PropTypes.bool,
  template: PropTypes.string,
};
const mapStateToProps = (state) => ({
  state,
  agentLinkAllowed: state.configurationSlice.features.features.agentLink,
  showAgentEmail: state.configurationSlice.features.features.agentEmail,
  customerId: state.loginSlice.user.customer_id,
  isLoading: state.agentSlice.isLoading,
  agents: state.agentSlice.agents,
  policies: state.policySlice.policies,
  template: state.configurationSlice.features.template,
});
export default connect(mapStateToProps)(MyAgent);
