import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import { find } from 'lodash';
import {
  formatPhoneNumber,
  isEmail,
  isPhone,
} from '../../../../utils/validators/generalValidators';

import {
  BestWayToContactContainer,
  BestWayToContactSelect,
  ClaimEventNameLabel,
  EmailRow,
  HomePhoneRow,
  LocationFieldsContainer,
  MobilePhoneRow,
  ReportClaimCityStateContainer,
  ReportClaimsAddressTitleHeader,
  ReportClaimsCountryTitleHeader,
  ReportClaimsFieldHeader,
  ReportClaimsStepThreeContainer,
  ReportClaimsStepThreeSubHeader,
  ReportClaimStepThreeField,
  ReportClaimStepThreeSubHeaderDescription,
  ReportClaimsTitleHeader,
  ReportClaimThreeFormContainerFullRow,
  ReportClaimZipCountyContainer,
  RequiredField,
  StateSelectComponent,
  ZipErrorMessage,
} from './styles';
import {
  setBestWayToContact,
  setCityText,
  setCountryText,
  setCountyText,
  setEmail,
  setLocation1Text,
  setLocation2Text,
  setLocation3Text,
  setLocation4Text,
  setPostalCode,
  setSelectedState,
  setStateText,
} from './reportClaimStepThreeReducer';
import { US_STATES } from '../../../common/constants';
import {
  ErrorMessage,
  ReportClaimsAddressTextInputField,
  ReportClaimsTextInputField,
  ReportClaimsZipInput,
} from '../styles';
import Addresses from '../../../common/addresses';
import { getStateByCode } from '../../../common/addresses/addressUtils';
import { getMailingState } from './selectors';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import ReportClaimButtons from '../report-claim-button-container';

export const ReportClaimFormContainer = props => {
  const {
    activeStep,
    claimEventContactsErrors,
    countyText,
    countryText,
    email,
    handleNext,
    handleStep,
    homePhone,
    businessPhone,
    otherPhone,
    location3Text,
    location4Text,
    mobilePhone,
    postalValue,
    selectedClaimEventDescription,
    stateText,
    stateValue,
    state,
    i18n,
  } = props;
  const bestWayToContact = props.state.reportClaimStepThreeSlice.claimEventContacts.contact
    .personInfo
    ? props.state.reportClaimStepThreeSlice.claimEventContacts.contact.personInfo.bestWayToContact
    : '';
  const BEST_WAY_TO_CONTACT = [
    { label: 'Mobile Phone', value: 'Mobile' },
    { label: 'Home Phone', value: 'Home' },
    { label: 'Business Phone', value: 'Business' },
    { label: 'Other Phone', value: 'Other' },
    { label: 'Email', value: 'Email' },
  ];
  const postalErrorMessage =
    postalValue && postalValue.length !== 0 && postalValue.length < 5 ? 'Invalid Entry' : '';
  const [isNonUSAddressState, setIsNonUSAddressState] = useState(false);
  const [isHomeRequired, setIsHomeRequired] = useState(false);
  const [isBusinessPhoneRequired, setIsBusinessPhoneRequired] = useState(false);
  const [isOtherPhoneRequired, setIsOtherPhoneRequired] = useState(false);
  const [isMobileRequired, setIsMobileRequired] = useState(false);
  const [isEmailRequired, setIsEmailRequired] = useState(false);
  const [isBestWayToContactSelected, setIsBestWayToContactInput] = useState(bestWayToContact);

  const [homePhoneField, setHomePhoneFormField] = React.useState(homePhone);
  const [mobilePhoneField, setMobilePhoneFormField] = React.useState(mobilePhone);
  const [businessPhoneField, setBusinessPhoneFormField] = React.useState(businessPhone);
  const [otherPhoneField, setOtherPhoneFormField] = React.useState(otherPhone);
  const [localLocation1Text, setLocalLocation1Text] = useState('');
  const [localLocation2Text, setLocalLocation2Text] = useState('');
  const [localCityText, setLocalCityText] = useState('');
  const [localPostalValue, setLocalPostalValue] = useState('');
  const [localStateValue, setLocalStateValue] = useState(
    find(
      US_STATES,
      usState =>
        usState.value ===
        getMailingState(state.reportClaimStepThreeSlice.claimEventContacts.contact.addresses),
    ),
  );
  const handlePlaceSelect = address => {
    const { addr1, addr2, city, stateProvCd, postalCode } = address;

    if (
      !(addr1.length === 0 || city.length === 0 || stateProvCd.length === 0 || postalCode === 0)
    ) {
      // update current state
      setLocalLocation1Text(addr1);
      setLocalLocation2Text(addr2);
      setLocalCityText(city);
      setLocalPostalValue(postalCode);
      setLocalStateValue(getStateByCode(stateProvCd));

      // update reducer
      props.dispatch(setLocation1Text(addr1));
      props.dispatch(setLocation2Text(addr2));
      props.dispatch(setCityText(city));
      props.dispatch(setPostalCode(postalCode));
      props.dispatch(setSelectedState(getStateByCode(stateProvCd)));
    }
  };
  return (
    <>
      <ReportClaimsStepThreeContainer>
        {!!claimEventContactsErrors && <ErrorMessage>{claimEventContactsErrors}</ErrorMessage>}
        <ClaimEventNameLabel>
          {selectedClaimEventDescription && selectedClaimEventDescription}
        </ClaimEventNameLabel>
        <ReportClaimsStepThreeSubHeader>
          Add your contact information.
        </ReportClaimsStepThreeSubHeader>
        <ReportClaimStepThreeSubHeaderDescription>
          Please verify your contact information.
        </ReportClaimStepThreeSubHeaderDescription>
        <MobilePhoneRow>
          <ReportClaimsFieldHeader htmlFor="mobilePhone">
            Mobile Phone {isMobileRequired ? <RequiredField>*</RequiredField> : null}
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.mobilePhoneTooltip')} />
          </ReportClaimsFieldHeader>
          <ReportClaimsTextInputField
            name="mobilePhone"
            value={mobilePhoneField}
            aria-label="Mobile Phone"
            onChange={e => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              setMobilePhoneFormField(formattedPhoneNumber);
            }}
          />
        </MobilePhoneRow>
        <HomePhoneRow>
          <ReportClaimsFieldHeader htmlFor="homePhone">
            Home Phone {isHomeRequired ? <RequiredField>*</RequiredField> : null}
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.homePhoneTooltip')} />
          </ReportClaimsFieldHeader>
          <ReportClaimsTextInputField
            name="homePhone"
            value={homePhoneField}
            aria-label="Home Phone"
            onChange={e => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              setHomePhoneFormField(formattedPhoneNumber);
            }}
          />
        </HomePhoneRow>
        <HomePhoneRow>
          <ReportClaimsFieldHeader htmlFor="businessPhone">
            Business Phone {isBusinessPhoneRequired ? <RequiredField>*</RequiredField> : null}
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.businessPhoneTooltip')} />
          </ReportClaimsFieldHeader>
          <ReportClaimsTextInputField
            name="businessPhone"
            value={businessPhoneField}
            aria-label="Business Phone"
            onChange={e => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              setBusinessPhoneFormField(formattedPhoneNumber);
            }}
          />
        </HomePhoneRow>
        <HomePhoneRow>
          <ReportClaimsFieldHeader htmlFor="otherPhone">
            Other Phone {isOtherPhoneRequired ? <RequiredField>*</RequiredField> : null}
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.otherPhoneTooltip')} />
          </ReportClaimsFieldHeader>
          <ReportClaimsTextInputField
            name="otherPhone"
            value={otherPhoneField}
            aria-label="Other Phone"
            onChange={e => {
              const formattedPhoneNumber = formatPhoneNumber(e.target.value);
              setOtherPhoneFormField(formattedPhoneNumber);
            }}
          />
        </HomePhoneRow>
        <EmailRow>
          <ReportClaimsFieldHeader htmlFor="email">
            Email {isEmailRequired ? <RequiredField>*</RequiredField> : null}
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.emailTooltip')} />
          </ReportClaimsFieldHeader>
          <ReportClaimsTextInputField
            name="email"
            defaultValue={email}
            aria-label="Email"
            onChange={e => {
              props.dispatch(setEmail(e.target.value));
            }}
          />
        </EmailRow>
        <ReportClaimStepThreeField>
          <ReportClaimsTitleHeader htmlFor="nonUSAddressCheckBox">
            Non-U.S. Address{' '}
            <input
              aria-label="Non US Address"
              type="checkbox"
              name="nonUSAddressCheckBox"
              checked={isNonUSAddressState}
              onChange={() => setIsNonUSAddressState(!isNonUSAddressState)}
            />
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.nonUSAddressTooltip')} />
          </ReportClaimsTitleHeader>
        </ReportClaimStepThreeField>
        <LocationFieldsContainer>
          {isNonUSAddressState && (
            <ReportClaimThreeFormContainerFullRow>
              <ReportClaimsCountryTitleHeader htmlFor="country">
                Country
                <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.countryTooltip')} />
              </ReportClaimsCountryTitleHeader>
              <ReportClaimsTextInputField
                name="country"
                aria-label="Country"
                defaultValue={countryText}
                onChange={e => {
                  props.dispatch(setCountryText(e.target.value));
                }}
              />
            </ReportClaimThreeFormContainerFullRow>
          )}
          {!isNonUSAddressState ? (
            <ReportClaimThreeFormContainerFullRow>
              <Addresses
                id="reportClaimContactLocationAddress"
                size="large"
                handlePlaceSelect={handlePlaceSelect}
              >
                <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.addressTooltip')} />
              </Addresses>
            </ReportClaimThreeFormContainerFullRow>
          ) : (
            <>
              <ReportClaimThreeFormContainerFullRow>
                <ReportClaimsAddressTitleHeader htmlFor="locationAddress1">
                  Address
                  <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.addressTooltip')} />
                </ReportClaimsAddressTitleHeader>
                <ReportClaimsAddressTextInputField
                  id="locationAddress1"
                  name="locationAddress1"
                  aria-label="Location Address Line 1"
                  value={localLocation1Text}
                  onChange={e => {
                    setLocalLocation1Text(e.target.value);
                    props.dispatch(setLocation1Text(e.target.value));
                  }}
                />
                <ReportClaimsAddressTextInputField
                  id="locationAddress2"
                  name="locationAddress2"
                  value={localLocation2Text}
                  aria-label="Location Address Line 2"
                  onChange={e => {
                    setLocalLocation2Text(e.target.value);
                    props.dispatch(setLocation2Text(e.target.value));
                  }}
                />
                {isNonUSAddressState && (
                  <ReportClaimThreeFormContainerFullRow>
                    <ReportClaimsAddressTextInputField
                      name="locationAddress3"
                      defaultValue={location3Text}
                      aria-label="Location Address Line 3"
                      onChange={e => {
                        props.dispatch(setLocation3Text(e.target.value));
                      }}
                    />
                    <ReportClaimsAddressTextInputField
                      name="locationAddress4"
                      defaultValue={location4Text}
                      aria-label="Location Address Line 4"
                      onChange={e => {
                        props.dispatch(setLocation4Text(e.target.value));
                      }}
                    />
                  </ReportClaimThreeFormContainerFullRow>
                )}
              </ReportClaimThreeFormContainerFullRow>
              <ReportClaimCityStateContainer>
                <ReportClaimStepThreeField>
                  <ReportClaimsFieldHeader htmlFor="city">
                    City
                    <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.cityTooltip')} />
                  </ReportClaimsFieldHeader>
                  <ReportClaimsTextInputField
                    input="city"
                    name="city"
                    value={localCityText}
                    aria-label="City"
                    onChange={e => {
                      setLocalCityText(e.target.value);
                      props.dispatch(setCityText(e.target.value));
                    }}
                  />
                </ReportClaimStepThreeField>
                <StateSelectComponent>
                  <ReportClaimStepThreeField>
                    <ReportClaimsFieldHeader htmlFor="state">
                      {isNonUSAddressState ? 'State/Province' : 'State'}{' '}
                    </ReportClaimsFieldHeader>
                    {!isNonUSAddressState ? (
                      <Select
                        name="stateList"
                        aria-label="State"
                        id="stateList"
                        defaultValue={find(US_STATES, state => state.value === stateValue)}
                        options={US_STATES}
                        value={localStateValue}
                        onChange={option => {
                          setLocalStateValue(option);
                          props.dispatch(setSelectedState(option));
                        }}
                      />
                    ) : (
                      <ReportClaimStepThreeField>
                        <ReportClaimsTextInputField
                          name="stateOrProvince"
                          aria-label="State or Province"
                          defaultValue={stateText}
                          onChange={e => {
                            props.dispatch(setStateText(e.target.value));
                          }}
                        />
                      </ReportClaimStepThreeField>
                    )}
                  </ReportClaimStepThreeField>
                </StateSelectComponent>
              </ReportClaimCityStateContainer>
              <ReportClaimZipCountyContainer>
                <ReportClaimStepThreeField>
                  <ReportClaimsFieldHeader htmlFor="zip">
                    {isNonUSAddressState ? 'Postal Code' : 'ZIP'}
                    <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.zipTooltip')} />
                  </ReportClaimsFieldHeader>
                  <ReportClaimsZipInput
                    id="zip"
                    name="zip"
                    aria-label={isNonUSAddressState ? 'Postal Code' : 'Zip'}
                    value={localPostalValue}
                    onChange={e => {
                      setLocalPostalValue(e.target.value);
                      props.dispatch(setPostalCode(e.target.value));
                    }}
                  />
                  <ZipErrorMessage>{postalErrorMessage && postalErrorMessage}</ZipErrorMessage>
                </ReportClaimStepThreeField>
                {!isNonUSAddressState && (
                  <div>
                    <ReportClaimsFieldHeader htmlFor="county">
                      County{' '}
                      <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.countyTooltip')} />
                    </ReportClaimsFieldHeader>
                    <ReportClaimsTextInputField
                      name="county"
                      defaultValue={countyText}
                      aria-label="County"
                      onChange={e => {
                        props.dispatch(setCountyText(e.target.value));
                      }}
                    />
                  </div>
                )}
              </ReportClaimZipCountyContainer>
            </>
          )}
        </LocationFieldsContainer>
        <BestWayToContactContainer>
          <ReportClaimsFieldHeader htmlFor="bestwaytocontact">
            Best Way To Contact <RequiredField>*</RequiredField>
            <TooltipInfo title={getText(i18n, 'reportClaim.stepThree.bestWayToContactTooltip')} />
          </ReportClaimsFieldHeader>
          <BestWayToContactSelect
            id="bestWayToContactSelect"
            name="bestwaytocontact"
            options={BEST_WAY_TO_CONTACT}
            defaultValue={
              bestWayToContact
                ? BEST_WAY_TO_CONTACT.find(contact => contact.value === bestWayToContact)
                : ''
            }
            aria-label="Best Way to Contact"
            onChange={option => {
              props.dispatch(setBestWayToContact(option.value));
              if (option.value === 'Home') {
                setIsEmailRequired(false);
                setIsHomeRequired(true);
                setIsMobileRequired(false);
                setIsBusinessPhoneRequired(false);
                setIsOtherPhoneRequired(false);
                setIsBestWayToContactInput(true);
              }
              if (option.value === 'Mobile') {
                setIsEmailRequired(false);
                setIsHomeRequired(false);
                setIsMobileRequired(true);
                setIsBusinessPhoneRequired(false);
                setIsOtherPhoneRequired(false);
                setIsBestWayToContactInput(true);
              }
              if (option.value === 'Business') {
                setIsEmailRequired(false);
                setIsHomeRequired(false);
                setIsMobileRequired(false);
                setIsBusinessPhoneRequired(true);
                setIsOtherPhoneRequired(false);
                setIsBestWayToContactInput(true);
              }
              if (option.value === 'Other') {
                setIsEmailRequired(false);
                setIsHomeRequired(false);
                setIsMobileRequired(false);
                setIsBusinessPhoneRequired(false);
                setIsOtherPhoneRequired(true);
                setIsBestWayToContactInput(true);
              }
              if (option.value === 'Email') {
                setIsEmailRequired(true);
                setIsHomeRequired(false);
                setIsMobileRequired(false);
                setIsBusinessPhoneRequired(false);
                setIsOtherPhoneRequired(false);
                setIsBestWayToContactInput(true);
              }
            }}
          />
        </BestWayToContactContainer>
      </ReportClaimsStepThreeContainer>

      <ReportClaimButtons
        activeStep={activeStep}
        bestWayToContact={bestWayToContact}
        BEST_WAY_TO_CONTACT={BEST_WAY_TO_CONTACT}
        email={email}
        homePhone={homePhone}
        isEmail={isEmail}
        isPhone={isPhone}
        businessPhoneField={businessPhoneField}
        homePhoneField={homePhoneField}
        mobilePhoneField={mobilePhoneField}
        otherPhoneField={otherPhoneField}
        mobilePhone={mobilePhone}
        businessPhone={businessPhone}
        otherPhone={otherPhone}
        isBestWayToContactSelected={isBestWayToContactSelected}
        handleNext={handleNext}
        handleStep={handleStep}
      />
    </>
  );
};
ReportClaimFormContainer.propTypes = {
  activeStep: PropTypes.any,
  state: PropTypes.any,
  dispatch: PropTypes.any,
  claimEventContactsErrors: PropTypes.any,
  email: PropTypes.string,
  mobilePhone: PropTypes.string,
  handleNext: PropTypes.any,
  handleStep: PropTypes.any,
  homePhone: PropTypes.string,
  businessPhone: PropTypes.string,
  otherPhone: PropTypes.string,
  isNonUSAddress: PropTypes.any,
  descriptionText: PropTypes.any,
  location1Text: PropTypes.any,
  location2Text: PropTypes.any,
  location3Text: PropTypes.any,
  location4Text: PropTypes.any,
  postalValue: PropTypes.string,
  phoneInfo: PropTypes.any,
  stateValue: PropTypes.any,
  stateText: PropTypes.any,
  countyText: PropTypes.any,
  selectedClaimEventDescription: PropTypes.any,
  countryText: PropTypes.any,
  i18n: PropTypes.object,
};
export default ReportClaimFormContainer;
