import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { useSnackbar } from 'notistack';
import { useDropzone } from 'react-dropzone';
import { flattenDeep } from 'lodash';
import ImageIcon from '@mui/icons-material/Image';

import { checkFileSize } from '../../../../utils/file-utils';

import { uploadFilesAndReview } from './reportClaimApi';
import {
  GarbageIcon,
  GarbageIconContainer,
  img,
  IndividualQuestionContainer,
  LossEventMessage,
  QuestionsContainer,
  QuestionTypeHeader,
  ReportClaimEventHeader,
  ReportClaimStepFourContainer,
  StyledDropZone,
  StyledReportClaimHeader,
  StyledSection,
  thumb,
  thumbInner,
  thumbsContainer,
} from './styles';
import Loader from '../../../common/loader';
import { getQuestionInputType, mapSubQuestionFunctions } from './utils';
import { setFilesBeingAttached } from './reportClaimStepFourReducer';
import ReportClaimButtons from '../report-claim-button-container';
import { handleQuestionsPreviouslyAnswered } from '../../../../utils/state';
import { getText } from '../../../../utils/i18n';

export const ReportClaimStepFour = (props) => {
  const {
    activeStep,
    handleNext,
    handleStep,
    i18n,
    questionReplies,
    reportClaimDetailsSection,
    dispatch,
    maxUploadFileSize,
    selectedClaimEventDescription,
  } = props;
  let mapReplies = handleQuestionsPreviouslyAnswered(questionReplies, reportClaimDetailsSection);
  let mappedSubQuestions = mapSubQuestionFunctions(mapReplies);
  const { enqueueSnackbar } = useSnackbar();
  const [question, setQuestion] = React.useState(mappedSubQuestions);
  const [files, setFiles] = React.useState([]);

  const getListOptions = (stringList) => {
    //stringList to array based on comma
    const newArray = stringList.split(',');
    return newArray.map((option) => {
      return { value: option, label: option };
    });
  };

  const setFilesForUpload = (fileNames, props) => {
    let goodFiles = checkFileSize(fileNames, maxUploadFileSize, enqueueSnackbar);
    if (goodFiles.length !== 0) {
      setFiles((prevState) =>
        flattenDeep([
          ...prevState,
          goodFiles.map((file) =>
            Object.assign(file, {
              preview: URL.createObjectURL(file),
              key: file.name,
              id: props.attachment.id,
              templateId: props.attachment.templateId,
            }),
          ),
        ]),
      );
    }
  };

  let Previews = (props) => {
    const { getRootProps, getInputProps } = useDropzone({
      accept: 'image/*,video/*,.pdf,.txt,.docx',
      onDrop: (acceptedFiles) => {
        setFilesForUpload(acceptedFiles, props);
      },
    });
    const thumbs = files.map((file, index) => {
      // eslint-disable-next-line react/prop-types
      if (props.attachment.id === file.id) {
        return (
          <div style={thumb} key={file.name}>
            <div style={thumbInner}>
              <img src={file.preview} style={img} alt="thumbnailForReportClaimFile" />
              <GarbageIconContainer>
                <GarbageIcon
                  onClick={() => {
                    files.splice(index, 1);
                    setFiles([...files]);
                  }}
                />
              </GarbageIconContainer>
            </div>
          </div>
        );
      }
    });

    return (
      <StyledSection className="container">
        <StyledDropZone {...getRootProps({ className: 'dropzone' })}>
          <ImageIcon
            className="fa fa-image btn"
            style={{ marginRight: '6px', position: 'relative', top: '5px' }}
          />
          Select File(s)
          <input {...getInputProps()} />
        </StyledDropZone>
        <aside style={thumbsContainer}>{thumbs}</aside>
      </StyledSection>
    );
  };

  const submitForReview = async () => {
    if (files.length > 0) {
      dispatch(setFilesBeingAttached(true));
    }
    await dispatch(uploadFilesAndReview(files, question, props));
  };
  return (
    <ReportClaimStepFourContainer>
      <Helmet>
        <title>Report Claim - Additional Loss Details</title>
      </Helmet>
      <ReportClaimEventHeader>{selectedClaimEventDescription}</ReportClaimEventHeader>
      <StyledReportClaimHeader>Please provide details about the incident.</StyledReportClaimHeader>
      {i18n?.general?.lossMessage ? (
        <LossEventMessage
          dangerouslySetInnerHTML={{
            __html: getText(i18n, 'general.lossMessage', ''),
          }}
        />
      ) : null}
      {question.length <= 0 && <Loader />}
      {question.map((event) => {
        return (
          <QuestionsContainer key={event.id}>
            <QuestionTypeHeader>{event.name}</QuestionTypeHeader>
            {event.questions.question.map((thisQuestion) => {
              return (
                <React.Fragment key={thisQuestion.id}>
                  {getQuestionInputType(
                    thisQuestion,
                    setQuestion,
                    getListOptions,
                    reportClaimDetailsSection,
                  )}
                </React.Fragment>
              );
            })}
            {event.attachments &&
              event.attachments.map((attachment) => {
                return (
                  <IndividualQuestionContainer key={attachment.id}>
                    <label>{attachment.description}</label>
                    <Previews attachment={attachment} />
                  </IndividualQuestionContainer>
                );
              })}
          </QuestionsContainer>
        );
      })}

      <ReportClaimButtons
        activeStep={activeStep}
        handleNext={handleNext}
        handleStep={handleStep}
        question={question}
        submitForReview={submitForReview}
      />
    </ReportClaimStepFourContainer>
  );
};
ReportClaimStepFour.propTypes = {
  activeStep: PropTypes.any,
  claimEvent: PropTypes.shape({
    name: PropTypes.string,
  }),
  dispatch: PropTypes.func,
  handleNext: PropTypes.any,
  handleStep: PropTypes.any,
  i18n: PropTypes.object,
  maxUploadFileSize: PropTypes.any,
  questionReplies: PropTypes.any,
  reportClaimDetailsSection: PropTypes.array,
  attachment: PropTypes.shape({
    id: PropTypes.string,
    templateId: PropTypes.string,
  }),
  selectedClaimEventDescription: PropTypes.string,
  state: PropTypes.object,
};
const mapStateToProps = (state) => ({
  claimEvent: state.reportClaimSlice.claimEvent,
  i18n: state.i18nSlice.i18n,
  maxUploadFileSize: state.configurationSlice.features.maxUploadFileSize,
  reportClaimDetailsSection: state.reportClaimSlice.updatedClaimEventTemplate.eventSections,
  questionReplies: state.reportClaimStepFourSlice.validationClaim.questionReplies,
  selectedClaimEventDescription: state.reportClaimSlice.selectedClaimEventTemplate.description,
  state,
});
export default connect(mapStateToProps)(ReportClaimStepFour);
