// accounts.js example duck
//  https://redux-toolkit.js.org/api/createReducer
//  https://redux-toolkit.js.org/api/createAction
// https://redux-toolkit.js.org/api/createAction#using-prepare-callbacks-to-customize-action-contents
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  errors: null,
};

export const logoutSlice = createSlice({
  name: 'logout',
  initialState,
  reducers: {
    logUserOut: state => {
      return { ...state };
    },
  },
});
export const { logUserOut } = logoutSlice.actions;
export default logoutSlice.reducer;
