import styled from 'styled-components';
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';

export const PaymentContainer = styled.div`
  margin: 25px;
`;
export const PaymentHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const StepThreeCards = styled.div`
  display: grid;
  margin-bottom: 24px;
  margin-top: 24px;
  border-radius: 4px;
  @media (max-width: 599px) {
    row-gap: 24px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    row-gap: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    row-gap: 24px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 31% 31% 31%;
    column-gap: 24px;
  }
`;
export const AmountContainer = styled.div.attrs({
  id: 'amountContainer',
})`
  display: grid;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const AmountHeader = styled.div.attrs({
  id: 'amountToBePaid',
})`
  font-size: 20px;
  color: #28333f;
  margin-left: 8px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  align-self: end;
  text-align: center;
`;
export const AmountDetails = styled.div.attrs({
  id: 'amountDetails',
})`
  font-size: 32px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #28333f;
  display: block;
  text-align: center;
  margin-top: 8px;
`;
export const InstrumentDetails = styled.div.attrs({
  id: 'instrumentDetails',
})`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  color: #28333f;
  display: block;
  margin-top: 8px;
`;
export const InstrumentContainer = styled.div.attrs({
  id: 'instrumentContainer',
})`
  display: grid;
  font-size: 16px;
  font-weight: 500;
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  height: 155px;
`;

export const InstrumentHeader = styled.div.attrs({
  id: 'instrumentHeader',
})`
  font-size: 16px;
  align-self: end;
  text-align: center;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const PayDateContainer = styled.div.attrs({
  id: 'payDateContainer',
})`
  background-color: #fafafa;
  align-self: center;
  justify-content: center;
  display: grid;
  height: 155px;
`;
export const DateOfPayment = styled.div.attrs({
  id: 'dateofpayment',
})`
  text-align: center;
  margin-top: 8px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const DateHeader = styled.div.attrs({
  id: 'dateheader',
})`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  align-self: end;
`;
export const StyledCreditIcon = styled(CreditCardIcon).attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  padding-right: 5px;
  padding-right: 5px;
  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
`;
export const StyledACHIcon = styled(AccountBalanceIcon).attrs({
  className: 'fa fa-lg fa-university',
})`
  padding-right: 5px;
  padding-right: 5px;
  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */

  padding-right: 5px;
  /* bottom: 5px; */
  /* position: relative; */
`;
export const StyledInfoIcon = styled(InfoIcon).attrs({
  className: 'fa fa-lg fa-info-circle',
})`
  margin-right: 6px;
`;
export const AuthorizationMessage = styled.div`
  margin-top: 40px;
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 16px 0;
`;
