import { createSlice } from '@reduxjs/toolkit';
import {
  mapAddrTypeCd,
  mapCity,
  mapId,
  mapState,
  mapZip,
  mapLocation1,
  mapLocation2,
  mapLocation3,
  mapLocation4,
} from '../step3/anonymousReportClaimStepThreeReducer';
const initialState = {
  claimSubmission: '',
  referenceNumber: '',
  claimEventTemplates: [],
  validationClaim: {},
  claimEventValidationErrors: '',
  isClaimEventValidated: false,
  selectedLocation: {},
  claimEvent: null,
  claimEventContacts: null,
  claimEventContactsErrors: '',
  isClaimEventLoading: false,
  selectedState: {},
  selectedVehicle: {
    label: '',
    value: '',
    id: '',
    riskNumber: '',
    description: '',
    typeCd: '',
    riskIdRef: '',
    vehicleModelYr: '',
    vehicleManufacturer: '',
    vehicleModel: '',
    vehicleIdentificationNumber: '',
    lineCd: '',
    ncciMake: '',
    vehicleInd: '',
    policyNumber: '',
  },
  selectedDriver: { value: '', label: '', id: '', partyInfo: '', policyNumber: '' },
  manualDriverName: '',
  manualLicenseNumber: '',
  isNonUSAddress: false,
  dateValue: '',
  timeValue: '',
  zipText: '',
  descriptionText: '',
  location1Text: '',
  location2Text: '',
  location3Text: '',
  location4Text: '',
  cityText: '',
  stateText: '',
  countyText: '',
  countryText: '',
  uploadFilesLabel: false,
  addr: [
    {
      id: '',
      addrTypeCd: '',
      addr1: '',
      city: '',
      stateProvCd: '',
      postalCode: '',
    },
  ],
};
export const anonymousReportClaimStepSixSlice = createSlice({
  name: 'anonymousReportClaimStepSixSlice',
  initialState,
  reducers: {
    resetStepSixState: () => {
      return initialState;
    },
    setClaimReferenceNumber: (state, action) => {
      return { ...state, referenceNumber: action.payload };
    },
    setClaimSubmit: (state, action) => {
      return { ...state, claimSubmission: action.payload };
    },
    setClaimEventContactsStepSix: (state, action) => {
      return { ...state, claimEventContacts: action.payload };
    },
    setClaimEventTemplatesStepSix: (state, action) => {
      return { ...state, claimEventTemplates: action.payload };
    },
    setValidateClaimStepSix: (state, action) => {
      return { ...state, validationClaim: action.payload };
    },
    setClaimEventValidatedStepSix: (state, action) => {
      return { ...state, isClaimEventValidated: action.payload };
    },
    setAddrTypeCdStepSix: (state, action) => {
      return { ...state, addr: mapAddrTypeCd(state, action) };
    },
    setSelectedLocationStepSix: (state, action) => {
      return { ...state, selectedLocation: action.payload };
    },
    setDateValueStepSix: (state, action) => {
      return { ...state, dateValue: action.payload };
    },
    setIdStepSix: (state, action) => {
      return { ...state, addr: mapId(state, action) };
    },
    setTimeValueStepSix: (state, action) => {
      return { ...state, timeValue: action.payload };
    },
    setSelectedStateStepSix: (state, action) => {
      return { ...state, selectedState: action.payload };
    },
    setZipStepSix: (state, action) => {
      return { ...state, addr: mapZip(state, action) };
    },
    setDescriptionStepSix: (state, action) => {
      return { ...state, descriptionText: action.payload };
    },
    setSelectedVehicleStepSix: (state, action) => {
      return { ...state, selectedVehicle: action.payload };
    },
    setSelectedDriverStepSix: (state, action) => {
      return { ...state, selectedDriver: action.payload };
    },
    setIsNonUSAddressStepSix: (state, action) => {
      return { ...state, isNonUSAddress: action.payload };
    },
    setLocation1StepSix: (state, action) => {
      return { ...state, addr: mapLocation1(state, action) };
    },
    setLocation2StepSix: (state, action) => {
      return { ...state, addr: mapLocation2(state, action) };
    },
    setLocation3StepSix: (state, action) => {
      return { ...state, addr: mapLocation3(state, action) };
    },
    setLocation4StepSix: (state, action) => {
      return { ...state, addr: mapLocation4(state, action) };
    },
    setCityStepSix: (state, action) => {
      return { ...state, addr: mapCity(state, action) };
    },
    setCountyTextStepSix: (state, action) => {
      return { ...state, countyText: action.payload };
    },
    setCountryTextStepSix: (state, action) => {
      return { ...state, countryText: action.payload };
    },
    setStateStepSix: (state, action) => {
      return { ...state, addr: mapState(state, action) };
    },
    setDriverNameManualStepSix: (state, action) => {
      return { ...state, manualDriverName: action.payload };
    },
    setLicenseNumberManualStepSix: (state, action) => {
      return { ...state, manualLicenseNumber: action.payload };
    },
    setClaimEventStepSix: (state, action) => {
      return { ...state, claimEvent: action.payload };
    },
    setClaimEventContactsErrorsStepSix: (state, action) => {
      return { ...state, claimEventContactsErrors: action.payload };
    },
    setClaimEventLoadingStepSix: (state, action) => {
      return { ...state, isClaimEventLoading: action.payload };
    },
    setClaimEventValidationErrors: (state, action) => {
      return { ...state, claimEventValidationErrors: action.payload };
    },
    setUploadFilesLabel: (state, action) => {
      return { ...state, uploadFilesLabel: action.payload };
    },
  },
});
export const {
  resetStepSixState,
  setClaimSubmit,
  setClaimReferenceNumber,
  setClaimEventContactsStepSix,
  setClaimEventTemplatesStepSix,
  setClaimEventValidationErrors,
  setValidateClaimStepSix,
  setClaimEventValidatedStepSix,
  setAddrTypeCdStepSix,
  setSelectedLocationStepSix,
  setSelectedStateStepSix,
  setIsNonUSAddressStepSix,
  setZipStepSix,
  setDescriptionStepSix,
  setIdStepSix,
  setLocation1StepSix,
  setLocation2StepSix,
  setLocation3StepSix,
  setLocation4StepSix,
  setCityStepSix,
  setCountyTextStepSix,
  setCountryTextStepSix,
  setStateStepSix,
  setDateValueStepSix,
  setTimeValueStepSix,
  setSelectedVehicleStepSix,
  setSelectedDriverStepSix,
  setDriverNameManualStepSix,
  setLicenseNumberManualStepSix,
  setClaimEventStepSix,
  setClaimEventLoadingStepSix,
  setClaimEventContactsErrorsStepSix,
  setUploadFilesLabel,
} = anonymousReportClaimStepSixSlice.actions;
export default anonymousReportClaimStepSixSlice.reducer;
