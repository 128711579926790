import React, { createRef } from 'react';
import { API } from '../../../utils/api/index';
import download from 'downloadjs';
import PropTypes from 'prop-types';
import { DocumentLink } from './styles';
import { gaEvent } from '../../../utils/google-analytics';
import { ga4Event } from '../../../utils/google-analytics-4';

export function AuthenticatedLink({ url, filename, children }) {
  const link = createRef();

  const handleAction = async () => {
    if (link.current.href) {
      return;
    }
    await API.get(url, {
      responseType: 'blob',
    })
      .then(response => {
        const content = response.headers['content-type'];
        download(response.data, filename, content);
      })
      .catch(error => console.log(error))
      .finally(() => {
        gaEvent({ category: 'request-id-cards', action: 'complete' });
        ga4Event({ category: 'request-id-cards', action: 'complete' });
      });
  };

  return (
    <>
      <DocumentLink role="button" ref={link} onClick={handleAction}>
        {children}
      </DocumentLink>
    </>
  );
}

AuthenticatedLink.propTypes = {
  url: PropTypes.string,
  filename: PropTypes.string,
  children: PropTypes.array,
};

export default AuthenticatedLink;
