let _helpers = {
  stripNumberFormatChars: function(value) {
    if (value) {
      // eslint-disable-next-line no-param-reassign, no-useless-escape
      value = value.toString().replace(/\$|\,|\%/g, '');
    }
    return value;
  },
  isNumber: function(n) {
    return !isNaN(parseFloat(n)) && isFinite(n);
  },
};

let _operations = {
  perform: function(lhs, operator, rhs) {
    if (lhs === '') return false;

    // Strip Number Format Characters
    // eslint-disable-next-line no-param-reassign
    lhs = _helpers.stripNumberFormatChars(lhs);
    // eslint-disable-next-line no-param-reassign
    rhs = _helpers.stripNumberFormatChars(rhs);

    //If both values are numbers, let's make 'em so before the comparison
    if (_helpers.isNumber(lhs) && _helpers.isNumber(rhs)) {
      // eslint-disable-next-line no-param-reassign
      lhs = parseFloat(lhs);
      // eslint-disable-next-line no-param-reassign
      rhs = parseFloat(rhs);
    }

    return _operations[operator](lhs, rhs);
  },
  '==': function(a, b) {
    // eslint-disable-next-line eqeqeq
    return a == b;
  },
  '===': function(a, b) {
    return a === b;
  },
  '>': function(a, b) {
    return a > b;
  },
  '>=': function(a, b) {
    return a >= b;
  },
  '<': function(a, b) {
    return a < b;
  },
  '<=': function(a, b) {
    return a <= b;
  },
};
export const _functions = {
  evalEquals: function(value1, value2) {
    return _operations.perform(value1, '===', value2);
  },
  evalEqualsIgnoreCase: function(value1, value2) {
    return _functions.evalEquals(value1.toUpperCase(), value2.toUpperCase());
  },
  evalNotEquals: function(value1, value2) {
    return !_functions.evalEquals(value1, value2);
  },
  evalGreaterThan: function(value1, value2) {
    return _operations.perform(value1, '>', value2);
  },
  evalGreaterThanOrEquals: function(value1, value2) {
    return _operations.perform(value1, '>=', value2);
  },
  evalLessThan: function(value1, value2) {
    return _operations.perform(value1, '<', value2);
  },
  evalLessThanOrEquals: function(value1, value2) {
    return _operations.perform(value1, '<=', value2);
  },
  evalInList: function(value, array) {
    return array.indexOf(value) >= 0;
  },
  evalBetween: function(value, boundaries) {
    var isBetween = false;

    // eslint-disable-next-line no-param-reassign
    value = _helpers.stripNumberFormatChars(value);
    var lowerBoundary = _helpers.stripNumberFormatChars(boundaries[0]);
    var upperBoundary = _helpers.stripNumberFormatChars(boundaries[1]);

    if (
      _helpers.isNumber(value) &&
      _helpers.isNumber(lowerBoundary) &&
      _helpers.isNumber(upperBoundary)
    ) {
      var valueNum = parseFloat(value);
      var lowerBoundaryNum = parseFloat(lowerBoundary);
      var upperBoundaryNum = parseFloat(upperBoundary);

      isBetween = lowerBoundaryNum <= valueNum && valueNum <= upperBoundaryNum;
    }
    return isBetween;
  },
};
export const _isJavaScript = value => {
  // eslint-disable-next-line eqeqeq
  return value && value.toLowerCase().indexOf('javascript:') == 0;
};
export const _functionName = value => {
  //value resembles : javascript:evalBetween(parentValue,5,10)
  //we want to return 'evalBetween'

  var start = value.indexOf(':') + 1;
  var end = value.indexOf('(');

  var retVal = value.slice(start, end);

  return retVal;
};

export const _functionParms = value => {
  //value resembles : javascript:evalBetween(parentValue, 5, 10)
  //             OR : javascript:evalBetween(parentValue, 'Yes')
  //we want to return [5,10] or Yes

  var regex = new RegExp("^'|'$", 'g'); //single-quotes (leading & trailing)

  var start = value.indexOf(',') + 1; //position of the 5
  var end = value.indexOf(')');

  var parmString = value
    .slice(start, end) // results in : 5, 10 or 'Yes'
    .replace(regex, ''); // results in : 5, 10 or Yes

  var parms = parmString.split(',');

  // eslint-disable-next-line eqeqeq
  return parms.length == 1 ? parms[0] : parms;
};
