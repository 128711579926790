import React from 'react';
import {
  AnnouncementsContainer,
  AnnouncementsHeader,
  NewsBannerContainer,
  NewsBannerTile,
  NewsBannerTileTemplate2,
  ScrollableContainer,
  TemplateOnePanel,
} from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const NewsBanners = props => {
  const { newsBanners, template } = props;

  return (
    <>
      {newsBanners && newsBanners.length > 0 ? (
        <>
          {template === 'template2' ? (
            <AnnouncementsContainer>
              <AnnouncementsHeader>Announcements</AnnouncementsHeader>
              {newsBanners.map(newsBannerItem => {
                return (
                  <NewsBannerTile key={newsBannerItem.ref}>
                    <div
                      dangerouslySetInnerHTML={{ __html: newsBannerItem.newsBanner.htmlContent }}
                    />
                  </NewsBannerTile>
                );
              })}
            </AnnouncementsContainer>
          ) : (
            <>
              <NewsBannerContainer>
                <TemplateOnePanel>Announcements</TemplateOnePanel>
                <ScrollableContainer>
                  {newsBanners.map(newsBannerItem => {
                    return (
                      <NewsBannerTileTemplate2
                        key={newsBannerItem.ref}
                        dangerouslySetInnerHTML={{ __html: newsBannerItem.newsBanner.htmlContent }}
                      />
                    );
                  })}
                </ScrollableContainer>
              </NewsBannerContainer>
            </>
          )}
        </>
      ) : null}
    </>
  );
};

NewsBanners.propTypes = {
  newsBanners: PropTypes.array.isRequired,
  dispatch: PropTypes.func.isRequired,
  template: PropTypes.string.isRequired,
};

export const mapStateToProps = state => ({
  newsBanners: state.newsBannersSlice.newsBanners,
  template: state.configurationSlice.features.template,
});

export default connect(mapStateToProps)(NewsBanners);
