import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPrimaryPhoneInfo, getSecondaryPhoneInfo } from '../user-profile/selectors';
import EditingPhones from './edit-phone';
import { setEditingPhone, setEmailErrors, setPhoneErrors } from '../user-profile/profileReducer';
import {
  EditPhoneButtonContainer,
  EditPhonesLink,
  PhoneDescription,
  PhoneNumberContainer,
  PhoneNumbersContainer,
  PhoneNumbersLabel,
  ProfileError,
  ProfilePhoneNumberLabel,
  StyledSecondaryPhoneContainer,
} from './styles';
import { getText } from '../../../utils/i18n';
import TooltipInfo from '../../common/tooltip';

export const PhoneDetails = (props) => {
  const {
    ariaLabel,
    editingEmailAndPhone,
    isDashboard,
    phoneErrors,
    phoneNumberPrimary,
    phoneNumberSecondary,
    primaryPhoneNameType,
    secondaryPhoneNameType,
  } = props;

  React.useEffect(() => {
    props.dispatch(setEmailErrors(null));
    props.dispatch(setPhoneErrors(null));
  }, []);

  return (
    <>
      {phoneErrors && <ProfileError>{phoneErrors}</ProfileError>}
      <PhoneNumberContainer>
        {phoneNumberPrimary && (
          <>
            <>
              <PhoneNumbersLabel>Phone Numbers</PhoneNumbersLabel>
              <PhoneNumbersContainer>
                Primary Phone {phoneNumberPrimary} ({primaryPhoneNameType})
                <StyledSecondaryPhoneContainer>
                  {phoneNumberSecondary && (
                    <>
                      Secondary Phone {phoneNumberSecondary} ({secondaryPhoneNameType})
                    </>
                  )}
                </StyledSecondaryPhoneContainer>
              </PhoneNumbersContainer>
            </>
          </>
        )}
        {editingEmailAndPhone ? (
          <EditingPhones isDashboard={isDashboard} ariaLabel={ariaLabel} />
        ) : null}
      </PhoneNumberContainer>
    </>
  );
};

PhoneDetails.propTypes = {
  ariaLabel: PropTypes.string,
  dispatch: PropTypes.func,
  editingEmailAndPhone: PropTypes.bool,
  isDashboard: PropTypes.bool,
  notificationPhone: PropTypes.string,
  phoneErrors: PropTypes.any,
  phoneNumberPrimary: PropTypes.string,
  phoneNumberSecondary: PropTypes.string,
  primaryPhoneNameType: PropTypes.string,
  secondaryPhoneNameType: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  phoneErrors: state.profileSlice.phoneErrors,
  editingEmailAndPhone: state.profileSlice.editingEmailAndPhone,
  notificationPhone: state.notificationSlice.notificationPhone,
  phoneNumberPrimary: getPrimaryPhoneInfo(state).phoneNumber,
  phoneNumberSecondary: getSecondaryPhoneInfo(state).phoneNumber,
  primaryPhoneNameType: getPrimaryPhoneInfo(state).phoneName,
  secondaryPhoneNameType: getSecondaryPhoneInfo(state).phoneName,
  i18n: state.i18nSlice.i18n,
});
PhoneDetails.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(PhoneDetails);
