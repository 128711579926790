import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  customerSubscriptions: null,
  customerSubscriptionsFull: null,
  notificationEmail: '',
  notificationPhone: '',
  consent: false,
  isLoading: false,
  isSubscribedSMS: false,
  isSubscribedEmail: true,
  userTimezonePreference: '',
};

export const notificationSlice = createSlice({
  name: 'notificationSlice',
  initialState,
  reducers: {
    setCustomerSubscriptions: (state, action) => {
      return { ...state, customerSubscriptions: action.payload };
    },
    setCustomerSubscriptionsFull: (state, action) => {
      return { ...state, customerSubscriptionsFull: action.payload };
    },
    setIsSubscribedEmail: (state, action) => {
      return { ...state, isSubscribedEmail: action.payload };
    },
    setIsSubscribedSMS: (state, action) => {
      return { ...state, isSubscribedSMS: action.payload };
    },
    setNotificationPhone: (state, action) => {
      return { ...state, notificationPhone: action.payload };
    },
    setNotificationEmail: (state, action) => {
      return { ...state, notificationEmail: action.payload };
    },
    setConsent: (state, action) => {
      return { ...state, consent: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setTimezone: (state, action) => {
      return { ...state, userTimezonePreference: action.payload };
    },
  },
});

export const {
  setCustomerSubscriptions,
  setCustomerSubscriptionsFull,
  setIsSubscribedEmail,
  setIsSubscribedSMS,
  setLoading,
  setNotificationEmail,
  setNotificationPhone,
  setTimezone,
} = notificationSlice.actions;
export default notificationSlice.reducer;
