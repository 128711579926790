import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import AddVehicle from './add-vehicle';
import AddPropertyMortgagee from './add-property-mortgagee';
import ChangeBillingMailingAddress from './change-billing-mailing-address';
import ChangePropertyMortgagee from './change-property-mortgagee';
import RemovePropertyMortgagee from './remove-property-mortgagee';
import AddVehicleFinanceCompany from './add-vehicle-finance-company';
import ChangeVehicleFinanceCompany from './change-vehicle-finance-company';
import RemoveVehicleFinanceCompany from './remove-vehicle-finance-company';
import ChangePropertyDeductible from './change-property-deductible';
import AddDriver from './add-driver';
import RequestNewPropertyCoverage from './new-property-coverage';
import RemoveVehicle from './remove-vehicle';
import RemoveDriver from './remove-driver';
import Other from './other';
import ChangeContactInformation from './change-contact-information';
import TemplateBased from './template-based';

export const ChangeEventComponent = props => {
  const { selectedPolicyChangeTemplate } = props;
  let component = null;
  if (!selectedPolicyChangeTemplate) {
    return component;
  }

  switch (selectedPolicyChangeTemplate.typeCd) {
    case 'AddVehicle':
      component = <AddVehicle {...props} />;
      break;
    case 'RemoveVehicle':
      component = <RemoveVehicle {...props} />;
      break;
    case 'AddPropertyMortgagee':
      component = <AddPropertyMortgagee {...props} />;
      break;
    case 'PolicyChangeAddress':
      component = <ChangeBillingMailingAddress {...props} />;
      break;
    case 'ChangePropertyMortgagee':
      component = <ChangePropertyMortgagee {...props} />;
      break;
    case 'RemovePropertyMortgagee':
      component = <RemovePropertyMortgagee {...props} />;
      break;
    case 'AddVehicleFinanceCompany':
      component = <AddVehicleFinanceCompany {...props} />;
      break;
    case 'AddLossPayee':
      component = <AddVehicleFinanceCompany {...props} isLossPayee={true} />;
      break;
    case 'ChangeVehicleFinanceCompany':
      component = <ChangeVehicleFinanceCompany {...props} />;
      break;
    case 'ChangeLossPayee':
      component = <ChangeVehicleFinanceCompany {...props} isLossPayee={true} />;
      break;
    case 'RemoveVehicleFinanceCompany':
      component = <RemoveVehicleFinanceCompany {...props} />;
      break;
    case 'RemoveLossPayee':
      component = <RemoveVehicleFinanceCompany {...props} isLossPayee={true} />;
      break;
    case 'OtherWithPolicySelection':
      component = <ChangePropertyDeductible {...props} />;
      break;
    case 'AddDriver':
      component = <AddDriver {...props} />;
      break;
    case 'RemoveDriver':
      component = <RemoveDriver {...props} />;
      break;
    case 'ChangeOtherReason':
      component = <Other {...props} />;
      break;
    case 'Other':
      component = <RequestNewPropertyCoverage {...props} />;
      break;
    case 'PolicyChangePolicyContactInfo':
      component = <ChangeContactInformation {...props} />;
      break;
    default:
      component = <TemplateBased {...props} />;
  }
  return component;
};
ChangeEventComponent.propTypes = {
  selectedPolicyChangeTemplate: PropTypes.object,
};
const mapStateToProps = state => ({
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
});
export default connect(mapStateToProps)(ChangeEventComponent);
