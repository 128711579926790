import { setErrors, setLoading, setPolicies } from './policyReducer';
import { API } from '../../../../utils/api';
import moment from 'moment';

const policyCache = new Map();

const getCurrentPolicyTerm = (policyRef, dispatch) => {
  dispatch(setLoading(true));
  return API.get(`policies/${policyRef}`)
    .then(response => {
      return response.data;
    })
    .catch(err => dispatch(setErrors(err.message)))
    .finally(() => {
      dispatch(setLoading(false));
    });
};

const mapBasicPolicyData = (mappedPolicy, basicPolicy) => {
  mappedPolicy.providerRef = basicPolicy.providerRef;
  mappedPolicy.policyNumber = basicPolicy.policyNumber;
  mappedPolicy.effectiveDt = basicPolicy.effectiveDt;
  mappedPolicy.expirationDt = basicPolicy.expirationDt;
  mappedPolicy.statusCd = basicPolicy.statusCd;
  mappedPolicy.displayDescription = basicPolicy.displayDescription;
  mappedPolicy.description = basicPolicy.description;
};

const updatePoliciesWithCurrentTerm = (policies, dispatch, policyAddressMap) => {
  let policyPromises = policies.map(async policyPromise => {
    const effectiveDate = moment(policyPromise.policyMini.basicPolicy.effectiveDt);
    const today = moment();
    if (effectiveDate.isAfter(today)) {
      policyPromise.isCurrentTerm = false;
      // eslint-disable-next-line no-param-reassign
      policyPromise = await getCurrentPolicyTerm(policyPromise.ref, dispatch);
    } else {
      policyPromise.isCurrentTerm = true;
    }
    return policyPromise;
  });
  Promise.all(policyPromises)
    .then(values => {
      let updatedPolicies = [];
      // iterate over all policies
      for (let i = 0; i < policies.length; i++) {
        const latestTermPolicyResponse = policies[i];
        const currentTermPolicyResponse = values[i];
        let latestTermPolicy = {};
        latestTermPolicy.policyRef = latestTermPolicyResponse.ref;
        latestTermPolicy.productName = latestTermPolicyResponse.productName;
        mapBasicPolicyData(latestTermPolicy, latestTermPolicyResponse.policyMini.basicPolicy);
        latestTermPolicy.insured = latestTermPolicyResponse.policyMini.insured;
        latestTermPolicy._revision = null;
        const latestTermPolicyFromCache = policyAddressMap.get(latestTermPolicy.policyRef)
        latestTermPolicy.policyAddress = latestTermPolicyFromCache.policyAddress;
        latestTermPolicy.lineCd = latestTermPolicyFromCache.lineCd;
        
        const isDuplicateFutureTerm =
          latestTermPolicyResponse.ref === currentTermPolicyResponse.systemId;
        if (!latestTermPolicyResponse.isCurrentTerm && !isDuplicateFutureTerm) {
          // if policy is effective in the future, add the current term policy
          const currentTermPolicy = {};
          currentTermPolicy.policyRef = currentTermPolicyResponse.systemId;
          currentTermPolicy.productName = latestTermPolicy.productName;
          const miniBasicPolicy = values[i]?.policyMini?.basicPolicy;
          mapBasicPolicyData(
            currentTermPolicy,
            miniBasicPolicy ? miniBasicPolicy : currentTermPolicyResponse.basicPolicy,
          );
          currentTermPolicy.insured = currentTermPolicyResponse.insured;
          currentTermPolicy._revision = currentTermPolicyResponse._revision;
          const currentTermPolicyFromCache = policyAddressMap.get(latestTermPolicy.policyRef)
          currentTermPolicy.policyAddress = currentTermPolicyFromCache.policyAddress;
          currentTermPolicy.lineCd = currentTermPolicyFromCache.lineCd;
          updatedPolicies.push(currentTermPolicy);
        }

        updatedPolicies.push(latestTermPolicy);
      }
      dispatch(setPolicies(updatedPolicies));
    })
    .catch(err => {
      dispatch(setErrors(err.message));
    });
};

const getDisplayAddress = address => {
  const { addr1, addr2, city, postalCode, stateProvCd } = address;
  return `${addr1}${
    addr2 ? ', ' + addr2 : ''
  }, ${city}, ${stateProvCd}, ${postalCode}`;
}

const getAddressForDashboard = (lines, mailingAddress) => {

  // Using Set() to eliminate duplicate addresses
  const buildingAddresses = new Set();
  const vehicleAddresses = new Set();
  if (lines && lines.length > 0) {
    lines.forEach(line => {
      const risks = line.risks;
      if (risks && risks.length > 0) {
        risks.forEach(risk => {
          risk?.building?.addresses?.forEach(address => {
            if (address.addrTypeCd === 'RiskAddr' && address.addr1) {
              buildingAddresses.add(getDisplayAddress(address));
            }
          });
          risk?.vehicle?.addresses?.forEach(address => {
            if (address.addrTypeCd === 'VehicleGarageAddr') {
              // if garage address is present, use that
              if (address.addr1) {
                vehicleAddresses.add(getDisplayAddress(address));
              } else {
                // else use mailing address
                vehicleAddresses.add(getDisplayAddress(mailingAddress));
              }
            }
          });
        });
      }
    });
  }

  let locationAddress = '';

  if (buildingAddresses.size === 1 && vehicleAddresses.size === 0) {
    // only one building address => building address
    locationAddress = buildingAddresses.values().next().value;
  } else if (buildingAddresses.size === 0 && vehicleAddresses.size === 1) {
    // only one vehicle garage address => garage address
    locationAddress = vehicleAddresses.values().next().value;
  } else if (buildingAddresses.size > 1 && vehicleAddresses.size === 0) {
    // multiple building addresses only => "Multiple Locations"
    locationAddress = 'Multiple Locations';
  } else if (buildingAddresses.size === 0 && vehicleAddresses.size > 1) {
    // multiple vehicle garage addresses only => "Multiple Risks"
    locationAddress = 'Multiple Garage Locations';
  } else if (buildingAddresses.size > 0 && vehicleAddresses.size > 0) {
    // one or more buildings and one or more vehicle => "Multiple Risks"
    locationAddress = 'Multiple Risks';
  }
  return locationAddress;
};

const getMailingAddress = (policy) => {
  const addresses = policy?.policyMini?.insured?.partyInfo?.filter(
    (partyInfoItem) => partyInfoItem.partyTypeCd === 'InsuredParty',
  )?.[0]?.addresses;
  if (addresses && addresses.length > 0) {
    return addresses?.find((address) => address?.addrTypeCd === 'InsuredMailingAddr') || {};
  }
  return {};
};

const updatePoliciesWithLocationAddresses = policies => {
  return policies.map(async policy => {
    return await API.get(`policies/${policy.ref}/full`)
      .then(response => {
        const lines = response?.data?.lines;
        const mailingAddress = getMailingAddress(policy);
        let locationAddress = getAddressForDashboard(lines, mailingAddress);
        return { ref: policy.ref, policyAddress: locationAddress, lineCd: lines[0].lineCd }; //V3 only ever grabbed first line code
      })
      .catch(() => {
        return { ref: policy.ref, policyAddress: '' };
      });
  });
};

export const getPoliciesWithLatestTerm = (customerRef, createdSinceDate, policiesCountLimit) => {
  return dispatch => {
    dispatch(setPolicies([]));
    dispatch(setLoading(true));
    if (API) {
      return (
        API.get(`policies?customerId=${customerRef}&createdSinceDt=${createdSinceDate}&limit=${policiesCountLimit}`)
          // get all policies with the latest term for the customer

          .then(response => {
            if (response.data.policyListItems === undefined || response.data.policyListItems.length === 0) {
              dispatch(setPolicies([]));
              dispatch(setLoading(false));
              return;
            }
            policyCache.clear();
            let latestPolicies = response.data.policyListItems;
            let productMap = new Map();
            let policiesWithProductName;

            const locationAddressPromises = updatePoliciesWithLocationAddresses(
              response.data.policyListItems,
            );
            const policyAddressMap = new Map();
            Promise.allSettled(locationAddressPromises).then(locationAddressResponse => {
              locationAddressResponse.forEach(policy => {
                policyAddressMap.set(policy.value.ref, { policyAddress: policy.value.policyAddress, lineCd: policy.value.lineCd });
              });



              let productNamePromises = response.data.policyListItems.map(policy => {
                return API.get(
                  `products?productVersionRefId=${policy.policyMini.basicPolicy.productVersionIdRef}`,
                )
                  .then(response => {
                    // create a map of productVersionIdRef => productName
                    response.data.productListItems.forEach(product => {
                      const productId = product.id;
                      const productName = product.productName;
                      if (!productMap.has(productId)) {
                        productMap.set(productId, productName);
                      }
                    });

                    policiesWithProductName = latestPolicies.map(policy => {
                      policyCache.set(policy.ref, policy);
                      const productName = productMap.get(
                        policy.policyMini.basicPolicy.productVersionIdRef,
                      );
                      return {
                        ...policy,
                        productName,
                      };
                    });

                    return policiesWithProductName;
                  })
                  .catch(err => dispatch(setErrors(err.message)))
                  .finally(() => dispatch(setLoading(false)));
              });
              Promise.allSettled(productNamePromises).then(responses => {
                // look for the response which contains product name for all policies
                const responseWithAllProductNames = responses.filter(
                  response =>
                    response.value.filter(value => value.productName === undefined).length === 0,
                )[0].value;

                // update with current terms
                updatePoliciesWithCurrentTerm(
                  responseWithAllProductNames,
                  dispatch,
                  policyAddressMap,
                );
              });
            });
          })
          .catch(err => dispatch(setErrors(err.message)))
      );
    }
  };
};
