import { isPhone } from '../../../utils/validators/generalValidators';
import { setPhoneErrors } from '../user-profile/profileReducer';
import { patchCustomerDetails } from './profile-api';

const validatePhones = (
  dispatch,
  primaryPhone,
  secondaryPhone,
  primaryPhoneType,
  secondaryPhoneType,
  oldSecondaryPhoneNumber,
  oldSecondaryPhoneType,
  oldPrimaryPhoneNumber,
  oldPrimaryPhoneType,
) => {
  const newErrors = [];
  const phoneTypes = ['Mobile', 'Home', 'Business', 'Other'];
  if (!isPhone(primaryPhone)) {
    newErrors.push('Please enter a valid primary phone number.');
  }
  if (!phoneTypes.includes(primaryPhoneType)) {
    newErrors.push('Please select primary phone number type.');
  }
  if (
    primaryPhone === oldPrimaryPhoneNumber &&
    primaryPhoneType === oldPrimaryPhoneType &&
    secondaryPhone === oldSecondaryPhoneNumber &&
    secondaryPhoneType === oldSecondaryPhoneType
  ) {
    newErrors.push('Please select a new phone type or enter a new phone number');
  }

  if (newErrors.length !== 0) {
    dispatch(setPhoneErrors(newErrors[0]));
  }

  return newErrors.length === 0;
};
export const savePhones = (
  props,
  newPrimaryPhone,
  newSecondaryPhone,
  primaryPhoneSelect,
  secondaryPhoneSelect,
  oldSecondaryPhoneNumber,
  oldSecondaryPhoneType,
  oldPrimaryPhoneNumber,
  oldPrimaryPhoneType,
  newEmailAddress,
) => {
  return async (dispatch) => {
    const {
      customerParty,
      customer,
      emailId,
      oldEmailAddress,
      phoneNumberPrimaryId,
      phoneNumberSecondaryId,
      systemId,
    } = props;
    if (
      validatePhones(
        dispatch,
        newPrimaryPhone,
        newSecondaryPhone,
        primaryPhoneSelect,
        secondaryPhoneSelect,
        oldSecondaryPhoneNumber,
        oldSecondaryPhoneType,
        oldPrimaryPhoneNumber,
        oldPrimaryPhoneType,
      ) ||
      newEmailAddress !== oldEmailAddress
    ) {
      let body = {
        partyInfo: [
          {
            id: customerParty.id,
            phoneInfo: [],
            emailInfo: {
              emailAddr: newEmailAddress,
              id: emailId,
            },
          },
        ],
        _revision: customer._revision,
      };
      if (newPrimaryPhone !== oldPrimaryPhoneNumber || primaryPhoneSelect !== oldPrimaryPhoneType) {
        body.partyInfo[0].phoneInfo.push({
          id: phoneNumberPrimaryId,
          phoneNumber: newPrimaryPhone,
          phoneName: primaryPhoneSelect,
        });
      }
      if (
        newSecondaryPhone !== oldSecondaryPhoneNumber ||
        secondaryPhoneSelect !== oldSecondaryPhoneType
      ) {
        body.partyInfo[0].phoneInfo.push({
          id: phoneNumberSecondaryId,
          phoneNumber: newSecondaryPhone,
          phoneName: secondaryPhoneSelect ? secondaryPhoneSelect : '',
        });
      }
      if (
        newPrimaryPhone !== oldPrimaryPhoneNumber ||
        newSecondaryPhone !== oldSecondaryPhoneNumber ||
        primaryPhoneSelect !== oldPrimaryPhoneType ||
        secondaryPhoneSelect !== oldSecondaryPhoneType ||
        newEmailAddress !== oldEmailAddress
      ) {
        await dispatch(patchCustomerDetails(systemId, body));
      }
    }
  };
};
