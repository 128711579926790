import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import {
  DateRowHeaderLabel,
  DescriptionRowHeaderLabel,
  DetailsRowHeaderLabel,
  HorizontalLine,
  LoaderMargin,
  MobileRowHeaderLabel,
  PendingRequestDescription,
  PendingRequestEffectiveDate,
  PendingRequestsButton,
  PendingRequestsButtonsContainer,
  PendingRequestsContainer,
  PendingRequestsDataContainer,
  PendingRequestsDataRowContainer,
  PendingRequestsHeader,
  PendingRequestsRowHeaderContainer,
  RequestNote,
  StyledNoRequests,
} from './styles';
import PropTypes from 'prop-types';
import { getPendingRequests } from './pendingRequestsAPI';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Loader from '../../common/loader';
import { resetState } from './pendingRequestsReducer';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import ApiError from '../../common/api-error';

export const PendingRequests = props => {
  const { customerId, isLoading, errors, pendingRequests } = props;
  useEffect(() => {
    props.dispatch(resetState());
    props.dispatch(getPendingRequests(customerId));
  }, []);
  return (
    <>
      <Helmet>
        <title>Pending Requests</title>
      </Helmet>
      <PendingRequestsContainer>
        <PendingRequestsHeader>Pending Requests</PendingRequestsHeader>
        <PendingRequestsRowHeaderContainer>
          <DateRowHeaderLabel>Date</DateRowHeaderLabel>
          <DescriptionRowHeaderLabel>Description</DescriptionRowHeaderLabel>
          <DetailsRowHeaderLabel>Details</DetailsRowHeaderLabel>
        </PendingRequestsRowHeaderContainer>
        {isLoading ? (
          <LoaderMargin>
            <Loader />
          </LoaderMargin>
        ) : errors ? (
          <ApiError body="There was an error loading your pending requests." variant="nomargin" />
        ) : pendingRequests.length > 0 ? (
          pendingRequests.map(task => (
            <PendingRequestsDataRowContainer key={task.systemId}>
              <PendingRequestsDataContainer>
                <MobileRowHeaderLabel>Date</MobileRowHeaderLabel>
                <PendingRequestEffectiveDate>
                  {moment(task.workDt).format(DISPLAY_DATE_FORMAT)}
                </PendingRequestEffectiveDate>
                <MobileRowHeaderLabel>Description</MobileRowHeaderLabel>
                <PendingRequestDescription>{task.description}</PendingRequestDescription>
                <MobileRowHeaderLabel>Details</MobileRowHeaderLabel>
                <RequestNote>{task.note}</RequestNote>
              </PendingRequestsDataContainer>
              <HorizontalLine />
            </PendingRequestsDataRowContainer>
          ))
        ) : (
          <StyledNoRequests>
            {' '}
            There are no change requests outstanding at this time.
          </StyledNoRequests>
        )}
        <PendingRequestsButtonsContainer>
          <Link to="/dashboard">
            <PendingRequestsButton>Return Home</PendingRequestsButton>
          </Link>
        </PendingRequestsButtonsContainer>
      </PendingRequestsContainer>
    </>
  );
};

PendingRequests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  customerId: PropTypes.string,
  pendingRequests: PropTypes.array,
  isLoading: PropTypes.bool,
  errors: PropTypes.string,
};

const mapStateToProps = state => ({
  customerId: state.customerSlice.customer.systemId,
  pendingRequests: state.pendingRequestsSlice.pendingRequests,
  isLoading: state.pendingRequestsSlice.isLoading,
  errors: state.pendingRequestsSlice.errors,
  state,
});

export default connect(mapStateToProps)(PendingRequests);
