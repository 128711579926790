import { initialize, event, pageview, ga } from 'react-ga';

export const initializeGoogleAnalytics = (keys, companyName) => {
  let isProd = false;
  if (process && process.env && process.env.NODE_ENV) {
    isProd = process.env.NODE_ENV !== 'development';
  }

  if (keys) {
    let trackingCode = '';
    if (isProd && keys.googleAnalyticsGwProd) {
      trackingCode = keys.googleAnalyticsGwProd;
    }

    if (!isProd && keys.googleAnalyticsGwDev) {
      trackingCode = keys.googleAnalyticsGwDev;
    }

    // don't send client GA analytics in preprod envs
    if (isProd && keys.googleAnalytics) {
      trackingCode = keys.googleAnalytics;
    }

    const gaOptions = {
      name: process.env.APP_NAME,
      clientId: companyName,
    };

    if (trackingCode !== '') {
      initialize(trackingCode, { gaOptions });
    }
  }
};

export const gaPageView = path => {
  if (ga()) {
    pageview(path);
  }
};

export const gaEvent = eventBody => {
  if (ga()) {
    event(eventBody);
  }
};
