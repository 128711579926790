import React from 'react';
import { connect } from 'react-redux';
import {
  MessageBody,
  MessageContainer,
  MessageTitleContainer,
  MessageTitle,
  SidepanelContainer,
} from './styles';
import PropTypes from 'prop-types';
import Loader from '../../common/loader';
import { getText } from '../../../utils/i18n';
const SidePanel = props => {
  const { i18n, features } = props;
  return (
    <>
      <SidepanelContainer>
        {Object.keys(i18n).length !== 0 ? (
          <>
            <MessageContainer>
              <MessageTitleContainer>
                <MessageTitle
                  dangerouslySetInnerHTML={{
                    __html: getText(
                      i18n,
                      'signin.helper.title',
                      'Welcome to the New Service Portal',
                    ),
                  }}
                />
              </MessageTitleContainer>
              <MessageBody
                dangerouslySetInnerHTML={{
                  __html: getText(i18n, 'signin.helper.body', ''),
                }}
              />
              {features.features.passwordResetInfo && (
                <MessageBody
                  dangerouslySetInnerHTML={{
                    __html: getText(i18n, 'signin.helper.resetPasswordNotice', ''),
                  }}
                />
              )}
            </MessageContainer>
          </>
        ) : (
          <Loader />
        )}
      </SidepanelContainer>
    </>
  );
};

SidePanel.propTypes = {
  i18n: PropTypes.any,
  features: PropTypes.any,
};

export const mapStateToProps = state => ({
  features: state.configurationSlice.features,
  isServiceUnavailable: state.configurationSlice.isServiceUnavailable,
  userId: state.loginSlice.user ? state.loginSlice.user.preferred_username : '',
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(SidePanel);
