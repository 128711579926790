import { createField, formatRecapFieldDate } from '../selector';

export const YES_NO_DEFAULT = 'No';

export const getFieldsForVehicleWithVIN = (vehicleModel, vin) => {
  let trim;
  if (vehicleModel.label) {
    trim = vehicleModel.label;
  } else if (vehicleModel.engineSize && vehicleModel.engineCylinders) {
    const vinText = vehicleModel.vinPrefix ? `- Vin: ${vehicleModel.vinPrefix}` : '';
    trim = `${vehicleModel.model} - Engine Size: ${vehicleModel.engineSize} - ${vehicleModel.engineCylinders} ${vinText}}`;
  } else {
    trim = vehicleModel.model;
  }
  return [
    {
      vehIdentificationNumber: vin, //'YV4162XZ5L1234567',
      manufacturer: vehicleModel.manufacturer,
      model: vehicleModel.model,
      modelYr: vehicleModel.modelYr.toString(),
      trim: trim,
    },
  ];
};
export const createPolicyChangeCoverageSummary = (
  effectiveDate,
  reason,
  changeName,
  vehicleModel,
  questions,
  answers,
) => {
  let trim;
  if (vehicleModel.label) {
    trim = vehicleModel.label;
  } else if (vehicleModel.engineSize !== undefined && vehicleModel.engineCylinders !== undefined) {
    trim = `${vehicleModel.model} - Engine Size: ${vehicleModel.engineSize} - ${vehicleModel.engineCylinders}`;
  } else {
    trim = vehicleModel.model;
  }
  const fields = [];
  fields.push(createField('Change Requested', changeName, 'text', false));
  fields.push(createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'));
  fields.push(createField('Reason', reason, 'text'));
  fields.push(createField('Car Year', vehicleModel.modelYr, 'text'));
  fields.push(createField('Car Make', vehicleModel.manufacturer, 'text'));
  fields.push(createField('Car Model', trim, 'text'));
  if (vehicleModel.vinPrefix) {
    fields.push(createField('Car VIN', vehicleModel.vinPrefix, 'text'));
  }

  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers[i].answer;
    let value;
    if (question.dataType === 'YesNo') {
      value = answer;
    } else if (question.dataType === 'List') {
      value = answer;
    } else if (question.dataType === 'Numeric') {
      value = answer.toString();
    } else if (question.dataType === 'TextArea') {
      value = answer;
    }
    fields.push(createField(question.text, value, 'text'));

    if (
      question.questions &&
      question.questions.question &&
      question.questions.question.length > 0 &&
      answers[i].subQuestionAnswers
    ) {
      for (let j = 0; j < question.questions.question.length; j++) {
        const subQuestion = question.questions.question[j];
        const subAnswer = answers[i].subQuestionAnswers[j];
        let subValue;
        if (subQuestion.dataType === 'YesNo') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'List') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'Numeric') {
          subValue = subAnswer.toString();
        } else if (subQuestion.dataType === 'TextArea') {
          subValue = subAnswer;
        }
        fields.push(createField(subQuestion.text, subValue, 'text'));
      }
    }
  }
  return fields;
};
