import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  selectedLocation: {
    riskAddress: '',
    label: '',
    value: '',
  },
  selectedState: {},
  selectedVehicle: {},
  selectedDriver: {},
  manualDriverName: '',
  driverRelationshipSelected: '',
  manualLicenseNumber: '',
  isNonUSAddress: false,
  dateValue: '',
  timeValue: '',
  zipText: '',
  descriptionText: '',
  location1Text: '',
  location2Text: '',
  location3Text: '',
  location4Text: '',
  cityText: '',
  stateText: '',
  countyText: '',
  countryNonUs: '',
  addr: [
    {
      id: '',
      addrTypeCd: '',
      addr1: '',
      city: '',
      stateProvCd: '',
      postalCode: '',
    },
  ],
};
let mapLocation1 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr1: action.payload };
  });
};
let mapLocation2 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr2: action.payload };
  });
};
let mapLocation3 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr3: action.payload };
  });
};
let mapLocation4 = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addr4: action.payload };
  });
};
let mapAddrTypeCd = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], addrTypeCd: action.payload };
  });
};
let mapId = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], id: action.payload };
  });
};
let mapCity = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], city: action.payload };
  });
};
let mapState = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], stateProvCd: action.payload };
  });
};
let mapZip = (state, action) => {
  return state.addr.map(() => {
    return { ...state.addr[0], postalCode: action.payload };
  });
};

export const reportClaimStepTwoSlice = createSlice({
  name: 'reportClaimStepTwoSlice',
  initialState,
  reducers: {
    resetStepTwoState: () => {
      return initialState;
    },
    setAddrTypeCd: (state, action) => {
      return { ...state, addr: mapAddrTypeCd(state, action) };
    },
    setSelectedLocation: (state, action) => {
      return { ...state, selectedLocation: action.payload };
    },
    setDateValue: (state, action) => {
      return { ...state, dateValue: action.payload };
    },
    setId: (state, action) => {
      return { ...state, addr: mapId(state, action) };
    },
    setTimeValue: (state, action) => {
      return { ...state, timeValue: action.payload };
    },
    setSelectedState: (state, action) => {
      return { ...state, selectedState: action.payload };
    },
    setZip: (state, action) => {
      return { ...state, addr: mapZip(state, action) };
    },
    setDescription: (state, action) => {
      return { ...state, descriptionText: action.payload };
    },
    setSelectedDriverRelationship: (state, action) => {
      return { ...state, driverRelationshipSelected: action.payload };
    },
    setSelectedVehicle: (state, action) => {
      return { ...state, selectedVehicle: action.payload };
    },
    setSelectedDriver: (state, action) => {
      return { ...state, selectedDriver: action.payload };
    },
    setIsNonUSAddress: (state, action) => {
      return { ...state, isNonUSAddress: action.payload };
    },
    setLocation1: (state, action) => {
      return { ...state, addr: mapLocation1(state, action) };
    },
    setLocation2: (state, action) => {
      return { ...state, addr: mapLocation2(state, action) };
    },
    setLocation3: (state, action) => {
      return { ...state, addr: mapLocation3(state, action) };
    },
    setLocation4: (state, action) => {
      return { ...state, addr: mapLocation4(state, action) };
    },
    setCountryNonUs: (state, action) => {
      return { ...state, countryNonUs: action.payload };
    },
    setCity: (state, action) => {
      return { ...state, addr: mapCity(state, action) };
    },
    setCountyText: (state, action) => {
      return { ...state, countyText: action.payload };
    },
    setCountryText: (state, action) => {
      return { ...state, countryText: action.payload };
    },

    setState: (state, action) => {
      return { ...state, addr: mapState(state, action) };
    },
    setDriverNameManual: (state, action) => {
      return { ...state, manualDriverName: action.payload };
    },
    setLicenseNumberManual: (state, action) => {
      return { ...state, manualLicenseNumber: action.payload };
    },
  },
});
export const {
  resetStepTwoState,
  setAddrTypeCd,
  setSelectedLocation,
  setSelectedState,
  setIsNonUSAddress,
  setZip,
  setDescription,
  setId,
  setLocation1,
  setLocation2,
  setLocation3,
  setLocation4,
  setCity,
  setCountyText,
  setCountryNonUs,
  setCountryText,
  setState,
  setDateValue,
  setTimeValue,
  setSelectedVehicle,
  setSelectedDriver,
  setSelectedDriverRelationship,
  setDriverNameManual,
  setLicenseNumberManual,
} = reportClaimStepTwoSlice.actions;
export default reportClaimStepTwoSlice.reducer;
