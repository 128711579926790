import { API } from '../../../utils/api/index';
import {
  setDocumentsErrors,
  setIdCardsDocuments,
  setIdCardsTemplates,
  setIdMailed,
  setLoading,
  setMailingErrors,
} from './idCardsReducer';
import { gaEvent } from '../../../utils/google-analytics';
import { ga4Event } from '../../../utils/google-analytics-4';

export const postMailIdCards = customerId => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.post('customers/' + customerId + '/tasks', {
      templateId: 'CustomerTask0005',
    })
      .then(dispatch(setIdMailed(true)))
      .catch(err => {
        dispatch(setMailingErrors(err.message));
      })
      .finally(() => {
        gaEvent({ category: 'request-id-cards', action: 'complete' });
        ga4Event({ category: 'request-id-cards', action: 'complete' });
        dispatch(setLoading(false));
      });
  };
};

export const getIdCards = policyId => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get('policies/' + policyId + '/insuredDocuments')
      .then(response => {
        dispatch(setIdCardsDocuments(response.data.documentListItems));
      })
      .catch(err => {
        dispatch(setDocumentsErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const getIdCardsTemplateTypes = () => {
  return dispatch => {
    dispatch(setLoading(true));
    return API.get('coderefs/UWPolicy/insured-documents/id-cards')
      .then(response => {
        dispatch(setIdCardsTemplates(response.data.keys[0].options));
      })
      .catch(err => {
        dispatch(setDocumentsErrors(err.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
