export const getText = (object, field, defaultValue) => {
  try {
    const split = field.split('.');
    let subObject = object;
    split.forEach(subField => {
      subObject = subObject[subField];
    });
    return subObject || defaultValue;
  } catch (e) {
    return defaultValue;
  }
};
