import React from 'react';
import { CustomPageHtml } from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CustomPage1 = props => {
  const { customPage1Html } = props;

  return <CustomPageHtml dangerouslySetInnerHTML={{ __html: customPage1Html }} />;
};

CustomPage1.propTypes = {
  customPage1Html: PropTypes.string,
};

export const mapStateToProps = state => ({
  customPage1Html: state.i18nSlice.i18n.customContent.customContentPage1.html,
});

export default connect(mapStateToProps)(CustomPage1);
