import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  phoneErrors: null,
  formErrors: null,
  emailErrors: null,
  notificationErrors: null,
  mailingAddress: '',
  mfaEnabled: false,
  editingPhone: false,
  editingEmail: false,
  editingEmailAndPhone: false,
  editingNotifications: false,
  editingTimezone: false,
  phoneNumberPrimary: '',
  phoneNumberPrimaryType: { value: '', label: 'Select Type' },
  phoneNumberSecondaryType: { value: '', label: 'Select Type' },
  phoneNumberSecondary: '',
  emailAddress: '',
  timeZoneErrors: null,
};

export const profileSlice = createSlice({
  name: 'profileSlice',
  initialState,
  reducers: {
    resetState: () => {
      return { ...initialState };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setPhoneErrors: (state, action) => {
      return { ...state, phoneErrors: action.payload };
    },
    setEmailErrors: (state, action) => {
      return { ...state, emailErrors: action.payload };
    },
    setNotificationErrors: (state, action) => {
      return { ...state, notificationErrors: action.payload };
    },
    setEditingNotifications: (state, action) => {
      return { ...state, editingNotifications: action.payload };
    },
    setEditingPhone: (state, action) => {
      return { ...state, editingPhone: action.payload };
    },
    setEditingEmail: (state, action) => {
      return { ...state, editingEmail: action.payload };
    },
    setEditingEmailAndPhone: (state, action) => {
      return { ...state, editingEmailAndPhone: action.payload };
    },
    setFormErrors: (state, action) => {
      return { ...state, formErrors: action.payload };
    },
    setMFAEnabled: (state, action) => {
      return { ...state, mfaEnabled: action.payload };
    },
    setEditingTimezone: (state, action) => {
      return { ...state, editingTimezone: action.payload };
    },
    setPhoneNumberPrimary: (state, action) => {
      return { ...state, phoneNumberPrimary: action.payload };
    },
    setPhoneNumberPrimaryType: (state, action) => {
      return { ...state, phoneNumberPrimaryType: action.payload };
    },
    setPhoneNumberSecondary: (state, action) => {
      return { ...state, phoneNumberSecondary: action.payload };
    },
    setPhoneNumberSecondaryType: (state, action) => {
      return { ...state, phoneNumberSecondaryType: action.payload };
    },
    setEmailAddress: (state, action) => {
      return { ...state, emailAddress: action.payload };
    },
    setGoPaperlessErrors: (state, action) => {
      return { ...state, goPaperlessErrors: action.payload };
    },
    setTimezoneErrors: (state, action) => {
      return { ...state, timezoneErrors: action.payload };
    },
    setDeleteUserErrors: (state, action) => {
      return { ...state, deleteUserErrors: action.payload };
    },
  },
});

export const {
  resetState,
  setLoading,
  setPhoneErrors,
  setPhoneNumberPrimary,
  setPhoneNumberSecondary,
  setEmailErrors,
  setEmailAddress,
  setNotificationErrors,
  setEditingNotifications,
  setEditingEmail,
  setEditingPhone,
  setEditingEmailAndPhone,
  setEditingTimezone,
  setFormErrors,
  setGoPaperlessErrors,
  setDeleteUserErrors,
  setTimezoneErrors,
  setPhoneNumberPrimaryType,
  setPhoneNumberSecondaryType,
} = profileSlice.actions;
export default profileSlice.reducer;
