import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import {
  setAddrTypeCd,
  setCity,
  setCountryNonUS,
  setCounty,
  setDescription,
  setDriverNameManual,
  setId,
  setIsNonUSAddress,
  setLicenseNumberManual,
  setLocation1,
  setLocation2,
  setLocation3,
  setLocation4,
  setSelectedDriver,
  setSelectedDriverRelationship,
  setSelectedLocation,
  setSelectedVehicle,
  setState,
  setZip,
} from './anonymousReportClaimStepThreeReducer';
import {
  getDrivers,
  getLocations,
  getVehicles,
} from '../../report-claim/report-claim-step-two/selectors';
import { findIndex } from 'lodash';
import { US_STATES } from '../../../common/constants';
import {
  ClaimEventNameLabel,
  LoaderMargin,
  LocationFieldsContainer,
  ReportClaimCityStateContainer,
  ReportClaimDriverNameAndLicenseContainer,
  ReportClaimsAddressTextInputField,
  ReportClaimsAddressTitleHeader,
  ReportClaimsCountryTitleHeader,
  ReportClaimsFieldHeader,
  ReportClaimsStepTwoContainer,
  ReportClaimsStepTwoSubHeader,
  ReportClaimStepTwoField,
  ReportClaimStepTwoVehicleAndDriverContainer,
  ReportClaimsTextAreaField,
  ReportClaimsTitleHeader,
  ReportClaimTwoFormContainerFullRow,
  ReportClaimZipCountyContainer,
  RequiredField,
  StateList,
  StateSelectComponent,
  ZipErrorMessage,
} from './styles';
import Loader from '../../../common/loader';
import Select from 'react-select';
import { ReportClaimsTextInputField } from '../../report-claim/styles';
import {
  getAnonymousClaimEventContacts,
  getAnonymousClaimEventTemplateById,
} from './anonymousReportClaimStepThreeApi';
import {
  ReportClaimButton,
  ReportClaimButtonContainer,
  ReportClaimNextButton,
} from '../step1/styles';
import { LocationEvent } from '../step2/styles';
import ApiError from '../../../common/api-error';
import { useSnackbar } from 'notistack';
import {
  setAddrTypeCdStepSix,
  setCityStepSix,
  setDescriptionStepSix,
  setIdStepSix,
  setLocation1StepSix,
  setLocation2StepSix,
  setLocation3StepSix,
  setLocation4StepSix,
  setStateStepSix,
  setZipStepSix,
} from '../recap-and-submit/anonymouseReportClaimStepSixReducer';
import { HelpIconMedium } from '../../../common/tooltip/styles';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import { ReportClaimCancelButton } from '../styles';
import { useHistory } from 'react-router';
import Addresses from '../../../common/addresses';
import { getStateByCode } from '../../../common/addresses/addressUtils';

export const Step3 = props => {
  const {
    address,
    county,
    countryNonUS,
    customerId,
    dispatch,
    drivers,
    driverRelationshipOptions,
    driverRelationshipSelected,
    errors,
    locations,
    isClaimEventLoading,
    isClaimEventsContactsLoading,
    isNonUSAddress,
    manualDriverName,
    manualLicenseNumber,
    selectedClaimEventTemplate,
    dateValue,
    timeValue,
    claimDescription,
    claimEvent,
    claimEventContactsErrors,
    selectedLocation,
    selectedDriver,
    selectedVehicle,
    vehicles,
    handleNext,
    handleStep,
    description,
    registrationToken,
    i18n,
  } = props;

  let [location1Text, setLocation1Text] = React.useState('');
  let [location2Text, setLocation2Text] = React.useState('');
  let [location3Text, setLocation3Text] = React.useState('');
  let [location4Text, setLocation4Text] = React.useState('');
  let [apiCallSuccess, setApiCallSuccess] = React.useState(false);
  let [cityText, setCityText] = React.useState('');
  let [zipText, setZipText] = React.useState('');
  let [countyText, setCountyText] = React.useState(county);
  let [descriptionText, setDescriptionText] = React.useState(description || '');
  let [countryText, setCountryText] = React.useState(countryNonUS);
  let [stateText, setStateText] = React.useState({ label: 'Alabama', value: 'AL' });
  let [nonUSStateOrProvinceText, setNonUSStateOrProvinceText] = React.useState(
    address?.stateProvCd,
  );

  const { enqueueSnackbar } = useSnackbar();
  const setFinalLocation = () => {
    dispatch(setLocation1(location1Text));
    dispatch(setLocation2(location2Text));
    dispatch(setLocation3(location3Text));
    dispatch(setLocation4(location4Text));
    dispatch(setCity(cityText));
    dispatch(setState(isNonUSAddress ? nonUSStateOrProvinceText : stateText.value));
    dispatch(setZip(zipText));
    dispatch(setDescription(descriptionText));
    dispatch(setCounty(countyText));
    dispatch(setSelectedDriverRelationship(driverRelationshipSelected?.value));
    dispatch(setCountryNonUS(countryText));
    //recap info
    dispatch(setLocation1StepSix(location1Text));
    dispatch(setLocation2StepSix(location2Text));
    dispatch(setLocation3StepSix(location3Text));
    dispatch(setLocation4StepSix(location4Text));
    dispatch(setCityStepSix(cityText));
    dispatch(setStateStepSix(isNonUSAddress ? nonUSStateOrProvinceText : stateText.value));
    dispatch(setZipStepSix(zipText));
    dispatch(setDescriptionStepSix(descriptionText));
    //---

    if (selectedLocation.riskAddress) {
      dispatch(setAddrTypeCd(selectedLocation.riskAddress.addrTypeCd));
      dispatch(setAddrTypeCdStepSix(selectedLocation.riskAddress.addrTypeCd));
      dispatch(setId(selectedLocation.riskAddress.id));
      dispatch(setIdStepSix(selectedLocation.riskAddress.id));
    } else {
      dispatch(setAddrTypeCd(selectedLocation.typeCd));
      dispatch(setAddrTypeCdStepSix(selectedLocation.typeCd));
      dispatch(setId(selectedLocation.id));
      dispatch(setIdStepSix(selectedLocation.id));
    }
  };
  const checkDriverAndVehicle = () => {
    return (
      selectedVehicle.value &&
      (selectedDriver.value === 'PermissiveUse' || selectedDriver.value === 'NonPermissiveUse'
        ? manualDriverName.length > 0 && driverRelationshipSelected !== ''
        : selectedDriver.value)
    );
  };
  const genericFieldRequirements = () => {
    return (
      descriptionText.trim().length > 0 &&
      (selectedLocation.riskAddress ||
        (location1Text.length > 0 && cityText.length > 0 && zipText.length > 4)) &&
      (isNonUSAddress === true
        ? countryText.length > 0 && nonUSStateOrProvinceText.length > 0
        : (stateText && stateText.value.length) > 0 || (stateText && stateText.length > 0))
    );
  };

  const isRequiredFieldsCompleted = () => {
    if (selectedClaimEventTemplate.view === 'Vehicle') {
      return !(genericFieldRequirements() && checkDriverAndVehicle());
    }
    return !genericFieldRequirements();
  };

  const isNonUSCheckboxDisabled = () => {
    if (selectedClaimEventTemplate.view === 'Vehicle') {
      return false;
    }
    return !selectedLocation.id || selectedLocation.riskAddress;
  };

  const fillAddressFields = selectedAddress => {
    const getStateTextFromList = stateCd => {
      let index = findIndex(US_STATES, state => state.value === stateCd);
      return US_STATES[index];
    };

    if (selectedAddress.riskAddress) {
      setLocation1Text(selectedAddress.riskAddress.addr1 ? selectedAddress.riskAddress.addr1 : '');
      setLocation2Text(selectedAddress.riskAddress.addr2 ? selectedAddress.riskAddress.addr2 : '');
      setLocation3Text(selectedAddress.riskAddress.addr3 ? selectedAddress.riskAddress.addr3 : '');
      setLocation4Text(selectedAddress.riskAddress.addr4 ? selectedAddress.riskAddress.addr4 : '');
      setCityText(selectedAddress.riskAddress.city ? selectedAddress.riskAddress.city : '');
      setCountyText(selectedAddress.riskAddress.county ? selectedAddress.riskAddress.county : '');
      setCountryText(
        selectedAddress.riskAddress.regionCd ? selectedAddress.riskAddress.regionCd : '',
      );
      setZipText(
        selectedAddress.riskAddress.postalCode ? selectedAddress.riskAddress.postalCode : '',
      );
      setStateText(
        selectedAddress.riskAddress.stateProvCd
          ? getStateTextFromList(selectedAddress.riskAddress.stateProvCd)
          : '',
      );
    } else {
      setLocation1Text(address?.addr1 ? address.addr1 : '');
      setLocation2Text(address?.addr2 ? address.addr2 : '');
      setLocation3Text(address?.addr3 ? address.addr3 : '');
      setLocation4Text(address?.addr4 ? address.addr4 : '');
      setCityText(address?.city ? address.city : '');
      setCountyText(countyText);
      setZipText(address?.postalCode ? address.postalCode : '');
      setCountryText(countryNonUS ? countryNonUS : '');
      setNonUSStateOrProvinceText(address?.stateProvCd ? address.stateProvCd : ''); //set a default state so it doesn't error out.
    }
  };

  React.useEffect(() => {
    if (selectedClaimEventTemplate) {
      dispatch(
        getAnonymousClaimEventTemplateById(
          selectedClaimEventTemplate.id,
          customerId,
          dateValue,
          timeValue,
          selectedClaimEventTemplate.view === 'Location' ? 'RiskLocation' : 'VehicleAndDrivers',
          registrationToken,
        ),
      );
    }
    if (selectedLocation) {
      fillAddressFields(selectedLocation);
    }
  }, []);

  const onLocationSelectionChange = selectedLocationOption => {
    if (selectedLocationOption) {
      if (selectedLocationOption.id !== 'Unscheduled Location') {
        props.dispatch(setIsNonUSAddress(false));
      }
      props.dispatch(setSelectedLocation(selectedLocationOption));
      fillAddressFields(selectedLocationOption);
    }
  };

  const zipErrorMessage = zipText.length !== 0 && zipText.length < 5 ? 'Invalid Entry' : '';

  const onVehicleSelectionChange = selectedVehicleOption => {
    if (selectedVehicleOption) {
      props.dispatch(setSelectedVehicle(selectedVehicleOption));
    }
  };

  const onDriverSelectionChange = selectedDriverOption => {
    if (selectedDriverOption) {
      props.dispatch(setSelectedDriver(selectedDriverOption));
    }
  };
  const onDriverRelationshipSelectionChange = selectedRelationshipType => {
    if (selectedRelationshipType) {
      props.dispatch(setSelectedDriverRelationship(selectedRelationshipType));
    }
  };

  const checkIfVehicleOrUnscheduled = () => {
    if (selectedClaimEventTemplate.view === 'Vehicle') {
      //if vehicle all address stuff should be addable
      return false;
    }
    return !(
      (selectedLocation && selectedLocation.value === 'Unscheduled Location') ||
      (selectedLocation && selectedLocation.value === 'Other Location')
    );
  };

  useEffect(() => {
    if (apiCallSuccess) {
      setFinalLocation();
      handleNext();
    }
  }, [apiCallSuccess]);
  const history = useHistory();

  const handlePlaceSelect = address => {
    const { addr1, addr2, city, stateProvCd, postalCode } = address;
    setLocation1Text(addr1);
    setLocation2Text(addr2);
    setCityText(city);
    setZipText(postalCode);
    setStateText(getStateByCode(stateProvCd, US_STATES));
  };
  return (
    <>
      <Helmet>
        <title>Report Claim - Loss Description</title>
      </Helmet>
      {isClaimEventLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : errors ? (
        <ApiError body="Unable to get coverage information." />
      ) : (
        <>
          <ReportClaimsStepTwoContainer>
            {!!claimEventContactsErrors && <ApiError body={claimEventContactsErrors} />}
            <ClaimEventNameLabel>{claimEvent && claimDescription}</ClaimEventNameLabel>
            <ReportClaimsStepTwoSubHeader>Add the location details.</ReportClaimsStepTwoSubHeader>
            {selectedClaimEventTemplate.view === 'Vehicle' ? (
              <ReportClaimStepTwoVehicleAndDriverContainer>
                <div>
                  <ReportClaimsTitleHeader>
                    Vehicle Information <RequiredField>*</RequiredField>
                  </ReportClaimsTitleHeader>
                  <Select
                    name="vehicleList"
                    id="vehicleList"
                    options={vehicles}
                    defaultValue={selectedVehicle}
                    onChange={selectedVehicleOption =>
                      onVehicleSelectionChange(selectedVehicleOption)
                    }
                  />
                </div>
                <div>
                  <ReportClaimsTitleHeader>
                    Driver Information <RequiredField>*</RequiredField>
                  </ReportClaimsTitleHeader>
                  <Select
                    name="driverList"
                    id="driverList"
                    options={drivers}
                    defaultValue={selectedDriver}
                    onChange={selectedDriverOption => onDriverSelectionChange(selectedDriverOption)}
                  />
                </div>
              </ReportClaimStepTwoVehicleAndDriverContainer>
            ) : null}
            {selectedClaimEventTemplate.view === 'Vehicle' &&
            (selectedDriver.value === 'NonPermissiveUse' ||
              selectedDriver.value === 'PermissiveUse') ? (
              <ReportClaimDriverNameAndLicenseContainer>
                <div>
                  <ReportClaimsTitleHeader htmlFor="driverName">
                    Driver Name <RequiredField>*</RequiredField>
                  </ReportClaimsTitleHeader>
                  <ReportClaimsTextInputField
                    name="driversName"
                    value={manualDriverName}
                    onChange={e => props.dispatch(setDriverNameManual(e.target.value))}
                  />
                </div>
                <div>
                  <ReportClaimsTitleHeader htmlFor="licenseNumber">
                    License Number
                  </ReportClaimsTitleHeader>
                  <ReportClaimsTextInputField
                    name="licenseNumber"
                    value={manualLicenseNumber}
                    onChange={e => props.dispatch(setLicenseNumberManual(e.target.value))}
                  />
                </div>
                <div>
                  <ReportClaimsTitleHeader htmlFor="driverRelationshipList">
                    Driver Relationship To Insured <RequiredField>*</RequiredField>
                  </ReportClaimsTitleHeader>
                  <Select
                    name="driverRelationshipList"
                    id="driverRelationshipList"
                    options={driverRelationshipOptions}
                    value={driverRelationshipSelected}
                    onChange={onDriverRelationshipSelectionChange.bind(this)}
                  />
                </div>
              </ReportClaimDriverNameAndLicenseContainer>
            ) : null}

            {selectedClaimEventTemplate.view === 'Location' ? (
              <>
                <ReportClaimsTitleHeader htmlFor="locationSelection">
                  Location Event <RequiredField>*</RequiredField>
                  <TooltipInfo
                    title={getText(i18n, 'reportClaimAnonymous.stepThree.locationEventTooltip')}
                  />
                </ReportClaimsTitleHeader>
                <LocationEvent
                  name="locationList"
                  id="locationList"
                  value={
                    selectedLocation
                      ? {
                          label: selectedLocation.label,
                          value: selectedLocation,
                        }
                      : null
                  }
                  options={locations}
                  onChange={selectedLocationOption => {
                    onLocationSelectionChange(selectedLocationOption);
                  }}
                />
              </>
            ) : null}
            <ReportClaimStepTwoField>
              <ReportClaimsTitleHeader htmlFor="nonUSAddressCheckBox">
                Non-U.S. Address{' '}
                <input
                  type="checkbox"
                  disabled={isNonUSCheckboxDisabled()}
                  name="nonUSAddressCheckBox"
                  checked={isNonUSAddress}
                  defaultValue={isNonUSAddress}
                  onChange={() => props.dispatch(setIsNonUSAddress(!isNonUSAddress))}
                />
                <TooltipInfo
                  title={getText(i18n, 'reportClaimAnonymous.stepThree.nonUSAddressTooltip')}
                />
              </ReportClaimsTitleHeader>
            </ReportClaimStepTwoField>
            <LocationFieldsContainer>
              {isNonUSAddress && (
                <ReportClaimTwoFormContainerFullRow>
                  <ReportClaimsCountryTitleHeader htmlFor="country">
                    Country <RequiredField>*</RequiredField>
                  </ReportClaimsCountryTitleHeader>
                  <ReportClaimsTextInputField
                    name="country"
                    value={countryText}
                    onChange={e => {
                      setCountryText(e.target.value);
                    }}
                    disabled={checkIfVehicleOrUnscheduled()}
                  />
                </ReportClaimTwoFormContainerFullRow>
              )}
              {!isNonUSAddress && !checkIfVehicleOrUnscheduled() && (
                <ReportClaimTwoFormContainerFullRow>
                  <Addresses
                    id="anonymousReportClaimContactLocationAddress"
                    size="large"
                    isAnonymousUser={true}
                    handlePlaceSelect={handlePlaceSelect}
                    isRequiredField={true}
                    label={'Location Address'}
                  >
                    <TooltipInfo
                      title={getText(i18n, 'reportClaimAnonymous.stepThree.locationAddressTooltip')}
                    />
                  </Addresses>
                </ReportClaimTwoFormContainerFullRow>
              )}
              {(checkIfVehicleOrUnscheduled() || isNonUSAddress) && (
                <>
                  <ReportClaimTwoFormContainerFullRow>
                    <ReportClaimsAddressTitleHeader htmlFor="locationAddress1">
                      Location Address <RequiredField>*</RequiredField>
                      <TooltipInfo
                        title={getText(
                          i18n,
                          'reportClaimAnonymous.stepThree.locationAddressTooltip',
                        )}
                      />
                    </ReportClaimsAddressTitleHeader>
                    <ReportClaimsAddressTextInputField
                      id="locationAddress1"
                      name="locationAddress1"
                      value={location1Text}
                      onChange={e => {
                        setLocation1Text(e.target.value);
                      }}
                      disabled={checkIfVehicleOrUnscheduled()}
                    />
                    <ReportClaimsAddressTextInputField
                      id="locationAddress2"
                      name="locationAddress2"
                      value={location2Text}
                      onChange={e => {
                        setLocation2Text(e.target.value);
                      }}
                      disabled={checkIfVehicleOrUnscheduled()}
                    />
                    {isNonUSAddress && (
                      <ReportClaimTwoFormContainerFullRow>
                        <ReportClaimsAddressTextInputField
                          id="locationAddress3"
                          name="locationAddress3"
                          value={location3Text}
                          onChange={e => {
                            setLocation3Text(e.target.value);
                          }}
                          disabled={checkIfVehicleOrUnscheduled()}
                        />
                        <ReportClaimsAddressTextInputField
                          name="locationAddress4"
                          value={location4Text}
                          onChange={e => {
                            setLocation4Text(e.target.value);
                          }}
                          disabled={checkIfVehicleOrUnscheduled()}
                        />
                      </ReportClaimTwoFormContainerFullRow>
                    )}
                  </ReportClaimTwoFormContainerFullRow>
                  <ReportClaimCityStateContainer>
                    <ReportClaimStepTwoField>
                      <ReportClaimsFieldHeader htmlFor="city">
                        City <RequiredField>*</RequiredField>
                        <TooltipInfo
                          title={getText(i18n, 'reportClaimAnonymous.stepThree.cityTooltip')}
                        />
                      </ReportClaimsFieldHeader>
                      <ReportClaimsTextInputField
                        id="city"
                        name="city"
                        value={cityText}
                        onChange={e => {
                          setCityText(e.target.value);
                        }}
                        disabled={checkIfVehicleOrUnscheduled()}
                      />
                    </ReportClaimStepTwoField>
                    <StateSelectComponent>
                      <ReportClaimStepTwoField>
                        <ReportClaimsFieldHeader htmlFor="state">
                          {isNonUSAddress ? 'State/Province' : 'State'}{' '}
                          <RequiredField>*</RequiredField>
                          <TooltipInfo
                            title={getText(i18n, 'reportClaimAnonymous.stepThree.stateTooltip')}
                          >
                            <HelpIconMedium />
                          </TooltipInfo>
                        </ReportClaimsFieldHeader>
                        {!isNonUSAddress ? (
                          <StateList
                            name="stateList"
                            id="stateList"
                            options={US_STATES}
                            value={stateText}
                            onChange={option => {
                              setStateText(option);
                            }}
                            isDisabled={checkIfVehicleOrUnscheduled()}
                          />
                        ) : (
                          <ReportClaimStepTwoField>
                            <ReportClaimsTextInputField
                              name="stateOrProvince"
                              value={nonUSStateOrProvinceText}
                              onChange={e => {
                                setNonUSStateOrProvinceText(e.target.value);
                              }}
                            />
                          </ReportClaimStepTwoField>
                        )}
                      </ReportClaimStepTwoField>
                    </StateSelectComponent>
                  </ReportClaimCityStateContainer>
                  <ReportClaimZipCountyContainer>
                    <ReportClaimStepTwoField>
                      <ReportClaimsFieldHeader htmlFor="zip">
                        {isNonUSAddress ? 'Postal Code' : 'ZIP'} <RequiredField>*</RequiredField>
                        <TooltipInfo
                          title={getText(i18n, 'reportClaimAnonymous.stepThree.zipTooltip')}
                        />
                      </ReportClaimsFieldHeader>
                      <ReportClaimsTextInputField
                        id="zip"
                        name="zip"
                        value={zipText}
                        onChange={e => {
                          setZipText(e.target.value);
                        }}
                        disabled={checkIfVehicleOrUnscheduled()}
                      />
                      <ZipErrorMessage>{zipErrorMessage && zipErrorMessage}</ZipErrorMessage>
                    </ReportClaimStepTwoField>
                    {!isNonUSAddress && (
                      <div>
                        <ReportClaimsFieldHeader htmlFor="county">County </ReportClaimsFieldHeader>
                        <TooltipInfo
                          title={getText(i18n, 'reportClaimAnonymous.stepThree.countyTooltip')}
                        />
                        <ReportClaimsTextInputField
                          name="county"
                          value={countyText}
                          onChange={e => {
                            setCountyText(e.target.value);
                          }}
                          disabled={checkIfVehicleOrUnscheduled()}
                        />
                      </div>
                    )}
                  </ReportClaimZipCountyContainer>
                </>
              )}
            </LocationFieldsContainer>

            <ReportClaimTwoFormContainerFullRow>
              <ReportClaimsFieldHeader htmlFor="description">
                Describe what happened <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(i18n, 'reportClaimAnonymous.stepThree.descriptionTooltip')}
                />
              </ReportClaimsFieldHeader>
              <ReportClaimsTextAreaField
                name="description"
                value={descriptionText}
                onChange={e => {
                  setDescriptionText(e.target.value);
                }}
              />
            </ReportClaimTwoFormContainerFullRow>
            <ReportClaimButtonContainer>
              {!isClaimEventsContactsLoading ? (
                <>
                  <ReportClaimCancelButton onClick={() => history.push('/')}>
                    Cancel
                  </ReportClaimCancelButton>
                  <ReportClaimNextButton
                    id="Next"
                    disabled={isRequiredFieldsCompleted()}
                    onClick={() =>
                      dispatch(
                        getAnonymousClaimEventContacts(
                          claimEvent.templateIdRef,
                          customerId,
                          selectedLocation,
                          selectedVehicle,
                          dateValue,
                          timeValue,
                          registrationToken,
                          enqueueSnackbar,
                          setApiCallSuccess,
                        ),
                      )
                    }
                  >
                    Next
                  </ReportClaimNextButton>
                  <ReportClaimButton
                    id="Previous"
                    onClick={() => {
                      handleStep();
                    }}
                  >
                    Back
                  </ReportClaimButton>
                </>
              ) : (
                <Loader />
              )}
            </ReportClaimButtonContainer>
          </ReportClaimsStepTwoContainer>
        </>
      )}
    </>
  );
};
Step3.propTypes = {
  address: PropTypes.any,
  cityText: PropTypes.string,
  claimDescription: PropTypes.string,
  claimEvent: PropTypes.any,
  claimEventContacts: PropTypes.object,
  claimEventContactsErrors: PropTypes.string,
  county: PropTypes.string,
  countryNonUS: PropTypes.string,
  customerId: PropTypes.string,
  dateValue: PropTypes.string,
  descriptionText: PropTypes.string,
  dispatch: PropTypes.func,
  drivers: PropTypes.array,
  driverRelationshipOptions: PropTypes.array,
  driverRelationshipSelected: PropTypes.any,
  errors: PropTypes.any,
  isClaimEventLoading: PropTypes.bool,
  isClaimEventsContactsLoading: PropTypes.bool,
  isNonUSAddress: PropTypes.bool,
  isReadyForNextStep: PropTypes.bool,
  location1Text: PropTypes.string,
  location2Text: PropTypes.string,
  location3Text: PropTypes.string,
  location4Text: PropTypes.string,
  locations: PropTypes.array,
  manualDriverName: PropTypes.string,
  manualLicenseNumber: PropTypes.string,
  selectedClaimEventTemplate: PropTypes.object,
  selectedDriver: PropTypes.object,
  selectedLocation: PropTypes.object,
  selectedState: PropTypes.object,
  selectedVehicle: PropTypes.object,
  stateText: PropTypes.string,
  timeValue: PropTypes.string,
  vehicles: PropTypes.array,
  zipText: PropTypes.string,
  features: PropTypes.object,
  handleNext: PropTypes.func,
  handleStep: PropTypes.func,
  description: PropTypes.string,
  registrationToken: PropTypes.string,
  i18n: PropTypes.object,
};

export const mapStateToProps = state => ({
  address: state.anonymousReportClaimStepThreeSlice.addr[0],
  claimEventContacts: state.anonymousReportClaimStepThreeSlice.claimEventContacts,
  claimEventContactsErrors: state.anonymousReportClaimStepThreeSlice.claimEventContactsErrors,
  claimEvent: state.anonymousReportClaimStepThreeSlice.claimEvent,
  claimDescription: state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate.description,
  countryNonUS: state.anonymousReportClaimStepThreeSlice.countryNonUS,
  county: state.anonymousReportClaimStepThreeSlice.county,
  customerId: state.loginSlice.customerId,
  dateValue: state.anonymousReportClaimStepThreeSlice.dateValue,
  drivers: getDrivers(state.anonymousReportClaimStepThreeSlice.claimEvent),
  driverRelationshipOptions: state.anonymousReportClaimStepTwoSlice.driverRelationshipOptions,
  driverRelationshipSelected: state.anonymousReportClaimStepThreeSlice.driverRelationshipSelected,
  errors: state.anonymousReportClaimStepTwoSlice.errors,
  isClaimEventLoading: state.anonymousReportClaimStepThreeSlice.isClaimEventLoading,
  isClaimEventsContactsLoading:
    state.anonymousReportClaimStepThreeSlice.isClaimEventsContactsLoading,
  isNonUSAddress: state.anonymousReportClaimStepThreeSlice.isNonUSAddress,
  isReadyForNextStep: state.anonymousReportClaimStepThreeSlice.isReadyForNextStep,
  locations: getLocations(state.anonymousReportClaimStepThreeSlice.claimEvent),
  manualDriverName: state.anonymousReportClaimStepThreeSlice.manualDriverName,
  manualLicenseNumber: state.anonymousReportClaimStepThreeSlice.manualLicenseNumber,
  selectedClaimEventTemplate: state.anonymousReportClaimStepTwoSlice.selectedClaimEventTemplate,
  selectedDriver: state.anonymousReportClaimStepThreeSlice.selectedDriver,
  selectedLocation: state.anonymousReportClaimStepThreeSlice.selectedLocation,
  selectedVehicle: state.anonymousReportClaimStepThreeSlice.selectedVehicle,
  stateText: state.anonymousReportClaimStepThreeSlice.stateText,
  timeValue: state.anonymousReportClaimStepThreeSlice.timeValue,
  description: state.anonymousReportClaimStepThreeSlice.descriptionText,
  vehicles: getVehicles(state.anonymousReportClaimStepThreeSlice.claimEvent),
  registrationToken: state.loginSlice.registrationToken,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(Step3);
