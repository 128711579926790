export const bankAccountTypes = [
  {
    label: 'Personal Checking',
    value: 'Checking',
    // valueToBeUsedInCall: `${achType ? achType : 'PPD'}`,
  },
  {
    label: 'Personal Savings',
    value: 'Savings',
    // valueToBeUsedInCall: `${achType ? achType : 'PPD'}`,
  },
  {
    label: 'Commercial Checking',
    achBankAccountType: 'Checking',
    value: 'CCD',
  },
];
