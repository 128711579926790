import { merge } from 'lodash';
import { setPaymentInstrument } from './makePaymentStepTwoReducer';
export const makeSDK = (
  firstName,
  lastName,
  customerAddress,
  paymentTenantName,
  paymentConfigId,
  paymentApiUrl,
  paymentCategory,
) => {
  let config = {
    apiUrl: paymentApiUrl,
    tenant: paymentTenantName,
    paymentConfigId: paymentConfigId,
    paymentCategory: paymentCategory,
    featurePrefillAddress: true,
    featureCustomAddressCollection: true,
    dev_enableVerboseLogging: false,
    customerAddress: customerAddress.addr1,
    customerCountry: 'US',
    customerCity: customerAddress.city,
    customerFirstName: firstName,
    customerLastName: lastName,
    customerState: customerAddress.stateProvCd,
    customerZip: customerAddress.postalCode,
  };
  return window.GuidewirePayments.createSDK(config);
};
const addFundingInstrumentTemporarily = (
  response,
  setFundingInstruments,
  fundingInstruments,
  dispatch,
  isUpdatingPaySchedule,
  tokenizedPaymentSource,
) => {
  let fundingInstrument = merge({}, response, tokenizedPaymentSource);
  fundingInstrument.isTemporary = true;
  fundingInstrument.achBankAccountTypeCd = fundingInstrument?.achBankAccountTypeCd
    ? fundingInstrument?.achBankAccountTypeCd
    : fundingInstrument.accountType
    ? fundingInstrument.accountType
    : '';
  fundingInstrument.achBankAccountNumber = fundingInstrument.achBankAccountNumber
    ? fundingInstrument.achBankAccountNumber
    : fundingInstrument.lastFour
    ? fundingInstrument.lastFour
    : '';
  if (!isUpdatingPaySchedule) {
    setFundingInstruments(
      fundingInstruments ? [...fundingInstruments, fundingInstrument] : [fundingInstrument],
    );
    dispatch(setPaymentInstrument(fundingInstrument));
  } else {
    setFundingInstruments([fundingInstrument]);
    dispatch(setPaymentInstrument(fundingInstrument));
  }
};

export const addPayment = (
  setFundingInstruments,
  fundingInstruments,
  isUpdatingPaySchedule,
  notify,
) => {
  return (dispatch) => {
    window.GuidewirePayments.collectAndAssociateCard(function (result) {
      switch (result.type) {
        case 'ERROR':
          notify(
            'We cannot add your credit card at this time. Please try again later or contact your agent.',
            {
              variant: 'error',
              anchorOrigin: {
                horizontal: 'center',
                vertical: 'top',
              },
            },
          );
          break;
        case 'CARD_ADDED':
          addFundingInstrumentTemporarily(
            result.card,
            setFundingInstruments,
            fundingInstruments,
            dispatch,
            isUpdatingPaySchedule,
            result.tokenizedCard,
          ); //saves if payment is successful
          break;
        case 'PAYMENTSOURCE_ADDED':
          addFundingInstrumentTemporarily(
            result.paymentSource,
            setFundingInstruments,
            fundingInstruments,
            dispatch,
            isUpdatingPaySchedule,
            result.tokenizedPaymentSource,
          ); //saves if payment is successful
          break;
        case 'CANCELED':
          //   vm.selected.val = ''; //sets radio button for cc as unclicked
          //   $scope.$apply(toStepOne);
          break;
        default:
        //   $log.warn('unexpected response type from payments sdk', result);
      }
    });
  };
};

export function getBillingAddress(customer) {
  const customerParty = customer.partyInfo?.find((party) => party.partyTypeCd === 'CustomerParty');
  return (
    customerParty?.addresses?.find((address) => address.addrTypeCd === 'CustomerBillingAddr') || {}
  );
}

export function getFirstName(billingAccount) {
  const insuredParty = billingAccount.partyInfo?.find(
    (party) =>
      party.partyTypeCd === 'InsuredParty' || party.partyTypeCd === 'StatementAccountParty',
  );
  return insuredParty?.nameInfo?.givenName || '';
}

export function getLastName(billingAccount) {
  const insuredParty = billingAccount.partyInfo?.find(
    (party) =>
      party.partyTypeCd === 'InsuredParty' || party.partyTypeCd === 'StatementAccountParty',
  );
  return insuredParty?.nameInfo?.surname || '';
}
