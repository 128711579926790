import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  accountCreationErrors: null,
  crossLinkRegistrationToken: '',
  customerId: undefined,
  errors: null,
  isAccountCreationSuccessful: undefined,
  isLoading: false,
  registrationFields: [],
  registrationToken: '',
  user: null,
  isCustomerEmailFound: false,
  customerEmailOnFile: null,
};

export const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    setUser: (state, action) => {
      return { ...state, user: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setAccountCreationErrors: (state, action) => {
      return { ...state, accountCreationErrors: action.payload };
    },

    setRegistrationFields: (state, action) => {
      return { ...state, registrationFields: action.payload };
    },
    setRegistrationToken: (state, action) => {
      return { ...state, registrationToken: action.payload };
    },
    setCrossLinkRegistrationToken: (state, action) => {
      return { ...state, crossLinkRegistrationToken: action.payload };
    },
    setAccountCreationSuccessful: (state, action) => {
      return { ...state, isAccountCreationSuccessful: action.payload };
    },
    setCustomerId: (state, action) => {
      return { ...state, customerId: action.payload };
    },
    setCustomerEmailFound: (state, action) => {
      return { ...state, isCustomerEmailFound: action.payload };
    },
    setCustomerEmailOnFile: (state, action) => {
      return { ...state, customerEmailOnFile: action.payload };
    },
  },
});
export const {
  setUser,
  setLoading,
  setErrors,
  setRegistrationFields,
  setRegistrationToken,
  setCrossLinkRegistrationToken,
  setAccountCreationSuccessful,
  setAccountCreationErrors,
  setCustomerId,
  setCustomerEmailFound,
  setCustomerEmailOnFile
} = loginSlice.actions;
export default loginSlice.reducer;
