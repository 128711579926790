import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimEventTemplates: [],
  claimEventContacts: null,
  claimEventContactsErrors: '',
  driverRelationshipOptions: [],
  selectedClaimEventTemplate: null,
  updatedClaimEventTemplate: null, // contains dynamic questions
  claimEvent: null,
  activeStep: 0,
  isLoading: false,
  isClaimEventLoading: false,
  errors: null,
};

export const reportClaimSlice = createSlice({
  name: 'reportClaimSlice',
  initialState,
  reducers: {
    resetStepOneState: () => {
      return initialState;
    },
    setActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload };
    },
    setClaimEventTemplates: (state, action) => {
      return { ...state, claimEventTemplates: action.payload };
    },
    setSelectedClaimEventTemplate: (state, action) => {
      return { ...state, selectedClaimEventTemplate: action.payload };
    },
    setUpdatedClaimEventTemplate: (state, action) => {
      return { ...state, updatedClaimEventTemplate: action.payload };
    },
    setClaimEvent: (state, action) => {
      return { ...state, claimEvent: action.payload };
    },
    setClaimEventContacts: (state, action) => {
      return { ...state, claimEventContacts: action.payload };
    },
    setClaimEventContactsErrors: (state, action) => {
      return { ...state, claimEventContactsErrors: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setClaimEventLoading: (state, action) => {
      return { ...state, isClaimEventLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setDriverRelationshipOptions: (state, action) => {
      return { ...state, driverRelationshipOptions: action.payload };
    },
  },
});
export const {
  resetStepOneState,
  setActiveStep,
  setLoading,
  setClaimEventLoading,
  setClaimEventTemplates,
  setSelectedClaimEventTemplate,
  setClaimEvent,
  setErrors,
  setClaimEventContacts,
  setClaimEventContactsErrors,
  setDriverRelationshipOptions,
  setUpdatedClaimEventTemplate,
} = reportClaimSlice.actions;
export default reportClaimSlice.reducer;
