import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ErrorMessageBody, ErrorMessageContainer, ErrorMessageTitle } from './styles';

export const ApiError = (props) => {
  const { body, variant, title, titleColor } = props;

  return (
    <>
      <ErrorMessageContainer variant={variant}>
        <ErrorMessageTitle titleColor={titleColor}>{title}</ErrorMessageTitle>
        <ErrorMessageBody>{body}</ErrorMessageBody>
      </ErrorMessageContainer>
    </>
  );
};
ApiError.propTypes = {
  body: PropTypes.string,
  variant: PropTypes.string,
  title: PropTypes.string,
  titleColor: PropTypes.string,
};
ApiError.defaultProps = {
  body: 'There was a problem. Please contact your agent for assistance or try again later.',
  title: '',
};
export const mapStateToProps = (state) => ({
  state,
});
export default connect(mapStateToProps)(ApiError);
