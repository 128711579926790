import styled from 'styled-components';

export const PolicyInfoFieldContainer = styled.div.attrs({
  className: 'PolicyInfoFieldContainer',
})`
  display: grid;
  grid-template-columns: 100%;
`;
export const PolicyInfoFieldHeader = styled.label.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-weight: 500;
  margin-top: 15px;
`;

export const PolicyInfoFieldTextInputField = styled.input.attrs(props => ({
  id: props.id ? props.id : 'missing id',
  type: 'text',
}))`
  height: 28px;
  margin-top: 5px;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 95%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 80%;
  }
  @media (min-width: 1280px) {
    width: ${props => (props.width ? props.width : '30%')};
  }
`;
export const PolicyInfoZipFieldText = styled.input.attrs(props => ({
  id: props.id ? props.id : 'missing id',
  type: 'text',
}))`
  height: 28px;
  margin-top: 5px;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 95%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 80%;
  }
  @media (min-width: 1280px) {
    width: ${props => (props.width ? props.width : '20%')};
  }
`;
export const PolicyInfoDateField = styled.input.attrs(props => ({
  id: props.id ? props.id : 'missing id',
  type: 'date',
}))`
  height: 28px;
  margin-top: 5px;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 95%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 80%;
  }
  @media (min-width: 1280px) {
    width: 36%;
  }
`;

export const RequiredField = styled.span.attrs({})`
  color: red;
`;

export const TextLink = styled.button`
  cursor: pointer;
  color: blue;
  background: none;
  box-shadow: none;
  padding: 0;
  text-decoration: underline;
  :hover {
    background: none;
  }
`;
export const DialogContainer = styled.div.attrs({
  id: 'DialogContainer',
})`
  padding: 25px;
`;
export const TermsAndConditionsContainer = styled.div.attrs({})`
  margin-top: 15px;
`;
export const ReportClaimButtonContainer = styled.div.attrs({
  id: 'ReportClaimButtonContainer',
})`
  display: block;
  margin: 24px 0;
`;
export const ReportClaimButton = styled.button.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  float: right;
  color: ${props => props.theme.primaryColor};
  background-color: white;
  padding: 8px 38px 8px 38px;
  margin-right: 10px;
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
    box-shadow: 3px 3px 6px gray;
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
    box-shadow: initial;
  }
  :disabled {
    background-color: gray;
    box-shadow: initial;
  }
`;

export const ReportClaimButtonForError = styled.button.attrs(props => ({
  id: 'reportClaimButtonForError',
}))`
  float: right;
  margin-top: -25px;
  color: ${props => props.theme.primaryColor};
  background-color: white;
  padding: 8px 38px 8px 38px;
  margin-right: 10px;
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
    box-shadow: 3px 3px 6px gray;
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
    box-shadow: initial;
  }
  :disabled {
    background-color: gray;
    box-shadow: initial;
  }
`;

export const ReportClaimNextButton = styled.button.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  float: right;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  padding: 8px 38px 8px 38px;
  border: 1px solid ${props => props.theme.primaryColor};
  :disabled {
    background-color: gray;
  }
`;
export const VerificationHeader = styled.h2.attrs({
  id: 'verifyAccountMessaging',
})`
  font-size: 20px;
  font-weight: 600;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
