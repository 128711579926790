import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  claimEventTemplates: [],
  validationClaim: {},
  fileNames: [],
  isClaimEventValidated: false,
};
export const anonymousReportClaimStepFiveSlice = createSlice({
  name: 'anonymousReportClaimStepFiveSlice',
  initialState,
  reducers: {
    resetStepFiveState: () => {
      return initialState;
    },
    setClaimEventTemplates: (state, action) => {
      return { ...state, claimEventTemplates: action.payload };
    },
    setValidateClaim: (state, action) => {
      return { ...state, validationClaim: action.payload };
    },
    setClaimEventValidated: (state, action) => {
      return { ...state, isClaimEventValidated: action.payload };
    },
  },
});
export const {
  resetStepFiveState,
  setClaimEventTemplates,
  setValidateClaim,
  setClaimEventValidated,
} = anonymousReportClaimStepFiveSlice.actions;
export default anonymousReportClaimStepFiveSlice.reducer;
