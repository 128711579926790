import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  InformationMessage,
  NewPremiumLabel,
  PolicyChangePreviewBorderedContainer,
  PolicyChangePreviewButton,
  PolicyChangePreviewButtonsContainer,
  PolicyChangePreviewContainer,
  PolicyChangePreviewFieldHeader,
  PolicyChangePreviewField,
  PolicyChangePreviewSubContainer,
  PolicyChangePreviewTextArea,
  TwoColumnContainer,
  HorizontalLine,
} from './styles';
import {
  setPolicyChangeCoverageRecapFields,
  setPolicyChangePreview,
} from '../../policyChangeReducer';
import { submitPolicyChange } from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { useHistory } from 'react-router';

export const PolicyChangePreview = (props) => {
  const { latestPolicyInfo, policyChangePreview, newDeductible, previewData } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);

  const premiumDiff = (policyChangePreview.finalPremiumAmt - latestPolicyInfo.finalPremiumAmt)
    .toFixed(2)
    .toString();

  const onClickSubmit = () => {
    setIsSubmitting(true);

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(previewData.recapFields));
    // submit API call
    props.dispatch(submitPolicyChange(previewData.payload));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    setIsSubmitting(false);
  }, []);

  const history = useHistory();

  return (
    <>
      <PolicyChangePreviewContainer>
        <TwoColumnContainer>
          <PolicyChangePreviewSubContainer>
            {latestPolicyInfo && latestPolicyInfo.deductible && (
              <PolicyChangePreviewField id="currentDeductibleAmount">
                <PolicyChangePreviewFieldHeader>Current Deductible</PolicyChangePreviewFieldHeader>$
                {latestPolicyInfo.deductible}
              </PolicyChangePreviewField>
            )}
            <PolicyChangePreviewField id="currentTotalPremiumAmount">
              <PolicyChangePreviewFieldHeader>Current Total Premium</PolicyChangePreviewFieldHeader>
              ${latestPolicyInfo.finalPremiumAmt}
            </PolicyChangePreviewField>
          </PolicyChangePreviewSubContainer>
          <PolicyChangePreviewBorderedContainer>
            {newDeductible && (
              <PolicyChangePreviewField>
                <PolicyChangePreviewFieldHeader>New Deductible</PolicyChangePreviewFieldHeader>
                <NewPremiumLabel id="newDeductible">{newDeductible}</NewPremiumLabel>
              </PolicyChangePreviewField>
            )}
            <PolicyChangePreviewField>
              <PolicyChangePreviewFieldHeader>New Total Premium</PolicyChangePreviewFieldHeader>
              <NewPremiumLabel id="newTotalPremium">
                ${policyChangePreview.finalPremiumAmt}
              </NewPremiumLabel>
            </PolicyChangePreviewField>
          </PolicyChangePreviewBorderedContainer>
          <div />
          <div>
            <PolicyChangePreviewField id="premiumChanges">
              <PolicyChangePreviewFieldHeader>Premium Changes</PolicyChangePreviewFieldHeader>
              {premiumDiff.includes('-') ? `-$${premiumDiff.substring(1)}` : `$${premiumDiff}`}
            </PolicyChangePreviewField>
            <InformationMessage>
              The premium change amount reflects the change in premium from the effective date of
              this change to the end of the policy term
            </InformationMessage>
          </div>
        </TwoColumnContainer>
        <HorizontalLine />
        <TwoColumnContainer>
          {previewData.fields.map((field) =>
            field.showFieldOnPremiumPreview ? (
              <PolicyChangePreviewField key={`id-${field.label.replaceAll(' ', '-')}`}>
                <PolicyChangePreviewFieldHeader>{field.label}</PolicyChangePreviewFieldHeader>
                {field.type === 'textArea' ? (
                  <PolicyChangePreviewTextArea value={field.value} />
                ) : (
                  <div id={field.label + ' Text'}>{field.value}</div>
                )}
              </PolicyChangePreviewField>
            ) : null,
          )}
        </TwoColumnContainer>
        <PolicyChangePreviewButtonsContainer>
          <PolicyChangePreviewButton
            onClick={() => {
              history.push('/dashboard');
            }}
            disabled={isSubmitting}
          >
            Cancel
          </PolicyChangePreviewButton>
          <PolicyChangePreviewButton
            onClick={() => {
              props.dispatch(setPolicyChangePreview(null));
            }}
            disabled={isSubmitting}
          >
            Edit Changes
          </PolicyChangePreviewButton>
          <PolicyChangePreviewButton
            id="Submit"
            onClick={() => {
              onClickSubmit();
            }}
            disabled={isSubmitting}
          >
            Submit
          </PolicyChangePreviewButton>
          {isSubmitting ? (
            <StyledLoaderContainer>
              <Loader loaderheight="20px" loaderwidth="20px" />
            </StyledLoaderContainer>
          ) : (
            <Spacer />
          )}
        </PolicyChangePreviewButtonsContainer>
      </PolicyChangePreviewContainer>
    </>
  );
};

PolicyChangePreview.propTypes = {
  dispatch: PropTypes.func,
  newDeductible: PropTypes.string,
  latestPolicyInfo: PropTypes.object,
  policyChangePreview: PropTypes.object,
  previewData: PropTypes.object,
  policyChangeSection: PropTypes.array,
};
const mapStateToProps = (state) => ({
  latestPolicyInfo: state.policyChangeSlice.latestPolicyInfo,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  previewData: state.policyChangeSlice.policyChangePreviewData,
  policyChangeSection: state.policyChangeSlice.policyChangeSection,
});
export default connect(mapStateToProps)(PolicyChangePreview);
