import * as React from 'react';
import UserLogout from '../../../logout/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  AgentContainer,
  AnnouncementContentArea,
  ChangeMyCoverageLinkVertical,
  ContentLink,
  CustomHTML1LinkVertical,
  CustomHTML2LinkVertical,
  CustomerInformationLinkVertical,
  GetQuoteContainer,
  HomeLinkHorizontal,
  HomeLinkVertical,
  IDCardsLinkVertical,
  LogoLink,
  LogoutLinkHorizontal,
  MakePaymentLinkVertical,
  MenuItems,
  MenuLinks,
  NeedHelpLinkHorizontal,
  OpenQuotesLinkVertical,
  PendingRequestLinkVertical,
  ProfileLinkHorizontal,
  ReportClaimLinkVertical,
  TemplateLogo,
  TemplateTwoNav,
  VerticalMenu,
  VerticalMenuAndDashboardContainer,
  VerticalMenuNewsAndDashboardContainer,
} from './styles';
import Routing from '../routing';
import MyAgent from '../../my-agent';
import GetQuote from '../../get-quote';
import Footer from '../../footer/index';
import NewsBanners from '../../../news-banners';

export const DesktopDashboardTemplate2 = (props) => {
  const {
    agentPanelAllowed,
    changeCoverageAllowed,
    customPage1Href,
    customPage1Html,
    customPage1Name,
    customPage2Html,
    customPage2Href,
    customPage2Name,
    CustomContentPage1,
    CustomContentPage2,
    needHelpAllowed,
    reportClaimAllowed,
    viewChangeRequestsAllowed,
    viewIdCard,
    crossLinkingAllowed,
    newsBanners,
    companyName,
  } = props;

  return (
    <>
      <TemplateTwoNav>
        <LogoLink to="/dashboard">
          <TemplateLogo src={`config/images/standard_logo.png`} alt={companyName + ' logo'} />
        </LogoLink>
        <MenuLinks>
          <HomeLinkHorizontal to="/dashboard">Home</HomeLinkHorizontal>
          <ProfileLinkHorizontal id="userProfileLink" to="/userProfile">
            Profile
          </ProfileLinkHorizontal>
          {needHelpAllowed && (
            <NeedHelpLinkHorizontal
              to={{
                pathname: '/needHelp',
                state: {
                  customerSlice: props.state.customerSlice,
                },
              }}
            >
              Need Help?
            </NeedHelpLinkHorizontal>
          )}
          <LogoutLinkHorizontal>
            <UserLogout {...props} />
          </LogoutLinkHorizontal>
        </MenuLinks>
      </TemplateTwoNav>
      {newsBanners && newsBanners.length > 0 ? (
        <VerticalMenuNewsAndDashboardContainer>
          <VerticalMenu>
            <MenuItems>
              <HomeLinkVertical>Home</HomeLinkVertical>
              {crossLinkingAllowed && <OpenQuotesLinkVertical>Open Quotes</OpenQuotesLinkVertical>}
              <MakePaymentLinkVertical>Make Payment</MakePaymentLinkVertical>
              {reportClaimAllowed && (
                <ReportClaimLinkVertical>Report Claim</ReportClaimLinkVertical>
              )}
              {changeCoverageAllowed && (
                <ChangeMyCoverageLinkVertical>Change Coverage</ChangeMyCoverageLinkVertical>
              )}
              {viewChangeRequestsAllowed && (
                <PendingRequestLinkVertical>Pending Requests</PendingRequestLinkVertical>
              )}
              {viewIdCard && <IDCardsLinkVertical>ID Cards</IDCardsLinkVertical>}
              <CustomerInformationLinkVertical>
                Customer Information
              </CustomerInformationLinkVertical>
            </MenuItems>
            {agentPanelAllowed && (
              <AgentContainer>
                <MyAgent />
              </AgentContainer>
            )}
            {crossLinkingAllowed && (
              <GetQuoteContainer>
                <GetQuote template="template2" />
              </GetQuoteContainer>
            )}
          </VerticalMenu>
          <Routing isMobile={false} />
          <AnnouncementContentArea>
            <NewsBanners />
          </AnnouncementContentArea>
        </VerticalMenuNewsAndDashboardContainer>
      ) : (
        <VerticalMenuAndDashboardContainer className="desktop">
          <VerticalMenu>
            <MenuItems>
              <HomeLinkVertical>Home</HomeLinkVertical>
              {crossLinkingAllowed && <OpenQuotesLinkVertical>Open Quotes</OpenQuotesLinkVertical>}
              <MakePaymentLinkVertical>Make Payment</MakePaymentLinkVertical>
              {reportClaimAllowed && (
                <ReportClaimLinkVertical>Report Claim</ReportClaimLinkVertical>
              )}
              {changeCoverageAllowed && (
                <ChangeMyCoverageLinkVertical>Change Coverage</ChangeMyCoverageLinkVertical>
              )}
              {viewChangeRequestsAllowed && (
                <PendingRequestLinkVertical>Pending Requests</PendingRequestLinkVertical>
              )}
              {viewIdCard && <IDCardsLinkVertical>ID Cards</IDCardsLinkVertical>}

              <CustomerInformationLinkVertical>
                Customer Information
              </CustomerInformationLinkVertical>
              {CustomContentPage1 && (
                <>
                  {customPage1Html ? (
                    <CustomHTML1LinkVertical>{customPage1Name}</CustomHTML1LinkVertical>
                  ) : (
                    <ContentLink href={customPage1Href} target="_blank">
                      {customPage1Name}
                    </ContentLink>
                  )}
                </>
              )}
              {CustomContentPage2 && (
                <>
                  {customPage2Html ? (
                    <CustomHTML2LinkVertical>{customPage2Name}</CustomHTML2LinkVertical>
                  ) : (
                    <ContentLink href={customPage2Href} target="_blank">
                      {customPage2Name}
                    </ContentLink>
                  )}
                </>
              )}
            </MenuItems>
            {agentPanelAllowed && (
              <AgentContainer>
                <MyAgent />
              </AgentContainer>
            )}
            {crossLinkingAllowed && (
              <GetQuoteContainer>
                <GetQuote template="template2" />
              </GetQuoteContainer>
            )}
          </VerticalMenu>
          <Routing isMobile={false} />
        </VerticalMenuAndDashboardContainer>
      )}
      <Footer />
    </>
  );
};

DesktopDashboardTemplate2.propTypes = {
  agentPanelAllowed: PropTypes.bool,
  changeCoverageAllowed: PropTypes.bool,
  customPage1Href: PropTypes.string,
  customPage1Html: PropTypes.string,
  customPage1Name: PropTypes.string,
  customPage2Href: PropTypes.string,
  customPage2Html: PropTypes.string,
  customPage2Name: PropTypes.string,
  CustomContentPage1: PropTypes.bool,
  CustomContentPage2: PropTypes.bool,
  needHelpAllowed: PropTypes.bool,
  reportClaimAllowed: PropTypes.bool,
  viewChangeRequestsAllowed: PropTypes.bool,
  viewIdCard: PropTypes.bool,
  crossLinkingAllowed: PropTypes.bool,
  newsBanners: PropTypes.array,
  companyName: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  agentPanelAllowed: state.configurationSlice.features.features.agentPanel,
  changeCoverageAllowed: state.configurationSlice.features.features.changeCoverage,
  customPage1Html: state.i18nSlice.i18n.customContent.customContentPage1.html,
  customPage1Href: state.i18nSlice.i18n.customContent.customContentPage1.url,
  customPage1Name: state.i18nSlice.i18n.customContent.customContentPage1.linkText,
  customPage2Html: state.i18nSlice.i18n.customContent.customContentPage2.html,
  customPage2Href: state.i18nSlice.i18n.customContent.customContentPage2.url,
  customPage2Name: state.i18nSlice.i18n.customContent.customContentPage2.linkText,
  CustomContentPage1: state.configurationSlice.features.features.CustomContentPage1,
  CustomContentPage2: state.configurationSlice.features.features.CustomContentPage2,
  needHelpAllowed: state.configurationSlice.features.features.needHelp,
  reportClaimAllowed: state.configurationSlice.features.features.reportClaim,
  viewChangeRequestsAllowed: state.configurationSlice.features.features.viewChangeRequests,
  viewIdCard: state.customerSlice.selfServiceConfigurations.selfServiceFeature.viewIdCard,
  crossLinkingAllowed: state.configurationSlice.features.features.cpPortalLinking,
  newsBanners: state.newsBannersSlice.newsBanners,
  companyName: state.configurationSlice.features.companyName,
  state,
});

DesktopDashboardTemplate2.propTypes = {
  state: PropTypes.shape({
    customerSlice: PropTypes.object,
  }),
};

export default connect(mapStateToProps)(DesktopDashboardTemplate2);
