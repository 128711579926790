import React from 'react';
import { useTheme } from 'styled-components';
import { styled } from '@mui/material/styles';

import { Check } from '@mui/icons-material';
import PropTypes from 'prop-types';

const PREFIX = 'Stepper';
const classes = {
  root: `${PREFIX}-root`,
  cta: `${PREFIX}-cta`,
  content: `${PREFIX}-content`,
  active: `${PREFIX}-active`,
  completed: `${PREFIX}-completed`,
  number: `${PREFIX}-number`,
  outerCircle: `${PREFIX}-outerCircle`,
  innerCircle: `${PREFIX}-innerCircle`,
};
const Completed = styled(Check)(({ styledtheme }) => ({
  [`&.${classes.completed}`]: {
    color: 'white',
    backgroundColor: styledtheme.primaryColor,
    borderRadius: '50%',
    padding: '2px',
    zIndex: 1,
    fontSize: 18,
  },
}));
const OuterCircle = styled('div')(({ styledtheme }) => ({
  [`&.${classes.outerCircle}`]: {
    width: 20,
    height: 20,
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: styledtheme.primaryColor,
    position: 'relative',
  },
}));
const InnerCircle = styled('div')(() => ({
  [`&.${classes.innerCircle}`]: {
    width: 5,
    height: 5,
    borderRadius: '50%',
    padding: '2px',
    backgroundColor: 'white',
    top: '50%',
    left: '50%',
    margin: '-5px 0 0 -5px',
    position: 'absolute',
  },
}));
const Number = styled('div')(() => ({
  [`&.${classes.number}`]: {
    borderRadius: '50%',
    border: `1px solid gray`,
    color: 'gray',
    width: '20px',
    height: '20px',
    padding: '2px',
  },
}));
const Root = styled('div')(({ styledtheme }) => ({
  [`&.${classes.root}`]: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  [`&:active .${classes.active}`]: {
    color: styledtheme.primaryColor,
  },
  [`&.${classes.completed}`]: {
    color: 'white',
    backgroundColor: styledtheme.primaryColor,
    borderRadius: '50%',
    padding: '2px',
    zIndex: 1,
    fontSize: 18,
  },
}));
export const CustomStepperIcons = props => {
  const { active, completed, icon } = props;
  const styledtheme = useTheme();
  return (
    <Root styledtheme={styledtheme} className={classes.root}>
      {active ? (
        <OuterCircle className={classes.outerCircle} styledtheme={styledtheme}>
          <InnerCircle className={classes.innerCircle}></InnerCircle>
        </OuterCircle>
      ) : completed ? (
        <Completed className={classes.completed} styledtheme={styledtheme} />
      ) : (
        <Number className={classes.number}>{icon}</Number>
      )}
    </Root>
  );
};
CustomStepperIcons.propTypes = {
  active: PropTypes.any,
  completed: PropTypes.any,
  icon: PropTypes.any,
};
