import styled from 'styled-components';
export const PendingPaymentAcceptanceButton = styled.button`
  color: black;
  background-color: white;
  border-radius: 4px;
  padding: 8px 24px;
  :hover {
    background-color: lightgray;
    color: black;
  }
  :active {
    background-color: gray;
    color: black;
  }
  :disabled {
    background-color: lightgray;
  }
  @media (max-width: 599px) {
    margin-top: 14px;
  }
`;
export const PendingPaymentContainer = styled.div.attrs({
  id: 'pendingPaymentContainer',
})`
  color: white;
  display: grid;
  grid-template-rows: 100%;
  justify-items: end;
  background-color: #932b27;
  max-width: 600px;
  max-height: 400px;
  padding: 16px 40px 70px 40px;
  border-radius: 5px;
  @media (max-width: 1600px) {
    margin-bottom: 20px;
    max-height: 800px;
  }
  @media (min-width: 1200px) {
    padding: 16px 40px 55px 40px;
  }
`;
export const ButtonContainer = styled.div``;
