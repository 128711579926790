import React from 'react';
import PropTypes from 'prop-types';
import Loader from '../../common/loader';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import { getClaimAttachments, getClaimDetails } from './claimDetailsAPI';
import ClaimDetailsRow from './claimDetailsRow';
import {
  ClaimDetailsContainer,
  ClaimDetailsHeader,
  ClaimDetailsTitle,
  ClaimInfoContainer,
  FileIcon,
  LoaderMargin,
  SectionHeader,
  UploadModalContainer,
} from './styles';
import ApiError from '../../common/api-error';
import FileUploadModal from '../file-upload';
import { ClaimAttachments } from './claimAttachments';

export const ClaimDetails = props => {
  const {
    isLoading,
    errors,
    claimDetails,
    claimDetailsPayments,
    claimDetailsDocuments,
    claimDocumentUpload,
    location: {
      state: { claimSystemId },
    },
    claimAttachments,
  } = props;

  const refreshAttachments = () => {
    props.dispatch(getClaimAttachments(props.location.state.claimSystemId));
  };

  React.useEffect(() => {
    props.dispatch(getClaimDetails(props.location.state.claimSystemId));
    if (claimDetailsDocuments) {
      refreshAttachments();
    }
  }, []);

  return isLoading ? (
    <LoaderMargin>
      <Loader />
    </LoaderMargin>
  ) : errors ? (
    <ApiError body="Unable to get claim information at this time. Please try again later." />
  ) : (
    <>
      <Helmet>
        <title>Claim Details</title>
      </Helmet>
      <ClaimDetailsContainer>
        <ClaimDetailsTitle>Claim Number {claimDetails.claimNumber}</ClaimDetailsTitle>
        <SectionHeader>General</SectionHeader>
        <ClaimInfoContainer>
          <ClaimDetailsRow label="Adjuster" value={claimDetails.adjusterName} />
          <ClaimDetailsRow label="Loss Description" value={claimDetails.shortDescription} />
          <ClaimDetailsRow label="Adjuster Phone" value={claimDetails.adjusterPhoneNumber} />
          <ClaimDetailsRow label="Report Number" value={claimDetails.caseNumber} />
          <ClaimDetailsRow label="Description" value={claimDetails.description} />
          {claimDetails.vehicleDescription && (
            <ClaimDetailsRow label="Vehicle" value={claimDetails.vehicleDescription} />
          )}
          <ClaimDetailsRow label="Authority Description" value={claimDetails.authorityContacted} />
          {claimDetails.driverLicense && (
            <ClaimDetailsRow label="License" value={claimDetails.driverLicense} />
          )}
          <ClaimDetailsRow label="Phone/Email" value={claimDetails.contact} />
          <ClaimDetailsRow label="Incident Date" value={claimDetails.lossDate} />
          <ClaimDetailsRow label="Best Way to Contact" value={claimDetails.bestWayToContact} />
          <ClaimDetailsRow label="Incident Time" value={claimDetails.lossTime} />

          {claimDetails.driverName && (
            <ClaimDetailsRow label="Driver" value={claimDetails.driverName} />
          )}
          <ClaimDetailsRow label="Loss Location" value={claimDetails.lossLocation} />
          {claimDetails.addr1 && (
            <>
              <ClaimDetailsRow
                label="Property Address"
                value={claimDetails.addr1}
                value2={claimDetails.addr2}
              />
            </>
          )}
        </ClaimInfoContainer>
        {claimDetails.claimPayments &&
          claimDetailsPayments &&
          claimDetails.claimPayments.map(payment => (
            <>
              <ClaimDetailsHeader>Payments</ClaimDetailsHeader>
              <ClaimInfoContainer>
                <ClaimDetailsRow label="Transaction" value={payment.paymentTransactionCd} />
                <ClaimDetailsRow label="Check Date" value={payment.checkDt} />
                <ClaimDetailsRow label="Pay To" value={payment.payToName} />
                <ClaimDetailsRow label="Mail To" value={payment.addr.addr1} />
                <ClaimDetailsRow label="Payment Type" value={payment.paymentType} />
                <ClaimDetailsRow label="Check Number" value={payment.checkNumber} />
                <ClaimDetailsRow label="Check Amount" value={payment.paidAmt} />
              </ClaimInfoContainer>
            </>
          ))}
        {claimDetailsDocuments && (
          <>
            <SectionHeader>
              <FileIcon />
              Documents
            </SectionHeader>

            <ClaimAttachments
              claimAttachments={claimAttachments}
              isLoading={isLoading}
              errors={errors}
            />
          </>
        )}
        {claimDocumentUpload && (
          <UploadModalContainer>
            <FileUploadModal
              id="fileUploadModal"
              type="Claim"
              beanRef={claimSystemId}
              refreshAttachments={refreshAttachments}
            />
          </UploadModalContainer>
        )}
      </ClaimDetailsContainer>
    </>
  );
};

ClaimDetails.propTypes = {
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  claimDetails: PropTypes.object,
  claimDetailsPayments: PropTypes.bool,
  claimDetailsDocuments: PropTypes.bool,
  location: PropTypes.shape({
    state: PropTypes.shape({
      claimSystemId: PropTypes.string,
      uploadDocumentsClicked: PropTypes.bool,
    }),
  }),
  errors: PropTypes.any,
  claimAttachments: PropTypes.array,
  claimDocumentUpload: PropTypes.bool,
};
const mapStateToProps = state => ({
  state,
  claimDetails: state.claimDetailsSlice.claimDetails,
  claimDetailsPayments: state.configurationSlice.features.features.claimDetailsPayments,
  claimDetailsDocuments: state.configurationSlice.features.features.claimDetailsDocuments,
  claimDocumentUpload: state.configurationSlice.features.features.claimDocumentUpload,
  isLoading: state.claimDetailsSlice.isLoading,
  errors: state.claimDetailsSlice.errors,
  claimAttachments: state.claimDetailsSlice.claimAttachments,
});
ClaimDetails.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(ClaimDetails);
