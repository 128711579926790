import React, { useState } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Collapse from '@mui/material/Collapse';
import { TableCell, TableHead, TableRow, TableBody, TableContainer, Box } from '@mui/material';
import { DISPLAY_DATE_FORMAT } from '../../common/constants';
import moment from 'moment';
import PropTypes from 'prop-types';
import { BillNumber, BillNumberHeader } from './styles';
export const PayScheduleTable = props => {
  const { schedule } = props;
  let initialState = schedule.reduce((result, plan) => {
    result[plan.id] = false;
    return result;
  }, {});
  const [showPlanFees, setShowPlanFees] = useState(initialState);
  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>
              <BillNumberHeader>#</BillNumberHeader>
            </TableCell>
            <TableCell>Bill Date</TableCell>
            <TableCell>Due Date</TableCell>
            <TableCell>Premium Amount</TableCell>
            <TableCell>Bill Amount</TableCell>
          </TableRow>
        </TableHead>
        {schedule.filter(plan => plan.statusCd !== 'Billed').map((plan, i) => {
          return (
            <TableBody key={plan.id}>
              <TableRow>
                <TableCell>
                  {showPlanFees[plan.id] ? (
                    <KeyboardArrowDownIcon
                      onClick={() =>
                        setShowPlanFees({
                          ...initialState,
                          [plan.id]: !showPlanFees[plan.id],
                        })
                      }
                    />
                  ) : (
                    <KeyboardArrowRightIcon
                      onClick={() =>
                        setShowPlanFees({
                          ...initialState,
                          [plan.id]: !showPlanFees[plan.id],
                        })
                      }
                    />
                  )}
                  <BillNumber>{i + 1}</BillNumber>
                </TableCell>
                <TableCell>{moment(plan.rollDt).format(DISPLAY_DATE_FORMAT)}</TableCell>
                <TableCell>{moment(plan.dueDt).format(DISPLAY_DATE_FORMAT)}</TableCell>
                <TableCell>${parseFloat(plan.grossAmt).toFixed(2)}</TableCell>
                <TableCell>
                  $
                  {plan.scheduleDetails
                    .reduce((lastAmount, billPart) => {
                      return billPart && billPart.amount
                        ? lastAmount + parseFloat(billPart.amount)
                        : lastAmount;
                    }, 0)
                    .toFixed(2)}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell style={{ border: 0 }}>
                  <Collapse in={showPlanFees[plan.id]} timeout="auto" unmountOnExit>
                    <Box>
                      <Table>
                        <TableHead />
                        <TableBody>
                          {plan.scheduleDetails.map(row => {
                            return (
                              <TableRow key={row.id}>
                                <TableCell style={{ border: 0, position: 'relative', left: '7px' }}>
                                  {row.categoryCd}
                                </TableCell>
                                <TableCell style={{ border: 0, textAlign: 'end' }}>
                                  ${parseFloat(row.amount).toFixed(2)}
                                </TableCell>
                              </TableRow>
                            );
                          })}
                        </TableBody>
                      </Table>
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>
            </TableBody>
          );
        })}
      </Table>
    </TableContainer>
  );
};
PayScheduleTable.propTypes = {
  schedule: PropTypes.array,
};
export default PayScheduleTable;
