import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  fieldAnswers: [],
};

export const makePaymentAnonymousStepOneSlice = createSlice({
  name: 'makePaymentAnonymousStepOne',
  initialState,
  reducers: {
    resetStepOne: () => {
      return initialState;
    },
    setFieldAnswers: (state, action) => {
      return { ...state, fieldAnswers: action.payload };
    },
  },
});
export const { setFieldAnswers } = makePaymentAnonymousStepOneSlice.actions;
export default makePaymentAnonymousStepOneSlice.reducer;
