import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { ReportClaimCancelButton } from '../styles';
import {
  PolicyInfoFieldContainer,
  PolicyInfoFieldHeader,
  ReportClaimNextButton,
  ReportClaimButtonContainer,
  RequiredField,
  TermsAndConditionsContainer,
  TextLink,
  VerificationHeader,
} from './styles';
import { useSnackbar } from 'notistack';
import Loader from '../../../common/loader';
import { setRegistrationToken } from '../../login/loginReducer';
import {
  getRegistrationFields,
  verifyRegistrationFields,
} from '../../create-account/createAccountApi';
import { setFieldAnswers, setPrivacyAcceptance } from './anonymousReportClaimStepOneReducer';
import { getPolicyInfoInputField } from '../../../../utils/fields';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';
import Modal from '../../../common/modal-with-html';
import termsHtml from '../../../../config/documents/tos.html';
import privacyHtml from '../../../../config/documents/privacy.html';
import { useHistory } from 'react-router';

const Step1 = props => {
  const {
    dispatch,
    registrationFields,
    handleNext,
    privacyAcceptance,
    registrationToken,
    fieldAnswers,
    i18n,
    customTOS,
    customPrivacyStatement,
  } = props;

  const [answers, setAnswers] = useState(fieldAnswers);
  const [termsAndConditionsDialogOpen, setTermsAndConditionsDialogOpen] = useState(false);
  const [privacyDialogOpen, setPrivacyDialogOpen] = useState(false);
  const [isConditionsAccepted, setConditionAccepted] = useState(privacyAcceptance);
  const [isLoading, setLoading] = useState(false);
  const [isNextButtonClicked, setNextButtonClicked] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    props.dispatch(setRegistrationToken(''));
    setLoading(true);
    props.dispatch(getRegistrationFields(enqueueSnackbar));
  }, []);

  useEffect(() => {
    if (registrationFields && registrationFields.length > 0) {
      setLoading(false);
    }
  }, [registrationFields]);

  useEffect(() => {
    if (registrationToken && registrationToken.length > 0 && isNextButtonClicked) {
      setLoading(false);
      handleNext();
    }
  }, [registrationToken]);

  const areRequiredFieldsCompleted = () => {
    if (
      registrationFields.length === 0 ||
      Object.entries(answers).length !== registrationFields.length
    )
      return false;
    for (const [, value] of Object.entries(answers)) {
      if (!value.fieldComplete) return false;
    }
    return isConditionsAccepted;
  };

  const nextButtonClickHandler = () => {
    // map question as name and answer as value
    const registrationAnswers = [];
    for (let i = 0; i < registrationFields.length; i++) {
      registrationAnswers[i] = { name: registrationFields[i].value, value: answers[i].answer };
    }

    const payload = {
      optionKey: 'serviceportal',
      registrationInfo: registrationAnswers,
    };
    setNextButtonClicked(true);
    dispatch(setFieldAnswers(answers));
    dispatch(setPrivacyAcceptance(true));
    dispatch(verifyRegistrationFields(payload, enqueueSnackbar, true));
  };
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Report Claim - Policy Information</title>
      </Helmet>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          <VerificationHeader>
            Answer the following question to verify a valid account.
          </VerificationHeader>
          {registrationFields.map((registrationField, index) => (
            <PolicyInfoFieldContainer key={index} id={registrationField.name + 'FieldContainer'}>
              <PolicyInfoFieldHeader>
                {registrationField.name}
                <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'reportClaimAnonymous.stepOne' + registrationField.name + '.Tooltip',
                  )}
                />
              </PolicyInfoFieldHeader>
              {getPolicyInfoInputField(
                registrationField.value,
                index,
                answers,
                setAnswers,
                'ReportClaimAnon',
              )}
            </PolicyInfoFieldContainer>
          ))}
          <TermsAndConditionsContainer>
            <label htmlFor="termsAndConditions">
              <input
                id="termsAndConditions"
                type="checkbox"
                checked={isConditionsAccepted}
                onChange={() => {
                  setConditionAccepted(!isConditionsAccepted);
                }}
              />
              <span>
                {' '}
                I have read and accept the{' '}
                <TextLink
                  onClick={() => {
                    setTermsAndConditionsDialogOpen(true);
                  }}
                >
                  {getText(i18n, 'general.termsOfServiceTitle', 'Terms of Service')}
                </TextLink>{' '}
                and{' '}
                <TextLink
                  onClick={() => {
                    setPrivacyDialogOpen(true);
                  }}
                >
                  {getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
                </TextLink>
                .
                <Modal
                  modalHtml={termsHtml}
                  dialogOpen={termsAndConditionsDialogOpen}
                  customModal={customTOS}
                  modalTitle={getText(i18n, 'general.termsOfServiceTitle', 'Terms of Service')}
                  setDialogClosed={() => setTermsAndConditionsDialogOpen(false)}
                />
                <Modal
                  modalHtml={privacyHtml}
                  dialogOpen={privacyDialogOpen}
                  customModal={customPrivacyStatement}
                  modalTitle={getText(i18n, 'general.privacyStatementTitle', 'Privacy Statement')}
                  setDialogClosed={() => setPrivacyDialogOpen(false)}
                />
              </span>
            </label>
          </TermsAndConditionsContainer>
          <ReportClaimButtonContainer>
            <ReportClaimCancelButton onClick={() => history.push('/')}>
              Cancel
            </ReportClaimCancelButton>
            <ReportClaimNextButton
              id="Next"
              disabled={!areRequiredFieldsCompleted()}
              onClick={() => {
                nextButtonClickHandler();
              }}
            >
              Next
            </ReportClaimNextButton>
          </ReportClaimButtonContainer>
        </>
      )}
    </>
  );
};

Step1.propTypes = {
  dispatch: PropTypes.any,
  registrationFields: PropTypes.array,
  registrationToken: PropTypes.string,
  termsOfServiceText: PropTypes.string,
  privacyStatementText: PropTypes.string,
  handleNext: PropTypes.func,
  fieldAnswers: PropTypes.object,
  privacyAcceptance: PropTypes.bool,
  customPrivacyStatement: PropTypes.bool,
  customTOS: PropTypes.bool,
  i18n: PropTypes.object,
};

export const mapStateToProps = state => ({
  registrationFields: state.loginSlice.registrationFields,
  registrationToken: state.loginSlice.registrationToken,
  fieldAnswers: state.anonymousReportClaimStepOneSlice.fieldAnswers,
  privacyAcceptance: state.anonymousReportClaimStepOneSlice.privacyAcceptance,
  customPrivacyStatement: state.configurationSlice.features.features.customPrivacyStatement,
  customTOS: state.configurationSlice.features.features.customTOS,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(Step1);
