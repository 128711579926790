import { API } from '../../../../utils/api/index';
import {
  setConfirmationNumber,
  setErrors,
  setLoading,
  setResponse,
} from '../step-four-recap/makePaymentStepFourReducer';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../../common/constants';
import { gaEvent } from '../../../../utils/google-analytics';
import { ga4Event } from '../../../../utils/google-analytics-4';

export const makePayment = (systemId, body, payDate) => {
  return dispatch => {
    dispatch(setLoading(true));
    if (payDate === moment().format(ISO_DATE_FORMAT)) {
      // Only statement account requires payment spread.
      // Since this is controlled by IN API, we can always send in "autoSpread=true".
      API.post(`/billingAccounts/${systemId}/paymentRequest?autoSpread=true`, body)
        .then(response => {
          if (response.status === 202) {
            // GA4 event for payment success
            ga4Event({
              category: 'make-a-payment',
              action: 'payment_complete',
              label: 'loggedin_payment',
            });

            dispatch(setResponse(body));
            if (body.paymentMethod === 'Credit Card') {
              // /billingHistories call is here
              API.get(`${response.headers.link}`)
                .then(response => {
                  console.log(response);
                  dispatch(setConfirmationNumber(response.data.transactionId));
                })
                .catch(err => console.log(err));
            }
          }
        })
        .catch(err => {
          // GA4 event for payment failure
          ga4Event({
            category: 'make-a-payment-failure',
            action: 'payment_failed',
            label: 'loggedin_payment',
          });
          const apiErrorMessage = err?.response?.data?.message ? err.response.data.message : '';
          let displayError;
          if (
            apiErrorMessage
              .toLowerCase()
              .includes('cannot accept payments on expired account')
          ) {
            displayError =
              apiErrorMessage;
          } else if (apiErrorMessage.toLowerCase().includes('unable to authorize credit card payment')) {
            displayError =
              'Unable to authorize credit card payment. Please try again later or contact your agent.';
          } else if (
            apiErrorMessage.toLowerCase().includes('unable to authorize payment amount of')
          ) {
            displayError = apiErrorMessage + ' Please try again later or contact your agent.';
          } else if (
            apiErrorMessage
              .toLowerCase()
              .includes('unable to process makePayment request for the amount')
          ) {
            displayError =
              apiErrorMessage.replace('makePayment', 'payment') +
              ' Please try again later or contact your agent.';
          } else {
            displayError = 'Please try again later or contact your agent.';
          }
          dispatch(setErrors(displayError));
        })
        .finally(() => {
          gaEvent({ category: 'make-a-payment', action: 'complete' });
          ga4Event({ category: 'make-a-payment', action: 'complete' });
          dispatch(setLoading(false));
        });
    } else {
      // SP v3 doesn't spread payment for scheduled payments, which may be wrong.
      // We can keep consistency with SP v3 for now until the customers raise concerns.
      API.post(`/billingAccounts/${systemId}/scheduledPaymentRequest`, body)
        .then(response => {
          if (response.status === 202) {
            dispatch(setResponse(body));
          }
        })
        .catch(err => {
          const errMsg = err?.response?.data?.message ? err.response.data.message : 'Error';
          dispatch(setErrors(errMsg));
        })
        .finally(() => {
          gaEvent({ category: 'make-a-payment', action: 'complete' });
          ga4Event({ category: 'make-a-payment', action: 'complete' });
          dispatch(setLoading(false));
        });
    }
  };
};
