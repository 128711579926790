import { GA4React } from 'ga-4-react';

let GA4;
let companyName;

export const ga4Event = eventBody => {
  if (GA4React.isInitialized()) {
    const { action, category, label } = eventBody;
    GA4.gtag('event', action, {
      event_label: label ? label : `${category}_${action}`,
      event_category: category,
      non_interaction: false,
      name: process.env.APP_NAME,
      clientId: companyName,
    });
  }
};
export const ga4PageView = location => {
  if (GA4React.isInitialized()) {
    GA4.gtag('event', 'page_view', {
      page_path: location,
      page_location: location || window.location,
      page_title: window.document.title || document.title,
      name: process.env.APP_NAME,
      clientId: companyName,
    });
  }
};

export const initializeGoogleAnalytics4 = (keys, _companyName) => {
  companyName = _companyName;
  let isProd = false;
  if (process && process.env && process.env.NODE_ENV) {
    isProd = process.env.NODE_ENV !== 'development';
  }

  if (keys) {
    let trackingCode = '';
    if (isProd && keys.googleAnalytics4GwProd) {
      trackingCode = keys.googleAnalytics4GwProd;
    }

    if (!isProd && keys.googleAnalytics4GwDev) {
      trackingCode = keys.googleAnalytics4GwDev;
    }

    // don't send client GA analytics in preprod envs
    if (isProd && keys.googleAnalytics4) {
      trackingCode = keys.googleAnalytics4;
    }

    const ga4react = new GA4React(trackingCode, { send_page_view: true });

    if (trackingCode !== '') {
      ga4react.initialize().then(
        ga4 => {
          GA4 = ga4;
          ga4PageView(window.location.href);
        },
        err => {
          console.error(err);
        },
      );
    }
  }
};
