import React, { useEffect } from 'react';
import Script from 'react-load-script';
import PropTypes from 'prop-types';
import { getPlaceMetadata, getUpdatedAddressMetadata } from './addressesApi';
import { connect } from 'react-redux';
import Loader from '../loader';
import {
  AddressLookupHeader,
  ErrorMessage,
  LookupAddressInput,
  ManualAddressContainer,
  ManualAddressFieldHeader,
  ManualAddressFieldsContainer,
  ManualAddressGenericField,
  ManualAddressSmallFieldsContainer,
  ManualAddressTextInputField,
  ManualAddressZipErrorMessage,
  ManualAddressZipInput,
  RequiredField,
  StateSelectComponent,
  VerificationMessage,
  VerifyingMessage,
} from './styles';
import { defaultLookupAddress, getFormattedAddress, getStateByCode } from './addressUtils';
import {
  setLookupAddress,
  setLookupAddressVerificationError,
  setLookupAddressVerified,
} from './addressesReducer';
import Select from 'react-select';
import { find } from 'lodash';
import { US_STATES } from '../constants';

const Addresses = props => {
  const {
    dispatch,
    isLoading,
    lookupAddress,
    features,
    lookupAddressVerificationError,
    lookupAddressVerified,
    isRequiredField,
    label,
    defaultAddress,
    isAnonymousUser,
  } = props;

  const [scriptUrl, setScriptUrl] = React.useState(undefined);
  const [selectedState, setSelectedState] = React.useState(
    getStateByCode(defaultAddress?.stateProvCd || ''),
  );
  const [addr1, setAddr1] = React.useState(defaultAddress?.addr1 || '');
  const [addr2, setAddr2] = React.useState(defaultAddress?.addr2 || '');
  const [city, setCity] = React.useState(defaultAddress?.city || '');
  const [county, setCounty] = React.useState(defaultAddress?.county || '');
  const [postalCode, setPostalCode] = React.useState(defaultAddress?.postalCode || '');
  const zipErrorMessage =
    postalCode && postalCode.length !== 0 && postalCode.length < 5 ? 'Invalid Entry' : '';

  const autocomplete = {};
  const defaultComponentMeta = {
    scriptUrl: null,
    apiKey: null,
    value: '',
  };
  const [componentMeta, setComponentMeta] = React.useState(defaultComponentMeta);

  const handlePlaceSelect = async () => {
    if (!autocomplete[props.id]) return;
    const place = autocomplete[props.id].getPlace();
    setComponentMeta({ ...componentMeta, value: place.formatted_address });
    dispatch(getPlaceMetadata(place, isAnonymousUser));
  };

  const handleScriptLoad = () => {
    const options = {
      types: [`address`],
    };
    /* global google */
    autocomplete[props.id] = new google.maps.places.Autocomplete(
      document.getElementById(props.id),
      options,
    );
    autocomplete[props.id].addListener('place_changed', handlePlaceSelect);
  };

  const onInputChange = e => {
    dispatch(setLookupAddressVerificationError(false));
    dispatch(setLookupAddressVerified(false));
    setComponentMeta({ ...componentMeta, value: e.target.value });
    if (e.target.value === '' || !lookupAddressVerified || lookupAddressVerificationError) {
      props.handlePlaceSelect(defaultLookupAddress);
    }
  };

  useEffect(() => {
    if (!!addr1 || !!addr2 || !!city || !!selectedState || !!postalCode) {
      props.handlePlaceSelect({
        addr1,
        addr2,
        city,
        stateProvCd: selectedState ? selectedState.value : '',
        postalCode,
        county,
      });
    }
  }, [addr1, addr2, city, selectedState, postalCode]);

  useEffect(() => {
    if (defaultAddress && defaultAddress.addr1) {
      setAddr1(defaultAddress.addr1);
      setAddr2(defaultAddress.addr2);
      setCity(defaultAddress.city);
      setSelectedState(getStateByCode(defaultAddress.stateProvCd));
      setPostalCode(defaultAddress.postalCode);
      setCounty(defaultAddress.county);
      props.handlePlaceSelect(defaultAddress);
    }
  }, [defaultAddress]);

  const onAddressFieldBlur = updatedAddress => {
    // verify only if the entered address is not empty and different from the last verified address
    if (
      updatedAddress.trim().length > 0 &&
      (!lookupAddressVerified || updatedAddress.trim() !== componentMeta.value.trim())
    ) {
      dispatch(getUpdatedAddressMetadata(updatedAddress, isAnonymousUser));
    }
  };

  useEffect(() => {
    const apiKey = features?.keys?.googlePlacesApi;
    if (apiKey && apiKey.length > 0) {
      setScriptUrl(
        apiKey
          ? `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`
          : undefined,
      );
    }
  }, [features]);

  useEffect(() => {
    if (scriptUrl) {
      props.handlePlaceSelect(lookupAddress ? lookupAddress : defaultLookupAddress);
      if (lookupAddressVerified) {
        setComponentMeta({ ...componentMeta, value: getFormattedAddress(lookupAddress) });
      }
    }
  }, [lookupAddress]);

  useEffect(() => {
    if (lookupAddressVerificationError) {
      dispatch(setLookupAddress(defaultLookupAddress));
    }
  }, [lookupAddressVerificationError]);

  useEffect(() => {
    setComponentMeta(defaultComponentMeta);
    dispatch(setLookupAddress(defaultLookupAddress));
    dispatch(setLookupAddressVerificationError(false));
    dispatch(setLookupAddressVerified(false));
  }, []);

  return (
    <>
      {scriptUrl && scriptUrl.length > 0 ? (
        <div>
          <AddressLookupHeader>
            {label || 'Address'} {isRequiredField && <RequiredField>*</RequiredField>}
          </AddressLookupHeader>
          <Script url={scriptUrl} onLoad={handleScriptLoad} />
          <LookupAddressInput
            id={props.id}
            type="text"
            value={componentMeta.value}
            onChange={onInputChange}
            aria-label="Location Address"
            onKeyDown={e => {
              // this prevents the navigation bug.
              if (e.keyCode === 13) {
                // when enter key is detected, stop propagating event further and focus out
                e.preventDefault();
                document.getElementById(props.id).blur();
              }
            }}
            onBlur={e => {
              onAddressFieldBlur(e.target.value);
            }}
          />
          {isLoading && (
            <VerifyingMessage>
              Verifying address <Loader loaderheight="15px" loaderwidth="15px" />
            </VerifyingMessage>
          )}
          {lookupAddressVerificationError && <ErrorMessage>Validation Error</ErrorMessage>}
          {lookupAddressVerified && (
            <VerificationMessage>Address has been verified</VerificationMessage>
          )}
        </div>
      ) : (
        <>
          <AddressLookupHeader>
            {label || 'Address'} {isRequiredField && <RequiredField>*</RequiredField>}
          </AddressLookupHeader>
          <ManualAddressContainer>
            <ManualAddressFieldsContainer>
              <ManualAddressTextInputField
                id="address1"
                name="address1"
                aria-label="Address Line 1"
                value={addr1}
                onChange={e => {
                  setAddr1(e.target.value);
                }}
              />
              <ManualAddressTextInputField
                id="address2"
                name="address2"
                aria-label="Address Line 2"
                value={addr2}
                onChange={e => {
                  setAddr2(e.target.value);
                }}
              />
            </ManualAddressFieldsContainer>
            <ManualAddressSmallFieldsContainer>
              <ManualAddressGenericField>
                <ManualAddressFieldHeader htmlFor="city">
                  City{isRequiredField && <RequiredField>*</RequiredField>}
                </ManualAddressFieldHeader>
                <ManualAddressTextInputField
                  input="city"
                  name="city"
                  value={city}
                  aria-label="City"
                  onChange={e => {
                    setCity(e.target.value);
                  }}
                />
              </ManualAddressGenericField>
              <StateSelectComponent>
                <ManualAddressGenericField>
                  <ManualAddressFieldHeader htmlFor="state">
                    State{isRequiredField && <RequiredField>*</RequiredField>}
                  </ManualAddressFieldHeader>
                  <Select
                    name="stateList"
                    aria-label="State"
                    id="stateList"
                    defaultValue={find(US_STATES, state => state.value === selectedState)}
                    options={US_STATES}
                    value={selectedState}
                    onChange={option => {
                      setSelectedState(option);
                    }}
                  />
                </ManualAddressGenericField>
              </StateSelectComponent>
            </ManualAddressSmallFieldsContainer>
            <ManualAddressSmallFieldsContainer>
              <ManualAddressGenericField>
                <ManualAddressFieldHeader htmlFor="zip">
                  ZIP{isRequiredField && <RequiredField>*</RequiredField>}
                </ManualAddressFieldHeader>
                <ManualAddressZipInput
                  id="zip"
                  name="zip"
                  aria-label="Zip"
                  value={postalCode}
                  onChange={e => {
                    setPostalCode(e.target.value);
                  }}
                />
                <ManualAddressZipErrorMessage>
                  {zipErrorMessage && zipErrorMessage}
                </ManualAddressZipErrorMessage>
              </ManualAddressGenericField>
              <div>
                <ManualAddressFieldHeader htmlFor="county">County </ManualAddressFieldHeader>
                <ManualAddressTextInputField
                  name="county"
                  defaultValue={county}
                  aria-label="County"
                  onChange={e => {
                    setCounty(e.target.value);
                  }}
                />
              </div>
            </ManualAddressSmallFieldsContainer>
          </ManualAddressContainer>
        </>
      )}
    </>
  );
};
Addresses.propTypes = {
  features: PropTypes.object,
  id: PropTypes.string.isRequired,
  handlePlaceSelect: PropTypes.func,
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  lookupAddress: PropTypes.any,
  lookupAddressVerificationError: PropTypes.bool,
  lookupAddressVerified: PropTypes.bool,
  isRequiredField: PropTypes.bool,
  label: PropTypes.string,
  isAnonymousUser: PropTypes.bool,
  defaultAddress: PropTypes.object,
};
const mapStateToProps = state => ({
  features: state.configurationSlice.features,
  isLoading: state.addressesSlice.isLoading,
  lookupAddress: state.addressesSlice.lookupAddress,
  lookupAddressVerificationError: state.addressesSlice.lookupAddressVerificationError,
  lookupAddressVerified: state.addressesSlice.lookupAddressVerified,
  state,
});

export default connect(mapStateToProps)(Addresses);
