import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import { getReplies } from './selectors';
import {
  AnonymousReportClaimsStepSixContainer,
  LoaderMargin,
  QuestionReplyContainer,
  QuestionsMobileLabel,
  QuestionLabel,
  RecapDesktopLabel,
  RecapText,
  ResponsesMobileLabel,
} from './styles';
import {
  ReportClaimButton,
  ReportClaimNextButton,
  ReportClaimButtonContainer,
} from '../step1/styles';
import { submitClaim } from './anonymousReportClaimSixApi.js';
import { setClaimSubmit } from './anonymouseReportClaimStepSixReducer';
import { useSnackbar } from 'notistack';
import {
  DISPLAY_DATE_FORMAT,
  TIME_FORMAT_24_HRS,
  TIME_FORMAT_AM_PM,
} from '../../../common/constants';
import { gaEvent } from '../../../../utils/google-analytics';
import Loader from '../../../common/loader';
import { ReportClaimCancelButton } from '../styles';
import { useHistory } from 'react-router';
import { ClaimEventNameLabel } from '../step4/styles';
import { ga4Event } from '../../../../utils/google-analytics-4';
import ApiError from '../../../common/api-error';

export const Step6 = props => {
  const {
    body,
    claimEventValidationErrors,
    lossDate,
    lossTime,
    address1,
    address2,
    address3,
    address4,
    county,
    country,
    city,
    claimEvent,
    dispatch,
    driverLicense,
    driverName,
    handleStep,
    policyNumber,
    postalCode,
    questionReplies,
    stateProvince,
    whatHappened,
    registrationToken,
    isClaimEventValidated,
    uploadFilesLabel,
  } = props;

  const [isSubmitDisabled, setSubmitDisabled] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    gaEvent({ category: 'report-a-claim', action: 'complete' });
    ga4Event({ category: 'report-a-claim', action: 'complete' });
    props.dispatch(setClaimSubmit(''));
  }, []);
  const history = useHistory();
  return (
    <>
      <Helmet>
        <title>Report Claim Anonymous Recap and Submit</title>
      </Helmet>
      {!isClaimEventValidated ? (
        <>
          {claimEventValidationErrors === '' ? (
            <LoaderMargin>
              <Loader />
              {uploadFilesLabel && <RecapDesktopLabel>Uploading file(s)...</RecapDesktopLabel>}
            </LoaderMargin>
          ) : null}
          {claimEventValidationErrors !== '' ? <ApiError title="Claim Validation Errors" /> : null}
        </>
      ) : (
        <AnonymousReportClaimsStepSixContainer>
          <>
            <ClaimEventNameLabel>{claimEvent && claimEvent.name}</ClaimEventNameLabel>
            <RecapText>
              The following is a recap of the incident information you have provided. Please review
              and ensure everything is correct:
            </RecapText>
            <QuestionReplyContainer>
              {policyNumber && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="policyNumberLabel"> Policy Number</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="policyNumberText"> {policyNumber}</div>
                </>
              )}
              {driverName && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="driverNameLabel"> Driver Name</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="driverNameText"> {driverName}</div>
                </>
              )}
              {driverLicense && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel> Driver License</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div> {driverLicense}</div>
                </>
              )}
              {lossDate && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="lossDateLabel"> Loss Date</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="lossDateText"> {moment(lossDate).format(DISPLAY_DATE_FORMAT)}</div>
                </>
              )}
              {lossTime && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="lossTimeLabel"> Loss Time</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="lossTimeText">
                    {moment(lossTime, TIME_FORMAT_24_HRS).format(TIME_FORMAT_AM_PM)}
                  </div>
                </>
              )}
              {country && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="countryLabel"> Country</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="countryText"> {country}</div>
                </>
              )}
              {address1 && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="address1Label"> Address 1</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="address1Text"> {address1}</div>
                </>
              )}
              {address2 && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="address2Label"> Address 2</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="address2Text"> {address2}</div>
                </>
              )}
              {address3 && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="address3Label"> Address 3</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="address3Text"> {address3}</div>
                </>
              )}
              {address4 && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="address4Label"> Address 4</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="address4Text"> {address4}</div>
                </>
              )}
              {county && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="countyLabel"> County</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="countyText"> {county}</div>
                </>
              )}
              {city && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="cityLabel"> City</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="cityText"> {city}</div>
                </>
              )}
              {country && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="countryLabel"> Country</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="countryText"> {country}</div>
                </>
              )}
              {stateProvince && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="stateLabel"> State</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="stateText"> {stateProvince}</div>
                </>
              )}
              {postalCode && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="postalCodeLabel"> Postal Code</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="postalCodeText"> {postalCode}</div>
                </>
              )}
              {whatHappened && (
                <>
                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                  <QuestionLabel id="whatHappenedLabel"> What Happened</QuestionLabel>
                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                  <div id="whatHappenedText"> {whatHappened}</div>
                </>
              )}
              {questionReplies &&
                questionReplies.map(question => {
                  return question.map(thisQuestion => {
                    if (thisQuestion.subQuestions) {
                      return (
                        <React.Fragment key={thisQuestion.id}>
                          <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                          <QuestionLabel id={thisQuestion.topDesc + ' Label'}>
                            {' '}
                            {thisQuestion.topDesc}
                          </QuestionLabel>
                          <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                          <div id={thisQuestion.topDesc + ' Text'}> {thisQuestion.topVal}</div>
                          {thisQuestion.subQuestions.map(subQuestion => {
                            if (subQuestion.Value) {
                              return (
                                <React.Fragment key={subQuestion.id}>
                                  <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                                  <QuestionLabel id={subQuestion.shortDesc + ' Label'}>
                                    {subQuestion.shortDesc}
                                  </QuestionLabel>
                                  <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                                  <div id={subQuestion.shortDesc + ' Text'}>
                                    {subQuestion.Value}
                                  </div>
                                </React.Fragment>
                              );
                            }
                            return null;
                          })}
                        </React.Fragment>
                      );
                    } else {
                      if (thisQuestion.Value) {
                        return (
                          <React.Fragment key={thisQuestion.id}>
                            <QuestionsMobileLabel>Question</QuestionsMobileLabel>
                            <QuestionLabel id={thisQuestion.shortDesc + ' Label'}>
                              {thisQuestion.shortDesc}
                            </QuestionLabel>
                            <ResponsesMobileLabel>Responses</ResponsesMobileLabel>
                            <div id={thisQuestion.shortDesc + ' Text'}>{thisQuestion.Value}</div>
                          </React.Fragment>
                        );
                      }
                      return null;
                    }
                  });
                })}
            </QuestionReplyContainer>
          </>

          <ReportClaimButtonContainer>
            <ReportClaimCancelButton onClick={() => history.push('/')}>
              Cancel
            </ReportClaimCancelButton>
            <ReportClaimNextButton
              id="Submit"
              disabled={isSubmitDisabled}
              onClick={async () => {
                setSubmitDisabled(true);
                await dispatch(submitClaim(body, registrationToken, enqueueSnackbar));
              }}
            >
              Submit Claim
            </ReportClaimNextButton>
            <ReportClaimButton
              id="Previous"
              onClick={() => {
                handleStep();
              }}
            >
              Back
            </ReportClaimButton>
          </ReportClaimButtonContainer>
        </AnonymousReportClaimsStepSixContainer>
      )}
    </>
  );
};
Step6.propTypes = {
  address1: PropTypes.string,
  address2: PropTypes.string,
  address3: PropTypes.string,
  address4: PropTypes.string,
  body: PropTypes.object,
  city: PropTypes.string,
  claimEvent: PropTypes.any,
  county: PropTypes.string,
  country: PropTypes.string,
  driverName: PropTypes.string,
  driverLicense: PropTypes.string,
  dispatch: PropTypes.func,
  handleStep: PropTypes.func,
  lossDate: PropTypes.string,
  lossTime: PropTypes.string,
  policyNumber: PropTypes.string,
  postalCode: PropTypes.any,
  questionReplies: PropTypes.array,
  stateProvince: PropTypes.string,
  whatHappened: PropTypes.any,
  registrationToken: PropTypes.string,
  claimSubmission: PropTypes.string,
  referenceNumber: PropTypes.string,
  claimEventValidationErrors: PropTypes.string,
  isClaimEventValidated: PropTypes.bool,
  uploadFilesLabel: PropTypes.bool,
};
const mapStateToProps = state => ({
  address1: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.addr1,
  address2: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.addr2,
  address3: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.addr3,
  address4: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.addr4,
  body: state.anonymousReportClaimStepSixSlice.validationClaim,
  claimEvent: state.anonymousReportClaimStepThreeSlice.claimEvent,
  county: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.county,
  country: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.regionCd,
  city: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.city,
  driverName: state.anonymousReportClaimStepSixSlice.validationClaim.driverName,
  driverLicense: state.anonymousReportClaimStepSixSlice.validationClaim.driverLicense,
  lossDate: state.anonymousReportClaimStepSixSlice.claimEventContacts.lossDt,
  lossTime: state.anonymousReportClaimStepSixSlice.claimEventContacts.lossTm,
  policyNumber: state.anonymousReportClaimStepSixSlice.claimEventContacts.policyNumber,
  postalCode: state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.postalCode,
  questionReplies: getReplies(state),
  stateProvince:
    state.anonymousReportClaimStepThreeSlice.selectedLocation?.address?.[0]?.stateProvCd,
  uploadFilesLabel: state.anonymousReportClaimStepSixSlice.uploadFilesLabel,
  whatHappened: state.anonymousReportClaimStepSixSlice.descriptionText,
  registrationToken: state.loginSlice.registrationToken,
  isClaimEventValidated: state.anonymousReportClaimStepSixSlice.isClaimEventValidated,
  claimEventValidationErrors: state.anonymousReportClaimStepSixSlice.claimEventValidationErrors,
});
export default connect(mapStateToProps)(Step6);
