import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { gaEvent } from '../../../utils/google-analytics';
import { find } from 'lodash';
import {
  BalancesDataContainer,
  BalancesHeaderContainer,
  ChangePayMethodContainer,
  ChangePayScheduleContainer,
  CurrentDueBalancesContainer,
  CurrentDueBalancesData,
  CurrentDueBalancesHeader,
  CurrentDueBalancesHeaderMobile,
  CurrentPayPlanHeader,
  DueDateBalancesContainer,
  DueDateBalancesHeader,
  DueDateBalancesHeaderMobile,
  HidePaymentArea,
  NewPaymentActionContainer,
  PaymentInfo,
  PaymentScheduleButton,
  PaymentScheduleEditButton,
  PaymentScheduleHideButton,
  PayoffAmountBalancesContainer,
  PayoffAmountBalancesData,
  PayoffAmountBalancesHeader,
  PayoffAmountBalancesHeaderMobile,
  PayPlanPayInformation,
  PayPlanScheduleContainer,
  PolicyNumberBalancesHeader,
  PolicyNumberContainer,
  PolicyNumberData,
  PolicyNumberHeaderMobile,
  ShowPaymentContainer,
} from './styles';
import { collapseEventBody, expandEventBody } from './utils';
import EditPaySchedule from './editPaySchedule';
import EditPayMethod from './editPayMethod';
import PayScheduleTable from './payScheduleTable';
import {
  resetStepTwoState,
  setFundingInstruments,
  setPaymentInstrument,
} from '../make-payment/step-two-amount/makePaymentStepTwoReducer';
import { getPayPlanOptions } from './policyAPI';
import { setNewPlanSchedule } from './payPlanReducer';
import CancelSaveContainer from './cancelSaveContainer';
import { ga4Event } from '../../../utils/google-analytics-4';

export const AccountRows = props => {
  const { dispatch, editPayPlan, fullAccount, policies } = props;

  let initialState = fullAccount.underlyingBillingAccounts
    ? fullAccount.underlyingBillingAccounts.reduce((result, account) => {
        result[account.id] = false;
        return result;
      }, {})
    : { [fullAccount.id]: false }; //for setting each schedule open or closed, if one policy just start with that one false
  let [showPaymentSchedule, setShowPaymentSchedule] = useState(initialState);
  let [showEditPaymentSchedule, setShowEditPaymentSchedule] = useState(initialState);
  let [showEditPaymentMethod, setShowEditPaymentMethod] = useState(initialState);
  let [policyToUpdatePayPlan, setPolicyToUpdatePayPlan] = useState(null);

  // Statement account should be excluded from "Balances" section, only the underlying policy account should be displayed.
  let policyAccount = fullAccount.underlyingBillingAccounts
    ? fullAccount.underlyingBillingAccounts.filter(
        (account) => account.accountDisplayName !== 'Statement',
      )
    : [fullAccount];

  const getPayPlanOptionsAndShowSchedule = (account) => {
    dispatch(setNewPlanSchedule(null));
    dispatch(resetStepTwoState());
    setShowPaymentSchedule({
      ...initialState,
      [account.id]: !showPaymentSchedule[account.id],
    });
    gaEvent(expandEventBody);
    ga4Event(expandEventBody);
    const temp = find(policies, ['policyNumber', account.accountNumber]);
    setPolicyToUpdatePayPlan(temp);
    dispatch(getPayPlanOptions(temp.policyRef));
  };

  return (
    <>
      <BalancesHeaderContainer>
        <PolicyNumberBalancesHeader>Policy Number</PolicyNumberBalancesHeader>
        <PayoffAmountBalancesHeader>Payoff Amount</PayoffAmountBalancesHeader>
        <CurrentDueBalancesHeader>Current Due</CurrentDueBalancesHeader>
        <DueDateBalancesHeader>Payment Schedule</DueDateBalancesHeader>
      </BalancesHeaderContainer>
      {policyAccount &&
        policyAccount.map((account) => {
          return (
            <BalancesDataContainer key={account.id}>
              <PolicyNumberContainer>
                <PolicyNumberHeaderMobile>Policy Number</PolicyNumberHeaderMobile>
                <PolicyNumberData>{account.accountNumber}</PolicyNumberData>
              </PolicyNumberContainer>
              <PayoffAmountBalancesContainer>
                <PayoffAmountBalancesHeaderMobile>Payoff Amount</PayoffAmountBalancesHeaderMobile>
                <PayoffAmountBalancesData>
                  ${parseFloat(account.totalAmt).toFixed(2)}
                </PayoffAmountBalancesData>
              </PayoffAmountBalancesContainer>
              <CurrentDueBalancesContainer>
                <CurrentDueBalancesHeaderMobile>Current Due</CurrentDueBalancesHeaderMobile>
                <CurrentDueBalancesData>
                  ${parseFloat(account.openAmt).toFixed(2)}
                </CurrentDueBalancesData>
              </CurrentDueBalancesContainer>
              <DueDateBalancesContainer>
                <DueDateBalancesHeaderMobile>Payment Schedule</DueDateBalancesHeaderMobile>
                {account.payPlan.schedule && showPaymentSchedule[account.id] === false ? (
                  <ShowPaymentContainer>
                    <KeyboardArrowRightIcon />
                    <PaymentScheduleButton
                      onClick={() => {
                        getPayPlanOptionsAndShowSchedule(account);
                      }}
                    >
                      Show/Manage Payment Schedule
                    </PaymentScheduleButton>
                  </ShowPaymentContainer>
                ) : (
                  <HidePaymentArea>
                    <KeyboardArrowDownIcon />
                    <PaymentScheduleHideButton
                      onClick={() => {
                        gaEvent(collapseEventBody);
                        ga4Event(collapseEventBody);
                        setShowPaymentSchedule({
                          ...showPaymentSchedule,
                          [account.id]: !showPaymentSchedule[account.id],
                        });
                      }}
                    >
                      Hide Payment Schedule
                    </PaymentScheduleHideButton>
                  </HidePaymentArea>
                )}
              </DueDateBalancesContainer>

              {showPaymentSchedule[account.id] === true ? (
                <PayPlanScheduleContainer>
                  <CurrentPayPlanHeader>{account.payPlan.description}</CurrentPayPlanHeader>
                  <PayScheduleTable schedule={account.payPlan.schedule} />
                  {account.electronicPaymentSource[0].methodCd &&
                    !account.payPlan.payPlanCd.includes('Direct') && (
                      <PayPlanPayInformation>
                        This pay plan uses{' '}
                        <PaymentInfo>
                          {account.electronicPaymentSource[0].methodCd} -{' '}
                          {account.electronicPaymentSource[0].methodCd === 'ACH'
                            ? account.electronicPaymentSource[0].achBankAccountNumber.slice(-4)
                            : account.electronicPaymentSource[0].creditCardNumber}{' '}
                        </PaymentInfo>
                        as a payment method.{' '}
                      </PayPlanPayInformation>
                    )}
                  {editPayPlan === true && (
                    <>
                      {!account.payPlan.payPlanCd.includes('Direct') && (
                        <ChangePayMethodContainer>
                          Need to change your Payment Method?{' '}
                          <PaymentScheduleEditButton
                            onClick={() => {
                              dispatch(setPaymentInstrument(null)); //reset if user has already clicked on set payschedule
                              dispatch(setFundingInstruments([]));
                              setShowEditPaymentSchedule(initialState); // reset edit schedule just show payment method
                              setShowEditPaymentMethod({
                                ...showEditPaymentMethod,
                                [account.id]: !showEditPaymentMethod[account.id],
                              });
                            }}
                          >
                            Click Here
                          </PaymentScheduleEditButton>
                        </ChangePayMethodContainer>
                      )}
                      <ChangePayScheduleContainer>
                        Need to change your Payment Schedule?{' '}
                        <PaymentScheduleEditButton
                          onClick={() => {
                            if (
                              account?.electronicPaymentSource[0]?.customerPaymentProfileId ||
                              account?.electronicPaymentSource[0]?.achBankAccountNumber
                            ) {
                              dispatch(setPaymentInstrument(account.electronicPaymentSource[0]));
                              dispatch(
                                setFundingInstruments([account?.electronicPaymentSource[0]]),
                              );
                            }
                            setShowEditPaymentMethod(initialState); // reset pay method clicked
                            setShowEditPaymentSchedule({
                              ...showEditPaymentSchedule,
                              [account.id]: !showEditPaymentSchedule[account.id],
                            });
                          }}
                        >
                          Click Here
                        </PaymentScheduleEditButton>
                      </ChangePayScheduleContainer>
                      {showEditPaymentSchedule[account.id] === true ? (
                        <EditPaySchedule account={account} />
                      ) : null}
                      {showEditPaymentMethod[account.id] === true &&
                      !account.payPlan.payPlanCd.includes('Direct') ? (
                        <EditPayMethod account={account} />
                      ) : null}{' '}
                      {/* direct accounts can edit just the payment method. Automated must change full pay schedule */}
                      <NewPaymentActionContainer>
                        {showEditPaymentSchedule[account.id] === true ||
                        showEditPaymentMethod[account.id] === true ? (
                          <CancelSaveContainer
                            showEditPaymentMethod={showEditPaymentMethod}
                            setShowPaymentSchedule={setShowPaymentSchedule}
                            showPaymentSchedule={showPaymentSchedule}
                            setShowEditPaymentMethod={setShowEditPaymentMethod}
                            setShowEditPaymentSchedule={setShowEditPaymentSchedule}
                            account={account}
                            fullAccount={fullAccount}
                            showEditPaymentSchedule={showEditPaymentSchedule}
                            policyToUpdatePayPlan={policyToUpdatePayPlan}
                            setPolicyToUpdatePayPlan={setPolicyToUpdatePayPlan}
                          />
                        ) : null}
                      </NewPaymentActionContainer>
                    </>
                  )}
                </PayPlanScheduleContainer>
              ) : null}
            </BalancesDataContainer>
          );
        })}
    </>
  );
};
AccountRows.propTypes = {
  fullAccount: PropTypes.shape({
    id: PropTypes.string,
    underlyingBillingAccounts: PropTypes.array,
    accountDisplayName: PropTypes.string,
    accountNumber: PropTypes.string,
    totalAmt: PropTypes.string,
    openAmt: PropTypes.string,
    payPlan: PropTypes.shape({
      schedule: PropTypes.array,
    }),
  }),
  dispatch: PropTypes.any,
  editPayPlan: PropTypes.bool,
  dueDate: PropTypes.string,
  newPlan: PropTypes.any,
  payPlanDateToPay: PropTypes.any,
  policies: PropTypes.any,
};
const mapStateToProps = (state) => ({
  editPayPlan: state.configurationSlice.features.features.editPayPlan,
  policies: state.policySlice.policies,
});
export default connect(mapStateToProps)(AccountRows);
