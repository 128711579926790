import * as React from 'react';
import UserLogout from '../../../logout/index';
import {
  AgentContainer,
  AnnouncementsContainer,
  BottomMenuLinks,
  BottomMenuLinksContainer,
  ChangeCoverageLink,
  ContentLink,
  CustomHTML1LinkVertical,
  CustomHTML2LinkVertical,
  DashboardContentArea,
  GetQuoteContainer,
  HomeLink,
  IdCardsLink,
  LogoLink,
  LogoutLinkHorizontal,
  MakePaymentLink,
  NeedHelpLinkHorizontal,
  OpenQuoteLink,
  PendingRequestLink,
  ProfileLinkHorizontal,
  ReportClaimLink,
  SidePanelContainer,
  SidePanelContainerWithAnnouncements,
  TemplateLogo,
  TemplateOneDashboardContainer,
  TemplateOneNav,
  TopLinkContainers,
  TopMenuLinks,
} from './styles';
import Routing from '../routing';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MyAgent from '../../my-agent';
import GetQuote from '../../get-quote';
import Footer from '../../footer';
import NewsBanners from '../../../news-banners';

export const DesktopDashboardTemplate1 = (props) => {
  const {
    agentPanelAllowed,
    changeCoverageAllowed,
    crossLinkingAllowed,
    customPage1Href,
    customPage1Html,
    customPage1Name,
    customPage2Href,
    customPage2Html,
    customPage2Name,
    CustomContentPage1,
    CustomContentPage2,
    needHelpAllowed,
    reportClaimAllowed,
    viewChangeRequestsAllowed,
    viewIdCard,
    newsBanners,
    companyName,
  } = props;

  return (
    <>
      <TemplateOneDashboardContainer>
        <TemplateOneNav>
          <LogoLink to="/dashboard">
            <TemplateLogo src={`config/images/standard_logo.png`} alt={companyName + ' logo'} />
          </LogoLink>
          <TopMenuLinks>
            <TopLinkContainers>
              <ProfileLinkHorizontal id="userProfileLink" to="/userProfile">
                Profile
              </ProfileLinkHorizontal>
            </TopLinkContainers>
            {needHelpAllowed && (
              <TopLinkContainers>
                <NeedHelpLinkHorizontal
                  to={{
                    pathname: '/needHelp',
                    state: {
                      customerSlice: props.state.customerSlice,
                    },
                  }}
                >
                  Need Help?
                </NeedHelpLinkHorizontal>
              </TopLinkContainers>
            )}
            <LogoutLinkHorizontal>
              <UserLogout {...props} />
            </LogoutLinkHorizontal>
          </TopMenuLinks>
          <BottomMenuLinksContainer>
            <div>
              <HomeLink to="/dashboard">Home</HomeLink>
            </div>
            {crossLinkingAllowed && (
              <div>
                <OpenQuoteLink to="/openQuotes">Open Quotes</OpenQuoteLink>
              </div>
            )}
            <div>
              <MakePaymentLink to="/makePayment">Make Payment</MakePaymentLink>
            </div>
            {reportClaimAllowed && (
              <div>
                <ReportClaimLink to="/reportClaim">Report Claim</ReportClaimLink>
              </div>
            )}
            {changeCoverageAllowed && (
              <div>
                <ChangeCoverageLink to="/changeCoverage">Change Coverage</ChangeCoverageLink>
              </div>
            )}
            {viewChangeRequestsAllowed && (
              <div>
                <PendingRequestLink to="/pendingRequests">Pending Requests</PendingRequestLink>
              </div>
            )}
            {viewIdCard && (
              <div>
                <IdCardsLink to="/idCards">ID Cards</IdCardsLink>
              </div>
            )}
            <div>
              <BottomMenuLinks to="/customerInformation">Customer Information</BottomMenuLinks>
            </div>
            {CustomContentPage1 && (
              <>
                {customPage1Html ? (
                  <CustomHTML1LinkVertical>{customPage1Name}</CustomHTML1LinkVertical>
                ) : (
                  <ContentLink href={customPage1Href} target="_blank">
                    {customPage1Name}
                  </ContentLink>
                )}
              </>
            )}
            {CustomContentPage2 && (
              <>
                {customPage2Html ? (
                  <CustomHTML2LinkVertical>{customPage2Name}</CustomHTML2LinkVertical>
                ) : (
                  <ContentLink href={customPage2Href} target="_blank">
                    {customPage2Name}
                  </ContentLink>
                )}
              </>
            )}
          </BottomMenuLinksContainer>
        </TemplateOneNav>
        <DashboardContentArea>
          <Routing isMobile={false} />
        </DashboardContentArea>
        {newsBanners && newsBanners.length > 0 ? (
          <SidePanelContainerWithAnnouncements>
            <AnnouncementsContainer>
              <NewsBanners />
            </AnnouncementsContainer>
            {agentPanelAllowed && (
              <AgentContainer>
                <MyAgent />
              </AgentContainer>
            )}
            {crossLinkingAllowed && (
              <GetQuoteContainer>
                <GetQuote template="template1" />
              </GetQuoteContainer>
            )}
          </SidePanelContainerWithAnnouncements>
        ) : (
          <SidePanelContainer>
            {agentPanelAllowed && (
              <AgentContainer>
                <MyAgent />
              </AgentContainer>
            )}
            {crossLinkingAllowed && (
              <GetQuoteContainer>
                <GetQuote template="template1" />
              </GetQuoteContainer>
            )}
          </SidePanelContainer>
        )}
      </TemplateOneDashboardContainer>
      <Footer />
    </>
  );
};

DesktopDashboardTemplate1.propTypes = {
  agentPanelAllowed: PropTypes.bool,
  crossLinkingAllowed: PropTypes.bool,
  changeCoverageAllowed: PropTypes.bool,
  customPage1Href: PropTypes.string,
  customPage1Html: PropTypes.string,
  customPage1Name: PropTypes.string,
  customPage2Href: PropTypes.string,
  customPage2Html: PropTypes.string,
  customPage2Name: PropTypes.string,
  CustomContentPage1: PropTypes.bool,
  CustomContentPage2: PropTypes.bool,
  needHelpAllowed: PropTypes.bool,
  reportClaimAllowed: PropTypes.bool,
  state: PropTypes.shape({
    customerSlice: PropTypes.object,
  }),
  viewChangeRequestsAllowed: PropTypes.bool,
  viewIdCard: PropTypes.bool,
  newsBanners: PropTypes.array,
  companyName: PropTypes.string,
};

export const mapStateToProps = (state) => ({
  agentPanelAllowed: state.configurationSlice.features.features.agentPanel,
  changeCoverageAllowed: state.configurationSlice.features.features.changeCoverage,
  customPage1Html: state.i18nSlice.i18n.customContent.customContentPage1.html,
  customPage1Href: state.i18nSlice.i18n.customContent.customContentPage1.url,
  customPage1Name: state.i18nSlice.i18n.customContent.customContentPage1.linkText,
  customPage2Html: state.i18nSlice.i18n.customContent.customContentPage2.html,
  customPage2Href: state.i18nSlice.i18n.customContent.customContentPage2.url,
  customPage2Name: state.i18nSlice.i18n.customContent.customContentPage2.linkText,
  CustomContentPage1: state.configurationSlice.features.features.CustomContentPage1,
  CustomContentPage2: state.configurationSlice.features.features.CustomContentPage2,
  needHelpAllowed: state.configurationSlice.features.features.needHelp,
  reportClaimAllowed: state.configurationSlice.features.features.reportClaim,
  state,
  viewChangeRequestsAllowed: state.configurationSlice.features.features.viewChangeRequests,
  viewIdCard: state.customerSlice.selfServiceConfigurations.selfServiceFeature.viewIdCard,
  crossLinkingAllowed: state.configurationSlice.features.features.cpPortalLinking,
  newsBanners: state.newsBannersSlice.newsBanners,
  companyName: state.configurationSlice.features.companyName,
});

export default connect(mapStateToProps)(DesktopDashboardTemplate1);
