import React from 'react';
import { CustomPageHtml } from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

const CustomPage2 = props => {
  const { customPage2Html } = props;
  return <CustomPageHtml dangerouslySetInnerHTML={{ __html: customPage2Html }} />;
};

CustomPage2.propTypes = {
  customPage2Html: PropTypes.string,
};

export const mapStateToProps = state => ({
  customPage2Html: state.i18nSlice.i18n.customContent.customContentPage2.html,
});

export default connect(mapStateToProps)(CustomPage2);
