import { TOKENIZED_API } from '../../../../utils/api';
import { setClaimReferenceNumber, setClaimSubmit } from './anonymouseReportClaimStepSixReducer';

export const submitClaim = (body, customerToken, enqueueSnackbar) => {
  return async dispatch => {
    return TOKENIZED_API.post(`/claimEvents?customerToken=${customerToken}`, body)
      .then(response => {
        let reference = response.headers.location.indexOf('claims');
        reference = reference + 7; //index of the claim number
        let claimReferenceNumber = response.headers.location.slice(reference); //slice the number off for getting loss call
        TOKENIZED_API.get(`/claims/${claimReferenceNumber}/consumerSummary`)
          .then(response => {
            dispatch(setClaimReferenceNumber(response.data.claimNumber));
            dispatch(setClaimSubmit('Success'));
          })
          .catch(() => {
            enqueueSnackbar(
              'Your request cannot be processed at this time. Please try again later.',
              {
                variant: 'error',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top',
                },
              },
            );
          });
      })
      .catch(err => {
        console.log(err);
        dispatch(setClaimSubmit('Failure'));
      });
  };
};
