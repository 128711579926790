import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import InfoIcon from '@mui/icons-material/Info';

import EditingNotifications from './editNotifications';
import { setLoading } from './notificationReducer';
import { setEditingNotifications, setNotificationErrors, setPhoneErrors } from './profileReducer';
import {
  EditNotificationContainer,
  EditNotificationPreferencesLink,
  MiniTitle,
  NoticeContainer,
  NotificationAddressLabel,
  NotificationContainer,
  NotificationError,
  NotificationType,
} from './styles';
import Loader from '../../common/loader';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';

export const NotificationDetails = props => {
  const {
    dispatch,
    editingNotifications,
    isLoading,
    isSubscribedEmail,
    isSubscribedSMS,
    notificationEmail,
    notificationErrors,
    notificationPhone,
    customerSubscriptions,
    i18n,
    termAcceptInd,
  } = props;

  useEffect(() => {
    dispatch(setLoading(true));
    dispatch(setNotificationErrors(null));
    dispatch(setPhoneErrors(null));
    dispatch(setLoading(false));
  }, []);
  const cancelEditNotifications = () => {
    props.dispatch(setEditingNotifications(false));
    props.dispatch(setNotificationErrors(null));
  };

  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <>
          {!isSubscribedSMS && !editingNotifications && (
            <NoticeContainer>
              <InfoIcon style={{ position: 'relative', top: '6px', right: '4px' }} />
              <MiniTitle>Notice:</MiniTitle>{' '}
              <span>
                To {customerSubscriptions === undefined ? 'enable' : 'resume'} SMS and Email
                notifications, edit your notification preferences below and follow the instructions.
              </span>
            </NoticeContainer>
          )}
          <NotificationContainer isDashboard={false}>
            <NotificationAddressLabel>
              SMS and Email Notifications
              <TooltipInfo
                title={getText(i18n, 'profile.smsAndEmailNotificationsTooltip')}
                size="large"
              />
              {!editingNotifications && (
                <NotificationType>
                  {termAcceptInd && isSubscribedSMS
                    ? isSubscribedEmail
                      ? 'Subscribed: SMS and email'
                      : 'Subscribed: SMS Only'
                    : termAcceptInd && isSubscribedEmail
                    ? 'Subscribed: Email Only'
                    : 'Unsubscribed'}
                </NotificationType>
              )}
              {!notificationPhone || !notificationEmail || notificationEmail === '' ? (
                <>
                  <NotificationType>
                    *Phone and Email are required for notifications
                  </NotificationType>
                </>
              ) : null}
            </NotificationAddressLabel>

            <>
              <EditNotificationContainer>
                {!editingNotifications && (
                  <EditNotificationPreferencesLink
                    onClick={() => dispatch(setEditingNotifications(true))}
                    disabled={
                      !notificationEmail ||
                      !notificationPhone ||
                      notificationEmail === '' ||
                      notificationPhone === ''
                    }
                  >
                    Edit
                  </EditNotificationPreferencesLink>
                )}
              </EditNotificationContainer>
            </>
          </NotificationContainer>
        </>
      )}
      {notificationErrors && <NotificationError>{notificationErrors}</NotificationError>}
      {editingNotifications ? (
        <EditingNotifications cancelEditNotifications={cancelEditNotifications} />
      ) : null}
    </>
  );
};

NotificationDetails.propTypes = {
  dispatch: PropTypes.func,
  editingNotifications: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSubscribedSMS: PropTypes.bool,
  isSubscribedEmail: PropTypes.bool,
  notificationEmail: PropTypes.string,
  notificationErrors: PropTypes.any,
  notificationPhone: PropTypes.string,
  profileName: PropTypes.string,
  customerSubscriptions: PropTypes.object,
  i18n: PropTypes.object,
  termAcceptInd: PropTypes.bool,
};
const mapStateToProps = state => ({
  isLoading: state.notificationSlice.isLoading,
  isSubscribedSMS: state.notificationSlice.isSubscribedSMS,
  isSubscribedEmail: state.notificationSlice.isSubscribedEmail,
  editingNotifications: state.profileSlice.editingNotifications,
  phoneErrors: state.profileSlice.phoneErrors,
  notificationErrors: null,
  notificationEmail: state.notificationSlice.notificationEmail,
  notificationPhone: state.notificationSlice.notificationPhone,
  editingEmail: state.profileSlice.editingEmail,
  editingPhone: state.profileSlice.editingPhone,
  profileName: state.customerSlice.customer.indexName,
  customerSubscriptions: state.profileSlice.customerSubscriptions,
  i18n: state.i18nSlice.i18n,
  termAcceptInd: state.notificationSlice?.customerSubscriptionsFull?.termAcceptInd || false,
});
NotificationDetails.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(NotificationDetails);
