import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment';
import { Helmet } from 'react-helmet';
import {
  CurrentDueAmount,
  CurrentDueContainer,
  DatesAndAmountsContainer,
  GridSpacingDesktopOne,
  GridSpacingDesktopTwo,
  InstructionsContainer,
  NoPaymentDueText,
  PaymentContainer,
  PolicyAndCurrentDueContainer,
  PolicyCard,
  PolicyHeader,
  PolicySubHeaders,
  PolicyToPayContainer,
  PolicyValueHeader,
  RadioChoice,
  SubHeader,
} from './styles';
import {
  setAccountToPayWith,
  setNextButtonDisabled,
  setActiveStep,
  setIsLoading,
  setNextButtonClicked,
} from '../makePaymentReducer';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';
import ApiError from '../../../common/api-error';
import PendingPaymentBanner from '../../../common/payment/pending-payment';
import { getPendingPayments } from '../../../common/payment/selectors';
import TooltipInfo from '../../../common/tooltip';
import { getText } from '../../../../utils/i18n';

//TODO: CHANGE NOTES: moving setAccountToPayWith into redux. Add check for policy actually being payable

export const MakeAPaymentStepOne = (props) => {
  const { accountToPayWith, activeStep, billingAccounts, dispatch, i18n, nextButtonClicked } =
    props;
  const [pendingPayments, setPendingPayments] = React.useState(
    getPendingPayments(billingAccounts[0]),
  );
  useEffect(() => {
    dispatch(setAccountToPayWith(billingAccounts[0]));
    dispatch(setNextButtonDisabled(false));
  }, []);
  const isValidPayment = (account) => {
    return account.billingAccount.openAmt > 0 || account.billingAccount.totalAmt > 0;
  };
  const nextButtonClickHandler = async () => {
    dispatch(setNextButtonClicked(false));

    dispatch(setIsLoading(true));
    dispatch(setActiveStep(1));
  };
  useEffect(() => {
    if (activeStep === 0 && nextButtonClicked === true) {
      nextButtonClickHandler();
    }
  }, [nextButtonClicked, activeStep]);
  useEffect(() => {
    if (
      !(
        accountToPayWith?.billingAccount?.openAmt > 0 ||
        accountToPayWith?.billingAccount?.totalAmt > 0
      ) ||
      !accountToPayWith
    ) {
      dispatch(setNextButtonDisabled(true));
    } else {
      dispatch(setNextButtonDisabled(false));
    }
  }, [accountToPayWith]);
  return (
    <PaymentContainer>
      <Helmet>
        <title>Make Payment - Select Account</title>
      </Helmet>
      {billingAccounts.length > 0 ? (
        <>
          <SubHeader>
            Choose a Policy To Pay
            <TooltipInfo title={getText(i18n, 'makePayment.stepOne.choosePolicyToPayTooltip')} />
          </SubHeader>
          {i18n?.makePayment?.stepOne?.instructions && (
            <InstructionsContainer
              dangerouslySetInnerHTML={{ __html: i18n.makePayment.stepOne.instructions }}
            />
          )}
          <PolicyToPayContainer>
            {billingAccounts.map((account) => {
              return (
                <PolicyCard key={account.billingAccount.id}>
                  <PolicyAndCurrentDueContainer>
                    <RadioChoice
                      aria-label={`Choose a Policy to Pay ${account.billingAccount.accountDisplayNumber}`}
                      id={'RadioInput' + account.billingAccount.accountDisplayNumber}
                      checked={(accountToPayWith && account.ref === accountToPayWith.ref) || false}
                      onChange={() => {
                        dispatch(setAccountToPayWith(account));
                        setPendingPayments(getPendingPayments(account));
                      }}
                    />
                    <PolicyHeader>{account.billingAccount.accountDisplayNumber}</PolicyHeader>
                    <CurrentDueContainer>
                      <PolicySubHeaders>Current Due</PolicySubHeaders>
                      <CurrentDueAmount>
                        {account.billingAccount.openAmt
                          ? `$${account.billingAccount.openAmt}`
                          : null}
                      </CurrentDueAmount>
                    </CurrentDueContainer>
                  </PolicyAndCurrentDueContainer>
                  {isValidPayment(account) ? (
                    <DatesAndAmountsContainer>
                      <GridSpacingDesktopOne />
                      <GridSpacingDesktopTwo />
                      <div>
                        <PolicySubHeaders>Bill To</PolicySubHeaders>
                        <PolicyValueHeader>
                          {account.billingAccount.billToCd ? account.billingAccount.billToCd : null}
                        </PolicyValueHeader>
                      </div>
                      <div>
                        <PolicySubHeaders>Due Date</PolicySubHeaders>
                        <PolicyValueHeader>
                          {account.dueDate
                            ? moment(account.dueDate).format(DISPLAY_DATE_FORMAT)
                            : null}
                        </PolicyValueHeader>
                      </div>
                      <div>
                        <PolicySubHeaders>Invoice Date</PolicySubHeaders>
                        <PolicyValueHeader>
                          {account.invoiceDate
                            ? moment(account.invoiceDate).format(DISPLAY_DATE_FORMAT)
                            : null}
                        </PolicyValueHeader>
                      </div>
                      <div>
                        <PolicySubHeaders>Payoff Amount</PolicySubHeaders>
                        <PolicyValueHeader>
                          {account.billingAccount.totalAmt
                            ? `$${account.billingAccount.totalAmt}`
                            : null}
                        </PolicyValueHeader>
                      </div>
                    </DatesAndAmountsContainer>
                  ) : (
                    <NoPaymentDueText>
                      Your account does not currently have a payment due.
                    </NoPaymentDueText>
                  )}
                </PolicyCard>
              );
            })}
          </PolicyToPayContainer>
          {pendingPayments && accountToPayWith && (
            <PendingPaymentBanner
              isReview={false}
              accountToPay={accountToPayWith?.fullAccount?.pendingReceipts}
            />
          )}
        </>
      ) : (
        <>
          <ApiError
            title="Payments Unavailable"
            body="We cannot find your account at this moment. Please try again later."
          />
        </>
      )}
    </PaymentContainer>
  );
};
MakeAPaymentStepOne.propTypes = {
  activeStep: PropTypes.any,
  accountToPayWith: PropTypes.object,
  billingAccounts: PropTypes.array,
  dispatch: PropTypes.func,
  i18n: PropTypes.object,
  nextButtonClicked: PropTypes.bool,
  pendingPayments: PropTypes.bool,
  response: PropTypes.object,
};
const mapStateToProps = (state) => ({
  accountToPayWith: state.makePaymentSlice.accountToPayWith,
  activeStep: state.makePaymentSlice.activeStep,
  billingAccounts: state.billingSlice.billingAccounts,
  i18n: state.i18nSlice.i18n,
  nextButtonClicked: state.makePaymentSlice.nextButtonClicked,
  response: state.makePaymentAnonymousSlice.response,
});
export default connect(mapStateToProps)(MakeAPaymentStepOne);
