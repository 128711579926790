import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { getPoliciesWithLatestTerm } from './policyAPI';
import PolicyRow from './policyRow';
import Loader from '../../../common/loader/index';
import {
  EffectiveDateLabel,
  ExpirationDateLabel,
  LoaderMargin,
  PolicyDashboardContainer,
  PolicyHeader,
  PolicyLabel,
  PolicyLabelContainer,
  StatusLabel,
  UploadLabel,
} from './style';
import ApiError from '../../../common/api-error';

const Policies = props => {
  const {
    customerId,
    createdSinceDate,
    errors,
    isLoading,
    policies,
    policyDocumentUploadAllowed,
    submitting,
    policiesCountLimit,
  } = props;

  const getPolicies = customerId => {
    props.dispatch(getPoliciesWithLatestTerm(customerId, createdSinceDate, policiesCountLimit));
  };
  useEffect(() => {
    getPolicies(customerId);
  }, []);

  return (
    <>
      <PolicyDashboardContainer>
        <PolicyHeader>Policies</PolicyHeader>
        <PolicyLabelContainer policyDocumentUpload={policyDocumentUploadAllowed}>
          <PolicyLabel aria-label="Column Header Policy Number">Policy Number</PolicyLabel>
          <EffectiveDateLabel aria-label="Column Header Effective Date">
            Effective Date{' '}
          </EffectiveDateLabel>
          <ExpirationDateLabel aria-label="Column Header Expiration Date">
            Expiration Date{' '}
          </ExpirationDateLabel>
          <StatusLabel aria-label="Column Header Status">Status </StatusLabel>
          {policyDocumentUploadAllowed && (
            <UploadLabel aria-label="Column Header Documents">Documents </UploadLabel>
          )}
        </PolicyLabelContainer>
        {isLoading ? (
          <LoaderMargin>
            <Loader />
          </LoaderMargin>
        ) : (
          <>
            {errors ? (
              <ApiError body="There was an error loading your Policies." variant="dashboard" />
            ) : (
              <>
                {policies.map(policy => {
                  return (
                    <PolicyRow
                      key={policy.policyRef + policy.statusCd}
                      {...policy}
                      systemId={policy.policyRef}
                      id={`policyRow  ${policy.policyRef} ${policy.statusCd}`}
                      submitting={submitting}
                      policyDocumentUploadAllowed={policyDocumentUploadAllowed}
                    />
                  );
                })}
              </>
            )}
          </>
        )}
      </PolicyDashboardContainer>
    </>
  );
};

Policies.propTypes = {
  policies: PropTypes.array.isRequired,
  isLoading: PropTypes.bool.isRequired,
  customerId: PropTypes.string,
  createdSinceDate: PropTypes.number,
  errors: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  submitting: PropTypes.bool,
  submittedPolicy: PropTypes.string,
  maxUploadFileSize: PropTypes.string,
  currentUploads: PropTypes.number,
  totalUploads: PropTypes.number,
  policyDocumentUploadAllowed: PropTypes.bool.isRequired,
  policiesCountLimit: PropTypes.string,
};

Policies.defaultProps = {
  policies: [],
  isLoading: false,
  submitting: null,
  customerId: '',
};

export const mapStateToProps = state => ({
  policies: state.policySlice.policies,
  isLoading: state.policySlice.isLoading,
  currentUploads: state.policySlice.currentUploads,
  totalUploads: state.policySlice.totalUploads,
  errors: state.policySlice.errors,
  customerId: state.loginSlice.user.customer_id,
  createdSinceDate: state.configurationSlice.features.policyCreatedSinceDt,
  maxUploadFileSize: state.configurationSlice.features.maxUploadFileSize,
  policyDocumentUploadAllowed: state.configurationSlice.features.features.policyDocumentUpload,
  submittedPolicy: state.policySlice.submittedPolicy,
  policiesCountLimit: state.configurationSlice.features.policiesCountLimit,
});

export default connect(mapStateToProps)(Policies);
