import {
  setClaimEvent,
  setClaimEventLoading,
  setClaimEventContactsLoading,
} from './anonymousReportClaimStepThreeReducer';
import { setClaimEventContacts } from '../step4/anonymousReportClaimStepFourReducer';
import { TOKENIZED_API } from '../../../../utils/api';
import { setErrors } from '../step2/anonymousReportClaimStepTwoReducer';
import { setClaimEventContactsStepSix } from '../recap-and-submit/anonymouseReportClaimStepSixReducer';

export const getAnonymousClaimEventTemplateById = (
  templateId,
  customerId,
  lossDt,
  lossTm,
  includeValue,
  customerToken,
) => {
  return async dispatch => {
    dispatch(setClaimEventLoading(true));
    return TOKENIZED_API.get(
      `/claimEvents/${templateId}` +
        `?customerRef=${customerId}` +
        `&lossDt=${lossDt}` +
        `&lossTm=${lossTm}` +
        `&customerToken=${customerToken}` +
        (includeValue === 'RiskLocation' ? '&includeRiskLocations=true' : '') +
        (includeValue === 'VehicleAndDrivers' ? '&includeVehiclesAndDrivers=true' : '') +
        (includeValue === 'Contacts' ? '&includeContacts=true' : ''),
    )
      .then(response => {
        dispatch(setClaimEvent(response.data));
      })
      .catch(err => {
        dispatch(setErrors(err.message));
      })
      .finally(() => {
        dispatch(setClaimEventLoading(false));
      });
  };
};

export const getAnonymousClaimEventContacts = (
  templateId,
  customerId,
  selectedLocation,
  selectedVehicle,
  lossDt,
  lossTm,
  customerToken,
  enqueueSnackbar,
  setApiCallSuccess,
) => {
  return async dispatch => {
    let policyNumber =
      Object.keys(selectedLocation).length === 0
        ? selectedVehicle.policyNumber
        : selectedLocation.policyNumber;
    let riskIdRef =
      Object.keys(selectedLocation).length === 0 ? selectedVehicle.id : selectedLocation.id;

    dispatch(setClaimEventContactsLoading(true));
    return TOKENIZED_API.get(
      `/claimEvents/${templateId}` +
        `?customerRef=${customerId}` +
        `&policyNumber=${policyNumber}` +
        `&riskIdRef=${riskIdRef}` +
        `&lossDt=${lossDt}` +
        `&lossTm=${lossTm}` +
        '&includeContacts=true' +
        `&customerToken=${customerToken}`,
    )
      .then(response => {
        dispatch(setClaimEventContacts(response.data));
        dispatch(setClaimEventContactsStepSix(response.data));
        setApiCallSuccess(true);
      })
      .catch(() => {
        enqueueSnackbar(
          'Your request cannot be processed at this time. Please contact your agent.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
      })
      .finally(() => dispatch(setClaimEventContactsLoading(false)));
  };
};
