export const getPaymentCategory = (paymentMethods, setPaymentCategory) => {
  if (
    paymentMethods.includes('UserSelect') &&
    paymentMethods.includes('CC') &&
    paymentMethods.includes('ACH')
  ) {
    setPaymentCategory('UserSelect');
    return 'UserSelect'; //user select iframe
  }
  if (
    paymentMethods.includes('UserSelect') &&
    paymentMethods.includes('ACH') &&
    !paymentMethods.includes('CC')
  ) {
    setPaymentCategory('Echeck');
    return 'Echeck'; //echeck iframe, no cc load
  }
  if (
    paymentMethods.includes('UserSelect') &&
    paymentMethods.includes('CC') &&
    !paymentMethods.includes('ACH')
  ) {
    setPaymentCategory('CreditCard');
    return 'CreditCard'; //cc iframe, no ach
  }
  if (
    !paymentMethods.includes('UserSelect') &&
    paymentMethods.includes('CC') &&
    paymentMethods.includes('ACH')
  ) {
    setPaymentCategory('CreditCard');
    return 'CreditCard'; //cc iframe, plus ach form
  }
  if (
    !paymentMethods.includes('UserSelect') &&
    paymentMethods.includes('CC') &&
    !paymentMethods.includes('ACH')
  ) {
    setPaymentCategory('CreditCard');
    return 'CreditCard'; //cc iframe, don't load ach
  }
  if (
    !paymentMethods.includes('UserSelect') &&
    !paymentMethods.includes('CC') &&
    paymentMethods.includes('ACH')
  ) {
    setPaymentCategory('ACHFormOnly');
    return 'ACHFormOnly'; //no iframe, ach form only
  }
};
