import React from 'react';
import PropTypes from 'prop-types';
import {
  ClaimAttachmentsDataContainer,
  ClaimAttachmentsLabelContainer,
  ClaimDocumentsContainer,
  DocumentTypeContainer,
  DocumentTypeLabel,
  DocumentTypeLabelMobile,
  DownloadLinkContainer,
  DownloadLinkLabel,
  DownloadLinkLabelMobile,
  LoaderMargin,
  PdfIcon,
} from './styles';
import Loader from '../../common/loader';
import { ApiError } from '../../common/api-error';
import AuthenticatedLink from '../../common/authenticated-link';

export const ClaimAttachments = props => {
  const { isLoading, errors, claimAttachments } = props;
  return (
    <ClaimDocumentsContainer>
      {isLoading ? (
        <LoaderMargin>
          <Loader />
        </LoaderMargin>
      ) : (
        <>
          <ClaimAttachmentsLabelContainer>
            <DocumentTypeLabel id="AttachmentType">Document Type</DocumentTypeLabel>
            <DocumentTypeLabel id="AttachmentDescription">Description</DocumentTypeLabel>
            <DownloadLinkLabel>Download Link</DownloadLinkLabel>
            <DocumentTypeLabel id="UploadDate">Upload Date</DocumentTypeLabel>
          </ClaimAttachmentsLabelContainer>
          {errors ? (
            <ApiError body="There was an error loading your attachments." variant="table" />
          ) : (
            <>
              {claimAttachments &&
                claimAttachments.map(document => {
                  return (
                    <ClaimAttachmentsDataContainer key={document.ref}>
                      <DocumentTypeContainer>
                        <DocumentTypeLabelMobile>Document Type</DocumentTypeLabelMobile>
                        <div>{document.name}</div>
                      </DocumentTypeContainer>
                      <DocumentTypeContainer>
                        <DocumentTypeLabelMobile>Description</DocumentTypeLabelMobile>
                        <div>{document.description}</div>
                      </DocumentTypeContainer>
                      <DownloadLinkContainer aria-label={`Download ${document.name}`}>
                        <DownloadLinkLabelMobile>Download Link</DownloadLinkLabelMobile>
                        <AuthenticatedLink
                          url={document._links[0].href}
                          filename={document.name + '.pdf'}
                        >
                          <PdfIcon />
                          Download
                        </AuthenticatedLink>
                      </DownloadLinkContainer>
                      <DocumentTypeContainer>
                        <DocumentTypeLabelMobile>Upload Date</DocumentTypeLabelMobile>
                        <div>{document.addDt}</div>
                      </DocumentTypeContainer>
                    </ClaimAttachmentsDataContainer>
                  );
                })}
            </>
          )}
        </>
      )}
    </ClaimDocumentsContainer>
  );
};
ClaimAttachments.propTypes = {
  isLoading: PropTypes.bool,
  errors: PropTypes.any,
  claimAttachments: PropTypes.array,
};
