import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  idMailed: false,
  documentErrors: null,
  mailingErrors: null,
  isLoading: false,
  idCardsDocuments: [],
  setIdCardsTemplates: [],
};

export const idCardsSlice = createSlice({
  name: 'idCardsSlice',
  initialState,
  reducers: {
    setIdMailed: (state, action) => {
      return { ...state, idMailed: action.payload };
    },
    setDocumentsErrors: (state, action) => {
      return { ...state, documentErrors: action.payload };
    },
    setMailingErrors: (state, action) => {
      return { ...state, mailingErrors: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setIdCardsDocuments: (state, action) => {
      if (action.payload === null) {
        return { ...state, idCardsDocuments: [] };
      } else {
        return { ...state, idCardsDocuments: state.idCardsDocuments.concat(action.payload) };
      }
    },
    setIdCardsTemplates: (state, action) => {
      return { ...state, idCardsTemplates: action.payload };
    },
  },
});
export const {
  setIdCardsDocuments,
  setIdMailed,
  setDocumentsErrors,
  setMailingErrors,
  setLoading,
  setIdCardsTemplates,
} = idCardsSlice.actions;
export default idCardsSlice.reducer;
