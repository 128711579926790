import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  isLoading: false,
  pendingRequests: [],
  errors: null,
};

export const pendingRequestsSlice = createSlice({
  name: 'pendingRequestsSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setPendingRequests: (state, action) => {
      return { ...state, pendingRequests: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    resetState: () => {
      return { ...initialState };
    },
  },
});
export const {
  setLoading,
  setPendingRequests,
  setErrors,
  resetState,
} = pendingRequestsSlice.actions;
export default pendingRequestsSlice.reducer;
