import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import {
  ChangePayScheduleEditScreenContainer,
  PayScheduleEditHeader,
  SelectPayMethodHeader,
} from './styles';
import FundingInstruments from './fundingInstruments';

export const EditPayMethod = (props) => {
  const { account } = props;
  return (
    <ChangePayScheduleEditScreenContainer>
      <PayScheduleEditHeader>Payment Method</PayScheduleEditHeader>
      <SelectPayMethodHeader>Select your payment method.</SelectPayMethodHeader>
      <FundingInstruments payScheduleAccount={account} />
    </ChangePayScheduleEditScreenContainer>
  );
};
EditPayMethod.propTypes = {
  account: PropTypes.any,
  dispatch: PropTypes.any,
  payPlanDateToPay: PropTypes.any,
  newPlan: PropTypes.any,
};
export const mapStateToProps = (state) => ({
  state,
  billingAccounts: state.billingSlice.billingAccounts,
  payPlanDateToPay: state.payPlanSlice.payPlanDateToPay,
});

export default connect(mapStateToProps)(EditPayMethod);
