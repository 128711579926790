import { API, MULTI_PART_API } from '../api';
import {
  setAttachingFiles,
  setErrors,
  setFileAttachmentComplete,
  setUploadedFiles,
} from '../../components/features/file-upload/fileUploadReducer';

export const uploadFilesApi = files => {
  return async dispatch => {
    dispatch(setUploadedFiles(null));
    if (files.length > 0) {
      let fd = new FormData();
      let promises = files.map(file => {
        fd.append(file.name, file);
        return MULTI_PART_API.post('multifileupload/v3', fd)
          .then(() => {
            return {
              fileName: file.name,
            };
          })
          .catch(err => {
            console.log(err);
          });
      });

      await Promise.allSettled(promises)
        .then(async responses => {
          const fileNames = [];
          if (responses && responses.length > 0) {
            responses.forEach(response => {
              fileNames.push(response.value.fileName);
            });
          }
          dispatch(setUploadedFiles(fileNames));
        })
        .catch(err => {
          console.log(err);
          dispatch(setErrors(err));
        });
    }
  };
};

const attachDocument = async (
  resource,
  ref,
  filenames,
  templateId,
  description,
  memo,
  dispatch,
  notify,
) => {
  dispatch(setAttachingFiles(true));
  const compositeFile = filenames.map(fileName => ({ fileName }));
  return await API.post(`${resource}/${ref}/documents`, {
    compositeFile,
    templateId,
    description,
    memo,
  })
    .then(() => {
      notify(`Success! Your files ${filenames} have been uploaded.`, {
        variant: 'success',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    })
    .catch(() => {
      notify(`Error! Fail to upload ${filenames}`, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
    });
};

export const attachFilesApi = (
  filenames,
  containerType,
  ref,
  templateId,
  description,
  memo,
  notify,
) => {
  return async dispatch => {
    let resource;
    if (containerType === 'Policy') {
      resource = 'policies';
    } else if (containerType === 'Claim') {
      resource = 'claims';
    } else {
      // return if no container type provided
      return;
    }
    try {
      await attachDocument(
        resource,
        ref,
        filenames,
        templateId,
        description,
        memo,
        dispatch,
        notify,
      );
    } finally {
      dispatch(setAttachingFiles(false));
      dispatch(setFileAttachmentComplete(true));
    }
  };
};
