import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const LoginArea = styled.div.attrs({
  id: 'loginArea',
})`
  border-top: 8px solid ${props => props.theme.primaryColor};
  border-left: 1px solid #e3e5e7;
  border-right: 1px solid #e3e5e7;
  border-bottom: 1px solid #e3e5e7;
  background-color: white;
  text-align: center;
  padding: 1% 4% 5% 4%;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  margin: ${props => (props.sidepanel ? null : '1%')};

  @media (max-width: 420px) {
    padding: 1% 10% 1% 5%;
    margin-top: 5%;
  }
  @media (min-width: 421px) and (max-width: 599px) {
    padding: 1% 7% 10% 4%;
    margin-top: 5%;
  }
  @media (min-width: 600px) and (max-width: 900px) {
    min-width: 409px;
    padding: 1% 4.6% 19% 2%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    min-width: 391px;
    padding: 1% 4% 12% 2%;
  }
  @media (min-width: 1280px) and (max-width: 1600px) {
    min-width: 410px;
    padding: 1% 2% 9% 1%;
  }
  @media (min-width: 1600px) {
    min-width: 396px;
    padding: 1% 2% 5.5% 1%;
  }
`;

export const LogoLink = styled(Link)`
  grid-area: logo;
`;
export const LoggedOutTemplateLogo = styled.img.attrs({
  id: 'loggedOutLogo',
})`
  width: auto;
  height: auto;
  padding: 30px 0px 30px 20px;
`;
export const FooterContainer = styled.div`
  @media (max-width: 599px) {
    position: initial;
  }
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
`;
export const LoginButtonContainer = styled.div.attrs({
  id: 'LoginButtonContainer',
})`
  text-align: left;
  margin-top: 8px;
`;
export const LoginButton = styled.button.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: left;
  background-color: ${props => props.theme.primaryColor};
  padding: 6px 12px;
`;
export const LoginContainer = styled.div.attrs({ id: 'loginContainer' })`
  display: flex;
  justify-content: center;
  @media (min-width: 275px) and (max-width: 336px) {
    flex-direction: column;
    width: 125%;
  }
  @media (min-width: 335px) and (max-width: 599px) {
    flex-direction: column;
    width: 98%;
  }
  @media (max-width: 600px) {
    flex-direction: column;
    padding-bottom: 150px;
  }
  @media (min-width: 601px) {
    flex-direction: row;
    padding-bottom: 150px;
  }
`;

export const OktaContainer = styled.div.attrs({ id: 'oktaContainer' })`
  .o-form-head {
    @media (min-width: 275px) and (max-width: 420px) {
      margin-bottom: 0;
    }
  }
`;

export const StyledSpan = styled.span.attrs({})`
  font-size: 12px;
  margin-right: 4px;
  text-align: left;
`;
export const CreateAccountButton = styled(Link).attrs({
  id: 'createAccountButton',
})`
  font-size: 12px;
`;
export const ReportClaimButton = styled.button.attrs({})`
  margin-top: 10px;
  padding: 8px 100px;
  width: 104.5%;
  border: ${props => (props.theme.primaryColor ? `1px solid ${props.theme.primaryColor}` : 'none')};
  background-color: ${props => props.theme.primaryColor};
  color: white;
`;
export const MakePaymentAnonymousButton = styled(ReportClaimButton).attrs({})``;
export const ReportClaimContainer = styled.div`
  margin-top: 30px;
  width: 100%;
  text-align: center;
  @media (min-width: 275px) and (max-width: 420px) {
    margin-top: 15px;
  }
`;
export const MakePaymentAnonymousContainer = styled(ReportClaimContainer)``;
export const StyledDiv = styled.div.attrs({})`
  font-size: 12px;
  margin-right: 4px;
`;
export const SidepanelContainer = styled.div.attrs({
  id: 'sidepanelContainer',
})`
  max-width: 600px;
  border-top: 8px solid ${props => props.theme.primaryColor};
  border-left: 1px solid #e3e5e7;
  border-right: 1px solid #e3e5e7;
  border-bottom: 1px solid #e3e5e7;
  background-color: white;
  text-align: center;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
`;
export const MessageBody = styled.div`
  margin: 0 auto;
  max-width: 475px;
`;
export const MessageTitleContainer = styled.div`
  margin: 20px auto;
`;
export const MessageTitle = styled.h3`
  font-size: 18px;
  font-family: inherit;
  font-weight: 500;
  text-align: center;
  line-height: 1.1;
  margin: 10px auto;
`;
export const MessageContainer = styled.div`
  padding: 15px 45px;
`;
