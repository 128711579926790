import { setI18n } from './i18nReducer';
import axios from 'axios';

export const loadI18n = () => dispatch => {
  return axios
    .get('../../../config/i18n/en.json')
    .then(vals => {
      dispatch(setI18n(vals.data));
    })
    .catch(err => console.log('Error loading i18n settings.', err));
};
