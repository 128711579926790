import every from 'lodash/every';
import moment from 'moment';

export const isEmail = value => {
  if (!(typeof value === 'string')) {
    return false;
  }

  const [localPart, domain] = value.trim().split('@');
  const [primaryDomain, tld] = (domain || '').split('.');
  return every([localPart, primaryDomain, tld]);
};

export const isPhone = value => {
  if (!(typeof value === 'string')) {
    return false;
  }

  const digits = value.replace(/\D+/g, '');
  const hasLetters = /[a-z]/i.test(value);

  if (hasLetters) {
    return false;
  }

  return digits.length >= 10 && digits.length <= 15;
};

export const formatPhoneNumber = value => {
  // if input value is falsy eg if the user deletes the input, then just return
  if (!value) return value;

  // clean the input for any non-digit values.
  const phoneNumber = value.replace(/[^\d]/g, '');

  // phoneNumberLength is used to know when to apply our formatting for the phone number
  const phoneNumberLength = phoneNumber.length;

  // we need to return the value with no formatting if its less then four digits
  // this is to avoid weird behavior that occurs if you  format the area code to early

  if (phoneNumberLength < 4) return phoneNumber;

  // if phoneNumberLength is greater than 4 and less the 7 we start to return
  // the formatted number
  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  // if phone number is international style (i.e. 1XXX-XXX-XXXX) then format appropriately.
  // This is only designed to work with U.S. phone numbers.
  if (phoneNumber.indexOf('1') === 0) {
    return `(${phoneNumber.slice(1, 4)}) ${phoneNumber.slice(4, 7)}-${phoneNumber.slice(7, 11)}`;
  }
  // finally, if the phoneNumberLength is greater then seven, we add the last
  // bit of formatting and return it.
  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
};
export const checkExpiration = expirationDate => moment(expirationDate).isAfter(moment());
export const millisecondsToMinutes = milliseconds => {
  let seconds = Math.floor(milliseconds / 1000);
  let minutes = Math.floor(seconds / 60);
  return minutes;
};
