import styled from 'styled-components';
import Select from 'react-select';

export const ReportClaimsSelectionHeader = styled.h3.attrs({
  id: 'ReportClaimsSubHeader',
})`
  margin-top: 30px;
  margin-bottom: 5px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const LocationEvent = styled(Select)`
  width: 50%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 100%;
  }
  @media (min-width: 1280px) {
    width: 49%;
  }
`;
export const EmergencyMessageComponent = styled.div.attrs({ id: 'emergencyMessageComponent' })`
  max-width: 545px;
  background-color: #fcf4f4;
  color: black;
  border: 1px solid #c4221f;
  border-radius: 4px;
  padding: 9px 20px 12px 20px;
  margin: 24px 0;
`;
export const LossEventMessage = styled.h4.attrs({
  id: 'lossMessage',
})`
  font-weight: 400;
  font-size: 16px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
