import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {
  RadioAddressLabel,
  RemoveMortgageeButtonsContainer,
  RemoveMortgageeCancelButton,
  RemoveMortgageePropertyComponent,
  RemoveMortgageeRadioButton,
  RemoveMortgageeSubmitButton,
  RemoveMortgageeTextAreaField,
  RemovePropertyMortgageeContainer,
  RemovePropertyMortgageeField,
  RemovePropertyMortgageeFieldHeader,
  RequiredField,
} from './styles';
import Loader from '../../../../common/loader';
import { createField, formatRecapFieldDate, getMortgageeById } from '../selector';
import {
  getAdditionalInterests,
  getMortgageeInterestTypes,
  submitPolicyChange,
} from '../../policyChangeAPI';
import { setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { Spacer, StyledLoaderContainer } from '../../styles';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useHistory } from 'react-router';

export const RemovePropertyMortgagee = props => {
  const {
    propertyMortgageeList,
    customerId,
    selectedPolicy,
    selectedPolicyChangeTemplate,
    isPropertyMortgageeListLoading,
    i18n,
  } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [description, setDescription] = useState('');
  const [selectedMortgageeId, setSelectedMortgageeId] = useState('');
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [selectedMortgagee, setSelectedMortgagee] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      effectiveDate.length > 0 && description.length > 0 && selectedMortgageeId.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Description', description, 'textArea'));
    fields.push(createField('Name', selectedMortgagee.interestName, 'text'));
    fields.push(createField('Type', selectedMortgagee.interestTypeCd, 'text'));
    fields.push(createField('Address', selectedMortgagee.addr1, 'text'));
    fields.push(createField('City', selectedMortgagee.city, 'text'));
    fields.push(createField('State', selectedMortgagee.stateProvCd, 'text'));
    fields.push(createField('ZIP', selectedMortgagee.postalCode, 'text'));
    fields.push(createField('Loan Number', selectedMortgagee.accountNumber, 'text'));
    return fields;
  };

  checkRequiredFields();

  const onSubmitButtonClick = () => {
    setIsSubmitting(true);
    setIsSubmitButtonEnabled(false);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: description,
          ai: [selectedMortgagee.originalValue],
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  useEffect(() => {
    props.dispatch(getMortgageeInterestTypes());
    if (selectedPolicy?.value !== 'default') {
      props.dispatch(getAdditionalInterests(selectedPolicy.value));
    }
  }, [selectedPolicy]);

  const history = useHistory();

  return (
    <>
      {propertyMortgageeList !== null && propertyMortgageeList.length === 0 ? (
        <>
          <RemovePropertyMortgageeFieldHeader>
            No mortgagees available to remove.
          </RemovePropertyMortgageeFieldHeader>
        </>
      ) : (
        <RemovePropertyMortgageeContainer>
          <RemovePropertyMortgageeField>
            <RemovePropertyMortgageeFieldHeader>
              Requested Effective Date <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.removePropertyMortgagee.effectiveDateTooltip')}
              />
            </RemovePropertyMortgageeFieldHeader>
            <input
              type="date"
              aria-label="Requested Effective Date"
              value={effectiveDate}
              min={todayDt}
              onChange={e => {
                setEffectiveDate(e.target.value);
              }}
            />
          </RemovePropertyMortgageeField>
          <RemovePropertyMortgageeFieldHeader>
            Description <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.removePropertyMortgagee.descriptionTooltip')}
            />
          </RemovePropertyMortgageeFieldHeader>
          <RemoveMortgageeTextAreaField
            value={description}
            onChange={e => {
              setDescription(e.target.value);
            }}
          />
          {isPropertyMortgageeListLoading ? (
            <Loader />
          ) : (
            <RemovePropertyMortgageeField>
              <RemovePropertyMortgageeFieldHeader>
                Mortgagees to be removed <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.removePropertyMortgagee.mortgageesToBeRemovedTooltip',
                  )}
                />
              </RemovePropertyMortgageeFieldHeader>
              {propertyMortgageeList &&
                propertyMortgageeList.map(mortgagee => {
                  return (
                    <RemoveMortgageePropertyComponent key={mortgagee.id}>
                      <RemoveMortgageeRadioButton
                        id={mortgagee.interestName}
                        name="riskProperty"
                        value={mortgagee.id}
                        onChange={e => {
                          setSelectedMortgageeId(e.target.value);
                          setSelectedMortgagee(
                            getMortgageeById(e.target.value, propertyMortgageeList),
                          );
                        }}
                      />{' '}
                      <RadioAddressLabel id={mortgagee.interestName + ' Label'}>
                        {mortgagee.interestName} <br /> {mortgagee.interestTypeCd} <br />
                        {mortgagee.addr1} {mortgagee.addr2} <br /> {mortgagee.city},
                        {mortgagee.stateProvCd} {mortgagee.postalCode}
                      </RadioAddressLabel>
                    </RemoveMortgageePropertyComponent>
                  );
                })}
            </RemovePropertyMortgageeField>
          )}
          <RemoveMortgageeButtonsContainer>
            <RemoveMortgageeCancelButton
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Cancel
            </RemoveMortgageeCancelButton>
            <RemoveMortgageeSubmitButton
              disabled={!isSubmitButtonEnabled}
              onClick={() => {
                onSubmitButtonClick();
              }}
            >
              Submit
            </RemoveMortgageeSubmitButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </RemoveMortgageeButtonsContainer>
        </RemovePropertyMortgageeContainer>
      )}
    </>
  );
};

RemovePropertyMortgagee.propTypes = {
  dispatch: PropTypes.func,
  propertyMortgageeList: PropTypes.array,
  mortgageeInterestTypes: PropTypes.array,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  isPropertyMortgageeListLoading: PropTypes.bool,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  propertyMortgageeList: state.policyChangeSlice.propertyMortgageeList,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  isPropertyMortgageeListLoading: state.policyChangeSlice.isPropertyMortgageeListLoading,
  customerId: state.customerSlice.customer.systemId,
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(RemovePropertyMortgagee);
