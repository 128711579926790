import React, { useState } from 'react';
import { RequiredField, TemplateBasedField, TemplateBasedFieldHeader, TemplateBasedNumericInputField } from './styles';
import Select from 'react-select';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import AdditionSubQuestionField from './additional-sub-question-field';
import { YES_NO_DEFAULT } from './selector';
import { getDeductibles } from '../selector';

const yesNoOptions = [
  { label: 'Yes', value: 'Yes' },
  { label: 'No', value: 'No' },
];

export const AdditionQuestionField = props => {
  const { id, q, index, setAdditionalQuestionsAnswers, setAdditionalSubQuestionsAnswers } = props;
  const yesNoInitialState = q.defaultValue || YES_NO_DEFAULT;
  const [selectedYesNoValue, setSelectedYesNoValue] = useState({
    label: yesNoInitialState,
    value: yesNoInitialState,
  });
  const [selectedListValue, setSelectedListValue] = useState({ label: '-- Select --', value: '' });
  const [numericInputValue, setNumericInputValue] = useState('');

  const listOptions = q.constraints ? getDeductibles(q.constraints) : [];

  const getInputField = type => {
    let input;
    switch (type) {
      case 'YesNo':
        input = (
          <Select
            id={id}
            options={yesNoOptions}
            value={selectedYesNoValue}
            onChange={selectedValue => {
              setSelectedYesNoValue(selectedValue);
              setAdditionalQuestionsAnswers(index, selectedValue.value);
            }}
          />
        );
        break;
      case 'List':
        input = (
          <Select
            id={id}
            options={listOptions}
            value={selectedListValue}
            onChange={selectedValue => {
              setSelectedListValue(selectedValue);
              setAdditionalQuestionsAnswers(index, selectedValue.value);
            }}
          />
        );
        break;
      case 'Numeric':
        input = (
          <TemplateBasedNumericInputField
            id={id}
            value={numericInputValue}
            onChange={e => {
              setAdditionalQuestionsAnswers(index, e.target.value);
              setNumericInputValue(e.target.value);
            }}
          />
        );
        break;
      default:
        input = null;
    }
    return input;
  };

  return (
    <TemplateBasedField key={q.name}>
      <TemplateBasedFieldHeader>
        {q.text}
        {q.requiredInd === 'Yes' && <RequiredField>*</RequiredField>}
      </TemplateBasedFieldHeader>
      {getInputField(q.dataType)}
      {q.questions &&
        q.questions.question &&
        q.questions.question.map((subQuestion, subIndex) => (
          <div key={subIndex}>
            {selectedYesNoValue.value === subQuestion.showOnParentValue && (
              <AdditionSubQuestionField
                id={id}
                q={subQuestion}
                index={subIndex}
                parentIndex={index}
                setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
              />
            )}
          </div>
        ))}
    </TemplateBasedField>
  );
};
AdditionQuestionField.propTypes = {
  id: PropTypes.string,
  q: PropTypes.object,
  index: PropTypes.number,
  setAdditionalQuestionsAnswers: PropTypes.func,
  setAdditionalSubQuestionsAnswers: PropTypes.func,
};

const mapStateToProps = state => ({
  state,
});

export default connect(mapStateToProps)(AdditionQuestionField);
