import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ErrorMessageContainer = styled.div`
  margin: 5% 2% 2% 2%;
  width: 100%;
  text-align: center;
`;
export const ErrorMessageTitle = styled.h3.attrs({})`
  font-family: 'Helvetica Neue', Lato, proxima-nova, Arial, Helvetica, sans-serif, sans-serif;
  font-size: 18px;
  margin-left: 20px;
`;
export const ErrorMessageBody = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
`;
export const LogoLink = styled(Link)`
  grid-area: logo;
`;
