import React from 'react';
import Dialog from '@mui/material/Dialog';
import PropTypes from 'prop-types';
import { DialogContainer } from './styles';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import AppBar from '@mui/material/AppBar';

export const Modal = props => {
  const { modalHtml, dialogOpen, setDialogClosed, customModal, modalTitle } = props;

  return (
    <Dialog open={dialogOpen} onClose={setDialogClosed} fullScreen={customModal}>
      {customModal ? (
        <>
          <AppBar>
            <Toolbar>
              <IconButton edge="end" color="inherit" onClick={setDialogClosed} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6">{modalTitle}</Typography>
            </Toolbar>
          </AppBar>
          <DialogContainer dangerouslySetInnerHTML={{ __html: modalHtml }} />
        </>
      ) : (
        <DialogContainer dangerouslySetInnerHTML={{ __html: modalHtml }} />
      )}
    </Dialog>
  );
};
Modal.propTypes = {
  dialogOpen: PropTypes.bool,
  modalHtml: PropTypes.any,
  modalTitle: PropTypes.string,
  setDialogClosed: PropTypes.func,
  setDialogOpened: PropTypes.func,
  customModal: PropTypes.bool,
};
export default Modal;
