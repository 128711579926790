import { API } from '../../../utils/api';
import {
  setAttachmentTemplates,
  setAttachmentTemplatesLoading,
  setErrors,
} from './fileUploadReducer';

export const getAttachmentTemplates = (beanType, beanRef, numberResults) => {
  return async dispatch => {
    return API.get(`attachmentTemplates?container=${beanType}&containerRef=${beanRef}&limit=${numberResults}`)
      .then(response => {
        const coderefs = [];
        let attachmentTemplates = response.data.attachmentTemplateListItems;
        if (attachmentTemplates && attachmentTemplates.length > 0) {
          return API.get(
            `coderefs/${
              beanType === 'Policy' ? 'UWPolicy' : 'CLClaim'
            }/insured-attachments/templates`,
          )
            .then(response => {
              if (response.data && response.data.keys && response.data.keys[0].options) {
                response.data.keys[0].options.forEach(coderef => {
                  coderefs[coderef.value] = true;
                });
              }
              attachmentTemplates = attachmentTemplates.filter(attachmentTemplate => {
                return coderefs[attachmentTemplate.id] === true;
              });
              attachmentTemplates.forEach(attachmentTemplate => {
                attachmentTemplate.label = attachmentTemplate.name;
                attachmentTemplate.value = attachmentTemplate.id;
              });
              dispatch(setAttachmentTemplates(attachmentTemplates));
              dispatch(setAttachmentTemplatesLoading(false));
            })
            .catch(err => {
              dispatch(setErrors(err.message));
              dispatch(setAttachmentTemplatesLoading(false));
            });
        } else {
          dispatch(setAttachmentTemplates([]));
          dispatch(setAttachmentTemplatesLoading(false));
        }
      })
      .catch(err => {
        dispatch(setErrors(err));
      });
  };
};
