import styled from 'styled-components';

export const AnnouncementsHeader = styled.h3.attrs({
  id: 'announcementsHeader',
})`
  @media (min-width: 600px) and (max-width: 959px) {
    padding: 2% 0 0 4px;
  }
  @media (max-width: 599px) {
    padding: 5% 0 0 4px;
  }
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-left: 20px;
  color: white;
`;

export const NewsBannerTile = styled.div.attrs(props => ({
  id: `newsBannerTile_${props.key}`,
}))`
  margin: 15px;
  padding: 15px;
  text-align: left;
  background-color: #f1f1f1;
  @media (min-width: 900px) {
    background-color: white;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    margin-left: 25px;
  }
  @media (max-width: 599px) {
    padding: 5%;
    margin-left: 23px;
    margin-right: 23px;
  }
`;

export const NewsBannerTileTemplate2 = styled.div.attrs(props => ({
  id: `newsBannerTile2_${props.key}`,
}))`
  margin: 0 10px 0 10px;
  padding: 15px 0 15px 0;
  text-align: left;
  max-width: 900px;
`;

export const TemplateOnePanel = styled.div.attrs({
  id: 'templateOnePanel',
})`
  background-color: ${props => props.theme.primaryColor};
  color: white;
  text-align: center;
  padding-top: 16px;
  padding-bottom: 16px;
  font-size: 24px;
`;
export const NewsBannerContainer = styled.div.attrs({
  id: 'outshellNewsBannerContainer',
})`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin: 2%;
  }
  border: 3px solid ${props => props.theme.primaryColor};
  margin: 6%;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
`;

export const ScrollableContainer = styled.div.attrs({
  id: 'scrollableContainer',
})`
  @media (min-width: 1280px) {
    overflow-y: auto;
  }
`;
export const AnnouncementsContainer = styled.div.attrs({
  id: 'announcementsContainerTemplate2',
})`
  @media (min-width: 600px) and (max-width: 959px) {
    padding-bottom: 70px;
  }
  @media (max-width: 599px) {
    padding-bottom: 150px;
  }
`;
