import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import {
  AvailabilityContainer,
  Button,
  CancelButton,
  CheckAvailButton,
  CloserContainer,
  GetAQuoteContainer,
  GetAQuoteFormContainer,
  GetAQuoteSubheader,
  ModalCloserSpaced,
  ProductAvailabilityButtonContainer,
  ProductLabel,
  ProductListContainer,
  RadioChoice,
  ZipCodeField,
  ZipCodeLabel,
} from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { checkProductAvailability, startQuote } from './openQuotesApi';
import { resetState, setAvailableProduct, setAvailableProductError } from './openQuotesReducer';
import Loader from '../../common/loader';
import { getMailingAddress } from '../user-profile/selectors';
import ApiError from '../../common/api-error';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';

export const GetQuoteModal = (props) => {
  const {
    dispatch,
    errors,
    products,
    isAvailableProductLoading,
    availableProduct,
    customerId,
    salesPortalUrl,
    availableProductError,
    mailingZip,
    i18n,
  } = props;
  const [open, setOpen] = useState(false);
  const [product, setProduct] = useState('');
  const [zip, setZip] = useState('');
  const [selectedZip, setSelectedZip] = useState('');
  const handleOpen = () => {
    dispatch(setAvailableProduct(null));
    dispatch(setAvailableProductError(null));
    setProduct('');
    setZip(mailingZip);
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const setSelectedProduct = (selectedProduct) => {
    setProduct(selectedProduct);
  };

  const handleCheckProductAvailabilityButtonClick = () => {
    props.dispatch(checkProductAvailability(zip, product));
    setSelectedZip(zip);
  };

  const handleStartQuoteButtonClick = () => {
    setOpen(false);
    props.dispatch(startQuote(customerId, salesPortalUrl, zip, availableProduct));
  };

  return (
    <>
      <Button onClick={handleOpen}>Get a Quote</Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md">
        <CloserContainer>
          <ModalCloserSpaced onClick={() => handleClose()} aria-label="Close Button">
            x
          </ModalCloserSpaced>
        </CloserContainer>
        <GetAQuoteContainer id="mainView">
          {isAvailableProductLoading ? (
            <Loader />
          ) : availableProduct === null || (availableProduct && availableProduct.noProducts) ? (
            <div id="pageOne">
              {availableProduct && availableProduct.noProducts ? (
                <ApiError body={availableProductError} variant="modal" />
              ) : null}
              <GetAQuoteSubheader>
                {availableProduct && availableProduct.noProducts
                  ? 'Not Available'
                  : "Let's Get Started"}
              </GetAQuoteSubheader>
              <AvailabilityContainer>
                {availableProduct && availableProduct.noProducts
                  ? `Sorry, we are unable to quote ${product} insurance for ZIP code ${selectedZip}.`
                  : 'We will check availability of the product at a location.'}
              </AvailabilityContainer>
              <div>
                {availableProduct && availableProduct.noProducts
                  ? 'You can check availability at another location'
                  : 'Enter location ZIP code'}
              </div>
              <GetAQuoteFormContainer>
                <div>
                  <ZipCodeLabel>
                    ZIP Code
                    <TooltipInfo title={getText(i18n, 'getQuote.zipTooltip')} />
                  </ZipCodeLabel>
                  <ZipCodeField
                    type="text"
                    aria-label="Zip Code"
                    value={zip}
                    onChange={(e) => setZip(e.target.value)}
                  />
                </div>
                <div>
                  <ProductLabel>
                    Product
                    <TooltipInfo title={getText(i18n, 'getQuote.productTooltip')} />
                  </ProductLabel>
                  <ProductListContainer>
                    {products &&
                      products.map((product) => (
                        <label key={product} htmlFor={product}>
                          <div>
                            <RadioChoice
                              name="product"
                              value={product}
                              id={product}
                              onChange={(e) => {
                                setSelectedProduct(e.target.value);
                              }}
                            />
                            {product}
                          </div>
                        </label>
                      ))}
                  </ProductListContainer>
                </div>
              </GetAQuoteFormContainer>
              <ProductAvailabilityButtonContainer>
                <CancelButton id="Cancel" onClick={() => handleClose()}>
                  Close
                </CancelButton>
                <CheckAvailButton
                  id="checkAvailability"
                  onClick={() => handleCheckProductAvailabilityButtonClick()}
                  disabled={zip.length < 5 || product.length === 0}
                >
                  Check Availability
                </CheckAvailButton>
              </ProductAvailabilityButtonContainer>
            </div>
          ) : (
            <div id="pageTwo">
              {errors ? (
                <ApiError
                  body="We are unable to start a quote right now, please contact your agent or try again
                  later."
                />
              ) : (
                <>
                  <GetAQuoteSubheader>Good News</GetAQuoteSubheader>
                  <AvailabilityContainer>
                    {availableProduct.productName} insurance is available for ZIP code {selectedZip}
                    .
                  </AvailabilityContainer>
                  <div>
                    Proceed to our Sales Portal site to get your quote (opens in a new tab or
                    window).
                  </div>
                  <ProductAvailabilityButtonContainer>
                    <CancelButton onClick={() => handleClose()}>Close</CancelButton>

                    <CheckAvailButton onClick={() => handleStartQuoteButtonClick()}>
                      Start Quote
                    </CheckAvailButton>
                  </ProductAvailabilityButtonContainer>
                </>
              )}
            </div>
          )}
        </GetAQuoteContainer>
      </Dialog>
    </>
  );
};
GetQuoteModal.propTypes = {
  dispatch: PropTypes.func,
  errors: PropTypes.string,
  customerId: PropTypes.string,
  products: PropTypes.array,
  isAvailableProductLoading: PropTypes.bool,
  availableProduct: PropTypes.object,
  availableProductError: PropTypes.string,
  salesPortalUrl: PropTypes.string,
  mailingZip: PropTypes.string,
  i18n: PropTypes.object,
};
const mapStateToProps = (state) => ({
  customerId: state.customerSlice.customer.systemId,
  errors: state.openQuotesSlice.errors,
  products: state.configurationSlice.features.cpProducts,
  isAvailableProductLoading: state.openQuotesSlice.isAvailableProductLoading,
  availableProduct: state.openQuotesSlice.availableProduct,
  availableProductError: state.openQuotesSlice.availableProductError,
  salesPortalUrl: state.configurationSlice.features.salesPortalUrl,
  mailingZip: getMailingAddress(state)?.postalCode,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(GetQuoteModal);
