import { createSlice } from '@reduxjs/toolkit';
const initialState = {
  activeStep: 0,
  amountToPay: null,
  // amountToPay: '0',
  acceptedPendingPaymentMessage: false,
  billSelected: {},
  confirmationNumber: null,
  error: null,
  isLoading: false,
  instrumentToPayWith: {},
  makePaymentStarted: false,
  nextButtonClicked: false,
  nextButtonDisabled: true,
  accountInfo: {},
  payDate: null, //in case we add scheduled payment dates
  radioAmountType: null,
  paymentConfigId: null,
  response: null,
};

export const makePaymentAnonymousSlice = createSlice({
  name: 'makePaymentAnonymous',
  initialState,
  reducers: {
    resetMakePaymentAnonymous: () => {
      return initialState;
    },
    setActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload };
    },
    setAmountToPay: (state, action) => {
      return { ...state, amountToPay: action.payload };
    },
    setBillSelected: (state, action) => {
      return { ...state, billSelected: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, error: action.payload };
    },
    setPayDate: (state, action) => {
      return { ...state, payDate: action.payload };
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setMakePaymentStarted: (state, action) => {
      return { ...state, makePaymentStarted: action.payload };
    },
    setNextButtonClicked: (state, action) => {
      return { ...state, nextButtonClicked: action.payload };
    },
    setNextButtonDisabled: (state, action) => {
      return { ...state, nextButtonDisabled: action.payload };
    },
    setInstrumentToPayWith: (state, action) => {
      return { ...state, instrumentToPayWith: action.payload };
    },
    setRadioAmountType: (state, action) => {
      return { ...state, radioAmountType: action.payload };
    },
    setResponse: (state, action) => {
      return { ...state, response: action.payload };
    },
    setPaymentConfigId: (state, action) => {
      return { ...state, paymentConfigId: action.payload };
    },
    setConfirmationNumber: (state, action) => {
      return { ...state, confirmationNumber: action.payload };
    },
  },
});
export const {
  resetMakePaymentAnonymous,
  setActiveStep,
  setAmountToPay,
  setBillSelected,
  setConfirmationNumber,
  setErrors,
  setIsLoading,
  setMakePaymentStarted,
  setNextButtonClicked,
  setNextButtonDisabled,
  setInstrumentToPayWith,
  setPayDate,
  setRadioAmountType,
  setPaymentConfigId,
  setResponse,
} = makePaymentAnonymousSlice.actions;
export default makePaymentAnonymousSlice.reducer;
