import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import Loader from '../../common/loader';
import AuthenticatedLink from '../../common/authenticated-link';
import { getIdCards, getIdCardsTemplateTypes, postMailIdCards } from './idCardsAPI';
import {
  DescriptionLabel,
  DigitalIdCardHeader,
  DownloadLinkContainer,
  DownloadLinkLabel,
  IdCardsContainer,
  IdCardsDescriptionContainer,
  IdCardsDocumentsContainer,
  IdCardsDocumentsDataContainer,
  IdCardsPrintLabelContainer,
  LoaderMargin,
  MailIdsButton,
  PdfIcon,
  StyledLoader,
} from './styles';
import { setIdCardsDocuments } from './idCardsReducer';
import ApiError from '../../common/api-error';
import { gaEvent } from '../../../utils/google-analytics';
import { ga4Event } from '../../../utils/google-analytics-4';

export const IdCards = props => {
  const {
    idCardsTemplates,
    idCardsDocuments,
    idMailed,
    documentErrors,
    mailingErrors,
    isLoading,
    customerId,
    policies,
  } = props;

  React.useEffect(() => {
    gaEvent({ category: 'request-id-cards', action: 'start' });
    ga4Event({ category: 'request-id-cards', action: 'start' });
    props.dispatch(setIdCardsDocuments(null));
    policies.forEach(policy => {
      props.dispatch(getIdCards(policy.policyRef));
    });
    props.dispatch(getIdCardsTemplateTypes());
  }, []);

  return (
    <>
      <Helmet>
        <title>Id Cards</title>
      </Helmet>
      <IdCardsContainer>
        <DigitalIdCardHeader aria-label="ID Cards Table">ID Cards</DigitalIdCardHeader>
        <IdCardsDocumentsContainer>
          {isLoading ? (
            <LoaderMargin>
              <Loader />
            </LoaderMargin>
          ) : (
            <>
              <IdCardsPrintLabelContainer>
                <DescriptionLabel aria-label="Column Header Description">
                  Description
                </DescriptionLabel>
                <DownloadLinkLabel aria-label="Column Header Link">Link</DownloadLinkLabel>
              </IdCardsPrintLabelContainer>
              {documentErrors ? (
                <ApiError body="There was an error loading your documents." variant="table" />
              ) : (
                <>
                  {idCardsDocuments &&
                    idCardsDocuments.map(document => {
                      return (
                        idCardsTemplates &&
                        idCardsTemplates.map(idTemplate => {
                          if (document && idTemplate.name === document.templateIdRef) {
                            return (
                              <IdCardsDocumentsDataContainer key={document.ref}>
                                <IdCardsDescriptionContainer>
                                  <div aria-label={`Description ${document.name}`}>
                                    {document.name}
                                  </div>
                                </IdCardsDescriptionContainer>
                                <DownloadLinkContainer aria-label="Download Link">
                                  <AuthenticatedLink
                                    url={document._links[0].href}
                                    filename={document.name + '.pdf'}
                                  >
                                    <PdfIcon />
                                    Download
                                  </AuthenticatedLink>
                                </DownloadLinkContainer>
                              </IdCardsDocumentsDataContainer>
                            );
                          } else {
                            return null;
                          }
                        })
                      );
                    })}
                </>
              )}
            </>
          )}
        </IdCardsDocumentsContainer>
        {idMailed ? (
          <>
            {mailingErrors ? (
              <div>Error! Mailing Id Cards cannot be completed at this time.</div>
            ) : (
              <p>Your Mail Id Card request has been submitted.</p>
            )}
          </>
        ) : (
          <p>To submit your request, click the Mail ID Cards button.</p>
        )}
        {!idMailed && (
          <>
            <MailIdsButton
              onClick={() => props.dispatch(postMailIdCards(customerId))}
              disabled={isLoading}
            >
              Mail ID Cards
            </MailIdsButton>
            {isLoading && <StyledLoader />}
          </>
        )}
      </IdCardsContainer>
    </>
  );
};

IdCards.propTypes = {
  idCardsDocuments: PropTypes.array,
  idCardsTemplates: PropTypes.array,
  dispatch: PropTypes.func,
  idMailed: PropTypes.bool,
  documentErrors: PropTypes.any,
  mailingErrors: PropTypes.any,
  isLoading: PropTypes.bool,
  customerId: PropTypes.string,
  policies: PropTypes.array,
};

const mapStateToProps = state => ({
  idCardsDocuments: state.idCardsSlice.idCardsDocuments,
  idCardsTemplates: state.idCardsSlice.idCardsTemplates,
  idMailed: state.idCardsSlice.idMailed,
  documentErrors: state.idCardsSlice.documentErrors,
  mailingErrors: state.idCardsSlice.mailingErrors,
  isLoading: state.idCardsSlice.isLoading,
  policies: state.policySlice.policies,
  customerId: state.customerSlice.customer.systemId,
});

IdCards.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(IdCards);
