import * as React from 'react';
import useMediaQuery from '@mui/material/useMediaQuery';

import DesktopDashboardTemplate1 from './desktop';
import MobileDashboardTemplate1 from './mobile';

export const DashboardTemplate1 = () => {
  const hidden = useMediaQuery((theme) => theme.breakpoints.down('md'));
  return hidden ? <MobileDashboardTemplate1 /> : <DesktopDashboardTemplate1 />;
};

export default DashboardTemplate1;
