import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  resetStepTwoState,
  setPaymentInstrument,
} from '../make-payment/step-two-amount/makePaymentStepTwoReducer';
import { resetState } from './payPlanReducer';
import {
  EditPaymentScheduleButtonContainer,
  PaymentScheduleCancelButton,
  SaveNewPaymentScheduleButton,
} from './styles';
import { gaEvent } from '../../../utils/google-analytics';
import { collapseEventBody, updatePayMethodBody, updatePayPlanBody } from './utils';
import { updatePayPlan } from './policyAPI';
import { useSnackbar } from 'notistack';
import { ga4Event } from '../../../utils/google-analytics-4';

export const CancelSaveContainer = (props) => {
  const {
    account,
    dispatch,
    customerId,
    fullAccount,
    hasOpenBalance,
    instrumentToPayWith,
    newPlan,
    payPlanDateToPay,
    policyToUpdatePayPlan,
    setPolicyToUpdatePayPlan,
    setShowEditPaymentMethod,
    setShowEditPaymentSchedule,
    setShowPaymentSchedule,
    showPaymentSchedule,
    showEditPaymentMethod,
    showEditPaymentSchedule,
    billingAccountsCountLimit
  } = props;
  const { enqueueSnackbar } = useSnackbar();
  let initialState = fullAccount.underlyingBillingAccounts
    ? fullAccount.underlyingBillingAccounts.reduce((result, account) => {
        result[account.id] = false;
        return result;
      }, {})
    : { [fullAccount.id]: false }; //for setting each schedule open or closed, if one policy just start with that one false
  const SavePaySchedule = async (accountId, account) => {
    const paymentServiceAccountId = account.arPolicy.carrierCd;
    if (showEditPaymentSchedule[accountId] === true) {
      const body = updatePayPlanBody(
        instrumentToPayWith,
        newPlan,
        payPlanDateToPay,
        customerId,
        paymentServiceAccountId,
      );
      await dispatch(
        updatePayPlan(
          policyToUpdatePayPlan.policyRef,
          body,
          enqueueSnackbar,
          customerId,
          hasOpenBalance,
          billingAccountsCountLimit
        ),
      );
      window.scrollTo(0, 0);
      dispatch(resetState());
      dispatch(resetStepTwoState());
      setShowPaymentSchedule(initialState);
      setShowEditPaymentSchedule(initialState);
      setPolicyToUpdatePayPlan(null);
    }
    if (showEditPaymentMethod[accountId] === true) {
      const body = updatePayMethodBody(instrumentToPayWith, account, customerId);
      await dispatch(
        updatePayPlan(
          policyToUpdatePayPlan.policyRef,
          body,
          enqueueSnackbar,
          customerId,
          hasOpenBalance,
          billingAccountsCountLimit,
          paymentServiceAccountId,
        ),
      );
      window.scrollTo(0, 0);
      dispatch(resetState());
      dispatch(resetStepTwoState());
      setShowPaymentSchedule(initialState);
      setShowEditPaymentSchedule(initialState);
      setShowEditPaymentMethod(initialState);
      setPolicyToUpdatePayPlan(null);
    }
  };

  const checkSaveDisabled = (accountId) => {
    if (showEditPaymentSchedule[accountId] === true) {
      if (
        newPlan &&
        newPlan.payPlanCd.includes('Direct') &&
        (newPlan.payPlan.dayOfMonthInd === 'Optional' || newPlan.payPlan.dayOfMonthInd === 'No')
      ) {
        return true;
      }
      if (
        newPlan &&
        (newPlan.payPlan.dayOfMonthInd === 'No' || newPlan.payPlan.dayOfMonthInd === 'Optional')
      ) {
        return newPlan && instrumentToPayWith;
      }
      return newPlan && payPlanDateToPay && payPlanDateToPay !== 0 && instrumentToPayWith;
    }
    if (showEditPaymentMethod[accountId] === true) {
      return instrumentToPayWith;
    }
  };
  return (
    <EditPaymentScheduleButtonContainer>
      <PaymentScheduleCancelButton
        onClick={() => {
          gaEvent(collapseEventBody);
          ga4Event(collapseEventBody);
          setShowPaymentSchedule({
            ...showPaymentSchedule,
            [account.id]: !showPaymentSchedule[account.id],
          });
          dispatch(setPaymentInstrument(null));
          setShowEditPaymentSchedule(initialState);
          setShowEditPaymentMethod(initialState);
        }}
      >
        Cancel
      </PaymentScheduleCancelButton>
      <SaveNewPaymentScheduleButton
        disabled={!checkSaveDisabled(account.id)}
        onClick={() => SavePaySchedule(account.id, account)}
      >
        Save
      </SaveNewPaymentScheduleButton>
    </EditPaymentScheduleButtonContainer>
  );
};
CancelSaveContainer.propTypes = {
  customerId: PropTypes.any,
  dispatch: PropTypes.any,
  fullAccount: PropTypes.any,
  instrumentToPayWith: PropTypes.any,
  payPlanDateToPay: PropTypes.any,
  payPlanOptions: PropTypes.array,
  policyToUpdatePayPlan: PropTypes.any,
  newPlan: PropTypes.any,
  setShowEditPaymentMethod: PropTypes.any,
  setPolicyToUpdatePayPlan: PropTypes.any,
  setShowEditPaymentSchedule: PropTypes.any,
  setShowPaymentSchedule: PropTypes.any,
  showEditPaymentMethod: PropTypes.any,
  showEditPaymentSchedule: PropTypes.any,
  showPaymentSchedule: PropTypes.any,
  account: PropTypes.any,
  hasOpenBalance: PropTypes.bool,
  billingAccountsCountLimit: PropTypes.string,
};
export const mapStateToProps = (state) => ({
  state,
  billingAccounts: state.billingSlice.billingAccounts,
  instrumentToPayWith: state.makePaymentStepTwoSlice.instrumentToPayWith,
  payPlanDateToPay: state.payPlanSlice.payPlanDateToPay,
  payPlanOptions: state.payPlanSlice.payPlanOptions,
  newPlan: state.payPlanSlice.newPlan,
  customerId: state.customerSlice.customer.systemId,
  editPayPlan: state.configurationSlice.features.features.editPayPlan,
  hasOpenBalance: state.configurationSlice.features.features.hasOpenBalance,
  policies: state.policySlice.policies,
  billingAccountsCountLimit: state.configurationSlice.features.billingAccountsCountLimit,
});

export default connect(mapStateToProps)(CancelSaveContainer);
