import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  activeStep: 0,
  accountToPayWith: null,
  acceptedPendingPaymentMessage: false,
  isLoading: false,
  nextButtonClicked: false,
  nextButtonDisabled: true,
};

export const makePaymentSlice = createSlice({
  name: 'makePaymentSlice',
  initialState,
  reducers: {
    resetStepOneState: () => {
      return initialState;
    },
    setAccountToPayWith: (state, action) => {
      return { ...state, accountToPayWith: action.payload };
    },
    setAcceptedPendingPaymentMessage: (state, action) => {
      return { ...state, acceptedPendingPaymentMessage: action.payload };
    },
    setActiveStep: (state, action) => {
      return { ...state, activeStep: action.payload };
    },
    setIsLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setNextButtonClicked: (state, action) => {
      return { ...state, nextButtonClicked: action.payload };
    },
    setNextButtonDisabled: (state, action) => {
      return { ...state, nextButtonDisabled: action.payload };
    },
  },
});
export const {
  resetStepOneState,
  setAcceptedPendingPaymentMessage,
  setAccountToPayWith,
  setActiveStep,
  setIsLoading,
  setNextButtonClicked,
  setNextButtonDisabled,
} = makePaymentSlice.actions;
export default makePaymentSlice.reducer;
