import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ErrorMessageBody, ErrorMessageContainer, ErrorMessageTitle } from './styles';
import { FooterContainer, LogoLink, LoggedOutTemplateLogo } from '../../features/login/styles';
import Footer from '../../features/dashboard/footer';
import { getText } from '../../../utils/i18n';

export const ErrorPage = props => {
  const { isServiceUnavailable, i18n, companyName } = props;
  let title;
  let body;
  try {
    if (isServiceUnavailable) {
      title = getText(
        i18n,
        'serviceUnavailable.title',
        'We are currently experiencing technical difficulties.',
      );
      body = getText(
        i18n,
        'serviceUnavailable.body',
        'We sincerely apologize for the inconvenience. Please try again later.',
      );
    } else {
      title = getText(i18n, 'downTime.title', 'System Down for Maintenance.');
      body = getText(
        i18n,
        'downTime.body',
        'We apologize that our system is currently unavailable.',
      );
    }
  } catch (err) {
    title = 'System Down for Maintenance.';
    body = 'We apologize that our system is currently unavailable.';
  }
  return (
    <>
      <LogoLink to="/dashboard">
        <LoggedOutTemplateLogo
          src={`config/images/loggedout-logo.png`}
          alt={companyName + ' logo'}
        />
      </LogoLink>
      <ErrorMessageContainer>
        <ErrorMessageTitle>{title}</ErrorMessageTitle>
        <ErrorMessageBody>{body}</ErrorMessageBody>
      </ErrorMessageContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
    </>
  );
};
ErrorPage.propTypes = {
  i18n: PropTypes.object,
  isServiceUnavailable: PropTypes.bool,
  companyName: PropTypes.string,
};
export const mapStateToProps = state => ({
  i18n: state.i18nSlice.i18n,
  isServiceUnavailable: state.configurationSlice.isServiceUnavailable,
  companyName: state.configurationSlice.features.companyName,
  state,
});
export default connect(mapStateToProps)(ErrorPage);
