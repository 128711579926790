import styled from 'styled-components';

export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
    margin-top: 16px;
  }
`;
export const PolicyDashboardContainer = styled.div.attrs({
  id: 'policyDashboardContainer',
})``;

export const PolicyHeader = styled.h1.attrs({
  id: 'policyHeader',
  'aria-label': 'Policies Table',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.125rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    margin-left: 5%;
  }
`;
export const PolicyLabelContainer = styled.div.attrs({
  className: 'policyColumnHeader',
})`
  display: grid;
  word-break: break-word;
  @media (max-width: 599px) {
    display: none;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: ${props =>
      props.policyDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${props =>
      props.policyDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 3%;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${props =>
      props.policyDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 5%;
  }
`;
export const policyInformationLabel = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const PolicyLabel = styled(policyInformationLabel).attrs({
  id: 'policyNumberLabel',
})``;
export const EffectiveDateLabel = styled(policyInformationLabel).attrs({
  id: 'effectiveDateLabel',
})``;
export const ExpirationDateLabel = styled(policyInformationLabel).attrs({
  id: 'expirationDateLabel',
})``;
export const StatusLabel = styled(policyInformationLabel).attrs({
  id: 'statusLabel',
})``;
export const UploadLabel = styled(policyInformationLabel).attrs({
  id: 'uploadLabel',
})``;

export const PolicyLabelMobile = styled(policyInformationLabel).attrs({
  id: 'policyNumberLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;

export const PolicyDescriptionLabelMobile = styled(policyInformationLabel).attrs({
  id: 'policyDescriptionLabelMobile',
})`
  font-weight: normal;
  font-size: 0.9rem;
`;
export const EffectiveDateLabelMobile = styled(policyInformationLabel).attrs({
  id: 'effectiveDateLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const ExpirationDateLabelMobile = styled(policyInformationLabel).attrs({
  id: 'expirationDateLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const UploadLabelMobile = styled(policyInformationLabel).attrs({
  id: 'uploadDateLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const PolicyStatusLabelMobile = styled(policyInformationLabel).attrs({
  id: 'policyStatusLabel',
})`
  @media (min-width: 600px) {
    display: none;
  }
`;
export const UploadIcon = styled.i.attrs({
  id: 'uploadIcon',
})`
  @media (max-width: 299px) {
    margin-top: 10px;
  }
`;
export const policyDataDiv = styled.div.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;

export const PolicyContainer = styled(policyDataDiv).attrs({
  id: 'policyNumberContainer',
})`
  word-break: break-word;
  @media (min-width: 300px) and (max-width: 599px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;
export const PolicyStatusContainer = styled(PolicyContainer)``;
export const PolicyUploadButtonContainer = styled(PolicyContainer)`
  @media (max-width: 299px) {
    margin-bottom: 10px;
  }
  @media (min-width: 300px) and (max-width: 599px) {
    margin-bottom: 16px;
  }
`;
export const PolicyDataContainer = styled.div.attrs({
  className: 'policyRow',
})`
  display: grid;
  word-break: break-all;
  @media (max-width: 599px) {
    row-gap: 16px;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    grid-template-columns: ${props =>
      props.policyDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-bottom: 24px;
    margin-top: 6px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: ${props =>
      props.policyDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 3%;
    margin-bottom: 24px;
    margin-top: 6px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: ${props =>
      props.policyDocumentUpload === true ? '1fr 1fr 1fr 1fr 1fr' : '1fr 1fr 1fr 1fr'};
    margin-left: 5%;
    margin-bottom: 24px;
    margin-top: 6px;
  }
`;

export const AddressSection = styled.div.attrs({})`
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  display: inline-block;
  border: 2px solid #444;
  padding: 15px;
  margin-right: 20px;
`;
AddressSection.displayName = 'AddressSection';

export const BoldHeading = styled.span.attrs({})`
  font-weight: bold;
`;
BoldHeading.displayName = 'BoldHeading';

export const AddressWrapper = styled.div.attrs({})``;
AddressWrapper.displayName = 'AddressWrapper';
export const UploadSpan = styled.span.attrs({
  id: 'uploadSpan',
})`
  margin-left: 8px;
`;
export const HorizontalLine = styled.hr.attrs({})`
  @media (max-width: 599px) {
    width: 100%;
    margin-bottom: 24px;
  }
  @media (min-width: 600px) {
    display: none;
  }
`;
