import styled from 'styled-components';
import Select from 'react-select';

export const ReportClaimsContainer = styled.div.attrs({
  id: 'ReportClaimsContainer',
})`
  margin: 30px;
  @media (max-width: 599px) {
    margin-left: 0;
    margin-right: 0;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const EmergencyMessageComponent = styled.div.attrs({ id: 'emergencyMessageComponent' })`
  background-color: #fcf4f4;
  color: black;
  border: 1px solid #c4221f;
  border-radius: 4px;
  padding: 9px 20px 12px 20px;
  margin-bottom: 24px;
`;
export const LossMessageComponent = styled.div.attrs({ id: 'lossMessageComponent' })`
  margin-bottom: 24px;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 16px;
  font-weight: 400;
`;
export const ReportClaimFormContainer = styled.div.attrs({
  id: 'ReportClaimFormContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    margin-bottom: 16px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-bottom: 16px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 100%;
    grid-template-rows: 1fr;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
export const ReportClaimsHeader = styled.h1.attrs({
  id: 'ReportClaimsHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 0;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ReportClaimsSubHeader = styled.h2.attrs({
  id: 'ReportClaimsSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 20px;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-block: 1em;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ReportClaimsSelectionHeader = styled.h3.attrs({
  id: 'ReportClaimsSubHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  width: 96.6%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    width: 98.6%;
  }
`;

export const ReportClaimsAddressTextInputField = styled.input.attrs({
  type: 'text',
})`
  height: 30px;
  margin-bottom: 10px;
  /* width: 98.6%; */
  @media (max-width: 599px) {
    /* width: 100%; */
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsTextAreaField = styled.textarea.attrs({
  id: 'ReportClaimsTextInputField',
})`
  height: 80px;
  margin-bottom: 10px;
  width: 99.6%;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;

export const ReportClaimsButtonsContainer = styled.div.attrs({
  id: 'ReportClaimsButtonsContainer',
})`
  text-align: right;
  @media (max-width: 599px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
`;

export const ReportClaimCancelButton = styled.button.attrs({
  id: 'ReportClaimsCancelButton',
})`
  margin-left: 10px;
  padding: 8px 39px;
  border: 0;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
`;
export const ReportClaimNextButton = styled.button.attrs({
  id: 'ReportClaimsNextButton',
})`
  margin-left: 10px;
  padding: 8px 47px;
  border: 0;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  @media (max-width: 380px) {
    margin-bottom: 16px;
  }
  @media (max-width: 599px) {
    margin-top: 10px;
  }
  :disabled {
    background-color: gray;
  }
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const ErrorMessage = styled.div`
  color: red;
  margin: 16px;
  font-size: 18px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    margin-top: 16px;
  }
`;
export const ReportClaimField = styled.div.attrs({})`
  min-width: 200px;
`;
export const ReportDateTimeContainer = styled.div.attrs({
  id: 'reportClaimTwoFormContainer',
})`
  display: grid;
  @media (max-width: 599px) {
    grid-template-columns: 1fr;
    margin-bottom: 15px;
  }
  @media (min-width: 600px) and (max-width: 699px) {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
  }
  @media (min-width: 700px) and (max-width: 1279px) {
    grid-template-columns: 1fr 1fr 2fr;
    grid-template-rows: 1fr;
    column-gap: 60px;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 8% 92%;
    grid-template-rows: 1fr;
    column-gap: 60px;
  }
`;
export const StyledHeader = styled.h3.attrs({
  id: 'reportClaimUnauthedHeader',
})`
  font-size: 1.5rem;
  margin-top: 15px !important;
  margin-bottom: 15px !important;
`;

export const ReportClaimStepTwoField = styled.div.attrs({})`
  min-width: 200px;
`;
export const ReportClaimsTitleHeader = styled.h4.attrs({
  id: 'reportClaimsTitleHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 0.9rem;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-bottom: 8px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const ReportClaimsAddressTitleHeader = styled(ReportClaimsTitleHeader).attrs({
  id: 'reportClaimsAddressTitleHeader',
})`
  margin-top: 0;
`;
export const ReportClaimsCountryTitleHeader = styled(ReportClaimsTitleHeader).attrs({
  id: 'reportClaimsCountryTitleHeader',
})`
  margin-top: 0;
`;
export const SelectLossEvent = styled(Select)`
  width: 64%;
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 959px) {
    width: 100%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 95%;
  }
  @media (min-width: 1280px) {
  }
  @media (min-width: 1800px) {
    width: 44%;
  }
`;
