import styled from 'styled-components';

export const PendingRequestsContainer = styled.div.attrs({
  id: 'idPendingRequestsContainer',
})`
  margin: 30px;
`;
export const PendingRequestsHeader = styled.h1.attrs({
  id: 'PendingRequestsHeader',
})`
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1.525rem;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeSpeed;
  margin-top: 0;
  margin-block: 1em;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 959px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const PendingRequestsRowHeaderContainer = styled.div`
  display: grid;
  word-break: break-word;
  margin-top: 10px;
  @media (max-width: 749px) {
    display: none;
  }
  @media (min-width: 750px) and (max-width: 959px) {
    grid-template-columns: 15% 33% 52%;
    grid-template-rows: 3fr 1fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 17% 33% 50%;
    grid-template-rows: 3fr 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 15% 33% 52%;
    grid-template-rows: 3fr 1fr;
  }
`;
export const PendingRequestsDataContainer = styled.div`
  display: grid;
  word-break: break-all;
  @media (max-width: 749px) {
    row-gap: 0px;
  }
  @media (min-width: 750px) and (max-width: 959px) {
    grid-template-columns: 15% 33% 52%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    grid-template-columns: 17% 33% 50%;
    grid-template-rows: 1fr;
  }
  @media (min-width: 1280px) {
    grid-template-columns: 15% 33% 52%;
    grid-template-rows: 1fr;
    margin-top: 5px;
    margin-bottom: 5px;
  }
`;
export const pendingRequestsRowHeaderInformationLabel = styled.h2.attrs({})`
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 700;
  margin: 0;
  @media (max-width: 749px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    max-width: 90%;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    max-width: 90%;
  }
  @media (min-width: 1280px) {
    max-width: 95%;
  }
`;
export const DateRowHeaderLabel = styled(pendingRequestsRowHeaderInformationLabel).attrs({
  id: 'idDateRowHeaderLabel',
})``;
export const DescriptionRowHeaderLabel = styled(pendingRequestsRowHeaderInformationLabel).attrs({
  id: 'idDescriptionRowHeaderLabel',
})``;
export const DetailsRowHeaderLabel = styled(pendingRequestsRowHeaderInformationLabel).attrs({
  id: 'idDetailsRowHeaderLabel',
})``;
export const MobileRowHeaderLabel = styled(pendingRequestsRowHeaderInformationLabel).attrs({
  id: 'idMobileRowHeaderLabel',
})`
  margin-top: 16px;
  @media (min-width: 749px) {
    display: none;
  }
`;
export const PendingRequestsDataRowContainer = styled.div.attrs({})``;

export const RequestNote = styled.div.attrs({
  id: 'requestDetails',
})`
  white-space: pre-wrap;
  word-break: break-word;
`;
export const PendingRequestEffectiveDate = styled.div.attrs({})`
  white-space: pre-wrap;
  word-break: break-word;
`;
export const PendingRequestDescription = styled.div.attrs({})`
  word-break: break-word;
  white-space: pre-wrap;
`;
export const PendingRequestsButtonsContainer = styled.div.attrs({})`
  text-align: center;
  margin-right: 2%;
  margin-top: 10%;
  @media (max-width: 384px) {
    text-align: center;
  }
`;
export const PendingRequestsButton = styled.button.attrs({})`
  padding: 8px 17px;
  border: 0;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  margin-bottom: 16px;
`;
export const HorizontalLine = styled.hr.attrs({})`
  margin-top: 30px;
`;
export const LoaderMargin = styled.div`
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    margin-top: 16px;
  }
`;

export const StyledNoRequests = styled.div`
  margin-top: 2%;
  font-size: 18px;
`;
