import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  customer: {},
  selfServiceConfigurations: {
    selfServiceFeature: {
      viewIdCard: null,
    },
    selfServiceItem: [],
  },
  errors: null,
};

export const customerSlice = createSlice({
  name: 'customerSlice',
  initialState,
  reducers: {
    setCustomer: (state, action) => {
      return { ...state, customer: action.payload };
    },
    setSelfServiceConfigurations: (state, action) => {
      return { ...state, selfServiceConfigurations: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
  },
});
export const {
  setCustomer,
  setSelfServiceConfigurations,
  setLoading,
  setErrors,
} = customerSlice.actions;
export default customerSlice.reducer;
