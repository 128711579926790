import * as React from 'react';
import DashboardTemplate1 from './template1/index';
import DashboardTemplate2 from './template2/index';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';

import { connect } from 'react-redux';
import Loader from '../../common/loader';
import { Redirect } from 'react-router';
import { setRegistrationToken } from '../login/loginReducer';
import { ISO_DATE_FORMAT } from '../../common/constants';
import moment from 'moment';
import { getNewsBanners } from '../news-banners/newsBannersApi';
import { setupPendo, updatePendoConfig } from '../../../utils/pendo';

export const Dashboard = props => {
  const {
    template,
    isLoading,
    isServiceUnavailable,
    dispatch,
    billingSectionLoading,
    billingErrors,
    user,
    features,
  } = props;

  React.useEffect(() => {
    // clear registrationToken, so create account will ask for validation fields
    dispatch(setRegistrationToken(''));

    const todayDt = moment().format(ISO_DATE_FORMAT);
    dispatch(getNewsBanners(todayDt));
  }, []);

  React.useEffect(() => {
    if (features.companyName) {
      updatePendoConfig(features.companyName, user.preferred_username);
    }
  }, [features, user]);

  return (
    <>
      <Helmet>
        <title>Dashboard</title>
      </Helmet>
      {isServiceUnavailable ? (
        <Redirect to="/error" />
      ) : isLoading ? (
        <Loader />
      ) : (
        <>
          {!billingSectionLoading && !billingErrors && (
            <a id="skip-nav" className="screenreader-text" href="#skipContent">
              Skip to Content
            </a>
          )}
          {template === 'template1' ? <DashboardTemplate1 /> : <DashboardTemplate2 />}
        </>
      )}
    </>
  );
};

Dashboard.propTypes = {
  template: PropTypes.string.isRequired,
  isLoading: PropTypes.bool,
  billingErrors: PropTypes.any,
  billingSectionLoading: PropTypes.bool,
  isServiceUnavailable: PropTypes.bool,
  dispatch: PropTypes.func,
  user: PropTypes.any,
  features: PropTypes.any,
};
const mapStateToProps = state => ({
  state,
  template: state.configurationSlice.features.template,
  isLoading: state.configurationSlice.isLoading,
  billingErrors: state.billingSlice.errors,
  billingSectionLoading: state.billingSlice.isLoading,
  isServiceUnavailable: state.configurationSlice.isServiceUnavailable,
  user: state.loginSlice.user,
  features: state.configurationSlice.features,
});

export default connect(mapStateToProps)(Dashboard);
