import styled from 'styled-components';
import { Link } from 'react-router-dom';

export const ForgotUsernameStepSubContainer = styled.div.attrs({
  id: 'ForgotUsernameStepOneContainer',
})`
  margin: 2% auto 10px;
  padding: 15px;
  border: 2px solid ${props => props.theme.gray};
  max-width: 450px;
`;
export const ForgotUsernameHeader = styled.label.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-weight: 700;
`;
export const ForgotUsernameMessageLabel = styled.div.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-size: 14px;
  margin-top: 12px;
  margin-bottom: 10px;
`;
export const ForgotUsernameDisclaimerLabel = styled.div.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-size: 12px;
  margin-top: 16px;
`;
export const RequiredField = styled.span.attrs({})`
  color: red;
`;
export const LogoLink = styled(Link)`
  grid-area: logo;
`;
export const FooterContainer = styled.div`
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  text-align: center;
`;
export const ChooseNotificationMethod = styled.div`
  display: flex;
  height: 40px;
  margin-bottom: 24px;
  margin-top: 24px;
`;
export const ViaTextButton = styled.button.attrs({
  type: 'button',
})`
  display: flex;
  flex: 1;
  border-radius: 6px 0 0 6px;
  justify-content: center;
  align-items: center;
  ${({ isActive, activeBackgroundColor, activeColor }) =>
    isActive &&
    `
      background-color: ${activeBackgroundColor};
      border-color: ${activeBackgroundColor};
      color: ${activeColor};
    `};
`;
export const ViaEmailButton = styled(ViaTextButton)`
  border-radius: 0 6px 6px 0;
`;
export const FieldContainer = styled.div.attrs({
  className: 'FieldContainer',
})`
  display: grid;
  grid-template-columns: 100%;
`;
export const FieldHeader = styled.label.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  font-weight: 500;
  margin-top: 15px;
`;
export const TextInputField = styled.input.attrs(props => ({
  id: props.id ? props.id : 'missing id',
  type: 'text',
}))`
  width: 98%;
  height: 28px;
  margin-top: 5px;
`;
export const ButtonContainer = styled.div.attrs({
  id: 'ButtonContainer',
})`
  text-align: center;
  margin-top: 8px;
`;
export const RetrieveUsernameButton = styled.button.attrs(props => ({
  id: props.id ? props.id : 'missing id',
}))`
  text-align: center;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  width: -webkit-fill-available;
  padding: 5px 100px;
  margin-top: 8px;
  :disabled {
    background-color: gray;
  }
`;
export const ReturnToLoginPageButton = styled(Link).attrs({
  id: 'returnToLoginPageButton',
})`
  width: 100%;
  font-size: 14px;
`;
export const ReturnToLoginPageButtonContainer = styled.div.attrs({
  id: 'returnToLoginPageButtonContainer',
})`
  margin-top: 0;
  text-align: center;
  display: block;
`;
