import { API } from '../../../utils/api/index';
import {
  setEditingEmail,
  setEditingPhone,
  setEmailErrors,
  setLoading,
  setPhoneErrors,
  setFormErrors,
} from '../user-profile/profileReducer';
import { setCustomer } from '../customer/customerReducer';

export const patchCustomerDetails = (customerId, body) => {
  return async (dispatch) => {
    dispatch(setLoading(true));
    await API.patch('customers/' + customerId, body)
      .then((response) => {
        dispatch(setCustomer(response.data));
        dispatch(setEditingEmail(false));
        dispatch(setEditingPhone(false));
        dispatch(setEmailErrors(null));
        dispatch(setPhoneErrors(null));
      })
      .catch((err) => {
        dispatch(setFormErrors(err.response?.data?.message));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
