import React, { useState } from 'react';
import moment from 'moment';
import { ISO_DATE_FORMAT } from '../../../../common/constants';
import { getAdditionalQuestions } from '../selector';
import {
  ChangeContactInformationButton,
  ChangeContactInformationButtonsContainer,
  ChangeContactInformationContainer,
  ChangeContactInformationTextAreaField,
  RequiredField,
  TwoColumnContainer,
} from './styles';
import {
  RequestedEffectiveDateField,
  RequestedEffectiveDateHeader,
} from '../change-billing-mailing-address/styles';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Spacer, StyledLoaderContainer } from '../../styles';
import Loader from '../../../../common/loader';
import { useHistory } from 'react-router-dom';
import { setPolicyChangeCoverageRecapFields } from '../../policyChangeReducer';
import { submitPolicyChange } from '../../policyChangeAPI';
import {
  checkAdditionalAnswersComplete,
  createPolicyChangeCoverageSummary,
  getQuestionReplies,
} from './selector';
import AdditionQuestionField from './additional-question-field';

export const ChangeContactInformation = props => {
  const { i18n, selectedPolicyChangeTemplate, customerId, selectedPolicy } = props;

  const todayDt = moment().format(ISO_DATE_FORMAT);
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [reason, setReason] = useState('');
  const [additionalQuestions] = useState(getAdditionalQuestions(selectedPolicyChangeTemplate));
  const [answers, setAnswers] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);

  const setAdditionalQuestionsAnswers = (index, answer) => {
    const theAnswer = {};
    theAnswer[index] = { answer };
    setAnswers(prevAnswers => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const setAdditionalSubQuestionsAnswers = (parentIndex, index, answer) => {
    const subQuestionAnswers = [];
    subQuestionAnswers[index] = answer;

    const theAnswer = {};
    theAnswer[parentIndex] = {
      answer: answers[parentIndex].answer,
      subQuestionAnswers,
    };
    setAnswers(prevAnswers => {
      return { ...prevAnswers, ...theAnswer };
    });
  };

  const history = useHistory();

  const checkRequiredFieldsCompleted = () => {
    // check effective date and reason
    let requiredFieldsCompleted = effectiveDate.length > 0 && reason.trim().length > 0;

    return !!requiredFieldsCompleted;
  };

  const createPayload = () => {
    return {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
        },
      ],
      policyChangeSection: selectedPolicyChangeTemplate.policyChangeSections,
      questionReplies: [{ questionReply: getQuestionReplies(additionalQuestions, answers) }],
      sourceCd: 'ServicePortal',
    };
  };

  const onSubmitButtonClick = () => {
    // set fields for recap/result
    props.dispatch(
      setPolicyChangeCoverageRecapFields(
        createPolicyChangeCoverageSummary(
          effectiveDate,
          reason,
          selectedPolicyChangeTemplate.name,
          additionalQuestions,
          answers,
        ),
      ),
    );

    setIsSubmitting(true);

    // submit API call
    const payload = createPayload();
    props.dispatch(submitPolicyChange(payload));
  };

  return (
    <>
      <ChangeContactInformationContainer>
        <RequestedEffectiveDateHeader>
          Requested Effective Date<RequiredField>*</RequiredField>
          <TooltipInfo
            title={getText(i18n, 'changeCoverage.changeContactInformation.effectiveDateTooltip')}
          />
        </RequestedEffectiveDateHeader>
        <RequestedEffectiveDateField>
          <input
            type="date"
            aria-label="Requested Effective Date"
            value={effectiveDate}
            min={todayDt}
            onChange={e => {
              setEffectiveDate(e.target.value);
            }}
          />
        </RequestedEffectiveDateField>
        <RequestedEffectiveDateHeader>
          Reason<RequiredField>*</RequiredField>
          <TooltipInfo
            title={getText(i18n, 'changeCoverage.changeContactInformation.reasonTooltip')}
          />
        </RequestedEffectiveDateHeader>
        <RequestedEffectiveDateField>
          <ChangeContactInformationTextAreaField
            id="reason"
            value={reason}
            onChange={e => {
              setReason(e.target.value);
            }}
          />
        </RequestedEffectiveDateField>

        <TwoColumnContainer>
          {additionalQuestions.map((q, index) => {
            return (
              <AdditionQuestionField
                id={q.text}
                key={index}
                q={q}
                index={index}
                setAdditionalQuestionsAnswers={setAdditionalQuestionsAnswers}
                setAdditionalSubQuestionsAnswers={setAdditionalSubQuestionsAnswers}
              />
            );
          })}
        </TwoColumnContainer>
        <ChangeContactInformationButtonsContainer>
          <ChangeContactInformationButton
            id="Cancel"
            onClick={() => {
              history.push('/dashboard');
            }}
          >
            Cancel
          </ChangeContactInformationButton>
          <ChangeContactInformationButton
            disabled={
              !checkAdditionalAnswersComplete(
                additionalQuestions,
                answers,
                setAdditionalQuestionsAnswers,
                setAdditionalSubQuestionsAnswers,
              ) ||
              !checkRequiredFieldsCompleted() ||
              isSubmitting
            }
            onClick={() => onSubmitButtonClick()}
          >
            Submit
          </ChangeContactInformationButton>
          {isSubmitting ? (
            <StyledLoaderContainer>
              <Loader loaderheight="20px" loaderwidth="20px" />
            </StyledLoaderContainer>
          ) : (
            <Spacer />
          )}
        </ChangeContactInformationButtonsContainer>
      </ChangeContactInformationContainer>
    </>
  );
};

ChangeContactInformation.propTypes = {
  dispatch: PropTypes.func,
  selectedPolicyChangeTemplate: PropTypes.object,
  customerId: PropTypes.string,
  policyChangeCoverageRecapFields: PropTypes.array,
  policies: PropTypes.array,
  selectedPolicy: PropTypes.any,
  isLoading: PropTypes.bool,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  policyChangeCoverageRecapFields: state.policyChangeSlice.policyChangeCoverageRecapFields,
  policies: state.policySlice.policies,
  selectedPolicy: state.policyChangeSlice.selectedPolicy.label,
  isLoading: state.policyChangeSlice.isLoading,
  i18n: state.i18nSlice.i18n,
});

export default connect(mapStateToProps)(ChangeContactInformation);
