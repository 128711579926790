import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  policyDocuments: [],
  policyAttachments: [],
  forms: [],
  policyDetails: {
    policyDetailsHtml: '',
    propertyDetailHtml: '',
    vehiclesDetailHtml: '',
    driversDetailHtml: '',
  },
  isLoading: false,
  isDetailsLoading: false,
  errors: null,
  policyPreference: '',
};

export const policyDetailsSlice = createSlice({
  name: 'policyDetailsSlice',
  initialState,
  reducers: {
    setPolicyDocuments: (state, action) => {
      return { ...state, policyDocuments: action.payload };
    },
    setForms: (state, action) => {
      return { ...state, forms: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setDetailsLoading: (state, action) => {
      return { ...state, isDetailsLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setPolicyDetails: (state, action) => {
      return { ...state, policyDetails: action.payload };
    },
    setPolicyPreference: (state, action) => {
      return { ...state, policyPreference: action.payload };
    },
    setPolicyAttachments: (state, action) => {
      return { ...state, policyAttachments: action.payload };
    },
  },
});
export const {
  setDetailsLoading,
  setErrors,
  setLoading,
  setPolicyDetails,
  setPolicyDocuments,
  setPolicyPreference,
  setForms,
  setPolicyAttachments,
} = policyDetailsSlice.actions;
export default policyDetailsSlice.reducer;
