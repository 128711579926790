import axios from 'axios';
import { setServiceUnavailable } from '../../components/common/configuration/configurationReducer';

let serviceTestUrl = undefined;
let retryAttempted = false;
let dispatcher = undefined;

export const setServiceTestUrl = _serviceTestUrl => {
  serviceTestUrl = _serviceTestUrl;
};

export const setDispatcher = _dispatcher => {
  dispatcher = _dispatcher;
};

const getCodeFromError = error => {
  const length = error.toString().length;
  return error.toString().slice(length - 3, length);
};
let baseUrl = '';
export let API;
export let MULTI_PART_API;
export let ANONYMOUS_API;
export let TOKENIZED_API = undefined;

export const getBaseUrl = () => {
  return baseUrl;
};

export const updateTokenizedApi = jwtToken => {
  TOKENIZED_API = axios.create({
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${jwtToken}`,
    },
    baseURL: getBaseUrl() + '/coreapi/v5/',
  });
};

export const refreshToken = () => {
  return ANONYMOUS_API.post('clients/serviceportal/sessions').then(response => {
    const jwtToken = response.data.jwtToken;
    updateTokenizedApi(jwtToken);
  });
};

const apiAuthConfig = config => {
  const tokenStorage = localStorage.getItem('okta-token-storage');
  let token = '';
  if (tokenStorage) {
    const tokens = JSON.parse(tokenStorage);
    if (Object.keys(tokens).length !== 0) {
      token = tokens.accessToken.accessToken;
    }
  }
  config.headers.Authorization = token ? `Bearer ${token}` : '';
  return config;
};

export const setBaseUrl = _baseUrl => {
  if (baseUrl === undefined || baseUrl.trim() === '') {
    baseUrl = _baseUrl;

    API = axios.create({
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      baseURL: getBaseUrl() + '/coreapi/v5/',
    });

    MULTI_PART_API = axios.create({
      headers: {
        'Content-Type': 'multipart/form-data',
        Accept: ' text/html',
      },
      baseURL: getBaseUrl(),
    });

    MULTI_PART_API.interceptors.request.use(function(config) {
      return apiAuthConfig(config);
    });

    ANONYMOUS_API = axios.create({
      headers: {
        'Content-Type': 'application/json;charset=UTF-8',
      },
      baseURL: getBaseUrl() + '/coreapi/v5/',
    });

    /**
     * TODO This may not be the optimized solution. A better solution is to retrieve authState via useOktaAuth hook.
     * However, the hook can only be used in component. We need a less-coupled way for integration, and then switch to the solution later.
     */
    API.interceptors.request.use(function(config) {
      return apiAuthConfig(config);
    });

    API.interceptors.response.use(
      response => response,
      error => {
        if (getCodeFromError(error) === '503') {
          if (!retryAttempted) {
            // retry
            if (serviceTestUrl) {
              retryAttempted = true;
              API.get(serviceTestUrl).then(
                () => {},
                error => {
                  if (getCodeFromError(error) === '503') {
                    // enter system down mode
                    dispatcher(setServiceUnavailable(true));
                  }
                },
              );
            }
          }
        }
        return Promise.reject(error);
      },
    );
  }
};

export const getQueryParams = (params, url) => {
  const href = url;
  const reg = new RegExp(`[?&]${params}=([^&#]*)`, 'i');
  const queryString = reg.exec(href);
  return queryString ? queryString[1] : null;
};
