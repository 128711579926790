import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';

export const getAddress = (riskId, buildingRisks) => {
  for (let i = 0; i < buildingRisks.length; i++) {
    const risk = buildingRisks[i];
    if (riskId === risk.id) {
      return `${risk.addr1}, ${risk.addr2 !== undefined ? riskId.addr2 : ''} ${risk.city}, ${
        risk.stateProvCd
      } ${risk.postalCode}`;
    }
  }
  return '';
};

export const createField = (label, value, type, showFieldOnPremiumPreview = true) => {
  return {
    label,
    value,
    type,
    showFieldOnPremiumPreview,
  };
};

export const getMortgageeById = (id, mortgageeList) => {
  for (let i = 0; i < mortgageeList.length; i++) {
    const mortgagee = mortgageeList[i];
    if (mortgagee.id === id) {
      return mortgagee;
    }
  }
  return null;
};

export const getVehicleById = (id, vehicleList) => {
  for (let i = 0; i < vehicleList.length; i++) {
    const vehicle = vehicleList[i];
    if (vehicle.id === id) {
      return vehicle.description;
    }
  }
  return null;
};

export const getVehicleFinanceCompanyById = (id, companyList) => {
  for (let i = 0; i < companyList.length; i++) {
    const company = companyList[i];
    if (company.id === id) {
      return company;
    }
  }
  return null;
};

export const getDeductibles = constraints => {
  try {
    return constraints
      .replace(/\\,/g, '@')
      .split(',')
      .map(x => {
        const deductible = x.replace(/@/g, ',');
        return { label: deductible, value: deductible };
      });
  } catch (e) {
    return [];
  }
};

// On the add vehicle page, we use the following questions
// TODO remove QUESTION_NAMES variable if not needed
// const QUESTION_NAMES = ['Task.Note004', 'Task.Note005', 'Task.Note006', 'Task.Note007'];
export const getAdditionalQuestions = template => {
  try {
    return template.policyChangeSections[0].questions[0].question;
  } catch (e) {
    return [];
  }
};

export const getQuestionReplies = (questions, answers) => {
  const replies = [];
  for (let i = 0; i < questions.length; i++) {
    const question = questions[i];
    const answer = answers[i].answer;
    let value;
    if (question.dataType === 'Numeric') {
      value = answer.toString();
    } else {
      value = answer;
    }
    replies.push({ name: question.name, value, visibleInd: true });
    // sub questions
    if (
      question.questions &&
      question.questions.question &&
      question.questions.question.length > 0 &&
      answers[i].subQuestionAnswers
    ) {
      for (let j = 0; j < question.questions.question.length; j++) {
        const subQuestion = question.questions.question[j];
        const subAnswer = answers[i].subQuestionAnswers[j];
        let subValue;
        if (subQuestion.dataType === 'YesNo') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'List') {
          subValue = subAnswer;
        } else if (subQuestion.dataType === 'Numeric') {
          subValue = subAnswer.toString();
        } else if (subQuestion.dataType === 'TextArea') {
          subValue = subAnswer;
        }
        replies.push({ name: subQuestion.name, value: subValue, visibleInd: true });
      }
    }
  }
  return replies;
};

export const formatRecapFieldDate = date => {
  return moment(date).format(DISPLAY_DATE_FORMAT);
};
