import styled from 'styled-components';

export const ReportClaimButtonContainer = styled.div.attrs({
  id: 'ReportClaimButtonContainer',
})`
  display: block;
  margin-top: 40px;
`;
export const ReportClaimNextButton = styled.button.attrs({
  id: 'ReportClaimNextButton',
})`
  text-align: center;
  background-color: ${props => props.theme.primaryColor};
  color: white;
  padding: 8px 45px;
  border: 1px solid ${props => props.theme.primaryColor};
  float: right;
  :disabled {
    background-color: gray;
  }
`;

export const ReportClaimPreviousButton = styled.button.attrs({
  id: 'reportClaimBackButton',
})`
  margin-right: 10px;
  padding: 9px 45px;
  color: ${props => props.theme.primaryColor};
  float: right;
  border: 1px solid ${props => props.theme.primaryColor};
  background-color: white;
  cursor: pointer;
  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
    box-shadow: 3px 3px 6px gray;
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
    box-shadow: initial;
  }
  @media (max-width: 599px) {
  }
`;

export const ReportClaimCancelButton = styled.button.attrs({
  id: 'reportClaimCancelButton',
})`
  background-color: initial;
  color: ${props => props.theme.primaryColor};
  box-shadow: none;
  cursor: pointer;
  margin-top: 8px;
  padding: initial;

  :hover {
    background-color: initial;
    color: ${props => props.theme.primaryHoverColor};
  }
  :active {
    background-color: initial;
    color: ${props => props.theme.primaryActiveColor};
  }
`;
