import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentMFA: {
    factorId: null,
    factorType: null,
  },
  emailAddress: '',
  factorId: '',
  mfaEnabled: false,
  mfaOptions: [],
  modalOpen: false,
  phoneNumber: '',
};

export const mfaSlice = createSlice({
  name: 'mfaSlice',
  initialState,
  reducers: {
    setEmailAddress: (state, action) => {
      return { ...state, emailAddress: action.payload };
    },
    setCurrentMFA: (state, action) => {
      return { ...state, currentMFA: action.payload };
    },
    setFactorId: (state, action) => {
      return { ...state, factorId: action.payload };
    },
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setModalOpen: (state, action) => {
      return { ...state, modalOpen: action.payload };
    },
    setMFAEnabled: (state, action) => {
      return { ...state, mfaEnabled: action.payload };
    },
    setMFAOptions: (state, action) => {
      return { ...state, mfaOptions: action.payload };
    },
    setPhoneNumber: (state, action) => {
      return { ...state, phoneNumber: action.payload };
    },
  },
});
export const {
  setCurrentMFA,
  setEmailAddress,
  setFactorId,
  setLoading,
  setMFAEnabled,
  setMFAOptions,
  setModalOpen,
  setPhoneNumber,
} = mfaSlice.actions;
export default mfaSlice.reducer;
