import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isLoading: false,
  errors: null,
  agents: [],
};

export const agentSlice = createSlice({
  name: 'agentSlice',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      return { ...state, isLoading: action.payload };
    },
    setErrors: (state, action) => {
      return { ...state, errors: action.payload };
    },
    setAgents: (state, action) => {
      return { ...state, agents: action.payload };
    },
  },
});
export const { setAgents, setLoading, setErrors } = agentSlice.actions;
export default agentSlice.reducer;
