import { API } from '../../../utils/api';
import {
  setAvailableProduct,
  setAvailableProductError,
  setAvailableProductLoading,
  setErrors,
  setLoading,
  setOpenQuotes,
} from './openQuotesReducer';

export const getOpenQuotes = customerId => {
  return dispatch => {
    dispatch(setErrors(null));
    dispatch(setLoading(true));
    return API.get(`/applications?customerId=${customerId}`)
      .then(response => {
        dispatch(
          setOpenQuotes(
            response.data.applicationListItems &&
              response.data.applicationListItems.filter(
                quote => quote.applicationMini.status !== 'Rejected',
              ),
          ),
        );
      })
      .catch(e => {
        dispatch(setErrors(e));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const completeQuote = (id, container, salesPortalUrl) => {
  return dispatch => {
    return API.post('/clients/servicePortal/portalLinkRequest', { id, container })
      .then(response => {
        window.open(`${salesPortalUrl}/referral?token=${response.data.resourceToken}`);
      })
      .catch(() => {
        // console.log(err);
        dispatch(setErrors('unable to start quote right now'));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const checkProductAvailability = (zipCode, selectedProduct) => {
  return dispatch => {
    dispatch(setAvailableProductError(null));
    dispatch(setAvailableProductLoading(true));
    return API.get(`/products?postalCode=${zipCode}`)
      .then(response => {
        const matchedProduct = response.data.productListItems.filter(product => {
          return product.productName === selectedProduct;
        })[0];
        dispatch(setAvailableProduct(matchedProduct || { noProducts: true }));
      })
      .catch(e => {
        if (((e.response || {}).data || {}).message === 'Invalid PostalCode') {
          dispatch(setAvailableProduct({ noProducts: true }));
        }
        dispatch(setAvailableProductError('There was an error trying to create your quote.'));
      })
      .finally(() => dispatch(setAvailableProductLoading(false)));
  };
};

export const startQuote = (id, salesPortalUrl, zip, availableProduct) => {
  return dispatch => {
    return API.post('/clients/servicePortal/portalLinkRequest', { id, container: 'Customer' })
      .then(response => {
        const url = `${salesPortalUrl}/referral?postalCode=${zip}&newQuote=true&carrierGroupId=${availableProduct.carrierGroupId}&licenseClass=${availableProduct.licenseClass}&token=${response.data.resourceToken}`;
        window.open(url, '_blank');
      })
      .catch(() => {
        // console.log(err);
        dispatch(setErrors('unable to start quote right now'));
      })
      .finally(() => dispatch(setLoading(false)));
  };
};
