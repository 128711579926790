import { API } from '../../../../utils/api';
import {
  setClaimSubmit,
  setClaimReferenceNumber,
  setIsSubmitted,
} from './reportClaimStepFiveReducer';
export const submitClaim = body => {
  return dispatch => {
    dispatch(setIsSubmitted(true));
    return API.post('/claimEvents', body)
      .then(response => {
        let reference = response.headers.location.indexOf('claims');
        reference = reference + 7; //index of the claim number
        let claimReferenceNumber = response.headers.location.slice(reference); //slice the number off for getting loss call
        API.get(`/claims/${claimReferenceNumber}/consumerSummary`)
          .then(response => {
            dispatch(setClaimReferenceNumber(response.data.claimNumber));
            dispatch(setClaimSubmit('Success'));
          })
          .catch(err => {
            console.log(err);
          });
      })
      .catch(() => {
        dispatch(setClaimSubmit('Failure'));
      });
  };
};
