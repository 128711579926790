import styled from 'styled-components';
import InputMask from 'react-input-mask';
import InfoIcon from '@mui/icons-material/Info';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';

export const StyledModal = styled.div`
  padding: 16px 24px;
  @media (max-width: 599px) {
    width: calc(100% - 64px);
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 361px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 361px;
  }
  @media (min-width: 1280px) {
    width: 361px;
  }
  min-height: 260px;
`;
export const StyledACHContainer = styled.div`
  margin: 45px 0 45px;
`;
export const StyledACHInputContainer = styled.div`
  margin: 24px 0 24px;
`;
export const PaymentContainer = styled.div`
  margin: 30px;
`;
export const PaymentHeader = styled.h1`
  font-size: 20px;
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;

export const SubHeader = styled.h2`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;
export const DateContainer = styled.div`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    grid-template-columns: 32% 32% 32%;
    column-gap: 24px;
  }
`;
export const AmountContainer = styled.div`
  display: grid;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
    grid-template-columns: 32% 32% 32%;
    column-gap: 24px;
  }
`;
export const AmountCards = styled.div`
  margin-bottom: 24px;
  height: 144px;
  width: 100%;
  border-radius: 4px;
  background-color: #fafafa;
`;
export const DateChoice = styled.input.attrs({
  type: 'date',
  'aria-label': 'Payment Date',
})`
  display: block;
  margin-top: 8px;
`;
export const FullAmountCard = styled(AmountCards)``;
export const MinimumAmountCard = styled(AmountCards)``;
export const OtherAmountCard = styled(AmountCards)``;
export const RadioChoice = styled.input.attrs({
  type: 'radio',
})`
  margin-left: 16px;
  margin-top: 16px;
  height: 24px;
  width: 24px;
`;
export const AmountHeader = styled.div`
  font-size: 16px;
  color: #28333f;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
  color: #28333f;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
  color: #28333f;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  font-size: 16px;
  color: #28333f;
  margin-left: 8px;
  margin-bottom: 0;
  font-weight: 400;
  display: inline-block;
  /* font-family: "Source Sans Pro"; */
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;

  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;

  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;
`;
export const PayDateHeader = styled.div.attrs({
  id: 'PayDateHeader',
})`
  font-size: 16px;
  color: #28333f;
  margin-left: 16px;
  margin-top: 16px;
  font-weight: 400;
  display: inline-block;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  letter-spacing: 0;
  vertical-align: super;
`;
export const AmountText = styled.div`
  font-size: 32px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  letter-spacing: 0;
  color: #28333f;
  display: block;
  margin-left: 48px;
  margin-top: 8px;
`;

export const PartialAmountInput = styled.input.attrs({
  id: 'partialAmountInput',
  placeholder: '$0.00',
})`
  display: block;
  margin-left: 48px;
  width: 121px;
  height: 40px;
`;

export const InformationArea = styled.div.attrs({
  id: 'amountInformation',
})`
  margin-left: 48px;
  margin-top: 16px;
  font-size: 14px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const StyledInfoIcon = styled(InfoIcon).attrs({
  className: 'fa fa-lg fa-info-circle',
})`
  margin-right: 6px;
  position: relative;
  top: 6px;
`;

export const PaymentTypeContainer = styled.div.attrs({})`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;

export const PaymentTypeHeader = styled.div.attrs({})`
  margin-top: 24px;
  margin-bottom: 24px;
  font-size: 20px;
  font-weight: 500;
`;
export const PaymentCards = styled.div`
  margin-bottom: 24px;
  height: 56px;
  display: block;
  width: 350px;
  border-radius: 4px;
  background-color: #fafafa;
  :hover {
    background-color: #fafafa;
  }
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
  }
  @media (min-width: 900px) and (max-width: 1279px) {
  }
  @media (min-width: 1280px) {
  }
`;
export const CreditCard = styled(PaymentCards)`
  font-size: 16px;
`;
export const ACHCard = styled(PaymentCards)`
  font-size: 16px;
`;
export const AddNewCard = styled.button.attrs({
  id: 'addNewCardButton',
})`
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  display: block;
  margin-top: 0;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    background-color: white;
  }
  box-shadow: 0 1px 4px 0 rgba(40, 51, 63, 0.24);
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 290px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 290px;
  }
  @media (min-width: 1280px) {
    width: 290px;
  }
`;
export const AddNewPaymentType = styled.button.attrs({
  id: 'addNewPaymentTypeButton',
})`
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  display: block;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    background-color: white;
  }
  box-shadow: 0 1px 4px 0 rgba(40, 51, 63, 0.24);
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 290px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 290px;
  }
  @media (min-width: 1280px) {
    width: 290px;
  }
`;
export const AddNewBankAccount = styled.button.attrs({
  id: 'addNewBankAccountButton',
})`
  border: 2px solid ${(props) => props.theme.primaryColor};
  color: ${(props) => props.theme.primaryColor};
  border-radius: 4px;
  background-color: white;
  cursor: pointer;
  height: 40px;
  display: block;
  margin-top: 16px;
  :hover {
    color: ${(props) => props.theme.primaryColor};
    background-color: white;
  }
  box-shadow: 0 1px 4px 0 rgba(40, 51, 63, 0.24);
  @media (max-width: 599px) {
    width: 100%;
  }
  @media (min-width: 600px) and (max-width: 979px) {
    width: 290px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    width: 290px;
  }
  @media (min-width: 1280px) {
    width: 290px;
  }
`;

export const StyledCreditIcon = styled(CreditCardIcon).attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  margin-left: 8px;
  position: relative;
`;
export const StyledModalCreditIcon = styled(CreditCardIcon).attrs({
  className: 'fa fa-lg fa-credit-card',
})`
  position: relative;
  top: 3px;
`;
export const StyledDeleteIcon = styled(DeleteIcon).attrs({
  className: 'fa fa-lg fa-trash',
})`
  cursor: pointer;
  top: 16px;
  right: 22px;
  position: relative;
  float: right;
`;
export const CreditInfo = styled.span.attrs({
  id: 'creditCardInfo',
  className: 'creditInfoSpan',
})`
  margin-left: 8px;
  bottom: 5px;
  font-weight: 400;
  position: relative;
`;

export const StyledACHIcon = styled(AccountBalanceIcon).attrs({
  className: 'fa fa-lg fa-university',
})`
  margin-left: 8px;
  position: relative;
`;
export const StyledModalACHIcon = styled(AccountBalanceIcon).attrs({
  className: 'fa fa-lg fa-university',
})`
  margin-left: 18px;
  position: relative;
  top: 3px;
`;

export const ACHInfo = styled.span.attrs({
  className: 'creditInfoSpan',
})`
  margin-left: 8px;
  bottom: 5px;
  font-weight: 400;
  position: relative;
`;
export const StyledAddIcon = styled(AddIcon).attrs({
  className: 'fa fa-lg fa-plus',
})`
  position: relative;
  top: 3px;
  color: ${(props) => props.theme.primaryColor};
  @media (max-width: 599px) {
    margin-left: 3px;
  }
  /* :hover {
    backgroundcolor: white;
  } */
`;
export const AddPaymentInfo = styled.span.attrs({
  className: 'addPaymentInfoSpan',
})`
  font-size: 16px;
  position: relative;
  bottom: 3px;
  margin-left: 8px;
  font-weight: 400;
  color: ${(props) => props.theme.primaryColor};
  /* text-decoration: none; */
`;
export const StyledDeleteButton = styled.button.attrs({
  id: 'deleteCardYesButton',
})`
  background-color: red;
  color: white;
  border: none;
  padding: 6px 55px 6px 55px;
  margin-right: 14px;
`;
export const StyledButtonNextDeleteCard = styled.button.attrs({
  id: 'deleteCardNoButton',
})`
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  border: none;
  padding: 6px 55px 6px 55px;
`;
export const StyledTextSpan = styled.span.attrs({})`
  margin-left: 16px;
  position: relative;
  bottom: 3px;
  font-size: 18px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
  text-decoration: none;
`;

export const StyledRoutingNumber = styled(InputMask).attrs({
  id: 'RoutingNumber',
})`
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 28px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;
export const StyledAccountNumber = styled(InputMask).attrs({
  id: 'AccountNumber',
})`
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 28px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;

export const StyledNameOnAccount = styled.input`
  display: block;
  margin-top: 8px;
  width: 100%;
  height: 28px;
  @media (max-width: 599px) {
    width: 80%;
  }
`;
export const StyledAddACH = styled.button.attrs({
  id: 'addAccountButton',
})`
  margin: 8px 0 8px;
  background-color: ${(props) => props.theme.primaryColor};
  color: white;
  padding: 8px 24px;
`;

export const StyledHorizontalLine = styled.div`
  background-color: #7d91ac;
  height: 1px;
  @media (max-width: 599px) {
  }
  @media (min-width: 600px) and (max-width: 979px) {
    margin-right: 24px;
    margin-left: 24px;
  }
  @media (min-width: 900px) and (max-width: 1279px) {
    margin-right: 24px;
    margin-left: 24px;
  }
  @media (min-width: 1280px) {
    margin-right: 24px;
    margin-left: 24px;
  }
`;
export const StyledACHTitle = styled.h3.attrs({
  id: 'bankAccountDetailsHeader',
})`
  font-size: 20px;
  font-family: 'Roboto', proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 500;
`;
export const StyledLabel = styled.label`
  font-size: 16px;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const StyledDialogSpan = styled.span`
  padding: 16px 24px;
  color: gray;
  font-size: 18px;
`;
export const StyledDialogActions = styled.div`
  flex: 0 0 auto;
  display: flex;
  padding: 14px;
  align-items: center;
  justify-content: flex-end;
`;

export const AmountDisclaimerContainer = styled.div.attrs({})`
  margin-bottom: 20px;
`;
export const AmountDisclaimerText = styled.label`
  font-weight: 500;
  font-family: proxima-nova, Lato, 'Helvetica Neue', Helvetica, Arial, sans-serif;
`;
export const InstructionsContainer = styled.div.attrs({
  id: 'instructionsContainer',
})`
  margin: 16px 0;
  font-size: initial;
`;
