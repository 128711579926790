const isValidPayment = account => {
  return account.billingAccount.openAmt > 0 || account.billingAccount.totalAmt > 0;
};
export const getAccountsWithABill = billingAccounts => {
  if (!billingAccounts) {
    return [];
  }
  return billingAccounts
    .filter(account => {
      if (isValidPayment(account)) {
        return {
          value: account.billingAccount.id,
          label: account.billingAccount.accountDisplayName,
        };
      }
    })
    .map(account => ({
      ...account,
      value: account.billingAccount.id,
      label: account.billingAccount.accountDisplayNumber,
    }));
};
