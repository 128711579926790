import styled from 'styled-components';

export const StyledFooter = styled.footer`
  background-color: #3c3c3c;
  color: white;
  width: 100%;
  margin-top: 20px;
  height: 60px;
  position: fixed;
  left: 0px;
  bottom: 0px;
  @media (max-width: 599px) {
    height: 150px;
  }
`;
export const LinksContainer = styled.div.attrs({
  id: 'linksContainer',
})`
  padding-top: 20px;
  display: flex;
  justify-content: flex-end;
  flex-direction: row;
  @media (max-width: 599px) {
    flex-direction: column;
    padding-top: 0px;
  }
`;
export const FooterContainer = styled.div.attrs({
  id: 'footerContainer',
})`
  display: grid;
  grid-template-columns: 60% 40%;
  @media (max-width: 599px) {
    grid-template-columns: 100%;
  }
`;
export const BuildInfoContainer = styled.div.attrs({
  id: 'buildinfocontainer',
})`
  display: flex;
  color: ${(props) => props.theme.colorgraydark};
  font-size: smaller;
  justify-content: flex-end;
  padding-top: 20px;
  padding-right: 20px;
  @media (max-width: 599px) {
    justify-content: center;
    padding-right: 0px;
  }
`;
export const StyledModalLink = styled.button`
  margin-left: 16px;
  display: inline-block;
  text-decoration: underline;
  background: none;
  vertical-align: middle;
  cursor: pointer;
  color: white;
  :hover {
    background: none;
  }
  @media (max-width: 599px) {
    margin-top: 16px;
  }
`;
export const StyledAboutUsLink = styled(StyledModalLink).attrs({
  id: 'styledAboutUsLink',
})``;
export const StyledPrivacyStatementLink = styled(StyledModalLink).attrs({
  id: 'styledPrivacyStatementLink',
})``;
export const StyledPrivacyStatementDiv = styled.div`
  padding: 25px;
`;
export const StyledTermsAndConditionsLink = styled(StyledModalLink).attrs({
  id: 'styledTermAndConditionsLink',
})``;

export const StyledDiv = styled.div`
  float: right;
  margin-right: 50px;
`;
export const StyledTOSDiv = styled.div`
  padding: 25px;
`;
export const AboutUsModalContainer = styled.div`
  padding: 60px;
`;
export const StyledSpan = styled.span.attrs({})`
  margin-left: 16px;
`;
export const StyledLink = styled.a.attrs({})`
  float: right;
  margin-right: 50px;
`;
export const AboutUsBlurb = styled.div.attrs({})`
  margin-bottom: 36px;
`;
export const ContactContainers = styled.div.attrs({})`
  margin-bottom: 36px;
`;
