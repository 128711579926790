import React from 'react';
import { HelpHeader, HelpInfoContainer, NeedHelpContainer } from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';
import NeedHelpRow from './need-help-row';

export const NeedHelp = props => {
  const selfServiceItems =
    props.location.state.customerSlice.selfServiceConfigurations.selfServiceItem;
  return (
    <>
      <Helmet>
        <title>Need Help</title>
      </Helmet>
      <NeedHelpContainer>
        <HelpHeader>Help Information</HelpHeader>
        <HelpInfoContainer>
          {selfServiceItems.map(selfServiceItem => {
            return <NeedHelpRow key={selfServiceItem.id} {...selfServiceItem} />;
          })}
        </HelpInfoContainer>
      </NeedHelpContainer>
    </>
  );
};

NeedHelp.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      customerSlice: PropTypes.object,
    }),
  }),
  errors: PropTypes.any,
};

const mapStateToProps = state => ({
  isLoading: state.customerSlice.isLoading,
  errors: state.customerSlice.errors,
  state,
});

NeedHelp.defaultProps = {
  state: {},
};

export default connect(mapStateToProps)(NeedHelp);
