import React, { useEffect } from 'react';
import Select from 'react-select';
import {
  ChangeCoverageButton,
  ChangeCoverageContainer,
  ChangeCoverageFormContainer,
  ChangeCoverageHeader,
  ChangeCoveragePolicySelectorContainer,
  ChangeCoverageRecapButtonsContainer,
  ChangeCoverageRecapLabel,
  ChangeCoverageRecapRow,
  ChangeCoverageRecapTextArea,
  CoverageChangesRecapContainer,
  CoverageRecapHeader,
  CoverageRecapSubHeader,
  PolicyToChangeLabel,
  ChangeCoverageMessageComponent,
} from './styles';
import { getPolicyChangeTemplateById, getPolicyChangeTemplates } from './policyChangeAPI';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { Helmet } from 'react-helmet';
import Loader from '../../common/loader';
import { ChangeEventComponent } from './change-types';
import {
  resetChangeCoverageState,
  setErrors,
  setPolicyChangePreview,
  setSelectedPolicy,
  setSelectedPolicyChangeTemplate,
  setShowChangeCoverageResults,
} from './policyChangeReducer';
import ApiError from '../../common/api-error';
import TooltipInfo from '../../common/tooltip';
import { getText } from '../../../utils/i18n';
import {
  PolicyChangePreviewButton,
  PolicyChangePreviewButtonsContainer,
} from './change-types/policy-change-preview/styles';

export const ChangeCoverage = props => {
  const {
    isLoading,
    customerId,
    policyChangeTemplates,
    selectedPolicyChangeTemplate,
    policyOptions,
    selectedPolicy,
    showChangeCoverageResults,
    policyChangeCoverageRecapFields,
    errors,
    policyChangePreview,
    i18n,
  } = props;

  // resets error and current view if props.location.key has changed.
  // this happens when the user clicks on the Change Coverage menu link
  useEffect(() => {
    props.dispatch(setErrors(null));
    props.dispatch(setShowChangeCoverageResults(false));
    props.dispatch(setSelectedPolicyChangeTemplate(null));
    props.dispatch(setSelectedPolicy(null));
    props.dispatch(setPolicyChangePreview(null));
  }, [props.location.key]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [showChangeCoverageResults]);
  const history = useHistory();
  useEffect(() => {
    props.dispatch(getPolicyChangeTemplates(customerId));
    let changeCoverageProcessListener = history.listen(history => {
      if (history.pathname === '/changeCoverage') {
        //path is fine, allow state to be tracked
        window.scrollTo(0, 0);
      } else {
        // reset state for change coverage
        // and unlisten
        props.dispatch(resetChangeCoverageState());
        changeCoverageProcessListener();
      }
    });
  }, []);

  let handleTemplateChange = template => {
    if (template.id) {
      props.dispatch(setSelectedPolicy(null));
      props.dispatch(getPolicyChangeTemplateById(template.id, customerId));
    }
  };

  let handlePolicyChange = policy => {
    if (policy && policy.value && policy.value !== 'default') {
      props.dispatch(setSelectedPolicy(policy));
    }
  };

  return (
    <>
      <Helmet>
        <title>Change Coverage</title>
      </Helmet>
      <ChangeCoverageContainer>
        <ChangeCoverageHeader>
          Change Coverage
          <TooltipInfo title={getText(i18n, 'changeCoverage.changeCoverageTooltip')} />
        </ChangeCoverageHeader>
        {i18n?.changeCoverage?.changeCoverageMessage ? (
          <ChangeCoverageMessageComponent
            dangerouslySetInnerHTML={{
              __html: getText(i18n, 'changeCoverage.changeCoverageMessage', ''),
            }}
          />
        ) : null}
        {isLoading ? (
          <Loader />
        ) : errors ? (
          <>
            <ApiError body={errors} variant="nomargin" />
            <PolicyChangePreviewButtonsContainer>
              <PolicyChangePreviewButton
                onClick={() => {
                  props.dispatch(setPolicyChangePreview(null));
                  props.dispatch(setErrors(null));
                }}
              >
                Back
              </PolicyChangePreviewButton>
            </PolicyChangePreviewButtonsContainer>
          </>
        ) : showChangeCoverageResults ? (
          <>
            <CoverageChangesRecapContainer>
              <CoverageRecapHeader>Your change request has been submitted.</CoverageRecapHeader>
              <CoverageRecapSubHeader>
                Here are the changes you requested. Please contact your agent if you have any
                questions or need to make a correction
              </CoverageRecapSubHeader>
              {policyChangeCoverageRecapFields.map(field => {
                return (
                  <ChangeCoverageRecapRow key={`${field.label}-${field.type}`}>
                    <ChangeCoverageRecapLabel id={field.label + ' label'}>
                      {field.label}
                    </ChangeCoverageRecapLabel>
                    {field.type === 'textArea' ? (
                      <ChangeCoverageRecapTextArea value={field.value} />
                    ) : (
                      <div id={field.label + ' text'}>{field.value}</div>
                    )}
                  </ChangeCoverageRecapRow>
                );
              })}
              <ChangeCoverageRecapButtonsContainer>
                <ChangeCoverageButton
                  id="close"
                  onClick={() => {
                    props.dispatch(setShowChangeCoverageResults(false));
                    history.push('/dashboard');
                  }}
                >
                  Close
                </ChangeCoverageButton>
              </ChangeCoverageRecapButtonsContainer>
            </CoverageChangesRecapContainer>
          </>
        ) : (
          <>
            <ChangeCoverageFormContainer>
              <Select
                id="policyChangeTemplatesSelector"
                isDisabled={policyChangePreview !== null}
                options={policyChangeTemplates}
                aria-label="Change Coverage"
                defaultValue={
                  selectedPolicyChangeTemplate === null
                    ? { label: '-- Select a Reason for Policy Change --', value: 'default' }
                    : {
                        label: selectedPolicyChangeTemplate.name,
                        value: selectedPolicyChangeTemplate.id,
                      }
                }
                onChange={handleTemplateChange}
              />
              {selectedPolicyChangeTemplate !== null && (
                <ChangeCoveragePolicySelectorContainer>
                  <PolicyToChangeLabel>
                    Policy To Change
                    <TooltipInfo title={getText(i18n, 'changeCoverage.policyToChangeTooltip')} />
                  </PolicyToChangeLabel>
                  <Select
                    id="policySelector"
                    isDisabled={policyChangePreview !== null}
                    options={policyOptions}
                    aria-label="Policy To Change"
                    defaultValue={{ label: '-- Select a Policy --', value: 'default' }}
                    onChange={handlePolicyChange}
                  />
                </ChangeCoveragePolicySelectorContainer>
              )}
              {selectedPolicyChangeTemplate !== null && selectedPolicy !== null && (
                <ChangeEventComponent {...props} />
              )}
            </ChangeCoverageFormContainer>
          </>
        )}
      </ChangeCoverageContainer>
    </>
  );
};

ChangeCoverage.propTypes = {
  dispatch: PropTypes.func,
  isLoading: PropTypes.bool,
  errors: PropTypes.any,
  customerId: PropTypes.string,
  policyChangeTemplates: PropTypes.array,
  selectedPolicyChangeTemplate: PropTypes.object,
  policyOptions: PropTypes.array,
  selectedPolicy: PropTypes.object,
  showChangeCoverageResults: PropTypes.bool,
  policyChangeCoverageRecapFields: PropTypes.array,
  location: PropTypes.object,
  policyChangePreview: PropTypes.object,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  isLoading: state.policyChangeSlice.isLoading,
  errors: state.policyChangeSlice.errors,
  customerId: state.customerSlice.customer.systemId,
  policyChangeTemplates: state.policyChangeSlice.policyChangeTemplates,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  policyOptions: state.policyChangeSlice.policyOptions,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  showChangeCoverageResults: state.policyChangeSlice.showChangeCoverageResults,
  policyChangeCoverageRecapFields: state.policyChangeSlice.policyChangeCoverageRecapFields,
  policyChangePreview: state.policyChangeSlice.policyChangePreview,
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(ChangeCoverage);
