import { ANONYMOUS_API, refreshToken, TOKENIZED_API, updateTokenizedApi } from '../../../utils/api';
import {
  setAccountCreationErrors,
  setAccountCreationSuccessful,
  setCustomerEmailFound,
  setCustomerEmailOnFile,
  setCustomerId,
  setErrors,
  setRegistrationFields,
  setRegistrationToken,
} from '../login/loginReducer';
import { ga4Event } from '../../../utils/google-analytics-4';
import { isEmail } from '../../../utils/validators/generalValidators';

export const getRegistrationFields = (notify) => {
  return (dispatch) => {
    // get sessions first
    return ANONYMOUS_API.post('clients/serviceportal/sessions')
      .then((response) => {
        const jwtToken = response.data.jwtToken;
        updateTokenizedApi(jwtToken);
        TOKENIZED_API.get(
          'coderefs/UWInterfaces/registration-criteria/validation-items/serviceportal',
        ).then((response) => {
          dispatch(setRegistrationFields(response.data.options));
        });
      })
      .catch((err) => {
        notify(
          'We were unable to retrieve registration fields at this time. Please contact your Agent.',
          {
            variant: 'error',
            anchorOrigin: {
              horizontal: 'center',
              vertical: 'top',
            },
          },
        );
        dispatch(setErrors(err));
      });
  };
};

export const verifyRegistrationFields = (payload, notify, isAnonReportClaimsFlow) => {
  return async (dispatch) => {
    dispatch(setAccountCreationErrors(null));
    dispatch(setCustomerEmailFound(false));
    return TOKENIZED_API.post('customerRegistrations/verificationRequest', payload)
      .then((response) => {
        if (!isAnonReportClaimsFlow) {
          const email = response.data.emailAddr;
          dispatch(setCustomerEmailFound(isEmail(email)));
          dispatch(setCustomerEmailOnFile(email));
        }
        dispatch(setRegistrationToken(response.data.registrationToken));
        dispatch(setCustomerId(response.data.customerId));
      })
      .catch(() => {
        notify('We were unable to locate your account. Please try again.', {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
        dispatch(setErrors('Unable To Verify Account'));
      });
  };
};

const registerCustomer = (payload, notify, dispatch) => {
  return TOKENIZED_API.post('customerRegistrations', payload)
    .then((response) => {
      if (response.status === 201) {
        // GA4 event for registration
        ga4Event({ category: 'account-registration', action: 'account_registration_complete' });
        notify('Success. Your account has been created. You may sign in now.', {
          variant: 'success',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
        dispatch(setAccountCreationSuccessful(true));
      }
    })
    .catch((err) => {
      // GA4 event for registration
      ga4Event({ category: 'account-registration', action: 'account_registration_failed' });

      // use api error message for 4xx, for others use generic.
      const errorMsg =
        err.response.status > 399 && err.response.status < 500 && err?.response?.data?.message
          ? err.response.data.message
          : 'We were unable to create your account at this time. Please contact an agent for further assistance.';
      notify(errorMsg, {
        variant: 'error',
        anchorOrigin: {
          horizontal: 'center',
          vertical: 'top',
        },
      });
      dispatch(setAccountCreationSuccessful(false));
      dispatch(setAccountCreationErrors(errorMsg));
    });
};

export const updateEmailAndRegisterCustomer = (
  payload,
  notify,
  email = '',
  customerId,
  registrationToken,
) => {
  return (dispatch) => {
    dispatch(setErrors(null));
    if (email && email.length > 0) {
      // get customer information on file
      TOKENIZED_API.get(`customers/${customerId}?customerToken=${registrationToken}`).then(
        (response) => {
          const registrationCustomer = response.data;
          const partyInfoList = registrationCustomer?.partyInfo || [];
          let partyInfo = partyInfoList.filter(
            (partyInfo) => partyInfo.partyTypeCd === 'CustomerParty',
          )[0];

          const partyInfoToUpdate = {
            id: partyInfo.id,
            partyTypeCd: partyInfo.partyTypeCd,
            emailInfo: {
              emailTypeCd: 'CustomerEmail',
              emailAddr: email,
              preferredInd: false,
            },
          };

          const customerPatchPayload = {
            indexName: registrationCustomer.indexName,
            partyInfo: [partyInfoToUpdate],
            _revision: registrationCustomer._revision,
          };

          // patch customer
          TOKENIZED_API.patch(
            `customers/${registrationCustomer.systemId}?customerToken=${registrationToken}`,
            customerPatchPayload,
          )
            .then(() => {
              // this will hide the email field if okta registrations fails since email on file has been updated
              dispatch(setCustomerEmailFound(true));
              dispatch(setCustomerEmailOnFile(email));

              // register customer now since customer email has been updated
              registerCustomer(payload, notify, dispatch);
            })
            .catch(() => {
              const errorMsg =
                'We were unable to create your account at this time. Please contact an agent for further assistance.';
              notify(errorMsg, {
                variant: 'error',
                anchorOrigin: {
                  horizontal: 'center',
                  vertical: 'top',
                },
              });
              dispatch(setAccountCreationErrors(errorMsg));
            });
        },
      );
    } else {
      // register customer directly.
      registerCustomer(payload, notify, dispatch);
    }
  };
};

export const getCustomersWithCrossLinkToken = (registrationToken, notify) => {
  return async (dispatch) => {
    await refreshToken();
    return TOKENIZED_API.post('customerRegistrations/verificationRequest', { registrationToken })
      .then((response) => {
        const email = response.data.emailAddr;
        dispatch(setCustomerEmailFound(isEmail(email)));
        dispatch(setCustomerEmailOnFile(email));
        dispatch(setRegistrationToken(response.data.registrationToken));
        dispatch(setCustomerId(response.data.customerId));
      })
      .catch(() => {
        const errorMsg =
          'We were unable to create your account at this time. Please contact an agent for further assistance.';
        notify(errorMsg, {
          variant: 'error',
          anchorOrigin: {
            horizontal: 'center',
            vertical: 'top',
          },
        });
        dispatch(setAccountCreationErrors(errorMsg));
      });
  };
};
