import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ThemeContext } from 'styled-components';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { configurePaymentBody } from '../step3/utils';
import { makePayment } from './makePaymentAnonymousApi';
import {
  setErrors,
  setIsLoading,
  setNextButtonDisabled,
  setMakePaymentStarted,
} from '../makePaymentAnonymousReducer';
import { getText } from '../../../../utils/i18n';
import PendingPaymentBanner from '../../../common/payment/pending-payment';
import { getPendingPayments } from '../../../common/payment/selectors';
import {
  AmountContainer,
  AmountDetails,
  AmountDisclaimerContainer,
  AmountDisclaimerText,
  AmountHeader,
  DateHeader,
  DateOfPayment,
  InstructionsContainer,
  InstrumentContainer,
  InstrumentDetails,
  InstrumentHeader,
  PayDateContainer,
  PaymentContainer,
  PaymentHeader,
  NotificationContainer,
  StyledInformationMessage,
  PayFailedButtonHome,
  StepThreeCards,
  StyledACHIcon,
  StyledCreditIcon,
  StyledInfoIcon,
} from './styles';
import { LineDivider } from '../../../common/line-divider';
import Loader from '../../../common/loader';
import moment from 'moment';
import { DISPLAY_DATE_FORMAT } from '../../../common/constants';
import { ReturnHomeButtonContainer } from '../styles';
const Step4 = (props) => {
  const {
    activeStep,
    amountToPay,
    billSelected,
    customerId,
    acceptedPendingPaymentMessage,
    dispatch,
    error,
    i18n,
    isLoading,
    nextButtonClicked,
    instrumentToPayWith,
    pendingPayments,
    registrationToken,
    payDate,
    response,
  } = props;

  //   The total amount charged to your credit card will be the payment amount shown plus a
  //   $4.95 processing fee.
  const theme = useContext(ThemeContext);

  const muiTheme = createTheme({
    overrides: {
      MuiStepIcon: {
        root: {
          '&.Mui-completed': {
            color: theme.primaryColor,
          },
          '&.Mui-active': {
            color: theme.primaryColor,
          },
        },
        active: {},
        completed: {},
      },
      MuiButton: {
        root: {
          backgroundColor: theme.primaryColor,
          color: 'white',
          '&:hover': {
            backgroundColor: theme.primaryColorHover,
            color: 'white',
          },
          '&.Mui-disabled': {
            backgroundColor: 'gray',
          },
        },
      },
    },
  });
  useEffect(() => {
    dispatch(setIsLoading(false));
    dispatch(setNextButtonDisabled(false));
    dispatch(setErrors(undefined));
  }, []);
  useEffect(() => {
    const paymentServiceAccountId = billSelected.billingAccount.carrierCd
      ? billSelected.billingAccount.carrierCd
      : 'IIC';
    if (activeStep === 3 && nextButtonClicked === true) {
      const finalBody = configurePaymentBody(
        instrumentToPayWith,
        amountToPay,
        customerId,
        customerId,
        paymentServiceAccountId,
        // payDate,
      );
      dispatch(setNextButtonDisabled(true));
      dispatch(setMakePaymentStarted(true));
      dispatch(setIsLoading(true));
      dispatch(makePayment(customerId, finalBody, registrationToken));
    }
  }, [nextButtonClicked]);
  const paymentInfo = () => {
    return instrumentToPayWith.methodCd === 'ACH' || instrumentToPayWith.sourceType === 'ACH'
      ? instrumentToPayWith.achBankAccountTypeCd
      : `${instrumentToPayWith.cardType ? instrumentToPayWith.cardType : 'Credit Card'} ${
          instrumentToPayWith.lastFour
            ? ` - ${instrumentToPayWith.lastFour}`
            : instrumentToPayWith.creditCardNumber
            ? ` - ${instrumentToPayWith.creditCardNumber}`
            : ''
        }`;
  };
  return (
    <>
      <Helmet>
        <title>Make Payment - Review and Make Payment</title>
      </Helmet>
      <ThemeProvider theme={muiTheme}>
        {isLoading ? <Loader /> : null}
        {!isLoading && !response && !error ? (
          <>
            {i18n?.makePaymentAnonymous?.stepFour?.instructions && (
              <InstructionsContainer
                dangerouslySetInnerHTML={{
                  __html: i18n.makePaymentAnonymous.stepFour.instructions,
                }}
              />
            )}
            <PaymentContainer>
              <StepThreeCards>
                <AmountContainer>
                  <AmountHeader>Amount To Be Paid </AmountHeader>
                  <AmountDetails> ${parseFloat(amountToPay).toFixed(2)}</AmountDetails>
                </AmountContainer>
                <InstrumentContainer>
                  <InstrumentHeader>Will be paid by</InstrumentHeader>

                  <InstrumentDetails>
                    {instrumentToPayWith.methodCd === 'ACH' ||
                    instrumentToPayWith.sourceType === 'ACH' ? (
                      <StyledACHIcon />
                    ) : (
                      <StyledCreditIcon />
                    )}
                    {paymentInfo()}
                  </InstrumentDetails>
                </InstrumentContainer>
                <PayDateContainer>
                  <DateHeader>Payment Date</DateHeader>
                  <DateOfPayment>
                    {payDate
                      ? moment(payDate).format(DISPLAY_DATE_FORMAT)
                      : moment().format(DISPLAY_DATE_FORMAT)}
                  </DateOfPayment>
                </PayDateContainer>
              </StepThreeCards>
              <AmountDisclaimerContainer>
                <AmountDisclaimerText>
                  <StyledInfoIcon />
                  {getText(
                    i18n,
                    'general.paymentAmountDisclaimer',
                    'Final payment amount may change depending on the payment type selected and applicable fees.',
                  )}
                </AmountDisclaimerText>
              </AmountDisclaimerContainer>
              {pendingPayments && !acceptedPendingPaymentMessage ? (
                <PendingPaymentBanner isReview={true} />
              ) : null}
            </PaymentContainer>
          </>
        ) : null}

        {error && (
          <PaymentContainer>
            <PaymentHeader>Payment Failed</PaymentHeader>
            <LineDivider />
            {i18n?.makePaymentAnonymous?.paymentFailure && (
              <InstructionsContainer
                dangerouslySetInnerHTML={{
                  __html: i18n.makePaymentAnonymous.paymentFailure,
                }}
              />
            )}
            <NotificationContainer>
              <StyledInfoIcon />
              <StyledInformationMessage>
                <b>Payment Failed:</b> {error}
              </StyledInformationMessage>
            </NotificationContainer>
            <ReturnHomeButtonContainer>
              <Link to="/dashboard">
                <PayFailedButtonHome>Return Home</PayFailedButtonHome>
              </Link>
            </ReturnHomeButtonContainer>
          </PaymentContainer>
        )}
      </ThemeProvider>
    </>
  );
};

Step4.displayName = 'MakePaymentAnonymousStep4';
Step4.propTypes = {
  activeStep: PropTypes.number,
  amountToPay: PropTypes.string,
  acceptedPendingPaymentMessage: PropTypes.any,
  billSelected: PropTypes.object,
  isLoading: PropTypes.bool,
  nextButtonClicked: PropTypes.bool,
  i18n: PropTypes.any,
  nextButtonDisabled: PropTypes.bool,
  dispatch: PropTypes.any,
  registrationToken: PropTypes.string,
  handleNext: PropTypes.func,
  billingAccounts: PropTypes.array,
  payDate: PropTypes.any,
  customerId: PropTypes.string,
  instrumentToPayWith: PropTypes.object,
  pendingPayments: PropTypes.any,
  response: PropTypes.object,
  error: PropTypes.string,
};
export const mapStateToProps = (state) => ({
  activeStep: state.makePaymentAnonymousSlice.activeStep,
  amountToPay: state.makePaymentAnonymousSlice.amountToPay,
  billSelected: state.makePaymentAnonymousSlice.billSelected,
  customerId: state.makePaymentAnonymousSlice.billSelected.billingAccount.systemId,
  isLoading: state.makePaymentAnonymousSlice.isLoading,
  nextButtonClicked: state.makePaymentAnonymousSlice.nextButtonClicked,
  nextButtonDisabled: state.makePaymentAnonymousSlice.nextButtonDisabled,
  instrumentToPayWith: state.makePaymentAnonymousSlice.instrumentToPayWith,
  acceptedPendingPaymentMessage: state.makePaymentAnonymousSlice.acceptedPendingPaymentMessage,
  pendingPayments: getPendingPayments(state.makePaymentAnonymousSlice.billSelected),
  registrationToken: state.loginSlice.registrationToken,
  payDate: state.makePaymentAnonymousSlice.payDate,
  response: state.makePaymentAnonymousSlice.response,
  error: state.makePaymentAnonymousSlice.error,
  i18n: state.i18nSlice.i18n,
  state,
});
export default connect(mapStateToProps)(Step4);
