import React, { useEffect, useState } from 'react';
import { findIndex } from 'lodash';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import {
  ChangeMortgageeButtonsContainer,
  ChangeMortgageeCancelButton,
  ChangeMortgageeNameInputField,
  ChangeMortgageeNextButton,
  ChangeMortgageePropertyComponent,
  ChangeMortgageeRadioButton,
  ChangeMortgageeStateSelectorContainer,
  ChangeMortgageeSubHeader,
  ChangeMortgageeSubmitButton,
  ChangeMortgageeTextAreaField,
  ChangeMortgageLoanNumberInputField,
  ChangePropertyMortgageeContainer,
  ChangePropertyMortgageeField,
  ChangePropertyMortgageeFieldHeader,
  MortgageeLabel,
  MortgageeListLoaderContainer,
  RadioAddressLabel,
  RequiredField,
  TwoColumnContainer,
} from './styles';
import moment from 'moment';
import {
  getAdditionalInterests,
  getMortgageeInterestTypes,
  submitPolicyChange,
} from '../../policyChangeAPI';
import Loader from '../../../../common/loader';
import Select from 'react-select';
import { ISO_DATE_FORMAT, US_STATES } from '../../../../common/constants';
import { createField, formatRecapFieldDate, getMortgageeById } from '../selector';
import {
  setPolicyChangeCoverageRecapFields,
  setPropertyMortgageeList,
} from '../../policyChangeReducer';
import { Spacer, StyledLoaderContainer } from '../../styles';
import Addresses from '../../../../common/addresses';
import { setAddressFields } from '../../../../common/addresses/addressUtils';
import TooltipInfo from '../../../../common/tooltip';
import { getText } from '../../../../../utils/i18n';
import { useHistory } from 'react-router';

const todayDt = moment().format(ISO_DATE_FORMAT);
export const ChangePropertyMortgagee = props => {
  const {
    propertyMortgageeList,
    selectedPolicy,
    mortgageeInterestTypes,
    customerId,
    selectedPolicyChangeTemplate,
    isPropertyMortgageeListLoading,
    i18n,
  } = props;

  // local state
  const [effectiveDate, setEffectiveDate] = useState(todayDt);
  const [selectedMortgageeId, setSelectedMortgageeId] = useState(null);
  const [showChangePropertyMortgageePageTwo, setShowChangePropertyMortgageePageTwo] = useState(
    false,
  );
  const [reason, setReason] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [city, setCity] = useState('');
  const [stateOrProvince, setStateOrProvince] = useState({
    label: '-- Select a State --',
    value: 'default',
  });
  const [zip, setZip] = useState('');
  const [interestType, setInterestType] = useState({
    label: '-- Select a Type --',
    value: 'default',
  });
  const [loanNumber, setLoanNumber] = useState('');
  const [isEscrowed, setIsEscrowed] = useState(false);
  const [isSubmitButtonEnabled, setIsSubmitButtonEnabled] = useState(false);
  const [selectedMortgagee, setSelectedMortgagee] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const checkRequiredFields = () => {
    // validate fields to enable submit button
    const areRequiredFieldsCompleted =
      effectiveDate.length > 0 &&
      reason.length > 0 &&
      name.length > 0 &&
      address &&
      address.length > 0 &&
      city &&
      city.length > 0 &&
      zip &&
      zip.length > 0 &&
      stateOrProvince &&
      stateOrProvince.value &&
      stateOrProvince.value.length > 0 &&
      interestType &&
      interestType.value &&
      interestType.value.length > 0 &&
      loanNumber &&
      loanNumber.length > 0;
    if (areRequiredFieldsCompleted !== isSubmitButtonEnabled) {
      setIsSubmitButtonEnabled(areRequiredFieldsCompleted);
    }
  };

  const searchMortgageeInterestTypes = mortgageeTypeValue => {
    let index = findIndex(
      mortgageeInterestTypes,
      mortgageeType => mortgageeType.value === mortgageeTypeValue,
    );
    return mortgageeInterestTypes[index];
  };

  const searchStateOrProvCd = stateOrProvCd => {
    let index = findIndex(US_STATES, state => state.value === stateOrProvCd);
    return US_STATES[index];
  };

  checkRequiredFields();
  const createPolicyChangeCoverageSummary = () => {
    const fields = [];
    fields.push(createField('Change Requested', selectedPolicyChangeTemplate.name, 'text', false));
    fields.push(
      createField('Requested Effective Date', formatRecapFieldDate(effectiveDate), 'text'),
    );
    fields.push(createField('Reason for Change', reason, 'text'));
    fields.push(createField('Name', name, 'text'));
    fields.push(createField('Address', address, 'text'));
    fields.push(createField('City', city, 'text'));
    fields.push(createField('State', stateOrProvince.value, 'text'));
    fields.push(createField('ZIP', zip, 'text'));
    fields.push(createField('Type', interestType.value, 'text'));
    fields.push(createField('Loan Number', loanNumber, 'text'));
    fields.push(createField('Premium Escrowed', isEscrowed ? 'Yes' : 'No', 'text'));
    return fields;
  };

  const updateMortgagee = (id, mortgageeList) => {
    setSelectedMortgageeId(id);
    setSelectedMortgagee(getMortgageeById(id, mortgageeList));
    setName(getMortgageeById(id, mortgageeList).interestName);
    setAddress(getMortgageeById(id, mortgageeList).addr1);
    setCity(getMortgageeById(id, mortgageeList).city);
    setZip(getMortgageeById(id, mortgageeList).postalCode);
    setStateOrProvince(searchStateOrProvCd(getMortgageeById(id, mortgageeList).stateProvCd));
    setInterestType(
      searchMortgageeInterestTypes(getMortgageeById(id, mortgageeList).interestTypeCd),
    );
    setLoanNumber(getMortgageeById(id, mortgageeList).accountNumber);
  };

  const onSubmitButtonClick = () => {
    setIsSubmitting(true);
    setIsSubmitButtonEnabled(false);

    // create payload
    const payload = {
      changeDt: effectiveDate,
      customerRef: customerId,
      templateIdRef: selectedPolicyChangeTemplate.id,
      policyDetail: {
        policyRef: selectedPolicy.value,
      },
      changeRequest: [
        {
          detailDescription: reason,
          ai: [
            {
              partyInfo: {
                addresses: [
                  {
                    addr1: address,
                    city: city,
                    stateProvCd: stateOrProvince.value,
                    postalCode: zip,
                  },
                ],
              },
              interestName: name,
              interestTypeCd: interestType.value,
              accountNumber: loanNumber,
              escrowInd: isEscrowed,
            },
            { ...selectedMortgagee.originalValue, status: 'Changed' },
          ],
        },
      ],
      sourceCd: 'ServicePortal',
    };

    // set fields for recap/result
    props.dispatch(setPolicyChangeCoverageRecapFields(createPolicyChangeCoverageSummary()));

    // submit API call
    props.dispatch(submitPolicyChange(payload));
  };

  useEffect(() => {
    props.dispatch(setPropertyMortgageeList(null));
    props.dispatch(getMortgageeInterestTypes());
    if (selectedPolicy?.value !== 'default') {
      props.dispatch(getAdditionalInterests(selectedPolicy.value));
    }
  }, [selectedPolicy]);

  const handlePlaceSelect = address => {
    setAddressFields(address, setAddress, setCity, setZip, setStateOrProvince);
  };

  const history = useHistory();

  return (
    <>
      {propertyMortgageeList !== null && propertyMortgageeList.length === 0 ? (
        <ChangePropertyMortgageeFieldHeader>
          No mortgagees available to change.
        </ChangePropertyMortgageeFieldHeader>
      ) : !showChangePropertyMortgageePageTwo ? (
        <ChangePropertyMortgageeContainer>
          <TwoColumnContainer>
            <ChangePropertyMortgageeField>
              <ChangePropertyMortgageeFieldHeader>
                Requested Effective Date <RequiredField>*</RequiredField>
                <TooltipInfo
                  title={getText(
                    i18n,
                    'changeCoverage.changePropertyMortgagee.effectiveDateTooltip',
                  )}
                />
              </ChangePropertyMortgageeFieldHeader>
              <input
                type="date"
                value={effectiveDate}
                aria-label="Requested Effective Date"
                min={todayDt}
                onChange={e => {
                  setEffectiveDate(e.target.value);
                }}
              />
            </ChangePropertyMortgageeField>
            {isPropertyMortgageeListLoading ? (
              <MortgageeListLoaderContainer>
                <Loader />
              </MortgageeListLoaderContainer>
            ) : (
              <ChangePropertyMortgageeField>
                <ChangePropertyMortgageeFieldHeader>
                  Mortgagees to be changed <RequiredField>*</RequiredField>
                  <TooltipInfo
                    title={getText(
                      i18n,
                      'changeCoverage.changePropertyMortgagee.mortgageesToBeChangedTooltip',
                    )}
                  />
                </ChangePropertyMortgageeFieldHeader>
                {propertyMortgageeList &&
                  propertyMortgageeList.map(mortgagee => {
                    return (
                      <ChangeMortgageePropertyComponent key={mortgagee.id}>
                        <ChangeMortgageeRadioButton
                          id={mortgagee.interestName}
                          name="riskProperty"
                          value={mortgagee.id}
                          onChange={e => {
                            updateMortgagee(e.target.value, propertyMortgageeList);
                          }}
                        />{' '}
                        <RadioAddressLabel id={mortgagee.interestName}>
                          {mortgagee.interestName} <br /> {mortgagee.interestTypeCd} <br />
                          {mortgagee.addr1} {mortgagee.addr2} <br /> {mortgagee.city},
                          {mortgagee.stateProvCd} {mortgagee.postalCode}
                        </RadioAddressLabel>
                      </ChangeMortgageePropertyComponent>
                    );
                  })}
              </ChangePropertyMortgageeField>
            )}
          </TwoColumnContainer>
          <ChangeMortgageeButtonsContainer>
            <ChangeMortgageeCancelButton
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Cancel
            </ChangeMortgageeCancelButton>
            <ChangeMortgageeNextButton
              disabled={!selectedMortgageeId}
              onClick={() => {
                setShowChangePropertyMortgageePageTwo(true);
              }}
            >
              Next
            </ChangeMortgageeNextButton>
          </ChangeMortgageeButtonsContainer>
        </ChangePropertyMortgageeContainer>
      ) : (
        <ChangePropertyMortgageeContainer>
          <ChangePropertyMortgageeFieldHeader>
            Selected Mortgagee
          </ChangePropertyMortgageeFieldHeader>
          <MortgageeLabel>
            {selectedMortgagee.interestName} <br /> {selectedMortgagee.interestTypeCd} <br />
            {selectedMortgagee.addr1} {selectedMortgagee.addr2} <br /> {selectedMortgagee.city},{' '}
            {selectedMortgagee.stateProvCd} {selectedMortgagee.postalCode}
          </MortgageeLabel>
          <ChangePropertyMortgageeFieldHeader>
            Reason for change <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.changePropertyMortgagee.reasonTooltip')}
            />
          </ChangePropertyMortgageeFieldHeader>
          <ChangeMortgageeTextAreaField
            id="reason"
            value={reason}
            onChange={e => {
              setReason(e.target.value);
            }}
          />
          <ChangeMortgageeSubHeader>New Mortgagee Information</ChangeMortgageeSubHeader>
          <ChangePropertyMortgageeFieldHeader>
            Name <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.changePropertyMortgagee.nameTooltip')}
            />
          </ChangePropertyMortgageeFieldHeader>
          <ChangeMortgageeNameInputField
            value={name}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          <Addresses
            id="changeCoverageChangePropertyMortgageeAddress"
            handlePlaceSelect={handlePlaceSelect}
            isRequiredField={true}
            defaultAddress={selectedMortgagee}
          >
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.changePropertyMortgagee.addressTooltip')}
            />
          </Addresses>
          <ChangeMortgageeStateSelectorContainer>
            <ChangePropertyMortgageeFieldHeader>
              Type <RequiredField>*</RequiredField>
              <TooltipInfo
                title={getText(i18n, 'changeCoverage.changePropertyMortgagee.typeTooltip')}
              />
            </ChangePropertyMortgageeFieldHeader>
            <Select
              name="mortgageeInterestTypesList"
              id="mortgageeInterestTypesList"
              defaultValue={{ label: '-- Select a Type --', value: 'default' }}
              value={interestType}
              options={mortgageeInterestTypes}
              onChange={selectedInterestType => {
                setInterestType(selectedInterestType);
              }}
            />
          </ChangeMortgageeStateSelectorContainer>
          <ChangePropertyMortgageeFieldHeader>
            Loan Number <RequiredField>*</RequiredField>
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.changePropertyMortgagee.loanNumberTooltip')}
            />
          </ChangePropertyMortgageeFieldHeader>
          <ChangeMortgageLoanNumberInputField
            value={loanNumber}
            onChange={e => {
              setLoanNumber(e.target.value);
            }}
          />
          <ChangePropertyMortgageeFieldHeader>
            Premium Escrowed
            <TooltipInfo
              title={getText(i18n, 'changeCoverage.changePropertyMortgagee.premiumEscrowedTooltip')}
            />
          </ChangePropertyMortgageeFieldHeader>
          <input type="checkbox" checked={isEscrowed} onChange={() => setIsEscrowed(!isEscrowed)} />{' '}
          If your insurance premium is included in your mortgage payment, check this box. Otherwise
          do not.
          <ChangeMortgageeButtonsContainer>
            <ChangeMortgageeCancelButton
              onClick={() => {
                history.push('/dashboard');
              }}
            >
              Cancel
            </ChangeMortgageeCancelButton>
            <ChangeMortgageeSubmitButton
              disabled={!isSubmitButtonEnabled}
              onClick={() => {
                onSubmitButtonClick();
              }}
            >
              Submit
            </ChangeMortgageeSubmitButton>
            {isSubmitting ? (
              <StyledLoaderContainer>
                <Loader loaderheight="20px" loaderwidth="20px" />
              </StyledLoaderContainer>
            ) : (
              <Spacer />
            )}
          </ChangeMortgageeButtonsContainer>
        </ChangePropertyMortgageeContainer>
      )}
    </>
  );
};

ChangePropertyMortgagee.propTypes = {
  dispatch: PropTypes.func,
  propertyMortgageeList: PropTypes.array,
  mortgageeInterestTypes: PropTypes.array,
  selectedPolicy: PropTypes.object,
  customerId: PropTypes.string,
  selectedPolicyChangeTemplate: PropTypes.object,
  isPropertyMortgageeListLoading: PropTypes.bool,
  i18n: PropTypes.object,
};

const mapStateToProps = state => ({
  propertyMortgageeList: state.policyChangeSlice.propertyMortgageeList,
  isPropertyMortgageeListLoading: state.policyChangeSlice.isPropertyMortgageeListLoading,
  mortgageeInterestTypes: state.policyChangeSlice.mortgageeInterestTypes,
  selectedPolicy: state.policyChangeSlice.selectedPolicy,
  selectedPolicyChangeTemplate: state.policyChangeSlice.selectedPolicyChangeTemplate,
  customerId: state.customerSlice.customer.systemId,
  i18n: state.i18nSlice.i18n,
  state,
});

export default connect(mapStateToProps)(ChangePropertyMortgagee);
