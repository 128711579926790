import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { ReportClaimsContainer, ReportClaimsHeader } from './styles';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { resetStepOneState, setActiveStep } from './reportClaimReducer';
import { StepButton, StepLabel } from '@mui/material';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import { styled } from '@mui/material/styles';
import { useHistory } from 'react-router-dom';
import { resetStepTwoState } from './report-claim-step-two/reportClaimStepTwoReducer';
import { resetStepThreeState } from './report-claim-step-three/reportClaimStepThreeReducer';
import { resetStepFourState } from './report-claim-step-four/reportClaimStepFourReducer';
import { resetStepFiveState } from './report-claim-step-five-review-submit/reportClaimStepFiveReducer';
import { gaEvent } from '../../../utils/google-analytics';

import Step1 from './report-claim-step-one';
import Step2 from './report-claim-step-two';
import Step3 from './report-claim-step-three';
import Step4 from './report-claim-step-four';
import Step5 from './report-claim-step-five-review-submit';
import Step6 from './report-claim-confirmation';
import { CustomStepperIcons } from '../../common/stepper/utils';
import Typography from '@mui/material/Typography';
import ReportClaimConfirmation from './report-claim-confirmation';
import { ga4Event } from '../../../utils/google-analytics-4';
const PREFIX = 'Root';
const classes = {
  root: `${PREFIX}-root`,
  button: `${PREFIX}-button`,
  backButton: `${PREFIX}-backButton`,
  instructions: `${PREFIX}-instructions`,
};
const Root = styled('div')(({ theme }) => ({
  [`&.${classes.root}`]: {
    width: '100%',
  },
  [`&.${classes.button}`]: {
    marginRight: theme.spacing(1),
  },
  [`&.${classes.backButton}`]: {
    marginRight: theme.spacing(1),
  },
  [`&.${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
const TypographyRoot = styled(Typography)(({ theme }) => ({
  [`&.${classes.instructions}`]: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
}));
export const ReportClaim = (props) => {
  const { activeStep, claimSubmission, dispatch } = props;

  const getSteps = () => {
    return ['Select Loss Event', 'Add Location', 'Add Contact', 'Provide Details', 'Review/Submit'];
  };
  const [completed, setCompleted] = React.useState({});
  const buttonProps = {};
  const stepProps = {};
  const steps = getSteps();

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    handleComplete();
    dispatch(setActiveStep(newActiveStep));
  };

  const handleStep = (step) => () => {
    dispatch(setActiveStep(step - 1));
    return step;
  };

  const handleStepper = (step) => () => {
    dispatch(setActiveStep(step));
    return step;
  };

  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return (
          <Step1
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
      case 1:
        return (
          <Step2
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
      case 2:
        return (
          <Step3
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
      case 3:
        return (
          <Step4
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
      case 4:
        return (
          <Step5
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
      case 5:
        return (
          <Step6
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
      default:
        return (
          <Step1
            handleNext={handleNext}
            activeStep={activeStep}
            handleStep={handleStep(activeStep)}
          />
        );
    }
  };

  // const todayDt = moment().format(ISO_DATE_FORMAT);

  let history = useHistory();
  useEffect(() => {
    gaEvent({ category: 'report-a-claim', action: 'start' });
    ga4Event({ category: 'report-a-claim', action: 'start' });
    let reportClaimProcessListener = history.listen((history) => {
      if (history.pathname === '/reportClaim') {
        window.scrollTo(0, 0);
        //path is fine, allow state to be tracked
      } else {
        // reset state for report claim
        // and unlisten
        dispatch(resetStepOneState());
        dispatch(resetStepTwoState());
        dispatch(resetStepThreeState());
        dispatch(resetStepFourState());
        dispatch(resetStepFiveState());
        reportClaimProcessListener();
      }
    });
  }, []);

  return (
    <>
      <Helmet>
        <title>Report Claim - Select Loss Event</title>
      </Helmet>
      <ReportClaimsContainer>
        <>
          {claimSubmission === '' ? (
            <Root className={classes.root}>
              <ReportClaimsHeader>Report Claim</ReportClaimsHeader>

              <Stepper alternativeLabel activeStep={activeStep}>
                {steps.map((label, index) => {
                  return (
                    <Step key={label} {...stepProps}>
                      <StepButton
                        onClick={handleStepper(index)}
                        {...buttonProps}
                        completed={completed[index]}
                      >
                        <StepLabel StepIconComponent={CustomStepperIcons}>{label}</StepLabel>
                      </StepButton>
                    </Step>
                  );
                })}
              </Stepper>

              <div>
                {
                  <div>
                    <TypographyRoot component={'span'} className={classes.instructions}>
                      {getStepContent(activeStep)}
                    </TypographyRoot>
                  </div>
                }
              </div>
            </Root>
          ) : (
            <ReportClaimConfirmation />
          )}
        </>
      </ReportClaimsContainer>
    </>
  );
};
ReportClaim.propTypes = {
  activeStep: PropTypes.number,
  dispatch: PropTypes.func,
  isClaimEventLoading: PropTypes.bool,
  errors: PropTypes.any,
  customerId: PropTypes.string,
  claimSubmission: PropTypes.any,
  selectedClaimEventTemplate: PropTypes.object,
  claimEvent: PropTypes.object,
  features: PropTypes.object,
};
const mapStateToProps = (state) => ({
  activeStep: state.reportClaimSlice.activeStep,
  isClaimEventLoading: state.reportClaimSlice.isClaimEventLoading,
  errors: state.reportClaimSlice.errors,
  customerId: state.customerSlice.customer.systemId,
  claimSubmission: state.reportClaimStepFiveSlice.claimSubmission,
  selectedClaimEventTemplate: state.reportClaimSlice.selectedClaimEventTemplate,
  claimEvent: state.reportClaimSlice.claimEvent,
  features: state.configurationSlice.features.features,
});

export default connect(mapStateToProps)(ReportClaim);
